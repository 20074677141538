import { Row, Typography } from 'antd';
import RequiredIcon from 'components/RequiredIcon';
import classNames from 'classnames';

const { Text } = Typography;

interface ITextWithIconProps {
  text: string;
  icon?: boolean;
  classes?: string;
}

const TextWithIcon = ({ text, icon = true, classes }: ITextWithIconProps) => {
  return (
    <Row align="middle" className={classNames(classes)}>
      {icon && <RequiredIcon />}
      <Text className={classNames('text-base', 'font-bold', 'ml-2')}>{text}</Text>
    </Row>
  );
};

export default TextWithIcon;
