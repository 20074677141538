import { NotificationFilterRequest, NotificationItem, UnseenNotificationCountResponse } from "common";

export const NOTIFICATION_GET_REQUEST = 'NOTIFICATION_GET_REQUEST';
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS';
export const NOTIFICATION_GET_FAILED = 'NOTIFICATION_GET_FAILED';
export const FETCH_MORE_NOTIFICATIONS = 'FETCH_MORE_NOTIFICATIONS';
export const NOTIFICATION_LOAD_MORE_REQUEST = 'NOTIFICATION_LOAD_MORE_REQUEST';
export const NOTIFICATION_LOAD_MORE_SUCCESS = 'NOTIFICATION_LOAD_MORE_SUCCESS';
export const NOTIFICATION_UNSEEN_GET_REQUEST = 'NOTIFICATION_UNSEEN_GET_REQUEST';
export const NOTIFICATION_UNSEEN_GET_SUCCESS = 'NOTIFICATION_UNSEEN_GET_SUCCESS';
export const NOTIFICATION_MARK_AS_SEEN_REQUEST = 'NOTIFICATION_MARK_AS_SEEN_REQUEST';
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';



export type FetchMoreNotifications = {
  type: typeof FETCH_MORE_NOTIFICATIONS;
};

export interface FetchNotificationsSuccessPayload {
  results: NotificationItem[];
  next?: string;
  previous?: string;
}



export interface FetchNotificationsRequest {
  type: typeof NOTIFICATION_GET_REQUEST;
  payload: NotificationFilterRequest;
}

export type FetchNotificationsSuccess = {
  type: typeof NOTIFICATION_GET_SUCCESS;
  payload: FetchNotificationsSuccessPayload;
};

export interface FetchNotificationsLoadMoreRequest {
  type: typeof NOTIFICATION_LOAD_MORE_REQUEST;
  payload: NotificationFilterRequest;
}
export interface FetchNotificationsUnseenCountRequest {
  type: typeof NOTIFICATION_UNSEEN_GET_REQUEST;
}
export interface FetchNotificationsMarkAsSeenRequest {
  type: typeof NOTIFICATION_MARK_AS_SEEN_REQUEST;
}

export interface NotificationsUnseenCountSuccess {
  type: typeof NOTIFICATION_UNSEEN_GET_SUCCESS;
  payload: UnseenNotificationCountResponse;
}

export type FetchNotificationsLoadMoreSuccess= {
  type: typeof NOTIFICATION_LOAD_MORE_SUCCESS;
  payload: FetchNotificationsSuccessPayload;
};

export interface AddNewNotificationPayload {
  notification: NotificationItem;
}

export type AddNewNotification = {
  type: typeof ADD_NEW_NOTIFICATION;
  payload: AddNewNotificationPayload;
};



export interface NotificationsState {
  isLoading: boolean,
  loadeMoreisLoading:boolean,
  notifications: NotificationItem[],
  unseenNotificationsCount:number,
  // error: string | null;
  next: string;
  // previous: string;
}



export type NotificationActions =
| FetchNotificationsRequest
| FetchNotificationsSuccess
| FetchNotificationsLoadMoreSuccess
| FetchNotificationsLoadMoreRequest
| FetchNotificationsUnseenCountRequest
| NotificationsUnseenCountSuccess
| FetchNotificationsMarkAsSeenRequest
| AddNewNotification
