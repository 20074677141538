import { Card, Col, Row, Avatar, Space, Typography, Tabs, Upload, Modal } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import type { TabsProps } from 'antd';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import SearchPlace from 'components/SearchPlace';
import TextWithIcon from 'components/JobPostModal/TextWithIcon';
import CustomTextArea from 'components/CustomTextArea';
import CustomInput from 'components/CustomInput';
import DocumentCard from 'components/DocumentCard';
import DButton from 'components/DButton';
import {
  MediaLiteItem,
  Profile,
  ProfileDoerUpdateRequest,
  ProfileMasterUpdateRequest,
  UserItem,
  UserResponse
} from 'common';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { doUploadFlow } from 'helpers/documentUploadFlow';
import { asyncForEach } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import useUser from 'redux/user/use-user';
import { fetchDeleteUserAccount, updateDoerPrifile, updatePrifile } from 'redux/user/actions';
import { UploadChangeParam } from 'antd/lib/upload';
import { GET_PROFILE_BY_ID_SUCCESS, USER_SET_LOADING } from 'redux/user/types';
import { SERVICES_COLORS, SERVICES } from '../../../../constants';
import DotsAndButton from 'components/DotsAndButton';
import PriceCard from 'components/PriceCard';
import { isAccountDeleted, isFaild } from 'redux/user/selectors';
import userEvent from '@testing-library/user-event';
import { RESET_STORE } from 'redux/common/types';
import OneSignal from 'react-onesignal';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

type ServiceType = {
  flatPrice: number;
  hourlyRate: number;
  service: string;
  isNew?: boolean;
  isActive?: boolean;
};
type EditType = {
  onCancel: () => void;
  data: UserResponse;
};

interface FormErrors {
  firstName?: string;
  lastName?: string;
  address?: string;
  companyTaxId?: string;
  companyName?: string;
  radius?: number;
  introduction?: string;
  location?: any;
}

const Edit = ({ onCancel, data }: EditType) => {
  const [serachValueInput, setSearchValueInput] = useState('');
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [services, setServices] = useState<Array<ServiceType>>([]);
  const [selectedServices, setSelectedServices] = useState<Array<ServiceType>>([]);
  const faild: boolean = useSelector(isFaild);
  const navigate = useNavigate();
  const accountDeleted: boolean = useSelector(isAccountDeleted);

  const [mainPrice, setMainPrice] = useState<{
    flatPrice: number;
    hourlyRate: number;
  }>();

  const [files, setFiles] = useState<{
    files: Array<MediaLiteItem>;
    originalFiles: Array<any>;
  }>({ files: [], originalFiles: [] });
  const [photo, setPhoto] = useState<{
    photoUrl: string;
    originalPhoto?: any;
  }>();

  const { user, keepUser } = useUser();
  const dispatch = useDispatch();
  const masterSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    introduction: yup.string().required(),
    address: yup.string().required(),
    companyTaxId: yup.string().required(),
    companyName: yup.string().required(),
    radius: yup.number().min(1).required()
  });

  const doerSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    introduction: yup.string().required()
  });

  const selectedSchema: any = user.profileType === 'MASTER' ? masterSchema : doerSchema;

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors }
  } = useForm<FormErrors>({
    defaultValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      companyTaxId: data?.companyTaxId,
      companyName: data?.companyName,
      address: data.address,
      introduction: data.introduction,
      radius: data.radius,
      location: data.location
    },
    resolver: yupResolver(selectedSchema)
  });

  //keyof UserItem
  const onChange = async (name: any, value: string | number) => {
    setValue(name, value);
  };

  //Profile
  const onSubmit: SubmitHandler<any> = async ({
    lastName,
    firstName,
    address,
    companyTaxId,
    companyName,
    introduction,
    radius,
    location
  }) => {
    console.log('i am heer blah blah');
    const result = await trigger();

    const newFiles: Array<any> = [];
    dispatch({
      type: USER_SET_LOADING,
      payload: true
    });

    await asyncForEach(files.originalFiles, async file => {
      const uploadedFile = await doUploadFlow(file, 'DOCUMENT', 'PROFILE', data._id);
      newFiles.push({
        name: file.name,
        sizeKB: file.size,
        type: 'DOCUMENT',
        path: uploadedFile.path
      });
    });

    let profilePicture = data?.profilePicture;

    debugger;

    if (photo?.originalPhoto) {
      const uploadedPhoto = await doUploadFlow(photo?.originalPhoto, 'PHOTO', 'PROFILE', data._id);
      profilePicture = uploadedPhoto.path;
    }

    const uploadedData = data?.documents || [];
    if (result) {
      if (user.profileType === 'MASTER') {
        const profileData = {
          lastName,
          firstName,
          address,
          location,
          scalability: data.scalability || 'AREA',
          companyTaxId,
          companyName,
          introduction,
          radius,
          documents: [...newFiles, ...uploadedData],
          services: selectedServices,
          profilePicture,
          flatPrice: mainPrice?.flatPrice,
          hourlyRate: mainPrice?.hourlyRate
        };

        dispatch(updatePrifile({ profileData }));
      } else {
        const profileData = {
          lastName,
          firstName,
          introduction,
          profilePicture
        };

        dispatch(updateDoerPrifile({ profileData }));
        console.log(profileData, 'profileData');
      }

      setFiles({ files: [], originalFiles: [] });
      if (faild) {
        onCancel();
      }
    } else {
    }
  };

  const onUploadDacument = async (documents: UploadChangeParam) => {
    const newFiles: Array<any> = [];
    await asyncForEach(documents.fileList, async document => {
      const path = URL.createObjectURL(document.originFileObj);

      const newFile = {
        name: document.name,
        sizeKB: document.size,
        type: 'DOCUMENT',
        path
      };
      newFiles.push(newFile);
    });
    const filesArr = [...newFiles, ...files.files];
    const orgFiles = [...documents.fileList, ...files.originalFiles];
    setFiles({
      files: filesArr,
      originalFiles: orgFiles
    });
  };

  const onUploadPhoto = async (photoFile: any) => {
    const path = URL.createObjectURL(photoFile.file);
    setPhoto({
      photoUrl: path,
      originalPhoto: photoFile.fileList[0]
    });
  };
  const onRemoveUploadedDocument = (index: number) => {
    const docs = data.documents?.filter((doc, idx) => index !== idx);
    dispatch({
      type: GET_PROFILE_BY_ID_SUCCESS,
      payload: { ...data, documents: docs }
    });
  };
  const onRemoveNewDocument = (index: number) => {
    const docs = files.files.filter((doc, idx) => index !== idx);
    const orgDocs = files.originalFiles.filter((doc, idx) => index !== idx);
    setFiles({
      files: docs,
      originalFiles: orgDocs
    });
  };

  useEffect(() => {
    if (data?.profilePicture) {
      setPhoto({
        photoUrl: `${process.env.REACT_APP_MEDIA_BASE_URL}${data?.profilePicture}`
      });
    }
  }, []);

  const filterServices = (key: string) => {
    let flag = false;
    data.services.forEach((item: ServiceType, index) => {
      if (item.service === key) {
        flag = true;
      }
    });
    return flag;
  };

  useEffect(() => {
    const newServices: Array<ServiceType> = [];
    const modifySelectedServices = data.services.map((item: ServiceType, index) => {
      return {
        ...item,
        isActive: true
      };
    });
    Object.keys(SERVICES).forEach(function (key, index) {
      if (!filterServices(key)) {
        newServices.push({
          flatPrice: 0,
          hourlyRate: 0,
          service: key,
          isNew: true,
          isActive: false
        });
      }
    });
    setSelectedServices(data.services);
    setMainPrice({
      flatPrice: data.flatPrice,
      hourlyRate: data.hourlyRate
    });
    setServices([...modifySelectedServices, ...newServices]);
  }, [data]);

  const handleAllChange = (val: string, prof: string, price: string) => {
    setMainPrice((prevState: any) => {
      return { ...prevState, [price]: +val || 0 };
    });
  };
  const handleInputChange = (val: string, prof: string, price: string) => {
    const updatedState = services.map((p: ServiceType) => {
      if (p.service === prof) {
        return {
          ...p,
          [price]: +val || 0
        };
      }
      return p;
    });
    setServices(updatedState);
  };

  const onSwichChange = (val: boolean, service: string) => {
    const updatedState = services.map((p: ServiceType) => {
      if (p.service === service) {
        return {
          ...p,
          isActive: val
        };
      }
      return p;
    });
    setServices(updatedState);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onApplay = () => {
    const newSelectedServices: Array<ServiceType> = [];
    services.forEach((item: ServiceType, index) => {
      if (item.isActive && (item.flatPrice > 0 || item.hourlyRate)) {
        newSelectedServices.push({
          service: item.service,
          flatPrice: item.flatPrice,
          hourlyRate: item.hourlyRate
        });
      }
    });
    setOpenModal(false);
    setSelectedServices(newSelectedServices);
  };

  const onDeleteAccount = () => {
    dispatch(fetchDeleteUserAccount({ userId: user._id }));
  };

  useEffect(() => {
    if (accountDeleted) {
      setVisibleDeleteModal(false);
      localStorage.removeItem('token');
      localStorage.removeItem('deviceId');
      localStorage.removeItem('pushNotificationId');
      OneSignal.setExternalUserId('promo');
      dispatch({ type: RESET_STORE });
      navigate('/auth/login');
    }
  }, [accountDeleted]);

  return (
    <Row className={classNames(styles.editContainer)}>
      <Col span={6}>
        <div className="text-center ">
          <Space wrap size={16}>
            {photo?.photoUrl ? (
              <Avatar
                className="border-solid border-[1px] border-[#E0E0E0]"
                size={98}
                src={photo?.photoUrl}
              />
            ) : (
              <span className={styles.name}>
                {data?.firstName && data?.firstName[0]}
                {data?.firstName && data?.lastName[0]}
              </span>
            )}
          </Space>
        </div>
        <div className={classNames(styles.editPhoto, 'text-center')}>
          <Upload
            beforeUpload={() => false}
            showUploadList={false}
            onChange={(event: UploadChangeParam) => onUploadPhoto(event)}
            fileList={[]}
            maxCount={5}
            accept="image/png, image/jpeg"
          >
            <span className="text-[12px] text-[#49C53B]">Change photo</span>
          </Upload>
        </div>
      </Col>
      <Col span={18} className={classNames(styles.rightSide, '')}>
        <div>
          <form className="validate-form" onSubmit={handleSubmit(onSubmit)}>
            <div className={classNames(styles.scrollingContent, '')}>
              <div className="mb-[24px]">
                <Row gutter={16}>
                  <Col span={10}>
                    <Text className={classNames(styles.inputName)}>First Name</Text>
                    <Row className="mt-[12px]">
                      <Controller
                        name="firstName"
                        control={control}
                        rules={{
                          required: 'This field is required',
                          maxLength: { value: 256, message: 'Max length is 256 characters' },
                          pattern: {
                            value: /(.|\s)*\S(.|\s)*/,
                            message: 'This field cannot be empty'
                          }
                        }}
                        render={({ fieldState: { error }, field }) => (
                          <CustomInput
                            placeholder="First Name"
                            allowedInput="string"
                            value={field.value || ''}
                            onChange={value => {
                              onChange(field.name, value);
                            }}
                            className={classNames('w-[505px]', {
                              'border border-icon-err focus-within:border-icon-err hover:border-icon-err':
                                error
                            })}
                          />
                        )}
                      />
                    </Row>
                    <Row className="w-[505px]" justify="start">
                      {errors.firstName && (
                        <Text className="text-icon-err mt-2 ml-5">{errors.firstName.message}</Text>
                      )}
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Text className={classNames(styles.inputName)}>Last Name</Text>
                    <Row className="mt-[12px]">
                      <Controller
                        name="lastName"
                        control={control}
                        rules={{
                          required: 'This field is required',
                          maxLength: { value: 256, message: 'Max length is 256 characters' },
                          pattern: {
                            value: /(.|\s)*\S(.|\s)*/,
                            message: 'This field cannot be empty'
                          }
                        }}
                        render={({ fieldState: { error }, field }) => (
                          <CustomInput
                            placeholder="Last Name"
                            allowedInput="string"
                            value={field.value || ''}
                            onChange={value => {
                              onChange(field.name, value);
                            }}
                            className={classNames('w-[505px]', {
                              'border border-icon-err focus-within:border-icon-err hover:border-icon-err':
                                error
                            })}
                          />
                        )}
                      />
                    </Row>
                    <Row className="w-[505px]" justify="start">
                      {errors.lastName && (
                        <Text className="text-icon-err mt-2 ml-5">{errors.lastName.message}</Text>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="my-2 mb-[24px]">
                <Text className={classNames(styles.inputName)}>Services offered</Text>

                <Row className="mt-[12px]">
                  <Col span={20}>
                    <Controller
                      name="introduction"
                      control={control}
                      render={({ fieldState: { error }, field }) => (
                        <CustomTextArea
                          value={field.value || ''}
                          onChangeValue={value => {
                            onChange(field.name, value);
                          }}
                          classes=" h-[130px] w-full"
                          placeholder="Add job details"
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>

              {user.profileType === 'MASTER' && (
                <>
                  <div className=" mb-[24px]">
                    <Text className={classNames(styles.inputName)}>Location</Text>
                    <Row className="mt-[12px]">
                      <Col span={20}>
                        <Row>
                          <Controller
                            name="address"
                            rules={{ required: 'This field is required' }}
                            control={control}
                            render={({ fieldState: { error }, field }) => (
                              <SearchPlace
                                onChange={(e: string) => {
                                  onChange(field.name, e);
                                }}
                                value={field.value || ''}
                                setValue={setSearchValueInput}
                                classes={'w-full mt-3'}
                                inputClasses={classNames({
                                  'border !border-icon-err focus-within:border-icon-err hover:border-icon-err':
                                    error
                                })}
                                onLocationChangeHandle={(lat, lng, address) => {
                                  setTimeout(() => {
                                    setValue('location', {
                                      type: 'Point',
                                      coordinates: [lng, lat]
                                    });
                                  });
                                }}
                              />
                            )}
                          />
                        </Row>
                        <Row className="w-[505px]" justify="start">
                          {errors.address && (
                            <Text className="text-icon-err mt-2 ml-5">
                              {errors.address.message}
                            </Text>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="my-[12px]">
                    <Text className={classNames(styles.inputName)}>Job search radius</Text>
                    <Row className="mt-[12px]">
                      <Col span={10}>
                        <Row>
                          <Controller
                            name="radius"
                            control={control}
                            rules={{
                              required: 'This field is required',
                              maxLength: { value: 256, message: 'Max length is 256 characters' },
                              pattern: {
                                value: /(.|\s)*\S(.|\s)*/,
                                message: 'This field cannot be empty'
                              }
                            }}
                            render={({ fieldState: { error }, field }) => (
                              <CustomInput
                                placeholder="Job search radius"
                                allowedInput="number"
                                value={String(field.value) || '0'}
                                onChange={value => {
                                  onChange(field.name, value);
                                }}
                                className={classNames('w-full', {
                                  'border border-icon-err focus-within:border-icon-err hover:border-icon-err':
                                    error
                                })}
                              />
                            )}
                          />
                        </Row>
                        <Row className="w-full" justify="start">
                          {errors?.radius && (
                            <Text className="text-icon-err mt-2 ml-5">
                              {errors?.radius.message}
                            </Text>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="w-ful h-[1px] my-4 bg-[#E0E0E0]"></div>

                  <div className={classNames(styles.blockTitleBold)}>Company Info</div>

                  <div className="my-2 mb-[24px]">
                    <Text className={classNames(styles.inputName)}>Company name</Text>
                    <Row className="mt-[12px]">
                      <Col span={20}>
                        <Row>
                          <Controller
                            name="companyName"
                            control={control}
                            rules={{
                              required: 'This field is required',
                              maxLength: { value: 256, message: 'Max length is 256 characters' },
                              pattern: {
                                value: /(.|\s)*\S(.|\s)*/,
                                message: 'This field cannot be empty'
                              }
                            }}
                            render={({ fieldState: { error }, field }) => (
                              <CustomInput
                                placeholder="Company Name"
                                allowedInput="string"
                                value={field.value || ''}
                                onChange={value => {
                                  onChange(field.name, value);
                                }}
                                className={classNames('w-full', {
                                  'border border-icon-err focus-within:border-icon-err hover:border-icon-err':
                                    error
                                })}
                              />
                            )}
                          />
                        </Row>
                        <Row className="w-full" justify="start">
                          {errors.companyName && (
                            <Text className="text-icon-err mt-2 ml-5">
                              {errors.companyName.message}
                            </Text>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="my-2 mb-[24px]">
                    <Text className={classNames(styles.inputName)}>Company tax ID</Text>
                    <Row className="mt-[12px]">
                      <Col span={20}>
                        <Row>
                          <Controller
                            name="companyTaxId"
                            control={control}
                            rules={{
                              required: 'This field is required',
                              maxLength: { value: 256, message: 'Max length is 256 characters' },
                              pattern: {
                                value: /(.|\s)*\S(.|\s)*/,
                                message: 'This field cannot be empty'
                              }
                            }}
                            render={({ fieldState: { error }, field }) => (
                              <CustomInput
                                placeholder="Company Tax Id"
                                allowedInput="string"
                                value={field.value || ''}
                                onChange={value => {
                                  onChange(field.name, value);
                                }}
                                className={classNames('w-full', {
                                  'border border-icon-err focus-within:border-icon-err hover:border-icon-err':
                                    error
                                })}
                              />
                            )}
                          />
                        </Row>
                        <Row className="w-full" justify="start">
                          {errors.companyTaxId && (
                            <Text className="text-icon-err mt-2 ml-5">
                              {errors.companyTaxId.message}
                            </Text>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="w-ful h-[1px] my-4 bg-[#E0E0E0]"></div>

                  <div className={classNames(styles.blockTitle, 'flex justify-between mb-[5px]')}>
                    Services and prices
                    <div
                      className="text-[14px] text-[#49C53B] cursor-pointer"
                      onClick={onOpenModal}
                    >
                      Manage
                    </div>
                  </div>

                  <div className={classNames(styles.item, 'flex flex-wrap pb-3 ')}>
                    {selectedServices.map((service, index) => {
                      return (
                        <div
                          key={`service-${index}`}
                          style={{
                            background: SERVICES_COLORS[index]
                          }}
                          className={classNames(styles.card, ` mb-2 ml-2`)}
                        >
                          <div className={classNames(styles.title, '')}>
                            {SERVICES[service.service]}
                          </div>
                          <div className={classNames(styles.text, '')}>
                            {service.hourlyRate > 0 && <>${service.hourlyRate}/hour</>}
                            {service.hourlyRate > 0 && service.flatPrice > 0 && <>|</>}
                            {service.flatPrice > 0 && <>${service.flatPrice}/job</>}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="w-ful h-[1px] my-2 bg-[#E0E0E0]"></div>

                  <div className={classNames(styles.blockTitle, 'flex justify-between')}>
                    Documents
                    <Upload
                      beforeUpload={() => false}
                      showUploadList={false}
                      onChange={(event: UploadChangeParam) => onUploadDacument(event)}
                      fileList={[]}
                      multiple
                      maxCount={5}
                      accept=".pdf"
                    >
                      <div className="text-[14px] text-[#49C53B] cursor-pointer">
                        {' '}
                        Upload documents
                      </div>
                    </Upload>
                  </div>

                  {files.files.length > 0 && (
                    <>
                      <div className="ml-[4px] text-[15px] my-2 font-semibold">New Files</div>

                      <div className={classNames(styles.item, 'flex flex-wrap')}>
                        {files.files?.map((document, index: number) => {
                          return (
                            <DocumentCard
                              onRemove={() => onRemoveNewDocument(index)}
                              hasRemoveAction={true}
                              key={index}
                              fileId={`doc-` + index}
                              fileName={document.name}
                              classes="ml-2 mb-2 bg-[#E0E2FF]"
                              size={document.sizeKB}
                            />
                          );
                        })}
                      </div>
                      <div className="w-ful h-[1px] my-2 bg-[#E0E0E0]"></div>
                    </>
                  )}
                  <div className={classNames(styles.item, 'flex flex-wrap')}>
                    {data.documents?.map((document, index: number) => {
                      return (
                        <DocumentCard
                          onRemove={() => onRemoveUploadedDocument(index)}
                          hasRemoveAction={true}
                          key={index}
                          fileId={`doc-` + index}
                          fileName={document?.name}
                          classes="ml-2 mb-2 "
                          size={document.sizeKB}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              <div className="w-ful  flex justify-end">
                <DButton
                  button
                  type="white"
                  className="!w-[140px] text-[#D30000]  mt-2 !border-[#D30000] !text-[14px] !h-[30px]"
                  onClick={() => setVisibleDeleteModal(true)}
                >
                  Delete Account
                </DButton>
              </div>
            </div>

            <div className="w-ful h-[1px] my-2 bg-[#E0E0E0]"></div>

            <Row justify="end" className="mt-[40px]">
              <Space size={16}>
                <DButton button type="white" className="!w-[148px] !h-[44px]" onClick={onCancel}>
                  Cancel
                </DButton>
                <DButton
                  type="light-green"
                  className="text-font-16 font-bold text-white w-[200px] h-[44px]"
                  submit
                  disabled={false}
                  loading={false}
                >
                  Save
                </DButton>
              </Space>
            </Row>
          </form>
        </div>
      </Col>

      <Modal
        className="!rounded-[30px] userServiceModal"
        visible={openModal}
        footer={false}
        destroyOnClose
        centered
        closable={false}
        onCancel={() => console.log('on cancel')}
      >
        <Col className="pt-7 pl-[47px] pr-12 pb-10">
          <Col>
            <Row justify="center">
              <Text className="text-font-22 font-bold">Manage Services & Prices</Text>
            </Row>
          </Col>
          <Col>
            <Row justify="center">
              <div className="">
                <div className={styles.priceCardsCont}>
                  <div className="mb-4">
                    <PriceCard
                      shadow={false}
                      leftInput={{
                        name: 'hr',
                        value: mainPrice?.hourlyRate
                      }}
                      rightInput={{
                        name: 'fp',
                        value: mainPrice?.flatPrice
                      }}
                      onChange={handleAllChange}
                    />
                  </div>

                  {services.map((item: ServiceType, index: number) => (
                    <div key={item.service} className="mb-2">
                      <PriceCard
                        title={item.service}
                        toggle
                        isOpen={item.isActive}
                        leftInput={{
                          name: 'hr' + item.service,
                          value: item.hourlyRate
                        }}
                        rightInput={{
                          name: 'fp' + item.service,
                          value: item.flatPrice
                        }}
                        onChange={handleInputChange}
                        onSwichChange={onSwichChange}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Row>
            <Row justify="end" className="mt-[40px]">
              <Space size={16}>
                <DButton
                  button
                  type="white"
                  className="!w-[148px] !h-[44px]"
                  onClick={onCloseModal}
                >
                  Cancel
                </DButton>
                <DButton
                  type="light-green"
                  className="text-font-16 font-bold text-white w-[200px] h-[44px]"
                  button
                  disabled={false}
                  loading={false}
                  onClick={onApplay}
                >
                  Done
                </DButton>
              </Space>
            </Row>
          </Col>
        </Col>
      </Modal>
      <Modal
        className={classNames(styles.deletModal, ' ')}
        style={{
          borderRadius: '16px !important'
        }}
        visible={visibleDeleteModal}
        footer={false}
        destroyOnClose
        centered
        closeIcon={<></>}
        onCancel={() => setVisibleDeleteModal(false)}
        maskStyle={{ background: 'unset' }}
      >
        <Row className={classNames(styles.container, '')}>
          <Col span={24}>
            <div className="text-[#18191A] text-[22px] text-center py-3 font-semibold">
              Are you sure to Delete your account ?
            </div>

            <div className="flex justify-center mt-[40px]">
              <div>
                <div className="flex justify-end mt-[30px]">
                  <DButton
                    type="only-text"
                    className="text-font-12 border border-[#18191A] rounded-[70px] font-bold text-[#18191A] w-[120px] h-[35px]"
                    onClick={() => setVisibleDeleteModal(false)}
                    disabled={false}
                    loading={false}
                  >
                    Cancel
                  </DButton>
                  <DButton
                    onClick={onDeleteAccount}
                    type="light-green"
                    className="text-font-12 ml-4 !bg-[rgb(193,40,27) font-bold text-white w-[120px] h-[35px]"
                    disabled={false}
                    loading={false}
                  >
                    Delete
                  </DButton>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

export default Edit;
