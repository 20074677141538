/** @format */
import dayjsRaw, { ConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjsRaw.extend(utc);

export const dayjs = (date?: ConfigType, format?: string) => dayjsRaw.utc(date, format);

export const convertSapDate = (sapDate: string): number | null => {
  if (sapDate?.trim().length > 0) {
    return dayjs(sapDate, 'YYYYMMDD').valueOf();
  }
  return null;
};

export const convertSapTimestamp = (sapTimestamp: string): number | null => {
  if (sapTimestamp?.trim().length > 0) {
    return Number(sapTimestamp.match(/\d+/g)!.join());
  }
  return null;
};

export const convertToSapTimestamp = (timestamp: number | null): string | null => {
  if (timestamp) {
    return `/Date(${timestamp})/`;
  }
  return null;
};

export const convertISODate = (isoDate?: string): Date | null => {
  if (isoDate && isoDate?.trim().length > 0) {
    return dayjs(isoDate, 'YYYY-MM-DD').toDate();
  }
  return null;
};
