import api from './index';
import {
  JobItem,
  CreateJobRequest,
  JobsFilterResponse,
  JobFilterRequest,
  AttachMediaJobRequest,
  ApplyJobRequest,
  JobItemLite,
  InviteMasterJobRequest,
  ChatChannelItem
} from 'common';
import qs from 'qs';

export const getJobs = async (_params: JobFilterRequest = {}): Promise<JobsFilterResponse> => {
  return await api.get('/jobs', {
    params: _params,
    paramsSerializer: _params => {
      _params.sortOrder = _params.sortBy === 'rating' ? -1 : _params.sortOrder;
      return qs.stringify(_params);
    }
  });
};

export const getMyAllJobs = async (jsonData: JobFilterRequest = {}): Promise<JobsFilterResponse> => {
  return await api.get('/jobs/my-all-jobs', {
    params: jsonData,
    paramsSerializer: jsonData => {
      return qs.stringify(jsonData);
    }
  });
};

export const getJob = (id: string): Promise<JobItem> => {
  return api.get(`/jobs/${id}`);
};

export const getMyJobs = (): Promise<JobItem[]> => {
  return api.get(`/jobs/my-jobs`);
};

export const postJob = (postJobData: CreateJobRequest): Promise<JobItem> => {
  return api.post(`/jobs`, postJobData);
};

export const inviteMasterToJob = ({
  jobId,
  masterId,
  message,
  hourlyRate,
  flatPrice,
}: InviteMasterJobRequest & { jobId: string }): Promise<ChatChannelItem> => {
  return api.post(`jobs/${jobId}/invite`, { masterId, message ,hourlyRate,flatPrice});
};

export const applyToJob = (applyJobData: ApplyJobRequest, jobId: string): Promise<JobItem> => {
  return api.post(`/jobs/${jobId}/apply`, applyJobData);
};

export const deactivateJob = (jobId: string): Promise<JobItem> => {
  return api.put(`/jobs/${jobId}/deactivate`);
};

export const reportJob = (jobId: string,data:{comment:string}): Promise<JobItem> => {
  console.log(data);
  return api.post(`/jobs/${jobId}/report`,data);
};


export const attachMediaToJob = (
  jobId: string | undefined,
  mediaPaths: AttachMediaJobRequest
): Promise<JobItem> => {
  return api.put(`/jobs/${jobId}/attach-media`, mediaPaths);
};
