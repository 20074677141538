/** @format */

import axios, { AxiosError } from 'axios';
import {
  ChatChannelItem,
  SendApplyJobMessageRequest,
  SendJobInvitationMessageRequest,
  ChangeSystemMessageStateByIdRequest,
  ChangeSystemMessageStateByTypeRequest,
  SendSystemMessageRequest,
  PublishMessagesByGroupIdRequest,
} from '../model/business/chat';
import { StatusError } from '../model/errors';

const chatServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.CHAT_SERVICE_URL!,
  apiKey: 'testKey',
};

export const sendApplyJobMessage = async (applyJobMessage: SendApplyJobMessageRequest) =>
  postData<ChatChannelItem>(`/send-apply-job-message`, applyJobMessage);

export const sendInviteMasterJobMessage = async (
  jobInvitationMessage: SendJobInvitationMessageRequest,
) => postData<ChatChannelItem>(`/send-job-invitation-message`, jobInvitationMessage);

export const changeSystemMessageStateById = async (
  messageId: string,
  data: ChangeSystemMessageStateByIdRequest,
) => putData<string>(`/${messageId}/change-system-message-state`, data);

export const changeSystemMessageStateByType = async (
  channelId: string,
  data: ChangeSystemMessageStateByTypeRequest,
) => putData<string>(`/${channelId}/change-system-messages-state-by-type`, data);

export const changeSystemMessageStateByState = async (
  messageId: string,
  data: ChangeSystemMessageStateByIdRequest,
) => putData<string>(`/${messageId}/change-system-message-state`, data);

export const sendSystemMessage = async (channelId: string, message: SendSystemMessageRequest) => {
  postData<ChatChannelItem>(`/${channelId}/send-system-message`, message);
};

export const publishByGroupId = async (groupId: string, data: PublishMessagesByGroupIdRequest) =>
  putData<string>(`/by-group-id/${groupId}/publish`, data);

interface ChatApiResponse {}

interface ChatApiError {
  name: string;
  message: string;
}

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<ChatApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const putData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.put<ChatApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const handleHttpError = (error: AxiosError<ChatApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${chatServiceConfig.url}${path}`;
