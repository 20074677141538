/** @format */

import * as yup from 'yup';
import { UserItem } from '../user';
import { JobItemLite } from '../job';
import { JobContractItemLite } from '../job-contract';

export const PaymentSendRequestYup = yup.object().shape({
  message: yup
    .string()
    .max(1000)
    .when('type', {
      is: 'TEXT',
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  type: yup.string().oneOf(['TEXT', 'MEDIA', 'SYSTEM']).required(),
  groupId: yup.string().optional(),
});

export type PaymentType = 'IN' | 'OUT';
export type PaymentPurpose = 'DOERS_FEE' | 'JOB_PAYMENT' | 'JOB_TIP' | 'ADD_NEW_PAYMENT_METHOD';
export type PaymentMethod = 'PAYPAL' | 'STRIPE';
export type PaymentStatus = 'PENDING' | 'DONE' | 'FAILED';

export type PaymentItem = {
  _id?: string;
  paymentId: string;
  user: string;
  sender: string | UserItem;
  receiver?: string | UserItem;
  job?: string | JobItemLite;
  jobContract?: string | JobContractItemLite;
  amount: number;
  type: PaymentType;
  purpose: PaymentPurpose;
  method: PaymentMethod;
  status: PaymentStatus;
};

export type AddPaymentMethodPaymentRequest = {
  sender: string;
  method: PaymentMethod;
  amount: number;
  purpose: PaymentPurpose;
};

export type PaymentsFilterResponse = {
  items: PaymentItem[];
  count: number;
};

export type PaymentTotalNumbersResponse = {
  totalInAmount: number;
  totalOutAmount: number;
};

export type MakePaymentRequest = {
  sender: string;
  receiver: string;
  jobId?: string;
  jobContractId?: string;
  amount: number;
  purpose: PaymentPurpose;
};

export type MakePaymentResponse = {
  sender: PaymentItem;
  receiver: PaymentItem;
};

export type PaymentCommonFilterRequest = {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  next?: string;
  previous?: string;
};

export type PaymentFilterRequest = PaymentCommonFilterRequest & {
  user?: string;
  type?: PaymentType;
  purpose?: PaymentPurpose;
};
