import React from 'react';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Divider } from 'antd';

const Steps = () => {
  return (
    <div className={styles.minH540}>
      <div className="flex flex-col items-center w-[474px]">
        <p className={classNames(['text-doers-black', styles.steps])}>Steps</p>
        <p className={classNames(styles.youWillAnser)}>
          You will answer 6 simple questions for activating your Master profile.
        </p>
        <div className={styles.numbersContainer}>
          <div className={styles.number}>1</div>
          <div className={styles.line}></div>
          <div className={styles.number}>2</div>
          <div className={styles.line}></div>
          <div className={styles.number}>3</div>
          <div className={styles.line}></div>
          <div className={styles.number}>4</div>
          <div className={styles.line}></div>
          <div className={styles.number}>5</div>
          <div className={styles.line}></div>
          <div className={styles.number}>6</div>
        </div>
        <div className={styles.prepareDocumentsList}>
          <p>Please prepare these following documents</p>
          <ul>
            <li>Passport or Id card</li>
            <li>Driving license( if your services are related to driving)</li>
            <li>
              Your service-related licenses, certifications or any documents that you find necessary
              to have on your profile page
            </li>
          </ul>
        </div>
        <Divider />
        <DButton
          text="Get started"
          className="w-[278px]"
          type="default"
          goTo="/become-a-master/account-type"
          //goTo="/become-a-master/introduction"
        />
      </div>
    </div>
  );
};

export default Steps;
