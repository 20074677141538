import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { Modal } from 'antd';
import CardsList from './CardsList';
import Map from './Map';
import Filters from '../Filters';
import { useDebounce, useDidMountEffect } from 'hooks';
import ProfileView from '../Modals/ProfileView';
import JobView from '../Modals/JobView';
import LoginModal from '../Modals/LoginModal';
import { filters as filtersSelector } from 'redux/dashboard/selectors';
import { getMasters } from 'api/masters';
import { applyToJob, getJobs, getMyAllJobs, getMyJobs } from 'api/jobs';
import { IMasterAndJobs } from 'types/mapScreen';
import { useSelector } from 'react-redux';
import ApplyForJob from '../Modals/ApplyForJob';
import {
  CLOSE_APPLY_MODAL,
  CLOSE_CONNECT_PAYMENT_MODAL,
  CLOSE_HIRE_MODAL,
  CLOSE_LOGIN_MODAL
} from 'redux/modals/actionTypes';
import { useDispatch } from 'react-redux';
import HireMaster from '../Modals/HireMaster';
import { JobItem, JobItemLite } from 'common';
import PayPalMoadalContent from '../Modals/PaymentMoadalContent';
import useUser from '../../../redux/user/use-user';
import _ from 'lodash';

const MapAndCards = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: locationState }: any = location;
  const { user, keepUser } = useUser();

  const {
    applyForJobModal: applyModal,
    hireMasterModal,
    loginModal,
    connectPaymentModal
  } = useSelector((state: any) => state.modals);

  useEffect(() => {
    if (location.pathname.includes('master')) {
      const splitted = location.pathname.split('/');
      const id = splitted[splitted.length - 1];
      navigate(`/explore/master/${id}`, {
        state: {
          master: id
        }
      });
    }
    if (location.pathname.includes('job')) {
      const splitted = location.pathname.split('/');
      const id = splitted[splitted.length - 1];
      navigate(`/explore/job/${id}`, {
        state: {
          job: id
        }
      });
    }
  }, []);

  const filters = useSelector(filtersSelector);

  const {
    prices: { hourlyRate, flatPrice },
    search
  } = filters;

  const [data, setData] = useState<IMasterAndJobs>({
    masters: {
      items: [],
      count: 0
    },
    jobs: {
      items: [],
      count: 0
    }
  });

  const [myJobs, setMyjobs] = useState<Array<JobItem>>([]);

  const closeModal = () => {
    navigate('/explore');
  };

  const closeApplyModal = () => {
    dispatch({
      type: CLOSE_APPLY_MODAL
    });
  };

  const closeHireModal = () => {
    dispatch({ type: CLOSE_HIRE_MODAL });
  };

  const closeLoginModal = () => {
    dispatch({ type: CLOSE_LOGIN_MODAL });
  };

  const debouncedSearch = useDebounce(filters, 700);
  useDidMountEffect(() => {
    (async () => {
      if (filters.mapInfo?.radius && filters.mapInfo?.center) {
        const filtersBody = {
          lng: filters.mapInfo?.center.lng,
          lat: filters.mapInfo?.center.lat,
          sortBy: filters.sortBy.toLowerCase(),
          radius: filters.mapInfo?.radius * 1000,
          from: filters.dates[0]?.utc()?.valueOf(),
          to: filters.dates[1]?.utc()?.valueOf(),
          ...(search && { search }),
          ...(hourlyRate.min && { rateFrom: hourlyRate.min }),
          ...(hourlyRate.max && { rateTo: hourlyRate.max }),
          ...(flatPrice.min && { priceFrom: flatPrice.min }),
          ...(flatPrice.max && { priceTo: flatPrice.max }),
          ...(filters.industries?.length && { industry: filters.industries }),
          searchPlaceLat: filters.searchPlace?.placeCoords.lat,
          searchPlaceLng: filters.searchPlace?.placeCoords.lng,
          sortOrder: 1
        };
        if (filters.lookingFor === 'masters') {
          const data = await getMasters(filtersBody);
          if (!_.isEmpty(user)) {
            const myjobs = await getMyAllJobs();
            setMyjobs(myjobs.items);
          }

          setData(prev => ({
            ...prev,
            masters: data
          }));
        } else if (filters.lookingFor === 'jobs') {
          const data = await getJobs(filtersBody);

          setData(prev => ({
            ...prev,
            jobs: data
          }));
        } else if (filters.lookingFor === 'both') {
          // TODOFRONT improve this part
          const masters = await getMasters(filtersBody);
          const jobs = await getJobs(filtersBody);
          setData({
            masters,
            jobs
          });
        }
      }
    })();
  }, [debouncedSearch]);

  return (
    <>
      <Filters />
      <div className={styles.content}>
        <Map data={data} />
        <CardsList data={data} />
      </div>
      <Modal
        className={styles.profile}
        visible={locationState?.master || locationState?.job}
        footer={false}
        destroyOnClose
        closeIcon={<div onClick={closeModal} className="icon-22 text-white" />}
        onCancel={closeModal}
      >
        {locationState?.master && <ProfileView />}
        {locationState?.job && <JobView />}
      </Modal>
      <Modal
        className={styles.applyModal}
        visible={applyModal.show}
        footer={false}
        destroyOnClose
        centered
        closable={false}
        onCancel={closeApplyModal}
      >
        <ApplyForJob
          api={applyToJob}
          modalTitle="Apply for the Job"
          priceSectionTitle="What’s the amount you’ll want to get for this job?"
          messageSectionTitle="What makes you a good fit for this job?"
          applyButtonText="Apply for Job"
        />
      </Modal>
      <Modal
        className={styles.applyModal}
        visible={hireMasterModal.show}
        footer={false}
        destroyOnClose
        centered
        closable={false}
        onCancel={closeHireModal}
      >
        <HireMaster />
      </Modal>
      <Modal
        className={styles.loginModal}
        visible={loginModal.show}
        footer={false}
        destroyOnClose
        centered
        closeIcon={<></>}
        onCancel={closeLoginModal}
        maskStyle={{ background: 'unset' }}
      >
        <LoginModal />
      </Modal>
    </>
  );
};

export default MapAndCards;
