  import { Avatar, Space } from "antd";
  import classNames from 'classnames';


  type CustomAvatarType = {

    firstName?:string;
    lastName?:string;
    imagePath?:string;
    size?:number;
    replaceImageSize:string;
    nameClasses?:string

  }
  const base_path = process.env.REACT_APP_MEDIA_BASE_URL


  const CustomAvatar = ({firstName,nameClasses, lastName, imagePath, size = 44 ,replaceImageSize = '100x100'}:CustomAvatarType) =>{


    return(
      <>

  <Space wrap size={16}>
                  {imagePath ?
                     <Avatar className='border-solid border-[1px] border-[#E0E0E0]'
                     size={size}
                     src={`${base_path}${imagePath.replace('original', replaceImageSize)}`}  /> :
                     <div style={{width:size,height:size}}  className={classNames(nameClasses,`text-white
                    text-center flex justify-center items-center
                     rounded-full `)} >
                        {firstName && firstName[0]}
                          {lastName && lastName[0]}
                     </div>
                     }
          </Space>
      </>
    )
  }

  export default CustomAvatar;
