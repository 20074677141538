import React, { useEffect, useState } from 'react';
import { Select, Typography } from 'antd';
import styles from './index.module.scss';
import ProfileCard from './ProfileCard';
import useDashboard from 'redux/dashboard/use-dashboard';
import JobCard from './JobCard';
import Tab from 'components/Tab';
import { IMasterAndJobs } from 'types/mapScreen';
import classNames from 'classnames';
import useUser from 'redux/user/use-user';
import { useEffectAsync } from 'hooks';
import { getAddressFromCoords } from 'api/generic';
import useGoogleMapZoom from 'redux/googleMapZoom/use-map-zoom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ModifiedChannel } from 'redux/chat/types';
import { setRedirectedChanelId, setSelectedChannel } from 'redux/chat/actions';
import { getLoggedInUser } from 'api/auth';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;

const CardsList = ({ data }: { data: IMasterAndJobs }) => {
  const { masters, jobs } = data;
  const { filters, changeFilters } = useDashboard();
  const [residentalAreaName, setResidentalAreaName] = useState('');
  const [chosenTab, setChosenTab] = useState('masters');
  const [isMasterActive, setIsMasterActive] = useState<boolean>(true);
  const { zoom } = useGoogleMapZoom();
  const navigate = useNavigate();
  const {
    modifiedChannels,
    selectedChannel
  }: {
    modifiedChannels: ModifiedChannel[];
    selectedChannel: ModifiedChannel;
  } = useSelector((state: any) => state.chat);

  const dispatch = useDispatch();

  const handleSortChange = (val: string) => {
    changeFilters({ ...filters, sortBy: val });
  };
  const { user,keepUser } = useUser();
  const options = [
    'Rating',
    // ...(filters.lookingFor === 'jobs' || (filters.lookingFor === 'both' && chosenTab === 'jobs')
    //   ? ['Distance']
    //   : []),
      'Distance',
    ...(user.profileType === 'MASTER' ||
    filters.lookingFor === 'masters' ||
    (filters.lookingFor === 'both' && chosenTab === 'masters')
      ? ['Matching']
      : [])
  ];




  useEffectAsync(async () => {
    if (filters.mapInfo?.center) {
      const mapsCenterAddress = await getAddressFromCoords([
        filters.mapInfo.center.lng,
        filters.mapInfo.center.lat
      ]);
      let cityOrVillage = '';
      let state = '';
      let country = '';

    const address_components = mapsCenterAddress[0].address_components;
      if (mapsCenterAddress[0]) {
        address_components.forEach((addressComponent: any) => {
          if (
            addressComponent.types[0] === 'locality' &&
            addressComponent.types[1] === 'political'
          ) {
            cityOrVillage = addressComponent.long_name;
          }
          if (
            addressComponent.types[0] === 'administrative_area_level_1' &&
            addressComponent.types[1] === 'political'
          ) {
            state = addressComponent.long_name;
          }
          if (addressComponent.types[0] === 'country') {
            country = addressComponent.long_name;
          }
        });
      }
      let areaName = '';
      if (zoom >= 9) {
        areaName = `${cityOrVillage ? `${cityOrVillage}, ` : state ? `${state}, ` : ''}${country}`;
      } else {
        areaName = `${state ? `${state}, ` : ''} ${country}`;
      }
      setResidentalAreaName(areaName);
    }
  }, [filters.mapInfo]);



useEffectAsync(async()=>{


  if(!_.isEmpty(user)){
    const currentUser = await getLoggedInUser();
    keepUser(currentUser);
  }

},[filters.lookingFor,chosenTab])


  useEffect(() => {
    if (filters.lookingFor === 'masters') {
      setIsMasterActive(true);
      setChosenTab('masters');
    } else if (filters.lookingFor === 'jobs') {
      setIsMasterActive(false);
      setChosenTab('masters');
    } else if (filters.lookingFor === 'both') {
      if (chosenTab === 'masters') {
        setIsMasterActive(true);
      } else if (chosenTab === 'jobs') {
        setIsMasterActive(false);
      }
    }
    changeFilters({
      ...filters,
      sortBy:
        user.profileType === 'MASTER' ||
        filters.lookingFor === 'masters' ||
        (filters.lookingFor === 'both' && chosenTab === 'masters')
          ? 'Matching'
          : 'Distance'
    });
  }, [filters.lookingFor, chosenTab]);

 const onNvigateToChat = (chatId:string)=>{
  dispatch(
    setRedirectedChanelId({
      redirectedChanelId:chatId
    })
  );
     navigate('/chat')
 }


  return (
    <div className="py-5 pl-6 pr-4">
      {filters.lookingFor === 'both' && (
        <Tab
          data={{
            masters: {
              tab: (
                <div className="custom-tab">
                  <i
                    className={classNames('icon-33 mr-2 text-font-12', {
                      'text-green': chosenTab === 'masters'
                    })}
                  />
                  Masters only
                </div>
              )
            },
            jobs: {
              tab: (
                <div className="custom-tab">
                  <i
                    className={classNames('icon-34 mr-2 text-font-15', {
                      'text-green': chosenTab === 'jobs'
                    })}
                  />
                  Jobs only
                </div>
              )
            }
          }}
          activeKey={chosenTab}
          handleTabChange={tab => setChosenTab(tab)}
          classes={styles.bothTab}
        />
      )}
      <Text className="text-doers-black font-bold text-font-22">{residentalAreaName}</Text>
      <div className={styles.countAndSort}>
        <Text className="font-semibold text-sm text-doers-black">{`${
          isMasterActive
            ? `${masters.count} master${masters.count > 1 ? 's' : ''}`
            : `${jobs.count} job${jobs.count > 1 ? 's' : ''}`
        } found`}</Text>
        <div className={styles.sort}>
          <Text className="mr-3">Sort By</Text>
          <Select
            className={styles.select}
            value={filters.sortBy}
            onChange={handleSortChange}
            placeholder="Select Radius"
            suffixIcon={<i className="icon-20 text-font-8 text-light-gray" />}
          >
            {options.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div
        className={classNames(styles.cardsList, {
          [styles.bothSelected]: filters.lookingFor === 'both'
        })}
      >
        {isMasterActive ? masters.items.map((item, i) => {
            return <ProfileCard  item={item} key={i} />
          })
          : jobs.items.map((item, i) => {
            if(item.user !== user._id){
              return <JobCard onNvigate={onNvigateToChat} item={item} key={i} />
            }
          })}
      </div>
    </div>
  );
};

export default React.memo(CardsList);
