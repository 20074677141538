export const MAP_SCREEN_DETAILS_MODAL_CHANGE = 'MAP_SCREEN_DETAILS_MODAL_CHANGE';
export const GALLERY_MODAL_OPEN = 'GALLERY_MODAL_OPEN';
export const GALLERY_MODAL_CLOSE = 'GALLERY_MODAL_CLOSE';
export const OPEN_JOB_APPLY_MODAL = 'OPEN_JOB_APPLY_MODAL';
export const CLOSE_APPLY_MODAL = 'CLOSE_APPLY_MODAL';
export const OPEN_HIRE_MODAL = 'OPEN_HIRE_MODAL';
export const CLOSE_HIRE_MODAL = 'CLOSE_HIRE_MODAL';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const EDIT_JOB_APPLY_MODAL_PRICE = 'EDIT_JOB_APPLY_MODAL_PRICE';
export const OPEN_REPORT_ISSUE_MODAL = 'OPEN_REPORT_ISSUE_MODAL';
export const CLOSE_REPORT_ISSUE_MODAL = 'OPEN_REPORT_ISSUE_MODAL';
export const OPEN_COMPLETE_JOB_MODAL = 'OPEN_COMPLETE_JOB_MODAL';
export const CLOSE_COMPLETE_JOB_MODAL = 'CLOSE_COMPLETE_JOB_MODAL';
export const OPEN_DECLINE_ISSUE_MODAL = 'OPEN_DECLINE_ISSUE_MODAL';
export const CLOSE_DECLINE_ISSUE_MODAL = 'CLOSE_DECLINE_ISSUE_MODAL';
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export const CLOSE_TIP_MODAL = 'CLOSE_TIP_MODAL';
export const OPEN_TIP_MODAL = 'OPEN_TIP_MODAL';

export const OPEN_CONNECT_PAYMENT_MODAL = 'OPEN_CONNECT_PAYMENT_MODAL';
export const CLOSE_CONNECT_PAYMENT_MODAL = 'CLOSE_CONNECT_PAYMENT_MODAL';

export const POST_JOB_MODAL = 'POST_JOB_MODAL';
