import React, { useState } from 'react';
import { Form } from 'antd';
import DButton from 'components/DButton';
import Input from 'components/Input';
import { useNavigate } from 'react-router-dom';
import ResetPassContainer from '../Containers/ResetPassContainer';
import { getPasswordResetCode } from 'api/auth';
import useUser from 'redux/user/use-user';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { keepEmailForPassReset, emailForUserReset } = useUser();
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await getPasswordResetCode(values);
      keepEmailForPassReset(values.email);
      navigate('/auth/code-verification');
    } catch (error: any) {
      form.setFields([
        {
          name: 'email',
          errors: ['User not found.']
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResetPassContainer>
      <div className="my-2">
        <Form onFinish={onFinish} autoComplete="off" form={form} validateTrigger={['onBlur']}>
          <div className="mt-[75px] mb-6 text-center font-bold">
            <p className="w-[270px] inline-flex justify-center">
              Write your email to receive a password reset code
            </p>
          </div>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'This field is required.' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
            className="mb-6"
            initialValue={emailForUserReset}
          >
            <Input label="Email*" size="lg" />
          </Form.Item>
          <DButton
            text="Send the code"
            loading={loading}
            type="default"
            submit
            className="w-[376px] text-sm font-semibold"
          />
        </Form>
      </div>
    </ResetPassContainer>
  );
};

export default ForgotPassword;
