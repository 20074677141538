
import { Card, Col, Descriptions, Row, Statistic } from 'antd';
import classNames from 'classnames';
import { Profile, UserItem, UserResponse } from 'common';
import DocumentCard from 'components/DocumentCard';
import { SERVICES,SERVICES_COLORS } from '../../../../../constants';
import styles from './index.module.scss';
import EditSvg from 'assets/icons/edit.svg';


type AboutType ={
      onEdit:()=>void;
      data: UserResponse ;
}

const About = ({onEdit, data}:AboutType) =>{

return(
  <>
  <div className={classNames(styles.aboutTab, '')}   >
      <div className={classNames(styles.item, '')}   >
          <div className={classNames(styles.itemTitle, 'flex justify-between mt-[5px]')}   >
          Services offered
            <div onClick={onEdit} className='text-[14px] flex items-center text-[#49C53B] cursor-pointer'>
            <img className='h-[14px] w-[14px] mr-1' src={EditSvg}  alt="icon" />
              Edit
            </div>

          </div>
          <div className={classNames(styles.itemContent, '')}   >
                  {data.introduction}
             </div>
      </div>


      {data.profileType === 'MASTER' && <>
      <div className={classNames(styles.item, '')}   >
          <div className={classNames(styles.itemTitle, '')}   >Location</div>
          <div className={classNames(styles.itemContent, '')}   >
                         {data.address}
             </div>
      </div>
      <div className={classNames(styles.item, 'pb-3')}   >
          <div className={classNames(styles.itemTitle, '')}   >Job Search Radius</div>
          <div className={classNames(styles.itemContent, '')}   > {data.radius} Miles
             </div>
      </div>
      <hr/>

        {(data?.companyName || data?.companyTaxId) && (
          <div className={classNames(  styles.itemTitleBold,'')}   >Company info</div>
          )
        }

        {data?.companyName && (
          <div className={classNames(styles.item, '')}   >
            <div className={classNames(styles.itemTitle, '')}   >Company name</div>
            <div className={classNames(styles.itemContent, '')}   >{data?.companyName}
            </div>
          </div>
        )}

        {data?.companyTaxId && (
          <div className={classNames(styles.item, 'pb-3 mt-1')}   >
            <div className={classNames(styles.itemTitle, '')}   >Company Tax ID</div>
            <div className={classNames(styles.itemContent, '')}   >{data?.companyTaxId}
            </div>
          </div>
        )}
      <hr/>
      <div className={classNames( styles.itemTitleBold,'')}   >Services and prices</div>
      <div className={classNames(styles.item, 'flex flex-wrap pb-3')}   >

            {data?.services?.map((service,index )=> {
                  const className =`bg-[${SERVICES_COLORS[index]}]`
                  return (
              <div  key={`service-${index}`}style={{
                  background:SERVICES_COLORS[index]
                }}  className={classNames(styles.card,' mb-2 ml-2')} >
                  <div  className={classNames(styles.title, '')} >
                  {SERVICES[service.service]}
                  </div>
                  <div  className={classNames(styles.text, '')} >
                        {service.hourlyRate > 0 && <>${service.hourlyRate}/hour</>}
                        {(service.hourlyRate >0 && service.flatPrice > 0) && <>|</>}
                        {service.flatPrice > 0 && <>${service.flatPrice}/job</>}
                        </div>
            </div>
                  )
            })

            }

          
      </div>
      <hr/>
      <div className={classNames( styles.itemTitleBold)} >Documents</div>
      <div className={classNames(styles.item,'flex flex-wrap')}   >
            {data?.documents?.map((document,index) => {
                 return (
                  <DocumentCard key={index} fileId={`doc-`+index} fileName={document.name}  classes=' mb-2 ml-2' size={document.sizeKB} />
                 )
            })
            }
      </div>

      </> }

  </div>
  </>
)

}

export default About;
