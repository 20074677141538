import { useEffect, useState } from 'react';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import PriceCard from 'components/PriceCard';
import classNames from 'classnames';
import useUser from 'redux/user/use-user';
import { useNavigate } from 'react-router-dom';
import DotsAndButton from 'components/DotsAndButton';

const Price = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const navigate = useNavigate();
  const [prices, setPrices] = useState<any[]>([]);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [genericPrices, setGenericPrices] = useState<{
    hourlyRate: number;
    flatPrice: number;
  }>({
    hourlyRate: masterFlowData.genericPrices?.hourlyRate || 0,
    flatPrice: masterFlowData.genericPrices?.flatPrice || 0
  });

  useEffect(() => {
    const prices = (masterFlowData?.services || [])
      .filter((x: any) => x.value)
      .map((x: any) => ({
        profession: x.profession.value,
        hourlyRate:
          masterFlowData?.prices?.find((y: any) => y.profession.value === x.profession.value)
            ?.hourlyRate || 0,
        flatPrice:
          masterFlowData?.prices?.find((y: any) => y.profession.value === x.profession.value)
            ?.flatPrice || 0
      }));
    setPrices(prices);
  }, []);

  const handleAllChange = (val: string, prof: string, price: string) => {
    const newPrices = prices.map((p: any) => {
      return {
        ...p,
        [price]: +val || 0
      };
    });
    setPrices(newPrices);
    setGenericPrices((prevState: any) => {
      return { ...prevState, [price]: +val || 0 };
    });
  };

  const handleInputChange = (val: string, prof: string, price: string) => {
    const newPrices = prices.map((p: any) => {
      if (p.profession === prof) {
        return {
          ...p,
          [price]: +val || 0
        };
      }
      return p;
    });
    setPrices(newPrices);
    // setGenericPrices((prevState: any) => {
    //   return { ...prevState, [price]: 0 };
    // });
  };

  const next = () => {
    keepBecomeMasterData({
      ...masterFlowData,
      prices,
      genericPrices: {
        hourlyRate: genericPrices.hourlyRate,
        flatPrice: genericPrices.flatPrice
      }
    });
    navigate('/become-a-master/address');
  };

  useEffect(() => {
    setNextDisabled(
      !!prices.find(x => x.hourlyRate || x.flatPrice) ||
        !!genericPrices.hourlyRate ||
        !!genericPrices.flatPrice
    );
  }, [genericPrices, prices]);

  return (
    <div className={classNames('flex flex-col items-center', styles.cont)}>
      <p className={classNames(['text-doers-black', styles.price])}>Price</p>
      <p className={classNames(styles.txt)}>
        Set the same or custom prices for the services. You will be able to negotiate the price for
        the future.
      </p>
      <div className="">
        <div className={styles.priceCardsCont}>
          <div className="mb-4">
            <PriceCard
              shadow={false}
              leftInput={{
                name: 'hr',
                value: genericPrices.hourlyRate
              }}
              rightInput={{
                name: 'fp',
                value: genericPrices.flatPrice
              }}
              onChange={handleAllChange}
            />
          </div>
          {prices.map(p => (
            <div key={p.profession} className="mb-2">
              <PriceCard
                title={p.profession}
                leftInput={{
                  name: 'hr' + p.profession,
                  value: p.hourlyRate
                }}
                rightInput={{
                  name: 'fp' + p.profession,
                  value: p.flatPrice
                }}
                onChange={handleInputChange}
              />
            </div>
          ))}
        </div>
      </div>
      <DotsAndButton>
        <DButton
          text="Next, Address"
          className="w-[304px] my-4"
          type="default"
          disabled={!nextDisabled}
          onClick={next}
        />
      </DotsAndButton>
    </div>
  );
};

export default Price;
