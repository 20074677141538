import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import useEffectAsync from '../../hooks/useEffectAsync';
import {
  fetchAddPaymentMethod,
  fetchConnectStripeAccount,
  makeStripePayment,
  fetchStripeGetPublicKey
} from 'api/transactions';
import { useEffect, useState } from 'react';
import DButton from 'components/DButton';
import './index.css';
import { useDispatch } from 'react-redux';
import { fetchGetUserPaymentsMethods } from 'redux/user/actions';
import { alert } from 'helpers';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,

      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' }
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee'
    }
  }
};

const StripeCardForm = ({ onFinishConnection, onStartConnection }: any) => {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  useEffectAsync(async () => {
    const key = await fetchStripeGetPublicKey();
    const stripeInstance = await loadStripe(key);
    setStripePromise(stripeInstance);
  }, []);

  return (
    <div className="stripeContainer">
      <Elements stripe={stripePromise}>
        <CardForm onFinishConnection={onFinishConnection} onStartConnection={onStartConnection} />
      </Elements>
    </div>
  );
};

const CardForm = ({ onFinishConnection, onStartConnection }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    onStartConnection();
    const cardElement = elements.getElement(CardNumberElement) as any;
    await fetchAddPaymentMethod({ method: 'STRIPE' }).then(async (clientSecret: any) => {
      const result: any = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement
        }
      });
      if (result.error) {
        alert.error(result.error.message);
        onFinishConnection();
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        setTimeout(() => {
          onFinishConnection();
        }, 2000);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label className="cardLabel">
          <span>Card Number</span>
          <CardNumberElement
            options={{
              showIcon: true
            }}
            id="cardNumberElement"
          />
        </label>
        <div className="flex justify-between mt-3">
          <label className="cardLabel">
            <span>Expiration Date</span>
            <CardExpiryElement id="cardExpiryElement" />
          </label>
          <label className="cardLabel">
            <span> CVC</span>
            <CardCvcElement id="cardCvcElement" />
          </label>
        </div>
        <div className="flex justify-end mt-2">
          <button id="saveBtn" type="submit" disabled={!stripe}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default StripeCardForm;
