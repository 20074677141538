import { CreateJobRequest } from 'common';
import { JobPostActionType } from './types';
import { JOB_POST } from './actionTypes';

export const initialState: CreateJobRequest = {
  title: '',
  description: '',
  location: { type: '', coordinates: [] },
  pro: false,
  address: '',
  flatPrice: undefined,
  hourlyRate: undefined,
  startDate: undefined,
  endDate: undefined,
  services: undefined,
};

export const jobPostReducer = (state = initialState, action: JobPostActionType) => {
  switch (action.type) {
    case JOB_POST:
      return { ...state, ...action.payload }; 
    default:
      return state;
  }
};
