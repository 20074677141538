import {
  JobFilterRequest,
  ProfileDoerUpdateRequest,
  ProfileMasterUpdateRequest,
  UserReviewFilterRequest,
  UserVideoFilterRequest
} from 'common';

export const USER_GET_AUTH_USER = 'USER_GET_AUTH_USER';
export const USER_GET_AUTH_USER_SUCCESS = 'USER_GET_AUTH_USER_SUCCESS';
export const USER_GET_AUTH_USER_FAILURE = 'USER_GET_AUTH_USER_FAILURE';
export const USER_KEEP_REGISTER_DATA = 'USER_KEEP_REGISTER_DATA';
export const USER_KEEP_EMAIL_FOR_RESET = 'USER_KEEP_EMAIL_FOR_RESET';
export const USER_KEEP_CODE_FOR_RESET = 'USER_KEEP_CODE_FOR_RESET';
export const KEEP_USER = 'KEEP_USER';
export const KEEP_BECOME_MASTER_DATA = 'KEEP_BECOME_MASTER_DATA';
export const GET_PROFILE_BY_ID_REQUEST = 'GET_PROFILE_BY_ID_REQUEST';
export const GET_PROFILE_BY_ID_SUCCESS = 'GET_PROFILE_BY_ID_SUCCESS';
export const GET_PROFILE_BY_ID_FAILED = 'GET_PROFILE_BY_ID_FAILED';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const UPDATE_DOER_PROFILE_REQUEST = 'UPDATE_DOER_PROFILE_REQUEST';
export const UPDATE_DOER_PROFILE_SUCCESS = 'UPDATE_DOER_PROFILE_SUCCESS';
export const UPDATE_DOER_PROFILE_FAILED = 'UPDATE_DOER_PROFILE_FAILED';

export const GET_USER_VIDEOS_REQUEST = 'GET_USER_VIDEOS_REQUEST';
export const GET_USER_VIDEOS_SUCCESS = 'GET_USER_VIDEOS_SUCCESS';
export const USER_SET_LOADING = 'USER_SET_LOADING';
export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const GET_USER_REVIEWS_REQUEST = 'GET_USER_REVIEWS_REQUEST';
export const GET_USER_REVIEWS_SUCCESS = 'GET_USER_REVIEWS_SUCCESS';

export const GET_LOGGED_IN_USER_JOBS_REQUSET = 'GET_LOGGED_IN_USER_JOBS_REQUSET';
export const GET_LOGGED_IN_USER_JOBS_SUCCESS = 'GET_LOGGED_IN_USER_JOBS_SUCCESS';
export const GET_LOGGED_IN_USER_JOBS_FAILED = 'GET_LOGGED_IN_USER_JOBS_FAILED';

export const GET_USER_PAYMENTS_METHODS_REQUEST = 'GET_USER_PAYMENTS_METHODS_REQUEST';
export const GET_USER_PAYMENTS_METHODS_SUCCESS = 'GET_USER_PAYMENTS_METHODS_SUCCESS';
export const GET_USER_PAYMENTS_METHODS_FAILED = 'GET_USER_PAYMENTS_METHODS_FAILED';

export const GET_USER_PAYIN_METHODS_REQUEST = 'GET_USER_PAYIN_METHODS_REQUEST';
export const GET_USER_PAYIN_METHODS_SUCCESS = 'GET_USER_PAYIN_METHODS_SUCCESS';
export const GET_USER_PAYIN_METHODS_FAILED = 'GET_USER_PAYIN_METHODS_FAILED';

export const DELETE_PAYMENTS_METHOD_REQUEST = 'DELETE_PAYMENTS_METHOD_REQUEST';
export const DELETE_PAYMENTS_METHOD_SUCCESS = 'DELETE_PAYMENTS_METHOD_SUCCESS';

export const SET_PAYMENT_METHOD_DEFAULT_REQUEST = 'SET_PAYMENT_METHOD_DEFAULT_REQUEST';
export const SET_PAYMENT_METHOD_DEFAULT_SUCCESS = 'SET_PAYMENT_METHOD_DEFAULT_SUCCESS';

export const DELETE_USER_ACCOUNT_REQUEST = 'DELETE_USER_ACCOUNT_REQUEST';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_FAILED = 'DELETE_USER_ACCOUNT_FAILED';

export const GET_TOTAL_PAYMENT_REQUEST = 'GET_TOTAL_PAYMENT_REQUEST';
export const GET_TOTAL_PAYMENT_SUCCESS = 'GET_TOTAL_PAYMENT_SUCCESS';

export interface FetchGetProfileByIdRequest {
  type: typeof GET_PROFILE_BY_ID_REQUEST;
  payload: { userId: string };
}

export interface FetchUpdateProfileRequest {
  type: typeof UPDATE_PROFILE_REQUEST;
  payload: { profileData: ProfileMasterUpdateRequest };
}

export interface FetchUpdateDoerProfileRequest {
  type: typeof UPDATE_DOER_PROFILE_REQUEST;
  payload: { profileData: ProfileDoerUpdateRequest };
}

export interface FetchGetUserVideosRequest {
  type: typeof GET_USER_VIDEOS_REQUEST;
  payload: UserVideoFilterRequest;
}
export interface FetchGetUserReviewsRequest {
  type: typeof GET_USER_REVIEWS_REQUEST;
  payload: { userId: string; jsonData: UserReviewFilterRequest };
}

export interface FetchDeleteVideoRequest {
  type: typeof DELETE_VIDEO_REQUEST;
  payload: { id: string };
}

export interface FetchGetLoggedInUserJobsRequest {
  type: typeof GET_LOGGED_IN_USER_JOBS_REQUSET;
  payload: JobFilterRequest;
}

export interface FetchGetPaymentsMethodsRequest {
  type: typeof GET_USER_PAYMENTS_METHODS_REQUEST;
}

export interface FetchGetPayInMethodsRequest {
  type: typeof GET_USER_PAYIN_METHODS_REQUEST;
}

export interface FetchDeletePaymentMethodRequest {
  type: typeof DELETE_PAYMENTS_METHOD_REQUEST;
  payload: { id: string };
}

export interface FetchPaymentMethodAsDefaultRequest {
  type: typeof SET_PAYMENT_METHOD_DEFAULT_REQUEST;
  payload: { id: string };
}

export interface FetchDeleteUserAccountRequest {
  type: typeof DELETE_USER_ACCOUNT_REQUEST;
  payload: { userId: string };
}
export interface FetchGetTotalPaymentRequest {
  type: typeof GET_TOTAL_PAYMENT_REQUEST;
}

export type UserActions =
  | FetchGetProfileByIdRequest
  | FetchUpdateProfileRequest
  | FetchGetUserVideosRequest
  | FetchDeleteVideoRequest
  | FetchGetUserReviewsRequest
  | FetchGetLoggedInUserJobsRequest
  | FetchGetPaymentsMethodsRequest
  | FetchDeletePaymentMethodRequest
  | FetchGetTotalPaymentRequest;
