import moment from 'moment';
import filterObj from './filterObj';
import { toast } from 'react-toastify';
import { doUploadFlow } from './documentUploadFlow';
import getDistance from './getDistance';
import uuid from 'react-uuid';

const getCurrentPositionFromBrowser = (): Promise<any> => {
  return new Promise((res, rej) => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }

    navigator.permissions
      .query({
        name: 'geolocation'
      })
      .then(
        result => {
          if (result.state == 'granted' || result.state == 'prompt') {
            navigator.geolocation.getCurrentPosition(
              success => {
                res([success.coords.longitude, success.coords.latitude]);
              },
              err => {
                rej('Pls enable location.');
              },
              {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              }
            );
          } else if (result.state == 'denied') {
            rej('Location permission is turned off');
          }
        },
        err => {
          rej(err);
        }
      );
  });
};

const calcCoordsDistance = (
  coords1: { lat: number; lng: number },
  coords2: { lat: number; lng: number },
  unit = 'K'
) => {
  if (coords1.lat == coords2.lat && coords1.lng == coords2.lng) {
    return 0;
  } else {
    var radlat1 = (Math.PI * coords1.lat) / 180;
    var radlat2 = (Math.PI * coords2.lat) / 180;
    var theta = coords1.lng - coords2.lng;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

const alert = {
  error: (message: string) => {
    return toast.error(
      <div className="flex">
        <i className="icon-3 text-icon-err text-font-26 ml-1 mr-2 mt-1" />
        <p className="mt-1 whitespace-pre-line">{message}</p>
      </div>
    );
  },
  success: (message: string) => {
    return toast.success(
      <div className="flex">
        <i className="icon-6 text-green text-font-26 ml-1 mr-2 mt-1" />
        <p className="mt-1 whitespace-pre-line">{message}</p>
      </div>
    );
  }
};

const showEndStartDate = (start: moment.MomentInput, end: moment.MomentInput) => {
  let obj: {
    both?: string;
    start?: string;
    end?: string;
  } = {
    both: '',
    start: '',
    end: ''
  };
  const startMoment = moment(start).local();
  const endMoment = moment(end).local();
  const getMonthDate = (date: moment.MomentInput) => moment(date, 'DD/MM/YYYY').startOf('month');
  const getYearDate = (date: moment.MomentInput) => moment(date, 'DD/MM/YYYY').startOf('year');

  if (start && end) {
    const monthDiff = Math.abs(endMoment.diff(getMonthDate(startMoment), 'months'));
    const yearDiff = Math.abs(endMoment.diff(getYearDate(startMoment), 'years'));

    const startDay = startMoment.format('DD');
    const endDay = endMoment.format('DD');
    const startMonth = startMoment.format('MMM');
    const endMonth = endMoment.format('MMM');
    const year = startMoment.format('YYYY');
    if (monthDiff && !yearDiff) {
      obj = {
        both: `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`
      };
    } else if (monthDiff && yearDiff) {
      obj = {
        both: `${startMoment.format('LL')} - ${endMoment.format('LL')}`
      };
    } else {
      obj = {
        both: `${startMonth} ${startDay} - ${endDay}, ${year}`
      };
    }
  } else if (start && !end) {
    obj = {
      start: startMoment.format('ll')
    };
  } else if (!start && end) {
    obj = {
      end: endMoment.format('ll')
    };
  }
  return obj;
};

const showPostedDate = (date: moment.MomentInput) => {
  const diffDays = Math.floor(
    (moment().utc()?.valueOf() - moment(date).startOf('day').valueOf()) / (1000 * 60 * 60 * 24) // one day in milliseconds
  );
  if (diffDays) {
    if (diffDays === 1) return 'Yesterday';
    return `${diffDays} days ago`;
  }
  return 'Today';
};

const showJobPrices = ({ flat, hourly, long }: any): string => {
  const hourlyText = long ? '/hour' : '/h';
  let prices = '';
  if (hourly) {
    prices = prices.concat(`$${hourly}${hourlyText}`);
  }
  if (flat && hourly) {
    prices = prices.concat(' | ');
  }
  if (flat) {
    prices = prices.concat(`$${flat}/job`);
  }
  if (!flat && !hourly) {
    prices = prices.concat('$ -');
  }
  return prices;
};

const showMasterPrices = ({ minFlat, maxFlat, minHourly, maxHourly, long }: any): string => {
  const hourlyText = long ? '/hour' : '/h';
  let prices = '';
  if (minHourly || maxHourly) {
    if (minHourly === maxHourly) {
      prices = prices.concat(`$${minHourly}${hourlyText}`);
    } else {
      prices = prices.concat(`$${minHourly}-${maxHourly}${hourlyText}`);
    }
  }
  if ((minFlat || maxFlat) && (minHourly || maxHourly)) {
    prices = prices.concat(' | ');
  }
  if (minFlat || maxFlat) {
    if (minFlat === maxFlat) {
      prices = prices.concat(`$${minFlat}/job`);
    } else {
      prices = prices.concat(`$${minFlat}-${maxFlat}/job`);
    }
  }
  if (!minFlat && !minFlat && !minHourly && !maxHourly) {
    prices = prices.concat('$ -');
  }
  return prices;
};

const asyncForEach = async (
  array: string | any[],
  callback: (arg0: any, arg1: number, arg2: any) => any
) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const toFixedUserRating = (rating?: number): number => parseFloat((rating || 0).toFixed(1));

export const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('deviceId');

  if (!deviceId) {
    deviceId = uuid();
    localStorage.setItem('deviceId', deviceId);
  }

  return deviceId;
};

const stringToHTML = (arg: string) => {
  const parser = new DOMParser(),
    DOM = parser.parseFromString(arg, "text/html");
  return DOM.body.childNodes[0] as HTMLElement;
};

export {
  getCurrentPositionFromBrowser,
  calcCoordsDistance,
  alert,
  showEndStartDate,
  filterObj,
  showPostedDate,
  showJobPrices,
  doUploadFlow,
  getDistance,
  asyncForEach,
  showMasterPrices,
  toFixedUserRating,
  stringToHTML
};
