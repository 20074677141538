import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { Radio } from 'antd';

type Props = {
  [props: string]: any;
};

const DRadio: React.FC<Props> = ({ ...props }) => {
  return (
    <div className={styles.imitiateCss}>
      <Radio {...props} />
    </div>
  );
};

export default DRadio;
