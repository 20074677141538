import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GALLERY_MODAL_CLOSE } from 'redux/modals/actionTypes';
import GallerySwiper from './GallerySwiper';

import styles from './index.module.scss';
import classNames from 'classnames';

const GalleryModal = () => {
  const dispatch = useDispatch();
  const {
    galleryModal: { show }
  } = useSelector((state: any) => state.modals);

  const close = () => {
    dispatch({
      type: GALLERY_MODAL_CLOSE
    });
  };

  return (
    <>
      {show && (
        <div onClick={close} className={classNames('icon-22 text-[#313233]', styles.closeIcon)} />
      )}
      <Modal
        wrapClassName={styles.galleryWrapper}
        className={styles.gallery}
        visible={show}
        footer={false}
        centered
        destroyOnClose
      >
        <GallerySwiper />
      </Modal>
    </>
  );
};

export default GalleryModal;
