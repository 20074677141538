/** @format */

import axios, { AxiosError } from 'axios';
import { UserItem, UserType, UserRole } from '../model/business/user';
import {
  ResetPasswordRequest,
  UserRegistrationRequest,
  SetPasswordResetCodeRequest,
  ConfirmEmailRequest,
  UpdateEmailConfirmCodeRequest,
} from '../model/business/auth';
import {
  UpdateActiveJobsRequest,
  UserResponse,
  AddUserPaymentMethodRequest,
  AddUserStripePaymentMethodRequest,
  AddJobKeywordsRequest,
  UserStatus,
} from '../model/business/user/user';
import { StatusError } from '../model/errors';
import { ProfileItem } from '../model/business/profile';
import { CreateUserReviewRequest } from '../model/business/review';

const userServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.USER_SERVICE_URL!,
  apiKey: 'testKey',
};

export const getUserListByIds = async (userIds: Array<string>): Promise<UserItem[]> =>
  getData<UserItem[]>('/list', {
    params: {
      userIds,
    },
  });

export const getUserById = async (userId: string): Promise<UserItem | null> =>
  getData<UserItem>(`/${userId}`);

export const getCurrentUserById = async (userId: string): Promise<UserItem | null> =>
  getData<UserItem>(`/current-user/${userId}`);

export const getUserByEmail = async (email: string): Promise<UserItem | null> =>
  getData<UserItem>(`/email/${email}`);

export const getProfileByUserId = async (userId: string): Promise<ProfileItem | null> =>
  getData<ProfileItem>(`/${userId}/profile`);

export const getUserByEmailAndType = async (
  email: string,
  type: UserType,
  userRole: UserRole = 'USER',
): Promise<UserItem | null> =>
  getData<UserItem>(`/email/${email}/${type}`, {
    params: {
      role: userRole,
    },
  });

export const getUserByEmailTypeAndStatus = async (
  email: string,
  type: UserType,
  statuses: UserStatus[],
  userRole: UserRole = 'USER',
): Promise<UserItem | null> =>
  getData<UserItem>(`/email/${email}/${type}`, {
    params: {
      role: userRole,
      statuses,
    },
  });

export const createUser = async (user: UserRegistrationRequest) => postData<UserItem>(`/`, user);

export const updateBackOfficeUser = async (userId:string,data:any) => postData<UserItem>(`/${userId}/update`, data);


export const setForgotPasswordCodeRequest = async (
  userId: string,
  data: SetPasswordResetCodeRequest,
) => putData<string>(`/${userId}/set-forgot-password-code`, data);

export const resetUserPassword = async (userId: string, data: ResetPasswordRequest) =>
  putData<string>(`/${userId}/reset-password`, data);

export const confirmUserEmail = async (userId: string, data: ConfirmEmailRequest) =>
  putData<string>(`/${userId}/confirm-email`, data);

export const updateConfirmEmailCode = async (userId: string, data: UpdateEmailConfirmCodeRequest) =>
  putData<string>(`/${userId}/confirm-email-code`, data);

export const getUserBySocialId = async (socialId: string): Promise<UserItem> =>
  getData<UserItem>(`/social-id/${socialId}`);

export const pushActiveJobs = async (userId: string, data: UpdateActiveJobsRequest) =>
  putData<string>(`/${userId}/push-active-jobs`, data);

export const pullActiveJobs = async (userId: string, data: UpdateActiveJobsRequest) =>
  putData<string>(`/${userId}/pull-active-jobs`, data);

export const addReviewToUser = async (userId: string, data: CreateUserReviewRequest) =>
  putData<UserResponse>(`/${userId}/add-review`, data);

export const addStripeCustomerIdToUser = async (userId: string, data: {customerId:string}) =>
  putData<string>(`/${userId}/add-stripe-customer-id`, data);

export const addStripeAccountIdToUser = async (userId: string, data: {accountId:string}) =>
  putData<string>(`/${userId}/add-stripe-account-id`, data);

     

export const addJobKeywordsToUser = async (userId: string, data: AddJobKeywordsRequest) =>
  putData<UserResponse>(`/${userId}/add-job-keywords`, data);

export const reactivateAppleAccountBySocialId = async (socialId: string) =>
  putData<UserItem>(`/${socialId}/reactivate`, {});

export const deleteProfileMediaById = async (userId: string) =>
  deleteData<void>(`/${userId}/profile-media`);

interface UserApiResponse {}

interface UserApiError {
  name: string;
  message: string;
}

export const addPaymentMethod = async (userId: string, data: AddUserPaymentMethodRequest) =>
  putData<string>(`/${userId}/add-payment-method`, data);

export const addStripePaymentMethod = async (userId: string, data: AddUserStripePaymentMethodRequest) =>
  putData<string>(`/${userId}/add-stripe-payment-method`, data);

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<UserApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};





const putData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.put<UserApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const deleteData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.delete<UserApiResponse>(getUrl(path), {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const handleHttpError = (error: AxiosError<UserApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${userServiceConfig.url}${path}`;
