import styles from './index.module.scss';
import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { showJobPrices } from 'helpers';
import { useNavigate } from 'react-router-dom';

const JobPin = ({
  onMouseOverFn,
  onMouseLeaveFn,
  id,
  pricePerHour,
  pricePerJob,
  bestMatch
}: {
  onMouseOverFn: () => void;
  onMouseLeaveFn: () => void;
  id?: string;
  pricePerHour?: number;
  pricePerJob?: number;
  lat?: number;
  lng?: number;
  bestMatch:boolean
}): JSX.Element => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const hoverContainerRef = useRef<HTMLDivElement | null>(null);
  const pinRef = useRef<HTMLDivElement | null>(null);

  const openJobDetailsModal = () => {
    navigate(`/explore/job/${id}`, {
      state: {
        job: id
      }
    });
  };


  useEffect(() => {
    if (pinRef.current && hoverContainerRef.current) {
      const pinRefWidth = pinRef.current.offsetWidth;
      hoverContainerRef.current.style.width = `${pinRefWidth}px`;
      hoverContainerRef.current.style.transform = `translateX(-${pinRefWidth / 2}px)`;
      pinRef.current.style.transform = `translateX(-${pinRefWidth / 2}px)`;
    }
  }, [pricePerHour, pricePerJob]);

  return (
    <div onClick={openJobDetailsModal}>
      <div ref={pinRef} className={classNames(styles.pin, 
        {[styles.isMaching]: bestMatch, [styles.pinHovered]: hovered })}>
        {showJobPrices({ flat: pricePerJob, hourly: pricePerHour, long: false })}
      </div>
      <div
        className={styles.container}
        ref={hoverContainerRef}
        onMouseEnter={() => {
          setHovered(true);
          onMouseOverFn();
        }}
        onMouseLeave={() => {
          setHovered(false);
          onMouseLeaveFn();
        }}
      />
    </div>
  );
};

export const JobInfo = ({
  title,
  pricePerHour,
  pricePerJob
}: {
  title: string;
  lat: number;
  lng: number;
  pricePerHour?: number;
  pricePerJob?: number;
}): JSX.Element => {


  return(
    <div className={styles.jobInfo}>
    <div className={styles.desctiption}>
      <span>{title}</span>
      <div className="font-bold text-font-13 mt-2">
        {showJobPrices({ flat: pricePerJob, hourly: pricePerHour, long: false })}
      </div>
    </div>
  </div>
  )
};

export default JobPin;
