import { useState } from 'react';
import Input from 'components/Input';
import { Form } from 'antd';
import OrDivider from 'components/OrDivider';
import DButton from 'components/DButton';
import SignInWith from '../SignInWith';
import { loginUser, resendConfirmEmailCode } from 'api/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import ResetPassContainer from '../Containers/ResetPassContainer';
import useUser from 'redux/user/use-user';
import { useDispatch } from 'react-redux';
import { CLOSE_LOGIN_MODAL } from 'redux/modals/actionTypes';
import classNames from 'classnames';
import { getDeviceId } from 'helpers';

const Login = ({ isModal }: { isModal?: boolean }) => {
  const { keepUser } = useUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keepRegUserData } = useUser();
  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const user = await loginUser({ ...values, deviceId: getDeviceId() });
      localStorage.setItem('token', user.token);

      if (user.userInfo?.pushNotificationId) {
        localStorage.setItem('pushNotificationId', user.userInfo?.pushNotificationId);
      }

      keepUser({ ...user, ...user.userInfo });
      if (user.skip) {
        navigate('/explore');
        dispatch({
          type: CLOSE_LOGIN_MODAL
        });
      } else {
        navigate('/email-verified');
      }
    } catch (error: any) {
      setLoading(false);

      if (error) {
        const errType = error.name;

        if (errType === 'USER_NOT_FOUND') {
          form.setFields([
            {
              name: 'username',
              errors: ['The email you entered isn’t connected to an account.']
            }
          ]);
        } else if (errType === 'INVALID_PASSWORD') {
          form.setFields([
            {
              name: 'password',
              errors: ['The password you’ve entered is incorrect.']
            }
          ]);
        } else if (errType === 'USER_NOT_ACTIVE') {
          const body = {
            email: values.username
          };
          (async () => {
            keepRegUserData(body);
            await resendConfirmEmailCode(body);
          })();
          navigate('/auth/email-verify');
        }
      }
    }
  };

  return (
    <ResetPassContainer isModal={isModal}>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        validateTrigger={['onBlur']}
        className={classNames(isModal ? 'mt-[44px]' : 'mt-[130px]')}
      >
        <Form.Item
          name="username"
          rules={[
            { required: true, message: 'This field is required.' },
            { type: 'email', message: 'Please enter a valid email' }
          ]}
          className="mb-2"
        >
          <Input label="Email*" size="lg" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'This field is required.' }]}>
          <Input
            label="Password*"
            size="lg"
            type="password"
            iconRender={(visible: boolean) => (visible ? 'Hide' : 'Show')}
          />
        </Form.Item>
        <div className="mt-4 mb-6 text-right">
          <DButton
            text="Forgot my password"
            type="only-text"
            className="text-doers-black text-sm font-bold"
            goTo="/auth/forgot-password"
          />
        </div>
        <DButton
          text="Login"
          type="default"
          submit
          className="w-[376px] text-sm font-semibold"
          loading={loading}
        />
        <div className="mt-8 mb-4">
          <OrDivider text="Or" />
          <p className="text-base text-center font-bold text-doers-black mt-8">Login with</p>
        </div>
        <SignInWith />
        <div className="text-center text-light-gray mt-8 font-medium">
          Don't you have an account yet?&nbsp;
          <DButton
            text="Sign up"
            type="only-text"
            className="text-green font-bold active:text-light-green"
            onClick={() => {
              navigate('/auth/register', {
                state: {
                  from: location.pathname
                }
              });
            }}
          />
        </div>
      </Form>
    </ResetPassContainer>
  );
};

export default Login;
