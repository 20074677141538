import { all } from 'redux-saga/effects';

import userSagas from './user/sagas';
import chatSagas from './chat/sagas';
import notificationSagas from './notification/sagas';
import transactionsSagas from './transactions/sagas';

export default function* rootSaga() {
  yield all([userSagas(), chatSagas(),notificationSagas(),transactionsSagas()]);
}
