import { Col, Row, Space, Table, Tabs, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import { fetchTransactionsMore, fetchTransactions } from 'redux/transactions/actions';
import { TransactionsState } from 'redux/transactions/types';
import TransactionsGreen from 'assets/icons/transactions_green.svg';
import TransactionsOrange from 'assets/icons/transactions_orange.svg';
import IncomingIcon from 'assets/icons/incoming_icon.svg';
import OutgoingIcon from 'assets/icons/outgoing_icon.svg';

import styles from './index.module.scss';
import { PaymentFilterRequest } from 'common';
import moment from 'moment';
import MaskLoader from 'components/MaskLoader';
import { fetchGetTotalPayments } from 'redux/user/actions';
const { TabPane } = Tabs;

const Transactions = () => {
  const filterInit: PaymentFilterRequest = {
    page: 1,
    limit: 30,
    type: 'IN'
  };
  const dispatch = useDispatch();
  const virtuosoTable = useRef<any>(null);
  const [activeTab, setActiveTab] = useState<string>('1');
  const [filter, setFilter] = useState<PaymentFilterRequest>(filterInit);

  const { transactions, isLoading, loadMore, loadeMoreisLoading } = useSelector(
    (state: any): TransactionsState => state.transaction
  );

  const { paymentsMethodsAmount } = useSelector((state: any): any => state.user);

  useEffect(() => {
    dispatch(fetchGetTotalPayments());
  }, []);

  useEffect(() => {
    let filterData = filter;
    if (paymentsMethodsAmount.totalInAmount === 0) {
      filterData = { ...filter, type: 'OUT' };
    }
    dispatch(fetchTransactions(filterData));
  }, [activeTab, paymentsMethodsAmount]);

  const loadMoreTransactions = () => {
    if (loadMore) {
      const page = filter.page ? filter.page + 1 : 1;
      const newFilterObj: PaymentFilterRequest = { ...filter, page };
      dispatch(fetchTransactionsMore(newFilterObj));
      setFilter(newFilterObj);
    }
  };

  const onChange = (key: string) => {
    setActiveTab(key);
    if (key === '1') {
      setFilter(filterInit);
    } else {
      setFilter({ ...filterInit, type: 'OUT' });
    }
  };

  const contentRenderer = () => {
    return (
      <TableVirtuoso
        className={classNames(styles.virtuoso, 'overflow-x-hidden ')}
        ref={virtuosoTable}
        endReached={loadMoreTransactions}
        data={transactions}
        overscan={200}
        style={{
          width: '100%',
          height: '100vh'
        }}
        // startReached={loadMoreNotifications}
        fixedHeaderContent={() => (
          <tr className={classNames(styles.tableTr, 'w-full bg-[#F4F4F4]   py-2 h-[46px]')}>
            <th className="pl-4" style={{ width: '30%' }}>
              Job title
            </th>
            <th style={{ width: '20%' }}>From</th>
            <th style={{ width: '20%' }}>Date</th>
            {/* <th >Type</th> */}
            <th style={{ width: '20%' }}>Amount</th>
          </tr>
        )}
        computeItemKey={index => index}
        itemContent={(index, item: any) => {
          let formattedDate = moment(item.createdAt).local().format('MMM DD, YYYY');
          if (moment().diff(moment(item.createdAt), 'days') < 1) {
            formattedDate = moment(item.createdAt).local().format('LT');
          }
          return (
            <>
              <td className={classNames(styles.columnJob, 'pl-4')}>{item.job?.title}</td>
              <td className={classNames(styles.columnFrom, '')}>{item.sender.fullName}</td>
              <td className={classNames(styles.columnDate, '')}>{formattedDate}</td>
              {/* <td className="text-left  text-[#18191A]">{item.type}</td> */}
              <td className={classNames(styles.columnAmount, '')}>
                <span className="font-bold">${item.amount}</span>
              </td>
            </>
          );
        }}
      />
    );
  };
  return (
    <>
      {(loadeMoreisLoading || isLoading) && <MaskLoader />}
      <Row className={classNames(styles.container, 'pt-5 ')}>
        <Col span={18} offset={3}>
          <div className="text-[#18191A] text-[22px] py-3 font-semibold">Transaction history</div>
          <div className="flex">
            <div className={classNames(styles.transactionsCard, ' ')}>
              <div className={classNames(styles.bgImage, ' ')}>
                <img className="" src={TransactionsGreen} alt="icon" />
              </div>
              <div className={classNames(styles.transactionsCardContent, ' ')}>
                <div className={classNames(styles.firstLine, ' ')}>
                  <img className="h-[35px] w-[35px]" src={IncomingIcon} alt="icon" />
                  <span className="ml-2"> Money earned </span>
                </div>
                <div className={classNames(styles.secondLine, ' ')}>
                  $ {paymentsMethodsAmount.totalInAmount}
                </div>
              </div>
            </div>

            <div className={classNames(styles.transactionsCard, 'ml-[-25px]')}>
              <div className={classNames(styles.bgImage, ' ')}>
                <img className="" src={TransactionsOrange} alt="icon" />
              </div>
              <div className={classNames(styles.transactionsCardContent, ' ')}>
                <div className={classNames(styles.firstLine, 'flex')}>
                  <img className="h-[35px] w-[35px]" src={OutgoingIcon} alt="icon" />
                  <span className="ml-2"> Money transferred </span>
                </div>
                <div className={classNames(styles.secondLine, ' ')}>
                  $ {paymentsMethodsAmount.totalOutAmount}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-[-25px]">
            <Tabs
              className={classNames('pl-3 tab text-light-gray', { bottomBorder: true })}
              defaultActiveKey="1"
              onChange={onChange}
            >
              {paymentsMethodsAmount.totalInAmount !== 0 && (
                <TabPane className={classNames(styles.tabPane)} tab={'Incoming'} key={'1'}>
                  <div className={classNames(styles.tabContent, 'mt-2')}>{contentRenderer()}</div>
                </TabPane>
              )}
              {paymentsMethodsAmount.totalOutAmount !== 0 && (
                <TabPane tab={'Outgoing'} key={'2'}>
                  <div className={classNames(styles.tabContent, 'mt-2')}>{contentRenderer()}</div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Transactions;
