import AppleLogin from 'react-apple-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import DButton from 'components/DButton';
import appleSvg from 'assets/icons/apple.svg';
import facebookSvg from 'assets/icons/fb.svg';

import { signInWith } from 'api/auth';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import GoogleLogin from './GoogleLogin';

const SignInWith = () => {
  let navigate = useNavigate();
  const { keepUser } = useUser();

  const responseFacebook = async ({ accessToken }: any) => {
    try {
      const user = await signInWith('FACEBOOK', accessToken);
      keepUser({ ...user, ...user.userInfo });
      localStorage.setItem('token', user.token);

      if (user.userInfo?.pushNotificationId) {
        localStorage.setItem('pushNotificationId', user.userInfo?.pushNotificationId);
      }

      redirectTo(user.skip);
    } catch (error) {
      console.log(error);
    }
  };

  const responseGoogle = async (access_token: string) => {
    try {
      const user = await signInWith('GOOGLE', access_token);
      keepUser({ ...user, ...user.userInfo });
      localStorage.setItem('token', user.token);

      if (user.userInfo?.pushNotificationId) {
        localStorage.setItem('pushNotificationId', user.userInfo?.pushNotificationId);
      }

      redirectTo(user.skip);
    } catch (error) {
      console.log(error);
    }
  };

  const responseApple = async (resp: any) => {
    try {
      let user;
      if (resp?.user) {
        user = {
          email: resp?.user?.email,
          firstName: resp?.user?.name?.firstName,
          lastName: resp?.user?.name?.lastName
        };
      }
      const loggedInUser = await signInWith('APPLE', resp?.authorization?.id_token, user);
      keepUser({ ...loggedInUser, ...loggedInUser.userInfo });
      localStorage.setItem('token', loggedInUser.token);

      if (loggedInUser.userInfo?.pushNotificationId) {
        localStorage.setItem('pushNotificationId', loggedInUser.userInfo?.pushNotificationId);
      }

      redirectTo(loggedInUser.skip);
    } catch (error) {
      console.log(error);
    }
  };

  const redirectTo = (skip: boolean) => {
    if (skip) {
      navigate('/explore');
    } else {
      navigate('/email-verified');
    }
  };

  return (
    <div className="flex justify-center my-4">
      <AppleLogin
        clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
        redirectURI={process.env.REACT_APP_HOSTING}
        callback={responseApple}
        responseType="code id_token"
        scope="name email"
        responseMode="form_post"
        usePopup={true}
        render={(renderProps: any) => (
          <DButton size="md" type="circle" icon={appleSvg} onClick={renderProps.onClick} />
        )}
      />
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
        fields="name,email,picture"
        callback={responseFacebook}
        render={(renderProps: any) => (
          <DButton
            size="md"
            type="circle"
            className="mx-4"
            icon={facebookSvg}
            onClick={renderProps.onClick}
          />
        )}
      />
      <GoogleLogin responseGoogle={responseGoogle} />
    </div>
  );
};

export default SignInWith;
