export const userTempRegData = state => state.user.userTempRegData;
export const emailForUserReset = state => state.user.emailForUserReset;
export const codeForUserReset = state => state.user.codeForUserReset;
export const userData = state => state.user.userData;
export const becomeMasterFlowData = state => state.user.becomeMasterFlowData;
export const userProfile = state => state.user.userProfile;
export const userVideos = state => state.user.userVideos;
export const userIsLoading = state => state.user.isLoading;
export const userReviews = state => state.user.userReviews;
export const isFaild = state => state.user.isFaild;
export const myJobs = state => state.user.myJobs;
export const userPaymentsmethods = state => state.user.userPaymentsMethods;
export const userPayInMethods = state => state.user.userPayInMethods;

export const isAccountDeleted = state => state.user.isAccountDeleted;

export const paymentsMethodsAmount = state => state.user.paymentsMethodsAmount;
