import { Skeleton } from 'antd';

const ChannelsLoading = () => {
  return (
    <>
      <Skeleton avatar active title={false} className="p-6 mt-1" />
      <Skeleton avatar active title={false} className="p-6 mt-1" />
      {/* <Skeleton avatar active title={false} className="p-6 mt-1" />
      <Skeleton avatar active title={false} className="p-6 mt-1" />
      <Skeleton avatar active title={false} className="p-6 mt-1" />
      <Skeleton avatar active title={false} className="p-6 mt-1" /> */}
    </>
  );
};

export default ChannelsLoading;
