/** @format */

import * as yup from 'yup';
import { MediaPathType } from '../media';
import { UserItem } from '../user';
import { Service, servicesList } from '../profile';

export const jobStatuses = [
  'DRAFT',
  'NEW',
  'ACTIVE',
  'READY_TO_START',
  'IN_PROGRESS',
  'PENDING',
  'ISSUE_REPORTED',
  'ISSUE_ACCEPTED',
  'FINISHED',
  'CANCELED',
  'REJECTED',
  'ADMIN_REVIEW',
  'DEACTIVATED',
  'BLOCKED',
  'DELETED',
];

export type JobStatus = typeof jobStatuses[number];

export type ReportCommentsType = {
  userId?: string;
  comment: string;
  createdAt?: string;
};

export type JobItem = {
  _id?: string;
  title: string;
  description: string;
  status?: JobStatus;
  services?: Array<Service>;
  deleted?: boolean;
  location: JobLocationType;
  pro: boolean;
  user: string;
  master?: string;
  address?: string;
  startDate?: string;
  endDate?: string;
  hourlyRate?: number;
  flatPrice?: number;
  video?: MediaPathType;
  photos?: Array<string>;
  userData?: UserItem;
  distance?: number;
  jobsPosted?: number;
  doerReviews?: number;
  doerReviewAvg?: number;
  bestMatch?: boolean;
  channelId?: string;
  contractId?: string;
  jobKeywords?: Array<string>;
  createdAt?: string;
  updatedAt?: string;
};

export type JobItemForAdmin = JobItem & {
  reported?: boolean;
  reportComments?: Array<JobUserReportedCommentType>;
};

export type JobItemLite = Pick<
  JobItem,
  | '_id'
  | 'title'
  | 'description'
  | 'address'
  | 'status'
  | 'startDate'
  | 'endDate'
  | 'hourlyRate'
  | 'flatPrice'
  | 'pro'
>;

export type JobItemMyJobsResponse = JobItemLite & {
  activeMasters?: Array<{
    masterId: string;
    jobContractId: string;
    channelId: string;
  }>;
  channelId?: string;
  contractId?: string;
};

export type JobsUsersMappedType = JobItem & { userData?: UserItem };

export type JobsUsersMappedTypeForAdmin = JobsUsersMappedType & {
  reportComments?: Array<ReportCommentsType>;
  reported?: boolean;
  reporterUserIds?: Array<string>;
};

export type JobUserReportedCommentType = {
  userId?: string;
  comment: string;
  profilePicture?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email?: string;
  createdAt?: string;
};

export type JobFilterRequest = {
  rateFrom?: number;
  rateTo?: number;
  priceFrom?: number;
  priceTo?: number;
  radius?: number;
  lat?: number;
  lng?: number;
  searchPlaceLat?: number;
  searchPlaceLng?: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  search?: string;
  from?: string;
  to?: string;
  user?: string;
  industry?: string[];
  excludeCurrentUser?: boolean;
  reported?: boolean;
};

export type JobFilterAdvancedRequest = JobFilterRequest & {
  master?: string;
  status?: JobStatus[];
};

export const JobFilterRequestSchema = yup.object().shape({
  rateFrom: yup.number().positive().optional(),
  rateTo: yup.number().positive().optional(),
  priceFrom: yup.number().positive().optional(),
  priceTo: yup.number().positive().optional(),
  radius: yup.number().positive().optional(),
  lat: yup.number().optional(),
  lng: yup.number().optional(),
  searchPlaceLat: yup.number().optional(),
  searchPlaceLng: yup.number().optional(),
  page: yup.number().positive().optional(),
  limit: yup.number().positive().optional(),
  sortBy: yup
    .string()
    .optional()
    .oneOf(['rating', 'distance', 'matching', 'createdAt', 'updatedAt']),
  sortOrder: yup.number().oneOf([1, -1]),
  search: yup.string().optional(),
  from: yup.string().optional(),
  to: yup.string().optional(),
  user: yup.string().optional(),
  industry: yup.lazy(val =>
    Array.isArray(val)
      ? yup.array().of(yup.string().oneOf(servicesList))
      : yup.string().oneOf(servicesList),
  ),
});

export const JobFilterAdvancedRequestSchema = JobFilterRequestSchema.shape({
  master: yup.string().optional(),
  status: yup.array().of(yup.string().oneOf(jobStatuses)),
  reported: yup.boolean().optional(),
});

export type JobLocationType = {
  type: string;
  coordinates: Array<number>;
};

export type JobsFilterResponse = {
  items: JobItem[];
  count: number;
};

export type JobsCountFilterResponse = {
  count: number;
};

/** Payload object for creating a job */
export type CreateJobRequest = {
  title: string;
  description?: string;
  services?: Array<Service>;
  location: JobLocationType;
  startDate?: string;
  endDate?: string;
  hourlyRate?: number;
  flatPrice?: number;
  address: string;
  pro: boolean;
};

/** Payload object for attaching media to a job */
export type AttachMediaJobRequest = {
  video: MediaPathType;
  photos?: Array<string>;
};

export const CreateJobRequestSchema = yup.object().shape({
  title: yup.string().min(1).max(255).required(),
  description: yup.string().optional(),
  services: yup.array().of(yup.string().oneOf(servicesList)),
  startDate: yup.date().nullable(true),
  endDate: yup.date().nullable(true),
  hourlyRate: yup.number().positive().nullable(true),
  flatPrice: yup.number().positive().nullable(true),
  address: yup.string().required(),
  location: yup.object().shape({
    type: yup.string().required().default('Point'),
    coordinates: yup.array(),
  }),
  pro: yup.boolean().default(false),
});

export const AttachMediaToJobRequestSchema = yup.object().shape({
  video: yup.object().required().shape({
    original: yup.string().required(),
    thumbnail: yup.string().required(),
  }),
  photos: yup.array().max(5).of(yup.string().required()),
});

/** Payload object for updating the job */
export type UpdateJobRequest = {
  title: string;
  description?: string;
  services?: Array<Service>;
  location: JobLocationType;
  startDate?: string;
  endDate?: string;
  hourlyRate?: number;
  flatPrice?: number;
  address: string;
  video: MediaPathType;
  photos?: Array<string>;
  pro: boolean;
};

export const UpdateJobRequestSchema = yup.object().shape({
  title: yup.string().min(1).max(255).required(),
  description: yup.string().optional(),
  services: yup.array().of(yup.string().oneOf(servicesList)),
  startDate: yup.date().nullable(true),
  endDate: yup.date().nullable(true),
  hourlyRate: yup.number().positive().nullable(true),
  flatPrice: yup.number().positive().nullable(true),
  address: yup.string().required(),
  location: yup.object().shape({
    type: yup.string().required().default('Point'),
    coordinates: yup.array(),
  }),
  video: yup.object().required().shape({
    original: yup.string().required(),
    thumbnail: yup.string().required(),
  }),
  photos: yup.array().max(5).of(yup.string().required()),
  pro: yup.boolean().default(false),
});

/** Payload object for applying for a job */
export type ApplyJobRequest = {
  hourlyRate?: number;
  flatPrice?: number;
  message: string;
};

export const ApplyJobRequestSchema = yup.object().shape({
  hourlyRate: yup.number().positive().nullable(true),
  flatPrice: yup.number().positive().nullable(true),
  message: yup.string().required(),
});

/** Payload object for invitation for a job */
export type InviteMasterJobRequest = {
  masterId: string;
  message: string;
  hourlyRate?: number;
  flatPrice?: number;
};

export const InviteMasterRequestSchema = yup.object().shape({
  masterId: yup.string().required(),
  message: yup.string().required(),
  hourlyRate: yup.number().positive().nullable(true),
  flatPrice: yup.number().positive().nullable(true),
});

export type UpdateUserReviewsInJobsRequest = {
  doerId: string;
  jobsPosted: number;
  doerReviews: number;
  doerReviewAvg: number;
};

export type ReportJobRequest = {
  comment?: string;
};

export const ReportJobRequestSchema = yup.object().shape({
  comment: yup.string().optional(),
});
