import { Row } from 'antd';
import classNames from 'classnames';
import styles from './Card.module.scss';

interface ICard {
  children: JSX.Element[] | JSX.Element;
  classes?: string;
}

const Card = ({ children, classes }: ICard) => {
  return <Row className={classNames(styles.container, 'p-2 flex-1', classes)}>{children}</Row>;
};

export default Card;
