import classNames from 'classnames';
import styles from './index.module.scss';
import { IIconProps } from './types';
import { Row } from 'antd';

const CircleBgIcon = ({
  circleClasses,
  innerIconClasses,
  circleColor = 'orange',
  icon = 'icon-25'
}: IIconProps) => {
  return (
    <Row
      className={classNames(
        styles.iconContainer,
        styles[circleColor],
        'w-6',
        'h-6',
        `${circleClasses}`
      )}
      align="middle"
      justify="center"
    >
      <i className={classNames(icon, 'text-font-12', 'text-white', `${innerIconClasses}`)} />
    </Row>
  );
};

export default CircleBgIcon;
