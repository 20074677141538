import { Socket } from "socket.io-client";

export const SET_SOCKET = 'SET_SOCKET';
export const SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION = 'SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION';




export interface GeneralState {
  socket: {socket:Socket} | null;
  lastAction: string | null
}
export interface SetSocketAction {
  type: typeof SET_SOCKET;
  payload: {socket:Socket} | null;
}
export interface SetLastActionBeforeConnection {
  type: typeof SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION;
  payload: {action:string,data?:any,type:string,afterAction?:any} | null;
}

export type GeneralActions =
| SetSocketAction
| SetLastActionBeforeConnection
