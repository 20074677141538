import classNames from 'classnames';
import { MASTER_FLOW_PAGES } from '../../constants';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import styles from './index.module.scss';

const DotsAndButton = ({ children }: { children: React.ReactNode }) => {
  const { masterFlowData } = useUser();
  const location = useLocation();

  return (
    <div className={styles.dotsContainer}>
      <div className={styles.dots}>
        {MASTER_FLOW_PAGES.map((p, i) => {
         const key =  p === 'account-type' ? 'accountType' : p;
          return (
            <div
            key={i}
            className={classNames({
              [styles.dot]: true,
              [styles.currentPage]:
                location.pathname === `/become-a-master/${p}` ||
                (p === 'radius' &&
                  (location.pathname === '/become-a-master/address' ||
                    location.pathname === '/become-a-master/radius')),
                  
              [styles.filled]: !!masterFlowData[key]
            })}
          ></div>
          )
        })}
      </div>
      {children}
    </div>
  );
};

export default DotsAndButton;
