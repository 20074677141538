/** @format */

import axios, { AxiosError } from 'axios';
import { ChatChannelItem } from '../model/business/chat';
import { CreateActivityRequest } from '../model/business/feed';
import { StatusError } from '../model/errors';

const feedServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.FEED_SERVICE_URL!,
  apiKey: 'testKey',
};

export const createFeedItem = async (
  userId: string,
  createActivityRequest: CreateActivityRequest,
) => postData<ChatChannelItem>(`/${userId}/create-feed-item`, createActivityRequest);

export const deleteAllVideosByUserId = async (userId: string) =>
  deleteData<void>(`/${userId}/all-videos`);

interface FeedApiResponse {}

interface FeedApiError {
  name: string;
  message: string;
}

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<FeedApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const deleteData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.delete<FeedApiResponse>(getUrl(path), {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const handleHttpError = (error: AxiosError<FeedApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${feedServiceConfig.url}${path}`;
