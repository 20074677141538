import React, { useState } from 'react';
import Input from 'components/Input';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import OrDivider from 'components/OrDivider';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import SignInWith from '../SignInWith';
import { registerUser } from 'api/auth';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import RegContainer from '../Containers/RegContainer';
import useUser from '../../../redux/user/use-user';
import has from 'lodash/has';

const Register = (): JSX.Element => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { keepRegUserData, keepUser, userTempRegData, user } = useUser();
  let navigate = useNavigate();
  const tempData = has(userTempRegData, ['firstName', 'lastName', 'email']) ? userTempRegData : {};
  const onFinish = async ({ repeatPassword, ...values }: any) => {
    try {
      setLoading(true);
      const regedUserData = await registerUser(values);
      keepUser({ ...user, ...regedUserData });
      keepRegUserData(values);
      navigate('/auth/email-verify');
    } catch (error: any) {
      if (error) {
        if (error.name === 'EMAIL_ALREADY_EXISTS') {
          form.setFields([
            {
              name: 'email',
              errors: ['The email already exists.']
            }
          ]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegContainer>
      <div className="mt-[115px]">
        <Form onFinish={onFinish} autoComplete="off" form={form} validateTrigger={['onBlur']}>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'This field is required.' }]}
            className="mb-2"
            initialValue={tempData.firstName}
          >
            <Input label="First Name*" size="lg" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'This field is required.' }]}
            className="mb-2"
            initialValue={tempData.lastName}
          >
            <Input label="Last Name*" size="lg" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'This field is required.' },
              { type: 'email', message: 'Please enter a valid email.' }
            ]}
            className="mb-2"
            initialValue={tempData.email}
          >
            <Input label="Email*" size="lg" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'This field is required.' },
              { min: 6, message: 'Password must contain 6 characters.' },
              { max: 60, message: 'Password must be no longer than 60 characters.' }
            ]}
            className="mb-2"
          >
            <Input
              label="Password (6-60 characters)*"
              size="lg"
              type="password"
              iconRender={(visible: boolean) => (visible ? 'Hide' : 'Show')}
            />
          </Form.Item>
          <Form.Item
            name="repeatPassword"
            dependencies={['password']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              { min: 6, message: 'Password must contain 6 characters.' },
              { max: 60, message: 'Password must be no longer than 60 characters.' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                }
              })
            ]}
          >
            <Input
              label="Repeat Password*"
              size="lg"
              type="password"
              iconRender={(visible: boolean) => (visible ? 'Hide' : 'Show')}
            />
          </Form.Item>
          <p
            className={classNames([
              'text-xs my-4 w-[376px] text-center',
              styles.termsAndConditions
            ])}
          >
            By signing up you agree with our{' '}
            <Link className="underline" to="/terms-of-conditions">
              Terms of Conditions
            </Link>{' '}
            and{' '}
            <Link className="underline" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>
          <DButton
            text="Sign Up"
            type="default"
            className="w-[376px] text-sm font-semibold"
            loading={loading}
            submit
          />
          <div className="mt-8 mb-4">
            <OrDivider text="Or" />
            <p className="text-base text-center font-bold text-doers-black mt-8">Sign up with</p>
          </div>
          <SignInWith />
          <div className="text-center text-light-gray mt-8 font-medium">
            Already have an account?&nbsp;
            <DButton
              text="Login"
              type="only-text"
              className="text-green font-bold active:text-light-green"
              goTo="/auth/login"
            />
          </div>
        </Form>
      </div>
    </RegContainer>
  );
};

export default Register;
