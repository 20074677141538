import { useState } from 'react';
import DButton from 'components/DButton';
import areas from 'assets/images/areas.svg';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { Select } from 'antd';
import DotsAndButton from 'components/DotsAndButton';

const { Option } = Select;
const radiusArr = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: 'Nationwide', value: 'nationwide' }
];

const Radius = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const [radius, setRadius] = useState(masterFlowData.radius || 1);
  const navigate = useNavigate();

  const next = () => {
    keepBecomeMasterData({
      ...masterFlowData,
      radius
    });
    navigate('/become-a-master/video');
  };

  return (
    <div className="flex flex-col items-center w-[376px]">
      <div className="w-[120px] h-[120px]">
        <img src={areas} alt="areas" />
      </div>
      <p className={styles.title}>Search Radius</p>
      <p className={styles.txt}>
        Lorem ipsum text. You will be able to change search radius whenever you will need.
      </p>
      <div>
        <div className="font-semibold text-font-12 flex items-center mb-2">
          <i className="icon-18 font-semibold text-font-18 mx-2"></i>
          Search Radius
        </div>
        <Select
          className="w-[376px]"
          value={radius}
          onChange={setRadius}
          placeholder="Select Radius"
          suffixIcon={<i className="text-black icon-20" />}
        >
          {radiusArr.map(r => (
            <Option className="capitalize" key={r.value} value={r.value}>{`${r.label} ${
              r.value === 'nationwide' ? '' : 'Miles'
            }`}</Option>
          ))}
        </Select>
      </div>
      <DotsAndButton>
        <DButton text="Next, Profile Video" className={styles.next} type="default" onClick={next} />
      </DotsAndButton>
    </div>
  );
};

export default Radius;
