/** @format */

import * as yup from 'yup';
import { ProfileType, ScalabilityType, LocationType, ServicePrice, AccountType } from '../profile';
import { MediaPathType, MediaLiteItem, mediaTypes } from '../media';
import { PaymentMethodType } from '../payment';
export type UserRole = 'ADMIN' | 'USER' | 'API_KEY';
export type AdminRole = 'SUPER_ADMIN' | 'ADMIN' | 'MANAGER' | 'SUPPORT';
export type UserStatus = 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'BLOCKED' | 'DELETED';
export type UserType = 'EMAIL' | 'FB' | 'GOOGLE' | 'APPLE';

export type UserItem = {
  _id?: string;
  email?: string;
  username: string;
  password?: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
  role: UserRole;
  adminRole?: AdminRole;
  socialId?: string;
  type: UserType;
  accountType?: AccountType;
  companyName?: string;
  companyTaxId?: string;
  passwordToken?: string;
  confirmToken?: string;
  skip: boolean;
  jobsPosted: number;
  doerReviews: number;
  doerReviewAvg: number;
  jobsCompleted: number;
  masterReviews: number;
  masterReviewAvg: number;
  introduction?: string;
  profileType?: ProfileType;
  hourlyRate: number;
  flatPrice: number;
  services: Array<ServicePrice>;
  minHourlyRate: number;
  maxHourlyRate: number;
  minFlatPrice: number;
  maxFlatPrice: number;
  scalability?: ScalabilityType;
  address?: string;
  zipCode?: string;
  location?: LocationType;
  radius: number;
  pro: boolean;
  bestMatch: boolean;
  fullName: string;
  profilePicture?: string;
  mediaPath?: MediaPathType;
  documents?: Array<MediaLiteItem>;
  documentsChecked: boolean;
  licenseVerified: boolean;
  activeJobs?: Array<{
    jobId: string;
    jobContractId: string;
    channelId: string;
  }>;
  lang: string;
  pushNotificationId?: string;
  followers?: Array<string>;
  following?: Array<string>;
  totalFollowers?: number;
  totalFollowing?: number;
  paypalPaymentMethodId: string;
  stripeCustomerId: string;
  stripeAccountId: string;
  createdAt?: string;
  updatedAt?: string;
};

export type UserResponse = Omit<
  UserItem,
  'password' | 'socialId' | 'passwordToken' | 'confirmToken'
>;

export type UserLiteResponse = Pick<
  UserItem,
  | '_id'
  | 'email'
  | 'username'
  | 'fullName'
  | 'firstName'
  | 'lastName'
  | 'status'
  | 'role'
  | 'adminRole'
  | 'type'
  | 'accountType'
  | 'profileType'
  | 'jobsPosted'
  | 'doerReviews'
  | 'doerReviewAvg'
  | 'jobsCompleted'
  | 'masterReviews'
  | 'masterReviewAvg'
  | 'pro'
  | 'bestMatch'
  | 'profilePicture'
  | 'lang'
  | 'pushNotificationId'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserItemSecureResponse = Omit<UserItem, 'password' | 'passwordToken' | 'confirmToken'>;

export type UsersFilterResponse = {
  items: UserResponse[];
  count: number;
};

export type UsersCountFilterResponse = {
  count: number;
};

export type UpdateActiveJobsRequest = {
  jobId: string;
  jobContractId: string;
  channelId: string;
};

export type AddUserPaymentMethodRequest = {
  type: PaymentMethodType;
  token: string;
};

export type AddUserStripePaymentMethodRequest = {
  type: PaymentMethodType;
  customerId: string;
};

export type UserPaymentMethod = {
  type: PaymentMethodType;
  token: string;
  identifier: string;
};

export type AddJobKeywordsRequest = {
  jobKeywords: Array<string>;
};

export const ReportProblemRequestSchema = yup.object().shape({
  feedback: yup.string().optional(),
  files: yup
    .array()
    .max(5)
    .of(
      yup
        .object()
        .required()
        .shape({
          path: yup.string().required(),
          type: yup.string().oneOf(mediaTypes).required(),
        }),
    ),
});

/** Payload object for reporting a problem */
export type ReportProblemRequest = {
  feedback?: string;
  files: Array<MediaLiteItem>;
};
