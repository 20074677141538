import { getAddressFromCoords } from 'api/generic';
import { ATLANTA_LOCATION } from '../constants';
import { getCurrentPositionFromBrowser } from 'helpers';
import { alert } from 'helpers';

const getUserLocationInfo = async (user: any) => {
  let { lng, lat, address } = ATLANTA_LOCATION;
  let enabled: boolean = false;
  if (user?.profileType === 'MASTER') {
    [lng, lat] = user.location.coordinates;
    const addresses = await getAddressFromCoords([lng, lat]);
    address = addresses[0].formatted_address;
  } else {
    try {
      const res = await getCurrentPositionFromBrowser();
      enabled = true;
      [lng, lat] = res;
      const addresses = await getAddressFromCoords([lng, lat]);
      address = addresses[0].formatted_address;
    } catch (err) {
      alert.error(`${err}`);
    }
  }
  return { lng, lat, address, enabled };
};

export default getUserLocationInfo;
