import { Col, Image, ImageProps } from 'antd';
import classNames from 'classnames';
import Video from 'components/Video';
import { memo } from 'react';
import styles from '../index.module.scss';

interface IProps {
  src?: string;
  alt?: string;
  preview?: ImageProps['preview'];
  width?: number;
  height?: number;
  className?: string;
  mediaType?: string;
  videoPlayerIcon?: boolean;
}

const MessageMediaCard = memo(function MessageMediaCard({
  src,
  alt,
  preview,
  width = 50,
  height = 50,
  className,
  mediaType,
  videoPlayerIcon = false
}: IProps) {
  return (
    <Col
      className={classNames(
        'overflow-hidden rounded-[8px]',
        styles.messageMediaCardContainer,
        className
      )}
      style={{ width, height }}
    >
      {mediaType === 'IMAGE' || mediaType === 'PHOTO' ? (
        <Image
          src={src}
          alt={alt}
          loading="lazy"
          preview={
            preview
              ? {
                  maskClassName: styles.imagePreviewMask
                }
              : false
          }
          wrapperClassName={styles.imageContainer}
          style={{ height }}
        />
      ) : (
        <Video
          blob={src}
          size={'message'}
          thumb={src}
          classes={styles.videoContainer}
          playIcon={videoPlayerIcon}
        />
      )}
    </Col>
  );
});

export default MessageMediaCard;
