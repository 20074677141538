import { useState } from 'react';
import { Typography, Col, Row, Space, Checkbox } from 'antd';
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import DateRangePicker from 'components/MainLayout/Filters/content/DateRangePicker';
import SearchPlace from 'components/SearchPlace';
import TextWithIcon from './TextWithIcon';
import { dateStateType } from 'types/types';
import JobServiceCard from './JobServiceCard';
import classNames from 'classnames';
import DButton from 'components/DButton';
import UploadFiles from './UploadFiles';
import { IJobPostModal } from './types';
import { UploadChangeParam } from 'antd/lib/upload';
import useJobPost from 'redux/jobPost/use-job-post';
import { MediaPathType, Service, CreateJobRequest, servicesList } from 'common';
import {
  filterObj,
  getCurrentPositionFromBrowser,
  asyncForEach,
  doUploadFlow,
  alert
} from 'helpers';
import { attachMediaToJob, postJob } from '../../api/jobs';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { getAddressFromCoords } from 'api/generic';
import { SERVICES } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import {setLastAction} from "../../redux/general/actions";

const { Text } = Typography;

const JobPostModal = ({ onCancelHandle }: IJobPostModal) => {

  const { lastAction} = useSelector((state: any) => state.general);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      video: false,
      location: '',
      pricePerHour: '',
      fixedPrice: ''
    }
  });

  const { jobData, reset: resetFilters, changeJobData } = useJobPost();
  const [imageList, setImageList] = useState<any[]>([]);
  const [videoList, setVideoList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [serachValueInput, setSearchValueInput] = useState('');
  const [flatPrice, setFlatPrice] = useState('');
  const [perHourPrice, setPerHourPrice] = useState('');

  const onImgUploadChangeHandle = (info: UploadChangeParam) => {
    setImageList(info.fileList);
  };

  const onImgDeleteHandle = (itemIndex: number) => {
    const newImageList = imageList.filter((item, index) => itemIndex !== index);
    setImageList(newImageList);
  };

  const onVidUploadHandle = (info: UploadChangeParam) => {
    setValue('video', true);
    trigger('video');
    setVideoList(info.fileList);
  };

  const resetState = () => {
    resetFilters();
    onCancelHandle();
    reset();
    setVideoList([]);
    setImageList([]);
    setLoading(false);
  };

  const onVidDeleteHandle = (itemIndex: number) => {
    const newVideoList = videoList.filter((item, index) => itemIndex !== index);
    setVideoList(newVideoList);
    setValue('video', false);
    trigger('video');
  };

  const onJobTypeClickHandle = (jobService: Service) => {
    let newSelectedServices: Service[];
    const arrayOfIds = jobData.services ? jobData.services.map(val => val) : [];
    if (arrayOfIds.includes(jobService) && jobData.services) {
      newSelectedServices = jobData.services.filter(item => item !== jobService);
    } else if (jobData.services) {
      newSelectedServices = [...jobData.services, jobService];
    } else {
      newSelectedServices = [jobService];
    }

    changeJobData({ ...jobData, services: newSelectedServices });
  };
  const findMyPlace = async () => {
    try {
      const res = await getCurrentPositionFromBrowser();
      const [lng, lat] = res;
      const addresses = await getAddressFromCoords([lng, lat]);
      let address = addresses[0].formatted_address;
      setSearchValueInput(address);
      setValue('location', address);
      trigger('location');
      changeJobData({
        ...jobData,
        address: address,
        location: { type: 'Point', coordinates: [lng, lat] }
      });
    } catch (err) {
      alert.error(`${err}`);
    }
  };

  const onSubmitHandle = async () => {
    const payload: CreateJobRequest = filterObj(
      {
        ...jobData,
        description: jobData.description?.trim()
      },
      [0, undefined, '', null]
    );

    try {

      const job = await postJob(payload);

      let videoPaths: MediaPathType = {
        original: '',
        thumbnail: ''
      };

      const video = videoList[0];
      setLoading(true);
      if (video) {
        const { path } = await doUploadFlow(video, 'VIDEO', 'JOB', job._id);
        const ext = path.split('.')[1];
        videoPaths = {
          original: path,
          thumbnail: path
            .replace('video', 'photo')
            .replace('original', '270x310')
            .replace(ext, 'png')
        };
      }

      const photosPaths: string[] = [];
      await asyncForEach(imageList, async doc => {
        const { path } = await doUploadFlow(doc, 'PHOTO', 'JOB', job._id);
        photosPaths.push(path);
      });
      await attachMediaToJob(job._id, {
        video: videoPaths,
        photos: photosPaths
      });
      resetState();
      if(lastAction){
        dispatch({
          type:lastAction.action,
          payload:lastAction.data
        })
        dispatch(setLastAction(null))
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Col className="pt-7 pl-[47px] pr-12 pb-10">
      <Col>
        <Row justify="center">
          <Text className="text-font-22 font-bold">Post a Job</Text>
        </Row>
      </Col>
      <Col className="mt-10">
        <TextWithIcon text="What job do you have?" classes="ml-[4px]" />
        <Row justify="center" className="mt-3">
          <Controller
            name="title"
            control={control}
            rules={{
              required: 'This field is required',
              maxLength: { value: 256, message: 'Max length is 256 characters' },
              pattern: { value: /(.|\s)*\S(.|\s)*/, message: 'This field cannot be empty' }
            }}
            render={({ fieldState: { error } }) => (
              <CustomInput
                placeholder="Job Title"
                allowedInput="string"
                value={jobData.title}
                onChange={value => {
                  if (value.length < 256) {
                    setValue('title', value);
                    trigger('title');
                    changeJobData({ ...jobData, title: value });
                  }
                }}
                className={classNames('w-[505px]', {
                  'border border-icon-err focus-within:border-icon-err hover:border-icon-err': error
                })}
              />
            )}
          />
          <Row className="w-[505px]" justify="start">
            {errors.title && (
              <Text className="text-icon-err mt-2 ml-5">{errors.title.message}</Text>
            )}
          </Row>
        </Row>
      </Col>
      <Col className="mt-6">
        <TextWithIcon
          text="Attach a video describing/showing what needs to be done"
          classes="ml-1"
        />
      </Col>
      <Col className="mt-3 w-full">
        <Row justify="center">
          <Controller
            name="video"
            control={control}
            rules={{ required: 'This field is required' }}
            render={() => (
              <UploadFiles
                className="max-w-[505px] !w-full"
                filesList={videoList}
                error={errors.video?.message ? true : false}
                maxUploadedFiles={1}
                uploadingFilesType="video"
                onUploadHandle={onVidUploadHandle}
                onDeleteHandle={onVidDeleteHandle}
              />
            )}
          />
          <Row className="w-[505px]" justify="start">
            {errors.video && (
              <Text className="text-icon-err mt-2 ml-5">{errors.video.message}</Text>
            )}
          </Row>
        </Row>
      </Col>
      <Col className="mt-6">
        <TextWithIcon icon={false} text="Upload any additional photos (up to 4)" classes="ml-5" />
        <Row justify="center" className="mt-4 w-full">
          <UploadFiles
            className="max-w-[505px] !w-full"
            filesList={imageList}
            maxUploadedFiles={4}
            uploadingFilesType="image"
            multipleSelect={true}
            onUploadHandle={onImgUploadChangeHandle}
            onDeleteHandle={onImgDeleteHandle}
          />
        </Row>
      </Col>
      <Col className="mt-6 ml-[20px]">
        <TextWithIcon
          icon={false}
          classes="ml-[2px]"
          text="Add anything that will help us match the master for your job"
        />
      </Col>
      <Col className="mt-3">
        <Row justify="center">
          <CustomTextArea
            value={jobData.description}
            onChangeValue={value => {
              changeJobData({ ...jobData, description: value });
            }}
            classes="w-[505px] h-[130px]"
            placeholder="Add job details"
          />
        </Row>
      </Col>
      <Col className="mt-6">
        <TextWithIcon text="Job location" classes="ml-[4px]" />
        <Row justify="center">
          <Controller
            name="location"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ fieldState: { error } }) => (
              <SearchPlace
                onChange={(e: string) => {
                  if (jobData.location.coordinates.length > 0) {
                    setValue('location', '');
                    trigger('location');
                  }
                }}
                value={serachValueInput}
                setValue={setSearchValueInput}
                classes={'w-[505px] mt-3'}
                inputClasses={classNames({
                  'border !border-icon-err focus-within:border-icon-err hover:border-icon-err':
                    error
                })}
                findMyPlace={findMyPlace}
                onLocationChangeHandle={(lat, lng, address) => {
                  setTimeout(() => {
                    setValue('location', address);
                    trigger('location');
                    changeJobData({
                      ...jobData,
                      address,
                      location: { type: 'Point', coordinates: [lng, lat] }
                    });
                  }, 100);
                }}
              />
            )}
          />
          <Row className="w-[505px]" justify="start">
            {errors.location && (
              <Text className="text-icon-err mt-2 ml-5">{errors.location.message}</Text>
            )}
          </Row>
        </Row>
      </Col>
      <Col className="mt-6 ml-[20px]">
        <TextWithIcon
          icon={false}
          text="When do you want master to start the job?"
          classes="ml-[2px]"
        />
        <Col className="mt-3 w-[240px]">
          <DateRangePicker
            classes={classNames('w-[400px] ml-2')}
            dateValues={[
              jobData.startDate ? moment(jobData.startDate) : null,
              jobData.endDate ? moment(jobData.endDate) : null
            ]}
            setDateValues={(date: dateStateType) => {
              changeJobData({
                ...jobData,
                startDate: moment(date[0])?.startOf('day')?.toISOString(),
                endDate: moment(date[1])?.endOf('day')?.toISOString()
              });
            }}
          />
        </Col>
      </Col>
      <Col className="mt-6 ml-[20px]">
        <TextWithIcon
          icon={false}
          text="Please select the price per hour or fixed"
          classes="ml-[2px]"
        />
        <Space direction="horizontal" size={24} className={'ml-2 mt-3'}>
          <CustomInput
            allowedInput={/^(\d+\.?(\d+)?)?$/gm}
            className="w-[188px]"
            suffixIcon={<div className="mr-1">$</div>}
            placeholder="Price per hour"
            value={perHourPrice}
            onChange={value => {
              setPerHourPrice(value);
              changeJobData({ ...jobData, hourlyRate: Number(value) });
            }}
          />
          <CustomInput
            allowedInput={/^(\d+\.?(\d+)?)?$/gm}
            className="w-[188px]"
            suffixIcon={<div className="mr-1">$</div>}
            placeholder={'Price per job'}
            value={flatPrice}
            onChange={value => {
              setFlatPrice(value);
              changeJobData({ ...jobData, flatPrice: Number(value) });
            }}
          />
        </Space>
      </Col>
      <Col className="mt-6 ml-[20px]">
        <TextWithIcon icon={false} text="What industry master do you need?" classes="ml-[2px]" />
        <Row className="ml-2 mt-3">
          {servicesList.map((item, i) => {
            return (
              <JobServiceCard
                classes="mr-3 mt-3"
                onClick={() => {
                  onJobTypeClickHandle(item);
                }}
                key={i}
                title={SERVICES[item]}
              />
            );
          })}
        </Row>
      </Col>
      <Col className="mt-8 ml-[30px]">
        <Row
          align="middle"
          onClick={e => {
            changeJobData({ ...jobData, pro: !jobData.pro });
          }}
          className="cursor-pointer inline-flex"
        >
          <Checkbox checked={jobData.pro} />
          <Row className="ml-4 text-font-16">
            <Space direction="horizontal" size={4}>
              <Text className="text-doers-black font-semibold">I’m looking for a</Text>
              <Text className="font-bold">Pro Master</Text>
            </Space>
          </Row>
        </Row>
      </Col>
      <Col className="mt-10 ml-[30px]">
        <Row justify="end">
          <Space size={16}>
            <DButton
              type="white"
              className="!w-[148px] !h-[44px]"
              onClick={() => {
                resetState();
                dispatch(setLastAction(null))
              }}
            >
              Cancel
            </DButton>
            <DButton
              type="light-green"
              className="text-font-16 font-bold text-white w-[200px] h-[44px]"
              onClick={handleSubmit(onSubmitHandle)}
              submit
              loading={loading}
            >
              Post the job
            </DButton>
          </Space>
        </Row>
      </Col>
    </Col>
  );
};

export default JobPostModal;
