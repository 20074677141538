import { Checkbox, Divider, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState, useEffect } from 'react';
import styles from './Industry.module.scss';

const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;

interface IIndustry {
  options: { label: string; value: string }[];
  checkedValues: string[];
  appliedValues: string[];
  setCheckedValues: (list: string[]) => void;
}

const Industry = ({
  options,
  checkedValues,
  appliedValues,
  setCheckedValues
}: IIndustry): JSX.Element => {
  const [checkAll, setCheckAll] = useState(appliedValues.length === options.length);

  const onChange = (list: any) => {
    setCheckedValues(list);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedValues(e.target.checked ? options.map(x => x.value) : []);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    if (!checkedValues.length) {
      setCheckAll(false);
    }
  }, [checkedValues]);

  return (
    <div>
      <div className={styles.col}>
        <Text className={styles.title}>Industry</Text>
        {/*<Checkbox className={styles.checkbox} onChange={onCheckAllChange} checked={checkAll}>*/}
        {/*  Select All Industries*/}
        {/*</Checkbox>*/}
      </div>
      <Divider className={styles.divider} />
      <div className={styles.servicesList}>
        <CheckboxGroup
          options={options}
          className={styles.checkboxGroup}
          value={checkedValues}
          onChange={onChange}
        ></CheckboxGroup>
      </div>
    </div>
  );
};

export default Industry;
