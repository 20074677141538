import { useDispatch, useSelector } from 'react-redux';
import { googleMapZoomAction } from './actions';
import { googleMapZoomSelector } from './selectors';
import { GoogleMapZoomState } from './types';

const useGoogleMapZoom = () => {
  const dispatch = useDispatch();
  const { zoom }: GoogleMapZoomState = useSelector(googleMapZoomSelector);

  function changeZoom(zoom: GoogleMapZoomState) {
    dispatch(googleMapZoomAction(zoom));
  }
  return {
    zoom,
    changeZoom
  };
};

export default useGoogleMapZoom;
