import styles from './index.module.scss';
import { FC } from 'react';
import classNames from 'classnames';

type Props = {
  count?: number;
  type?: string;
};

const types: any = {
  master: {
    color1: '#ff8e67',
    color2: '#ff6f3d'
  },
  doer: {
    color1: '#49C53B',
    color2: '#38A12C'
  },
  default: {
    color1: '#49C53B',
    color2: '#49C53B'
  }
};

const Pin: FC<Props> = ({ count, type = 'default' }) => {
  return (
    <div className={classNames(styles.pin)}>
      {!!count && <div className={styles.count}>{count}</div>}
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="45.673" viewBox="0 0 34 45.673">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0"  stopColor={types[type].color1} />
            <stop offset="1" stopColor={types[type].color2} />
          </linearGradient>
        </defs>
        <g id="Group_2752" data-name="Group 2752" transform="translate(-207 -326.001)">
          <g id="Group_962" data-name="Group 962" transform="translate(208 327.001)">
            <path
              id="Icon_material-location-on"
              data-name="Icon material-location-on"
              d="M23.5,3a15.988,15.988,0,0,0-16,16c0,12,16,27.29,16,27.29S39.5,31,39.5,19A15.988,15.988,0,0,0,23.5,3Zm0,21.714A5.714,5.714,0,1,1,29.214,19,5.716,5.716,0,0,1,23.5,24.714Z"
              transform="translate(-7.5 -3)"
              stroke="transparent"
              strokeWidth="2"
              fill="url(#linear-gradient)"
            />
            <ellipse
              id="Ellipse_80"
              data-name="Ellipse 80"
              cx="11.274"
              cy="11.477"
              rx="11.274"
              ry="11.477"
              transform="translate(4.726 4.825)"
              fill="#fff"
            />
            <circle
              id="Ellipse_81"
              data-name="Ellipse 81"
              cx="7.426"
              cy="7.426"
              r="7.426"
              transform="translate(8.574 8.708)"
              fill="url(#linear-gradient)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Pin;
