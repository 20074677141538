import React from 'react';
import DButton from 'components/DButton';
import { Link } from 'react-router-dom';
import IconBack from 'assets/icons/icon-arrow-back.png';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';

const RegContainer = ({ children }: { children: JSX.Element }) => {
  const { pathname, state }: any = useLocation();
  let goTo = '';
  switch (pathname) {
    case '/auth/email-verify':
      goTo = '/auth/register';
      break;
    case '/auth/register':
      goTo = state?.from ? state?.from : '/auth';
      break;
    default:
      console.log('Sorry?!');
  }
  return (
    <div className="relative flex-1 h-full">
      {pathname !== '/email-verified' && (
        <div className="absolute top-[7%] left-[16px]">
          <DButton size={'sm'} type={'circle'} icon={IconBack} goTo={goTo} />
        </div>
      )}
      <header className={styles.logo}>
        <Link to="/" className="text-xl font-bold text-dark-green text-xl">
          Doers
        </Link>
      </header>
      <div className="flex flex-1 items-center justify-center h-full">{children}</div>
    </div>
  );
};

export default RegContainer;
