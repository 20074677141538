import * as types from './types';

// export const getAuthUserAction = () => ({
//   type: types.USER_GET_AUTH_USER
// });

export const changeFiltersAction = payload => ({
  type: types.DASHBOARD_CHANGE_FILTERS,
  payload
});
