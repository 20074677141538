import api from './index';
import {
  AddPaymentMethodRequest,
  PaymentFilterRequest,
  PaymentMethodPurpose,
  PaymentsFilterResponse,
  UserPaymentMethod
} from 'common';
import qs from 'qs';

export const getTransactions = (
  jsonData?: PaymentFilterRequest
): Promise<PaymentsFilterResponse> => {
  return api.get(`/payments`, {
    params: jsonData,
    paramsSerializer: jsonData => {
      return qs.stringify(jsonData);
    }
  });
};

export const fetchSetupToken = (): Promise<string> => {
  return api.post(`/paypal/payment-method/setup-token?clientApp=web`);
};

export const makeStripePayment = (): Promise<string> => {
  return api.post(`/payments/make-stripe-payment`);
};

export const fetchStripeGetPublicKey = async (): Promise<string> => {
  return api.get(`/stripe/get-key`);
};

export const fetchStripeCreateIntent = (): Promise<string> => {
  return api.post(`/stripe/payment-method/create-payment-intent`);
};

export const approvePaymentToken = (jsonData?: any): Promise<any> => {
  return api.get(`/paypal/payment-method/approve-token`, {
    params: jsonData,
    paramsSerializer: jsonData => {
      return qs.stringify(jsonData);
    }
  });
};

export const fetchAddPaymentMethod = (data: AddPaymentMethodRequest): Promise<string> => {
  return api.post(`/payments/add-payment-method`, data);
};

export const fetchConnectStripeAccount = (): Promise<string> => {
  return api.get(`/stripe/connect-stripe-account?clientApp=web`);
};

export const getUserPaymentMethods = (): Promise<UserPaymentMethod[]> => {
  return api.get(`/payments/user-payment-methods`);
};

export const getUserPayInMethods = (): Promise<UserPaymentMethod[]> => {
  return api.get(`/payments/user-payin-methods`);
};
// export const deletePaymentMethod = (token:string): Promise<string> => {
//   return  api.delete(`/paypal/payment-method/${token}`);
// };

export const deletePaymentMethod = (id: string): Promise<string> => {
  return api.delete(`/payments/${id}/delete-payment-method`);
};

export const setPaymentMethodDefault = ({
  id,
  purpose
}: {
  id: string;
  purpose: PaymentMethodPurpose;
}): Promise<string> => {
  return api.put(`/payments/${id}/set-payment-method-as-default`, { purpose });
};

export const getTotalPayments = (): Promise<string> => {
  return api.get(`/payments/total-payments`);
};
