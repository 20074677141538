import classNames from 'classnames';
import React, { RefObject, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import styles from './index.module.scss';
import DButton from 'components/DButton';

type Props = {
  api?: (val: string) => void; // sould be changed to required
  placeholder: string;
  className?: string;
  content: JSX.Element;
  onOutsideClick?: () => void;
  apply?: () => void;
  reset?: () => void;
  values?: string;
};

const FilterInput: React.FC<Props> = ({
  api,
  placeholder,
  className = '',
  content,
  apply,
  reset,
  values,
  onOutsideClick
}): JSX.Element => {
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const [active, setActive] = useState(false);
  useClickOutside(ref, () => {
    onOutsideClick && onOutsideClick();
    active && setActive(false);
  });

  const applyAndClose = () => {
    apply && apply();
    setActive(false);
  };

  const resetAndClose = () => {
    reset && reset();
    setActive(false);
  };

  const openContent = (e: any) => {
    if (e.target == ref.current || e.target == ref.current?.firstChild) {
      setActive(true);
    }
  };

  return (
    <div
      ref={ref}
      className={classNames({
        [className]: true,
        [styles.filterInput]: true,
        [styles.active]: active
      })}
      onClick={openContent}
    >
      <span className={classNames([styles.placeholder, values && styles.values])}>
        {values || placeholder}
      </span>
      {active && (
        <div className={classNames({ active: styles.active, [styles.content]: true })}>
          <div className={styles.top}>{content}</div>
          <div className={styles.bottom}>
            {reset && (
              <DButton
                text="Reset"
                type="only-text"
                className="font-bold"
                onClick={resetAndClose}
              />
            )}
            {apply && (
              <DButton text="Apply" className="h-[44px]" type="default" onClick={applyAndClose} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default FilterInput;
