import { Typography, Row } from 'antd';

const { Text } = Typography;

const RequiredIcon = () => {
  return (
    <Row justify="center" className="bg-orange w-[18px] h-[18px]">
      <Text className="text-font-base text-white">*</Text>
    </Row>
  );
};

export default RequiredIcon;
