import { SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION, SET_SOCKET, SetSocketAction, SetLastActionBeforeConnection } from "./types";
import { Socket } from 'socket.io-client';

export const setSocket = (payload: {socket:Socket}):SetSocketAction => ({
  type: SET_SOCKET,
  payload
});
export const setLastAction = (payload: {action:string,data:any,type:string,afterAction?:any} | null):SetLastActionBeforeConnection => ({
  type: SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION,
  payload
});
