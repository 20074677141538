import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

interface Props  {
  component: React.FC;
}

const ProtectedRoute: React.FC<Props> = ({ component: Component }) => {
  const token = !!localStorage.getItem('token');
 return token ? <Component /> : <Navigate to="/explore" />;
};

export default ProtectedRoute;
