import { Fragment } from 'react';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import classNames from 'classnames';
import useUser from 'redux/user/use-user';
import review from 'assets/images/review.svg';
import { MASTER_FLOW_PAGES } from '../../../constants';

const Review = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();

  console.log('review became a master')
  return (
    <div className="flex flex-col items-center w-[474px]">
      <div className="w-[120px] h-[120px]">
        <img src={review} alt="review" />
      </div>
      <p className={classNames(['text-doers-black mt-4', styles.review])}>Review</p>
      <div className={styles.numbersContainer}>
        {MASTER_FLOW_PAGES.map((p, i: number) => (
          <Fragment key={p}>
            <div
              className={classNames({
                [styles.number]: true,
                [styles.filled]: !!masterFlowData[p]
              })}
            >
              {!!masterFlowData[p] ? <i className="icon-9 text-white" /> : i + 1}
            </div>
            {i + 1 !== MASTER_FLOW_PAGES.length && <div className={styles.line}></div>}
          </Fragment>
        ))}
      </div>
      <div className={styles.txt}>
        Good Job! Your profile information sent to our team to review and verify it
        <span className="text-doers-black text-base font-semibold"> within 1 hour</span>. In case of
        rejection you will get a clear feedback via e-mail.
      </div>
      <DButton text="Explore Jobs" className={styles.explore} type="default" goTo="/explore" />
    </div>
  );
};

export default Review;
