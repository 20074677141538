import * as _ from 'lodash';

type Filters = null | undefined | 0 | false | '' | 'empty-array' | 'empty-object';
type NonFilteredobjectType = {
  [key: string]: any;
};

const filterObj = (noneFilteredObject: NonFilteredobjectType, filters: Filters[]): any => {
  let result = noneFilteredObject;
  filters.forEach(filter => {
    for (const key in result) {
      if (result[key] === filter || _.isNaN(result[key])) {
        delete result[key];
      }
      switch (filter) {
        case 'empty-array':
          if (Array.isArray(result[key]) && result[key].length === 0) {
            delete result[key];
          }
          break;
        case 'empty-object':
          if (typeof result[key] === 'object' && Object.keys(result[key]).length === 0) {
            delete result[key];
          }
          break;
      }
    }
  });
  return result;
};

export default filterObj;
