import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DButton from 'components/DButton';
import useUser from 'redux/user/use-user';
import isEmpty from 'lodash/isEmpty';

const FirstView = () => {
  const { keepRegUserData, userTempRegData } = useUser();

  useEffect(() => {
    !isEmpty(userTempRegData) && keepRegUserData({});
  }, []);

  return (
    <div className="text-center flex flex-1 flex-col items-center justify-center">
      <div className="w-[376px]">
        <Link to="/" className="text-xl font-bold text-dark-green">
          Doers
        </Link>
        <div className="mt-8">
          <DButton
            text="Sign up"
            type="default"
            className="w-[376px] text-sm font-semibold"
            goTo="/auth/register"
          />
        </div>
        <div className=" mt-2 mb-6">
          <DButton
            text="Login"
            size="lg"
            type="white"
            goTo="/auth/login"
            className="text-dark-green"
          />
        </div>
        <DButton
          text="Skip"
          type="only-text"
          goTo="/explore"
          className="text-doers-black text-sm font-bold"
        />
      </div>
    </div>
  );
};

export default FirstView;
