import DButton from 'components/DButton';
import { Outlet, useNavigate } from 'react-router-dom';
import IconBack from 'assets/icons/icon-arrow-back.png';
import { useLocation } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { useEffect } from 'react';

const hideBackIn = ['review'];
const hideSkipIn = ['steps', 'scalability', 'address', 'radius', 'documents', 'review'];

const MasterFlowConatiner = () => {
  const { pathname, state }: any = useLocation();

  const navigate = useNavigate();

  const { user } = useUser();

  useEffect(() => {
    if (user?.profileType === 'MASTER') {
      navigate('/explore');
    }
  }, []);

  let back = '';
  switch (pathname) {
    case '/become-a-master/steps':
      back = state?.from ? state.from : '/email-verified';
      break;
    case '/become-a-master/account-type':
      back = '/become-a-master/steps';
      break;
    case '/become-a-master/company':
        back = '/become-a-master/account-type';
        break;   
    case '/become-a-master/introduction':
        back = '/become-a-master/account-type';
        break; 

    case '/become-a-master/services':
      back = '/become-a-master/introduction';
      break;
    case '/become-a-master/prices':
      back = '/become-a-master/services';
      break;
    // case '/become-a-master/scalability':
    //   back = '/become-a-master/prices';
    //   break;
    case '/become-a-master/address':
      back = '/become-a-master/prices';
      break;
    case '/become-a-master/radius':
      back = '/become-a-master/address';
      break;
    case '/become-a-master/video':
      back = '/become-a-master/radius';
      // if (masterFlowData.radius && masterFlowData.scalability === scalability.AREA.value) {
      //   back = '/become-a-master/radius';
      // } else {
      //   back = '/become-a-master/scalability';
      // }
      break;
    case '/become-a-master/documents':
      back = '/become-a-master/video';
      break;
    default:
      console.log('Sorry?!');
  }

  let skip = '';
  switch (pathname) {
    case '/become-a-master/introduction':
      skip = '/become-a-master/services';
      break;
    case '/become-a-master/introduction':
      skip = '/become-a-master/steps';
      break;
    case '/become-a-master/services':
      skip = '/become-a-master/prices';
      break;
    case '/become-a-master/prices':
      skip = '/become-a-master/address';
      break;
    // case '/become-a-master/scalability':
    //   skip = '/become-a-master/prices';
    //   break;
    // case '/become-a-master/address':
    //   skip = '/become-a-master/scalability';
    //   break;
    case '/become-a-master/radius':
      skip = '/become-a-master/address';
      break;
    case '/become-a-master/video':
      skip = '/become-a-master/documents';
      break;
    case '/become-a-master/documents':
      skip = '/become-a-master/review';
      break;
    default:
      console.log('Sorry?!');
  }

  return (
    <div className="relative flex-1 h-full">
      {!hideBackIn.includes(pathname.replace('/become-a-master/', '')) && (
        <div className="absolute top-[52px] left-[140px]">
          <DButton size="sm" type="circle" icon={IconBack} goTo={back} />
        </div>
      )}
      {!hideSkipIn.includes(pathname.replace('/become-a-master/', '')) && (
        <div className="absolute top-[52px] right-[140px]">
          <DButton
            type="white-bordered"
            className="font-bold text-sm w-[144px] h-[44px]"
            text="Skip this Step"
            goTo={skip}
          />
        </div>
      )}
      <div className="flex flex-1 justify-center h-full pt-[89px]">
        <Outlet />
      </div>
    </div>
  );
};

export default MasterFlowConatiner;
