import {
  FETCH_CHANNELS,
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_FAILURE,
  SET_SELECTED_CHANNEL,
  SET_MODIFIED_CHANNELS,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MORE_MESSAGES,
  RESET_CHAT,
  ADD_NEW_MESSAGE,
  ADD_NEW_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_MESSAGE,
  SET_SUGGESTION_POPOVER,
  SET_REDIRECTED_CHANEL_ID,
  SET_MESSAGE_ID_FOR_ACTION,
  GET_UNSEEN_MESSAGES_REQUEST,
  GET_UNSEEN_MESSAGES_SUCCESS,
  MESSAGES_MARK_AS_SEEN_REQUEST
} from './actionTypes';
import { ChatActions, ChatState, ModifiedChannel } from './types';

const initialState: ChatState = {
  channelsLoading: true,
  suggestionPopover: { visible: false, ownerIsMe: false, cleanText: true },
  channels: [],
  redirectedChanelId: null,
  channelsPage: 1,
  loadMoreChannels: true,
  error: null,
  selectedChannel: null,
  modifiedChannels: [],
  messagesLoading: true,
  messages: [],
  isLoading: false,
  singleMessage: null,
  messagesError: null,
  messageForAction: null,
  unseenMessages: null,
  firstTimeMessagesLoad: false, // added to handle first time scroll to bottom in chat
  next: '',
  previous: ''
};

export default (state = initialState, action: ChatActions) => {
  switch (action.type) {
    case FETCH_CHANNELS:
      return {
        ...state,
        ...(state.loadMoreChannels && { channelsLoading: true })
      };
    case FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        channelsLoading: false,
        channels: action.payload.channels,
        channelsPage: action.payload.channelsPage,
        loadMoreChannels: action.payload.loadMoreChannels,
        error: null
      };
    case FETCH_CHANNELS_FAILURE:
      return {
        ...state,
        channelsLoading: false,
        channels: [],
        error: action.payload.error
      };
    case SET_MODIFIED_CHANNELS:
      return {
        ...state,
        modifiedChannels: action.payload.modifiedChannels
      };
    case SET_REDIRECTED_CHANEL_ID:
      return {
        ...state,
        redirectedChanelId: action.payload.redirectedChanelId
      };
    case SET_SELECTED_CHANNEL:
      return {
        ...state,
        ...(action.payload.selectedChannel && { selectedChannel: action.payload.selectedChannel }),
        // messagesLoading: true,
        firstTimeMessagesLoad: false,
        next: '',
        previous: '',
        messages: []
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoading: false,
        messages: action.payload.messages,
        messagesError: null,
        firstTimeMessagesLoad: action.payload.firstTimeMessagesLoad,
        next: action.payload.next,
        previous: action.payload.previous
      };
    case FETCH_MORE_MESSAGES:
      return {
        ...state,
        messagesLoading: true,
        firstTimeMessagesLoad: true
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        messagesLoading: false,
        messages: [],
        messagesError: action.payload.messagesError
      };
    case ADD_NEW_MESSAGE:
      if (action.payload.message.channel === state.selectedChannel?._id) {
        return {
          ...state,
          singleMessage: action.payload.message,
          messages: [...state.messages, action.payload.message]
        };
      }

      let count = 0;
      let unseenMessages = state.unseenMessages;
      if (unseenMessages?.channels) {
        unseenMessages.channels.forEach(
          (channel: { count: number; _id: string }, index: number) => {
            if (action.payload.message.channel === channel._id) {
              count = channel.count;
              if (unseenMessages?.channels[index]) {
                count = channel.count + 1;
                unseenMessages.channels[index] = {
                  ...channel,
                  count
                };
              }
            }
          }
        );
      }
      return {
        ...state,
        unseenMessages: {
          ...unseenMessages,
          totalUnseenCount: unseenMessages ? unseenMessages.totalUnseenCount + 1 : 1
        }
      };

    case UPDATE_MESSAGE:
      return {
        ...state,
        messages: state.messages.map(message => {
          if (message._id === action.payload.message._id) {
            return action.payload.message;
          }
          return message;
        })
      };
    case ADD_NEW_CHANNEL:
      let unSeenMessages = state.unseenMessages;
      if (!unSeenMessages) {
        unSeenMessages = { channels: [], totalUnseenCount: 0 };
      }
      return {
        ...state,
        channels: [action.payload.channel, ...state.channels],
        unseenMessages: {
          ...unSeenMessages,
          channels: [{ _id: action.payload.channel._id, count: 1 }, ...unSeenMessages.channels]
        },
        modifiedChannels: [action.payload.modifiedChannel, ...state.modifiedChannels]
      };
    case UPDATE_CHANNEL:
      const channels: Array<ModifiedChannel> = [];
      state.modifiedChannels.forEach(modifiedChannel => {
        if (modifiedChannel._id === action.payload._id) {
          channels.unshift({ ...modifiedChannel, ...action.payload });
        } else {
          channels.push(modifiedChannel);
        }
      });
      return {
        ...state,
        modifiedChannels: channels,
        ...(state.selectedChannel?._id === action.payload._id && {
          selectedChannel: {
            ...state.selectedChannel,
            ...action.payload
          }
        })
      };
    case RESET_CHAT:
      return {
        ...initialState,
        unseenMessages: state.unseenMessages
      };
    case SET_SUGGESTION_POPOVER:
      return {
        ...state,
        suggestionPopover: action.payload
      };
    case SET_MESSAGE_ID_FOR_ACTION:
      return {
        ...state,
        messageForAction: action.payload
      };
    case GET_UNSEEN_MESSAGES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_UNSEEN_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unseenMessages: action.payload
      };
    case MESSAGES_MARK_AS_SEEN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    default:
      return {
        ...state
      };
  }
};
