import TextField from './TextField';

type Props = {
  services: {
    profession: { label: string; value: string };
    value: boolean;
    id: number;
  }[];
  onChange: (idx: number) => void;
};

const RadioMulti: React.FC<Props> = ({ services, onChange }): JSX.Element => {
  return (
    <div className="w-[376px]">
      {services.map((item, idx) => {
        return (
          <div className="mb-2" key={item.id}>
            <TextField
              text={item.profession.label}
              onChange={() => onChange(idx)}
              isChecked={item.value}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RadioMulti;
