/** @format */
/** @format */

import axios, { AxiosError } from 'axios';

import { StatusError } from '../model/errors';
import { MediaItem } from '../model/business/media';

const mediaServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.MEDIA_SERVICE_URL!,
  apiKey: 'testKey',
};

export const getMediaByPurposeAndType = async (user: string, type: string, purpose: string) =>
  getData<MediaItem[]>(`/user-media`, { params: { purpose, type, user } });

interface MediaApiError {
  name: string;
  message: string;
}

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const handleHttpError = (error: AxiosError<MediaApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${mediaServiceConfig.url}${path}`;
