/** @format */
import * as yup from 'yup';
import { MediaPathType, MediaLiteItem, mediaTypes } from '../media';

export type ProfileType = 'USER' | 'MASTER';
export type ScalabilityType = 'NATIONWIDE' | 'AREA';
export type AccountType = 'INDIVIDUAL' | 'COMPANY';

export type ProfileItem = {
  _id: string;
  introduction?: string;
  type?: ProfileType;
  scalability?: ScalabilityType;
  address?: string;
  zipCode?: string;
  location?: LocationType;
  radius: number;
  pro: boolean;
  bestMatch: boolean;
  completedJobs: number;
  fullName: string;
  mediaPath?: MediaPathType;
  accountType?: AccountType;
  profilePicture?: string;
};

export type ProfilesFilterResponse = {
  items: ProfileItem[];
  count: number;
};

export type ProfileFilterRequest = {
  rateFrom?: number;
  rateTo?: number;
  priceFrom?: number;
  priceTo?: number;
  radius?: number;
  lat?: number;
  lng?: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  search?: string;
  createdAt?: number;
  from?: number;
  to?: number;
  industry?: string[];
  user?: string;
};

/** Profile entity */
export type Profile = {
  _id?: string;
  /** First name of the user */
  firstName: string;
  /** Last name of the user */
  lastName: string;
  /** Introduction of the user */
  introduction?: string;
  /** Type of the profile */
  type?: ProfileType;
  services?: Array<ServicePrice>;
  scalability?: ScalabilityType;
  address?: string;
  zipCode?: string;
  location?: LocationType;
  radius: number;
  pro: boolean;
  bestMatch: boolean;
  rating: number;
  completedJobs: number;
  fullName: string;
  profilePicture?: string;
  companyName?: string;
  companyTaxId?: string;
  mediaPath?: MediaPathType;
  documents?: Array<MediaLiteItem>;
  bioKeywords?: Array<string>;
  jobKeywords?: Array<string>;
};

export type LocationType = {
  type: string;
  coordinates: Array<number>;
};

export const servicesList = [
  'ODD_JOBS_AND_OTHER_SERVICES',
  'ARTS',
  'ENTERTAINMENT_AND_RECREATION',
  'ACCOMMODATION_AND_FOOD_SERVICES',
  'EDUCATION_AND_SPORTS',
  'FINANCE_ADMINISTRATION_BUSINESS_SUPPORT',
  'TRANSPORTATION_AND_MOVING_SERVICES',
  'SOCIAL_ASSISTANCE',
  'CONSTRUCTION',
  'MANUFACTURING',
];

export const servicesTextMapping = {
  ODD_JOBS_AND_OTHER_SERVICES: 'Odd jobs and Other Services',
  ARTS: 'Arts',
  ENTERTAINMENT_AND_RECREATION: 'Entertainment and Recreation',
  ACCOMMODATION_AND_FOOD_SERVICES: 'Accommodation and Food Services',
  EDUCATION_AND_SPORTS: 'Education and Sports',
  FINANCE_ADMINISTRATION_BUSINESS_SUPPORT: 'Finance, Administration, Business Support',
  TRANSPORTATION_AND_MOVING_SERVICES: 'Transportation and moving services',
  SOCIAL_ASSISTANCE: 'Social Assistance',
  CONSTRUCTION: 'Construction',
  MANUFACTURING: 'Manufacturing',
};

export const ProfileMasterUpdateRequestSchema = yup.object().shape({
  firstName: yup.string().min(1).max(40).required(),
  lastName: yup.string().min(1).max(40).required(),
  introduction: yup.string().min(1),
  profileType: yup.string().required().default('USER'),
  hourlyRate: yup.number().min(0),
  flatPrice: yup.number().min(0),
  services: yup.array().of(
    yup.object().shape({
      service: yup.string().oneOf(servicesList).required(),
      hourlyRate: yup.number().min(0),
      flatPrice: yup.number().min(0),
    }),
  ),
  scalability: yup.string().oneOf(['NATIONWIDE', 'AREA']),
  address: yup.string().nullable(true),
  zipCode: yup.string().nullable(true),
  location: yup.object().shape({
    type: yup.string().required().default('Point'),
    coordinates: yup.array(),
  }),
  radius: yup.number().default(100),
  accountType: yup.string().oneOf(['INDIVIDUAL', 'COMPANY']),
  profilePicture: yup.string().optional(),
  companyName: yup.string().nullable(true),
  companyTaxId: yup.string().nullable(true),
  mediaPath: yup.object().optional().nullable(true),
  documents: yup
    .array()
    .max(5)
    .of(
      yup
        .object()
        .required()
        .shape({
          path: yup.string().required(),
          type: yup.string().oneOf(mediaTypes).required(),
        }),
    ),
});

export const ProfileDoerUpdateRequestSchema = yup.object().shape({
  firstName: yup.string().min(1).max(40).required(),
  lastName: yup.string().min(1).max(40).required(),
  introduction: yup.string().min(1),
  profilePicture: yup.string().optional(),
});

/** Payload object of the master user for registration */
export type ProfileMasterUpdateRequest = {
  /** First name of the user */
  firstName: string;
  /** Last name of the user */
  lastName: string;
  /** Introduction of the user */
  introduction?: string;
  /** Generic hourly rate of user */
  hourlyRate?: number;
  /** Generic flat price of user */
  flatPrice?: number;
  /** Services of user */
  services?: Array<ServicePrice>;
  /** Type of the profile */
  profileType?: ProfileType;
  scalability: ScalabilityType;
  address?: string;
  zipCode?: string;
  location?: LocationType;
  radius?: number;
  accountType?: AccountType;
  /** Profile picture of the user */
  profilePicture?: string;
  companyName?: string;
  companyTaxId?: string;
  mediaPath?: MediaPathType | null | {};
  documents?: Array<MediaLiteItem>;
};

/** Payload object of the doer user */
export type ProfileDoerUpdateRequest = {
  /** First name of the user */
  firstName: string;
  /** Last name of the user */
  lastName: string;
  /** Introduction of the user */
  introduction?: string;
  /** Profile picture of the user */
  profilePicture?: string;
};

export type Service = (typeof servicesList)[number];

export type ServicePrice = {
  service: Service;
  hourlyRate: number;
  flatPrice: number;
};
