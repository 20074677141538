import React, { useEffect, useRef, useState } from 'react';
import PriceTooltip from 'components/PriceTooltip';
import GoogleMapReact from 'google-map-react';
import Pin from 'components/Pin';
import MyLocation from 'components/MyLocation';
import styles from './index.module.scss';
import useDashboard from 'redux/dashboard/use-dashboard';
import { calcCoordsDistance, getCurrentPositionFromBrowser } from 'helpers';
import MasterPin, { MasterInfo } from 'components/MasterPin';
import currentLocationImg from 'assets/images/currentLocation.svg';
import { IMasterAndJobs } from 'types/mapScreen';
import JobPin, { JobInfo } from 'components/JobPin';
import { JobItem } from 'common';
import getUserLocationInfo from 'helpers/getUserLocationInfo';
import useUser from 'redux/user/use-user';
import useGoogleMapZoom from 'redux/googleMapZoom/use-map-zoom';
import { ATLANTA_LOCATION } from '../../../../constants';
import _ from 'lodash';
import { alert } from 'helpers';

type Props = {
  data: IMasterAndJobs;
};

const props = {
  center: {
    lat: 40.1872,
    lng: 44.5152
  },
  zoom: 11
};

const AnyReactComponent1 = (props: any) => <div>asdasds</div>;
const AnyReactComponent2 = (props: any) => <PriceTooltip price={451} asterisk master />;
const SearchedLocationPin = (props: any) => <Pin type="default" />;
const MyLocationPin = (props: any) => <MyLocation />;
const MapControl = (props: any) => {
  return (
    <div className={styles.mapControl}>
      <img src={currentLocationImg} alt="currentLocationImg" />
    </div>
  );
};

const Map: React.FC<Props> = ({ data: { masters, jobs } }): JSX.Element => {
  const { filters, changeFilters } = useDashboard();

  const [myLocation, setMyLocation] = useState<number[]>();
  const [activeMaster, setActiveMaster] = useState<any>(null);
  const [activeJob, setActiveJob] = useState<JobItem | null>(null);
  const [showPins, setShowPins] = useState(false);
  const [hasNewJob, setHasNewJob] = useState(false);

  const { user } = useUser();
  const mapRef = useRef<any>();
  const { zoom, changeZoom } = useGoogleMapZoom();

  useEffect(() => {
    (async () => {
      const { lng, lat, address, enabled } = await getUserLocationInfo(user);
      if (user?.profileType !== 'MASTER' && enabled) {
        setMyLocation([lng, lat]);
      }

 

      if(!filters.searchPlace && !filters.mapInfo){
        changeFilters({
          ...filters,
          searchPlace: {
            placeName: address,
            placeCoords: {
              lng,
              lat
            }
          },
          mapInfo: {
            ...filters.mapInfo,
            radius: 32,
            center: {
              lng,
              lat
            }
          }
        });
      }else{
        changeFilters({...filters})
      }
    
    })();
  }, [user?._id]);

  const handleOnChange = (val: GoogleMapReact.ChangeEventValue) => {
    mapRef.current?.LatLng(val.center.lat, val.center.lng);
    const radius = calcCoordsDistance(val.bounds.ne, val.center) || 32;
    changeZoom({ zoom: val.zoom });
    changeFilters({
      ...filters,
      mapInfo: {
        ...val,
        radius,
        ...(mapRef.current && {
          center: {
            lng: mapRef.current?.lng(),
            lat: mapRef.current?.lat()
          }
        })
      }
    });
  };

  const goToMyPos = async () => {
    try {
      const [lng, lat] = await getCurrentPositionFromBrowser();
      setMyLocation([lng, lat]);
      changeFilters({
        ...filters,
        mapInfo: {
          ...filters.mapInfo,
          center: { lng, lat }
        }
      });
    } catch (err) {
      alert.error(`${err}`);
    }
  };

 





 

  return (
    <div className={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={{
          lng: ATLANTA_LOCATION.lng,
          lat: ATLANTA_LOCATION.lat
        }}
        defaultZoom={props.zoom}
        center={filters.mapInfo?.center}
        zoom={zoom}
        onChange={handleOnChange}
        options={{
          fullscreenControl: false,
          zoomControlOptions: { position: 6 }
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps }) => {
          mapRef.current = maps;
          setShowPins(true);
        }}
      >
        {showPins &&
          (filters.lookingFor === 'masters' || filters.lookingFor === 'both') &&
          masters.items.map(master => {

            return(
              <MasterPin
                key={master._id}
                id={master._id}
                accountType={master.accountType || 'INDIVIDUAL'}
                lat={master?.location?.coordinates[1]}
                lng={master?.location?.coordinates[0]}
                bestMatch={master.bestMatch || false}
                onMouseOverFn={() => setActiveMaster(master)}
                onMouseLeaveFn={() => setActiveMaster(null)}
            />
            )
          })}
        {showPins &&
          (filters.lookingFor === 'jobs' || filters.lookingFor === 'both') &&
          jobs.items.map(job => {
            if(job.user !== user._id){
              return (
                <JobPin
                key={job._id}
                id={job._id}
                pricePerJob={job.flatPrice}
                pricePerHour={job.hourlyRate}
                onMouseOverFn={() => setActiveJob(job)}
                onMouseLeaveFn={() => setActiveJob(null)}
                bestMatch={job.bestMatch || false}

                lat={job?.location?.coordinates[1]}
                lng={job?.location?.coordinates[0]}
              />
               )
            }
            
          })}
        {showPins && activeMaster && (
          <MasterInfo
            // TODOFRONT pass real image when BE will be ready
            masterImgUrl=""
            masterName={activeMaster.fullName}
            lat={activeMaster.location.coordinates[1]}
            lng={activeMaster.location.coordinates[0]}
          />
        )}
        {showPins && activeJob && (
          <JobInfo
            title={activeJob.title}
            pricePerJob={activeJob.flatPrice}
            pricePerHour={activeJob.hourlyRate}
            lat={activeJob.location.coordinates[1]}
            lng={activeJob.location.coordinates[0]}
          />
        )}
        {showPins && filters.searchPlace?.placeCoords?.lat && (
          <SearchedLocationPin
            lat={filters.searchPlace.placeCoords.lat}
            lng={filters.searchPlace.placeCoords.lng}
          />
        )}
        {showPins && !!myLocation?.length && (
          <MyLocationPin lng={myLocation[0]} lat={myLocation[1]} />
        )}
      </GoogleMapReact>
      <div onClick={goToMyPos}>
        <MapControl />
      </div>
    </div>
  );
};

export default Map;
