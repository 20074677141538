import React, { useState } from 'react';
import PropTypes from 'prop-types'; // ES6
import classNames from 'classnames';
import styles from './style.module.scss';
import { Col, Input, Row, Typography ,Switch} from 'antd';
import { SERVICES } from 'constants';

const PriceCard = ({ shadow = true, title, toggle= false,isOpen=true, leftInput = {}, rightInput = {}, onChange,onSwichChange ={},}) => {
  const [activeInput, setActiveInput] = useState('');
  const [open, setOpen] = useState(isOpen);

  const onFocus = e => {
    setActiveInput(e.target.name);
  };

  const onBlur = () => {
    setActiveInput('');
  };
  // const onSwitchChange =()=>[
  //   setOpen(!open)
  //   onSwichChange
  // ]

  return (
    <Row
      className={classNames(styles.card, {
        [styles.cardWithShadow]: shadow,
        'w-[328px]': shadow,
        'w-[376px]': !shadow,
        // 'h-[138px] py-16 px-24 rounded-[16px]': isopen,
        'px-[16px] !h-[55px] !rounded-[46px]': !isOpen

      })}
    >
      {title && (
        <Col className={styles.titleContainer}>
          <Typography className={classNames(styles.title,{
            [styles.hasToggleAndClose]:toggle && !isOpen,
              '!text-[#18191A]':toggle ,
          })}>{SERVICES[title]}</Typography>
          {toggle &&   <Switch onChange={()=>onSwichChange(!isOpen, title,)} 
                                defaultChecked={isOpen} 
                                className={classNames('swichStyle')} />}
        </Col>
      )}
      {isOpen &&(
            <>
            <Row className={classNames('justify-between', { 'w-[328px]': shadow, 'w-[376px]': !shadow })}>
              <Col className={classNames({ 'w-[154px]': shadow, 'w-[180px]': !shadow })}>
                <Typography
                  className={classNames(styles.label, {
                    [styles.labelActive]: !!leftInput.value || activeInput === leftInput.name
                  })}
                >
                  <i className="icon-19 text-font-18 mr-2" />
                  Hourly rate
                </Typography>
              </Col>
              <Col className={styles.orContainer} />
              <Col className={classNames({ 'w-[154px]': shadow, 'w-[180px]': !shadow })}>
                <Typography
                  className={classNames(styles.label, {
                    [styles.labelActive]: !!rightInput.value || activeInput === rightInput.name
                  })}
                >
                  <i className="icon-17 text-font-18 mr-2" />
                  Flat Price
                </Typography>
              </Col>
            </Row>
            <Row className={classNames('justify-between', { 'w-[328px]': shadow, 'w-[376px]': !shadow })}>
              <Col className={classNames({ 'w-[154px]': shadow, 'w-[180px]': !shadow })}>
                <Input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  name={leftInput.name}
                  onChange={e => onChange(e.target.value, title, 'hourlyRate')}
                  value={leftInput.value}
                  type="number"
                  className={classNames(styles.input, {
                    [styles.inputActive]: !!+leftInput.value || activeInput === leftInput.name,
                    [styles.focused]: activeInput === leftInput.name,
                    [styles.inputBig]: !shadow
                  })}
                  onWheel={event => event.currentTarget.blur()}
                  addonBefore="$"
                />
              </Col>
              <Col className={styles.orContainer}>Or</Col>
              <Col className={classNames({ 'w-[154px]': shadow, 'w-[180px]': !shadow })}>
                <Input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  name={rightInput.name}
                  onChange={e => onChange(e.target.value, title, 'flatPrice')}
                  value={rightInput.value}
                  type="number"
                  className={classNames(styles.input, {
                    [styles.inputActive]: !!+rightInput.value || activeInput === rightInput.name,
                    [styles.focused]: activeInput === rightInput.name,
                    [styles.inputBig]: !shadow
                  })}
                  onWheel={event => event.currentTarget.blur()}
                  addonBefore="$"
                />
              </Col>
            </Row>
            </>
      )

      }

  
    </Row>
  );
};

PriceCard.propTypes = {
  shadow: PropTypes.bool,
  title: PropTypes.string,
  leftInput: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.node,
    name: PropTypes.string
  }),
  rightInput: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.node,
    name: PropTypes.string
  }),
  onChange: PropTypes.func
};

export default PriceCard;
