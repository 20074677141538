import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import RegContainer from '../Containers/RegContainer';
import styles from './index.module.scss';
import DButton from 'components/DButton';
import { useNavigate } from 'react-router-dom';
import Sent from 'assets/images/sent.svg';
import useUser from '../../../redux/user/use-user';
import { confirmEmail, resendConfirmEmailCode } from 'api/auth';
import classNames from 'classnames';
import { alert, getDeviceId } from 'helpers';

const VerifyEmail = () => {
  const { userTempRegData, keepUser, user } = useUser();

  let navigate = useNavigate();
  const [code, setCode] = useState<string[]>([]);
  const [err, showErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const handleCodeChange = (code: string) => {
    setCode(code.split(''));
  };

  const verifyUser = async () => {
    try {
      setLoading(true);
      const body = {
        code: code.join(''),
        email: userTempRegData.email
      };

      const confirmedUserResp = await confirmEmail({ ...body, deviceId: getDeviceId() });

      keepUser({ ...user, ...confirmedUserResp, ...confirmedUserResp.userInfo });
      localStorage.setItem('token', confirmedUserResp.token);

      if (confirmedUserResp.userInfo?.pushNotificationId) {
        localStorage.setItem('pushNotificationId', confirmedUserResp.userInfo?.pushNotificationId);
      }

      navigate('/email-verified');
    } catch (e) {
      showErr(true);
    } finally {
      setLoading(false);
    }
  };

  const resend = async () => {
    try {
      setResendLoading(true);
      await resendConfirmEmailCode({
        email: userTempRegData.email
      });
      alert.success(
        `We have sent the new code to your ${userTempRegData.email} email address. Please check your mailbox.`
      );
    } catch (error) {
      console.log('resendError');
    } finally {
      setResendLoading(false);
    }
  };

  useEffect(() => {
    if (!userTempRegData?.email) {
      navigate('/auth/register');
    }
  }, [userTempRegData]);

  return (
    <RegContainer>
      <div className="flex flex-col justify-center items-center w-[376px] text-center mt-[100px]">
        <img src={Sent} alt="sent" />
        <div className={styles['conf-email']}>Verify that it is you</div>
        <div className="text-base text-doers-black mb-6 w-[210px]">
          We sent a 5-digit code to {userTempRegData.email}
        </div>
        <ReactCodeInput
          fields={5}
          autoFocus={true}
          onChange={handleCodeChange}
          values={code}
          className={classNames({ [styles.inputStyle]: true, [styles.err]: err })}
        />
        <div className="mt-6">
          <DButton
            text="Confirm"
            disabled={code.length !== 5}
            className="w-[302px] font-semibold"
            type="default"
            loading={loading}
            onClick={verifyUser}
          />
        </div>
        <div className="flex mt-3">
          <p className="font-sm text-light-gray">Didn't receive an email?</p>
          <DButton
            text="&nbsp;Resend"
            type="only-text"
            className="font-bold text-sm active:text-light-gray disabled:text-light-gray"
            onClick={resend}
            loading={resendLoading}
          />
        </div>
      </div>
    </RegContainer>
  );
};

export default VerifyEmail;
