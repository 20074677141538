import DButton from 'components/DButton';
import { Link } from 'react-router-dom';
import IconBack from 'assets/icons/icon-arrow-back.png';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './index.module.scss';
import useUser from 'redux/user/use-user';
import { Row } from 'antd';
import { useSelector } from 'react-redux';

const ResetPassContainer = ({
  children,
  isModal
}: {
  children: JSX.Element;
  isModal?: boolean;
}) => {
  const { keepEmailForPassReset } = useUser();
  const location = useLocation();
  const {

    loginModal
  } = useSelector((state: any) => state.modals);

  let goTo = '';
  switch (location.pathname) {
    case '/auth/login':
      goTo = '/auth';
      keepEmailForPassReset('');
      break;
    case '/auth/forgot-password':
      goTo = '/auth/login';
      break;
    case '/auth/code-verification':
      goTo = '/auth/forgot-password';
      break;
    case '/auth/set-new-pass':
      goTo = '/auth/code-verification';
      break;
    default:
      console.log('Sorry?!');
  }

    
  return (
    <div className="relative flex-1">
      {!isModal && location.pathname !== '/auth/pass-changed' && (
        <div className="absolute top-[7%] left-[16px]">
          <DButton size={'sm'} type={'circle'} icon={IconBack} goTo={goTo} />
        </div>
      )}
      {isModal ? (
        <Row className="font-bold text-dark-green text-font-22" justify="center">
          {loginModal.masterId ? <> Login to invite a Master</> :<> Login to apply to the job </>}
         
        </Row>
      ) : (
        <header className={classNames(['absolute text-center top-[10.8%]', styles.logo])}>
          <Link to="/" className="text-xl font-bold text-dark-green text-xl">
            Doers
          </Link>
        </header>
      )}
      <div className="flex flex-1 items-center justify-center h-full">{children}</div>
    </div>
  );
};

export default ResetPassContainer;
