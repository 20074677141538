import React, { useEffect, useState } from 'react';
import DButton from 'components/DButton';
import classNames from 'classnames';
import { Input } from 'antd';
import pencil from 'assets/images/pencil.svg';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import DotsAndButton from 'components/DotsAndButton';

const Introduction = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const { TextArea } = Input;
  const [introduction, setIntro] = useState<string>('');
  const navigate = useNavigate();

  const next = () => {
    keepBecomeMasterData({ ...masterFlowData, introduction });
    navigate('/become-a-master/services');
  };

  useEffect(() => {
    if (masterFlowData?.introduction) {
      setIntro(masterFlowData?.introduction);
    }
  }, [masterFlowData?.introduction]);

  return (
    <div>
      <div className="flex flex-col items-center w-[474px]">
        <div className="w-[120px] h-[120px]">
          <img src={pencil} alt="pencil" />
        </div>
        <p className={styles.introduction}>Introduction</p>
        <p className={classNames(styles.introduce)}>
          Shortly introduce the services you can provide
        </p>
        <div className={styles.areaCont}>
          <TextArea
            className={styles.textarea}
            placeholder="Tell about your experience. Mention what is the advantages of working with you."
            value={introduction}
            onChange={e => setIntro(e.target.value)}
          />
        </div>
        <DotsAndButton>
          <DButton
            text="Next, Services"
            className={styles.next}
            disabled={introduction.length < 11}
            type="default"
            onClick={next}
          />
        </DotsAndButton>
      </div>
    </div>
  );
};

export default Introduction;
