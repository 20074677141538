import { PaymentFilterRequest ,PaymentItem, PaymentsFilterResponse} from "common";


export const TRANSACTIONS_GET_REQUEST = 'TRANSACTIONS_GET_REQUEST';
export const TRANSACTIONS_GET_SUCCESS = 'TRANSACTIONS_GET_SUCCESS';
export const TRANSACTIONS_GET_FAILED = 'TRANSACTIONS_GET_FAILED';
export const TRANSACTIONS_LOAD_MORE_REQUEST = 'TRANSACTIONS_LOAD_MORE_REQUEST';
export const TRANSACTIONS_LOAD_MORE_SUCCESS = 'TRANSACTIONS_LOAD_MORE_SUCCESS';


//PaymentsFilterResponse

export interface FetchTransactionsRequest {
  type: typeof TRANSACTIONS_GET_REQUEST;
  payload: PaymentFilterRequest;
}

export interface FetchTransactionsSuccess {
  type: typeof TRANSACTIONS_GET_SUCCESS;
  payload: PaymentsFilterResponse;
}

export type FetchMoreTransactions = {
  type: typeof TRANSACTIONS_LOAD_MORE_REQUEST;
};






export interface FetchTransactionsLoadMoreRequest {
  type: typeof TRANSACTIONS_LOAD_MORE_REQUEST;
  payload: PaymentFilterRequest;
}

export type FetchTransactionsLoadMoreSuccess= {
  type: typeof TRANSACTIONS_LOAD_MORE_SUCCESS;
  payload: PaymentsFilterResponse;
};




export interface FetchTransactionsSuccessPayload {
  items: PaymentItem[];
  next?: string;
  previous?: string;
}



export interface TransactionsState {
  isLoading: boolean,
  loadeMoreisLoading:boolean,
  transactions: PaymentItem[],
  // error: string | null;
  next: string;
  loadMore:boolean
  // previous: string;
}

export type TransactionsActions =
| FetchTransactionsLoadMoreRequest
| FetchTransactionsLoadMoreSuccess
| FetchTransactionsRequest
| FetchTransactionsSuccess
