/** @format */

import axios, { AxiosError } from 'axios';
import { UserItem, UserType, UserRole } from '../model/business/user';
import { UserRegistrationRequest, BackOfficeUserSetPasswordRequest } from '../model/business/auth';

import { StatusError } from '../model/errors';
import { ProfileItem } from '../model/business/profile';

const adminUserServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.ADMIN_USER_SERVICE_URL!,
  apiKey: 'testKey',
};

interface UserApiResponse {}

interface UserApiError {
  name: string;
  message: string;
}

export const getUserListByIds = async (userIds: Array<string>): Promise<UserItem[]> =>
  getData<UserItem[]>('/list', {
    params: {
      userIds,
    },
  });

export const setForgotPassword = async (userId: string, data: BackOfficeUserSetPasswordRequest) =>
  putData<string>(`/${userId}/set-forgot-password`, data);

export const getUserById = async (userId: string): Promise<UserItem | null> =>
  getData<UserItem>(`/${userId}`);

export const getCurrentUserById = async (userId: string): Promise<UserItem | null> =>
  getData<UserItem>(`/current-user/${userId}`);

export const getUserByEmail = async (email: string): Promise<UserItem | null> =>
  getData<UserItem>(`/email/${email}`);

export const getProfileByUserId = async (userId: string): Promise<ProfileItem | null> =>
  getData<ProfileItem>(`/${userId}/profile`);

export const activateUser = async (userId: string, data: BackOfficeUserSetPasswordRequest) =>
  putData<string>(`/${userId}/activate-user`, data);

export const getUserByEmailAndType = async (
  email: string,
  type: UserType,
  userRole: UserRole = 'ADMIN',
): Promise<UserItem | null> =>
  getData<UserItem>(`/email/${email}/${type}`, {
    params: {
      role: userRole,
    },
  });

export const createUser = async (user: UserRegistrationRequest) => postData<UserItem>(`/`, user);

export const updateUser = async (userId: string, data: any) =>
  putData<UserItem>(`/${userId}/update`, data);

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<UserApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const putData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.put<UserApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const handleHttpError = (error: AxiosError<UserApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${adminUserServiceConfig.url}${path}`;
