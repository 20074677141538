import classNames from 'classnames';
import { useState } from 'react';
import styles from './index.module.scss';
import { Badge } from 'antd';

type Props = {
  type: 'notification' | 'message';
  className?: string;
  unSeenCount: number;
  onClick?: () => void;
  children?:any
};

const Notifications = ({ type, className, onClick ,children,unSeenCount}: Props) => {

 
  return (
   <>
   
   <div className={classNames([styles.div, className])} onClick={onClick}>
      <i className={`icon-${type === 'notification' ? '30' : '31'}`} />
      {/* <span>{unSeenCount}</span>  */}
      {unSeenCount > 0  &&  <Badge
        className="site-badge-count-109"
        count={unSeenCount}
        style={{ backgroundColor: '#52c41a' }}
      />  }
</div>
{children}
</>
  );
};

export default Notifications;
