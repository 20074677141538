import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Typography, Divider, Space } from 'antd';
import Card from './Card';
import styles from './index.module.scss';
import DButton from 'components/DButton';
import { showEndStartDate, showJobPrices, toFixedUserRating } from 'helpers';
import LabelData from 'components/MainLayout/Modals/LabelData';
import Video from 'components/Video';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  OPEN_JOB_APPLY_MODAL,
  GALLERY_MODAL_OPEN,
  OPEN_CONNECT_PAYMENT_MODAL
} from 'redux/modals/actionTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { fetchGetUserPaymentsMethods } from 'redux/user/actions';
import { getUserPayInMethods, getUserPaymentMethods } from 'api/transactions';
import { setLastAction } from 'redux/general/actions';

const { Text } = Typography;

const JobCard = ({ item, onNvigate }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const channelIdRef = useRef<string | null>(null);

  // useEffect(() =>{
  //   dispatch(fetchGetUserPaymentsMethods());
  // },[])

  const showDistance = (distance: number): string => {
    const mileToMeter = 1609;
    const result = distance / mileToMeter;
    if (result >= 1) {
      return `${Number(result).toFixed(2)} mi`;
    } else if (result === 0) {
      return '0 mi';
    }
    return `${Number(distance / mileToMeter).toFixed(2)} mi`;
  };

  const showDates = () => {
    const { start, end, both } = showEndStartDate(item.startDate, item.endDate);
    if (both) {
      return <Text className={styles.jobCardDates}>{both}</Text>;
    }
    if (start) {
      return (
        <LabelData
          classes={classNames('doers-black-trprnt', styles.jobCardDate)}
          label="Start date:"
          value={start}
        />
      );
    }
    if (end) {
      return (
        <LabelData
          classes={classNames('doers-black-trprnt', styles.jobCardDate)}
          label="End date:"
          value={end}
        />
      );
    }
  };

  const openJobDetailsModal = (id: string) => {
    navigate(`/explore/job/${id}`, {
      state: {
        job: id
      }
    });
  };

  const onVideoClickHandle = () => {
    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: {
        videos: [item.video?.original]
      }
    });
  };

  const handleApplyFLow = async (item: any) => {
    if (user._id) {
      if (user.profileType !== 'MASTER') {
        dispatch(
          setLastAction({
            action: OPEN_JOB_APPLY_MODAL,
            data: {
              show: true,
              jobId: item._id,
              pricePerHour: item.hourlyRate,
              pricePerJob: item.flatPrice
            },
            type: 'modal'
          })
        );
        navigate('/become-a-master/steps', {
          state: {
            from: location.pathname
          }
        });
      } else {
        const payInMethods = await getUserPayInMethods();

        if (payInMethods.length > 0) {
          dispatch({
            type: OPEN_JOB_APPLY_MODAL,
            payload: {
              show: true,
              jobId: item._id,
              pricePerHour: item.hourlyRate,
              pricePerJob: item.flatPrice
            }
          });
        } else {
          dispatch(
            setLastAction({
              action: OPEN_JOB_APPLY_MODAL,
              data: {
                show: true,
                jobId: item._id,
                pricePerHour: item.hourlyRate,
                pricePerJob: item.flatPrice
              },
              type: 'modal'
            })
          );
          dispatch({
            type: OPEN_CONNECT_PAYMENT_MODAL
          });
        }
      }
    } else {
      dispatch({
        type: 'OPEN_LOGIN_MODAL',
        payload: {
          show: true
        }
      });
    }
  };

  const isJobActive = () => {
    let flag = false;
    user?.activeJobs?.forEach(
      (job: { jobId: string; jobContractId: string; channelId: string }) => {
        if (job.jobId === item._id) {
          channelIdRef.current = job.channelId;
          flag = true;
        }
      }
    );
    return flag;
  };

  return (
    <Card classes="justify-between flex-nowrap pr-4">
      <Col>
        <Video
          thumb={process.env.REACT_APP_MEDIA_BASE_URL + item.video?.thumbnail}
          size="sm-job"
          onClick={() => onVideoClickHandle()}
        />
      </Col>
      <Col className="ml-6 flex justify-between flex-col w-full">
        <Row className="flex-col">
          <Row className="flex justify-between flex-nowrap">
            <Text className={styles.jobTitle}>{item.title}</Text>
            {item.pro && (
              <div className={styles.badges}>
                <span>
                  <i className="icon-25" />
                </span>
              </div>
            )}
          </Row>
          <Space className="mt-1" size={4}>
            <Text>by</Text>
            <DButton
              text={`${item.userData?.firstName} ${item.userData?.lastName}`}
              type="only-text"
              className="text-green font-semibold text-font-14 max-w-[217px] truncate"
            />
            <Divider type="vertical" style={{ borderLeft: '1px solid black' }}></Divider>
            <i className="icon-26 text-orange"></i>
            <Text className="font-bold">{toFixedUserRating(item.userData?.doerReviewAvg)}</Text>
            <Text className="font-normal">({item.userData?.doerReviews})</Text>
          </Space>
          <Space direction="vertical" className="mt-1" size={0.1}>
            <Text className="text-light-gray leading-[17px] block">
              {item.address} ({showDistance(item.distance)})
            </Text>
            <Text className="mt-1 block">{showDates()}</Text>
          </Space>
        </Row>
        <Row className="justify-between" align="middle">
          <Text className="font-bold">
            {showJobPrices({ hourly: item.hourlyRate, flat: item.flatPrice, long: true })}
          </Text>
          <Row>
            <DButton
              onClick={() => openJobDetailsModal(item._id)}
              text="Job Details"
              type="only-text"
              className="text-green font-semibold text-font-13 mr-3 hover:text-light-green"
            />

            {isJobActive() ? (
              <DButton
                text="View offer"
                onClick={() => onNvigate(channelIdRef.current)}
                type="white"
                className={styles.hireBtn}
              />
            ) : (
              <DButton
                text="Apply"
                onClick={() => handleApplyFLow(item)}
                type="white"
                className={styles.hireBtn}
              />
            )}
          </Row>
        </Row>
      </Col>
    </Card>
  );
};

export default React.memo(JobCard);
