import { TransactionsActions, TransactionsState } from './types';
import * as types from './types';

const initialState : TransactionsState = {
  isLoading: false,
  loadeMoreisLoading: false,
  transactions: [],
  loadMore:true,
  next:''
  
};

const transactionReducer = (state = initialState, action:TransactionsActions) => {
  switch (action.type) {
    case types.TRANSACTIONS_GET_REQUEST:
      return {
        ...state,
        isLoading:true
      };
    case types.TRANSACTIONS_GET_SUCCESS:

        return {
          ...state,
          isLoading:false,
          transactions: action.payload.items,
          loadMore: action?.payload?.items.length < 30 ? false : true

        }; 
     case types.TRANSACTIONS_LOAD_MORE_REQUEST:

          return {
            ...state,
            loadeMoreisLoading:true,
           
          };  
      case types.TRANSACTIONS_LOAD_MORE_SUCCESS:
        return {
          ...state,
          loadeMoreisLoading:false,
          transactions: [...state.transactions,...action?.payload?.items],
          loadMore: action?.payload?.items.length < 30 ? false : true
        };   

    default:
      return state;
  }
};

export default transactionReducer;
