/** @format */

import * as yup from 'yup';
import { JobItemLite } from '../job';
import { UserItem } from '../user';

export type ActivityStatus = 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'BLOCKED' | 'DELETED';
export declare type ActivityType = 'VIDEO' | 'JOB' | 'MASTER';
export declare type CommunityActivityType = 'LIKE' | 'COMMENT';
export type CommunityActivityStatus = 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'BLOCKED' | 'DELETED';

export type ActivityItem = {
  _id?: string;
  description?: string;
  video: string;
  photo: string;
  user?: UserItem;
  hashtags?: Array<string>;
  status?: ActivityStatus;
  type?: ActivityType;
  deleted?: boolean;
  location?: ActivityLocationType;
  likes?: Array<string>;
  comments?: Array<string>;
  totalLikes?: number;
  totalComments?: number;
  likedByMe?: boolean;
  job?: string | JobItemLite;
  reportComments?: any;
  userId?: string;
};

export type ActivityFilterRequest = {
  rateFrom?: number;
  rateTo?: number;
  priceFrom?: number;
  priceTo?: number;
  radius?: number;
  lat?: number;
  lng?: number;
  page?: number;
  limit?: string;
  sortBy?: string;
  type?: string;
  sortOrder?: number;
  search?: string;
  userId?: string;
  tutorial?: boolean;
  firstVideoId?: string;
};

export type ActivityLocationType = {
  type: string;
  coordinates: Array<number>;
};

export type ActivitiesFilterResponse = {
  items: ActivityItem[];
  count: number;
};

/** Payload object for creating a feed */
export type CreateActivityRequest = {
  /** Description of the feed */
  description?: string;
  /** Video of the feed */
  video: string;
  /** Photo of the feed */
  photo: string;
  /** Job id */
  job?: string;
  /** Hashtags of the feed */
  hashtags?: Array<string>;
  /** Type of the feed */
  type?: ActivityType;
  /** Location of the feed */
  location?: ActivityLocationType;
};

export const CreateActivityRequestSchema = yup.object().shape({
  description: yup.string().min(1).max(400).optional(),
  video: yup.string().min(1).max(400).required(),
  photo: yup.string().min(1).max(400).required(),
  type: yup.string().required().default('JOB_TYPE_1'),
  location: yup.object().shape({
    type: yup.string().required().default('Point'),
    coordinates: yup.array(),
  }),
});

/** Payload object for updating the feed */
export type UpdateActivityRequest = {
  /** Description of the feed */
  description: string;
  /** Video of the feed */
  video: string;
  /** Photo of the feed */
  photo: string;
  /** User info of the feed */
  user: {
    fullName: string;
    rating?: number;
    jobCount?: number;
  };
  /** Job id */
  job?: string;
  /** Hashtags of the feed */
  hashtags?: Array<string>;
  /** Type of the feed */
  type?: ActivityType;
  /** Location of the feed */
  location?: ActivityLocationType;
};

export const UpdateActivityRequestSchema = yup.object().shape({
  description: yup.string().min(1).max(400).required(),
  video: yup.string().min(1).max(400).required(),
  photo: yup.string().min(1).max(400).required(),
  type: yup.string().required().default('JOB_TYPE_1'),
  location: yup.object().shape({
    type: yup.string().required().default('Point'),
    coordinates: yup.array(),
  }),
});

export type CommunityActivityFilterRequest = {
  feedItemId: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  search?: string;
  next?: string;
  previous?: string;
};

export type CommunityActivitiesFilterResponse = {
  items: CommunityActivityItem[];
  count: number;
};

export type CommunityActivityItem = {
  _id?: string;
  user: string | UserItem;
  feedItem: string;
  type: CommunityActivityType;
  text?: string;
  hashtags?: Array<string>;
  taggedUsers?: Array<string>;
  status: CommunityActivityStatus;
};

/** Payload object for creating a feed comment */
export type CreateCommunityActivityRequest = {
  text: string;
  hashtags?: Array<string>;
  taggedUsers?: Array<string>;
};

export const CreateCommunityActivityRequestSchema = yup.object().shape({
  text: yup.string().min(1).max(400).required(),
  hashtags: yup.array().optional(),
  taggedUsers: yup.array().optional(),
});

export type ReportVideoRequest = {
  comment?: string;
};

export const ReportVidoeRequestSchema = yup.object().shape({
  comment: yup.string().optional(),
});

export type BlockFeedRequest = {
  blockReason: string;
};
export const BlockFeedRequestSchema = yup.object().shape({
  blockReason: yup.string().required(),
});
