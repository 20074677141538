import React, { Children } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import XIcon from '../../assets/icons/x-icon.svg';
import { OPEN_REPORT_ISSUE_MODAL } from '../../redux/modals/actionTypes';

type Props = {
  size?: string;
  type:
    | 'only-text'
    | 'default'
    | 'secondary'
    | 'white-bordered'
    | 'circle'
    | 'light-green'
    | 'white-shadowed'
    | 'white';
  text?: string;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
  submit?: boolean;
  goTo?: string;
  textColor?: string;
  loading?: boolean;
  children?: JSX.Element | string;
  className?: string;
  loadingClassName?: string;
  [rest: string]: any;
};

const DButton: React.FC<Props> = ({
  size = 'md',
  type = 'only-text',
  text,
  icon,
  onClick,
  disabled,
  submit,
  goTo,
  // eslint-disable-next-line react/prop-types
  // textColor = 'text-black',
  loading,
  children,
  className,
  loadingClassName,
  ...rest
}) => {
  const navigate = useNavigate();
  const doActionOrRedirect = () => {
    if (goTo) {
      navigate(goTo);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={classNames([
        styles['btn-custom'],
        styles[size],
        styles[type],
        icon ? 'justify-between' : 'justify-center',
        'relative',
        loading ? `${styles.loading} ${styles.disabled}` : '',
        disabled ? styles.disabled : '',
        // textColor,
        className
      ])}
      style={rest.customStyles}
      onClick={doActionOrRedirect}
      disabled={loading || disabled}
      type={submit ? 'submit' : 'button'}
    >
      {icon && icon === 'x' && <i className={'icon-22 text-font-12 mr-1'}></i>}
      {icon && icon !== 'x' && <img src={icon} alt="icon" />}
      {text || children}
      {loading && (
        <i
          className={classNames({
            'icon-5 text-font-20 absolute right-3': true,
            [styles.spin]: true,
            [styles.onlyTextSpin]: type === 'only-text',
            [loadingClassName as string]: !!loadingClassName
          })}
        />
      )}
      {icon && <div className={styles['empty-div-for-icon']} />}
    </button>
  );
};

export default DButton;
