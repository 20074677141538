import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

interface Props  {
  component: React.FC;
}

const PrivateRoute: React.FC<Props> = ({ component: Component }) => {
  const token = !!localStorage.getItem('token');
  return token ? <Component /> : <Navigate to="/auth/login" />;
};

export default PrivateRoute;
