import { useDispatch, useSelector } from 'react-redux';
// import { RESET_STORE } from 'redux/common/types';
import {
  getAuthUserAction,
  keepRegUserDataAction,
  keepEmailForPassResetAction,
  keepCodeForPassResetAction,
  keepUserAction,
  keepBecomeMasterDataAction
} from './actions';
import {
  userTempRegData as regData,
  emailForUserReset as email,
  codeForUserReset as code,
  userData,
  becomeMasterFlowData
} from './selectors';

const useUser = () => {
  const dispatch = useDispatch();

  const userTempRegData = useSelector(regData);
  const emailForUserReset = useSelector(email);
  const codeForUserReset = useSelector(code);
  const user = useSelector(userData);
  const masterFlowData = useSelector(becomeMasterFlowData);
  const getAuthUser = () => {
    dispatch(getAuthUserAction());
  };

  const keepRegUserData = data => {
    dispatch(keepRegUserDataAction(data));
  };

  const keepEmailForPassReset = data => {
    dispatch(keepEmailForPassResetAction(data));
  };

  const keepCodeForPassReset = data => {
    dispatch(keepCodeForPassResetAction(data));
  };

  const keepUser = data => {
    dispatch(keepUserAction(data));
  };
  const keepBecomeMasterData = data => {
    dispatch(keepBecomeMasterDataAction(data));
  };

  // const handleLogout() {
  //   AuthService.logout();
  //   history.push(RouteCodes.arcusNet);
  //   dispatch({
  //     type: RESET_STORE
  //   });
  //   window.location.href = RouteCodes.arcusNetBase;
  // }

  return {
    userTempRegData,
    emailForUserReset,
    codeForUserReset,
    user,
    masterFlowData,
    keepUser,
    keepBecomeMasterData,
    keepRegUserData,
    keepEmailForPassReset,
    keepCodeForPassReset,
    getAuthUser
  };
};

export default useUser;
