/** @format */

export type SocketMessageType =
  | 'NEW_CHAT_CHANNEL'
  | 'UPDATE_CHAT_CHANNEL'
  | 'NEW_CHAT_MESSAGE'
  | 'UPDATE_CHAT_MESSAGE'
  | 'NEW_NOTIFICATION';

export type SocketMessageItem = {
  type: SocketMessageType;
  payload: any;
};

export type SendSocketMessage = {
  userIds?: string[];
  messages: Array<SocketMessageItem>;
  sentAt?: string;
};

export type SendSocketMessageResponse = {
  status: string;
};

export enum SocketChannelsEnum {
  PRIVATE_MESSAGE = 'private-message-channel',
  PRIVATE_CHANNEL_MESSAGE = 'private-chat-channel',
  JOB_CHANNEL = 'job-channel',
  PRIVATE_NOTIFICATION_MESSAGE = 'private-notification-channel',
}
