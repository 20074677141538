import { GeneralActions, GeneralState, SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION, SET_SOCKET } from "./types";


const initialState : GeneralState = {
  socket:null,
  lastAction:null
  
};

const generalReducer = (state = initialState, action:GeneralActions) => {
  switch (action.type) {
    case SET_SOCKET:
      return {
        ...state,
        socket:action.payload?.socket
      };
   case SET_LAST_ACTION_BEFORE_PAYMENT_METHOD_CONNECTION:
        return {
          ...state,
          lastAction:action.payload
        };  
      default:
        return state;
}
}

export default generalReducer;

