/** @format */

import axios, { AxiosError } from 'axios';
import {
  MakePaymentRequest,
  MakePaymentResponse,
  AddPaymentMethodPaymentRequest,
  AddPaymentMethodPaymentResponse,
  PaymentItem,
} from '../model/business/payment';
import { StatusError } from '../model/errors';

const paymentServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.PAYMENT_SERVICE_URL!,
  apiKey: 'testKey',
};

export const makePayment = async (payment: MakePaymentRequest): Promise<MakePaymentResponse> =>
  postData<MakePaymentResponse>('', payment);

export const getPaymentById = async (paymentId: string): Promise<PaymentItem> =>
  getData<PaymentItem>(`/get-by-id/${paymentId}`);

export const addPaymentMethodPayment = async (
  payment: AddPaymentMethodPaymentRequest,
): Promise<AddPaymentMethodPaymentResponse> => postData<any>('add-payment-method-payment', payment);

interface PaymentApiResponse {}

interface PaymentApiError {
  name: string;
  message: string;
}

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<PaymentApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const putData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.put<PaymentApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const handleHttpError = (error: AxiosError<PaymentApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${paymentServiceConfig.url}${path}`;
