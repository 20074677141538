import { useState, useRef, RefObject } from 'react';
import { DatePicker } from 'antd';
import styles from './DateRangePicker.module.scss';
import DButton from 'components/DButton';
import { useClickOutside } from 'hooks';
// import { Moment } from 'moment';
import { dateStateType } from 'types/types';
const { RangePicker } = DatePicker;

interface IDateRangePicker {
  dateValues: dateStateType;
  setDateValues: any;
  classes?: string;
}

const DateRangePicker = ({ dateValues, setDateValues, classes }: IDateRangePicker) => {
  const [dates, setDates] = useState<dateStateType>(dateValues);
  const [isOpen, setIsOpen] = useState(false);
  const panelRef: any = useRef(null);
  const wrapperRef: RefObject<HTMLDivElement> = useRef(null);
  const rangePickerRef: any = useRef(null);

  useClickOutside(panelRef, (e: { target: any }) => {
    if (!wrapperRef.current || !wrapperRef.current.contains(e.target)) {
      setIsOpen(false);
      setDates(dateValues);
    }
  });
  return (
    <div
      className={classes}
      ref={wrapperRef}
      onFocus={e => {
        setIsOpen(true);
      }}
    >
      <RangePicker
        picker="date"
        className={styles.dateRangePicker}
        value={dates}
        allowClear={false}
        ref={rangePickerRef}
        inputReadOnly
        open={isOpen}
        onCalendarChange={date => {
          date && setDates(date);
        }}
        separator={
          <span
            style={{
              width: '9px',
              height: '1px',
              background: 'black',
              display: 'block',
              marginRight: '5px'
            }}
          />
        }
        panelRender={component => <div ref={panelRef}>{component}</div>}
        dropdownClassName={'dropDownDateComponent'}
        renderExtraFooter={() => (
          <div className={styles.extraFooter}>
            <DButton
              text="Reset"
              type="only-text"
              className="font-bold"
              onClick={() => {
                setDates([null, null]);
                setDateValues([null, null]);
                setIsOpen(false);
              }}
            />
            <DButton
              text="Apply"
              className="h-[44px]"
              type="default"
              onClick={() => {
                setTimeout(() => rangePickerRef.current.blur(), 0);
                setDates(dates);
                setDateValues(dates);
                setIsOpen(false);
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default DateRangePicker;
