import { GoogleMapZoomActionType, GoogleMapZoomState } from './types';
import { UPDATE_ZOOM } from './actionTypes';

const initialState: GoogleMapZoomState = {
  zoom: 11
};

export const googleMapZoomReducer = (state = initialState, action: GoogleMapZoomActionType) => {
  switch (action.type) {
    case UPDATE_ZOOM:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
