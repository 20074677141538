import React, { useState } from 'react';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Divider } from 'antd';
import RadioMulti from 'components/RadioMulti';
import TextField from 'components/RadioMulti/TextField';
import { useNavigate } from 'react-router-dom';
import circle from 'assets/images/circle.svg';
import useUser from 'redux/user/use-user';
import DotsAndButton from 'components/DotsAndButton';

const AccountType = () => {

  const [value,setValue] = useState<'INDIVIDUAL'|'COMPANY'>('INDIVIDUAL')
  const navigate = useNavigate();
  const { masterFlowData, keepBecomeMasterData } = useUser();

  const onChange = (val: 'INDIVIDUAL'|'COMPANY') => {
    setValue(val)
  }

  const next = () => {
    keepBecomeMasterData({ ...masterFlowData, ...{accountType:value} });
    navigate(value === 'INDIVIDUAL' ?'/become-a-master/introduction' : '/become-a-master/company');
  };

  return (
    <div >
      <div className="flex flex-col items-center w-[474px]">
        <div className="w-[120px] h-[120px]">
            <img src={circle} alt="pencil" />
       </div>
        <p className={classNames(['text-doers-black', styles.steps])}>Account type</p>
        <p className={classNames(styles.youWillAnser)}>
        Choose the type of your account
        </p>

        <div className="w-[376px]">


        <div className="mb-2" >
            <TextField
              text={'I’m an Individual Master'}
              onChange={() => onChange('INDIVIDUAL')}
              isChecked={value === 'INDIVIDUAL' ? true : false}
            />
          </div>
          <div className="mb-2" >
            <TextField
              text={'I’m a Master running a company'}
              onChange={() => onChange('COMPANY')}
              isChecked={value === 'COMPANY' ? true : false}
            />
          </div>
        </div>
      
        <DotsAndButton>
          <DButton
            text="Get started"
            // className="w-[278px]"
            className={styles.next}
            type="default"
            onClick={next}
          />
        </DotsAndButton>
      </div>
    </div>
)

}

export default AccountType;
