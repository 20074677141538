import { Col, Row, Skeleton, Typography } from 'antd';
import classNames from 'classnames';
import useUser from 'redux/user/use-user';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../index.module.scss';
import {
  memo,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ChatState } from 'redux/chat/types';
import { fetchMoreMessages } from 'redux/chat/actions';
import MessageMediaCard from './MessageMediaCard';
import moment from 'moment';
import MessageDocumentCard from './MessageDocumentCard';
import { downloadFile } from 'helpers/downloadFile';
import { GALLERY_MODAL_OPEN } from 'redux/modals/actionTypes';
import { filterObj } from 'helpers';
import { ChatMessageType, MediaLiteItem } from 'common';
import MessageItemWrapper from './MessageItemWrapper';
import SystemMessage from './SystemMessage';

import { Virtuoso } from 'react-virtuoso';
import CustomAvatar from '../../CustomAvatar';

const START_INDEX = 100000;
const INITIAL_ITEM_COUNT = 20;

const { Text } = Typography;
const base_path = process.env.REACT_APP_MEDIA_BASE_URL;

interface IMessagesProps {
  containerRef: RefObject<HTMLDivElement>;
  setJobOfferSectionOpen: () => void;
}
const Messages = ({ containerRef, setJobOfferSectionOpen }: IMessagesProps) => {
  const dispatch = useDispatch();
  const {
    user: { _id: me }
  } = useUser();

  const {
    messages: chatMessages,
    firstTimeMessagesLoad,
    messagesLoading,
    next,
    singleMessage,
    selectedChannel
  } = useSelector((state: any): ChatState => state.chat);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const virtuosoRef = useRef<any>(null);

  // useLayoutEffect(() => {
  //   const { current } = containerRef;
  //   if (chatMessages.length && current && firstTimeMessagesLoad && messagesLoading) {
  //     current.scrollTo({ top: current.scrollHeight * 0.25 });
  //   }
  // }, [chatMessages.length, messagesLoading]);

  // useLayoutEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // }, [firstTimeMessagesLoad]);

  const messages = chatMessages.map((message: any, index: number) => {
    const prevMessage: any = chatMessages[index - 1];
    const messageDate = message.createdAt;
    const prevMessageDate = prevMessage?.createdAt;
    let showDate =
      !prevMessageDate || new Date(prevMessageDate).getDate() !== new Date(messageDate).getDate();
    return {
      ...message,
      showDate
    };
  });

  const onScroll = () => {
    const { current } = containerRef;
    if (current && firstTimeMessagesLoad) {
      if (current.scrollTop < current.scrollHeight * 0.2 && next) {
        dispatch(fetchMoreMessages());
      }
    }
  };

  const showMessageDate = (createdAt: string) => {
    const createdAtTemp = moment(createdAt).local().format('YYYY/MM/DD');
    const today = moment().local().format('YYYY/MM/DD');
    if (createdAtTemp == today) {
      return 'Today';
    }
    return moment(createdAt).local().format('DD MMM YYYY');
  };

  const addContentToModal = (activeItem: string | undefined, photos: any[], videos: any[]) => {
    const payloadTemp = {
      photos,
      videos,
      activeItem: activeItem
    };

    const filteredPayload = filterObj(payloadTemp, ['empty-array']);

    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: filteredPayload
    });
  };

  const handleOnMediaClick = (files: MediaLiteItem[], file: MediaLiteItem) => {
    const photosTemp: string[] = [];
    const videosTemp: string[] = [];
    files.forEach(file => {
      switch (file.type) {
        case 'PHOTO':
          photosTemp.push(file.path);
          break;
        case 'VIDEO':
          videosTemp.push(file.path);
          break;
      }
    });
    addContentToModal(file.path, photosTemp, videosTemp);
  };

  // const sysMessages = messages.filter(
  //   (message: any) => message.type !== 'TEXT' && message.type !== 'MEDIA' && message.type !== 'FILE'
  // );

  const isSystemMessage = (type: ChatMessageType) => {
    return type !== 'TEXT' && type !== 'MEDIA' && type !== 'FILE';
  };

  useEffect(() => {
    if (singleMessage && me === singleMessage.sender) {
      scrollToBottom();
    }
  }, [singleMessage]);

  const loadMoreMessages = useCallback(() => {
    if (next) {
      dispatch(fetchMoreMessages());
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (virtuosoRef.current) {
      virtuosoRef?.current?.scrollToIndex({
        index: internalMessages.length - 1,
        align: 'start',
        behavior: 'auto'
      });
    }
  };

  const internalMessages = useMemo(() => {
    return messages;
  }, [messages]);

  useEffect(() => {
    if (internalMessages.length <= 20) {
      scrollToBottom();
    }
  }, [internalMessages.length]);

  const followOutput = useCallback((isAtBottom: any) => {
    return isAtBottom ? 'smooth' : false;
  }, []);

  return (
    <>
      <Col
        className={classNames(
          'w-full overflow-y-auto overflow-x-hidden',
          styles.chatMessagesContainer
        )}
        // onScroll={onScroll}
        id="chatMessages"
        ref={containerRef}
      >
        {messagesLoading && firstTimeMessagesLoad && (
          <div className="h-8 w-full mt-10 top-[35px] absolute ">
            <i className={classNames(styles.spin, 'icon-5 text-font-24 text-green absolute')} />
          </div>
        )}
        <div
          className={classNames('overflow-y-auto overflow-x-hidden ')}
          style={{
            width: '100%',
            height: '100vh'
          }}
        >
          <Virtuoso
            className={classNames('overflow-x-hidden ')}
            ref={virtuosoRef}
            // firstItemIndex={START_INDEX - messages.length -1}
            firstItemIndex={START_INDEX - internalMessages.length - 1}
            computeItemKey={index => index}
            startReached={loadMoreMessages}
            initialTopMostItemIndex={internalMessages.length - 1}
            data={internalMessages}
            followOutput={followOutput}
            style={{ flex: '1 1 auto', overscrollBehavior: 'contain' }}
            itemContent={(index, item) => {
              let { message, sender, files, _id, createdAt, type, metadata, state, showDate } =
                item;
              const isMe = sender === me;
              const areDocumentFilesExist = !!files.filter((file: any) => file.type === 'DOCUMENT')
                .length;
              const areMediaFilesExist = !!files.filter(
                (file: any) => file.type === 'PHOTO' || file.type === 'VIDEO'
              ).length;

              return (
                <div key={index + '_' + _id} className="messageItem  top-[58px] translate-y-0 ">
                  {showDate && (
                    <Row
                      className={classNames(styles.time, 'w-full pb-2 pt-5')}
                      align="middle"
                      justify="center"
                    >
                      <Text className={styles.text}>{showMessageDate(createdAt)}</Text>
                    </Row>
                  )}
                  <Row className="py-2">
                    <SystemMessage
                      id={_id}
                      selectedChannel={selectedChannel}
                      setJobOfferSectionOpen={setJobOfferSectionOpen}
                      isMe={isMe}
                      type={type}
                      state={state}
                      groupId={'asdasd'}
                      time={createdAt}
                      metadata={metadata}
                    />
                  </Row>
                  {!isSystemMessage(type) && (
                    <Row
                      key={index}
                      className={classNames(
                        'w-full whitespace-pre-wrap py-4',
                        isMe ? 'mr-9' : 'ml-6',
                        'h-max'
                      )}
                      justify={!isMe ? 'start' : 'end'}
                      align="bottom"
                      wrap={false}
                    >
                      {!isMe && (
                        <Col className="mr-4 mb-auto pt-2 min-w-[32px]">
                          <CustomAvatar
                            firstName={selectedChannel?.interlocutor.firstName}
                            lastName={selectedChannel?.interlocutor.lastName}
                            size={32}
                            replaceImageSize="40x40"
                            imagePath={selectedChannel?.interlocutor?.profilePicture}
                            nameClasses="bg-[#999999] font-medium text-[16px]"
                          />
                        </Col>
                      )}
                      <Col className={classNames({ 'mr-9': isMe })}>
                        {type === 'TEXT' && (
                          <div className="w-full">
                            <MessageItemWrapper isMyMessage={isMe} time={createdAt}>
                              <Text>{message?.trim()} </Text>
                            </MessageItemWrapper>
                          </div>
                        )}
                        <Col>
                          {areMediaFilesExist && (
                            <MessageItemWrapper isMyMessage={isMe} time={createdAt}>
                              <Row gutter={10} justify={isMe ? 'end' : 'start'} wrap>
                                {files.map((file: { type: string; path: string }, index: any) => {
                                  if (file.type === 'PHOTO' || file.type === 'VIDEO') {
                                    return (
                                      <Col
                                        key={_id + index}
                                        onClick={() => handleOnMediaClick(files, file)}
                                        className="cursor-pointer"
                                      >
                                        <MessageMediaCard
                                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${
                                            file.type === 'PHOTO'
                                              ? file.path.replace('original', '100x100')
                                              : file.path
                                          }`}
                                          height={100}
                                          width={100}
                                          preview={false}
                                          mediaType={file.type}
                                          videoPlayerIcon={file.type === 'VIDEO'}
                                        />
                                      </Col>
                                    );
                                  }
                                })}
                              </Row>
                            </MessageItemWrapper>
                          )}
                          {areDocumentFilesExist && (
                            <MessageItemWrapper isMyMessage={isMe} time={createdAt}>
                              <Row gutter={10} wrap>
                                {files.map(
                                  (
                                    file: { type: string; path: string; name: string },
                                    index: number
                                  ) => {
                                    if (file.type === 'DOCUMENT') {
                                      return (
                                        <Col
                                          className={styles.documentContainerWrapper}
                                          onClick={() => downloadFile(file.path, 'doc')}
                                          key={index}
                                        >
                                          <MessageDocumentCard fileName={file.name} />
                                        </Col>
                                      );
                                    }
                                  }
                                )}
                              </Row>
                            </MessageItemWrapper>
                          )}
                        </Col>
                      </Col>
                    </Row>
                  )}
                </div>
              );
            }}
          />
        </div>
        {/* TODOFRONT: loading component */}
      </Col>
    </>
  );
};

export default memo(Messages);
