import React from 'react';
import styles from './index.module.scss';

type Props = {
  text: string;
};

const OrDivider: React.FC<Props> = ({ text }): JSX.Element => (
  <div className={styles['divider']}>
    <span />
    <p>{text}</p>
    <span />
  </div>
);

export default OrDivider;
