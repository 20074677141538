import { Col, Space, Typography } from 'antd';
const { Text } = Typography;

interface ILabelData {
  label: string;
  value?: string;
  direction?: 'vertical' | 'horizontal';
  classes?: string;
}

const LabelData = ({ label, value, direction, classes }: ILabelData) => {
  return (
    <Col>
      <Space direction={direction} className={classes}>
        <Text>{label}</Text>
        <Text className="font-bold">{value || '-'}</Text>
      </Space>
    </Col>
  );
};

export default LabelData;
