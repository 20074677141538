import styles from './index.module.scss';
import googleImg from 'assets/images/google.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MasterPin = ({
  onMouseOverFn,
  onMouseLeaveFn,
  id,
  accountType,
  bestMatch
}: {
  onMouseOverFn: () => void;
  onMouseLeaveFn: () => void;
  id: string;
  lat?: number;
  lng?: number;
  accountType:string;
  bestMatch:boolean
}): JSX.Element => {
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);

  const openMasterDetailsModal = () => {
    navigate(`/explore/master/${id}`, {
      state: {
        master: id
      }
    });
  };

  return (
    <div onClick={openMasterDetailsModal}>
      <div className={`${styles.pin} ${bestMatch && styles.isMaching}  ${hovered && styles.pinHovered}`}>
        {accountType === 'INDIVIDUAL' ?  <span className="icon-27 text-font-20" /> : <span className="icon-34 text-font-20" />}
      </div>
      <div
        className={styles.container}
        onMouseEnter={() => {
          setHovered(true);
          onMouseOverFn();
        }}
        onMouseLeave={() => {
          setHovered(false);
          onMouseLeaveFn();
        }}
      ></div>
    </div>
  );
};

export const MasterInfo = ({
  masterImgUrl,
  masterName
}: {
  masterImgUrl: string;
  masterName: string;
  lat: number;
  lng: number;
}): JSX.Element => (
  <div className={styles.masterInfo}>
    <div className={styles.imgAndName}>
      <img src={masterImgUrl || googleImg} />
      <span>{masterName || 'John Gibson'}</span>
    </div>
    <div className="flex text-font-13 items-center justify-center">
      <i className="icon-26 text-orange mr-1" />
      <p className="text-doers-black font-bold font-sm">5.5</p>
      <p className="text-doers-black font-normal font-sm">(0)</p>
    </div>
  </div>
);

export default MasterPin;
