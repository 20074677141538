import { Col } from 'antd';
import classNames from 'classnames';

interface IDashedBorderCardProps {
  children: JSX.Element;
  classes?: string;
}

const DashedBorderCard = ({ children, classes }: IDashedBorderCardProps) => {
  return (
    <Col className={classNames('w-auto border-dashed border-[1px] rounded-2xl', classes)}>
      {children}
    </Col>
  );
};

export default DashedBorderCard;
