import { NotificationActions, NotificationsState } from './types';
import * as types from './types';

const initialState: NotificationsState = {
  isLoading: false,
  loadeMoreisLoading: false,
  notifications: [],
  unseenNotificationsCount: 0,
  next: ''
};

const notificationReducer = (state = initialState, action: NotificationActions) => {
  switch (action.type) {
    case types.NOTIFICATION_GET_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.NOTIFICATION_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: action?.payload?.results,
        next: action.payload.next
      };
    case types.NOTIFICATION_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadeMoreisLoading: true
      };
    case types.NOTIFICATION_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadeMoreisLoading: false,
        notifications: [...state.notifications, ...action?.payload?.results],
        next: action.payload.next
      };

    case types.NOTIFICATION_UNSEEN_GET_REQUEST:
      return {
        ...state,
        loadeMoreisLoading: true
      };
    case types.NOTIFICATION_UNSEEN_GET_SUCCESS:
      return {
        ...state,
        loadeMoreisLoading: false,
        unseenNotificationsCount: action.payload.unseenCount
      };
    case types.NOTIFICATION_MARK_AS_SEEN_REQUEST:
      return {
        ...state,
        loadeMoreisLoading: true
      };
    case types.ADD_NEW_NOTIFICATION:
      let count = state.unseenNotificationsCount + 1;
      return {
        ...state,
        unseenNotificationsCount: count
      };

    default:
      return state;
  }
};

export default notificationReducer;
