import { useState } from 'react';
import { Row, Typography } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';
import { SERVICES } from '../../../../constants';

const { Text } = Typography;
interface IServicesInfoProps {
  data: string[];
  clickable?: boolean;
}
const ServicesInfo = ({ data,clickable = true }: IServicesInfoProps) => {
  const [selectedSkill, setSelectedSkill] = useState<null | Number>(null);

  return (
    <Row className="mt-4" align="middle">
      {data.map((item, idx) => (
        <Text
          key={idx}
          className={classNames(
            styles.service,
            selectedSkill === idx ? styles.selected : '',
            'p-1 pl-2 pr-2 mr-2 font-bold mb-2'
          )}
          onClick={() => {
            if(clickable){
              setSelectedSkill(idx)
          }}}
        >
          {SERVICES[item]}
        </Text>
      ))}
    </Row>
  );
};

export default ServicesInfo;
