import { PayloadAction } from '@reduxjs/toolkit';
import {
  getChannels,
  getMessages,
  getMessagesCursored,
  getUnseenMessages,
  messageMarkAllAsSeen
} from 'api/chat';
import {
  ChatChannelFilterRequest,
  ChatChannelItem,
  ChatMessageItem,
  UnseenMessagesCountResponse,
  UserItem
} from 'common';
import {
  all,
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest
} from 'redux-saga/effects';
import {
  fetchChannelsFailure,
  fetchChannelsSuccess,
  fetchMessagesFailure,
  fetchMessagesSuccess,
  setModifiedChannels
} from './actions';
import {
  ADD_NEW_MESSAGE,
  FETCH_CHANNELS,
  FETCH_MORE_MESSAGES,
  GET_UNSEEN_MESSAGES_REQUEST,
  GET_UNSEEN_MESSAGES_SUCCESS,
  MESSAGES_MARK_AS_SEEN_REQUEST,
  SET_SELECTED_CHANNEL
} from './actionTypes';
import {
  FetchChannelsFailure,
  FetchChannelsSuccess,
  FetchMessagesFailure,
  FetchMessagesSuccess,
  InterlocutorUser,
  ModifiedChannel,
  SelectChannel,
  SetModifiedChannels
} from './types';
import { useSelector } from 'react-redux';
import { unseenMessages } from './selectors';

/*
  Worker Saga: Fired on FETCH_CHANNELS action
*/
function* fetchChannelsSaga(
  action: PayloadAction<{
    page: number;
  }>
): Generator<
  | CallEffect<ChatChannelFilterRequest[]>
  | PutEffect<FetchChannelsSuccess | FetchChannelsFailure | SetModifiedChannels | SelectChannel>
  | SelectEffect,
  void,
  any
> {
  const { userData: user } = yield select(state => state.user); // <-- get the user from the state

  const {
    channels: oldChannels,
    modifiedChannels: oldModified,
    loadMoreChannels
  } = yield select(state => state.chat);
  if (loadMoreChannels) {
    try {
      const page: number = action.payload.page;
      const channels: ChatChannelItem[] = yield call(getChannels, {});

      yield put(
        fetchChannelsSuccess({
          channels: [...oldChannels, ...channels],
          channelsPage: page,
          loadMoreChannels: channels.length >= 10 // TODO: check if this is correct
        })
      );
      const modifiedChannels: ModifiedChannel[] = [];

      channels.forEach((channel: any) => {
        const interlocutor: InterlocutorUser =
          channel.doer._id === user._id
            ? { ...channel.master, isMaster: true }
            : { ...channel.doer, isMaster: false };
        const modifiedChannel = {
          _id: channel._id,
          interlocutor,
          lastMessage: channel.lastMessage,
          lastMessageType: channel.lastMessageType,
          status: channel.jobContract.status,
          title: channel.job.title,
          date: channel.updatedAt,
          doerId: channel.doer._id,
          masterId: channel.master._id,
          img: './',
          doerName: channel.doer.fullName,
          masterName: channel.master.fullName,
          jobDescription: channel.jobContract.description,
          type: channel.type,
          lastMessageUserId: channel.lastMessageUserId,
          lastMessageMetadata: channel.lastMessageMetadata,
          jobContractId: channel.jobContract._id,
          contractId: channel.jobContract.contractId,
          metadata: channel.metadata,
          job: channel.job,
          jobContract: channel?.jobContract
        };
        if (interlocutor.status !== 'DELETED') {
          modifiedChannels.push(modifiedChannel);
        }
      });
      yield put(setModifiedChannels({ modifiedChannels: [...oldModified, ...modifiedChannels] }));
    } catch (e: any) {
      yield put(fetchChannelsFailure({ error: e.message }));
    }
  }
}

function* selectChannelSaga(
  action: PayloadAction<{ selectedChannel: ModifiedChannel }>
): Generator<
  | PutEffect<FetchMessagesSuccess | FetchMessagesFailure | SetModifiedChannels>
  | CallEffect
  | SelectEffect,
  void,
  any
> {
  try {
    const { selectedChannel } = yield select(state => state.chat);

    const {
      results,
      next,
      previous
    }: {
      results: ChatMessageItem[];
      previous: string;
      next: string;
    } = yield call(getMessagesCursored, {
      limit: 20,
      channel: selectedChannel._id
      // next: oldNext,
      // previous: oldPrevious
      // sortBy: 'createdAt',
      // sortOrder: -1
    });
    yield put(
      fetchMessagesSuccess({
        messages: results.reverse(),
        firstTimeMessagesLoad: true,
        next,
        previous
      })
    );
  } catch (e: any) {
    yield put(fetchMessagesFailure({ error: e.message }));
  }
}
function* fetchMoreMessagesSaga(): Generator<
  PutEffect<FetchMessagesSuccess | FetchMessagesFailure> | CallEffect | SelectEffect,
  void,
  any
> {
  try {
    const {
      messages: oldMessages,
      selectedChannel,
      next: oldNext
    } = yield select(state => state.chat);
    // yield delay(500);
    const {
      results,
      next,
      previous
    }: {
      results: ChatMessageItem[];
      previous: string;
      next: string;
    } = yield call(getMessagesCursored, {
      limit: 10,
      channel: selectedChannel._id,
      next: oldNext
      // previous: oldPrevious
      // sortBy: 'createdAt',
      // sortOrder: -1
    });

    yield put(
      fetchMessagesSuccess({
        messages: [...results.reverse(), ...oldMessages],
        firstTimeMessagesLoad: true,
        next,
        previous
      })
    );
  } catch (e: any) {
    yield put(fetchMessagesFailure({ error: e.message }));
  }
}

function* fetchUnSeenMessagesSaga(action: { type: string }) {
  try {
    const response = (yield call(getUnseenMessages)) as UnseenMessagesCountResponse | null;

    yield put({
      type: GET_UNSEEN_MESSAGES_SUCCESS,
      payload: response
    });
  } catch (e) {}
}

function* fetchMessageAsSeenSaga(action: { type: string; payload: { channelId: string } }) {
  try {
    const response = (yield call(messageMarkAllAsSeen, action.payload.channelId)) as string;
    const { unseenMessages } = yield select(state => state.chat);

    let count = 0;
    if (unseenMessages) {
      unseenMessages.channels.forEach((channel: { count: number; _id: string }, index: number) => {
        if (action.payload.channelId === channel._id) {
          count = channel.count;
          unseenMessages.channels[index] = {
            ...channel,
            count: 0
          };
        }
      });

      const response = {
        channels: unseenMessages.channels,
        totalUnseenCount: unseenMessages.totalUnseenCount - count
      };
      yield put({
        type: GET_UNSEEN_MESSAGES_SUCCESS,
        payload: response
      });
    }
  } catch (e) {
    console.log(e, 'e');
  }
}

// function* addNewMessageSaga(): Generator<
//   | PutEffect<>
//   | CallEffect<>
//   | SelectEffect,
//   void,
//   any> {

//   }

/*
  Starts worker saga on latest dispatched `FETCH_CHANNELS` action.
  Allows concurrent increments.
*/
function* chatSaga() {
  yield all([
    takeLatest(FETCH_CHANNELS, fetchChannelsSaga),
    takeLatest(SET_SELECTED_CHANNEL, selectChannelSaga),
    takeLatest(FETCH_MORE_MESSAGES, fetchMoreMessagesSaga),
    takeLatest(GET_UNSEEN_MESSAGES_REQUEST, fetchUnSeenMessagesSaga),
    takeLatest(MESSAGES_MARK_AS_SEEN_REQUEST, fetchMessageAsSeenSaga)

    // takeLatest(ADD_NEW_MESSAGE, addNewMessageSaga)
  ]);
}

export default chatSaga;
