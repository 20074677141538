export const scalability = {
  NATIONWIDE: {
    value: 'NATIONWIDE',
    label: 'Nationwide'
  },
  AREA: {
    value: 'AREA',
    label: 'Area'
  }
};

export const ATLANTA_LOCATION = {
  address: 'Atlanta, GA, USA',
  lat: 33.75374,
  lng: -84.38633
};

export const MASTER_FLOW_PAGES: string[] = [
  'account-type',
  'introduction',
  'services',
  'prices',
  'radius',
  'video',
  'documents'
];

export const SERVICES: { [key: string]: string } = {
  ODD_JOBS_AND_OTHER_SERVICES: 'Odd jobs and Other Services',
  ARTS: 'Arts',
  ENTERTAINMENT_AND_RECREATION: 'Entertainment and Recreation',
  ACCOMMODATION_AND_FOOD_SERVICES: 'Accommodation and Food Services',
  EDUCATION_AND_SPORTS: 'Education and Sports',
  FINANCE_ADMINISTRATION_BUSINESS_SUPPORT: 'Finance, Administration, Business Support',
  TRANSPORTATION_AND_MOVING_SERVICES: 'Transportation and moving services',
  SOCIAL_ASSISTANCE: 'Social Assistance',
  CONSTRUCTION: 'Construction',
  MANUFACTURING: 'Manufacturing'
};
export const SERVICES_COLORS = ['#E8A3A3','#A3C4E8','#C9E8A3','#E8D5A3','#A3E8D1','#D3A3E8','#E8A3D9','#A3E8B8','#E8CCA3','#B5A3E8']

export const JOB_STATUSES: any = {
  NEW: { color: 'bg-[#A248F6]', text: 'New' },
  READY_TO_START: { color: 'bg-[#2DA055]', text: 'Ready to start' },
  IN_PROGRESS: { color: 'bg-[#4453F6]', text: 'In progress' },
  PENDING: { color: 'bg-[#DF9F54]', text: 'Pending work approval' },
  ISSUE_REPORTED: { color: 'bg-[#DA4F59]', text: 'Issue reported' },
  ISSUE_ACCEPTED: { color: 'bg-[#5A3E83]', text: 'Issue accepted' },
  ISSUE_DECLINED: { color: 'bg-[#FF6F3D]', text: 'Issue declined' },
  FINISHED: { color: 'bg-[#555555]', text: 'Finished' },
  CANCELED: { color: 'bg-[#999999]', text: 'Canceled' },
  REJECTED: { color: 'bg-[#999999]', text: 'Rejected' },
  ADMIN_REVIEW: { color: 'bg-[#DA9f00]', text: 'Admin Review' },
  DEACTIVATED:{ color: 'bg-[#DA4F91]', text: 'Deactivated'},
};

export const SYSTEMMESSAGE: any = {
  DOER_APPLY_JOB_ACCEPTED: { color: 'bg-[#AF41FF]', text: 'New' },
  DOER_INVITE_TO_JOB_EDITED: { color: 'bg-[#00A34C]', text: 'Ready to start' },
  MASTER_APPLY_JOB: { color: 'bg-[#4153FF]', text: 'In progress' },
  MASTER_INVITATION_ACCEPTED: { color: 'bg-[#EA9C42]', text: 'Pending' },
  DOER_JOB_TIPPED: { color: 'bg-[#EC4055]', text: 'Issue reported' },
  DOER_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN: { color: 'bg-[#EC4055]', text: 'Issue accepted' },
  MASTER_INVITATION_REJECTED: { color: 'bg-[#555555]', text: 'Finished' },
  DOER_INVITE_TO_JOB: { color: 'bg-[#999999]', text: 'Canceled' },
  DOER_CANCELED_OFFER: { color: 'bg-[#999999]', text: 'Rejected' },
  DOER_JOB_COMPLETION_REPORT_ISSUE: { color: 'bg-[#999999]', text: 'Rejected' },
  MASTER_APPLY_JOB_EDITED: { color: 'bg-[#999999]', text: 'Rejected' },
  DOER_JOB_PAID: { color: 'bg-[#999999]', text: 'Rejected' },
  DOER_APPLY_JOB_REJECTED: { color: 'bg-[#999999]', text: 'Rejected' },
  MASTER_JOB_MARKED_AS_COMPLETED: { color: 'bg-[#999999]', text: 'Rejected' },
  MASTER_JOB_STARTED: { color: 'bg-[#999999]', text: 'Rejected' },
  DOER_JOB_COMPLETION_APPROVED: { color: 'bg-[#999999]', text: 'Rejected' },
};
