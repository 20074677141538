import { Row, Col } from 'antd';
import classNames from 'classnames';
import CustomInput from 'components/CustomInput';
import Tab from 'components/Tab';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChannelCard from './ChannelCard';
import styles from '../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import ChannelsLoading from './ChannelsLoading';
import { fetchChannels, fetchMessagesMarkAllAsSeen, setSelectedChannel } from 'redux/chat/actions';
import useUser from 'redux/user/use-user';
import { ModifiedChannel } from 'redux/chat/types';
import { SET_SUGGESTION_POPOVER } from 'redux/chat/actionTypes';
import { Virtuoso } from 'react-virtuoso';
import { isTemplateMiddle } from 'typescript';
import { unseenMessages } from 'redux/chat/selectors';

const ChannelsList = ({ setIsEmpty }: { setIsEmpty: (bool: any) => any }) => {
  const [firstTimeChannelsLoad, setFirstTimeChannelsLoad] = useState(true);
  const [scrollToItem, setScrollToItem] = useState(false);

  const [formattedChannels, setForrmattedChannels] = useState<any>([]);
  const [activeTab, setActiveTab] = useState('allMessages');
  const virtuosoRefChannelList = useRef<any>(null);
  const unseenMessagesInfo = useSelector(unseenMessages);

  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const {
    modifiedChannels,
    channelsLoading,
    channelsPage,
    channels,
    selectedChannel,
    redirectedChanelId
  } = useSelector((state: any) => state.chat);
  const { user } = useUser();
  const channellListRef = useRef(null);

  const TABS = {
    allMessages: {
      tab: 'All Messages',
      content: <div></div>
    },
    postedJobs: {
      tab: 'Posted Jobs',
      content: <div></div>
    },
    ...(user?.profileType === 'MASTER' && {
      myJobs: {
        tab: `My Jobs`,
        content: <div></div>
      }
    })
  };
  // const onScroll = (e: any) => {
  //   if (channellListRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = channellListRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       dispatch(fetchChannels({ page: channelsPage + 1 }));
  //     }
  //   }
  // };

  const setActiveChannel = useCallback(
    (id: string) => {
      dispatch(
        setSelectedChannel({
          selectedChannel: modifiedChannels.find((ch: ModifiedChannel) => ch._id === id)
        })
      );
    },
    [modifiedChannels]
  );

  useEffect(() => {
    dispatch(fetchChannels({ page: 1 }));
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_SUGGESTION_POPOVER,
      payload: { visible: false, ownerIsMe: false, cleanText: true }
    });

    if (selectedChannel?._id) {
      dispatch(fetchMessagesMarkAllAsSeen({ channelId: selectedChannel._id }));
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (!channelsLoading) {
      if (channels.length === 0) {
        setIsEmpty(true);
      } else {
        //firstTimeChannelsLoad
        if (modifiedChannels.length && firstTimeChannelsLoad) {
          let selectedChannel = modifiedChannels[0];
          if (redirectedChanelId) {
            setTimeout(() => {
              setScrollToItem(true);
            }, 500);
            selectedChannel = modifiedChannels.find(
              (ch: ModifiedChannel) => ch._id === redirectedChanelId
            );
          }
          dispatch(setSelectedChannel({ selectedChannel })); // TODOFRONT <= this causes bad re-renders, logic should be refactored
          setFirstTimeChannelsLoad(false);
        }
        setIsEmpty(false);
      }
    }
  }, [channelsLoading, channels, modifiedChannels, redirectedChanelId]);

  const handleSearch = (channel: any, keys: string[]) => {
    for (let i = 0; i < keys.length; i++) {
      if (channel[keys[i]].toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let tempModifiedChannels;
    if (activeTab === 'allMessages') {
      tempModifiedChannels = modifiedChannels.filter((channel: any) => {
        if (handleSearch(channel, ['title', 'doerName', 'masterName', 'jobDescription'])) {
          return true;
        }
      });
    } else if (activeTab === 'postedJobs') {
      tempModifiedChannels = modifiedChannels.filter((channel: any) => {
        if (handleSearch(channel, ['title', 'doerName', 'masterName', 'jobDescription'])) {
          return channel.doerId === user?._id;
        }
      });
    } else if (activeTab === 'myJobs') {
      tempModifiedChannels = modifiedChannels.filter((channel: any) => {
        if (handleSearch(channel, ['title', 'doerName', 'masterName', 'jobDescription'])) {
          return channel.masterId === user?._id;
        }
      });
    }
    setForrmattedChannels(tempModifiedChannels);
  }, [modifiedChannels, activeTab, searchValue]);

  const handleSearchChange = (val: string) => {
    setSearchValue(val);
  };

  const formattedChannelsMemo = useMemo(() => {
    return formattedChannels;
  }, [formattedChannels]);

  const findRedirectedChanelIdAndScrollToItem = () => {
    var BreakException = {};
    try {
      formattedChannels.forEach((item: any, index: number) => {
        if (item._id === redirectedChanelId) {
          virtuosoRefChannelList.current.scrollToIndex({
            index: index,
            align: 'start',
            behavior: 'smooth'
          });
          throw BreakException;
        }
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (redirectedChanelId) {
      const selectedChannel = modifiedChannels.find(
        (ch: ModifiedChannel) => ch._id === redirectedChanelId
      );
      dispatch(setSelectedChannel({ selectedChannel })); // TODOFRONT <= this causes bad re-renders, logic should be refactored
      findRedirectedChanelIdAndScrollToItem();
    }
  }, [redirectedChanelId, scrollToItem]);

  const getUnReadMessagesCount = (id: string) => {
    if (unseenMessagesInfo?.channels) {
      const channel = unseenMessagesInfo.channels.find(item => item._id === id);
      return channel?.count;
    }
    return 0;
  };
  return (
    <>
      <Row align="middle" justify="center">
        <CustomInput
          onChange={handleSearchChange}
          placeholder="Search"
          value={searchValue}
          icon="icon-29"
          className="w-full max-w-[341px]"
        />
      </Row>
      <Row justify="center" className="mt-4">
        <Tab
          data={TABS}
          bottomBorder
          classes="w-full max-w-[361px]"
          activeKey={activeTab}
          handleTabChange={va => {
            setActiveTab(va);
          }}
        />
      </Row>
      <Row>
        <Col
          ref={channellListRef}
          // onScroll={onScroll}
          className={classNames(styles.channels, 'overflow-y-auto overflow-x-hidden w-full')}
        >
          {channelsLoading && <ChannelsLoading />}
          <Virtuoso
            className={classNames(styles.channels, 'overflow-x-hidden overflow-y-auto')}
            ref={virtuosoRefChannelList}
            data={formattedChannels}
            style={{
              width: '100%',
              height: '100vh'
            }}
            itemContent={(i, channel: any) => {
              return (
                <div>
                  <ChannelCard
                    key={channel._id + i}
                    interlocutor={channel.interlocutor}
                    lastMessage={channel.lastMessage}
                    lastMessageType={channel.lastMessageType}
                    status={channel.status}
                    title={channel.title}
                    _id={channel._id}
                    date={channel.date}
                    unreadMessages={getUnReadMessagesCount(channel._id)}
                    lastMessageMetadata={channel.lastMessageMetadata}
                    lastMessageUserId={channel.lastMessageUserId}
                    isActive={selectedChannel?._id === channel._id ? true : false}
                    jobContractId={channel.jobContractId}
                    setActiveChannel={setActiveChannel}
                  />
                </div>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ChannelsList;
