import { Col, Modal, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import CircleBgIcon from 'components/Icons/CircleBgIcon';
import ChatHeaderProfileInfo from '../ChatHeaderProfileInfo';
import DButton from 'components/DButton';
import style from './ChatRightSidebar.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  CLOSE_APPLY_MODAL,
  CLOSE_COMPLETE_JOB_MODAL,
  CLOSE_DECLINE_ISSUE_MODAL,
  CLOSE_FEEDBACK_MODAL,
  CLOSE_REPORT_ISSUE_MODAL,
  CLOSE_TIP_MODAL,
  GALLERY_MODAL_OPEN,
  OPEN_COMPLETE_JOB_MODAL,
  OPEN_JOB_APPLY_MODAL,
  OPEN_REPORT_ISSUE_MODAL
} from 'redux/modals/actionTypes';

import EditJobPrice from 'components/MainLayout/Modals/ApplyForJob';
import ReportCompleteJob from 'components/MainLayout/Modals/ReportCompleteJob';

import { ModifiedChannel } from 'redux/chat/types';
import useUser from 'redux/user/use-user';
import { SET_SUGGESTION_POPOVER } from 'redux/chat/actionTypes';
import moment from 'moment';
import {
  requestAcceptJobContract,
  requestRejectJobContract,
  requestChangePriceJobContract,
  requestCompleteJob,
  requestReportIssueJob,
  requestDeclineReportedIssueJob,
  requestCancelJobContract,
  requestStartJob,
  requestApproveCompletedJob,
  requestResumeJob
} from 'api/systemMessages';
import FeedbackModal from 'components/MainLayout/Modals/FeedbackModal';
import TipModal from 'components/MainLayout/Modals/TipModal';
import { useEffectAsync } from 'hooks';
import { useRef, useState } from 'react';
import { JobItem } from 'common';
import { getJob } from 'api/jobs';
import { useLocation, useNavigate } from 'react-router-dom';
import { alert } from 'helpers';
import MessageMediaCard from '../MessageMediaCard';
import { filterObj } from 'helpers';
import TextField from 'components/RadioMulti/TextField';
import { spawn } from 'child_process';

const { Text } = Typography;

const borderClass = 'border-b-[1px] py-[26px] border-[#EBEBEB]';

type InitalCancelRejectModalStateType = {
  visible: boolean;
  title: string;
  text: string;
  buttons: any;
};

const ChatRightSidebar = ({
  setIsJobOfferSectionOpen
}: {
  setIsJobOfferSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<
    Array<{ path: string; original?: string; type: string }> | undefined
  >();
  const [priceType, setPriceType] = useState<
    { flatPrice?: number; hourlyRate?: number } | undefined
  >(undefined);

  const navigate = useNavigate();
  const elementrRef = useRef<null | HTMLDivElement>(null);

  const initalCancelRejectModalState: InitalCancelRejectModalStateType = {
    visible: false,
    title: '',
    text: '',
    buttons: null
  };
  const [cancelRejectModalState, setCancelRejectModalState] =
    useState<InitalCancelRejectModalStateType>(initalCancelRejectModalState);
  const [loading, setLoading] = useState(false);

  const {
    applyForJobModal: applyModal,
    completeJobModal,
    reportIssueModal,
    declineIssueModal,
    feedbackModal,
    tipModal
  } = useSelector((state: any) => state.modals);
  const {
    selectedChannel,
    messageForAction
  }: {
    selectedChannel: ModifiedChannel;
    messageForAction: { id: string; type: string; groupId?: any } | null;
  } = useSelector((state: any) => state.chat);

  const { user } = useUser();

  const profileType = user._id === selectedChannel?.doerId ? 'doer' : 'master';

  const closeApplyModal = () => {
    dispatch({
      type: CLOSE_APPLY_MODAL
    });
  };

  const closeReportCompleteJobModal = (action: string) => {
    dispatch({
      type: action,
      payload: {
        show: false
      }
    });
  };

  const openEditModal = () => {
    const jobContractHourlyRate = selectedChannel?.jobContract?.hourlyRate;
    const jobContractFlatPrice = selectedChannel?.jobContract?.flatPrice;
    const jobHourlyRate = selectedChannel?.job?.hourlyRate;
    const jobFlatPrice = selectedChannel?.job?.flatPrice;

    let pricePerHour = jobHourlyRate;
    let pricePerJob = jobFlatPrice;

    if (jobContractHourlyRate) {
      pricePerHour = jobContractHourlyRate;
    }

    if (jobContractFlatPrice) {
      pricePerJob = jobContractFlatPrice;
    }

    dispatch({
      type: OPEN_JOB_APPLY_MODAL,
      payload: {
        show: true,
        jobId: selectedChannel?.job._id,
        pricePerHour,
        pricePerJob
      }
    });
  };

  const setSuggestionPopoverVisibility = (visible: boolean) => {
    dispatch({
      type: SET_SUGGESTION_POPOVER,
      payload: {
        visible,
        ownerIsMe: user._id === selectedChannel.doerId ? true : false,
        cleanText: true
      }
    });
  };

  useEffectAsync(async () => {
    if (selectedChannel?.job._id) {
      try {
        const job = await getJob(selectedChannel.job._id);
        const filesObj: any = [];
        if (job.video) {
          filesObj.push({
            type: 'VIDEO',
            path: job.video?.thumbnail,
            original: job.video?.original
          });
        }
        job?.photos?.map(photo => {
          filesObj.push({
            type: 'PHOTO',
            path: photo
          });
        });

        setFiles(filesObj);
      } catch (error) {
        alert.error('Job not found.');
        navigate('/explore');
      }
    }
    if (selectedChannel?.jobContract?.flatPrice && selectedChannel?.jobContract?.hourlyRate) {
      setPriceType({ flatPrice: selectedChannel?.jobContract?.flatPrice });
    }
  }, [selectedChannel]);

  const EditBtnGroup = (
    <Row justify="space-between" className="w-full">
      <DButton
        type="light-green"
        onClick={openEditModal}
        className={classNames(style.btn, style.editBtn)}
      >
        {profileType === 'master' ? 'Edit Offer Price' : 'Edit Job Price'}
      </DButton>
      <DButton onClick={() => onCancel()} className={style.cancelBtn} type="secondary">
        Cancel Offer
      </DButton>
    </Row>
  );

  const onChange = (val: 'flatPrice' | 'hourlyRate') => {
    if (selectedChannel?.jobContract) {
      setPriceType({ [val]: selectedChannel?.jobContract[val] });
    }
  };

  // const openCompleteReportModal = (action: string) => {
  //   dispatch({
  //     type: action,
  //     payload: {
  //       id: selectedChannel?.jobContractId,
  //       // messageId: id,
  //       // groupId: groupId,
  //       show: true
  //     }
  //   });
  // };

  const AcceptBtnGroup = (
    <Space direction="vertical" size={12} className="w-full">
      {selectedChannel?.jobContract?.flatPrice &&
        selectedChannel?.jobContract?.flatPrice > 0 &&
        selectedChannel?.jobContract?.hourlyRate &&
        selectedChannel?.jobContract?.hourlyRate > 0 && (
          <div>
            <div className="mb-2">
              <TextField
                text={
                  <span className={classNames(style.priceTitle, 'ml-1')}>
                    Fixed price
                    <span className={classNames(style.priceValue, 'ml-1')}>
                      (${selectedChannel?.jobContract?.flatPrice})
                    </span>
                  </span>
                }
                onChange={() => onChange('flatPrice')}
                isChecked={priceType && priceType['flatPrice'] ? true : false}
                side="right"
                customClasses="shadow-none h-[25px] p-0 justify-start"
              />
            </div>
            <div className="mb-2">
              <TextField
                text={
                  <span className={classNames(style.priceTitle, 'ml-1')}>
                    Hourly rate
                    <span className={classNames(style.priceValue, 'ml-1')}>
                      (${selectedChannel?.jobContract?.hourlyRate}/h)
                    </span>
                  </span>
                }
                onChange={() => onChange('hourlyRate')}
                isChecked={priceType && priceType['hourlyRate'] ? true : false}
                side="right"
                customClasses="shadow-none h-[25px] p-0 justify-start"
              />
              <span></span>
            </div>
          </div>
        )}

      <Row justify="center">
        <DButton
          type="light-green"
          text="Accept offer"
          onClick={() => requestAcceptJobContract(selectedChannel?.jobContractId, priceType)}
          className={classNames(style.btn, 'w-full')}
        />
      </Row>
      <Row justify="space-between">
        <DButton
          type="secondary"
          text="Reject"
          onClick={() => onReject()}
          className={classNames(style.cancelBtn, 'px-12')}
        />
        <DButton
          className={classNames(style.cancelBtn, 'px-5')}
          type="secondary"
          onClick={() => setSuggestionPopoverVisibility(true)}
          text="Suggest Price"
        />
      </Row>
    </Space>
  );

  const checkIsHoursWorked = () => {
    if (selectedChannel?.jobContract && selectedChannel?.jobContract.hoursWorked) {
      return true;
    }
    return false;
  };

  const ReportApproveBtnGroup = (
    <Space direction="vertical" size={12} className="w-full">
      {checkIsHoursWorked() && (
        <div className={classNames(style.workedHouersBlock, 'p-4')}>
          <div className="flex justify-between py-1">
            <div className={classNames(style.label)}>Worked</div>
            <div className={classNames(style.value)}>
              {selectedChannel?.jobContract?.hoursWorked} hours
            </div>
          </div>
          <div className="flex justify-between py-1">
            <div className={classNames(style.label)}>To be paid</div>
            {selectedChannel?.jobContract?.hourlyRate &&
              selectedChannel?.jobContract?.hoursWorked && (
                <div className={classNames(style.value)}>
                  ${' '}
                  {selectedChannel?.jobContract?.hoursWorked *
                    selectedChannel?.jobContract?.hourlyRate}
                </div>
              )}
          </div>
        </div>
      )}

      <Row justify="center" className="">
        <DButton
          className={classNames(
            style.cancelBtn,
            'h-12 !w-full max-w-[300px]  !p-0 !px-2 text-white font-bold text-font-13'
          )}
          type="light-green"
          onClick={async () =>
            await requestApproveCompletedJob(
              selectedChannel?.jobContractId as string,
              messageForAction?.id as string
            )
          }
          text="Accept & Pay"
        />
        <DButton
          type="default"
          text="Report Issue"
          onClick={() => openCompleteReportModal(OPEN_REPORT_ISSUE_MODAL)}
          className={classNames(
            style.cancelBtn,
            'h-12 !p-0  !w-full max-w-[300px] mt-2 !px-2 text-white font-bold text-font-13'
          )}
        />
      </Row>
    </Space>
  );
  const ReadyToStart = (
    <Space direction="vertical" size={12} className="w-full">
      <Row justify="center">
        <DButton
          className={classNames('h-[40px] w-[200px] !p-0 !px-2 text-white font-bold text-font-16')}
          type="light-green"
          onClick={async () =>
            await requestStartJob(
              selectedChannel?.jobContractId as string,
              messageForAction?.id as string
            )
          }
          text="Start the job"
        />
      </Row>
    </Space>
  );

  const MarkAsComplated = (
    <Space direction="vertical" size={12} className="w-full">
      <Row justify="center">
        <DButton
          className={classNames(
            'h-[40px]  !p-0 !px-2  w-[200px] text-white font-bold text-font-16'
          )}
          type="light-green"
          onClick={() => openCompleteReportModal(OPEN_COMPLETE_JOB_MODAL)}
          text={
            selectedChannel?.jobContract?.issueReported ? 'Job is completed ' : 'Mark as completed'
          }
        />
      </Row>
    </Space>
  );

  const ResumeTheJob = (
    <Space direction="vertical" size={12} className="w-full">
      <Row justify="center">
        <DButton
          className={classNames('h-[40px] w-[200px]  !p-0 !px-2 text-white font-bold text-font-16')}
          type="light-green"
          onClick={async () =>
            await requestResumeJob(
              selectedChannel?.jobContractId as string,
              messageForAction?.id as string
            )
          }
          text="Resume the job"
        />
      </Row>
    </Space>
  );

  const RIGHT_SIDEBAR_FOOTER: { [key: string]: any } = {
    DOER_INVITE_TO_JOB: {
      doer: EditBtnGroup,
      master: AcceptBtnGroup
    },
    MASTER_APPLY_JOB: {
      doer: AcceptBtnGroup,
      master: EditBtnGroup
    },
    MASTER_JOB_MARKED_AS_COMPLETED: {
      doer: ReportApproveBtnGroup
    },
    READY_TO_START: {
      master: ReadyToStart
    },
    MASTER_STARTED_JOB: {
      master: MarkAsComplated
    },
    RESUME_THE_JOB: {
      master: ResumeTheJob
    }
  };

  const addContentToModal = (activeItem: string | undefined, photos: any[], videos: any[]) => {
    const payloadTemp = {
      photos,
      videos,
      activeItem: activeItem
    };

    const filteredPayload = filterObj(payloadTemp, ['empty-array']);

    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: filteredPayload
    });
  };

  const handleOnMediaClick = (files: any, file: any) => {
    const photosTemp: string[] = [];
    const videosTemp: string[] = [];
    files?.forEach((file: any) => {
      switch (file.type) {
        case 'PHOTO':
          photosTemp.push(file.path);
          break;
        case 'VIDEO':
          videosTemp.push(file.original);
          break;
      }
    });
    addContentToModal(file.type === 'PHOTO' ? file.path : file.original, photosTemp, videosTemp);
  };

  const isOfferAccepted = () => {
    if (
      selectedChannel.status !== 'NEW' &&
      selectedChannel.status !== 'REJECTED' &&
      selectedChannel.status !== 'CANCELED'
    ) {
      return true;
    }
    return false;
  };

  const titleText = () => {
    if (isOfferAccepted()) {
      return 'Job Contract';
    }

    if (
      user._id === selectedChannel.doerId &&
      selectedChannel?.jobContract?.type === 'DOER_INVITE_TO_JOB'
    ) {
      return 'Your Job offer for a Master';
    } else if (
      user._id === selectedChannel.doerId &&
      selectedChannel?.jobContract?.type === 'MASTER_APPLY_JOB'
    ) {
      return 'Master’s offer for your Job';
    } else if (
      user._id === selectedChannel.masterId &&
      selectedChannel?.jobContract?.type === 'MASTER_APPLY_JOB'
    ) {
      return 'Your offer for the job';
    } else if (
      user._id === selectedChannel.masterId &&
      selectedChannel?.jobContract?.type === 'DOER_INVITE_TO_JOB'
    ) {
      return 'Job offer for you';
    }
    //Job Contract
    return 'Job Offer';
  };

  const onReject = () => {
    setCancelRejectModalState({
      visible: true,
      title: 'Rejecting offer',
      buttons: {
        cancel: { text: 'Return' },
        accept: {
          text: 'Reject Offer',
          action: async () => {
            try {
              setLoading(true);
              await requestRejectJobContract(selectedChannel?.jobContractId);
            } catch (err) {
              console.error(err);
            } finally {
              setLoading(false);
              setCancelRejectModalState(initalCancelRejectModalState);
            }
          }
        }
      },
      text: `Are you sure that you want to Reject offer from ${selectedChannel?.interlocutor.firstName} ?`
    });
  };

  const onCancel = () => {
    setCancelRejectModalState({
      visible: true,
      buttons: {
        cancel: { text: 'Return' },
        accept: {
          text: 'Cancel Offer',
          action: async () => {
            try {
              setLoading(true);
              await requestCancelJobContract(selectedChannel?.jobContractId);
            } catch (err) {
              console.error(err);
            } finally {
              setLoading(false);
              setCancelRejectModalState(initalCancelRejectModalState);
            }
          }
        }
      },
      title: 'Cancelling an offer',
      text: `Are you sure that you want to Cancel your offer for ${selectedChannel?.interlocutor.firstName}?`
    });
  };

  const priceRenderer = () => {
    const jobContractHourlyRate = selectedChannel?.jobContract?.hourlyRate;
    const jobHourlyRate = selectedChannel?.job?.hourlyRate;
    const jobContractFlatPrice = selectedChannel?.jobContract?.flatPrice;
    const jobFlatPrice = selectedChannel?.job?.flatPrice;

    const html = [];
    if (!isOfferAccepted()) {
      if (jobContractFlatPrice === undefined && jobContractHourlyRate === undefined) {
        if (jobHourlyRate) {
          html.push(
            <span key={1} className="">
              ${jobHourlyRate}/h
            </span>
          );
          if (jobFlatPrice) {
            html.push(' | ');
          }
        }
        if (jobFlatPrice) {
          html.push(
            <span key={2} className="">
              ${jobFlatPrice}/job
            </span>
          );
        }
        return html;
      }

      if (jobContractHourlyRate || jobContractFlatPrice) {
        if (jobContractHourlyRate && jobHourlyRate) {
          if (jobHourlyRate === jobContractHourlyRate) {
            html.push(
              <span key={3} className="">
                ${jobHourlyRate}/h
              </span>
            );
          } else {
            html.push(
              <span key={4} className="line-through mr-2">
                ${jobHourlyRate}/h
              </span>
            );
            html.push(
              <span key={5} className="">
                ${jobContractHourlyRate}/h
              </span>
            );
          }
          if (jobContractFlatPrice) {
            html.push(' | ');
          }
        } else if (jobHourlyRate === undefined && jobContractHourlyRate) {
          html.push(
            <span key={6} className="">
              ${jobContractHourlyRate}/h
            </span>
          );
          if (jobContractFlatPrice) {
            html.push(' | ');
          }
        }
        if (jobFlatPrice && jobContractFlatPrice) {
          if (jobFlatPrice === jobContractFlatPrice) {
            html.push(
              <span key={7} className="">
                ${jobFlatPrice}/job
              </span>
            );
          } else {
            html.push(
              <span key={8} className="line-through mr-2">
                ${jobFlatPrice}/job
              </span>
            );
            html.push(
              <span key={9} className="">
                ${jobContractFlatPrice}/job
              </span>
            );
          }
        } else if (jobFlatPrice === undefined && jobContractFlatPrice) {
          html.push(
            <span key={10} className="">
              ${jobContractFlatPrice}/job
            </span>
          );
        }
      }
    } else {
      if (jobContractHourlyRate) {
        html.push(
          <span key={11} className="">
            ${jobContractHourlyRate}/h
          </span>
        );
      }
      if (jobContractFlatPrice && jobContractHourlyRate) {
        html.push(' |  ');
      }
      if (jobContractFlatPrice) {
        html.push(
          <span key={12} className="">
            ${jobContractFlatPrice}/job
          </span>
        );
      }
    }
    return html;
  };

  const openCompleteReportModal = (action: string) => {
    dispatch({
      type: action,
      payload: {
        id: selectedChannel?.jobContractId,
        messageId: messageForAction?.id,
        groupId: messageForAction?.groupId,
        show: true
      }
    });
  };

  return (
    <>
      {selectedChannel && (
        <Col className="relative h-full">
          <Row
            align="middle"
            justify="space-between"
            className={classNames(
              'text-font-16 leading-[26px] px-6 ',
              !isOfferAccepted() ? borderClass : 'pt-[16px]'
            )}
          >
            <Text className="font-bold ">{titleText()}</Text>
            <i
              className="icon-22 text-[#707070] cursor-pointer"
              onClick={() => setIsJobOfferSectionOpen(false)}
            />
          </Row>
          {isOfferAccepted() && (
            <Row className="px-6 py-[16px] border-b-[1px] border-[#EBEBEB]">
              Contract ID: # {selectedChannel?.contractId}
            </Row>
          )}

          <Row className={classNames('text-font-14', 'overflow-auto', style.middleContent)}>
            <Col
              className="w-full overflow-x-hidden"
              style={{
                marginBottom: elementrRef?.current ? elementrRef?.current?.clientHeight - 20 : 20
              }}
            >
              <Row className={classNames('px-6 pt-3 pb-4', borderClass)}>
                <Space direction="vertical" size={12}>
                  <Text className={classNames('text-font-16 ', 'font-bold', style.textTwoLines)}>
                    {selectedChannel?.title}
                  </Text>
                  {selectedChannel?.job.pro && (
                    <Space align="center" size={12}>
                      <CircleBgIcon circleColor="orange" icon="icon-25" />
                      <Text className="text-font-14 font-normal">Pro account needed</Text>
                    </Space>
                  )}
                </Space>
              </Row>

              <Row className={classNames('px-6 pt-3 pb-4 ', borderClass)}>
                <Space direction="vertical" size={16}>
                  <Space size={8} direction="vertical">
                    <Text className="font-bold">Created By</Text>
                    <ChatHeaderProfileInfo
                      fontSize="text-font-14"
                      data={{
                        lastName:
                          selectedChannel?.doerId === user._id
                            ? user.lastName
                            : selectedChannel?.interlocutor.lastName,
                        firstName:
                          selectedChannel?.doerId === user._id
                            ? user.firstName
                            : selectedChannel?.interlocutor.firstName,
                        fullName: `${selectedChannel?.doerName} ${
                          selectedChannel?.doerId === user._id ? '(You)' : ''
                        }`,
                        img:
                          selectedChannel?.doerId === user._id
                            ? user.profilePicture
                            : selectedChannel.interlocutor.profilePicture
                      }}
                      showBadges={false}
                      showReviews={false}
                    />
                  </Space>
                  <Space size={8} direction="vertical">
                    <Text className="font-bold">Master</Text>
                    <ChatHeaderProfileInfo
                      data={{
                        lastName:
                          selectedChannel?.masterId === user._id
                            ? user.lastName
                            : selectedChannel?.interlocutor.lastName,
                        firstName:
                          selectedChannel?.masterId === user._id
                            ? user.firstName
                            : selectedChannel?.interlocutor.firstName,
                        fullName: `${selectedChannel?.masterName} ${
                          selectedChannel?.masterId === user._id ? '(You)' : ''
                        }`,
                        img:
                          selectedChannel?.masterId === user._id
                            ? user.profilePicture
                            : selectedChannel.interlocutor.profilePicture,
                        pro: selectedChannel?.interlocutor.pro,
                        bestMatch: selectedChannel?.interlocutor.bestMatch
                      }}
                      showBadges={true}
                      showReviews={false}
                    />
                  </Space>
                </Space>
              </Row>

              <Row className={classNames('px-6 pt-3 pb-4')}>
                <Space direction="vertical" size={20}>
                  <Space direction="vertical" size={0}>
                    <Text className="font-bold">Location</Text>
                    <Text>{selectedChannel?.job.address}</Text>
                  </Space>
                  {(selectedChannel?.job.startDate || selectedChannel?.job.endDate) && (
                    <Space size={75}>
                      {selectedChannel?.job.startDate && (
                        <Space direction="vertical">
                          <Text className="font-bold">Start Date</Text>
                          <Text>
                            {moment(selectedChannel?.job.startDate).format('MMM DD,yyyy')}
                          </Text>
                        </Space>
                      )}
                      {selectedChannel?.job.endDate && (
                        <Space direction="vertical">
                          <Text className="font-bold">Deadline</Text>
                          <Text>{moment(selectedChannel?.job.endDate).format('MMM DD,yyyy')}</Text>
                        </Space>
                      )}
                    </Space>
                  )}
                </Space>
              </Row>
              <Row className={classNames('px-6 pt-3 pb-4', 'bg-[#FFF6F0]')}>
                <Space direction="vertical" size={0}>
                  <Text className="font-bold">Price</Text>

                  <Text className="w-full text-font-13">
                    <Text className="font-bold">{priceRenderer()}</Text>
                    <br />
                    {/* Suggested price section */}
                  </Text>
                </Space>
              </Row>
              <Row className={classNames('px-6 pt-3 pb-4')}>
                <Space direction="vertical" size={0}>
                  <Text className="font-bold">Description</Text>
                  <Text>{selectedChannel?.job?.description} </Text>
                </Space>
              </Row>
              <Row gutter={10} justify={'center'} wrap>
                {files?.map((file: { type: string; path: string }, index: any) => {
                  if (file.type === 'PHOTO' || file.type === 'VIDEO') {
                    return (
                      <Col
                        key={index}
                        onClick={() => handleOnMediaClick(files, file)}
                        className="cursor-pointer mt-3"
                      >
                        <MessageMediaCard
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${
                            file.type === 'PHOTO'
                              ? file.path.replace('original', '100x100')
                              : file.path
                          }`}
                          height={100}
                          width={100}
                          preview={false}
                          mediaType={file.type}
                          className="border-solid border-2"
                          videoPlayerIcon={file.type === 'VIDEO'}
                        />
                      </Col>
                    );
                  }
                })}
              </Row>
            </Col>
          </Row>
          {RIGHT_SIDEBAR_FOOTER[selectedChannel?.jobContract?.type || ''] &&
            selectedChannel?.status === 'NEW' && (
              <Row
                ref={elementrRef}
                className="px-6 pt-3 pb-4 absolute z-[5] w-full bottom-0 shadow-[0px_0px_4px_#00000029] bg-white"
              >
                {RIGHT_SIDEBAR_FOOTER[selectedChannel?.jobContract?.type || ''][profileType]}
              </Row>
            )}
          {selectedChannel?.status === 'PENDING' && (
            <Row
              ref={elementrRef}
              className="px-6 pt-3 pb-4 absolute z-[5] w-full bottom-0 shadow-[0px_0px_4px_#00000029] bg-white"
            >
              {RIGHT_SIDEBAR_FOOTER['MASTER_JOB_MARKED_AS_COMPLETED'][profileType]}
            </Row>
          )}
          {selectedChannel?.status === 'READY_TO_START' && (
            <Row
              ref={elementrRef}
              className="px-6 pt-3 pb-4 absolute z-[5] w-full bottom-0 shadow-[0px_0px_4px_#00000029] bg-white"
            >
              {RIGHT_SIDEBAR_FOOTER['READY_TO_START'][profileType]}
            </Row>
          )}
          {selectedChannel?.status === 'ISSUE_ACCEPTED' && (
            <Row
              ref={elementrRef}
              className="px-6 pt-3 pb-4 absolute z-[5] w-full bottom-0 shadow-[0px_0px_4px_#00000029] bg-white"
            >
              {RIGHT_SIDEBAR_FOOTER['RESUME_THE_JOB'][profileType]}
            </Row>
          )}
          {selectedChannel?.status === 'IN_PROGRESS' && (
            <Row
              ref={elementrRef}
              className="px-6 pt-3 pb-4 absolute z-[5] w-full bottom-0 shadow-[0px_0px_4px_#00000029] bg-white"
            >
              {RIGHT_SIDEBAR_FOOTER['MASTER_STARTED_JOB'][profileType]}
            </Row>
          )}

          <Modal
            className={style.applyModal}
            visible={applyModal.show}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={closeApplyModal}
          >
            <EditJobPrice
              api={requestChangePriceJobContract}
              modalTitle={
                selectedChannel.type === 'MASTER_APPLY_JOB' ||
                selectedChannel.type === 'DOER_INVITE_TO_JOB'
                  ? 'Suggest new price '
                  : 'Edit Job price'
              }
              priceSectionTitle={
                profileType === 'master'
                  ? 'Fill in the new price you want to apply to job *'
                  : 'Fill in the new price for your job'
              }
              messageSectionTitle={
                profileType === 'master' ? 'Add note for the job owner' : 'Add note for the Master'
              }
              applyButtonText={
                profileType === 'master' ? 'Apply with new price' : 'Change job price'
              }
              messageDefaultMessage="Hi! The price is changed!"
              jobContractId={selectedChannel?.jobContract?._id}
            />
          </Modal>
          <Modal
            className={style.applyModal}
            visible={cancelRejectModalState.visible}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={closeApplyModal}
          >
            <Col className="apply-job-container">
              <Row className="flex justify-center">
                <Text className="text-font-22 font-bold">{cancelRejectModalState.title} </Text>
              </Row>
              <Row className="mt-8 w-full">
                <Text className="text-font-20 font-bold">{cancelRejectModalState.text}</Text>
              </Row>

              <Row justify="end" className="mt-[40px]">
                <Space size={16}>
                  <DButton
                    type="white"
                    className="!w-[148px] !h-[44px]"
                    onClick={() => setCancelRejectModalState(initalCancelRejectModalState)}
                  >
                    {cancelRejectModalState?.buttons?.cancel.text}
                  </DButton>
                  <DButton
                    type="light-green"
                    className="text-font-16 font-bold text-white w-[200px] h-[44px]"
                    onClick={() => cancelRejectModalState?.buttons?.accept.action()}
                    button
                    disabled={loading}
                    loading={loading}
                  >
                    {cancelRejectModalState?.buttons?.accept.text}
                  </DButton>
                </Space>
              </Row>
            </Col>
          </Modal>

          <Modal
            className={style.modal}
            visible={completeJobModal.show}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={() => closeReportCompleteJobModal(CLOSE_COMPLETE_JOB_MODAL)}
          >
            <ReportCompleteJob
              api={requestCompleteJob}
              cancelBtnText="Cancel"
              report={false}
              submitBtnText="Complete Job"
              mediaTitle="Upload the photos/videos of completion"
              messageTitle="Message for Job owner"
              messageDefaultValue="Hi! I’ve completed the job. Attaching some photos and videos for you to see the result. Hope everything is ok"
              messagePlacehodler="Hi! I’ve completed the job. Attaching some photos and videos for you to see the result. Hope everything is ok"
              title="Complete the job "
              onCancelClick={() => closeReportCompleteJobModal(CLOSE_COMPLETE_JOB_MODAL)}
              apiData={{
                id: completeJobModal.id,
                messageId: completeJobModal.messageId
              }}
            />
          </Modal>
          <Modal
            className={style.modal}
            visible={reportIssueModal.show}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={() => closeReportCompleteJobModal(CLOSE_REPORT_ISSUE_MODAL)}
          >
            <ReportCompleteJob
              api={requestReportIssueJob}
              cancelBtnText="Cancel"
              report={true}
              submitBtnText="Report an issue"
              mediaTitle="Upload the photos/videos of the issue"
              messageTitle="What was wrong with the job?"
              messageDefaultValue={`Hi! Can you please fix this issue as well?`}
              messagePlacehodler="Hi! Can you please fix this issue as well?"
              title="Report an issue"
              acceptBtnClass="w-[220px]"
              onCancelClick={() => closeReportCompleteJobModal(CLOSE_REPORT_ISSUE_MODAL)}
              apiData={{
                id: reportIssueModal.id,
                messageId: reportIssueModal.messageId
              }}
            />
          </Modal>
          <Modal
            className={style.modal}
            visible={declineIssueModal.show}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={() => closeReportCompleteJobModal(CLOSE_DECLINE_ISSUE_MODAL)}
          >
            <ReportCompleteJob
              api={requestDeclineReportedIssueJob}
              cancelBtnText="Cancel"
              submitBtnText="Decline Reported Issue"
              report={true}
              mediaTitle="Upload the photos/videos"
              messageTitle="Decline reason"
              messageDefaultValue={`Hi! I see that you thing that job is not fully done according to the contract. Attaching more photos and videos for you to see the result. Hope everything is ok now and you can approve the work.`}
              messagePlacehodler="Hi! I see that you thing that job is not fully done according to the contract. Attaching more photos and videos for you to see the result. Hope everything is ok now and you can approve the work."
              title="Decline reported Issue(s)"
              acceptBtnClass="w-[270px]"
              onCancelClick={() => closeReportCompleteJobModal(CLOSE_DECLINE_ISSUE_MODAL)}
              apiData={{
                id: declineIssueModal.id,
                messageId: declineIssueModal.messageId
              }}
            />
          </Modal>
          <Modal
            className={style.modal}
            visible={feedbackModal.show}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={() => dispatch({ type: CLOSE_FEEDBACK_MODAL })}
          >
            <FeedbackModal
              img={selectedChannel.interlocutor.profilePicture}
              firstName={selectedChannel.interlocutor.firstName}
              lastName={selectedChannel.interlocutor.lastName}
              name={
                selectedChannel?.doerId === user._id
                  ? selectedChannel?.masterName
                  : selectedChannel?.doerName
              }
            />
          </Modal>
          <Modal
            className={style.modal}
            visible={tipModal.show}
            footer={false}
            destroyOnClose
            centered
            closable={false}
            onCancel={() => dispatch({ type: CLOSE_TIP_MODAL })}
          >
            <TipModal
              img={selectedChannel.interlocutor.profilePicture}
              name={selectedChannel?.masterName}
              firstName={selectedChannel.interlocutor.firstName}
              lastName={selectedChannel.interlocutor.lastName}
            />
          </Modal>
        </Col>
      )}
    </>
  );
};

export default ChatRightSidebar;
