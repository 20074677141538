
import classNames from 'classnames';
import { NotificationType } from 'common';
import { getSystemMessage } from 'components/Chat/components/SystemMessageConfiguration';
import moment from 'moment';
import { getSystemNotification } from './Configuration';
import styles from './index.module.scss';

type NotificationItemType ={
    type: string;
    status:string;
    createdAt?: string;
    channelId:string;
    seen:boolean;
    doerName:string;
    masterName:string;
    jobTitle:string;
    metaData?:{tipAmount?:number,paidAmount?:number,doerRatingStart?:number,masterRatingStart?:number};
    onNavigate:(channelId:string)=> void
}

const NotificationItem = ({type,createdAt,status,channelId,seen,doerName,masterName,jobTitle,onNavigate,metaData}:NotificationItemType) =>{


const notification = getSystemNotification({type,doerName,masterName,jobTitle,metaData})



return(
  <>
          <div onClick={()=> onNavigate(channelId)} className={classNames(styles.content, 'cursor-pointer  p-5 relative flex items-start')}>
           <div  className={classNames(styles.iconFit, 'relative flex-none w-8 h-8 mr-1 icon-fit')}>
                  <span
                    className="rounded-full"
                  ></span>
                </div>
                <div className="ml-2 w-full overflow-hidden">
                  <div className="flex justify-between	 items-center">
                    <span  className={classNames(styles.title, 'mr-5 text-[#313233] text-medium truncate')}>
                     {notification?.title}
                    </span>
                    <div className="ml-auto text-xs text-slate-400 whitespace-nowrap">
                           {moment(createdAt).format('LT')}
                    </div>
                  </div>
                  <div  className={classNames(styles.text, 'w-full text-[12px] text-slate-500 mt-0.5')}>
                       {notification?.text}
                  </div>
                </div>
          </div>
  
  </>
)
}

export default NotificationItem
