import api from './index';
import {
  NotificationFilterRequest,
  NotificationItem,
  UnseenNotificationCountResponse
} from 'common';
import qs from 'qs';

export const getNotifications = (
  jsonData?: NotificationFilterRequest
): Promise<NotificationItem[]> => {
  return api.get(`/notifications`, {
    params: jsonData,
    paramsSerializer: jsonData => {
      return qs.stringify(jsonData);
    }
  });
};

export const getNotificationsUnseenCount = (): Promise<UnseenNotificationCountResponse> => {
  return api.get(`/notifications/unseen-count`);
};
export const notificationsMarkAllAsSeen = (): Promise<string> => {
  return api.put(`/notifications/mark-all-as-seen`);
};

export const getExternalUserIdAuthHash = (playerId: string): Promise<string> => {
  return api.get(`/notifications/external-user-id-auth-hash/${playerId}`);
};
