import {Col, Divider, Row, Typography} from "antd";
import classNames from "classnames";
import {JobItem, JobsFilterResponse} from "common";
import PostedJobItem from "components/PostedJobItem";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {fetchGetMyJobs} from "redux/user/actions";
import {myJobs, userIsLoading} from "redux/user/selectors";

import styles from './index.module.scss';
import {SERVICES_COLORS, SERVICES} from '../../../constants';
import Video from "components/Video";
import ServicesInfo from "../Modals/ServicesInfo";
import DButton from "components/DButton";
import {showPostedDate, showJobPrices, toFixedUserRating, showEndStartDate} from "helpers";
import LabelData from "../Modals/LabelData";
import ProfileInfo from "../Modals/ProfileInfo";
import {GALLERY_MODAL_OPEN} from "redux/modals/actionTypes";
import vid from 'assets/images/vdo.png';
import {setRedirectedChanelId} from "redux/chat/actions";
import {useNavigate} from "react-router-dom";
import {deactivateJob} from "api/jobs";
import MaskLoader from "components/MaskLoader";

const {Text} = Typography;


const PostedJobs = () => {

  const jobsList: Array<JobItem> = useSelector(myJobs);
  const isLoading: boolean = useSelector(userIsLoading);
  const dispatch = useDispatch();
  const virtuoso = useRef<any>(null);
  const [selectedJob, setSelectedJob] = useState<JobItem>()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchGetMyJobs({}));
  }, [])

  const loadMoreJobs = () => {

  }


  const onClickJobItem = (id: string) => {

    const job = jobsList.filter((item) => id === item._id)
    setSelectedJob(job[0])
  }

  useEffect(() => {
    if (!selectedJob) {
      setSelectedJob(jobsList[0])
    }
  }, [jobsList])

  const showDates = () => {
    const {start, end, both} = showEndStartDate(selectedJob?.startDate, selectedJob?.endDate);
    if (both) {
      return <LabelData classes="mb-3" label="Date:" value={both}/>;
    }
    if (start) {
      return <LabelData classes="mb-3" label="Start date:" value={start}/>;
    }
    if (end) {
      return <LabelData classes="mb-3" label="End date:" value={end}/>;
    }
  };

  const openGalleryModal = (activeItem: string | undefined) => {
    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: {
        photos: selectedJob?.photos,
        videos: [selectedJob?.video?.original],
        activeItem
      }
    });
  };

  const onNavigateToChat = (id: string) => {

    dispatch(
      setRedirectedChanelId({
        redirectedChanelId: id
      })
    );
    navigate('/chat')
  }


  const onDeactivateJob = async () => {
    try {
      if (selectedJob) {
        const result = await deactivateJob(selectedJob._id || '');
        dispatch(fetchGetMyJobs({}));
        setSelectedJob(result);
      }
    } catch (e) {
      console.error(e)
    }

  }
  return (
    <>
      {isLoading && <MaskLoader/>}
      {selectedJob &&
        (
          <Row className={classNames(styles.container, 'pt-5 ')}>
            <Col span={24}>
              <Row>
                <Col span={7} className="">
                  <div className={classNames(styles.sectionTitle, 'ml-1')}>Posted Jobs</div>
                  <div className={classNames(styles.jobsList, 'overflow-x-hidden overflow-y-auto')}>
                    {jobsList.length > 0 && (
                      <Virtuoso className={classNames(styles.virtuosoStyle, ' overflow-x-hidden')}
                                ref={virtuoso}
                                endReached={loadMoreJobs}
                                data={jobsList}
                                overscan={200}

                                style={{
                                  width: "100%",
                                  // height: "100vh",
                                }}
                                computeItemKey={(index: number) => index}

                                itemContent={(index: number, item: JobItem) => {
                                  const {_id, title, createdAt, video, status, channelId} = item
                                  return (
                                    <PostedJobItem
                                      title={title}
                                      status={status}
                                      channelId={channelId}
                                      id={_id || ''}
                                      isActive={selectedJob?._id === _id}
                                      createdAt={createdAt}
                                      onClick={onClickJobItem}
                                      viewChat={onNavigateToChat}
                                      video={video}/>
                                  )
                                }}/>
                    )

                    }
                  </div>

                </Col>
                <Col span={17}>
                  <div className={classNames(styles.jobDetails)}>
                    <div className={classNames(styles.sectionTitle, '')}>Job Details</div>
                    <div className={classNames(styles.jobDetailsBody, ' overflow-y-auto overflow-x-hidden')}>
                      <Col>

                        <Row  >
                          <Col>
                            <Row className={styles.imgContainer}>
                              <Video
                                classes={classNames(styles.imgItem)}
                                thumb={process.env.REACT_APP_MEDIA_BASE_URL + selectedJob?.video?.thumbnail}
                                size="lg"
                                onClick={() => openGalleryModal(selectedJob?.video?.original)}
                              />
                              {(selectedJob?.photos || []).map((item, key) => (
                                <Col
                                  className="cursor-pointer"
                                  key={key.toString()}
                                  onClick={() => openGalleryModal(item)}
                                >
                                  <div className={classNames(styles.imgItem)}>
                                    <img src={process.env.REACT_APP_S3_BUCKET + item} alt=""/>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                        <Row className={styles.infoContainer}>
                          <Col className=" flex-1 flex flex-col mt-4">
                            <Row>
                              <Col>

                                <Text  className={classNames(styles.title, 'block')} >
                                  {selectedJob?.title}
                                </Text>
                                <Text className="text-font-22 font-bold mr-4 my-3 block">
                                  {showJobPrices({hourly: selectedJob?.hourlyRate, flat: selectedJob?.flatPrice, long: true})}
                                </Text>
                                <ServicesInfo clickable={false} data={(selectedJob?.services || []).map(item => item)}/>
                                <div className="mt-1">
                                  <LabelData
                                    classes={classNames('mb-3', styles.labelData, 'items-start')}
                                    label="Location:"
                                    value={selectedJob?.address}
                                  />
                                  <Text>{showDates()}</Text>
                                  <LabelData
                                    classes={classNames('mb-3', styles.labelData, 'items-start')}
                                    label="Job posted:"
                                    value={showPostedDate(selectedJob?.createdAt)}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row justify="space-between">

                              {!selectedJob?.pro && (
                                <Row align="middle" className="mt-3">
                                  <div className={classNames(styles.iconContainer, styles.orange)}>
                                    <i className="icon-25 text-font-12 text-white"/>
                                  </div>
                                  <Text className="font-bold text-light-gray ml-2">Pro Account Needed</Text>
                                </Row>
                              )}

                              {selectedJob?.bestMatch && (
                                <Row align="middle">
                                  <div className={classNames(styles.iconContainer, styles.blue)}>
                                    <i className="icon-26 text-font-12 text-white"/>
                                  </div>
                                  <Text className="font-bold text-light-gray ml-2">Best Match</Text>
                                </Row>
                              )}
                            </Row>
                            <Row className="mt-1">
                              <Col className="flex-1">

                                <Divider className="mt-4 mb-0"/>
                                {selectedJob?.description && (
                                  <>
                                    <Text className="font-bold mt-4 block">Description</Text>
                                    <Text className="font-doers-black block text-font-16">{selectedJob.description}</Text>
                                    <Divider className="mt-3 mb-0"/>
                                  </>
                                )}
                                {(selectedJob && selectedJob._id) && (
                                  <Row className="py-5 justify-center">
                                    <DButton
                                      type="white"
                                      onClick={onDeactivateJob}
                                      className={styles.deactivateJobBtn}
                                      disabled={selectedJob.status === 'DEACTIVATED'}
                                    >
                                      <Text className="font-bold text-[#18191A]">
                                        {selectedJob.status === 'DEACTIVATED' ? <>Deactevated</> : <>Deactivate Job</>}
                                      </Text>
                                    </DButton>
                                  </Row>
                                )}

                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      </Col>
                    </div>
                    <div>
                    </div>

                  </div>

                </Col>

              </Row>
            </Col>


          </Row>
        )
      }

    </>
  )
}

export default PostedJobs;
