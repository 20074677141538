import { useState } from 'react';
import classNames from 'classnames';
import { Row, Typography } from 'antd';

const { Text } = Typography;

interface IJobServiceCard {
  title: string;
  onClick: () => void;
  classes?: string;
}

const JobServiceCard = ({ title, classes, onClick }: IJobServiceCard) => {
  const [isSelected, setIsSelected] = useState(false);

  const onClickHandle = () => {
    setIsSelected(!isSelected);
    onClick();
  };

  return (
    <Row
      onClick={onClickHandle}
      justify="center"
      align="middle"
      className={classNames(
        'rounded-[36px] border-[1px]',
        'h-9',
        'cursor-pointer',
        { 'border-[#49C43B]': isSelected },
        { 'border-[#E6E6E6]': !isSelected },
        classes
      )}
    >
      <Text
        className={classNames(
          'font-normal text-font-14 leading-[13px]',
          'ml-[18px] mr-[18px]',

          {
            'text-[#49C43B]': isSelected
          }
        )}
      >
        {title}
      </Text>
    </Row>
  );
};

export default JobServiceCard;
