import api from 'api';
import axios from 'axios';
import { MediaItem, PurposeType } from 'common';

export const getAddressFromCoords = ([lon, lat]: number[]): Promise<any> => {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lon}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&language=en`
    )
    .then(({ data: { results } }) => results);
};

export const getPresignedUrl = (
  contentType: string,
  mediaType: string,
  purpose: PurposeType,
  referenceId?: string
): Promise<any> => {
  return api.get(
    `/media/signed-url?contentType=${contentType}&mediaType=${mediaType}&purpose=${purpose}${
      referenceId ? `&referenceId=${referenceId}` : ''
    }`
  );
};

export const uploadFile = (url: string, formData: any): Promise<any> => {
  return axios.post(url, formData);
};

export const docUploaded = (uuid: string): Promise<MediaItem> => {
  return api.post(`/media/${uuid}/uploaded`);
};
