/** @format */

import axios, { AxiosError } from 'axios';
import {
  JobContractItem,
  JobContractItemLite,
  UpdateJobContractLastSystemMessageRequest,
} from '../model/business/job-contract';
import { StatusError } from '../model/errors';

const jobContractServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.JOB_CONTRACT_SERVICE_URL!,
  apiKey: 'testKey',
};

export const getJobContractById = async (jobContractId: string): Promise<JobContractItem | null> =>
  getData<JobContractItem>(`/${jobContractId}`);

export const getJobContractListByIds = async (
  jobContractIds: Array<string>,
): Promise<JobContractItemLite[]> =>
  getData<JobContractItemLite[]>('/list', {
    params: {
      jobContractIds: jobContractIds,
    },
  });

export const setLastSystemMessageId = async (
  jobContractId: string,
  data: UpdateJobContractLastSystemMessageRequest,
) => putData<string>(`/${jobContractId}/last-system-message`, data);

interface JobContractApiResponse {}
interface JobContractApiError {
  name: string;
  message: string;
}

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const putData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.put<JobContractApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const handleHttpError = (error: AxiosError<JobContractApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${jobContractServiceConfig.url}${path}`;
