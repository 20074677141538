import { getNotifications, getNotificationsUnseenCount, notificationsMarkAllAsSeen } from 'api/notification';
import { NotificationFilterRequest, NotificationItem, UnseenNotificationCountResponse } from 'common';
import { all, takeLatest, fork, put, call } from 'redux-saga/effects';

import * as types from './types';

function* getNotificationsSaga(action: { type: string; payload: NotificationFilterRequest }) {
  try {

    const response = (yield call(getNotifications,action.payload)) as NotificationItem[] | null;
    yield put({
      type: types.NOTIFICATION_GET_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.NOTIFICATION_GET_FAILED,
      //payload: e?.response?.data
    });
  }
}
function* notificationsLoadMoreSaga(action: { type: string; payload: NotificationFilterRequest }) {
  try {

    const response = (yield call(getNotifications,action.payload)) as NotificationItem[] | null;
    yield put({
      type: types.NOTIFICATION_LOAD_MORE_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.NOTIFICATION_GET_FAILED,
      //payload: e?.response?.data
    });
  }
}

function* notificationsUnseenSaga(action: { type: string; }) {
  try {
    const response = (yield call(getNotificationsUnseenCount)) as UnseenNotificationCountResponse | null;
    yield put({
      type: types.NOTIFICATION_UNSEEN_GET_SUCCESS,
      payload: response
    });
    
  } catch (e) {
   console.error();
//unseenCount
  }
}
function* notificationsMarkAsSeen(action: { type: string; }) {
  try {
    const response = (yield call(notificationsMarkAllAsSeen)) as string | null;
    yield put({
      type: types.NOTIFICATION_UNSEEN_GET_SUCCESS,
      payload: {unseenCount:0}
    });
    
  } catch (e) {
   console.error();

  }
}

function* watchNotifications() {
  yield takeLatest(types.NOTIFICATION_GET_REQUEST, getNotificationsSaga);
  yield takeLatest(types.NOTIFICATION_LOAD_MORE_REQUEST, notificationsLoadMoreSaga);
  yield takeLatest(types.NOTIFICATION_UNSEEN_GET_REQUEST, notificationsUnseenSaga);
  yield takeLatest(types.NOTIFICATION_MARK_AS_SEEN_REQUEST, notificationsMarkAsSeen);


}

export default function* notificationSagas() {
  yield all([fork(watchNotifications)]);
}
