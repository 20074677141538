import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './label.module.scss';

type Props = {
  children: JSX.Element;
  label: string;
  value: string;
};

const FloatLabel: React.FC<Props> = ({ children, label, value }): JSX.Element => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || (value && value.length !== 0) ? 'label-float' : '';

  return (
    <div
      className={styles['label-input-cont']}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={classNames(styles[labelClass], styles.label)}>{label}</label>
    </div>
  );
};

export default FloatLabel;
