import { Card, Col, Row, Avatar, Space, Typography, Tabs, Modal } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import type { TabsProps } from 'antd';
import About from './components/Tabs/About';
import Edit from './components/Edit';
import useUser from 'redux/user/use-user';
import { useDispatch, useSelector } from 'react-redux';
import { getPrifileByUserId } from 'redux/user/actions';
import { Profile, UserItem, UserResponse } from 'common';
import { userIsLoading, userProfile } from 'redux/user/selectors';
import Icon8 from 'assets/icons/svg-icon-8.svg';
import Icon1 from 'assets/icons/svg-icon-1.svg';
import Videos from './components/Tabs/Videos';
import MaskLoader from 'components/MaskLoader';
import DButton from 'components/DButton';
import Reviews from './components/Tabs/Reviews';
import { UPDATE_PROFILE_FAILED } from 'redux/user/types';

const { TabPane } = Tabs;
const { Title } = Typography;
const { Meta } = Card;

const UserProfile = () => {
  const [edit, setEdit] = useState<boolean>(false);
  const { user, keepUser } = useUser();
  const dispatch = useDispatch();
  const profileData: UserResponse = useSelector(userProfile);
  const isLoading: boolean = useSelector(userIsLoading);

  const onChange = (key: string) => {};

  useEffect(() => {
    dispatch(getPrifileByUserId({ userId: user._id }));
  }, []);

  return (
    <>
      {isLoading && <MaskLoader />}

      <div className={classNames(styles.content, '')}>
        <Title level={3}>Profile</Title>
        <Row>
          {!edit ? (
            <>
              <Col span={6}>
                <div className={styles.viewLeftside}>
                  <div className="text-center ">
                    <Space wrap size={16}>
                      {profileData?.profilePicture ? (
                        <Avatar
                          className="border-solid border-[1px] border-[#E0E0E0]"
                          size={98}
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${profileData?.profilePicture}`}
                        />
                      ) : (
                        <span className={styles.name}>
                          {profileData?.firstName && profileData?.firstName[0]}
                          {profileData?.firstName && profileData?.lastName[0]}
                        </span>
                      )}
                    </Space>
                  </div>
                  {/* <div  className={classNames(styles.editPhoto, 'text-center pt-[7px] py-[7px]')}>
                                    <span onClick={()=>setEdit(true)}>Edit photo</span>
                            </div> */}

                  <div className="text-center py-[10px] text-[20px] font-semibold ">
                    <span>{profileData?.fullName}</span>
                  </div>

                  {user.pro && (
                    <div className="text-center py-[10px] flex justify-center">
                      <img className="h-[21px] w-[21px]" src={Icon8} alt="icon" />
                      <span className="ml-2 text-[#515864] font-semibold text-[13px]">
                        {'Pro Account'}
                      </span>
                    </div>
                  )}

                  <div className="text-center py-[10px] flex justify-center">
                    <img className="" src={Icon1} alt="icon" />

                    <span className="ml-2 text-[#18191A] text-[13px]">
                      {profileData.licenseVerified ? (
                        <>License verified</>
                      ) : (
                        <>License not verified</>
                      )}
                    </span>
                  </div>
                </div>
                <div className={styles.becomeProMasterBlock}>
                  <div className={styles.becomeProMasterBlock_title}>Become Pro Master!</div>
                  <div className={styles.becomeProMasterBlock_text}>
                    Upload documents to get Pro verification.
                  </div>
                </div>
              </Col>
              <Col span={18} className={classNames(styles.viewRightSide, '')}>
                <Tabs
                  className={classNames('pl-3 tab text-light-gray', { bottomBorder: true })}
                  defaultActiveKey="2"
                  onChange={onChange}
                >
                  <TabPane tab={'Videos'} key={'1'}>
                    <div className={classNames(styles.tabContent, '')}>
                      <Videos userId={user._id} />
                    </div>
                  </TabPane>
                  <TabPane tab={'About'} key={'2'}>
                    <div className={classNames(styles.tabContent, '')}>
                      <About data={profileData} onEdit={() => setEdit(true)} />
                    </div>
                  </TabPane>
                  <TabPane tab={'Reviews'} key={'3'}>
                    <Reviews userId={user._id} />
                  </TabPane>
                </Tabs>
              </Col>
            </>
          ) : (
            <Edit data={profileData} onCancel={() => setEdit(false)} />
          )}
        </Row>
      </div>
    </>
  );
};
export default UserProfile;
