/** @format */

import axios, { AxiosError } from 'axios';
import { JobItem, JobItemLite, UpdateUserReviewsInJobsRequest } from '../model/business/job';
import { StatusError } from '../model/errors';

const jobServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.JOB_SERVICE_URL!,
  apiKey: 'testKey',
};

export const getJobById = async (jobId: string): Promise<JobItem | null> =>
  getData<JobItem>(`/${jobId}`);

export const getJobListByIds = async (jobIds: Array<string>): Promise<JobItemLite[]> =>
  getData<JobItemLite[]>('/list', {
    params: {
      jobIds,
    },
  });

export const updateUserReviewsForActiveJobs = async (data: UpdateUserReviewsInJobsRequest) =>
  patchData<string>(`/user-reviews`, data);

interface JobApiResponse {}
interface JobApiError {
  name: string;
  message: string;
}

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const patchData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.patch<JobApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const handleHttpError = (error: AxiosError<JobApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${jobServiceConfig.url}${path}`;
