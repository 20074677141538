/** @format */

import * as yup from 'yup';
import { UserStatus, UserRole, AdminRole, UserType, UserItemSecureResponse } from '../user';

export type SessionType = 'USER' | 'ADMIN' | 'API';
export type SessionStatus = 'WAITING_2FA' | 'OK' | 'CLOSED';

/** Payload object of the user for registration */
export type UserRegistrationRequest = {
  /** First name of the user */
  firstName: string;
  /** Last name of the user */
  lastName: string;
  /** Email of the user */
  email: string;
  /** Password of the user */
  password: string;
  /** Autogenerated username of the user */
  username?: string;
  /** Status of the user */
  status?: UserStatus;
  /** Email confirm token of the user */
  confirmToken?: string;
  /** Type of the user */
  type?: UserType;
  /** SocialId of the user */
  socialId?: string;
};

export const UserRegistrationRequestSchema = yup.object().shape({
  firstName: yup.string().min(1).max(40).required(),
  lastName: yup.string().min(1).max(40).required(),
  email: yup.string().required().email(),
  password: yup.string().when('type', {
    is: 'EMAIL',
    then: schema => schema.required().min(6).max(60),
    otherwise: schema => schema.min(0),
  }),
  type: yup.string().required().default('EMAIL'),
});

export type LoginRequest = {
  username: string;
  password: string;
  deviceId?: string;
};

export type LoginResponse = {
  token: string;
  _id?: string;
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  status?: UserStatus;
  role?: UserRole;
  adminRole?: AdminRole;
  type?: UserType;
  skip: boolean;
  userInfo?: UserItemSecureResponse;
  profilePicture?: string;
};

export const LoginRequestSchema = yup.object().shape({
  username: yup.string().required().email(),
  password: yup.string().required().min(6).max(60),
});

export type SocialLoginType = 'GOOGLE' | 'FACEBOOK' | 'APPLE';

export interface AuthSession {
  id: string;
  dateCreated: number;
  username: string;
  issued: number;
  exp: number;
  scopes: Array<string>;
  isAPI?: boolean;
  role?: UserRole;
  adminRole?: AdminRole;
  deviceId?: string;
}

/**
 * Identical to the Session type, but without the `issued` and `expires` properties.
 */
export type PartialSession = Omit<AuthSession, 'issued' | 'exp'>;

export interface EncodeResult {
  token: string;
  expires: number;
  issued: number;
}

type AppleLoginUser = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

export type LoginSocialRequest = {
  socialToken: string;
  user?: AppleLoginUser;
  deviceId?: string;
};

export const RequestPasswordResetCodeSchema = yup.object().shape({
  email: yup.string().required().email(),
});

export type RequestPasswordResetCode = {
  email: string;
};

export const SetPasswordResetCodeRequestSchema = yup.object().shape({
  code: yup.string().required().min(5).max(5),
});

export type SetPasswordResetCodeRequest = {
  code: string;
};

export const CheckResetPasswordCodeRequestSchema = yup.object().shape({
  email: yup.string().required().email(),
  code: yup.string().required().min(5).max(5),
});

export type CheckResetPasswordCodeRequest = {
  email: string;
  code: string;
};

export const ResetPasswordRequestSchema = yup.object().shape({
  email: yup.string().required().email(),
  code: yup.string().required().min(5).max(5),
  password: yup.string().required().min(6).max(60),
});

export type ResetPasswordRequest = {
  email: string;
  code: string;
  password: string;
};

export const ConfirmEmailRequestSchema = yup.object().shape({
  email: yup.string().required().email(),
  code: yup.string().required().min(5).max(5),
});

export type ConfirmEmailRequest = {
  email: string;
  code: string;
  deviceId?: string;
};

export const ResendConfirmEmailRequestSchema = yup.object().shape({
  email: yup.string().required().email(),
});

export type ResendConfirmEmailRequest = {
  email: string;
};

export type UpdateEmailConfirmCodeRequest = {
  code: string;
};
