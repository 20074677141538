import { Upload } from 'antd';
import { updateUserProfile } from 'api/user';
import clip from 'assets/images/clip.svg';
import classNames from 'classnames';
import { ProfileMasterUpdateRequest } from 'common';
import DButton from 'components/DButton';
import DotsAndButton from 'components/DotsAndButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { scalability } from 'constants/index';
import styles from './index.module.scss';
import { alert, asyncForEach, filterObj } from 'helpers';
import { doUploadFlow } from 'helpers';
import { UploadChangeParam } from 'antd/lib/upload';

const DocumentsUpload = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const { masterFlowData, keepBecomeMasterData, user, keepUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.profileType === 'MASTER') {
      navigate('/explore');
    }
  }, []);

  const onChange = (info: UploadChangeParam) => {
    keepBecomeMasterData({
      ...masterFlowData,
      documents: info.fileList.length
    });
    setFileList(info.fileList);
  };

  const props = {
    name: 'file',
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <i className="icon-23" />
    },
    accept: '.jpg,.jpeg,.png,.pdf'
  };

  const complete = async () => {
    setLoading(true);
    const data: ProfileMasterUpdateRequest = {
      lastName: user.lastName,
      firstName: user.firstName,
      ...(masterFlowData.introduction && { introduction: masterFlowData.introduction }),
      profileType: 'MASTER',
      accountType: masterFlowData.accountType,
      companyTaxId: masterFlowData.companyTaxId,
      companyName: masterFlowData.companyName,
      location: {
        type: 'Point',
        coordinates: masterFlowData.coords
      },
      ...(masterFlowData.address && { address: masterFlowData.address }),
      ...(masterFlowData.postalCode && { zipCode: masterFlowData.postalCode }),
      ...(masterFlowData.radius && {
        radius: masterFlowData.radius === 'nationwide' ? 0 : masterFlowData.radius
      }),
      scalability:
        masterFlowData.radius === 'nationwide'
          ? scalability.NATIONWIDE.value
          : scalability.AREA.value,
      hourlyRate: masterFlowData.genericPrices?.hourlyRate,
      flatPrice: masterFlowData.genericPrices?.flatPrice,
      services: masterFlowData.prices?.map(({ profession, ...rest }: any) => ({
        ...filterObj(rest, ['']),
        service: profession
      }))
    };
    try {
      const { video } = masterFlowData;
      if (video) {
        await doUploadFlow(video, 'VIDEO', 'PROFILE');
      }

      await asyncForEach(fileList, async doc => {
        await doUploadFlow(doc, 'DOCUMENT', 'PROFILE');
      });

      const updatedUser = await updateUserProfile(data);
      keepUser({ ...user, ...updatedUser });
      keepBecomeMasterData({
        introduction: null,
        services: null,
        prices: null,
        scalability: null,
        video: null,
        documents: null
      });
      navigate('/become-a-master/review');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = ({ type, size }: { type: string; size: number }) => {
    const isCorrectType =
      type === 'image/jpeg' ||
      type === 'image/jpg' ||
      type === 'image/png' ||
      type === 'application/pdf';
    if (!isCorrectType) {
      alert.error('Please upload files only with jpg, jpeg, png, pdf types.');
      return Upload.LIST_IGNORE;
    }
    const isLt10M = size / 1024 / 1024 < 10;
    if (!isLt10M) {
      alert.error('Max file size 10mb.');
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return (
    <div className={classNames(['flex flex-col items-center', styles.cont])}>
      <div className="w-[120px] h-[120px]">
        <img src={clip} alt="areas" />
      </div>
      <p className={styles.title}>Documents</p>
      <p className={styles.txt}>
        Please attach the documents that you think can prove your competency for doing the services
        that you provide. It can be a certificate, driving license.
      </p>

      <div>
        <Upload
          multiple
          className="flex flex-col items-center"
          beforeUpload={beforeUpload}
          onChange={onChange}
          {...props}
        >
          <DButton text="Attach" type="default" className={styles.attach} />
        </Upload>
        <p className={styles.fileRequirements}>Max 10mb per file. Only: jpg, jpeg, png, pdf</p>
      </div>
      <DotsAndButton>
        <DButton
          text="Complete"
          loading={loading}
          // disabled={!masterFlowData.documents?.length}
          className={styles.complete}
          type="default"
          onClick={complete}
        />
      </DotsAndButton>
    </div>
  );
};

export default DocumentsUpload;
