import { Col, Typography, Row, Divider, Space } from 'antd';
import styles from './index.module.scss';
import google from 'assets/images/google.png';
import DButton from 'components/DButton';
import classNames from 'classnames';
import Card from './Card';
import Video from 'components/Video';
import { ProfileItem } from 'common';
import { showMasterPrices, toFixedUserRating } from 'helpers';
import { useDispatch } from 'react-redux';
import {
  GALLERY_MODAL_OPEN,
  OPEN_CONNECT_PAYMENT_MODAL,
  OPEN_HIRE_MODAL,
  OPEN_LOGIN_MODAL,
  POST_JOB_MODAL
} from 'redux/modals/actionTypes';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { getUserPaymentMethods } from 'api/transactions';
import { setLastAction } from 'redux/general/actions';
import { getMyJobs } from '../../../../api/jobs';
import { useState } from 'react';
import { toast } from 'react-toastify';
const { Text } = Typography;

const ProfileCard: React.FC<{ item: ProfileItem }> = ({ item, onViewProfileClick }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { user } = useUser();

  const openProfileDetailsModal = (id: string) => {
    navigate(`/explore/master/${id}`, {
      state: {
        master: id
      }
    });
  };

  const handleHireFlow = async (masterId: string) => {
    if (user._id) {
      setLoading(true);
      const myJobs = await getMyJobs();

      const payMethods = await getUserPaymentMethods();
      if (payMethods.length > 0) {
        if (myJobs.length === user.activeJobs.length) {
          // if user does not have not active jobs open `Create a job` modal and after that open `Hire master` modal
          dispatch({
            type: POST_JOB_MODAL,
            payload: true
          });
          dispatch(
            setLastAction({
              action: OPEN_HIRE_MODAL,
              data: {
                show: true,
                masterId
              },
              type: 'modal'
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
          dispatch({
            type: OPEN_HIRE_MODAL,
            payload: {
              show: true,
              masterId
            }
          });
        }
      } else {
        setLoading(false);
        dispatch({
          type: OPEN_CONNECT_PAYMENT_MODAL
        });
        if (myJobs.length === user.activeJobs.length) {
          dispatch(
            setLastAction({
              action: POST_JOB_MODAL,
              data: {
                payload: true
              },
              type: 'modal',
              afterAction: {
                action: OPEN_HIRE_MODAL,
                data: {
                  show: true,
                  masterId
                },
                type: 'modal'
              }
            })
          );
          // dispatch({
          //   type: POST_JOB_MODAL,
          //   payload:true
          // });
        } else {
          dispatch(
            setLastAction({
              action: OPEN_HIRE_MODAL,
              data: {
                show: true,
                masterId
              },
              type: 'modal'
            })
          );
        }

        // dispatch({
        //   type: OPEN_CONNECT_PAYMENT_MODAL
        // });
      }
    } else {
      dispatch({
        type: OPEN_LOGIN_MODAL,
        payload: {
          show: true,
          masterId
        }
      });
    }
  };

  const handleVideoClick = () => {
    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: {
        videos: [item.mediaPath?.original]
      }
    });
  };

  let thumbPath = '';
  if (item.mediaPath?.original) {
    const ext = item.mediaPath?.original.split('.')[1];
    thumbPath = item.mediaPath?.original
      .replace('video', 'photo')
      .replace('original', '270x310')
      .replace(`${ext}`, 'png');
  }

  return (
    <Card>
      <Video
        thumb={process.env.REACT_APP_MEDIA_BASE_URL + thumbPath}
        size="sm-prof"
        onClick={handleVideoClick}
      />
      <Col className="ml-6 max-w-[316px] w-full">
        <Row
          className="flex-1 w-full cursor-pointer"
          onClick={() => openProfileDetailsModal(item._id)}
        >
          <Col className="w-full">
            <Row align="middle" className="w-full">
              <img src={google} style={{ borderRadius: '50%', background: 'black', width: 32 }} />
              <Row className="ml-3 flex flex-1 items-center justify-between w-max">
                <div className="font-bold max-w-[210px]">{item.fullName || 'Data issue'}</div>
                <div className={classNames(styles.badges)}>
                  {item.bestMatch && (
                    <span className={classNames(styles.bestMatch)}>
                      <i className="icon-26" />
                    </span>
                  )}
                  {item.pro && (
                    <span className={classNames(styles.pro)}>
                      <i className="icon-25 " />
                    </span>
                  )}
                </div>
              </Row>
            </Row>
            <Row className="mt-2" style={{ fontSize: `14px` }} align="middle">
              <i className="icon-26 text-orange"></i>
              <Text className="ml-2 font-bold">
                {`${toFixedUserRating(item.masterReviewAvg)}`}
                <Text className="font-normal ml-1">({item.masterReviews})</Text>
              </Text>
              <Divider type="vertical" />
              <Text className="text-doers-black font-normal">{`${item.jobsCompleted} jobs completed`}</Text>
            </Row>
          </Col>
        </Row>
        <Row className="flex justify-between items-center mt-4">
          <Text
            className="font-bold text-doers-black cursor-pointer"
            onClick={() => openProfileDetailsModal(item._id)}
          >
            {showMasterPrices({
              minFlat: item.minFlatPrice,
              maxFlat: item.maxFlatPrice,
              minHourly: item.minHourlyRate,
              maxHourly: item.maxHourlyRate
            })}
          </Text>
          <Space size={14}>
            <DButton
              onClick={() => openProfileDetailsModal(item._id)}
              text="View Profile"
              type="only-text"
              className="text-green font-semibold text-font-13 hover:text-light-green"
            />
            <DButton
              onClick={() => handleHireFlow(item._id)}
              text={`Hire`}
              type="white"
              loadingClassName="text-[16px] right-[5px]"
              loading={isLoading}
              className={styles.hireBtn}
            />
          </Space>
        </Row>
      </Col>
    </Card>
  );
};

export default ProfileCard;
