import React from 'react';
import { Route, Navigate, RouteProps } from 'react-router-dom';

interface Props  {
  restricted: boolean;
  component: React.FC;
}

const PublicRoute: React.FC<Props> = ({ component: Component, restricted = false, ...rest }) => {
  const token = !!localStorage.getItem('token');
  return restricted && token ? <Navigate to="/explore" /> : <Component {...rest} />;
};

export default PublicRoute;
