/** @format */

import * as yup from 'yup';
import * as AWS from 'aws-sdk';

export const mediaTypes = ['DOCUMENT', 'PHOTO', 'VIDEO'];
export type MediaType = typeof mediaTypes[number];

export type PurposeType = 'PROFILE' | 'JOB' | 'FEED' | 'CHAT' | 'FEEDBACK';
export type MediaStatus = 'PENDING' | 'PUBLISHED';
export type UploadedMediaRequest = {
  uuid: string;
};

export const MediaSignedURLRequestYup = yup.object().shape({
  mediaType: yup.string().oneOf(mediaTypes).required(),
  contentType: yup.string().when('mediaType', {
    is: mediaType => ['DOCUMENT', 'PHOTO'].includes(mediaType),
    then: schema =>
      schema
        .oneOf([
          'image/jpeg',
          'image/jpg',
          'image/img',
          'image/png',
          'image/webp',
          'application/pdf',
        ])
        .required(),
    otherwise: schema =>
      schema
        .oneOf(['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/webm', 'video/quicktime'])
        .required(),
  }),
});

export type BlockVideoRequest = {
  comment?: string;
};

export type MediaItem = {
  user: string;
  name?: string | null;
  type: MediaType;
  purpose: PurposeType;
  contentType: string;
  uuid: string;
  path: string;
  ext: string;
  status: MediaStatus;
  resourceId: string;
  sizeKB?: number;
};

export type Media = {
  user: string;
  name?: string | null;
  type: MediaType;
  purpose: PurposeType;
  contentType: string;
  uuid: string;
  path: string;
  ext: string;
  status: MediaStatus;
  resourceId: string;
  sizeKB?: number;
};

export type GetSignedURLResponse = {
  s3Response: AWS.S3.PresignedPost;
  uuid: string;
  userId: string;
  folder: string;
  fileName: string;
  resourceId: string;
  name?: string;
  sizeKB?: number;
};

const DocExtensions = {
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/img': 'img',
  'image/webp': 'webmp',
  'application/pdf': 'pdf',
};

const PhotoExtensions = {
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/img': 'img',
  'image/webp': 'webmp',
};

const VideoExtensions = {
  'video/x-msvideo': 'x-msvideo',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpeg',
  'video/webm': 'webm',
  'video/quicktime': 'mov',
};

export type FileInfo = {
  path: string;
  ext: Array<string>;
  thumbnail?: string;
};

export const SupportedMediaTypes = {
  PROFILE: {
    DOCUMENT: {
      path: 'media/document/original/profile',
      ext: DocExtensions,
    },
    VIDEO: {
      path: 'media/video/original/profile',
      ext: VideoExtensions,
    },
    PHOTO: {
      path: 'media/photo/original/profile',
      ext: PhotoExtensions,
    },
  },
  JOB: {
    PHOTO: {
      path: 'media/photo/original/job',
      ext: PhotoExtensions,
    },
    VIDEO: {
      path: 'media/video/original/job',
      ext: VideoExtensions,
    },
  },
  FEED: {
    VIDEO: {
      path: 'media/video/original/activity',
      ext: VideoExtensions,
    },
  },
  CHAT: {
    PHOTO: {
      path: 'media/photo/original/chat',
      ext: PhotoExtensions,
    },
    VIDEO: {
      path: 'media/video/original/chat',
      ext: VideoExtensions,
    },
    DOCUMENT: {
      path: 'media/document/original/chat',
      ext: DocExtensions,
    },
  },
  FEEDBACK: {
    PHOTO: {
      path: 'media/photo/original/feedback',
      ext: PhotoExtensions,
    },
    VIDEO: {
      path: 'media/video/original/feedback',
      ext: VideoExtensions,
    },
  },
};

export type MediaPathType = {
  original: string;
  thumbnail: string;
};

export type MediaLiteItem = Pick<MediaItem, 'path' | 'type' | 'name' | 'sizeKB'>;

export type UserVideoFilterRequest = {
  userId: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
};

export type UserVideoResponse = {
  items: MediaItem[];
  count: number;
};
