import React, { useEffect, useState } from 'react';
import DButton from 'components/DButton';
import { Divider, Input, Radio } from 'antd';
import areas from 'assets/images/areas.svg';
import DRadio from 'components/DRadio';
import usa from 'assets/images/usa.svg';
import location from 'assets/images/location.svg';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { scalability } from '../../../constants';
import DotsAndButton from 'components/DotsAndButton';

const goTo = {
  0: {
    text: 'Next',
    route: '/'
  },
  [scalability.NATIONWIDE.value]: {
    text: 'Next, Profile video',
    route: '/become-a-master/video'
  },
  [scalability.AREA.value]: {
    text: 'Next, Address',
    route: '/become-a-master/address'
  }
};

const Areas = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const next = () => {
    keepBecomeMasterData({ ...masterFlowData, scalability: value });
    navigate(goTo[value].route);
  };

  useEffect(() => {
    if (masterFlowData?.scalability) {
      setValue(masterFlowData?.scalability);
    }
  }, []);

  return (
    <div className="flex flex-col items-center w-[474px]">
      <div className="w-[120px] h-[120px]">
        <img src={areas} alt="areas" />
      </div>
      <p className={styles.title}>Job Search Areas</p>
      <p className={styles.txt}>
        Do you want to see the whole nationwide jobs or only the jobs near you based on your
        selected radius?
      </p>
      <div>
        <Radio.Group className="mb-[145px]" value={value} onChange={onChange}>
          <div className={styles.cards}>
            <div className={styles.selectCard}>
              <div className={classNames([styles.imgAndLabel, value === 1 && styles.selected])}>
                <img src={usa} alt="usa" />
                <p className="mt-2">{scalability.NATIONWIDE.label}</p>
              </div>
              <Divider className="m-0" />
              <div className={styles.radio}>
                <DRadio value={scalability.NATIONWIDE.value} />
              </div>
            </div>
            <div className={styles.selectCard}>
              <div className={classNames([styles.imgAndLabel, value === 2 && styles.selected])}>
                <img src={location} alt="location" />
                <p className="mt-2">{scalability.AREA.label}</p>
              </div>
              <Divider className="m-0" />
              <div className={styles.radio}>
                <DRadio value={scalability.AREA.value} />
              </div>
            </div>
          </div>
        </Radio.Group>
      </div>
      <DotsAndButton>
        <DButton
          type="default"
          disabled={!value}
          className={styles.next}
          text={goTo[value].text}
          onClick={next}
        />
      </DotsAndButton>
    </div>
  );
};

export default Areas;
