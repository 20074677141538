import { Typography, Col, Row, Space, Input } from 'antd';
import classNames from 'classnames';
import CustomTextArea from 'components/CustomTextArea';
import DButton from 'components/DButton';
import TextWithIcon from 'components/JobPostModal/TextWithIcon';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useUser from 'redux/user/use-user';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { CLOSE_APPLY_MODAL, EDIT_JOB_APPLY_MODAL_PRICE } from 'redux/modals/actionTypes';
import { useEffect, useRef, useState } from 'react';
import { alert } from 'helpers';
import uuid from 'react-uuid';
import { sendMessage } from 'api/chat';
import { ChatState } from 'redux/chat/types';
import { getLoggedInUser } from 'api/auth';

const { Text } = Typography;

type ApllyJobType = {
  pricePerHour: string;
  pricePerJob: string;
  message: string;
};

type inputStateType = {
  text: string;
  displayText: string;
  prefix: boolean;
};

interface IApplyForJob {
  api: any;
  modalTitle: string;
  priceSectionTitle: string;
  messageSectionTitle: string;
  applyButtonText: string;
  messageDefaultMessage?: string;
  jobContractId?: string; //will be changed
}
const ApplyForJob = ({
  api,
  modalTitle,
  priceSectionTitle,
  messageSectionTitle,
  applyButtonText,
  messageDefaultMessage,
  jobContractId
}: IApplyForJob) => {
  const { user,keepUser } = useUser();
  const { applyForJobModal: modalState } = useSelector((state: any) => state.modals);
  const { selectedChannel } = useSelector((state: any): ChatState => state.chat);
  const profileType = user._id === selectedChannel?.doerId ? 'doer' : 'master';

  const [disabled,setDisabled] = useState<boolean>(true)
  const perHourDisplayRef = useRef<any>(null);
  const perJobDisplayRef = useRef<any>(null);

  const perHourInputRef = useRef<any>(null);
  const perJobInputRef = useRef<any>(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [perJobInputState, setPerJobInputState] = useState<inputStateType>({
    text: '/job',
    displayText: '',
    prefix: false
  });

  const [perHourInputState, setPerHourInputState] = useState<inputStateType>({
    text: '/hour',
    displayText: '',
    prefix: false
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<ApllyJobType>({
    defaultValues: {
      pricePerHour: modalState?.pricePerHour || '',
      pricePerJob: modalState?.pricePerJob || '',
      message:
        messageDefaultMessage ||
        `Hello,\n\nI have all needed skills for this Job. Please check my profile for more details.\n\nBest Regards,\n${user.firstName}`
    }
  });

  const { pricePerHour, pricePerJob, message } = watch();

  const closeApplyModal = () => {
    dispatch({
      type: CLOSE_APPLY_MODAL
    });
  };

  const onSubmitHandle = async (data: FieldValues) => {
    try {
      setLoading(true);
      if (jobContractId) {
        const groupId = uuid();
        await sendMessage({
          channelId: selectedChannel?._id || '',
          type: 'TEXT',
          message,
          groupId
        });
        await api({
          id: jobContractId,
          hourlyRate: +data.pricePerHour,
          flatPrice: +data.pricePerJob,
          groupId
        });
      } else {
        await api(
          {
            hourlyRate: data.pricePerHour || undefined,
            flatPrice: data.pricePerJob || undefined,
            message: data.message
          },
          modalState.jobId
        );
        const user = await getLoggedInUser();
        keepUser(user);
        alert.success('You have successfully applied for the Job');
      }
    } catch (err) {
      console.error(err);
      //alert.error('Something went wrong');
    } finally {
      setLoading(false);
      closeApplyModal();
    }
  };
  const onBlurHandle = (
    e: any,
    setInputState: React.Dispatch<React.SetStateAction<inputStateType>>,
    ref: any
  ) => {
    setInputState(prevState => {
      let prefix = false;
      ref.current.style.display = 'none';
      if (e.target.value) {
        ref.current.style.display = 'block';
        prefix = true;
      }
      return { ...prevState, blur: true, prefix: prefix };
    });
  };

  const onFocusHandle = (
    e: any,
    setInputState: React.Dispatch<React.SetStateAction<inputStateType>>,
    ref: any
  ) => {
    setInputState(prevState => {
      ref.current.style.display = 'none';
      return { ...prevState, blur: false, prefix: true };
    });
    e.target.focus();
  };

  useEffect(() => {

    setDisabled(false)


    if(selectedChannel &&  modalState?.pricePerHour === Number(pricePerHour) && !modalState?.pricePerJob && !pricePerJob){
      setDisabled(true)
    }

    if(selectedChannel &&  modalState?.pricePerJob === Number(pricePerJob) && !modalState?.pricePerHour && !pricePerHour){
      setDisabled(true)
    }


    if(selectedChannel &&  modalState?.pricePerHour === Number(pricePerHour) && modalState?.pricePerJob === Number(pricePerJob)){
      setDisabled(true)
    }

    if(!((pricePerHour || pricePerJob) && message.trim())){
      setDisabled(true)
    }
    if (pricePerHour) {
      setPerHourInputState(prev => {
        return { ...prev, prefix: true, displayText: `${pricePerHour} $${prev.text}` };
      });
    }
    if (pricePerJob) {
      setPerJobInputState(prev => {
        return { ...prev, prefix: true, displayText: `${pricePerJob} $${prev.text}` };
      });
    }
  }, [pricePerHour, pricePerJob,message]);

  useEffect(() => {
    if (pricePerHour) {
      perHourDisplayRef.current.style.display = 'block';
    }
    if (pricePerJob) {
      perJobDisplayRef.current.style.display = 'block';
    }
  }, []);

  const  jobContractHourlyRate  = selectedChannel?.jobContract?.hourlyRate ;
  const  jobContractFlatPrice= selectedChannel?.jobContract?.flatPrice ;
  const  jobHourlyRate  = selectedChannel?.job?.hourlyRate ;
  const  jobFlatPrice= selectedChannel?.job?.flatPrice ;


  return (
    <Col className="apply-job-container">
      <Row className="flex justify-center">
        <Text className="text-font-22 font-bold">{modalTitle} </Text>
      </Row>
      <Row className="mt-8 w-full">
        <TextWithIcon text={priceSectionTitle} classes="ml-1" />
        {!!(modalState?.pricePerHour || modalState?.pricePerJob) && (
          <Text className="w-full ml-7 italic text-font-13">
            {profileType === 'doer' ? 'Suggested before:' : `Doer’s budget: `} &nbsp;
            <Text className="font-bold text-[#515864]">
              {profileType === 'master' && <>
                {selectedChannel ?
                 <>
                  {jobHourlyRate && `$${jobHourlyRate}/hour`}
                  {jobHourlyRate && jobFlatPrice && ' | '}
                  {jobFlatPrice && `$${jobFlatPrice}/job`}
                  </> :
                  <>
                    {modalState?.pricePerHour && `$${modalState?.pricePerHour}/hour`}
                    {modalState?.pricePerHour && modalState?.pricePerJob && ' | '}
                    {modalState?.pricePerJob && `$${modalState?.pricePerJob}/job`}
                  </>
              }

              </>  }

              {profileType === 'doer' && <>
                {modalState?.pricePerHour && `$${modalState?.pricePerHour}/hour`}
                {modalState?.pricePerHour && modalState?.pricePerJob && ' | '}
                {modalState?.pricePerJob && `$${modalState?.pricePerJob}/job`}
              </>  }

            </Text>
            <br />
          </Text>
        )}
        {profileType === 'master' &&
          (jobContractHourlyRate !== modalState?.pricePerHour || jobContractFlatPrice !== modalState?.pricePerJob) &&
          jobContractId && (
            <Text className="w-full ml-7 italic text-font-13">
              Suggested by you: &nbsp;
              <Text className="font-bold text-[#515864]">
                {jobContractHourlyRate   && `$${jobContractHourlyRate}/hour`}
                {jobContractHourlyRate && jobContractFlatPrice && ' | '}
                {jobContractFlatPrice  && `$${jobContractFlatPrice}/job`}
              </Text>
              <br />
            </Text>
          )}

        {/* Suggested price section */}
        <Row className="flex flex-row">
          <Col>
            <Controller
              name="pricePerHour"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Col className="!relative">
                  <Input
                    placeholder={perHourInputState.prefix ? '' : 'Price per hour ($/hour)'}
                    value={value}
                    ref={perHourInputRef}
                    prefix={

                      <div className={classNames({ hidden: !perHourInputState.prefix })}>$</div>
                    }
                    onChange={e => {
                      if (/^(\d{0}|\d+)$/.test(e.target.value)) {
                        onChange(e);
                      }
                    }}
                    className={classNames(
                      styles.input,
                      'w-[187px] mt-3 h-[40px] ml-7 pl-4 font-normal'
                    )}
                    onBlur={e => onBlurHandle(e, setPerHourInputState, perHourDisplayRef)}
                    onFocus={e => onFocusHandle(e, setPerHourInputState, perHourDisplayRef)}
                  />
                  <div
                    className={styles.fakeValue}
                    ref={perHourDisplayRef}
                    onClick={e => {
                      e.currentTarget.style.display = 'none';
                      perHourInputRef.current && perHourInputRef.current.focus();
                    }}
                  >
                    <Text ellipsis={{ tooltip: true, suffix: '' }} className="w-[145px]">
                      {perHourInputState.displayText}
                    </Text>
                  </div>
                </Col>
              )}
            />
            <Row justify="start" className="ml-11">
              {errors.pricePerHour && (
                <Text className="text-icon-err">{errors.pricePerHour.message}</Text>
              )}
            </Row>
          </Col>
          <Col>
            <Controller
              name="pricePerJob"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Col className="!relative">
                  <Input
                    ref={perJobInputRef}
                    prefix={

                      <div className={classNames({ hidden: !perJobInputState.prefix })}>$</div>
                    }
                    value={value}
                    onChange={e => {
                      if (/^(\d{0}|\d+)$/.test(e.target.value)) {
                        onChange(e);
                      }
                    }}
                    placeholder={perJobInputState.prefix ? '' : 'Price per job ($/job)'}
                    className={classNames(
                      styles.input,
                      'w-[187px] mt-3 ml-7 pl-4  h-[40px] font-normal'
                    )}
                    onBlur={e => onBlurHandle(e, setPerJobInputState, perJobDisplayRef)}
                    onFocus={e => onFocusHandle(e, setPerJobInputState, perJobDisplayRef)}
                  />
                  <div
                    className={styles.fakeValue}
                    ref={perJobDisplayRef}
                    onClick={e => {
                      e.currentTarget.style.display = 'none';
                      perJobInputRef.current && perJobInputRef.current.focus();
                    }}
                  >
                    <Text
                      ellipsis={{
                        tooltip: true,
                        suffix: ''
                      }}
                      className="w-[145px]"
                    >
                      {perJobInputState.displayText}
                    </Text>
                  </div>
                </Col>
              )}
            />
            <Row justify="start" className="ml-11">
              {errors.pricePerJob && (
                <Text className="text-icon-err">{errors.pricePerJob.message}</Text>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="w-full">
          <TextWithIcon text={messageSectionTitle} classes="ml-1 mt-8" />
          <Controller
            name="message"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomTextArea classes="ml-7 mt-3" value={value} onChangeValue={onChange} />
            )}
          />
        </Row>
      </Row>
      <Row justify="end" className="mt-[40px]">
        <Space size={16}>
          <DButton type="white" className="!w-[148px] !h-[44px]" onClick={closeApplyModal}>
            Cancel
          </DButton>

           <DButton
            type="light-green"
            className="text-font-16 font-bold text-white w-[260px] h-[44px]"
            onClick={handleSubmit(onSubmitHandle)}
            submit
            loading={loading}
            disabled={disabled}
          >
            {applyButtonText}
          </DButton>

        </Space>
      </Row>
    </Col>
  );
};
export default ApplyForJob;
