import React from 'react';
import styles from './index.module.scss';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import GalleryModal from './Modals/GalleryModal';

const MainLayout: React.FC = (): JSX.Element => {


  return (
    <>
      <div className={styles.dashboard}>
        <Header />
        <Outlet />
        <GalleryModal />
      </div>
    </>
  );
};
export default MainLayout;
