/** @format */
// HTTP clients for all services
import * as userClient from './user.client';
import * as mediaClient from './media.client';
import * as authClient from './auth.client';
import * as jobClient from './job.client';
import * as jobContractClient from './job-contract.client';
import * as chatClient from './chat.client';
import * as notificationClient from './notification.client';
import * as socketClient from './socket.client';
import * as feedClient from './feed.client';
import * as paymentClient from './payment.client';
import * as adminUserClient from './admin-user.client';

export {
  userClient,
  mediaClient,
  authClient,
  jobClient,
  jobContractClient,
  chatClient,
  notificationClient,
  socketClient,
  feedClient,
  paymentClient,
  adminUserClient
};
