import { ChangeJobContractPriceRequest, CompleteJobRequest, JobGroupMessageRequest } from 'common';
import api from './index';

const requestContractUpdate = (
  id: string,
  systemMessageId: string | undefined,
  action: string,
  body?: Record<string, any>
): Promise<void> => {
  return api.put(`/job-contracts/${id}/${action}`, {
    ...body,
    systemMessageId
  });
};

export const requestLeaveFeedback = (
  id: string,
  systemMessageId: string,
  feedback: string,
  ratingStart: number
) => api.post(`/job-contracts/${id}/leave-feedback`, { feedback, ratingStart, systemMessageId });

export const requestLeaveTip = (id: string, systemMessageId: string, tipAmount: number) =>
  api.post(`/job-contracts/${id}/leave-tip`, { tipAmount, systemMessageId });

export const requestRejectJobContract = (id: string) => {
  return requestContractUpdate(id, undefined, 'reject-offer');
};

export const requestCancelJobContract = (id: string) =>
  requestContractUpdate(id, undefined, 'cancel-offer');

export const requestAcceptJobContract = (
  id: string,
  body?: { flatPrise?: number; hourlyRate?: number } | undefined
) => {
  return requestContractUpdate(id, undefined, 'accept-offer', body);
};

export const requestStartJob = (id: string, messageId: string) =>
  requestContractUpdate(id, messageId, 'start-job');

export const requestCompleteJob = (id: string, messageId: string, body: CompleteJobRequest) => {
  return requestContractUpdate(id, messageId, 'complete-job', body);
};

export const requestApproveCompletedJob = (id: string, messageId: string) =>
  requestContractUpdate(id, messageId, 'approve-complete-job');

export const requestReportIssueJob = (
  id: string,
  messageId: string,
  body: JobGroupMessageRequest
) => requestContractUpdate(id, messageId, 'report-issue-complete-job', body);

export const requestAcceptReportedIssueJob = (id: string, messageId: string) =>
  requestContractUpdate(id, messageId, 'accept-reported-issue');

export const requestDeclineReportedIssueJob = (
  id: string,
  messageId: string,
  body: JobGroupMessageRequest
) => requestContractUpdate(id, messageId, 'decline-reported-issue', body);

export const requestResumeJob = (id: string, messageId: string) =>
  requestContractUpdate(id, messageId, 'resume-job');

export const requestCancelIssueAndApproveJob = (id: string, messageId: string) =>
  requestContractUpdate(id, messageId, 'cancel-issue-and-approve-job');

export const requestPassIssueAdmin = (id: string, messageId: string) =>
  requestContractUpdate(id, messageId, 'pass-issue-to-admin');

export const requestChangePriceJobContract = ({
  id,
  groupId,
  hourlyRate,
  flatPrice
}: { id: string } & ChangeJobContractPriceRequest) =>
  requestContractUpdate(id, undefined, 'change-price', { groupId, hourlyRate, flatPrice });
