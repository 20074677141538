/** @format */

const ORDERBY_REGEX = /^\w+(:(asc|desc))?$/i;
const FILTER_REGEX = /(\w+):(eq|gt|ne|ge|lt|le):([a-zA-Z0-9-.\s,']+)/i;
const IS_STRING_REGEX = /^'.*'$/;

export const parseOrderByQueryParams = (
  orderByQueryParamString: string | undefined,
  customMapping?: Object | undefined,
) => {
  if (orderByQueryParamString?.trim().length) {
    let orderByQueryParams = orderByQueryParamString
      .split(',')
      .map(each => each.replace(/\s/g, ''))
      .filter(each => each.length !== 0);

    const allMatch = orderByQueryParams.every(each => ORDERBY_REGEX.test(each));

    if (allMatch) {
      orderByQueryParams = orderByQueryParams.map(each => {
        let worker = each.trim();

        const paramsComponents = each.split(':');
        const paramsName = paramsComponents[0];
        if (customMapping && customMapping[paramsName]) {
          worker = customMapping[paramsName] as string;
          if (paramsComponents.length > 1) {
            worker += ` ${paramsComponents[1]}`;
          }
          return worker;
        }

        worker = worker[0].toUpperCase() + worker.substring(1);
        worker = worker.replace(/:/g, ' ');
        return worker;
      });

      return orderByQueryParams;
    }

    throw new Error(
      `Incorrect orderBy query param passed : ${orderByQueryParamString}. Expected format: <Attribute>:(asc|desc)`,
    );
  }
  return [];
};

export const parseFilterQueryParams = (
  filterQueryParamString: string | undefined,
  customMapping?: Object | undefined,
) => {
  if (filterQueryParamString?.trim().length) {
    const filterQueryParams = filterQueryParamString
      .split(',')
      .map(each => each.trim())
      .filter(each => each.length !== 0);

    const allMatch = filterQueryParams.every(each => FILTER_REGEX.test(each));

    if (allMatch) {
      const result = filterQueryParams.map(queryParam => {
        FILTER_REGEX.exec(queryParam);
        let attribute = RegExp.$1;
        const operator = RegExp.$2;
        const comparisonValue = RegExp.$3;

        if (customMapping && customMapping[attribute]) {
          attribute = customMapping[attribute] as string;
        } else {
          attribute = attribute[0].toUpperCase() + attribute.substring(1);
        }

        if (IS_STRING_REGEX.test(comparisonValue)) {
          const extractedValue = comparisonValue.substring(1, comparisonValue.length - 1);
          return { [attribute]: { [operator]: extractedValue } };
        }
        return { [attribute]: { [operator]: Number(comparisonValue) } };
      });
      return result;
    }

    throw new Error(`Incorrect filter query param passed : ${filterQueryParamString}. Expected format: <Attribute>:<operator>:<value>\n
  Operators currently supported eq, ne, gt, ge, lt, le`);
  }
  return [];
};
