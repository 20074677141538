import { Input } from 'antd';
import useClickOutside from 'hooks/useClickOutside';
import { RefObject, useEffect, useRef, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import classNames from 'classnames';
import styles from './index.module.scss';
import useUser from 'redux/user/use-user';

interface ISearchPlaceProps {
  onLocationChangeHandle: (lat: number, lng: number, address: string) => void;
  classes?: string;
  value: string;
  setValue: (val: string) => void;
  findMyPlace?: () => void;
  onChange?: (value: string) => void;
  inputClasses?: string;
  onKeyUpHandle?: (e: any) => void;
}

const SearchPlace = ({
  onLocationChangeHandle,
  onChange = () => {},
  value,
  setValue,
  findMyPlace,
  classes,
  onKeyUpHandle,
  inputClasses
}: ISearchPlaceProps) => {
  const [opened, setOpened] = useState(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);

  const {
    placePredictions,
    placesService,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesAutocompleteService
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['establishment', 'premise'],
    // TODOFRONT research how to pass these props
    // libraries: ['places'],
    // googleMapsScriptBaseUrl: 'https://maps.googleapis.com/maps/api/js',
    options: {
      // fields: ['formatted_address', 'geometry', 'name'],
      // strictBounds: true,
      // fields: ['formatted_address', 'geometry', 'name'],
      // strictBounds: false,
      // types: ['establishment'],
      componentRestrictions: { country: 'usa' }
    },
    language: 'en'
  });
  useClickOutside(ref, () => {
    opened && setOpened(false);
  });

  const useCurrentLocation = async () => {
    findMyPlace && findMyPlace();
    setOpened(false);
  };

  const pickLocation = (item: any) => {
    placesService?.getDetails({ placeId: item.place_id }, (placeDetails: any) => {
      setValue(item.description);
      onLocationChangeHandle(
        placeDetails.geometry.location.lat(),
        placeDetails.geometry.location.lng(),
        item.description
      );
      onChange(item.description);
    });
    setOpened(false);
  };

  useEffect(() => {
    if (!placePredictions.length) {
      getPlacePredictions({ input: value });
    }
  }, [value]);

  return (
    <div className={classNames(styles.placesCont, classes)} ref={ref}>
      <Input
        value={value}
        onKeyUp={onKeyUpHandle}
        placeholder="Location"
        className={classNames('input-focused', inputClasses)}
        onChange={evt => {
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
        onFocus={() => {
          setOpened(true);
        }}
      />
      <div className={styles.placesList}>
        {opened && (
          <div className={styles.list}>
            <div className={styles.listItem} onClick={useCurrentLocation}>
              <span className="icon-2" />
              <p>Use my current location</p>
            </div>
            {placePredictions.map((item, i) => (
              <div
                className={styles.listItem}
                key={i}
                onClick={() => {
                  pickLocation(item);
                }}
              >
                <span className="icon-32" />
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPlace;
