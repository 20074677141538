import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import ResetPassContainer from '../Containers/ResetPassContainer';
import styles from './index.module.scss';
import DButton from 'components/DButton';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import { checkResetPassword, getPasswordResetCode } from 'api/auth';
import classNames from 'classnames';
import { alert } from 'helpers';

const CodeVerification = () => {
  let navigate = useNavigate();
  const { emailForUserReset, keepCodeForPassReset } = useUser();
  const [code, setCode] = useState<string[]>([]);
  const [err, showErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const handleCodeChange = (code: string) => {
    setCode(code.split(''));
  };

  const confirmCode = async () => {
    try {
      setLoading(true);
      const body = {
        code: code.join(''),
        email: emailForUserReset
      };
      keepCodeForPassReset(body.code);
      await checkResetPassword(body);
      navigate('/auth/set-new-pass');
    } catch (e) {
      showErr(true);
    } finally {
      setLoading(false);
    }
  };

  const resend = async () => {
    try {
      setResendLoading(true);
      await getPasswordResetCode({
        email: emailForUserReset
      });
      alert.success(
        `We have sent the new code to your ${emailForUserReset} email address. \n Please check your mailbox.`
      );
    } catch (error) {
      console.log('resendError');
    } finally {
      setResendLoading(false);
    }
  };

  useEffect(() => {
    if (!emailForUserReset) {
      navigate('/auth/forgot-password');
    }
  }, [emailForUserReset]);

  return (
    <ResetPassContainer>
      <div className="flex flex-col justify-center items-center w-[376px] text-center mt-[75px]">
        <div className={styles['conf-email']}>Confirm your email</div>
        <div className="text-base text-doers-black mb-6 w-[210px]">
          We sent a 5-digit code to {emailForUserReset}
        </div>
        <ReactCodeInput
          fields={5}
          autoFocus={true}
          onChange={handleCodeChange}
          values={code}
          className={classNames({ [styles.inputStyle]: true, [styles.err]: err })}
        />
        <div className="mt-6">
          <DButton
            disabled={code.length !== 5}
            text="Confirm"
            className="w-[376px] font-semibold"
            type="default"
            loading={loading}
            onClick={confirmCode}
          />
        </div>
        <div className="flex mt-3">
          <p className="font-sm text-light-gray">Didn't receive an email?</p>
          <DButton
            text="&nbsp;Resend"
            type="only-text"
            onClick={resend}
            loading={resendLoading}
            className="font-bold text-sm active:text-light-gray disabled:text-light-gray"
          />
        </div>
      </div>
    </ResetPassContainer>
  );
};

export default CodeVerification;
