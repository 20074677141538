import { Col, Modal, Row } from 'antd';
import PaylapIcon from 'assets/icons/paypal.svg';
import DButton from 'components/DButton';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { fetchSetupToken } from 'api/transactions';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLOSE_CONNECT_PAYMENT_MODAL,
  OPEN_JOB_APPLY_MODAL,
  POST_JOB_MODAL
} from 'redux/modals/actionTypes';
import { setLastAction } from 'redux/general/actions';
import useUser from 'redux/user/use-user';
import { useNavigate } from 'react-router-dom';

type PaymentMoadalContentType = {
  closeModal: () => void;
  applayAction: () => void;
  visible: boolean;
};

const PaymentMoadalContent = ({ closeModal, applayAction, visible }: PaymentMoadalContentType) => {
  const dispatch = useDispatch();
  const { socket, lastAction } = useSelector((state: any) => state.general);
  const { user, keepUser } = useUser();
  const [text, setText] = useState('');
  const navigate = useNavigate();

  const redirectToPayPal = (url: string) => {
    const approvalUrl = url;
    window.open(approvalUrl, 'mywindow', 'width=700,height=700,toolbar=1,status=1,left= 200');
  };

  const connectPaypal = async () => {
    await fetchSetupToken().then((res: any) => {
      dispatch({ type: CLOSE_CONNECT_PAYMENT_MODAL });
      redirectToPayPal(res);
    });
  };

  const onNavogatePaymentPage = async () => {
    dispatch({ type: CLOSE_CONNECT_PAYMENT_MODAL });
    navigate('/payments');
  };

  useEffect(() => {
    if (lastAction?.action === POST_JOB_MODAL) {
      setText(
        'Please connect your preferred payment method, such as PayPal or credit/debit cards, to proceed. This will enable you to post a job or task, and your account will be charged only when you authorize payment to masters for completed jobs or as per your mutual agreement.'
      );
    } else if (lastAction?.action === OPEN_JOB_APPLY_MODAL) {
      setText(
        'Please connect your preferred payment method, such as PayPal or credit/debit cards, to enable payment receipts. Your account will be charged only when you receive a payment, with a 10% fee held by the platform.'
      );
    }
  }, [lastAction]);

  return (
    <>
      <Modal
        className={classNames(styles.modal, ' ')}
        style={{
          borderRadius: '16px !important'
        }}
        visible={visible}
        footer={false}
        destroyOnClose
        centered
        closeIcon={<></>}
        onCancel={closeModal}
        maskStyle={{ background: 'unset' }}
      >
        <Row className={classNames(styles.container, '')}>
          <Col span={24}>
            <div className="text-[#18191A] text-[22px] text-center py-3 font-semibold">
              Attach payment method
            </div>

            <div className="flex justify-center mt-[50px]">
              <div>
                {/* <div className={classNames(styles.paypalImage, 'flex justify-center ')}>
                  <img className="h-[93px] w-[93px]" src={PaylapIcon} alt="icon" />
                </div> */}
                <div className={classNames(styles.text, ' py-2')}>
                  Please connect your preferred payment method to be able continue.{' '}
                </div>
                <div className={classNames(styles.text, '!text-[14px] pt-1')}>{text}</div>

                <div className="flex justify-center mt-[40px]">
                  <DButton
                    type="only-text"
                    className="text-font-16 border border-[#18191A] rounded-[70px] font-bold text-[#18191A] w-[150px] h-[44px]"
                    onClick={closeModal}
                    disabled={false}
                    loading={false}
                  >
                    Cancel
                  </DButton>
                  <DButton
                    onClick={onNavogatePaymentPage}
                    type="light-green"
                    className="text-font-16 ml-4 font-bold text-white w-[200px] h-[44px]"
                    disabled={false}
                    loading={false}
                  >
                    Connect
                  </DButton>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PaymentMoadalContent;
