import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import reducer from './reducers';
import rootSaga from './sagas';

const isProd = process.env.NODE_ENV === 'production';

const logger = createLogger();

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (!isProd) {
  // middlewares.push(logger);
}

// mount it on the Store
const store = createStore(reducer, applyMiddleware(...middlewares));

// then run the saga
sagaMiddleware.run(rootSaga);

export default store;
