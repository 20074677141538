import React from 'react';
import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import styles from '../index.module.scss';

interface IProps {
  fileName?: string;
  icon?: string;
  type?: 'upload';
}

const { Text } = Typography;

const MessageDocumentCard = ({ fileName = '', type,icon }: IProps) => {
  const fileNameSplit = fileName.split('.');
  const extension = fileNameSplit[fileNameSplit.length - 1];
  return (
    <Col
      className={classNames(styles.messageDocumentCardContainer, {
        [styles.upload]: type === 'upload'
      })}
    >
      <Row align="middle" className="mr-auto">
        <Col className={styles.fileIcon}>
          <Text className="text-font-12 font-bold">{extension}</Text>
        </Col>
        <Text
          className={classNames('font-bold text-font-12', styles.fileName)}
          ellipsis={{
            tooltip: true,
            suffix: ''
          }}
        >
          {fileName}
        </Text>
      </Row>
    </Col>
  );
};

export default MessageDocumentCard;
