import classNames from 'classnames';
import { useState } from 'react';
import styles from './index.module.scss';

type allowedInputType = 'number' | 'string' | RegExp;

type Props = {
  placeholder?: string;
  className?: string;
  value: string;
  onChange: (val: string) => void;
  icon?: string;
  allowedInput?: allowedInputType;
  suffixIcon?: JSX.Element;
};

const CustomInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  className,
  allowedInput = 'string',
  icon,
  suffixIcon
}): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const inputTypeHandleByType = (input: string, allowedInput: allowedInputType) => {
    if (allowedInput === 'string') return true;
    if (allowedInput === 'number' && (Number(input) || input === '')) return true;
    else if (allowedInput instanceof RegExp) {
      if (allowedInput.test(input)) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const onChangeHandle = (e: React.FormEvent<HTMLInputElement>, allowedInput: allowedInputType) => {
    const inputString = e.currentTarget.value;
    if (inputTypeHandleByType(inputString, allowedInput)) {
      onChange(inputString);
    }
  };
  return (
    <div
      className={classNames(styles.customInput, className, 'pl-4', 'input-focused')}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {isFocused || value.length ? suffixIcon : ''}{' '}
      {/*add sffixIcon if there is a text or field is focused*/}
      {icon && <span className={classNames(icon, 'mr-1')} />}
      <input
        value={value}
        onChange={e => onChangeHandle(e, allowedInput)}
        placeholder={!isFocused ? placeholder : ''}
      />
    </div>
  );
};
export default CustomInput;
