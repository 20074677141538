import { IFeedbackModal, IReportIssueCompleteModal, ITipModal } from 'types/chatScreen';
import {
  IMasterJobDetailsModal,
  IGalleryModal,
  IApplyJobModal,
  IHireMasterModal,
  ILoginModal,
  IConnectPaymentModal,

} from './../../types/mapScreen';
import * as actionTypes from './actionTypes';
import { ModalActionType } from './types';

const initialState: {
  masterOrJobDetails: IMasterJobDetailsModal;
  galleryModal: IGalleryModal;
  applyForJobModal: IApplyJobModal;
  hireMasterModal: IHireMasterModal;
  loginModal: ILoginModal;
  completeJobModal: IReportIssueCompleteModal;
  reportIssueModal: IReportIssueCompleteModal;
  declineIssueModal: IReportIssueCompleteModal;
  feedbackModal: IFeedbackModal;
  tipModal: ITipModal;
  connectPaymentModal: IConnectPaymentModal;
  isPostJobModalOpen:boolean;
} = {
  masterOrJobDetails: {
    jobId: null,
    masterId: null,
    showModal: false
  },
  galleryModal: {
    galleryItems: {
      fileFetchType: 'download',
      photos: [],
      videos: []
    },
    show: false
  },
  applyForJobModal: {
    jobId: null,
    pricePerHour: '',
    pricePerJob: '',
    show: false
  },
  hireMasterModal: {
    masterId: null,
    show: false,
    note: ''
  },
  loginModal: {
    show: false,
    masterId: null,
  },
  completeJobModal: {
    show: false,
    id: '',
    messageId: '',
    groupId: ''
  },
  reportIssueModal: {
    show: false,
    id: '',
    messageId: '',
    groupId: ''
  },
  declineIssueModal: {
    show: false,
    id: '',
    messageId: '',
    groupId: ''
  },
  feedbackModal: {
    show: false,
    systemMessageId: '',
    contractId: ''
  },
  tipModal: {
    show: false,
    systemMessageId: '',
    contractId: ''
  },
  connectPaymentModal: {
    show: false,
  },
  isPostJobModalOpen: false

};

const modalsReducer = (state = initialState, action: ModalActionType) => {

  switch (action.type) {
    case actionTypes.MAP_SCREEN_DETAILS_MODAL_CHANGE:
      return {
        ...state,
        masterOrJobDetails: action.payload
      };
    case actionTypes.GALLERY_MODAL_OPEN:
      return {
        ...state,
        galleryModal: {
          show: true,
          galleryItems: {
            ...state.galleryModal.galleryItems,
            ...action.payload
          }
        }
      };
    case actionTypes.GALLERY_MODAL_CLOSE:
      return {
        ...state,
        galleryModal: {
          ...state.galleryModal,
          show: false,
          galleryItems: {
            fileFetchType: 'download',
            videos: [],
            photos: [],
            activeItem: 0
          }
        }
      };
    case actionTypes.OPEN_JOB_APPLY_MODAL:
      return {
        ...state,
        applyForJobModal: action.payload
      };
    case actionTypes.EDIT_JOB_APPLY_MODAL_PRICE:
      return {
        ...state,
        applyForJobModal: {
          ...state.applyForJobModal,
          ...action.payload
        }
      };
    case actionTypes.CLOSE_APPLY_MODAL:
      return {
        ...state,
        applyForJobModal: {
          show: false,
          jobId: null,
          pricePerHour: '',
          pricePerJob: ''
        }
      };
    case actionTypes.OPEN_HIRE_MODAL:
      return {
        ...state,
        hireMasterModal: action.payload
      };
    case actionTypes.CLOSE_HIRE_MODAL:
      return {
        ...state,
        hireMasterModal: {
          show: false,
          masterId: null,
          pricePerHour: '',
          pricePerJob: '',
          note: ''
        }
      };
    case actionTypes.OPEN_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.payload
      };
    case actionTypes.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginModal: {
          show: false
        }
      };
    case actionTypes.OPEN_REPORT_ISSUE_MODAL:
      return {
        ...state,
        reportIssueModal: {
          show: true,
          ...action.payload
        }
      };
    case actionTypes.CLOSE_REPORT_ISSUE_MODAL:
      return {
        ...state,
        reportIssueModal: {
          show: false,
          id: '',
          messageId: '',
          groupId: ''
        }
      };
    case actionTypes.OPEN_COMPLETE_JOB_MODAL:
      return {
        ...state,
        completeJobModal: {
          show: true,
          ...action.payload
        }
      };
    case actionTypes.CLOSE_COMPLETE_JOB_MODAL:
      return {
        ...state,
        completeJobModal: {
          show: false,
          id: '',
          messageId: '',
          groupId: ''
        }
      };
    case actionTypes.OPEN_DECLINE_ISSUE_MODAL:
      return {
        ...state,
        declineIssueModal: {
          show: true,
          ...action.payload
        }
      };
    case actionTypes.CLOSE_DECLINE_ISSUE_MODAL:
      return {
        ...state,
        declineIssueModal: {
          show: false,
          id: '',
          messageId: '',
          groupId: ''
        }
      };
    case actionTypes.OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModal: {
          show: true,
          ...action.payload
        }
      };
    case actionTypes.CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModal: {
          show: false,
          systemMessageId: '',
          contractId: ''
        }
      };
    case actionTypes.OPEN_TIP_MODAL:
      return {
        ...state,
        tipModal: {
          show: true,
          ...action.payload
        }
      };
    case actionTypes.CLOSE_TIP_MODAL:
      return {
        ...state,
        tipModal: {
          show: false,
          systemMessageId: '',
          contractId: ''
        }
      };
   case actionTypes.OPEN_CONNECT_PAYMENT_MODAL:
      return {
        ...state,
        connectPaymentModal: {
          show: true,
        }
      };
   case actionTypes.CLOSE_CONNECT_PAYMENT_MODAL:
        return {
          ...state,
          connectPaymentModal: {
            show: false,
          }
        };

   case actionTypes.POST_JOB_MODAL:
          return {
            ...state,
            isPostJobModalOpen: action.payload
          }

    default:
      return state;
  }
};

export default modalsReducer;
