import MaskLoader from 'components/MaskLoader';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Col, Divider, Row, Typography } from 'antd';
import { useEffectAsync } from 'hooks';
import { getFeedById } from 'api/commom';
import Video from 'components/Video';
import ReactPlayer from 'react-player/lazy';
import DButton from 'components/DButton';
import CircleBgIcon from 'components/Icons/CircleBgIcon';
import CustomAvatar from 'components/CustomAvatar';
const { Text } = Typography;
const VodeoPage = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  useEffectAsync(async () => {
    if (params.id) {
      setLoading(true);
      await getFeedById(params.id).then(res => {
        setLoading(false);
        setData(res);
      });
    }
  }, []);

  const onOpenApp = () => {
    window.location.href = 'doersdev://video/65982cd0bf0b1e9634f1fe0a';
  };

  return (
    <>
      {loading && <MaskLoader />}
      <div className={styles.header}>
        <div className="cursor-pointer">
          <Typography className={styles.logo}>Doers</Typography>
        </div>
        <div className="flex mr-1 items-center">
          <DButton type="light-green" className={styles.uploadBtn} onClick={onOpenApp}>
            <Row align="middle" justify="center">
              {/* <i className="icon-28 mr-2" /> */}
              Open App
            </Row>
          </DButton>
        </div>
      </div>
      <div className={classNames(styles.container, 'pt-5 ')}>
        {data && (
          <ReactPlayer
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            stopOnUnmount
            playing={true}
            controls={true}
            url={`${process.env.REACT_APP_MEDIA_BASE_URL}media/${data.video}`}
            volume={1}
          />
        )}
        <Row className={classNames('flex-nowrap')} align={'top'}>
          <Col span={21}>
            <div className={classNames('flex', 'items-start')}>
              <CustomAvatar
                firstName={data?.user.fullName.split(' ')[0]}
                lastName={data?.user.fullName.split(' ')[1]}
                size={50}
                replaceImageSize="50x50"
                imagePath={data?.user.profilePicture || ''}
                nameClasses="bg-[#999999] font-medium text-[16px]"
              />
              {/* <img src={img} width={32} height={32} className="rounded-full"></img> */}
              <div className="ml-3 ">
                <div className="flex ">
                  <Text
                    className={classNames(
                      'font-bold',
                      'leading-[20px] text-[white]  ',
                      'text-font-16'
                    )}
                  >
                    {data?.user.fullName}
                  </Text>

                  <>
                    {data?.user.profileType === 'MASTER' && (
                      <Row
                        justify="center"
                        align="middle"
                        className={classNames('ml-1 bg-[#EE784B] rounded-[20px]')}
                      >
                        <Text className="text-font-12 font-bold text-white px-2 py-[2px]">
                          Master
                        </Text>
                      </Row>
                    )}
                    {data?.user.bestMatch && (
                      <CircleBgIcon circleClasses="ml-1" circleColor="blue" icon="icon-26" />
                    )}
                    {data?.user.pro && (
                      <CircleBgIcon circleClasses="ml-1" circleColor="orange" icon="icon-25" />
                    )}
                  </>
                </div>
                <Row align="middle">
                  <i className={classNames('icon-26', 'text-orange')}></i>
                  <Text className={classNames('ml-2', 'font-bold text-[white]')}>
                    {data?.user.profileType === 'MASTER'
                      ? data?.user.masterReviewAvg
                      : data?.user.doerReviewAvg}
                  </Text>
                  <Text className={classNames('ml-2', 'font-normal text-[white]')}>
                    (
                    {data?.user.profileType === 'MASTER'
                      ? data?.user.masterReviews
                      : data?.user.doerReviews}
                    )
                  </Text>
                  <Divider type="vertical" className="mt-1 border-[#18191A33] text-[white]" />

                  {data?.user.profileType === 'MASTER' && (
                    <Text className="font-normal text-[white]">
                      {data?.user.jobsCompleted} jobs completed
                    </Text>
                  )}
                  {data?.user.profileType !== 'MASTER' && (
                    <Text className="font-normal text-[white]">
                      {data?.user.jobsPosted} jobs posted
                    </Text>
                  )}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VodeoPage;
