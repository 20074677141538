import {
  requestAcceptReportedIssueJob,
  requestApproveCompletedJob,
  requestCancelIssueAndApproveJob,
  requestPassIssueAdmin,
  requestResumeJob,
  requestStartJob
} from 'api/systemMessages';
import { ChatMessageType, SystemMessageState } from 'common';
import { SET_MESSAGE_ID_FOR_ACTION } from 'redux/chat/actionTypes';
import { ModifiedChannel } from 'redux/chat/types';
import {
  OPEN_COMPLETE_JOB_MODAL,
  OPEN_DECLINE_ISSUE_MODAL,
  OPEN_FEEDBACK_MODAL,
  OPEN_REPORT_ISSUE_MODAL,
  OPEN_TIP_MODAL
} from 'redux/modals/actionTypes';

type SystemMessageType = {
  title?: string;
  text?: string;
  textIcon?: string;
  buttons?: ButtonType[] | any[];
  seter?: () => void;
};

type ButtonType = {
  text: string;
  fn?: () => void;
  type?:
    | 'only-text'
    | 'default'
    | 'secondary'
    | 'white-bordered'
    | 'circle'
    | 'light-green'
    | 'white-shadowed'
    | 'white';
  disabled?: boolean;
};

type SystemMessagesType = {
  [key: string]: {
    forMe?: SystemMessageType;
    forOther?: SystemMessageType;
  };
};

type SystemMessageConfigurationTypes = {
  currentChannel: any; //ModifiedChannel | null |any;
  id?: string;
  type: ChatMessageType | any;
  state?: SystemMessageState | any;
  groupId?: string;
  metadata?: any;
  time?: string;
  setJobOfferSectionOpen?: () => void | undefined;
  openCompleteReportModal?: (type: string) => void | undefined;
  loadingBtnName?: string | undefined;
  setLoadingBtnName?: (text: string) => void | undefined;
  navigate?: (path: string) => void | undefined;
  dispatch?: any | undefined;
  setFilter?: (obj: any) => void;
};

const getSystemMessage = ({
  setJobOfferSectionOpen,
  openCompleteReportModal,
  currentChannel,
  groupId,
  loadingBtnName,
  setLoadingBtnName,
  navigate,
  dispatch,
  state,
  time,
  metadata,
  id,
  type,
  setFilter
}: SystemMessageConfigurationTypes) => {
  const typesArray = [];
  typesArray.push(type);
  const firstStateText = () => {
    if (state === 'NEW') {
      return { text: 'Review & Accept' };
    } else if (state === 'REJECTED') {
      // return 'x Rejected';
      return { text: ' Rejected', iconType: 'x' };
    } else if (state === 'CANCELED') {
      // return `x Canceled`;
      return { text: ' Canceled', iconType: 'x' };
    }
    return { text: '✓ Accepted' };
  };
  const SYSTEM_MESSAGES: SystemMessagesType = {
    MASTER_APPLY_JOB: {
      forMe: {
        title: 'Your Job application was sent!',
        text: `Please wait for ${currentChannel?.interlocutor.firstName} to approve the offer.`
      },
      forOther: {
        title: 'Master applied for the job',
        text: 'Review master’s profile and respond to their offer.',
        buttons: [
          ...(state === 'NEW'
            ? [
                {
                  text: firstStateText().text,
                  iconType: firstStateText()?.iconType,
                  fn: () => {
                    if (setJobOfferSectionOpen) {
                      setJobOfferSectionOpen();
                    }
                  },
                  type: 'light-green'
                }
              ]
            : []),

          ...(state === 'REJECTED' || state === 'CANCELED'
            ? [
                {
                  text: state === 'REJECTED' ? 'Rejected' : 'Canceled',
                  iconType: 'x',
                  type: 'default',
                  disabled: true
                }
              ]
            : []),
          ...(state === 'ACCEPTED'
            ? [
                {
                  text: '✓ Accepted',
                  type: 'default',
                  disabled: true
                }
              ]
            : [])
        ]
      }
    },
    MASTER_CANCELED_OFFER: {
      forMe: {
        title: 'You have cancelled your offer',
        text: `Continue looking for jobs. `,
        buttons: [
          {
            text: 'Explore jobs',
            type: 'light-green',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
            }
          }
        ]
      },
      forOther: {
        title: 'Master cancelled the offer!',
        text: `Continue looking for Masters.`,
        buttons: [
          {
            text: 'Explore Masters',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
            },
            type: 'light-green'
          }
        ]
      }
    },

    MASTER_APPLY_JOB_EDITED: {
      forMe: {
        title: 'Your have applied with a new price!',
        text: `Please wait for ${currentChannel?.interlocutor.firstName} to approve the new offer.`
      },
      forOther: {
        title: 'Master applied with a new price!',
        text: ` Please review ${currentChannel?.interlocutor.firstName} new offer and proceed.`,
        buttons: [
          {
            text: firstStateText().text,
            iconType: firstStateText()?.iconType,
            fn: () => {
              if (setJobOfferSectionOpen) {
                setJobOfferSectionOpen();
              }
            },
            type: 'light-green',
            disabled: state !== 'NEW'
          }
        ]
      }
    },
    DOER_APPLY_JOB_ACCEPTED: {
      forMe: {
        title: `You have accepted ${currentChannel?.interlocutor.firstName}'s offer!`,
        text: 'You’ll be notified when Master starts working.'
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName}  accepted your offer!`,
        text: 'Change the status once you start working on the job.',
        seter: () => {
          if (id && dispatch) {
            dispatch({
              type: SET_MESSAGE_ID_FOR_ACTION,
              payload: {
                id,
                type
              }
            });
          }
        },
        buttons: [
          {
            text: state === 'NEW' ? 'Start the job' : '✓ Job Started',
            type: 'light-green',
            fn: async () => {
              if (setLoadingBtnName && id) {
                setLoadingBtnName('requestStartJob');

                await requestStartJob(currentChannel?.jobContractId as string, id);
                setLoadingBtnName('');
              }
            },
            disabled: state !== 'NEW'
          }
        ]
      }
    },
    DOER_APPLY_JOB_REJECTED: {
      forMe: {
        title: `You have rejected the offer!`,
        text: 'Continue looking for Masters.',
        buttons: [
          {
            text: 'Explore Masters',
            type: 'light-green',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
            }
          }
        ]
      },
      forOther: {
        title: 'Offer is rejected!',
        text: 'Continue looking for jobs.',
        buttons: [
          {
            text: 'Explore Jobs',
            type: 'light-green',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
              // TODOFRONT: change tab to explore jobs
            }
          }
        ]
      }
    },

    DOER_CANCELED_OFFER: {
      forMe: {
        title: `You have cancelled your offer`,
        text: ' Continue looking for Masters. ',

        buttons: [
          {
            text: 'Explore Masters',
            type: 'light-green',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
            }
          }
        ]
      },
      forOther: {
        title: ' Offer is cancelled!',
        text: 'Continue looking for jobs.',
        buttons: [
          {
            text: 'Explore Jobs',
            type: 'light-green',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
              // TODOFRONT: change tab to explore jobs
            }
          }
        ]
      }
    },
    DOER_INVITE_TO_JOB: {
      forMe: {
        title: 'Your Job offer was sent!',
        text: `Please wait for Master ${currentChannel?.interlocutor.firstName} to review and accept.`
      },
      forOther: {
        title: 'You’ve got a job offer!',
        text: `Please review ${currentChannel?.interlocutor.firstName}'s offer and proceed.`,
        buttons: [
          ...(state === 'NEW'
            ? [
                {
                  text: 'Review & Accept',
                  type: 'light-green',
                  fn: () => {
                    if (setJobOfferSectionOpen) {
                      setJobOfferSectionOpen();
                    }
                  }
                }
              ]
            : []),

          ...(state === 'REJECTED' || state === 'CANCELED'
            ? [
                {
                  text: state === 'REJECTED' ? 'Rejected' : 'Canceled',
                  iconType: 'x',
                  type: 'default',
                  disabled: true
                }
              ]
            : []),
          ...(state === 'ACCEPTED'
            ? [
                {
                  text: '✓ Accepted',
                  type: 'default',
                  disabled: true
                }
              ]
            : [])
        ]
      }
    },
    DOER_INVITE_TO_JOB_EDITED: {
      forMe: {
        title: 'You’ve updated the Job Offer!',
        text: 'You’ve sent the updated Job Offer, please wait for Master to review and accept it.'
      },
      forOther: {
        title: ` ${currentChannel?.interlocutor.firstName} has updated the offer! `,
        text: 'Review and approve the new offer.',
        buttons: [
          {
            text: firstStateText().text,
            iconType: firstStateText()?.iconType,
            type: 'light-green',
            disabled: state !== 'NEW',
            fn: () => {
              if (setJobOfferSectionOpen) {
                setJobOfferSectionOpen();
              }
            }
          }
        ]
      }
    },
    MASTER_INVITATION_ACCEPTED: {
      forMe: {
        title: `You have accepted ${currentChannel?.interlocutor.firstName}'s offer!`,
        text: 'Change the status once you start working on the job.',
        buttons: [
          {
            text: state === 'NEW' ? ' Start the job' : '✓ Job Started',
            type: 'light-green',
            loading: loadingBtnName === 'requestStartJob',
            fn: async () => {
              if (setLoadingBtnName && id) {
                setLoadingBtnName('requestStartJob');
                await requestStartJob(currentChannel?.jobContractId as string, id);
                setLoadingBtnName('');
              }
            },
            disabled: state !== 'NEW'
          }
        ]
      },
      forOther: {
        title: 'Master has accepted your offer!',
        text: 'You’ll be notified when Master starts working.'
      }
    },
    MASTER_INVITATION_REJECTED: {
      forMe: {
        title: 'You have rejected the offer.',
        text: 'Continue looking for jobs.',
        buttons: [
          {
            text: 'Explore Jobs',
            type: 'light-green',
            fn: () => {
              if (navigate && setFilter) {
                setFilter({ sortBy: 'Matching', lookingFor: 'jobs' });
                navigate('/explore');
              }
            }
          }
        ]
      },
      forOther: {
        title: 'Master rejected the offer!',
        text: 'Continue looking for Masters.',
        buttons: [
          {
            text: 'Explore Masters',
            type: 'light-green',
            fn: () => {
              if (navigate) {
                navigate('/explore');
              }
            }
          }
        ]
      }
    },
    MASTER_JOB_STARTED: {
      forMe: {
        title: 'You have started the job.',
        text: 'Change the status once you finish your work.',
        seter: () => {
          if (id && dispatch) {
            dispatch({
              type: SET_MESSAGE_ID_FOR_ACTION,
              payload: {
                id,
                type,
                groupId
              }
            });
          }
        },
        buttons: [
          {
            text: state === 'NEW' ? 'Mark as completed' : '✓ Job is completed',
            type: 'light-green',
            disabled: state !== 'NEW',

            fn: async () => {
              if (openCompleteReportModal) {
                openCompleteReportModal(OPEN_COMPLETE_JOB_MODAL);
              }
            }
          }
        ]
      },
      forOther: {
        title: 'Work is started!!',
        text: 'You’ll be notified when Master finishes his work.'
      }
    },
    MASTER_JOB_RESUMED: {
      forMe: {
        title: ' You have resumed the job.',
        text: 'Change the status once you finish your work.',
        seter: () => {
          if (id && dispatch) {
            dispatch({
              type: SET_MESSAGE_ID_FOR_ACTION,
              payload: {
                id,
                type,
                groupId
              }
            });
          }
        },
        buttons: [
          {
            text: 'Job is completed',
            type: 'light-green',
            disabled: state !== 'NEW',
            fn: async () => {
              // setLoadingBtnName('requestCompleteJob');
              // await requestCompleteJob(selectedChannel?.jobContractId as string, id, groupId);
              // setLoadingBtnName('');
              // TODOFRONT: open modal with photos/videos and textarea to complete the job
              if (openCompleteReportModal) {
                openCompleteReportModal(OPEN_COMPLETE_JOB_MODAL);
              }
            }
          }
        ]
      },
      forOther: {
        title: 'Master resumed working!',
        text: `You’ll be notified when Master finishes his work.`
      }
    },
    MASTER_JOB_MARKED_AS_COMPLETED: {
      forMe: {
        title: 'You marked job as completed!',
        text: `Please wait for ${currentChannel?.interlocutor.firstName} to approve the job.`
      },
      forOther: {
        title: 'Master marked job as completed!',
        text: 'Master finished the job, please review and accept.',
        seter: () => {
          if (id && dispatch) {
            dispatch({
              type: SET_MESSAGE_ID_FOR_ACTION,
              payload: {
                id,
                type
              }
            });
          }
        },
        buttons: [
          ...(state === 'NEW'
            ? [
                {
                  text: 'Report Issue',
                  type: 'default',
                  fn: () => {
                    if (openCompleteReportModal) {
                      openCompleteReportModal(OPEN_REPORT_ISSUE_MODAL);
                    }
                  }
                },
                {
                  text: 'Accept & Pay',
                  type: 'light-green',
                  loading: loadingBtnName === 'requestApproveCompletedJob',
                  seter: () => {
                    if (id && dispatch) {
                      dispatch({
                        type: SET_MESSAGE_ID_FOR_ACTION,
                        payload: {
                          id,
                          type
                        }
                      });
                    }
                  },
                  fn: async () => {
                    if (setLoadingBtnName && id) {
                      setLoadingBtnName('requestApproveCompletedJob');
                      await requestApproveCompletedJob(currentChannel?.jobContractId as string, id);
                      setLoadingBtnName('');
                    }
                  }
                }
              ]
            : []),
          ...(state === 'APPROVED' || state === 'ISSUE_REPORTED'
            ? [
                {
                  text: state === 'APPROVED' ? ' ✓ Approved' : 'Issue Reported',
                  iconType: state === 'APPROVED' ? '' : 'x',
                  type: 'default',
                  disabled: true
                }
              ]
            : [])
        ]
      }
    },
    DOER_JOB_COMPLETION_APPROVED: {
      forMe: {
        title: 'You have approved the work.',
        text: 'The payment will be automatically proceed from your account.',
        buttons: [
          {
            text: state === 'NEW' ? 'Leave a tip' : `✓ $${metadata?.tipAmount} tip is added`,
            disabled: state !== 'NEW',
            type: 'light-green',
            fn: () => {
              dispatch({
                type: OPEN_TIP_MODAL,
                payload: {
                  show: true,
                  contractId: currentChannel?.jobContractId,
                  systemMessageId: id
                }
              });
            }
          }
        ]
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} approved your work!`,
        text: `You'll be paid for it shortly.`
      }
    },
    DOER_JOB_COMPLETION_REPORT_ISSUE: {
      forMe: {
        title: 'You have reported an issue!',
        text: `Please wait for ${currentChannel?.interlocutor.firstName} to respond.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} reported an issue !`,
        text: 'Please review the reported issue(s) and respond.',
        buttons: [
          ...(state === 'NEW'
            ? [
                {
                  text: 'Decline',
                  type: 'default',
                  loading: loadingBtnName === 'requestDeclineReportedIssueJob',
                  fn: async () => {
                    if (setLoadingBtnName && openCompleteReportModal) {
                      setLoadingBtnName('requestDeclineReportedIssueJob');
                      openCompleteReportModal(OPEN_DECLINE_ISSUE_MODAL);
                      // await requestDeclineReportedIssueJob(
                      //   selectedChannel?.jobContractId as string,
                      //   id,
                      //   groupId
                      // );
                      setLoadingBtnName('');
                    }
                  }
                },
                {
                  text: 'Accept Issue',
                  type: 'light-green',
                  loading: loadingBtnName === 'requestAcceptReportedIssueJob',
                  fn: async () => {
                    if (setLoadingBtnName && id) {
                      setLoadingBtnName('requestAcceptReportedIssueJob');
                      await requestAcceptReportedIssueJob(
                        currentChannel?.jobContractId as string,
                        id
                      );
                      setLoadingBtnName('');
                    }
                  }
                }
              ]
            : []),
          ...(state === 'ISSUE_ACCEPTED' || state === 'ISSUE_DECLINED'
            ? [
                {
                  text: state === 'ISSUE_ACCEPTED' ? '✓ Issue Accepted' : 'Issue declined',
                  iconType: state === 'ISSUE_ACCEPTED' ? '' : 'x',
                  type: 'default',
                  disabled: true
                }
              ]
            : [])
        ]
      }
    },
    MASTER_JOB_COMPLETION_REPORT_ISSUE_ACCEPTED: {
      forMe: {
        title: 'You have accepted reported issue(s).',
        text: 'Change the status once you resume working on the job.',
        seter: () => {
          if (id && dispatch) {
            dispatch({
              type: SET_MESSAGE_ID_FOR_ACTION,
              payload: {
                id,
                type
              }
            });
          }
        },
        buttons: [
          {
            text: state === 'NEW' ? 'Resume the job ' : '✓ Job resumed',
            type: 'light-green',
            classNames: 'w-[180px]',
            loading: loadingBtnName === 'requestResumeJob',

            fn: async () => {
              if (setLoadingBtnName && id) {
                setLoadingBtnName('requestResumeJob');
                await requestResumeJob(currentChannel?.jobContractId as string, id);
                setLoadingBtnName('');
              }
            },
            disabled: state !== 'NEW'
          }
        ]
      },
      forOther: {
        title: 'Master accepted the issue!',
        text: `${currentChannel?.interlocutor.firstName} has accepted the issue. You’ll be notified when he starts/resumes working.`
      }
    },
    MASTER_JOB_COMPLETION_REPORT_ISSUE_DECLINED: {
      forMe: {
        title: 'You have declined reported issue(s).',
        text: ` If ${currentChannel?.interlocutor.firstName} doesn’t accept the work, it will be passed to System Admin for review.`
      },
      forOther: {
        title: 'Master declined the issue(s) ',
        text: `Please review again and respond. If you do not respond between 3 days it will be passed to Admin for review autometically.`,
        buttons: [
          ...(state === 'NEW'
            ? [
                {
                  text: 'Cancel Issue & Approve the Job',
                  type: 'light-green',
                  classNames: '',
                  loading: loadingBtnName === 'requestCancelIssueAndApproveJob',
                  fn: async () => {
                    if (setLoadingBtnName && id) {
                      setLoadingBtnName('requestCancelIssueAndApproveJob');
                      await requestCancelIssueAndApproveJob(
                        currentChannel?.jobContractId as string,
                        id
                      );
                      setLoadingBtnName('');
                    }
                  }
                },
                {
                  text: 'Pass the issue to Admin',
                  type: 'default',
                  classNames: '',
                  loading: loadingBtnName === 'requestPassIssueAdmin',
                  fn: async () => {
                    if (setLoadingBtnName && id) {
                      setLoadingBtnName('requestPassIssueAdmin');
                      await requestPassIssueAdmin(currentChannel?.jobContractId as string, id);
                      setLoadingBtnName('');
                    }
                  }
                }
              ]
            : []),
          ...(state === 'ISSUE_DECLINED' || state === 'APPROVED'
            ? [
                {
                  text: state === 'ISSUE_DECLINED' ? 'Pass the issue to Admin' : 'Issue ancelled',
                  type: 'light-green',
                  disabled: true
                }
              ]
            : [])
        ]
      }
    },
    DOER_JOB_COMPLETION_REPORT_ISSUE_CANCELED: {
      forMe: {
        title: 'You cancelled the issue & approved the job!',
        text: 'The payment will be automatically proceed from your account.',
        buttons: [
          {
            text: 'Leave a tip',
            disabled: state !== 'NEW',
            type: 'light-green',
            fn: () => {
              dispatch({
                type: OPEN_TIP_MODAL,
                payload: {
                  show: true,
                  contractId: currentChannel?.jobContractId,
                  systemMessageId: id
                }
              });
            }
          }
        ]
      },
      forOther: {
        title: `Job owner accepted your job!`,
        text: `You'll be paid for it shortly.`
      }
    },
    DOER_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN: {
      forMe: {
        title: 'The case was passed to Admin.',
        text: 'The Admin will review your case and contact you shortly.'
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} did not accept your job and passed it to Admin team!`,
        text: 'The Admin will review your case and contact you shortly.'
      }
    },
    DOER_JOB_PAID: {
      forMe: {
        title: `Master has been paid! `,
        text: `$${metadata?.paidAmount} has been transferred to Master’s account.`,
        buttons: [
          {
            text:
              state === 'NEW' || state === 'FEEDBACK_LEFT_MASTER'
                ? 'Rate the experience'
                : `✓ Rated ${metadata?.masterRatingStart} ⭐️ 67 `,
            type: 'light-green',
            disabled: state !== 'NEW' && state !== 'FEEDBACK_LEFT_MASTER',
            fn: async () => {
              console.log('open rate experience modal');
              dispatch({
                type: OPEN_FEEDBACK_MODAL,
                payload: {
                  show: true,
                  contractId: currentChannel?.jobContractId,
                  systemMessageId: id
                }
              });
            }
          }
        ]
      },
      forOther: {
        title: `You have been paid!`,
        text: `$${metadata?.paidAmount} has been transferred to your account.`,
        buttons: [
          {
            text:
              state === 'NEW' || state === 'FEEDBACK_LEFT_DOER'
                ? 'Rate the experience'
                : `✓ Rated ${metadata?.doerRatingStart} ⭐️`,
            type: 'light-green',
            disabled: state !== 'NEW' && state !== 'FEEDBACK_LEFT_DOER',
            fn: () => {
              console.log('open rate experience modal');
              dispatch({
                type: OPEN_FEEDBACK_MODAL,
                payload: {
                  show: true,
                  contractId: currentChannel?.jobContractId,
                  systemMessageId: id
                }
              });
            }
          }
        ]
      }
    },
    DOER_JOB_TIPPED: {
      forMe: {
        title: ` You have added $${metadata?.tipAmount} as a tip`,
        text: `Payment will be done shortly`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `You’ll receive a payment shortly.`
      }
    },

    SYSTEM_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN: {
      forMe: {
        title: ` The case was passed to Admin automatically.`,
        text: `You did not respond to the issue denial in 72 hours and the case was passed to platform Admins automatically. They will review your case and contact you shortly.`
      },
      forOther: {
        title: `The case was passed to Admin automatically.`,
        text: `${currentChannel?.interlocutor.firstName} did not respond to the issue denial in 72 hours and the case was passed to platform Admins automatically. They will review your case and contact you shortly.`
      }
    },
    DOER_FEEDBACK_LEFT: {
      forMe: {
        title: ` You gave  345  ${metadata?.masterRatingStart} ⭐️ to ${currentChannel?.interlocutor.firstName}! `,
        text: `${metadata?.feedbackText}`
      },
      forOther: {
        title: `You have received ${metadata?.masterRatingStart} ⭐️ from ${currentChannel?.interlocutor.firstName}`,
        text: `${metadata?.feedbackText}`
      }
    },
    MASTER_FEEDBACK_LEFT: {
      forMe: {
        title: ` You gave  ${metadata?.doerRatingStart} ⭐️ to ${currentChannel?.interlocutor.firstName}! `,
        text: `${metadata?.feedbackText}`
      },
      forOther: {
        title: `You have received ${metadata?.doerRatingStart} ⭐️ from ${currentChannel?.interlocutor.firstName}`,
        text: `${metadata?.feedbackText}`
      }
    },

    DOER_JOB_TIP_FAILED_FINAL_ATTEMPTS: {
      forMe: {
        title: ` Tip transfer from your account is failed!`,
        text: `We’re not able to transfer $${metadata?.tipAmount} tip from your account.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `We’re not able to transfer $${metadata?.tipAmount} tip from your account.`
      }
    },
    DOER_JOB_TIP_FAILED_ATTEMPTS_1: {
      forMe: {
        title: ` Tip transfer from your account is failed!`,
        text: `We’re not able to transfer tip from your account. Please make sure that you  account is up and can proceed $${metadata?.tipAmount}. We will try again in 12 hours.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `You’ll receive a payment shortly.`
      }
    },

    DOER_JOB_TIP_FAILED_ATTEMPTS_2: {
      forMe: {
        title: `Tip transfer from your account is failed!`,
        text: `We’re not able to transfer tip from your account. Please make sure that you account is up and can proceed $${metadata?.tipAmount}. We will try again in 12 hours.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `You’ll receive a payment shortly.`
      }
    },

    DOER_JOB_PAYMENT_FAILED_FINAL_ATTEMPTS: {
      forMe: {
        title: ` The contract based Payment is failed!`,
        text: `We’re not able to proceed the contract based payment. Master is notified.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `You’ll receive a payment shortly.`
      }
    },

    DOER_JOB_PAYMENT_FAILED_ATTEMPTS_1: {
      forMe: {
        title: ` The first Payment attempt is failed!`,
        text: `We’re not able to proceed the contract based payment. Please make sure that you  account is up and can proceed $${metadata?.amountToPay}. We will try again in 12 hours.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `You’ll receive a payment shortly.`
      }
    },

    DOER_JOB_PAYMENT_FAILED_ATTEMPTS_2: {
      forMe: {
        title: ` The second Payment attempt is failed!`,
        text: `We’re not able to proceed the contract based payment. Please make sure that you  account is up and can proceed $${metadata?.amountToPay}. We will try again in 12 hours.`
      },
      forOther: {
        title: `${currentChannel?.interlocutor.firstName} added $${metadata?.tipAmount} as a tip!`,
        text: `You’ll receive a payment shortly.`
      }
    }
  };

  const seterOther = SYSTEM_MESSAGES[type]?.forOther?.seter;
  const seterMe = SYSTEM_MESSAGES[type]?.forMe?.seter;

  if (seterOther !== undefined) {
    seterOther();
  }
  if (seterMe !== undefined) {
    seterMe();
  }
  return SYSTEM_MESSAGES[type];
};

export { getSystemMessage };
