import { ChatChannelFilterRequest } from 'common';
import {
  ADD_NEW_CHANNEL,
  ADD_NEW_MESSAGE,
  FETCH_CHANNELS,
  FETCH_CHANNELS_FAILURE,
  FETCH_CHANNELS_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MORE_MESSAGES,
  RESET_CHAT,
  SET_MODIFIED_CHANNELS,
  SET_REDIRECTED_CHANEL_ID,
  SET_SELECTED_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_MESSAGE,
  SET_MESSAGE_ID_FOR_ACTION,
  GET_UNSEEN_MESSAGES_SUCCESS,
  GET_UNSEEN_MESSAGES_REQUEST,
  MESSAGES_MARK_AS_SEEN_REQUEST,
} from './actionTypes';
import {
  FetchChannels,
  FetchChannelsSuccess,
  FetchChannelsSuccessPayload,
  FetchChannelsFailure,
  FetchChannelsFailurePayload,
  SelectedChannelPayload,
  SelectChannel,
  ModifiedChannels,
  SetModifiedChannels,
  FetchMessagesFailure,
  FetchMessagesFailurePayload,
  FetchMessagesSuccess,
  FetchMessagesSuccessPayload,
  FetchMoreMessages,
  ResetChat,
  AddNewMessage,
  AddNewMessagePayload,
  AddNewChannel,
  AddNewChannelPayload,
  UpdateMessagePayload,
  UpdateMessage,
  UpdateChannel,
  UpdateChannelPayload,
  SetRedirectedChanelId,
  SetMessageIdForAction,
  FetchUnseenMessagesRequest,
  FetchMessagesAsSeenRequest
} from './types';

export const resetChat = (): ResetChat => ({
  type: RESET_CHAT
});

export const fetchChannels = (payload: ChatChannelFilterRequest): FetchChannels => ({
  type: FETCH_CHANNELS,
  payload
});

export const addNewChannel = (payload: AddNewChannelPayload): AddNewChannel => ({
  type: ADD_NEW_CHANNEL,
  payload
});

export const updateChannel = (payload: UpdateChannelPayload): UpdateChannel => ({
  type: UPDATE_CHANNEL,
  payload
});

export const fetchChannelsSuccess = (
  payload: FetchChannelsSuccessPayload
): FetchChannelsSuccess => ({
  type: FETCH_CHANNELS_SUCCESS,
  payload
});

export const fetchChannelsFailure = (
  payload: FetchChannelsFailurePayload
): FetchChannelsFailure => ({
  type: FETCH_CHANNELS_FAILURE,
  payload
});

export const setModifiedChannels = (payload: ModifiedChannels): SetModifiedChannels => ({
  type: SET_MODIFIED_CHANNELS,
  payload
});

export const setSelectedChannel = (payload: SelectedChannelPayload): SelectChannel => ({
  type: SET_SELECTED_CHANNEL,
  payload
});

export const fetchMoreMessages = (): FetchMoreMessages => ({
  type: FETCH_MORE_MESSAGES
});

export const fetchMessagesSuccess = (
  payload: FetchMessagesSuccessPayload
): FetchMessagesSuccess => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload
});

export const fetchMessagesFailure = (
  payload: FetchMessagesFailurePayload
): FetchMessagesFailure => ({
  type: FETCH_MESSAGES_FAILURE,
  payload
});

export const addNewMessage = (payload: AddNewMessagePayload): AddNewMessage => ({
  type: ADD_NEW_MESSAGE,
  payload
});

export const updateMessage = (payload: UpdateMessagePayload): UpdateMessage => ({
  type: UPDATE_MESSAGE,
  payload
});

export const setRedirectedChanelId = (payload: {redirectedChanelId:string|null}): SetRedirectedChanelId => ({
  type: SET_REDIRECTED_CHANEL_ID,
  payload
});
export const fetchUnseenChannelsRequest = (): FetchUnseenMessagesRequest => ({
  type: GET_UNSEEN_MESSAGES_REQUEST,
});
export const fetchMessagesMarkAllAsSeen= (payload: {channelId:string}): FetchMessagesAsSeenRequest => ({
  type: MESSAGES_MARK_AS_SEEN_REQUEST,  
  payload
});


// export const fetchUnseenChannelsSuccess = (
//   payload: any
// ): any => ({
//   type: GET_UNSEEN_MESSAGES_SUCCESS,
//   payload
// });

// export const setMessageIdForAction = (payload: {messageIdForAction:string|null}): SetMessageIdForAction => {
//   return {
//     type: SET_MESSAGE_ID_FOR_ACTION,
//     payload
//   }
// };
