import {
  JobFilterRequest,
  PaymentMethodPurpose,
  ProfileDoerUpdateRequest,
  ProfileMasterUpdateRequest,
  UserReviewFilterRequest
} from 'common';
import * as types from './types';

export const getAuthUserAction = () => ({
  type: types.USER_GET_AUTH_USER
});

export const keepRegUserDataAction = (payload: any) => ({
  type: types.USER_KEEP_REGISTER_DATA,
  payload
});

export const keepEmailForPassResetAction = (payload: any) => ({
  type: types.USER_KEEP_EMAIL_FOR_RESET,
  payload
});

export const keepCodeForPassResetAction = (payload: any) => ({
  type: types.USER_KEEP_CODE_FOR_RESET,
  payload
});

export const keepUserAction = (payload: any) => ({
  type: types.KEEP_USER,
  payload
});

export const keepBecomeMasterDataAction = (payload: any) => ({
  type: types.KEEP_BECOME_MASTER_DATA,
  payload
});

export const getPrifileByUserId = (payload: {
  userId: string;
}): types.FetchGetProfileByIdRequest => ({
  type: types.GET_PROFILE_BY_ID_REQUEST,
  payload
});

export const updatePrifile = (payload: {
  profileData: ProfileMasterUpdateRequest;
}): types.FetchUpdateProfileRequest => ({
  type: types.UPDATE_PROFILE_REQUEST,
  payload
});

export const updateDoerPrifile = (payload: {
  profileData: ProfileDoerUpdateRequest;
}): types.FetchUpdateDoerProfileRequest => ({
  type: types.UPDATE_DOER_PROFILE_REQUEST,
  payload
});

export const getUserVideos = (payload: { userId: string }): types.FetchGetUserVideosRequest => ({
  type: types.GET_USER_VIDEOS_REQUEST,
  payload
});
export const getUserReviews = (payload: {
  userId: string;
  jsonData: UserReviewFilterRequest;
}): types.FetchGetUserReviewsRequest => ({
  type: types.GET_USER_REVIEWS_REQUEST,
  payload
});
export const deleteVideoById = (payload: { id: string }): types.FetchDeleteVideoRequest => ({
  type: types.DELETE_VIDEO_REQUEST,
  payload
});

export const fetchGetMyJobs = (
  payload: JobFilterRequest
): types.FetchGetLoggedInUserJobsRequest => ({
  type: types.GET_LOGGED_IN_USER_JOBS_REQUSET,
  payload
});

export const fetchGetUserPaymentsMethods = (): types.FetchGetPaymentsMethodsRequest => ({
  type: types.GET_USER_PAYMENTS_METHODS_REQUEST
});

export const fetchGetUserPayInMethods = (): types.FetchGetPayInMethodsRequest => ({
  type: types.GET_USER_PAYIN_METHODS_REQUEST
});

export const fetchDeletePaymentMethod = (payload: {
  id: string;
  purpose: PaymentMethodPurpose;
}): types.FetchDeletePaymentMethodRequest => ({
  type: types.DELETE_PAYMENTS_METHOD_REQUEST,
  payload
});

export const fetchPaymentMethodAsDefault = (payload: {
  id: string;
  purpose: PaymentMethodPurpose;
}): types.FetchPaymentMethodAsDefaultRequest => ({
  type: types.SET_PAYMENT_METHOD_DEFAULT_REQUEST,
  payload
});

export const fetchDeleteUserAccount = (payload: {
  userId: string;
}): types.FetchDeleteUserAccountRequest => ({
  type: types.DELETE_USER_ACCOUNT_REQUEST,
  payload
});

export const fetchGetTotalPayments = (): types.FetchGetTotalPaymentRequest => ({
  type: types.GET_TOTAL_PAYMENT_REQUEST
});
