import { getTransactions } from 'api/transactions';
import { PaymentFilterRequest, PaymentItem, PaymentsFilterResponse } from 'common';
import { all, takeLatest, fork, put, call } from 'redux-saga/effects';

import * as types from './types';

function* getTransactionsSaga(action: { type: string; payload: PaymentFilterRequest }) {
  try {
    const response = (yield call(getTransactions, action.payload)) as PaymentsFilterResponse | null;

    yield put({
      type: types.TRANSACTIONS_GET_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.TRANSACTIONS_GET_FAILED
      //payload: e?.response?.data
    });
  }
}
function* transactionsLoadMoreSaga(action: { type: string; payload: PaymentFilterRequest }) {
  try {
    const response = (yield call(getTransactions, action.payload)) as PaymentsFilterResponse | null;

    // console.log(response,'TRANSACTIONS_LOAD_MORE_SUCCESS')
    yield put({
      type: types.TRANSACTIONS_LOAD_MORE_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e, 'error');
    yield put({
      type: types.TRANSACTIONS_GET_FAILED
      //payload: e?.response?.data
    });
  }
}

function* watchTransactions() {
  yield takeLatest(types.TRANSACTIONS_GET_REQUEST, getTransactionsSaga);
  yield takeLatest(types.TRANSACTIONS_LOAD_MORE_REQUEST, transactionsLoadMoreSaga);
}

export default function* transactionsSagas() {
  yield all([fork(watchTransactions)]);
}
