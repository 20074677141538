import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  isMaster?: boolean;
};

const MyLocation: React.FC<Props> = ({ isMaster }): JSX.Element => (
  <div
    className={classNames(styles.outerCircle, {
      [styles.isMaster]: isMaster
    })}
  >
    <span className={styles.inner} />
  </div>
);

export default MyLocation;
