import { Row, Col, Divider, Typography, Space } from 'antd';
import classNames from 'classnames';

const { Text } = Typography;

interface IProfileInfoProps {
  data: {
    profileImage: string | undefined;
    rating: number | undefined;
    reviews: number | undefined;
    jobsCompleted: number | undefined;
    fullName: string;
  };
  size?: 'md' | 'sm';
}
const ProfileInfo = ({
  data: { profileImage = '', rating, reviews, jobsCompleted, fullName = '' },
  size = 'sm'
}: IProfileInfoProps) => {
  const sizes = {
    sm: {
      img: 42,
      titleFont: 14,
      font: 14,
      infoHeight: 38
    },
    md: {
      img: 52,
      titleFont: 22,
      font: 16,
      infoHeight: 38
    }
  };
  return (
    <Row className={classNames('flex-nowrap', 'items-center')} align="bottom">
      <img
        src={profileImage}
        width={sizes[size].img}
        height={sizes[size].img}
        className="rounded-full"
      ></img>
      <Col className={`ml-3`}>
        <Space className={classNames({ ['mb-1']: size === 'md' })}>
          <Text className={classNames(`text-font-${sizes[size].titleFont}`, 'font-bold')}>
            {fullName}
          </Text>
        </Space>
        <Row align="middle">
          <i className={classNames('icon-26', 'text-orange')}></i>
          <Text className={classNames('ml-2', 'font-bold')}>{rating}</Text>
          <Divider type="vertical" className="mt-1" />
          <Text className="font-normal">{reviews} reviews</Text>
          <Divider type="vertical" className="mt-1" />
          <Text className="font-normal">{jobsCompleted} jobs completed</Text>
        </Row>
      </Col>
    </Row>
  );
};

export default ProfileInfo;
