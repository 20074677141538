import { Typography, Col, Row, Space } from 'antd';
import { requestLeaveTip } from 'api/systemMessages';
import CustomInput from 'components/CustomInput';
import { useState } from 'react';
import DButton from 'components/DButton';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_TIP_MODAL } from 'redux/modals/actionTypes';

import styles from './index.module.scss';
import OrDivider from 'components/OrDivider';
import Input from 'components/Input';
import CustomAvatar from 'components/CustomAvatar';

const { Text } = Typography;

type TipType = {
  amount: string;
  selected: boolean;
};

interface ITipModal {
  img?: string;
  name: string;
  firstName: string;
  lastName: string;
}

interface ITipField {
  tipAmount: string;
}
interface ITipNode {
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode | string;
}

const TipNode = ({ selected, onClick, children }: ITipNode) => {
  return (
    <Row
      className={classNames(styles.tipNodeContainer, { [styles.selected]: selected })}
      onClick={onClick}
    >
      {children}
    </Row>
  );
};

const TipModal = ({ img, name, firstName, lastName }: ITipModal) => {
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid }
  } = useForm<ITipField>({
    mode: 'onChange',
    defaultValues: {
      tipAmount: ''
    }
  });
  const dispatch = useDispatch();
  const { tipModal } = useSelector((state: any) => state.modals);
  const [loading, setLoading] = useState(false);
  const [tipList, setTipList] = useState<TipType[]>([
    {
      amount: '1',
      selected: false
    },
    {
      amount: '5',
      selected: false
    },
    {
      amount: '10',
      selected: false
    }
  ]);

  const handleSubmitClick = async (fields: ITipField) => {
    try {
      setLoading(true);
      await requestLeaveTip(tipModal.contractId, tipModal.systemMessageId, +fields.tipAmount);
      closeModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    dispatch({
      type: CLOSE_TIP_MODAL
    });
  };

  const handleTipSelect = (index: number) => {
    const tempTipList = tipList.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          selected: true
        };
      }
      return {
        ...item,
        selected: false
      };
    });
    setValue('tipAmount', tempTipList[index].amount);
    setTipList(tempTipList);
    trigger('tipAmount');
  };

  const handleInputChange = () => {
    const tempTipList = tipList.map((item, i) => {
      return {
        ...item,
        selected: false
      };
    });
    setTipList(tempTipList);
  };

  return (
    <Col className={styles.container}>
      <Row align="middle" justify="center">
        <Text className="text-font-22  font-bold">Leave a tip </Text>
      </Row>
      <Col className="mt-10">
        <Row className={styles.imgContainer} justify="center">
          <CustomAvatar
            firstName={firstName ? firstName[0] : ''}
            lastName={lastName ? lastName[0] : ''}
            size={100}
            replaceImageSize="100x100"
            imagePath={img}
            nameClasses="bg-[#999999] font-medium text-[16px]"
          />
        </Row>
        <Row justify="center" className="mt-2">
          <Text className="text-font-16 font-bold">{name}</Text>
        </Row>
      </Col>
      <Col className="mt-10">
        <Row className="w-full gap-[10px]" align="middle" justify="center">
          {tipList.map((item, index) => (
            <Col
              key={index}
              className="w-[120px] cursor-pointer"
              onClick={() => handleTipSelect(index)}
            >
              <TipNode selected={item.selected} onClick={() => {}}>
                <Row justify="center" className="w-full h-[48px]" align="middle">
                  <Text className="text-font-16">${item.amount}</Text>
                </Row>
              </TipNode>
            </Col>
          ))}
        </Row>
        <Col className="mt-[30px]">
          <Col className="max-w-[376px] ml-auto mr-auto">
            <OrDivider text="or" />
          </Col>
          <Row justify="center" align="middle"></Row>
        </Col>
        <Col className="mt-[32px]">
          <Row justify="center" className="mb-4">
            <Text className="text-font-16 font-bold">Enter custom amount</Text>
          </Row>
          <Row justify="center">
            <Col className="w-[187px]">
              <Row justify="center" className="w-full h-[40px]" align="middle">
                <Controller
                  control={control}
                  name="tipAmount"
                  rules={{
                    max: { value: 100, message: 'Tip amount must be less than or equal to 100$' },
                    min: { value: 1, message: 'Tip amount must be greater than or equal to 1$' },
                    required: {
                      value: true,
                      message: 'Tip amount must be greater than or equal to 1$'
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <CustomInput
                      allowedInput={'number'}
                      className={styles.tipInput}
                      value={value}
                      onChange={e => {
                        onChange(e);
                        handleInputChange();
                      }}
                      placeholder="$--"
                    />
                  )}
                />
              </Row>
              {errors.tipAmount && (
                <Row justify="center" className="mt-2">
                  <Text className="text-font-14 text-red-500">{errors.tipAmount.message}</Text>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Col>

      <Row justify="center" className="mt-[64px]">
        <Space size={16}>
          <DButton type="white" className="!w-[148px] !h-[44px]" onClick={closeModal}>
            Cancel
          </DButton>
          <DButton
            type="light-green"
            className="text-font-16 font-bold text-white w-[200px] h-[44px]"
            onClick={handleSubmit(fields => handleSubmitClick(fields))}
            submit
            disabled={!isValid}
            loading={loading}
          >
            Leave a tip
          </DButton>
        </Space>
      </Row>
    </Col>
  );
};

export default TipModal;
