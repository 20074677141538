import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Col, Row } from 'antd';

const PayPalConnection: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const result = queryParams.get('result');
  const [timer, setTimer] = useState<number>(5);
  const [resultText, setResultText] = useState<{
    title: string;
    text: string;
  }>();

  useEffect(() => {
    let timeoutID = null;
    if (result === 'rejected' || result === 'approved') {
      if (result === 'rejected') {
        setResultText({
          title: 'PayPal Account Connection Rejected',
          text: "Unfortunately, we couldn't connect your PayPal account to our platform. Please try again or contact our support team if the problem persists."
        });
      } else {
        setResultText({
          title: 'PayPal Account Connected Successfully!',
          text: 'Your PayPal account has been successfully connected to our platform. You can now use it for payments and receive money on this platform.'
        });
      }
      timeoutID = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      if (window.opener != null && !window.opener.closed) {
        parent.window.opener.postMessage(result, process.env.REACT_APP_HOSTING);
        parent.window.close();
      }
    }
  }, [timer]);

  return (
    <div className={styles.paypalSuccess}>
      <Row>
        <Col span={12} offset={6}>
          <Row className="justify-center">
            <div className={styles.timerBlock}>
              <h1 className="text-center ">{timer}</h1>
            </div>
          </Row>
          <>
            <h1 className="text-center text-[25px] py-2">{resultText?.title}</h1>
            <p className="text-center text-[20px] py-2">{resultText?.text}</p>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default PayPalConnection;
