import api from './index';
import { ProfilesFilterResponse, ProfileFilterRequest, Profile, UserResponse } from 'common';
import qs from 'qs';

// maybe need to write in other service
export const getMasters = async (
  _params: ProfileFilterRequest = {}
): Promise<ProfilesFilterResponse> => {
  return await api.get('/users/profile', {
    params: _params,
    paramsSerializer: _params => {
      _params.sortOrder = _params.sortBy === 'rating' ? -1 : _params.sortOrder;
      return qs.stringify(_params);
    }
  });
};

export const getMaster = (id: string): Promise<UserResponse> => {
  return api.get(`/users/${id}/profile`);
};
