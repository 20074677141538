import React from 'react';
import { Outlet } from 'react-router-dom';
import WalletImg from 'assets/images/wallet.png';
import classNames from 'classnames';
import styles from './index.module.scss';
import MainLayout from 'components/MainLayout';
import Login from 'components/Auth/Login';
import Register from 'components/Auth/Register';
import FirstView from 'components/Auth/FirstView';
import VerifyEmail from 'components/Auth/VerifyEmail';
import EmailVerified from 'components/Auth/EmailVerified';
import ForgotPassword from 'components/Auth/ForgotPassword';
import CodeVerification from 'components/Auth/CodeVerification';
import SetNewPassword from 'components/Auth/SetNewPassword';
import PassChanged from 'components/Auth/PassChanged';
import Steps from 'components/Auth/Steps';
import MasterFlowConatiner from 'components/Auth/Containers/MasterFlowConatiner';
import Introduction from 'components/Auth/Introduction';
import Areas from 'components/Auth/Areas';
import Address from 'components/Auth/Address';
import Price from 'components/Auth/Price';
import Services from 'components/Auth/Services';
import Radius from 'components/Auth/Radius';
import VideoUpload from 'components/Auth/VideoUpload';
import DocumentsUpload from 'components/Auth/DocumentsUpload';
import Review from 'components/Auth/Review';
import AccountType from 'components/Auth/AccountType';
import Company from 'components/Auth/Company';

import { GoogleOAuthProvider } from '@react-oauth/google';
import PayPalConnection from './PayPalConnection';
import PymentMethodConnection from './PymentMethodConnection';

const Auth: React.FC = (): JSX.Element => (
  <div className="grid h-full">
    <div className="flex">
      <div className={classNames([styles.slider])}>
        <div>
          <img src={WalletImg} alt="wallet" />
          <p className="text-green text-lg text-center font-bold mb-4">Who we are?</p>
          <p className="text-sm text-light-gray items-center justify-center text-center px-[38px]">
            Post any service and any job with relevant video
          </p>
        </div>
      </div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Outlet />
      </GoogleOAuthProvider>
    </div>
  </div>
);

export {
  MainLayout,
  Login,
  Register,
  FirstView,
  VerifyEmail,
  EmailVerified,
  ForgotPassword,
  CodeVerification,
  SetNewPassword,
  PassChanged,
  Steps,
  MasterFlowConatiner,
  Introduction,
  Areas,
  Address,
  Price,
  Services,
  Radius,
  VideoUpload,
  DocumentsUpload,
  Review,
  AccountType,
  Company,
  PayPalConnection,
  PymentMethodConnection
};

export default Auth;
