import PaylapIcon from 'assets/icons/paypal.svg';
import VisaIcon from 'assets/icons/visa.png';
import MasterCardIcon from 'assets/icons/mastercard.jpeg';
import AmexCardIcon from 'assets/icons/amex.png';
import DiscoverCardIcon from 'assets/icons/discover.png';
import DinersCardIcon from 'assets/icons/diners.png';
import JcbCardIcon from 'assets/icons/jcb.jpeg';
import UnionpayCardIcon from 'assets/icons/unionpay.png';
import StripeIcon from 'assets/icons/stripe.png';

import styles from './index.module.scss';
import classNames from 'classnames';

const Methods: any = {
  PAYPAL: {
    name: 'PayPal account',
    icon: PaylapIcon
  },
  STRIPE: {
    name: 'PayPal account',
    icon: StripeIcon
  }
};

const Brands: any = {
  visa: {
    name: 'Visa',
    icon: VisaIcon
  },
  mastercard: {
    name: 'Master',
    icon: MasterCardIcon
  },
  amex: {
    name: 'American Expres',
    icon: AmexCardIcon
  },
  discover: {
    name: 'Discover',
    icon: DiscoverCardIcon
  },
  diners: {
    name: 'Diners',
    icon: DinersCardIcon
  },
  jcb: {
    name: 'JCB',
    icon: JcbCardIcon
  },
  unionpay: {
    name: 'UnionPay',
    icon: UnionpayCardIcon
  }
};

type PaymentMethodItemTypes = {
  brand?: string;
  expDate?: string;
  cardNumber?: number;
  status?: string;
  email?: string;
  bankName?: string;
  accountType?: string;
  purpose: string;
  isDefault: boolean;
  method: string;
  userFullName: string;
  id: string;
  onDeleteClick: (id: string) => void;
  onSetDefault: (id: string) => void;
};

const PaymentMethodItem = ({
  brand,
  method,
  id,
  isDefault,
  cardNumber,
  expDate,
  userFullName,
  email,
  purpose,
  bankName,
  accountType,
  onDeleteClick,
  onSetDefault
}: PaymentMethodItemTypes) => {
  const onClick = () => {
    onDeleteClick(id);
  };

  const setAsDefault = () => {
    onSetDefault(id);
  };

  if (expDate) {
    let year = expDate.substring(0, 4);
    let month = expDate.substring(4, expDate.length);

    if (Number(month) < 10) {
      month = 0 + month;
    }
    expDate = `${month}/${year}`;
  }

  const getEmailParts = (email: string) => {
    var parts = email.split('@');

    if (parts.length === 2) {
      var username = parts[0];
      var domain = parts[1];
      var maskedUsername = username.charAt(0) + '*****';
      return maskedUsername + '@' + domain;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={classNames(styles.container, 'flex mt-2 justify-between items-center')}>
        <div className={classNames(styles.leftSide, 'flex items-center')}>
          {method === 'STRIPE' && purpose === 'PAYIN' && (
            <>
              {brand && accountType === 'card' ? (
                <img className="h-[50px] w-[50px]" src={Brands[brand].icon} alt="icon" />
              ) : (
                <img className="h-[50px] w-[50px]" src={Methods[method].icon} alt="icon" />
              )}
            </>
          )}

          {method === 'STRIPE' && purpose === 'PAYOUT' && brand && (
            <img className="h-[50px] w-[50px]" src={Brands[brand].icon} alt="icon" />
          )}
          {method === 'PAYPAL' && (
            <img className="h-[50px] w-[50px]" src={Methods[method].icon} alt="icon" />
          )}
          {method === 'STRIPE' && (
            <>
              {purpose === 'PAYOUT' && expDate && cardNumber && (
                <div className="ml-3">
                  <div className="font-bold">{userFullName}</div>
                  <div className="text-[rgb(110,110,110)]">
                    *****{cardNumber}
                    <span className="ml-2">Expires: {expDate} </span>
                  </div>
                </div>
              )}

              {purpose === 'PAYIN' && accountType === 'card' && (
                <div className="ml-3">
                  <div className="font-bold">{userFullName}</div>
                  <div className="text-[rgb(110,110,110)]">
                    *****{cardNumber}
                    <span className="ml-2">Expires: {expDate} </span>
                  </div>
                </div>
              )}

              {purpose === 'PAYIN' && accountType === 'bank_account' && (
                <div className="ml-3">
                  <div className="font-bold">{bankName}</div>
                  <div className="text-[rgb(110,110,110)]">*****{cardNumber}</div>
                </div>
              )}
            </>
          )}
          {method === 'PAYPAL' && (
            <div className="ml-2 font-bold">{email && getEmailParts(email)}</div>
          )}
        </div>
        <div className={classNames(styles.rightSide)}>
          {isDefault ? (
            <span className={classNames(styles.textFont, 'text-[#B7B7B7]')}>Default</span>
          ) : (
            <span
              onClick={setAsDefault}
              className={classNames(styles.textFont, 'text-[#49C53B] ml-2 cursor-pointer')}
            >
              Set as defauilt
            </span>
          )}
          <span className={classNames(styles.dot)}></span>

          <span
            onClick={onClick}
            className={classNames(styles.textFont, 'text-[#D30000] ml-2 cursor-pointer')}
          >
            Delete
          </span>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodItem;
