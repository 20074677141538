import { Col, Divider, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import styles from '../index.module.scss';
import { IChatHeaderProfileInfo } from '../types';
import CircleBgIcon from 'components/Icons/CircleBgIcon';
import { ModifiedChannel } from 'redux/chat/types';
import { useSelector } from 'react-redux';
import CustomAvatar from 'components/CustomAvatar';

const { Text } = Typography;
const ChatHeaderProfileInfo = ({
  data: {
    fullName,
    firstName,
    lastName,
    img,
    rating,
    reviews,
    master,
    pro,
    bestMatch,
    jobsCount = 0,
    isMaster
  },
  showBadges,
  showReviews,
  isJobOfferSectionOpen,
  setIsJobOfferSectionOpen,
  fontSize
}: IChatHeaderProfileInfo) => {
  const { selectedChannel }: { selectedChannel: ModifiedChannel } = useSelector(
    (state: any) => state.chat
  );

  const isOfferAccepted = () => {
    if (
      selectedChannel.status !== 'NEW' &&
      selectedChannel.status !== 'REJECTED' &&
      selectedChannel.status !== 'CANCELED'
    ) {
      return true;
    }
    return false;
  };

  return (
    <Row
      className={classNames(styles.chatHeaderProfileInfoContainer, 'flex-nowrap')}
      align={showReviews ? 'top' : 'middle'}
    >
      <Col span={21}>
        <div
          className={classNames('flex', setIsJobOfferSectionOpen ? 'items-start' : 'items-center')}
        >
          <CustomAvatar
            firstName={firstName ? firstName[0] : ''}
            lastName={lastName ? lastName[0] : ''}
            size={40}
            replaceImageSize="40x40"
            imagePath={img}
            nameClasses="bg-[#999999] font-medium text-[16px]"
          />
          {/* <img src={img} width={32} height={32} className="rounded-full"></img> */}
          <div className="ml-3 ">
            <div className="flex ">
              <Text className={classNames('font-bold', 'leading-[20px],', fontSize)}>
                {fullName}
              </Text>
              {showBadges && (
                <>
                  {master && (
                    <Row
                      justify="center"
                      align="middle"
                      className={classNames('ml-1 bg-[#EE784B] rounded-[20px]')}
                    >
                      <Text className="text-font-12 font-bold text-white px-2 py-[2px]">
                        Master
                      </Text>
                    </Row>
                  )}
                  {bestMatch && (
                    <CircleBgIcon circleClasses="ml-1" circleColor="blue" icon="icon-26" />
                  )}
                  {pro && <CircleBgIcon circleClasses="ml-1" circleColor="orange" icon="icon-25" />}
                </>
              )}
            </div>
            {showReviews && (
              <Row align="middle">
                <i className={classNames('icon-26', 'text-orange')}></i>
                <Text className={classNames('ml-2', 'font-bold')}>{rating}</Text>
                <Text className={classNames('ml-2', 'font-normal')}>({reviews})</Text>
                <Divider type="vertical" className="mt-1 border-[#18191A33]" />

                {isMaster && <Text className="font-normal">{jobsCount} jobs completed</Text>}
                {!isMaster && <Text className="font-normal">{jobsCount} jobs posted</Text>}
              </Row>
            )}
          </div>
        </div>
      </Col>
      {setIsJobOfferSectionOpen && !isJobOfferSectionOpen && (
        <Col className={styles.viewOffer} onClick={() => setIsJobOfferSectionOpen(true)}>
          {isOfferAccepted() ? 'View Contract' : 'View Offer'}
        </Col>
      )}
    </Row>
  );
};

export default ChatHeaderProfileInfo;
