/**
 * @format
 */
/* eslint-disable max-classes-per-file */

import { StatusCodes } from 'http-status-codes';

type ValidationErrorDetails = {
  [name: string]: unknown;
};

interface ErrorResponseJSON {
  message: string;
  status?: number;
  name?: string;

  // Validation Error
  details?: ValidationErrorDetails;
}

class StandardizedError extends Error {
  status = 500;

  name = 'InternalServerError';

  toJSON(): ErrorResponseJSON {
    return {
      name: this.name,
      status: this.status,
      message: this.message,
    };
  }
}

class BadRequestError extends StandardizedError {
  name = 'BadRequest';

  status = StatusCodes.BAD_REQUEST;
}

class ConflictError extends StandardizedError {
  name = 'Conflict';

  status = StatusCodes.CONFLICT;
}

class ForbiddenError extends StandardizedError {
  name = 'Forbidden';

  status = StatusCodes.FORBIDDEN;
}

class InternalServerError extends StandardizedError {
  name = 'InternalServerError';

  status = StatusCodes.INTERNAL_SERVER_ERROR;
}

class NotFoundError extends StandardizedError {
  name = 'NotFound';

  status = StatusCodes.NOT_FOUND;
}

class UnauthorizedError extends StandardizedError {
  name = 'Unauthorized';

  status = StatusCodes.UNAUTHORIZED;
}

class ValidationError extends StandardizedError {
  status = StatusCodes.UNPROCESSABLE_ENTITY;

  message = 'Validation Failed';

  details: ValidationErrorDetails = {};

  constructor(details: ValidationErrorDetails) {
    super();
    this.details = details;
  }

  toJSON(): ErrorResponseJSON {
    return {
      message: this.message,
      details: this.details,
    };
  }
}

export {
  StandardizedError,
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError,
  ValidationError,
  ConflictError,
};
