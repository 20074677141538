import DButton from 'components/DButton';
import styles from './index.module.scss';
import vid from 'assets/images/vdo.png';
import classNames from 'classnames';
import { Row, Col, Typography, Space, Skeleton } from 'antd';
import ProfileInfo from '../ProfileInfo';
import Tab from 'components/Tab';
import ServicesInfo from '../ServicesInfo';
import LabelData from '../LabelData';
import React, { useEffect, useRef, useState } from 'react';
import { getMaster } from 'api/masters';
import { IUserReview, MediaItem, UserResponse, UserReviewFilterRequest } from 'common';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectAsync } from 'hooks';
import Video from 'components/Video';
import { showMasterPrices, toFixedUserRating } from 'helpers';
import moment from 'moment';
import { GALLERY_MODAL_OPEN, OPEN_HIRE_MODAL, OPEN_LOGIN_MODAL } from 'redux/modals/actionTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { alert } from 'helpers';
import useUser from 'redux/user/use-user';
import { getUserReviews, getUserVideos } from 'redux/user/actions';
import { userReviews, userVideos } from 'redux/user/selectors';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';
import ReviewItem from 'components/ReviewItem';
import MessageMediaCard from 'components/Chat/components/MessageMediaCard';
import { filterObj } from 'helpers';

const { Text } = Typography;
interface IDescription {
  children: string | undefined;
  icon: string;
}
const Description = ({ icon, children }: IDescription) => {
  return (
    <Col span={10} className="mt-1 flex items-center">
      <i className={`${icon} text-lg`} />
      <Text ellipsis={{ tooltip: true }} className="ml-1 w-[200px]">
        {children}
      </Text>
    </Col>
  );
};

const About: React.FC<{ master: UserResponse | undefined }> = ({ master }): JSX.Element => {
  return (
    <Col>
      <Space direction="vertical">
        <Text className="text-light-gray font-10 font-normal">{master?.introduction}</Text>
      </Space>
      <Col className="mt-6">
        <Text className="font-black normal mt-4">Overview </Text>
      </Col>
      <Row gutter={[3, 2]} className="mt-4">
        {!!master?.jobsCompleted && (
          <Description icon="icon-37">
            {`Hired ${master?.jobsCompleted} Time${master?.jobsCompleted === 1 ? '' : 's'}`}
          </Description>
        )}
        {!!master?.documentsChecked && (
          <Description icon="icon-36">{'Documents Checked'}</Description>
        )}
        {!!master?.address && <Description icon="icon-40">{master.address}</Description>}
        <Description icon="icon-38">
          {`Joined Doers: ${moment(master?.createdAt).format('MMM')} ${moment(
            master?.createdAt
          ).format('YYYY')}`}
        </Description>
        {!!master?.radius && <Description icon="icon-41">{`${master.radius} mi`}</Description>}
        {!!master?.licenseVerified && (
          <Description icon="icon-39">{'License verified'}</Description>
        )}
      </Row>
    </Col>
  );
};
const Reviews: React.FC<{ master: UserResponse | undefined }> = ({ master }): JSX.Element => {
  const filterInit: UserReviewFilterRequest = {
    page: 1,
    userId: ''
  };

  const dispatch = useDispatch();
  const virtuoso = useRef<any>(null);
  const [filter, setFilter] = useState<UserReviewFilterRequest>(filterInit);
  const reviews: Array<IUserReview> = useSelector(userReviews);

  useEffect(() => {
    if (master?._id) {
      dispatch(getUserReviews({ userId: master?._id, jsonData: filter }));
    }
  }, [master]);

  const loadMoreReviews = () => {};
  return (
    <>
      <Virtuoso
        className="overflow-x-hidden max-h-[32rem]"
        ref={virtuoso}
        endReached={loadMoreReviews}
        data={reviews}
        overscan={200}
        style={{
          width: '100%',
          height: '100vh'
        }}
        computeItemKey={index => index}
        itemContent={(index, item: IUserReview) => {
          const { ratingStart, leftBy, feedback } = item;
          return (
            <ReviewItem
              date={item.createdAt}
              feedback={feedback}
              user={leftBy}
              rating={ratingStart}
            />
          );
        }}
      />
    </>
  );
};

const Videos: React.FC<{ master: UserResponse | undefined }> = ({ master }): JSX.Element => {
  const filterInit: UserReviewFilterRequest = {
    page: 1,
    userId: ''
  };

  const dispatch = useDispatch();
  const virtuoso = useRef<any>(null);
  const [filter, setFilter] = useState<UserReviewFilterRequest>(filterInit);
  // const videos:Array<IUserReview> = useSelector(userReviews);
  const videos: Array<MediaItem> = useSelector(userVideos);

  useEffect(() => {
    if (master?._id) {
      dispatch(getUserVideos({ userId: master?._id }));
    }
  }, [master]);

  const handleOnMediaClick = (files: any, file: any) => {
    const photosTemp: string[] = [];
    const videosTemp: string[] = [];
    files?.forEach((file: any) => {
      videosTemp.push('media/' + file.video);
    });
    addContentToModal('media/' + file.video, photosTemp, videosTemp);
  };

  const addContentToModal = (activeItem: string | undefined, photos: any[], videos: any[]) => {
    const payloadTemp = {
      photos,
      videos,
      activeItem: activeItem
    };

    const filteredPayload = filterObj(payloadTemp, ['empty-array']);

    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: filteredPayload
    });
  };

  const loadMoreReviews = () => {};

  return (
    <>
      <Row className={classNames()} gutter={10} justify={'center'} wrap>
        <VirtuosoGrid
          className={classNames(styles.virtuosoGrid, 'overflow-x-hidden max-h-[32rem]')}
          ref={virtuoso}
          endReached={loadMoreReviews}
          data={videos}
          overscan={200}
          style={{
            width: '100%',
            height: '100vh'
          }}
          components={{
            List: Row
          }}
          computeItemKey={index => index}
          itemContent={(index, video: any) => {
            return (
              <Col
                key={`video-${index}`}
                className={classNames(styles.videoGrid, 'cursor-pointer mt-3')}
              >
                <span onClick={() => handleOnMediaClick(videos, video)}>
                  <MessageMediaCard
                    src={`${process.env.REACT_APP_MEDIA_BASE_URL}/media/${video?.photo.replace(
                      'original',
                      '100x100'
                    )}`}
                    height={100}
                    width={100}
                    preview={false}
                    mediaType={'VIDEO'}
                    className="border-solid border-2"
                    videoPlayerIcon={true}
                  />
                </span>
              </Col>
            );
          }}
        />
      </Row>
    </>
  );
};

const ProfileView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [master, setMaster] = useState<UserResponse>();
  const [chosenTab, setChosenTab] = useState('about');
  const { state: locationState }: any = location;
  const { user } = useUser();

  const tabData = {
    about: {
      tab: 'About',
      content: <About master={master} />
    },
    videos: {
      tab: 'Videos ',
      content: <Videos master={master} />
    },

    reviews: {
      tab: 'Reviews',
      content: <Reviews master={master} />
    }
    // credentials: {
    //   tab: 'Credentials'
    // }
  };

  useEffectAsync(async () => {
    try {
      const master = await getMaster(locationState.master);
      setMaster(master);
    } catch (error) {
      alert.error('Master not found.');
      navigate('/explore');
    }
  }, []);

  const onVideoClickHandle = () => {
    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: {
        videos: [master?.mediaPath?.original]
      }
    });
  };

  console.log(master?.mediaPath, 'master?.mediaPath?');
  let thumbPath = '';

  if (master?.mediaPath && master?.mediaPath?.original) {
    const ext = master?.mediaPath?.original.split('.')[1];
    thumbPath = master?.mediaPath?.original
      .replace('video', 'photo')
      .replace('original', '270x310')
      .replace(`${ext}`, 'png');
  }

  const handleHireFlow = () => {
    if (user._id) {
      dispatch({
        type: OPEN_HIRE_MODAL,
        payload: {
          show: true,
          masterId: master?._id
        }
      });
    } else {
      dispatch({
        type: OPEN_LOGIN_MODAL,
        payload: {
          show: true
        }
      });
    }
  };

  return (
    <Col>
      {master ? (
        <Row className="flex flex-1 flex-nowrap">
          <Col>
            <Video
              thumb={process.env.REACT_APP_MEDIA_BASE_URL + thumbPath}
              size="lg"
              onClick={onVideoClickHandle}
            />
          </Col>
          <Col className="ml-7 text-base flex justify-between flex-col">
            <Row>
              <Row>
                {master.bestMatch && (
                  <Col className="mb-4">
                    <Row align="middle">
                      <div className={classNames(styles.iconContainer, styles.blue)}>
                        <i className="icon-26 text-font-12 text-white" />
                      </div>
                      <Text className="font-bold text-light-gray ml-2">Best Match</Text>
                    </Row>
                  </Col>
                )}
                {master.pro && (
                  <Col className="mb-4">
                    <Row align="middle" className="ml-4">
                      <div className={classNames(styles.iconContainer, styles.orange)}>
                        <i className="icon-25 text-font-12 text-white" />
                      </div>
                      <Text className="font-bold text-light-gray ml-2">Pro Account</Text>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <ProfileInfo
                  data={{
                    profileImage: vid,
                    rating: toFixedUserRating(master.masterReviewAvg),
                    reviews: master.masterReviews,
                    jobsCompleted: master.jobsCompleted,
                    fullName: master.fullName
                  }}
                  size="md"
                />
              </Row>
              <Row className="w-full">
                <ServicesInfo data={master.services.map(item => item.service)} />
              </Row>
              <Row>
                <LabelData label="Working hours:" value="N/A" classes="mt-6" />
              </Row>
            </Row>
            <Row>
              <Space direction="vertical">
                <Text className="font-bold text-font-22 mt-6 block">
                  {showMasterPrices({
                    minFlat: master.minFlatPrice,
                    maxFlat: master.maxFlatPrice,
                    minHourly: master.minHourlyRate,
                    maxHourly: master.maxHourlyRate,
                    long: true
                  })}
                </Text>
                <DButton
                  type="light-green"
                  onClick={handleHireFlow}
                  className={classNames(styles.hireButton, 'mt-5')}
                >
                  <Text className="font-bold text-white">Hire {master.fullName.split(' ')[0]}</Text>
                </DButton>
              </Space>
            </Row>
          </Col>
        </Row>
      ) : (
        <div className="flex">
          <div className="mr-4">
            <Skeleton.Avatar size={150} active shape="square" />
          </div>
          <Skeleton active />
        </div>
      )}
      <Tab
        classes="mt-10"
        data={tabData}
        bottomBorder
        activeKey={chosenTab}
        centered={true}
        handleTabChange={tab => setChosenTab(tab)}
      />
    </Col>
  );
};

export default ProfileView;
