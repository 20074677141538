
import styles from './index.module.scss';
import classNames from 'classnames';
import { JOB_STATUSES } from '../../constants';
import { Typography } from 'antd';
import DButton from 'components/DButton';
import moment from 'moment';
import Video from 'components/Video';


type PostedJobItemType = {
  title:string;
  id:string;
  status?:string;
  createdAt?:string;
  channelId?:string;
  video:any;
  isActive:boolean;
  onClick:(id:string)=>void;
  viewChat:(id:string)=>void
}


const { Text } = Typography;

const PostedJobItem = ({title,status,createdAt,video,onClick,id,isActive,viewChat,channelId}:PostedJobItemType )=>{

  let formattedDate = moment(createdAt).local().format('MMM DD, YYYY HH:mm');
  if (moment().diff(moment(createdAt), 'days') < 1) {
    formattedDate = moment(createdAt).local().format('LT');
  }


   let videoPath= '' 
   if(video.thumbnail){
    videoPath= video?.thumbnail.replace('original', '100x100')
   }

return(
  <>
  <div  className={classNames(styles.container,{
    [styles.containerIsActive]:isActive
  },'flex box')} onClick={()=>onClick(id)}  >
    <div  className={classNames(styles.leftSide)}>

            



    <Video
              thumb={process.env.REACT_APP_MEDIA_BASE_URL +videoPath}
              size="message"
              />       
          
    </div>
    <div  className={classNames(styles.rightSide,'')}> 

    {status && (
   <div className={classNames(styles.jobStatus,'flex')}>
   <Text
                 className={classNames(
                   'text-white text-[10px] px-[8px] py-[3px] rounded-[20px] font-semibold',
                   JOB_STATUSES[status]?.color
                 )}
               >
                 {JOB_STATUSES[status]?.text}
               </Text>
     </div>   
    )

    }
    
      <div className={classNames(styles.jobTitle,'')}>{title}</div>      
      <div className={classNames(styles.jobDate,'flex justify-between')}> 
      <span>
      {formattedDate}
      </span>
      
      </div>   
      <div className={classNames('flex justify-end')}>
      <div className={classNames(styles.jobAction,'')}> 
      {(status !== 'NEW' && channelId) && (
            <DButton
            text="View Chat"
            onClick={() => viewChat(channelId)}
            type="white"
            className={styles.viewChatBtn}
          />
      )
      }
    
      </div>
        
        
        </div>   
         

    </div>
  </div>
  </>
)

}

export default PostedJobItem;
