import { Avatar, Col, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { setSelectedChannel } from 'redux/chat/actions';
import { ChannelCardProps } from '../types';
import { memo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModifiedChannel } from 'redux/chat/types';
import { JOB_STATUSES } from '../../../constants';
import useUser from 'redux/user/use-user';
import { getSystemMessage } from './SystemMessageConfiguration';
import CustomAvatar from 'components/CustomAvatar';

const { Text } = Typography;
const base_path = process.env.REACT_APP_MEDIA_BASE_URL;

const ChannelCard = ({
  unreadMessages = 0,
  isUnreadMessage = false,
  status,
  img,
  lastMessage,
  lastMessageType,
  title,
  date,
  interlocutor,
  _id,
  isActive,
  jobContractId,
  lastMessageUserId,
  lastMessageMetadata,
  setActiveChannel
}: ChannelCardProps) => {
  let formattedDate = moment(date).local().format('MMM DD, YYYY');
  if (moment().diff(moment(date), 'days') < 1) {
    formattedDate = moment(date).local().format('LT');
  }
  // const {
  //   modifiedChannels,
  //   selectedChannel
  // }: {
  //   modifiedChannels: ModifiedChannel[];
  //   selectedChannel: ModifiedChannel;
  // } = useSelector((state: any) => state.chat);

  const {
    user: { _id: me }
  } = useUser();
  const dispatch = useDispatch();

  // const setActiveChannel = () => {

  //   selectedChannel?._id !== _id &&
  //     dispatch(
  //       setSelectedChannel({
  //         selectedChannel: modifiedChannels.find((ch: ModifiedChannel) => ch._id === _id)
  //       })
  //     );
  // };

  const rendererLastMessage = () => {
    if (lastMessageType === 'TEXT') {
      return lastMessage;
    } else if (lastMessageType === 'MEDIA') {
      return 'Media or other files';
    } else {
      const isMe = lastMessageUserId === me;
      const listSettings = {
        id: _id,
        type: lastMessageType,
        state: status,
        metadata: lastMessageMetadata,
        currentChannel: { interlocutor, jobContractId }
      };

      const message = getSystemMessage(listSettings);
      const messageKey = isMe ? 'forMe' : 'forOther';
      const messageObject = message?.[messageKey];

      return messageObject?.title;
    }
  };

  const onActive = () => {
    if (!isActive && interlocutor.status !== 'DELETED') {
      setActiveChannel(_id);
    }
  };
  return (
    <div
      className={classNames('w-auto text-font-14  relative p-3 pl-4 hover:bg-[#F4F7FA]', {
        'bg-[#F4F7FA]': isActive,
        'bg-[#F7F7F7]': interlocutor.status === 'DELETED',
        'cursor-pointer': interlocutor.status !== 'DELETED'
      })}
      onClick={onActive}
    >
      <Row wrap={false}>
        {isActive && <div className="absolute h-[100%] bg-green w-[5px] top-0 left-0"></div>}
        <Col>
          <CustomAvatar
            firstName={interlocutor?.firstName}
            lastName={interlocutor?.lastName}
            size={32}
            replaceImageSize="40x40"
            imagePath={interlocutor?.profilePicture}
            nameClasses="bg-[#999999] font-medium text-[16px]"
          />
        </Col>
        <Col className="w-full ml-3">
          <Space direction="vertical" size={4} className="w-full">
            <Row justify="space-between" className="w-auto">
              <Text className="font-semibold truncate w-52">{interlocutor.fullName}</Text>
              <Text className="text-font-12">{formattedDate}</Text>
            </Row>
            <Row justify="space-between">
              <Text ellipsis className={classNames('w-[251px]', { 'font-bold': isUnreadMessage })}>
                {rendererLastMessage()}
              </Text>
              {!!unreadMessages && (
                <p className="bg-orange min-w-[21px] min-h-[21px] rounded-full text-white font-bold !text-font-12 flex justify-center items-center">
                  {unreadMessages < 99 ? unreadMessages : 99}
                </p>
              )}
            </Row>
            <Row className="text-font-12">
              <Col>
                <Text
                  className={classNames(
                    'text-white text-[10px] px-[8px] py-[2px]  rounded-[20px] font-semibold',
                    JOB_STATUSES[status]?.color
                  )}
                >
                  {JOB_STATUSES[status]?.text}
                </Text>
              </Col>
              <Col className="ml-2 max-w-[220px]">
                <Text ellipsis className="opacity-[0.66]">
                  {title}
                </Text>
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ChannelCard);
