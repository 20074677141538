/** @format */

import axios, { AxiosError } from 'axios';
import { SendSocketMessage, SendSocketMessageResponse } from '../model/business/socket';
import { StatusError } from '../model/errors';

const socketServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.SOCKET_SERVICE_URL!,
  apiKey: 'testKey',
};

export const sendSocketMessageToUsers = async (socketMessage: SendSocketMessage) =>
  postData<SendSocketMessageResponse>(`/api/socket/send-to-users`, socketMessage);

interface SocketApiResponse {}

interface SocketApiError {
  name: string;
  message: string;
}

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<SocketApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const handleHttpError = (error: AxiosError<SocketApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${socketServiceConfig.url}${path}`;
