/** @format */
export interface CountryCode {
  key: string;
  value: string;
  code3: string;
  number: string;
}

export type CountryCodes = CountryCode[];
/**
 * Represents an ISO 3166-ALPHA-2 & 3166-ALPHA-3 conform country code accessible through the full
 * english name of the country.
 */
export const countryCodes: CountryCodes = [
  { value: 'AF', code3: 'AFG', key: 'Afghanistan', number: '004' },
  { value: 'AL', code3: 'ALB', key: 'Albania', number: '008' },
  { value: 'DZ', code3: 'DZA', key: 'Algeria', number: '012' },
  { value: 'AS', code3: 'ASM', key: 'American Samoa', number: '016' },
  { value: 'AD', code3: 'AND', key: 'Andorra', number: '020' },
  { value: 'AO', code3: 'AGO', key: 'Angola', number: '024' },
  { value: 'AI', code3: 'AIA', key: 'Anguilla', number: '660' },
  { value: 'AQ', code3: 'ATA', key: 'Antarctica', number: '010' },
  { value: 'AG', code3: 'ATG', key: 'Antigua and Barbuda', number: '028' },
  { value: 'AR', code3: 'ARG', key: 'Argentina', number: '032' },
  { value: 'AM', code3: 'ARM', key: 'Armenia', number: '051' },
  { value: 'AW', code3: 'ABW', key: 'Aruba', number: '533' },
  { value: 'AU', code3: 'AUS', key: 'Australia', number: '036' },
  { value: 'AT', code3: 'AUT', key: 'Austria', number: '040' },
  { value: 'AZ', code3: 'AZE', key: 'Azerbaijan', number: '031' },
  { value: 'BS', code3: 'BHS', key: 'Bahamas (the)', number: '044' },
  { value: 'BH', code3: 'BHR', key: 'Bahrain', number: '048' },
  { value: 'BD', code3: 'BGD', key: 'Bangladesh', number: '050' },
  { value: 'BB', code3: 'BRB', key: 'Barbados', number: '052' },
  { value: 'BY', code3: 'BLR', key: 'Belarus', number: '112' },
  { value: 'BE', code3: 'BEL', key: 'Belgium', number: '056' },
  { value: 'BZ', code3: 'BLZ', key: 'Belize', number: '084' },
  { value: 'BJ', code3: 'BEN', key: 'Benin', number: '204' },
  { value: 'BM', code3: 'BMU', key: 'Bermuda', number: '060' },
  { value: 'BT', code3: 'BTN', key: 'Bhutan', number: '064' },
  { value: 'BO', code3: 'BOL', key: 'Bolivia (Plurinational State of)', number: '068' },
  { value: 'BQ', code3: 'BES', key: 'Bonaire, Sint Eustatius and Saba', number: '535' },
  { value: 'BA', code3: 'BIH', key: 'Bosnia and Herzegovina', number: '070' },
  { value: 'BW', code3: 'BWA', key: 'Botswana', number: '072' },
  { value: 'BV', code3: 'BVT', key: 'Bouvet Island', number: '074' },
  { value: 'BR', code3: 'BRA', key: 'Brazil', number: '076' },
  { value: 'IO', code3: 'IOT', key: 'British Indian Ocean Territory (the)', number: '086' },
  { value: 'BN', code3: 'BRN', key: 'Brunei Darussalam', number: '096' },
  { value: 'BG', code3: 'BGR', key: 'Bulgaria', number: '100' },
  { value: 'BF', code3: 'BFA', key: 'Burkina Faso', number: '854' },
  { value: 'BI', code3: 'BDI', key: 'Burundi', number: '108' },
  { value: 'CV', code3: 'CPV', key: 'Cabo Verde', number: '132' },
  { value: 'KH', code3: 'KHM', key: 'Cambodia', number: '116' },
  { value: 'CM', code3: 'CMR', key: 'Cameroon', number: '120' },
  { value: 'CA', code3: 'CAN', key: 'Canada', number: '124' },
  { value: 'KY', code3: 'CYM', key: 'Cayman Islands (the)', number: '136' },
  { value: 'CF', code3: 'CAF', key: 'Central African Republic (the)', number: '140' },
  { value: 'TD', code3: 'TCD', key: 'Chad', number: '148' },
  { value: 'CL', code3: 'CHL', key: 'Chile', number: '152' },
  { value: 'CN', code3: 'CHN', key: 'China', number: '156' },
  { value: 'CX', code3: 'CXR', key: 'Christmas Island', number: '162' },
  { value: 'CC', code3: 'CCK', key: 'Cocos (Keeling) Islands (the)', number: '166' },
  { value: 'CO', code3: 'COL', key: 'Colombia', number: '170' },
  { value: 'KM', code3: 'COM', key: 'Comoros (the)', number: '174' },
  { value: 'CD', code3: 'COD', key: 'Congo (the Democratic Republic of the)', number: '180' },
  { value: 'CG', code3: 'COG', key: 'Congo (the)', number: '178' },
  { value: 'CK', code3: 'COK', key: 'Cook Islands (the)', number: '184' },
  { value: 'CR', code3: 'CRI', key: 'Costa Rica', number: '188' },
  { value: 'HR', code3: 'HRV', key: 'Croatia', number: '191' },
  { value: 'CU', code3: 'CUB', key: 'Cuba', number: '192' },
  { value: 'CW', code3: 'CUW', key: 'Curaçao', number: '531' },
  { value: 'CY', code3: 'CYP', key: 'Cyprus', number: '196' },
  { value: 'CZ', code3: 'CZE', key: 'Czechia', number: '203' },
  { value: 'CI', code3: 'CIV', key: "Côte d'Ivoire", number: '384' },
  { value: 'DK', code3: 'DNK', key: 'Denmark', number: '208' },
  { value: 'DJ', code3: 'DJI', key: 'Djibouti', number: '262' },
  { value: 'DM', code3: 'DMA', key: 'Dominica', number: '212' },
  { value: 'DO', code3: 'DOM', key: 'Dominican Republic (the)', number: '214' },
  { value: 'EC', code3: 'ECU', key: 'Ecuador', number: '218' },
  { value: 'EG', code3: 'EGY', key: 'Egypt', number: '818' },
  { value: 'SV', code3: 'SLV', key: 'El Salvador', number: '222' },
  { value: 'GQ', code3: 'GNQ', key: 'Equatorial Guinea', number: '226' },
  { value: 'ER', code3: 'ERI', key: 'Eritrea', number: '232' },
  { value: 'EE', code3: 'EST', key: 'Estonia', number: '233' },
  { value: 'SZ', code3: 'SWZ', key: 'Eswatini', number: '748' },
  { value: 'ET', code3: 'ETH', key: 'Ethiopia', number: '231' },
  { value: 'FK', code3: 'FLK', key: 'Falkland Islands (the) [Malvinas]', number: '238' },
  { value: 'FO', code3: 'FRO', key: 'Faroe Islands (the)', number: '234' },
  { value: 'FJ', code3: 'FJI', key: 'Fiji', number: '242' },
  { value: 'FI', code3: 'FIN', key: 'Finland', number: '246' },
  { value: 'FR', code3: 'FRA', key: 'France', number: '250' },
  { value: 'GF', code3: 'GUF', key: 'French Guiana', number: '254' },
  { value: 'PF', code3: 'PYF', key: 'French Polynesia', number: '258' },
  { value: 'TF', code3: 'ATF', key: 'French Southern Territories (the)', number: '260' },
  { value: 'GA', code3: 'GAB', key: 'Gabon', number: '266' },
  { value: 'GM', code3: 'GMB', key: 'Gambia (the)', number: '270' },
  { value: 'GE', code3: 'GEO', key: 'Georgia', number: '268' },
  { value: 'DE', code3: 'DEU', key: 'Germany', number: '276' },
  { value: 'GH', code3: 'GHA', key: 'Ghana', number: '288' },
  { value: 'GI', code3: 'GIB', key: 'Gibraltar', number: '292' },
  { value: 'GR', code3: 'GRC', key: 'Greece', number: '300' },
  { value: 'GL', code3: 'GRL', key: 'Greenland', number: '304' },
  { value: 'GD', code3: 'GRD', key: 'Grenada', number: '308' },
  { value: 'GP', code3: 'GLP', key: 'Guadeloupe', number: '312' },
  { value: 'GU', code3: 'GUM', key: 'Guam', number: '316' },
  { value: 'GT', code3: 'GTM', key: 'Guatemala', number: '320' },
  { value: 'GG', code3: 'GGY', key: 'Guernsey', number: '831' },
  { value: 'GN', code3: 'GIN', key: 'Guinea', number: '324' },
  { value: 'GW', code3: 'GNB', key: 'Guinea-Bissau', number: '624' },
  { value: 'GY', code3: 'GUY', key: 'Guyana', number: '328' },
  { value: 'HT', code3: 'HTI', key: 'Haiti', number: '332' },
  { value: 'HM', code3: 'HMD', key: 'Heard Island and McDonald Islands', number: '334' },
  { value: 'VA', code3: 'VAT', key: 'Holy See (the)', number: '336' },
  { value: 'HN', code3: 'HND', key: 'Honduras', number: '340' },
  { value: 'HK', code3: 'HKG', key: 'Hong Kong', number: '344' },
  { value: 'HU', code3: 'HUN', key: 'Hungary', number: '348' },
  { value: 'IS', code3: 'ISL', key: 'Iceland', number: '352' },
  { value: 'IN', code3: 'IND', key: 'India', number: '356' },
  { value: 'ID', code3: 'IDN', key: 'Indonesia', number: '360' },
  { value: 'IR', code3: 'IRN', key: 'Iran (Islamic Republic of)', number: '364' },
  { value: 'IQ', code3: 'IRQ', key: 'Iraq', number: '368' },
  { value: 'IE', code3: 'IRL', key: 'Ireland', number: '372' },
  { value: 'IM', code3: 'IMN', key: 'Isle of Man', number: '833' },
  { value: 'IL', code3: 'ISR', key: 'Israel', number: '376' },
  { value: 'IT', code3: 'ITA', key: 'Italy', number: '380' },
  { value: 'JM', code3: 'JAM', key: 'Jamaica', number: '388' },
  { value: 'JP', code3: 'JPN', key: 'Japan', number: '392' },
  { value: 'JE', code3: 'JEY', key: 'Jersey', number: '832' },
  { value: 'JO', code3: 'JOR', key: 'Jordan', number: '400' },
  { value: 'KZ', code3: 'KAZ', key: 'Kazakhstan', number: '398' },
  { value: 'KE', code3: 'KEN', key: 'Kenya', number: '404' },
  { value: 'KI', code3: 'KIR', key: 'Kiribati', number: '296' },
  { value: 'KP', code3: 'PRK', key: "Korea (the Democratic People's Republic of)", number: '408' },
  { value: 'KR', code3: 'KOR', key: 'Korea (the Republic of)', number: '410' },
  { value: 'KW', code3: 'KWT', key: 'Kuwait', number: '414' },
  { value: 'KG', code3: 'KGZ', key: 'Kyrgyzstan', number: '417' },
  { value: 'LA', code3: 'LAO', key: "Lao People's Democratic Republic (the)", number: '418' },
  { value: 'LV', code3: 'LVA', key: 'Latvia', number: '428' },
  { value: 'LB', code3: 'LBN', key: 'Lebanon', number: '422' },
  { value: 'LS', code3: 'LSO', key: 'Lesotho', number: '426' },
  { value: 'LR', code3: 'LBR', key: 'Liberia', number: '430' },
  { value: 'LY', code3: 'LBY', key: 'Libya', number: '434' },
  { value: 'LI', code3: 'LIE', key: 'Liechtenstein', number: '438' },
  { value: 'LT', code3: 'LTU', key: 'Lithuania', number: '440' },
  { value: 'LU', code3: 'LUX', key: 'Luxembourg', number: '442' },
  { value: 'MO', code3: 'MAC', key: 'Macao', number: '446' },
  { value: 'MG', code3: 'MDG', key: 'Madagascar', number: '450' },
  { value: 'MW', code3: 'MWI', key: 'Malawi', number: '454' },
  { value: 'MY', code3: 'MYS', key: 'Malaysia', number: '458' },
  { value: 'MV', code3: 'MDV', key: 'Maldives', number: '462' },
  { value: 'ML', code3: 'MLI', key: 'Mali', number: '466' },
  { value: 'MT', code3: 'MLT', key: 'Malta', number: '470' },
  { value: 'MH', code3: 'MHL', key: 'Marshall Islands (the)', number: '584' },
  { value: 'MQ', code3: 'MTQ', key: 'Martinique', number: '474' },
  { value: 'MR', code3: 'MRT', key: 'Mauritania', number: '478' },
  { value: 'MU', code3: 'MUS', key: 'Mauritius', number: '480' },
  { value: 'YT', code3: 'MYT', key: 'Mayotte', number: '175' },
  { value: 'MX', code3: 'MEX', key: 'Mexico', number: '484' },
  { value: 'FM', code3: 'FSM', key: 'Micronesia (Federated States of)', number: '583' },
  { value: 'MD', code3: 'MDA', key: 'Moldova (the Republic of)', number: '498' },
  { value: 'MC', code3: 'MCO', key: 'Monaco', number: '492' },
  { value: 'MN', code3: 'MNG', key: 'Mongolia', number: '496' },
  { value: 'ME', code3: 'MNE', key: 'Montenegro', number: '499' },
  { value: 'MS', code3: 'MSR', key: 'Montserrat', number: '500' },
  { value: 'MA', code3: 'MAR', key: 'Morocco', number: '504' },
  { value: 'MZ', code3: 'MOZ', key: 'Mozambique', number: '508' },
  { value: 'MM', code3: 'MMR', key: 'Myanmar', number: '104' },
  { value: 'NA', code3: 'NAM', key: 'Namibia', number: '516' },
  { value: 'NR', code3: 'NRU', key: 'Nauru', number: '520' },
  { value: 'NP', code3: 'NPL', key: 'Nepal', number: '524' },
  { value: 'NL', code3: 'NLD', key: 'Netherlands (the)', number: '528' },
  { value: 'NC', code3: 'NCL', key: 'New Caledonia', number: '540' },
  { value: 'NZ', code3: 'NZL', key: 'New Zealand', number: '554' },
  { value: 'NI', code3: 'NIC', key: 'Nicaragua', number: '558' },
  { value: 'NE', code3: 'NER', key: 'Niger (the)', number: '562' },
  { value: 'NG', code3: 'NGA', key: 'Nigeria', number: '566' },
  { value: 'NU', code3: 'NIU', key: 'Niue', number: '570' },
  { value: 'NF', code3: 'NFK', key: 'Norfolk Island', number: '574' },
  { value: 'MP', code3: 'MNP', key: 'Northern Mariana Islands (the)', number: '580' },
  { value: 'NO', code3: 'NOR', key: 'Norway', number: '578' },
  { value: 'OM', code3: 'OMN', key: 'Oman', number: '512' },
  { value: 'PK', code3: 'PAK', key: 'Pakistan', number: '586' },
  { value: 'PW', code3: 'PLW', key: 'Palau', number: '585' },
  { value: 'PS', code3: 'PSE', key: 'Palestine, State of', number: '275' },
  { value: 'PA', code3: 'PAN', key: 'Panama', number: '591' },
  { value: 'PG', code3: 'PNG', key: 'Papua New Guinea', number: '598' },
  { value: 'PY', code3: 'PRY', key: 'Paraguay', number: '600' },
  { value: 'PE', code3: 'PER', key: 'Peru', number: '604' },
  { value: 'PH', code3: 'PHL', key: 'Philippines (the)', number: '608' },
  { value: 'PN', code3: 'PCN', key: 'Pitcairn', number: '612' },
  { value: 'PL', code3: 'POL', key: 'Poland', number: '616' },
  { value: 'PT', code3: 'PRT', key: 'Portugal', number: '620' },
  { value: 'PR', code3: 'PRI', key: 'Puerto Rico', number: '630' },
  { value: 'QA', code3: 'QAT', key: 'Qatar', number: '634' },
  { value: 'MK', code3: 'MKD', key: 'Republic of North Macedonia', number: '807' },
  { value: 'RO', code3: 'ROU', key: 'Romania', number: '642' },
  { value: 'RU', code3: 'RUS', key: 'Russian Federation (the)', number: '643' },
  { value: 'RW', code3: 'RWA', key: 'Rwanda', number: '646' },
  { value: 'RE', code3: 'REU', key: 'Réunion', number: '638' },
  { value: 'BL', code3: 'BLM', key: 'Saint Barthélemy', number: '652' },
  { value: 'SH', code3: 'SHN', key: 'Saint Helena, Ascension and Tristan da Cunha', number: '654' },
  { value: 'KN', code3: 'KNA', key: 'Saint Kitts and Nevis', number: '659' },
  { value: 'LC', code3: 'LCA', key: 'Saint Lucia', number: '662' },
  { value: 'MF', code3: 'MAF', key: 'Saint Martin (French part)', number: '663' },
  { value: 'PM', code3: 'SPM', key: 'Saint Pierre and Miquelon', number: '666' },
  { value: 'VC', code3: 'VCT', key: 'Saint Vincent and the Grenadines', number: '670' },
  { value: 'WS', code3: 'WSM', key: 'Samoa', number: '882' },
  { value: 'SM', code3: 'SMR', key: 'San Marino', number: '674' },
  { value: 'ST', code3: 'STP', key: 'Sao Tome and Principe', number: '678' },
  { value: 'SA', code3: 'SAU', key: 'Saudi Arabia', number: '682' },
  { value: 'SN', code3: 'SEN', key: 'Senegal', number: '686' },
  { value: 'RS', code3: 'SRB', key: 'Serbia', number: '688' },
  { value: 'SC', code3: 'SYC', key: 'Seychelles', number: '690' },
  { value: 'SL', code3: 'SLE', key: 'Sierra Leone', number: '694' },
  { value: 'SG', code3: 'SGP', key: 'Singapore', number: '702' },
  { value: 'SX', code3: 'SXM', key: 'Sint Maarten (Dutch part)', number: '534' },
  { value: 'SK', code3: 'SVK', key: 'Slovakia', number: '703' },
  { value: 'SI', code3: 'SVN', key: 'Slovenia', number: '705' },
  { value: 'SB', code3: 'SLB', key: 'Solomon Islands', number: '090' },
  { value: 'SO', code3: 'SOM', key: 'Somalia', number: '706' },
  { value: 'ZA', code3: 'ZAF', key: 'South Africa', number: '710' },
  { value: 'GS', code3: 'SGS', key: 'South Georgia and the South Sandwich Islands', number: '239' },
  { value: 'SS', code3: 'SSD', key: 'South Sudan', number: '728' },
  { value: 'ES', code3: 'ESP', key: 'Spain', number: '724' },
  { value: 'LK', code3: 'LKA', key: 'Sri Lanka', number: '144' },
  { value: 'SD', code3: 'SDN', key: 'Sudan (the)', number: '729' },
  { value: 'SR', code3: 'SUR', key: 'Suriname', number: '740' },
  { value: 'SJ', code3: 'SJM', key: 'Svalbard and Jan Mayen', number: '744' },
  { value: 'SE', code3: 'SWE', key: 'Sweden', number: '752' },
  { value: 'CH', code3: 'CHE', key: 'Switzerland', number: '756' },
  { value: 'SY', code3: 'SYR', key: 'Syrian Arab Republic', number: '760' },
  { value: 'TW', code3: 'TWN', key: 'Taiwan', number: '158' },
  { value: 'TJ', code3: 'TJK', key: 'Tajikistan', number: '762' },
  { value: 'TZ', code3: 'TZA', key: 'Tanzania, United Republic of', number: '834' },
  { value: 'TH', code3: 'THA', key: 'Thailand', number: '764' },
  { value: 'TL', code3: 'TLS', key: 'Timor-Leste', number: '626' },
  { value: 'TG', code3: 'TGO', key: 'Togo', number: '768' },
  { value: 'TK', code3: 'TKL', key: 'Tokelau', number: '772' },
  { value: 'TO', code3: 'TON', key: 'Tonga', number: '776' },
  { value: 'TT', code3: 'TTO', key: 'Trinidad and Tobago', number: '780' },
  { value: 'TN', code3: 'TUN', key: 'Tunisia', number: '788' },
  { value: 'TR', code3: 'TUR', key: 'Turkey', number: '792' },
  { value: 'TM', code3: 'TKM', key: 'Turkmenistan', number: '795' },
  { value: 'TC', code3: 'TCA', key: 'Turks and Caicos Islands (the)', number: '796' },
  { value: 'TV', code3: 'TUV', key: 'Tuvalu', number: '798' },
  { value: 'UG', code3: 'UGA', key: 'Uganda', number: '800' },
  { value: 'UA', code3: 'UKR', key: 'Ukraine', number: '804' },
  { value: 'AE', code3: 'ARE', key: 'United Arab Emirates (the)', number: '784' },
  {
    value: 'GB',
    code3: 'GBR',
    key: 'United Kingdom of Great Britain and Northern Ireland (the)',
    number: '826',
  },
  { value: 'UM', code3: 'UMI', key: 'United States Minor Outlying Islands (the)', number: '581' },
  { value: 'US', code3: 'USA', key: 'United States of America (the)', number: '840' },
  { value: 'UY', code3: 'URY', key: 'Uruguay', number: '858' },
  { value: 'UZ', code3: 'UZB', key: 'Uzbekistan', number: '860' },
  { value: 'VU', code3: 'VUT', key: 'Vanuatu', number: '548' },
  { value: 'VE', code3: 'VEN', key: 'Venezuela (Bolivarian Republic of)', number: '862' },
  { value: 'VN', code3: 'VNM', key: 'Viet Nam', number: '704' },
  { value: 'VG', code3: 'VGB', key: 'Virgin Islands (British)', number: '092' },
  { value: 'VI', code3: 'VIR', key: 'Virgin Islands (U.S.)', number: '850' },
  { value: 'WF', code3: 'WLF', key: 'Wallis and Futuna', number: '876' },
  { value: 'EH', code3: 'ESH', key: 'Western Sahara', number: '732' },
  { value: 'YE', code3: 'YEM', key: 'Yemen', number: '887' },
  { value: 'ZM', code3: 'ZMB', key: 'Zambia', number: '894' },
  { value: 'ZW', code3: 'ZWE', key: 'Zimbabwe', number: '716' },
  { value: 'AX', code3: 'ALA', key: 'Åland Islands', number: '248' },
];

export enum PhoneCountryCode {
  Abkhazia = '+7 840',
  Afghanistan = '+93',
  Albania = '+355',
  Algeria = '+213',
  AmericanSamoa = '+1 684',
  Andorra = '+376',
  Angola = '+244',
  Anguilla = '+1 264',
  AntiguaAndBarbuda = '+1 268',
  Argentina = '+54',
  Armenia = '+374',
  Aruba = '+297',
  Ascension = '+247',
  Australia = '+61',
  AustralianExternalTerritories = '+672',
  Austria = '+43',
  Azerbaijan = '+994',
  Bahamas = '+1 242',
  Bahrain = '+973',
  Bangladesh = '+880',
  Barbados = '+1 246',
  Barbuda = '+1 268',
  Belarus = '+375',
  Belgium = '+32',
  Belize = '+501',
  Benin = '+229',
  Bermuda = '+1 441',
  Bhutan = '+975',
  Bolivia = '+591',
  BosniaAndHerzegovina = '+387',
  Botswana = '+267',
  Brazil = '+55',
  BritishIndianOceanTerritory = '+246',
  BruneiDarussalam = '+673',
  Bulgaria = '+359',
  BurkinaFaso = '+226',
  Burundi = '+257',
  Cambodia = '+855',
  Cameroon = '+237',
  Canada = '+1',
  CapeVerde = '+238',
  CaymanIslands = '+ 345',
  CentralAfricanRepublic = '+236',
  Chad = '+235',
  Chile = '+56',
  China = '+86',
  ChristmasIsland = '+61',
  CocosKeelingIslands = '+61',
  Colombia = '+57',
  Comoros = '+269',
  Congo = '+242',
  CongoDemocraticRepublic = '+243',
  CookIslands = '+682',
  CostaRica = '+506',
  Croatia = '+385',
  Cuba = '+53',
  Curacao = '+599',
  Cyprus = '+357',
  CzechRepublic = '+420',
  Denmark = '+45',
  DiegoGarcia = '+246',
  Djibouti = '+253',
  Dominica = '+1 767',
  DominicanRepublic = '+1 809',
  EastTimor = '+670',
  EasterIsland = '+56',
  Ecuador = '+593',
  Egypt = '+20',
  ElSalvador = '+503',
  EquatorialGuinea = '+240',
  Eritrea = '+291',
  Estonia = '+372',
  Ethiopia = '+251',
  FalklandIslands = '+500',
  FaroeIslands = '+298',
  Fiji = '+679',
  Finland = '+358',
  France = '+33',
  FrenchAntilles = '+596',
  FrenchGuiana = '+594',
  FrenchPolynesia = '+689',
  Gabon = '+241',
  Gambia = '+220',
  Georgia = '+995',
  Germany = '+49',
  Ghana = '+233',
  Gibraltar = '+350',
  Greece = '+30',
  Greenland = '+299',
  Grenada = '+1 473',
  Guadeloupe = '+590',
  Guam = '+1 671',
  Guatemala = '+502',
  Guinea = '+224',
  GuineaBissau = '+245',
  Guyana = '+595',
  Haiti = '+509',
  Honduras = '+504',
  HongKong = '+852',
  Hungary = '+36',
  Iceland = '+354',
  India = '+91',
  Indonesia = '+62',
  Iran = '+98',
  Iraq = '+964',
  Ireland = '+353',
  Israel = '+972',
  Italy = '+39',
  IvoryCoast = '+225',
  Jamaica = '+1 876',
  Japan = '+81',
  Jordan = '+962',
  Kazakhstan = '+7 7',
  Kenya = '+254',
  Kiribati = '+686',
  Kuwait = '+965',
  Kyrgyzstan = '+996',
  Laos = '+856',
  Latvia = '+371',
  Lebanon = '+961',
  Lesotho = '+266',
  Liberia = '+231',
  Libya = '+218',
  Liechtenstein = '+423',
  Lithuania = '+370',
  Luxembourg = '+352',
  Macau = '+853',
  Macedonia = '+389',
  Madagascar = '+261',
  Malawi = '+265',
  Malaysia = '+60',
  Maldives = '+960',
  Mali = '+223',
  Malta = '+356',
  MarshallIslands = '+692',
  Martinique = '+596',
  Mauritania = '+222',
  Mauritius = '+230',
  Mayotte = '+262',
  Mexico = '+52',
  Micronesia = '+691',
  MidwayIsland = '+1 808',
  Moldova = '+373',
  Monaco = '+377',
  Mongolia = '+976',
  Montenegro = '+382',
  Montserrat = '+1664',
  Morocco = '+212',
  Myanmar = '+95',
  Namibia = '+264',
  Nauru = '+674',
  Nepal = '+977',
  Netherlands = '+31',
  NetherlandsAntilles = '+599',
  Nevis = '+1 869',
  NewCaledonia = '+687',
  NewZealand = '+64',
  Nicaragua = '+505',
  Niger = '+227',
  Nigeria = '+234',
  Niue = '+683',
  NorfolkIsland = '+672',
  NorthKorea = '+850',
  NorthernMarianaIslands = '+1 670',
  Norway = '+47',
  Oman = '+968',
  Pakistan = '+92',
  Palau = '+680',
  PalestinianTerritory = '+970',
  Panama = '+507',
  PapuaNewGuinea = '+675',
  Paraguay = '+595',
  Peru = '+51',
  Philippines = '+63',
  Poland = '+48',
  Portugal = '+351',
  PuertoRico = '+1 787',
  Qatar = '+974',
  Reunion = '+262',
  Romania = '+40',
  Russia = '+7',
  Rwanda = '+250',
  Samoa = '+685',
  SanMarino = '+378',
  SaudiArabia = '+966',
  Senegal = '+221',
  Serbia = '+381',
  Seychelles = '+248',
  SierraLeone = '+232',
  Singapore = '+65',
  Slovakia = '+421',
  Slovenia = '+386',
  SolomonIslands = '+677',
  SouthAfrica = '+27',
  SouthGeorgiaAndSandwichIsl = '+500',
  SouthKorea = '+82',
  Spain = '+34',
  SriLanka = '+94',
  Sudan = '+249',
  Suriname = '+597',
  Swaziland = '+268',
  Sweden = '+46',
  Switzerland = '+41',
  Syria = '+963',
  Taiwan = '+886',
  Tajikistan = '+992',
  Tanzania = '+255',
  Thailand = '+66',
  TimorLeste = '+670',
  Togo = '+228',
  Tokelau = '+690',
  Tonga = '+676',
  TrinidadAndTobago = '+1 868',
  Tunisia = '+216',
  Turkey = '+90',
  Turkmenistan = '+993',
  TurksAndCaicosIslands = '+1 649',
  Tuvalu = '+688',
  Uganda = '+256',
  Ukraine = '+380',
  UnitedArabEmirates = '+971',
  UnitedKingdom = '+44',
  UnitedStates = '+1',
  Uruguay = '+598',
  Uzbekistan = '+998',
  Vanuatu = '+678',
  Venezuela = '+58',
  Vietnam = '+84',
  VirginIslandsBritish = '+1 284',
  VirginIslandsUS = '+1 340',
  WakeIsland = '+1 808',
  WallisAndFutuna = '+681',
  Yemen = '+967',
  Zambia = '+260',
  Zanzibar = '+255',
  Zimbabwe = '+263',
}
