import React, { useEffect, useState } from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
import Auth, * as AuthAll from 'components/Auth';
import Private from './PrivateRoute';
import Public from './PublicRoute';
import MapAndCards from 'components/MainLayout/MapAndCards';
import useUser from 'redux/user/use-user';
import useEffectAsync from 'hooks/useEffectAsync';
import { getLoggedInUser } from 'api/auth';
import Chat from 'components/Chat';
import UserProfile from 'components/MainLayout/UserProfile';
import Transactions from 'components/MainLayout/Transactions';
import PostedJobs from 'components/MainLayout/PostedJobs';
import Payments from 'components/MainLayout/Payments';
import ProtectedRoute from './ProtectedRoute';
import AgreeAndContinue from '../components/MainLayout/Modals/AgreeAndContinue ';
import VodeoPage from 'components/MainLayout/VideoPage';

const Routes: React.FC = (): JSX.Element => {
  const token = localStorage.getItem('token');
  const agreeConditions = localStorage.getItem('agreeConditions');
  const { keepUser, user } = useUser();
  const [drawApp, setDrawApp] = useState(false);
  const [agreeAndContinue, setAgreeAndContinue] = useState<boolean>(false);

  const agree = () => {
    localStorage.setItem('agreeConditions', 'accepted');
    setAgreeAndContinue(false);
  };

  useEffectAsync(async () => {
    if (agreeConditions) {
      setAgreeAndContinue(false);
    } else {
      setAgreeAndContinue(true);
    }

    if (token) {
      const user = await getLoggedInUser();
      keepUser(user);
    }
    setDrawApp(true);
  }, [token]);

  const reload = () => window.location.reload();

  return (
    <>
      {drawApp && (
        <ReactRoutes>
          <Route path="/auth" element={<Public component={Auth} restricted />}>
            <Route path="" element={<AuthAll.FirstView />} />
            <Route path="login" element={<AuthAll.Login />} />
            <Route path="register" element={<AuthAll.Register />} />
            <Route path="email-verify" element={<AuthAll.VerifyEmail />} />
            <Route path="forgot-password" element={<AuthAll.ForgotPassword />} />
            <Route path="code-verification" element={<AuthAll.CodeVerification />} />
            <Route path="set-new-pass" element={<AuthAll.SetNewPassword />} />
            <Route path="pass-changed" element={<AuthAll.PassChanged />} />
            <Route path="*" element={<Navigate to="" />} />
          </Route>

          <Route path="/explore/*" element={<AuthAll.MainLayout />}>
            <Route path="*" element={<MapAndCards />} />
          </Route>
          <Route path="/chat" element={<ProtectedRoute component={AuthAll.MainLayout} />}>
            <Route path="" element={<Chat />} />
          </Route>

          <Route path="/profile" element={<ProtectedRoute component={AuthAll.MainLayout} />}>
            <Route path="" element={<UserProfile />} />
          </Route>
          <Route path="/transactions" element={<ProtectedRoute component={AuthAll.MainLayout} />}>
            <Route path="" element={<Transactions />} />
          </Route>
          <Route path="/posted-jobs" element={<ProtectedRoute component={AuthAll.MainLayout} />}>
            <Route path="" element={<PostedJobs />} />
          </Route>
          <Route path="/payments" element={<ProtectedRoute component={AuthAll.MainLayout} />}>
            <Route path="" element={<Payments />} />
          </Route>

          <Route path="email-verified" element={<AuthAll.EmailVerified />} />
          <Route path="paypal" element={<AuthAll.PayPalConnection />} />
          <Route path="payment-method-connection" element={<AuthAll.PymentMethodConnection />} />

          <Route
            path="/become-a-master"
            element={<Private component={AuthAll.MasterFlowConatiner} />}
          >
            <Route path="steps" element={<AuthAll.Steps />} />
            <Route path="account-type" element={<AuthAll.AccountType />} />
            <Route path="company" element={<AuthAll.Company />} />

            <Route path="introduction" element={<AuthAll.Introduction />} />
            <Route path="services" element={<AuthAll.Services />} />
            <Route path="prices" element={<AuthAll.Price />} />
            <Route path="scalability" element={<AuthAll.Areas />} />
            <Route path="address" element={<AuthAll.Address />} />
            <Route path="radius" element={<AuthAll.Radius />} />
            <Route path="video" element={<AuthAll.VideoUpload />} />
            <Route path="documents" element={<AuthAll.DocumentsUpload />} />
            <Route path="review" element={<AuthAll.Review />} />
          </Route>
          <Route path="/video/:id" element={<VodeoPage />} />

          <Route path="" element={<Navigate to="/auth" />} />
          <Route path="*" element={<>Not Found</>} />
        </ReactRoutes>
      )}

      <AgreeAndContinue
        visible={agreeAndContinue}
        closeModal={() => setAgreeAndContinue(false)}
        type="firstTime"
        applayAction={agree}
      />
    </>
  );
};

export default Routes;
