import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

type ITab = {
  [key: string]: {
    tab: JSX.Element | string;
    content?: JSX.Element;
  };
};
interface ITabProps {
  data: ITab;
  bottomBorder?: boolean;
  classes?: string;
  activeKey?: string;
  centered?:boolean
  handleTabChange?: (tab: string) => void;
}

const Tab = ({
  data,
  bottomBorder = true,
  classes,
  activeKey = '',
  centered= false,
  handleTabChange
}: ITabProps) => {
  return (
    <Tabs
      centered={centered}
      onChange={handleTabChange}
      className={classNames(styles.tab, 'tab text-light-gray', classes, {
        bottomBorder: bottomBorder
      })}
      activeKey={activeKey}
    >
      {Object.keys(data).map(key => (
        <TabPane tab={data[key].tab} key={key}>
          {data[key].content}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default Tab;
