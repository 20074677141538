import { DependencyList, useEffect, useRef } from 'react';

const useDidMountEffect = (calback: () => void, deps: DependencyList | undefined): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) calback();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
