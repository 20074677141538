import { IUserReview, UserReviewFilterRequest } from "common";
import ReviewItem from "components/ReviewItem";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import { getUserReviews } from "redux/user/actions";
import { userReviews } from "redux/user/selectors";
import classNames from "classnames";
import styles from "./index.module.scss";


type ReviewsType ={
  userId:string;
}

const  Reviews = ({userId}:ReviewsType) =>{


  const filterInit:UserReviewFilterRequest ={
    page:1,
    userId:''
  }

const dispatch = useDispatch();
const virtuoso = useRef<any>(null);
const [filter,setFilter] = useState<UserReviewFilterRequest>(filterInit)
const reviews:Array<IUserReview> = useSelector(userReviews);

useEffect(()=>{
  if(userId){
    dispatch(getUserReviews({userId: '62dbf5de7382159e68449f4e',jsonData:filter}))
  }
},[userId])



const loadMoreReviews = ()=>{

}



return(
  <div className="">
   <Virtuoso  className={classNames(styles.virtuosoStyle, 'overflow-x-hidden')}
                      ref={virtuoso}
                      endReached={loadMoreReviews}
                      data={reviews}
                      overscan={200}
                      style={{
                        width: "100%",height: "100vh",
                      }}
                      computeItemKey={(index)=> index}

                      itemContent={(index, item :IUserReview)=> {
                          const {ratingStart,leftBy,feedback} = item;

                        return(
                            <ReviewItem date={item.createdAt} feedback={feedback} user={leftBy} rating={ratingStart}/>
                        )
                      }} />
  </div>
)

}

export default Reviews
