import classNames from 'classnames';

interface ICustomTextAreaProps {
  classes?: string;
  placeholder?: string;
  onChangeValue: (value: string) => void;
  value: string | undefined;
}

const CustomTextArea = ({ classes, placeholder, onChangeValue, value }: ICustomTextAreaProps) => {
  return (
    <textarea
      placeholder={placeholder}
      onChange={e => onChangeValue(e.target.value)}
      value={value}
      className={classNames(
        `rounded-[16px] pl-4 pt-[10px] pr-4 h-[150px] border-solid border-[1px] border-[#BBBCBC] w-full resize-none focus-visible:outline-none input-focused`,
        classes
      )}
    />
  );
};

export default CustomTextArea;
