import {
  UserRegistrationRequest,
  LoginRequest,
  LoginResponse,
  UserItem,
  ConfirmEmailRequest,
  ResendConfirmEmailRequest,
  RequestPasswordResetCode,
  ResetPasswordRequest,
  CheckResetPasswordCodeRequest
} from 'common';

import api from './index';
import { getDeviceId } from 'helpers';

export const loginUser = async(body: LoginRequest): Promise<LoginResponse> => {

    const data :any =  await   api.post('/auth/login', body);

    return data ;
};

export const logoutUser = (): Promise<string> => {
  return api.post('/auth/logout');
};

export const registerUser = (body: UserRegistrationRequest): Promise<UserItem> => {
  return api.post('/auth/register', body);
};

export const signInWith = (
  sociaNetwork: string,
  accessToken: string,
  user?: any
): Promise<LoginResponse> => {
  return api.post(`/auth/login/${sociaNetwork}`, {
    socialToken: accessToken,
    user,
    deviceId: getDeviceId()
  });
};

export const confirmEmail = (body: ConfirmEmailRequest): Promise<LoginResponse> => {
  return api.put('/auth/confirm-email', body);
};

export const resendConfirmEmailCode = (body: ResendConfirmEmailRequest): Promise<UserItem> => {
  return api.post('/auth/resend-confirm-email', body);
};

export const getPasswordResetCode = (body: RequestPasswordResetCode): Promise<string> => {
  return api.put('/auth/request-password-reset', body);
};

export const checkResetPassword = (body: CheckResetPasswordCodeRequest): Promise<string> => {
  return api.put('/auth/check-reset-password', body);
};

export const resetPassword = (body: ResetPasswordRequest): Promise<string> => {
  return api.put('/auth/reset-password', body);
};

export const getLoggedInUser = (): Promise<string[]> => {
  return api.get('/auth/me');
};
