import React from 'react';
import { Col, Typography } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import styles from '../index.module.scss';

interface IMessageItemWrapper {
  isMyMessage: boolean;
  children?: JSX.Element | JSX.Element[];
  time: string;
}

const { Text } = Typography;

const MessageItemWrapper = ({ isMyMessage, children, time }: IMessageItemWrapper) => {
  return (
    <Col
      className={classNames(
        styles.messageItemWrapperContainer,
        { [styles.myMessage]: isMyMessage },
        'px-3 pt-3 pb-2 rounded-[8px] max-w-[539px]'
      )}
    >
      {children}
      <Col className={styles.timeContainer}>
        <Text italic className={classNames('text-font-12 text-[#A5A5A5]')}>
          {moment(time).format('LT')}
        </Text>
      </Col>
    </Col>
  );
};

export default MessageItemWrapper;
