import { ChatMessageStatus, ChatMessageType, SystemMessageState } from 'common';
import classNames from 'classnames';
import { Col, Row, Space, Typography } from 'antd';
import DButton from 'components/DButton';
import { useNavigate } from 'react-router-dom';
import { ModifiedChannel } from 'redux/chat/types';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../index.module.scss';
import moment from 'moment';
import { getSystemMessage } from './SystemMessageConfiguration';
import useDashboard from 'redux/dashboard/use-dashboard';
import { stringToHTML } from '../../../helpers';
import XIcon from 'assets/icons/x-icon.svg';

type SystemMessageType = {
  title?: string;
  text?: string;
  buttons?: ButtonType[] | any[];
};

type ButtonType = {
  text: string;
  fn?: () => void;
  type?:
    | 'only-text'
    | 'default'
    | 'secondary'
    | 'white-bordered'
    | 'circle'
    | 'light-green'
    | 'white-shadowed'
    | 'white';
  disabled?: boolean;
};

type SystemMessagesType = {
  [key: string]: {
    forMe?: SystemMessageType;
    forOther?: SystemMessageType;
  };
};

const { Text } = Typography;

const SystemMessage = ({
  id,
  isMe,
  type,
  state,
  selectedChannel,
  groupId,
  metadata,
  time,
  setJobOfferSectionOpen
}: {
  id: string;
  isMe: boolean;
  type: ChatMessageType;
  state?: SystemMessageState;
  selectedChannel: ModifiedChannel | null;
  groupId: string;
  metadata?: any;
  time: string;
  setJobOfferSectionOpen: () => void;
}) => {
  const navigate = useNavigate();
  const [loadingBtnName, setLoadingBtnName] = useState('');
  const { filters, changeFilters } = useDashboard();

  const dispatch = useDispatch();

  const openCompleteReportModal = (action: string) => {
    dispatch({
      type: action,
      payload: {
        id: selectedChannel?.jobContractId,
        messageId: id,
        groupId: groupId,
        show: true
      }
    });
  };

  const setFilter = useCallback((obj: any) => {
    changeFilters({ ...filters, ...obj });
  }, []);

  const listSettings = {
    id,
    type,
    state,
    currentChannel: selectedChannel,
    groupId,
    metadata,
    time,
    setJobOfferSectionOpen,
    openCompleteReportModal,
    loadingBtnName,
    setLoadingBtnName,
    navigate,
    dispatch,
    setFilter
  };

  const message = getSystemMessage(listSettings);
  const messageKey = isMe ? 'forMe' : 'forOther';
  const messageObject = message?.[messageKey];
  return (
    <>
      {messageObject && (
        <div className={classNames('w-full px-16 py-4 ', isMe ? 'bg-[#F3F4FD]' : 'bg-[#FDF6F0]')}>
          <Col>
            <Row>
              <div className="rounded-full flex items-center justify-center w-8 h-8 border-[1px] border-[#DBCCBF] mr-4">
                <i className="icon-39 text-font-26" />
              </div>
              <Text className="font-bold text-font-14">{messageObject?.title} </Text>
            </Row>
          </Col>
          <Col className="mr-4 pl-[50px]">
            <Space size={8} direction="vertical">
              <Text>{messageObject?.text}</Text>
              {!!messageObject?.buttons?.length && (
                <Space size={16}>
                  {messageObject?.buttons.map((button: any, index) => {
                    return (
                      <DButton
                        key={index}
                        disabled={button.disabled}
                        className={`h-8 min-w-[162px] !p-0 !px-2 text-white font-bold text-font-13 ${button?.classNames}`}
                        text={button.text}
                        onClick={button.fn}
                        loading={button.loading}
                        type={button.type}
                        icon={button?.iconType}
                        loadingClassName="text-font-20"
                      />
                    );
                  })}
                </Space>
              )}
            </Space>
          </Col>

          <Row className={classNames('justify-end', styles.timeContainer)}>
            <Text italic className={classNames('text-font-12 text-[#A5A5A5]')}>
              {moment(time).format('LT')}
            </Text>
          </Row>
        </div>
      )}
    </>
  );
};

export default memo(SystemMessage);
