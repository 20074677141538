import { useEffect, useState } from 'react';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import classNames from 'classnames';
import RadioMulti from 'components/RadioMulti';
import { getServices } from 'api/user';
import useUser from 'redux/user/use-user';
import { useNavigate } from 'react-router-dom';
import DotsAndButton from 'components/DotsAndButton';
import { Spin } from 'antd';
import { SERVICES } from '../../../constants';

type Service = {
  profession: { label: string; value: string };
  value: boolean;
  id: number;
};

const Services = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const [services, setServices] = useState<Service[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (masterFlowData?.services) {
        setServices(masterFlowData?.services);
      } else {
        const srvcs = await getServices();
        const mappedSrvcs = srvcs.map((x, i) => ({
          profession: { label: SERVICES[x], value: x },
          value: false,
          id: i
        }));
        setServices(mappedSrvcs);
      }
    })();
  }, []);

  const onChange = (idx: number) => {
    const checkedItems = services.map((item, i) => {
      if (i === idx) {
        return {
          ...item,
          value: !item.value
        };
      }
      return item;
    });
    setServices(checkedItems);
  };

  const next = () => {
    keepBecomeMasterData({
      ...masterFlowData,
      ...(services.find(x => x.value) ? { services } : { services: null })
    });
    navigate('/become-a-master/prices');
  };

  return (
    <div className={styles.minH540}>
      <div className="flex flex-col items-center w-[474px]">
        <p className={classNames(['text-doers-black', styles.services])}>Services</p>
        <p className={classNames(styles.txt)}>
          Select your service to tell the world what you do. You can select multiple services.
        </p>
        <div className={styles.cardsCont}>
          {services?.length ? (
            <RadioMulti services={services} onChange={onChange} />
          ) : (
            <Spin size="large" />
          )}
        </div>
        <DotsAndButton>
          <DButton text="Next, Price" className="w-[278px] mt-4" type="default" onClick={next} />
        </DotsAndButton>
      </div>
    </div>
  );
};

export default Services;
