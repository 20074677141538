import DButton from 'components/DButton';
import googleSvg from 'assets/icons/gmail.svg';

import { useGoogleLogin, CodeResponse, TokenResponse } from '@react-oauth/google';

const GoogleLogin = ({ responseGoogle }: { responseGoogle: (access_token: string) => void }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => responseGoogle(access_token),
    onError: err => console.log(err)
  });

  return <DButton size="md" type="circle" icon={googleSvg} onClick={googleLogin} />;
};

export default GoogleLogin;
