import styles from './index.module.scss';
import classNames from 'classnames';

export default function TextField({
  text,
  isChecked,
  onChange,
  side ='left',
  customClasses=''
}: {
  text: any;
  isChecked: any;
  onChange: any;
  side?:string;
  customClasses?:string
}): JSX.Element {
  return (
    <div className={classNames(styles.textField,customClasses)} >
      {side === 'left' &&        <span className={isChecked ? styles.checkedText : styles.text}>{text}</span>}
      <div className={styles.checkboxCircle} onClick={onChange}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked}
          onChange={() => {}}
        />

      </div>
      {side === 'right' &&        <span className={isChecked ? styles.checkedText : styles.text}>{text}</span>}

    </div>
  );
}
