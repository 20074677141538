import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Col, Row } from 'antd';

const PymentMethodConnection: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const result = queryParams.get('app');
  const [timer, setTimer] = useState<number>(5);
  const [resultText, setResultText] = useState<{
    title: string;
    text: string;
  }>();

  useEffect(() => {
    let timeoutID = null;

    setResultText({
      title: 'Payment Method Connected Successfully!',
      text: 'Your payment method account has been successfully connected to our platform. You can now use it for payments and receive money on this platform.'
    });
    timeoutID = setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);

    if (timer === 0) {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      if (result && result === 'mobile') {
        // window.open('doersus://oauth', '_blank');
        window.location.href = 'doersus://oauth';
      } else if (window.opener != null && !window.opener.closed) {
        parent.window.opener.postMessage('approved', process.env.REACT_APP_HOSTING);
        parent.window.close();
      }
    }
  }, [timer]);

  return (
    <div className={styles.success}>
      <Row>
        <Col span={12} offset={6}>
          <Row className="justify-center">
            <div className={styles.timerBlock}>
              <h1 className="text-center ">{timer}</h1>
            </div>
          </Row>
          <>
            <h1 className={styles.title}>{resultText?.title}</h1>
            <p className={styles.text}>{resultText?.text}</p>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default PymentMethodConnection;
