import { GoogleOAuthProvider } from '@react-oauth/google';
import { Login } from 'components/Auth';
import React from 'react';


const LoginModal = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Login isModal={true} />
    </GoogleOAuthProvider>
  );
};

export default LoginModal;
