import { PaymentFilterRequest } from "common";
import { FetchTransactionsLoadMoreRequest, FetchTransactionsRequest, TRANSACTIONS_GET_REQUEST, TRANSACTIONS_LOAD_MORE_REQUEST } from "./types";


export const fetchTransactions= (payload: PaymentFilterRequest): FetchTransactionsRequest => ({
  type: TRANSACTIONS_GET_REQUEST,
  payload
});

export const fetchTransactionsMore= (payload: PaymentFilterRequest): FetchTransactionsLoadMoreRequest => ({
  type: TRANSACTIONS_LOAD_MORE_REQUEST,
  payload
});

