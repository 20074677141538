/** @format */

import { type } from 'os';
import * as yup from 'yup';

export type BackOfficeUserActivateRequest = {
  password: string;
  token: string;
};

export const BackOfficeUserActivateRequestSchema = yup.object().shape({
  token: yup.string().required(),
  password: yup.string().required().min(8).max(32),
});

export type BackOfficeUserForgotPassStep1Request = {
  email: string;
};

export const BackOfficeUserForgotPassStep1RequestSchema = yup.object().shape({
  email: yup.string().required().email(),
});

export type BackOfficeUserForgotPassStep2Request = {
  password: string;
  token: string;
};

export const BackOfficeUserForgotPassStep2RequestSchema = yup.object().shape({
  password: yup.string().required().min(8).max(32),
  token: yup.string().required(),
});

export type BackOfficeUserForgotPasswordRequest = {
  password: string;
};

export type BackOfficeUserSetPasswordRequest = {
  hasPassword: string;
};

export const BackOfficeUserSetPasswordRequestSchema = yup.object().shape({
  hasPassword: yup.string().required().min(8).max(64),
});

export const BackOfficeUserForgotPasswordRequestSchema = yup.object().shape({
  password: yup.string().required().min(8).max(32),
});
