/** @format */

import * as yup from 'yup';
import { AdminRole, UserRole, UserStatus, UserType } from './user';

export type UserFilterByAdminRequest = {
  rateFrom?: number;
  rateTo?: number;
  priceFrom?: number;
  priceTo?: number;
  radius?: number;
  lat?: number;
  lng?: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  search?: string;
  createdAt?: number;
  from?: number;
  to?: number;
  industry?: string[];
  user?: string;
  role?: UserRole;
  status?: UserStatus;
};

export type BlockUserByAdminRequest = {
  blockReason: string;
};
export const BlockUserByAdminRequestSchema = yup.object().shape({
  blockReason: yup.string().required(),
});

export type CreateBackOfficeUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  username?: string;
  type?: UserType;
  socialId?: string;
  adminRole?: AdminRole;
};

export const CreateBackOfficeUserRequestSchema = yup.object().shape({
  firstName: yup.string().min(1).max(40).required(),
  lastName: yup.string().min(1).max(40).required(),
  email: yup.string().required().email(),
  type: yup.string().required().default('EMAIL'),
  adminRole: yup.string().required().default('SUPPORT'),
});

export type BlockRequest = {
  comment?: string;
};

export const BlockUserRequestSchema = yup.object().shape({
  comment: yup.string().optional(),
});

export const ChangeBackOfficeUserPasswordRequestSchema = yup.object().shape({
  oldPassword: yup.string().required().min(8).max(60),
  newPassword: yup.string().required().min(8).max(60),
});

export type ChangeBackOfficeUserPasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

export const UpdateBackOfficeUserProfileRequestSchema = yup.object().shape({
  firstName: yup.string().min(1).max(40).required(),
  lastName: yup.string().min(1).max(40).required(),
  username: yup.string().required(),
  address: yup.string(),
  zipCode: yup.string(),
});

export type UpdateBackOfficeUserRequest = CreateBackOfficeUserRequest;

export type UpdateBackOfficeUserProfileRequest = CreateBackOfficeUserRequest & {
  address?: string;
  zipCode?: string;
  profilePicture?: string;
};
