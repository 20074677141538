import { Col, message, Row, Typography } from 'antd';
import classNames from 'classnames';
import ChannelsList from './components/ChannelsList';
import { useRef, useEffect, useState, useCallback } from 'react';
import ChatHeader from './components/ChatHeader';
import EmptyChat from './components/EmptyChat';
import Messages from './components/Messages';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewChannel,
  addNewMessage,
  resetChat,
  setSelectedChannel,
  updateChannel,
  updateMessage
} from 'redux/chat/actions';
import MessageInput from './components/MessageInput';
import { InterlocutorUser, ModifiedChannel } from 'redux/chat/types';
import { Socket, connect } from 'socket.io-client';
import useUser from 'redux/user/use-user';
import { UserItem } from 'common';
import ChatRightSidebar from './components/ChatRightSidebar/ChatRightSidebar';
import { SET_SUGGESTION_POPOVER } from 'redux/chat/actionTypes';

const Chat = () => {
  const dispatch = useDispatch();
  const messagesWrapperRef = useRef<HTMLDivElement>(null);

  const [isJobOfferSectionOpen, setIsJobOfferSectionOpen] = useState(true);

  const { socket} = useSelector(
    (state: any) => state.general
  );

  const { user } = useUser();
  useEffect(() => {

    return() =>{
      dispatch(resetChat())
    }

  }, []);

  useEffect(() => {
    const { current } = messagesWrapperRef;
    socket?.on('private-chat-channel', ({ messages }: any) => {
      const channel: any = messages[0].payload;
      const interlocutor: InterlocutorUser =
        channel.doer._id === user._id
          ? { ...channel.master, isMaster: true }
          : { ...channel.doer, isMaster: false };

      const modifiedChannel: ModifiedChannel = {
        _id: channel._id,
        interlocutor,
        lastMessage: channel.lastMessage,
        lastMessageType:channel.lastMessageType,
        status: channel.job.status,
        title: channel.job.title,
        date: channel.updatedAt,
        img: './',
        doerId: channel.doer._id,
        doerName: channel.doer.fullName,
        jobDescription: channel.job.description,
        masterId: channel.master._id,
        masterName: channel.master.fullName,
        type: channel.type,
        jobContractId: channel.jobContract._id,
        contractId:channel.jobContract.contractId,
        metadata: channel.metadata,
        job: channel.job,
        jobContract: channel?.jobContract
      };

      if (messages[0].type === 'NEW_CHAT_CHANNEL') {
        dispatch(
          addNewChannel({
            channel:{...channel,lastMessageUserId :channel.lastMessageUserId,jobContractId: channel.jobContract._id,},
            modifiedChannel
          })
        );
      } else if (messages[0].type === 'UPDATE_CHAT_CHANNEL') {
        dispatch(
          updateChannel({
            _id: channel._id,
            lastMessage: channel.lastMessage,
            lastMessageType:channel.lastMessageType,
            lastMessageUserId:channel.lastMessageUserId,
            lastMessageMetadata:channel.lastMessageMetadata,
            state: channel.state,
            date: channel.updatedAt,
            jobContractId: channel.jobContract._id,
            ...(channel.jobContract._id && { jobContract: channel.jobContract }),
            ...(channel.jobContract.status && { status: channel.jobContract.status })
          })
        );
      }
    });

    // socket?.on('private-message-channel', ({ messages }: any) => {

    //   messages.forEach((message : any) => {
    //     if (message.type === 'NEW_CHAT_MESSAGE') {
    //       dispatch(
    //         addNewMessage({
    //           message: message.payload
    //         })
    //       );
    //     } else if (message.type === 'UPDATE_CHAT_MESSAGE') {
    //       dispatch(
    //         updateMessage({
    //           message: message.payload
    //         })
    //       );
    //     }
    //   });

    //   if (current && current.scrollHeight - current.scrollTop - current.clientHeight <= 200) {
    //     current.scrollTop = current.scrollHeight;
    //   }
    // });

    socket?.on('reconnection_attempt', () => {
      // setTimeout(() => {
      //   socket.connect();
      // }, 8000);
      console.log('reconnection_attempt');
    });

    socket?.io.on('reconnect', () => {
      console.log('reconnect');
    });

    socket?.on('connect_error', (err: any) => {
      // setTimeout(() => {
      //   socket.connect();
      // }, 8000);
      console.log(`connect_error due to ${err.message}`);
    });

    socket?.on('disconnect', (reason: any) => {
      console.log('disconnect', reason);
    });

    return () => {

      socket?.off('private-chat-channel');

      // socket?.off('private-message-channel');
      dispatch(
        setSelectedChannel({
          selectedChannel: null
        })
      );
    }
  }, [socket]);

  const [isReceivedChatEmpty, setIsReceivedChatEmpty] = useState(false);

  const setJobOfferSectionOpen = useCallback(() => {
    setIsJobOfferSectionOpen(true);
  }, []);



  useEffect(()=>{
      if(isJobOfferSectionOpen){
        dispatch({
          type: SET_SUGGESTION_POPOVER,
          payload: {visible:false,ownerIsMe : false,cleanText:true}
        });
      }
  },[isJobOfferSectionOpen])



  const middleContentWidthCalc = !isJobOfferSectionOpen ? styles.middleContentWidthCalc : '';

  return isReceivedChatEmpty ? (
    <Row className="flex h-full items-start justify-center">
      <EmptyChat />
    </Row>
  ) : (
    <Row className={classNames(styles.chatContainer)} wrap={false}>
      <Col className="max-w-[386px] w-full pt-4">
        <ChannelsList setIsEmpty={setIsReceivedChatEmpty} />
      </Col>
      <Col
        className={classNames(
          'border-x-[1px] border-[#EBEBEB] flex flex-col ',
          styles.middleContent,
          middleContentWidthCalc
        )}
      >
        <Row>
          <ChatHeader
            isJobOfferSectionOpen={isJobOfferSectionOpen}
            setIsJobOfferSectionOpen={setIsJobOfferSectionOpen}
          />
        </Row>
        <Row className="h-full">
          <Messages
            setJobOfferSectionOpen={setJobOfferSectionOpen}
            containerRef={messagesWrapperRef}
          />
        </Row>
        <MessageInput messagesContainerRef={messagesWrapperRef} />
      </Col>

        <Col className={`min-w-[360px] w-[360px] ${!isJobOfferSectionOpen ? 'hidden' : ''} `}>
          <ChatRightSidebar setIsJobOfferSectionOpen={setIsJobOfferSectionOpen} />
        </Col>

    </Row>
  );
};

export default Chat;
