import { Col, Popover, Row, Spin, Upload } from 'antd';
import classNames from 'classnames';
import styles from '../index.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { useClickOutside } from 'hooks';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { attachMediaToMessage, sendMessage } from 'api/chat';
import { useDispatch, useSelector } from 'react-redux';
import { ChatState } from 'redux/chat/types';
import { asyncForEach, doUploadFlow, filterObj } from 'helpers';
import { SendChatMessageRequest } from 'common';
import MessageDocumentCard from './MessageDocumentCard';
import MessageMediaCard from './MessageMediaCard';
import { GALLERY_MODAL_OPEN } from 'redux/modals/actionTypes';
import { SET_SUGGESTION_POPOVER } from 'redux/chat/actionTypes';

type ChatMessagesRef = HTMLDivElement;

type IMessageInputProps = {
  messagesContainerRef: React.RefObject<ChatMessagesRef>;
};

const MessageInput = ({ messagesContainerRef }: IMessageInputProps) => {
  const dispatch = useDispatch();
  const { selectedChannel, suggestionPopover } = useSelector((state: any): ChatState => state.chat);
  const emojiContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLDivElement>(null)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [files, setFiles] = useState<UploadFile[]>([]);
  let keyMap = useRef<{ [index: string]: boolean }>({});


  useClickOutside(emojiContainerRef, () => {
    setEmojiVisible(false);
  });

  const onOpenEmojiClick = () => {
    setEmojiVisible(!emojiVisible);
  };

  // TODOFRONT: add support for other types of files and add to constants
  const MEDIA_TYPES: any = {
    'image/png': 'PHOTO',
    'image/jpeg': 'PHOTO',
    'video/mp4': 'VIDEO',
    'video/quicktime': 'VIDEO',
    'application/pdf': 'DOCUMENT'
  };

  const onSendMessage = async () => {
    const filesLength = files.length;
    const trimmedMessage = message.trim();
    if (trimmedMessage || filesLength) {
      const mediaAndFiles = files.reduce(
        (acc: { media: UploadFile[]; files: UploadFile[] }, file: UploadFile) => {
          const type = file.type as string;
          return {
            media:
              MEDIA_TYPES[type] === 'PHOTO' || MEDIA_TYPES[type] === 'VIDEO'
                ? [...acc.media, file]
                : acc.media,
            files: MEDIA_TYPES[type] === 'DOCUMENT' ? [...acc.files, file] : acc.files
          };
        },
        { media: [], files: [] }
      );
      setLoading(true);
      const messageData: any = {
        channelId: selectedChannel?._id || '',
        message: message || '',
        type: filesLength ? 'MEDIA' : 'TEXT'
      };

      try {
        if (trimmedMessage) {
          const defaultTextBody: {
            channelId: string;
          } & SendChatMessageRequest = {
            channelId: selectedChannel?._id || '',
            message: message,
            type: 'TEXT'
          };
          await sendMessage(defaultTextBody);
        }
        if (mediaAndFiles.media.length) {
          const mediaData: {
            channelId: string;
          } & SendChatMessageRequest = {
            channelId: selectedChannel?._id || '',
            message: '',
            type: 'MEDIA'
          };
          const sentMediaMessage = await sendMessage(mediaData);
          const attachedFiles: { path: string; type: string; name: string | null | undefined }[] =
            [];
          const chatMessageId = sentMediaMessage._id as string;
          await asyncForEach(mediaAndFiles.media, async media => {
            const uploadedFile = await doUploadFlow(
              media,
              MEDIA_TYPES[media.type] || 'DOCUMENT',
              'CHAT',
              sentMediaMessage._id
            );
            attachedFiles.push({
              path: uploadedFile.path,
              type: uploadedFile.type,
              name: media.name
            });
          });
          await attachMediaToMessage({
            chatMessageId,
            body: { files: attachedFiles }
          });
        }

        if (mediaAndFiles.files.length) {
          await asyncForEach(mediaAndFiles.files, async file => {
            const fileData: {
              channelId: string;
            } & SendChatMessageRequest = {
              channelId: selectedChannel?._id || '',
              message: '',
              type: 'FILE'
            };
            const sentFileMessage = await sendMessage(fileData);
            const chatMessageId = sentFileMessage._id as string;
            const uploadedFile = await doUploadFlow(
              file,
              MEDIA_TYPES[file.type] || 'DOCUMENT',
              'CHAT',
              sentFileMessage._id
            );
            const attachedFile = {
              path: uploadedFile.path,
              type: uploadedFile.type,
              name: file.name
            };
            await attachMediaToMessage({
              chatMessageId,
              body: { files: [attachedFile] }
            });
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        files && setFiles([]);
        message && setMessage('');
        setLoading(false);
        scrollDownMessages();
      }
    }
  };

  const handleEmojiClick = (emojiObject: EmojiClickData) => {
    setMessage(message + emojiObject.emoji);
  };

  const scrollDownMessages = () => {
    const { current } = messagesContainerRef;
    if (current?.scrollTop) {
      current?.scroll({
        top: current.scrollHeight
      });
    }
  };

  const handleUploadChange: UploadProps['onChange'] = ({ fileList, file }) => {
    setFiles(fileList);
  };

  const handleFileDelete = (index: number) => {
    const filteredFiles = files.filter((file, i) => i !== index);
    setFiles(filteredFiles);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    keyMap.current[e.key] = true;
    if (keyMap.current['Enter'] && keyMap.current['Shift']) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (loading) {
        return;
      }
      onSendMessage();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    delete keyMap.current[e.key];
  };

  const addContentToModal = (activeItem: string | undefined, photos: any[], videos: any[]) => {
    const payloadTemp = {
      photos,
      videos,
      activeItem,
      fileFetchType: 'upload'
    };

    const filteredPayload = filterObj(payloadTemp, ['empty-array']);
    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: filteredPayload
    });
  };

  const hideSuggestionPopover = () => {
    setTimeout(() => {
      dispatch({
        type: SET_SUGGESTION_POPOVER,
        payload: {visible:false,ownerIsMe:false,cleanText:false}
      });
    }, 400);
  };

  const handleUploadedMediaClick = (files: any[], fileItem: UploadFile) => {
    const photosTemp: string[] = [];
    const videosTemp: string[] = [];

    let activeItemPath = '';
    files.forEach(file => {
      const path = URL.createObjectURL(file.originFileObj);
      if (fileItem.uid == file.uid) {
        activeItemPath = path;
      }
      if (file.type.includes('image')) {
        photosTemp.push(path);
      } else if (file.type.includes('video')) {
        videosTemp.push(path);
      }
    });
    addContentToModal(activeItemPath, photosTemp, videosTemp);
  };



  useEffect(()=>{
    if(suggestionPopover.visible){
      setMessage('Please change your offer price to ')
      textAreaRef.current?.focus()
    }else if(!suggestionPopover.visible && suggestionPopover.cleanText){
      setMessage('')
    }

  },[suggestionPopover.visible])

  return (
    <>
      <Col
        id="chat-input-popover"
        className={classNames(styles.messageInputContainer, 'border-r-[1px] border-[#EBEBEB]')}
      >
        <Row
          className={classNames(styles.filesContainer, { [styles.fileExists]: files.length })}
          align="middle"
          gutter={[0, 15]}
        >
          {files.map((file, index) => {
            const mediaType = file.type?.split('/')[0];
            return (
              <Col key={index} className={styles.fileItem}>
                <Row
                  className={classNames(styles.fileItemDelete)}
                  align="middle"
                  justify="center"
                  onClick={() => handleFileDelete(index)}
                >
                  <div className="icon-24" />
                </Row>

                {file.type?.includes('image') || file.type?.includes('video') ? (
                  <Col
                    className="cursor-pointer"
                    onClick={() => handleUploadedMediaClick(files, file)}
                  >
                    <MessageMediaCard
                      mediaType={mediaType?.toLocaleUpperCase()}
                      videoPlayerIcon={mediaType === 'video'}
                      src={file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''}
                      width={50}
                      height={50}
                    />
                  </Col>
                ) : (
                  <MessageDocumentCard fileName={file.name} type="upload" />
                )}
              </Col>
            );
          })}
        </Row>

        <Row
          className="pl-3 pr-4 border-t-[1px] border-[#EBEBEB] min-h-[56px] h-14 flex items-center"
          wrap={false}
        >
          <Upload
            beforeUpload={() => false}
            showUploadList={false}
            onChange={handleUploadChange}
            fileList={files}
            multiple
            maxCount={5}
          >
            <div
              className={classNames(
                styles.uploadFilesCircle,
                'bg-circle-gray mr-3 flex items-center justify-center rounded-full'
              )}
            >
              <span className={classNames(styles.icon, 'icon-13 text-font-22')} />
            </div>
          </Upload>
          <Popover
            visible={suggestionPopover.visible}
            className={classNames('text-white', 'chat-input-popover')}
            placement="topLeft"
            animation="zoom"
            content={`Suggest your price in the chat. If you come up with the new price, ask the ${suggestionPopover.ownerIsMe ? 'Master' :'Job owner'} to
            change it from their offer.`}
            overlayClassName={styles.popover}
          >
            <TextArea
              placeholder="Type a message..."
              className={styles.textarea}
              ref={textAreaRef}
              value={message}
              id="messageTextArea"
              onChange={e => {
                if (suggestionPopover.visible) {
                  hideSuggestionPopover();
                }
                setMessage(e.target.value);
              }}
              onKeyDownCapture={handleEnterPress}
              onKeyUp={handleKeyUp}
            />
          </Popover>
          <span
            className="icon-42 text-font-20 mx-5 cursor-pointer"
            onClick={() => onOpenEmojiClick()}
          />

          {loading ? (
            <Spin className="ml-2" />
          ) : (
            <span
              onClick={() => onSendMessage()}
              className={classNames('icon-43 text-font-18 text-green cursor-pointer', {
                'text-[#DADADA] cursor-auto': !message.trim() && !files.length
              })}
            />
          )}
          {emojiVisible && (
            <div className="absolute right-0 bottom-[10px]" ref={emojiContainerRef}>
              <EmojiPicker
                lazyLoadEmojis
                width="auto"
                onEmojiClick={handleEmojiClick}
                previewConfig={{ showPreview: false }}
              />
            </div>
          )}
        </Row>
      </Col>

    </>
  );
};

export default MessageInput;
