import { NotificationType } from "common"


type SystemNotificationType = {
    type:string ;
    doerName:string;
    masterName:string;
    jobTitle:string;
    metaData?:{tipAmount?:number,paidAmount?:number,doerRatingStart?:number,masterRatingStart?:number};

}


const getSystemNotification = ({type,doerName,masterName,jobTitle,metaData}:SystemNotificationType) => {


  
  const SYSTEM_NOTIFICATION:any = {

        MASTER_APPLY_JOB:{
                title:'Master applied for your job!',
                text:`Master applied for your ${jobTitle} job. Review master’s profile and respond to their offer.`,
            },
        DOER_INVITE_TO_JOB:{
              title:'You’ve got a job offer!',
              text:`You have been invited to the ${jobTitle} job.  Please review offer and proceed`,
          },
        MASTER_APPLY_JOB_EDITED:{
              title:'Master applied with a new price!',
              text:`Please review new offer for your ${jobTitle} and proceed.`,
          },
        DOER_INVITE_TO_JOB_EDITED:{
            title:'You’ve got a job offer with a new price!',
            text:`Please review new offer for ${jobTitle} and proceed.`,
          }, 
        MASTER_CANCELED_OFFER:{
          title:'Master cancelled the offer!',
          text:'Please review and continue looking for masters.',
        },
        DOER_CANCELED_OFFER:{
          title:'Offer is cancelled!',
          text:'Continue looking for jobs.',
        },
        MASTER_INVITATION_ACCEPTED:{
          title:'Master accepted the offer!',
          text:'You’ll be notified when Master starts working.',
        },
        DOER_APPLY_JOB_ACCEPTED:{
          title:'Job owner accepted the offer!',
          text:'Change the status once you start working on the job.',
        },
        
        
        MASTER_INVITATION_REJECTED:{
          title:'Master rejected the offer!',
          text:'Please review and continue looking for masters.',
        },

        DOER_APPLY_JOB_REJECTED:{
          title:'Offer is rejected!',
          text:'Continue looking for jobs.',
        },
        MASTER_JOB_STARTED:{
          title:'Work is started!',
          text:'You’ll be notified when Master finishes his work.',
        },
        MASTER_JOB_RESUMED:{
          title:'Work is Resumed!',
          text:'You’ll be notified when Master finishes his work.',
        },
        
        
        MASTER_JOB_MARKED_AS_COMPLETED:{
          title:'Job is completed!',
          text:`${masterName} finished the job, please review and accept.`,
        },
        DOER_JOB_COMPLETION_APPROVED:{
          title:'Work is approved!',
          text:'You’ll be paid for it shortly.',
        },
        DOER_JOB_COMPLETION_REPORT_ISSUE:{
          title:'Issue is reported!',
          text:'Please review the reported issue(s) and respond.',
        },
        MASTER_JOB_COMPLETION_REPORT_ISSUE_ACCEPTED:{
          title:'Issue is accepted!',
          text:'You’ll be notified when he starts/resumes working.',
        },
        
        
        MASTER_JOB_COMPLETION_REPORT_ISSUE_DECLINED:{
          title:'Issue is declined!',
          text:'Please review again and respond.',
        },
        DOER_JOB_COMPLETION_REPORT_ISSUE_CANCELED:{
          title:'Issue is cancelled and work is approved ',
          text:'You’ll be paid for it shortly.',
        },
        DOER_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN:{
          title:'Job was not approved and passed to Admin for review',
          text:'Admins will review your case and contact you shortly.',
        },
        DOER_JOB_PAID:{
          title:'You have been paid!',
          text:`$${metaData?.paidAmount} has been transferred to your Paypal account.`,
        },
       
        DOER_JOB_TIPPED:{
          title:'Tip is added',
          text:'You’ll receive a payment shortly.',
        },
        DOER_JOB_TIP_FAILED_FINAL_ATTEMPTS:{
          title:'Tip transfer from your account is failed!',
          text:'You’ll receive a payment shortly.',
        },
        DOER_JOB_TIP_FAILED_ATTEMPTS_1:{
          title:'Tip transfer from your account is failed!',
          text:'We’re not able to proceed the contract based payment. Master is notified.',
        },
        DOER_JOB_TIP_FAILED_ATTEMPTS_2:{
          title:'Tip transfer from your account is failed!',
          text:'We’re not able to proceed the contract based payment.',
        },

        DOER_JOB_PAYMENT_FAILED_FINAL_ATTEMPTS :{
          title:'The contract based Payment is failed!',
          text:'We’re not able to proceed the contract based payment. Master is notified.',
        },
        DOER_JOB_PAYMENT_FAILED_ATTEMPTS_1 :{
          title:'The first Payment attempt is failed!',
          text:'We’re not able to proceed the contract based payment.',
        },
        DOER_JOB_PAYMENT_FAILED_ATTEMPTS_2 :{
          title:'The second Payment attempt is failed!',
          text:'We’re not able to proceed the contract based payment.',
        },
        SYSTEM_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN :{
          title:'Job was not approved and passed to Admin for review',
          text:'Admins will review your case and contact you shortly.',
        },
        DOER_FEEDBACK_LEFT :{
          title:`You have received ${metaData?.doerRatingStart}⭐️ from ${doerName}!`,
          text:'',
        },
        MASTER_FEEDBACK_LEFT :{
          title:`You have received ${metaData?.masterRatingStart}⭐️ from ${masterName}!`,
          text:'',
        },
       
  }

  return SYSTEM_NOTIFICATION[type];

}


export {getSystemNotification}
