  import { Typography, Col, Row, Space, Input, Select } from 'antd';
  import classNames from 'classnames';
  import CustomTextArea from 'components/CustomTextArea';
  import DButton from 'components/DButton';
  import TextWithIcon from 'components/JobPostModal/TextWithIcon';
  import { Controller, FieldValues, useForm } from 'react-hook-form';
  import { useSelector } from 'react-redux';
  import useUser from 'redux/user/use-user';
  import styles from './index.module.scss';
  import { useDispatch } from 'react-redux';
  import { CLOSE_HIRE_MODAL } from 'redux/modals/actionTypes';
  import { getMyJobs, inviteMasterToJob } from 'api/jobs';
  import { useEffect, useRef, useState } from 'react';
  import { InviteMasterJobRequest, JobItemLite,JobItemMyJobsResponse } from 'common';
  import { useDidMountEffect, useEffectAsync } from 'hooks';
  const { Option } = Select;
  import { alert } from 'helpers';
  import { setRedirectedChanelId } from 'redux/chat/actions';
  import { useNavigate } from 'react-router-dom';
  import { getLoggedInUser } from 'api/auth';
  import { getMaster } from 'api/masters';

  const { Text } = Typography;

  type HireMasterType = {
    job: string | undefined;
    note: string;
    pricePerHour: string | number | undefined;
    pricePerJob: string | number | undefined;

  };



  type inputStateType = {
    text: string;
    displayText: string;
    prefix: boolean;
  };

  const HireMaster = () => {
    const { user,keepUser } = useUser();
    const { hireMasterModal: modalState } = useSelector((state: any) => state.modals);
    const dispatch = useDispatch();
    const [myJobs, setMyJobs] = useState<JobItemMyJobsResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const channelIdRef = useRef<string|null>(null);

    const [disabled,setDisabled] = useState<boolean>(true);
    const [pricies,setPricies] = useState<{pricePerHour:string | number | undefined,pricePerJob:string | number | undefined}| null>(null)
    const [masterPricies,setMasterPricies] = useState<{pricePerHour:string | number | undefined,pricePerJob:string | number | undefined}| null>(null)

    const perHourDisplayRef = useRef<any>(null);
    const perJobDisplayRef = useRef<any>(null);

    const perHourInputRef = useRef<any>(null);
    const perJobInputRef = useRef<any>(null);

    const [perJobInputState, setPerJobInputState] = useState<inputStateType>({
      text: '/job',
      displayText: '',
      prefix: false
    });

    const [perHourInputState, setPerHourInputState] = useState<inputStateType>({
      text: '/hour',
      displayText: '',
      prefix: false
    });


    const {
      control,
      handleSubmit,
      formState: { errors },
      watch,
      setValue
    } = useForm<HireMasterType>({
      defaultValues: {
        job: undefined,
        pricePerJob: modalState?.pricePerJob || undefined,
        pricePerHour: modalState?.pricePerHour || undefined,
        note: `Hello,\n\nPlease check my job post.\n\nBest Regards,\n${user.firstName}`
      }
    });

    const { job, note ,pricePerJob,pricePerHour} = watch();

    const closeHireModal = () => {
      dispatch({
        type: CLOSE_HIRE_MODAL
      });
    };

    const onSubmitHandle = async (data: FieldValues) => {
      try {
        setLoading(true);

        let body: InviteMasterJobRequest & { jobId: string } = {
          jobId: data.job,
          message: data.note,
          masterId: modalState.masterId,}
        if(pricePerHour){
          body = {...body, hourlyRate : Number(pricePerHour)}
        }
        if(pricePerJob){
          body = {...body, flatPrice : Number(pricePerJob)}
        }

        await inviteMasterToJob(body);
        alert.success('You have successfully invited a Master to review your Job post');
       closeHireModal();

      } catch (error) {
        console.error(error);
        //alert.error('Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    useEffectAsync(async () => {
      const res = await getMyJobs();
      setMyJobs(res);
    }, []);

    useDidMountEffect(() => {
      if (myJobs.length) {
        setValue('job', myJobs[0]._id);
        setValue('pricePerJob', myJobs[0]?.flatPrice);
        setValue('pricePerHour', myJobs[0]?.hourlyRate);
      }
    }, [myJobs]);

    const onChangeJobList = (id:string) =>{
      myJobs?.forEach(myJob =>{
        if(myJob._id === id){

          setValue('pricePerJob', myJob?.flatPrice || undefined);
          setValue('pricePerHour', myJob?.hourlyRate || undefined);
          setValue('job', id);
        }
    })
    }

    const isMasterActive = () =>{

      const {masterId} = modalState;
      let flag = false;
      myJobs?.forEach(myJob =>{
          if(myJob._id === job){
            myJob?.activeMasters?.forEach(master =>{
              if(master.masterId === masterId){
                  channelIdRef.current = master.channelId
                 flag = true
              }
          })
          }
      })
      return flag;
    }


    const onNvigateToChat = ()=>{
      dispatch(
        setRedirectedChanelId({
          redirectedChanelId:channelIdRef.current
        })
      );

      dispatch({
        type: CLOSE_HIRE_MODAL
      });
         navigate('/chat')
     }

     const onBlurHandle = (
      e: any,
      setInputState: React.Dispatch<React.SetStateAction<inputStateType>>,
      ref: any
    ) => {
      setInputState(prevState => {
        let prefix = false;
        ref.current.style.display = 'none';
        if (e.target.value) {
          ref.current.style.display = 'block';
          prefix = true;
        }
        return { ...prevState, blur: true, prefix: prefix };
      });
    };

    const onFocusHandle = (
      e: any,
      setInputState: React.Dispatch<React.SetStateAction<inputStateType>>,
      ref: any
    ) => {
      setInputState(prevState => {
        ref.current.style.display = 'none';
        return { ...prevState, blur: false, prefix: true };
      });
      e.target.focus();
    };

    useEffectAsync(async () => {
      try{
        const master = await getMaster(modalState.masterId);
        setMasterPricies({
          pricePerHour:master.hourlyRate,
          pricePerJob:master.flatPrice
        })

      }catch(error){
        console.error(error);

      }
      setDisabled(false);

      if(!((pricePerHour || pricePerJob) && note.trim())){
        // setDisabled(true)
      }

      if (pricePerHour) {
        setPerHourInputState(prev => {
          return { ...prev, prefix: true, displayText: `${pricePerHour} $${prev.text}` };
        });
      }
      if (pricePerJob) {
        setPerJobInputState(prev => {
          return { ...prev, prefix: true, displayText: `${pricePerJob} $${prev.text}` };
        });
      }
    }, [pricePerHour, pricePerJob]);

    useEffect(() => {
      if (pricePerHour) {
        perHourDisplayRef.current.style.display = 'block';
      }
      if (pricePerJob) {
        perJobDisplayRef.current.style.display = 'block';
      }
    }, []);

    const masterRateRenderer = () => {

      let text = ''
      if(masterPricies?.pricePerHour &&  Number(masterPricies?.pricePerHour)  > 0){
          text = `$${masterPricies?.pricePerHour }/hour`
      }
      if(masterPricies?.pricePerJob  && masterPricies?.pricePerHour ){
        text += ' | '

      }
      if(masterPricies?.pricePerJob  &&   Number(masterPricies?.pricePerJob) > 0){
        text += `$${masterPricies?.pricePerJob }/job`
      }
      return text
    }


    return (
      <>

        <Row className="flex justify-center">
          <Text className="text-font-22 font-bold">Invite Master for the Job </Text>
        </Row>
        <Row className="mt-8 w-full">
          <TextWithIcon text="Select from your jobs list" classes="ml-1" />
          <Row className="flex flex-row ml-7 w-full mt-3">
            <Controller
              name="job"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  className={classNames('w-full h-[40px]', styles.select)}
                  value={value}
                  onChange={(e)=>{
                    // onChange();
                    onChangeJobList(e);
                  }}
                  placeholder="Select Job"
                  suffixIcon={<i className="icon-20 text-font-8 text-light-gray" />}>
                  {myJobs.map(job => (
                    <Option key={job._id} value={job._id}>
                      {job.title}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Row>
          {/* not finished */}
          {!isMasterActive() && (

              <Row className="mt-8 w-full">
              <TextWithIcon text={'What is the amount you are ready to pay for this job ?'} classes="ml-1" />
              <Text className="w-full ml-7 italic text-font-13">
                        Master’s rate : &nbsp;
                  <Text className="font-bold text-[#515864]">
                    {masterRateRenderer()}
                         </Text>
                  <br />
                </Text>

              {/* Suggested price section */}
              <Row className="flex flex-row">
                <Col>
                  <Controller
                    name="pricePerHour"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Col className="!relative">

                        <Input
                          placeholder={perHourInputState.prefix ? '' : 'Price per hour ($/hour)'}
                          value={value}
                          ref={perHourInputRef}
                          prefix={
                            <div className={classNames({ hidden: !perHourInputState.prefix })}>$</div>
                          }
                          onChange={e => {
                            if (/^(\d{0}|\d+)$/.test(e.target.value)) {
                              onChange(e);
                            }
                          }}
                          className={classNames(
                            styles.input,
                            'w-[187px] mt-3 h-[40px] ml-7 pl-4 font-normal '
                          )}
                          onBlur={e => onBlurHandle(e, setPerHourInputState, perHourDisplayRef)}
                          onFocus={e => onFocusHandle(e, setPerHourInputState, perHourDisplayRef)}
                        />
                        <div
                          className={styles.fakeValue}
                          ref={perHourDisplayRef}
                          onClick={e => {
                            e.currentTarget.style.display = 'none';
                            perHourInputRef.current && perHourInputRef.current.focus();
                          }}
                        >

                          { (pricePerHour && Number(pricePerHour) > 0) && (
                            <Text ellipsis={{ tooltip: true, suffix: '' }} className="w-[145px]">
                            {perHourInputState.displayText}
                          </Text>
                          )}

                        </div>
                      </Col>
                    )}
                  />
                  <Row justify="start" className="ml-11">
                    {errors.pricePerHour && (
                      <Text className="text-icon-err">{errors.pricePerHour.message}</Text>
                    )}
                  </Row>
                </Col>
                <Col>
                  <Controller
                    name="pricePerJob"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Col className="!relative">
                        <Input
                          ref={perJobInputRef}
                          prefix={

                            <div className={classNames({ hidden: !perJobInputState.prefix })}>$</div>
                          }
                          value={value}
                          onChange={e => {
                            if (/^(\d{0}|\d+)$/.test(e.target.value)) {
                              onChange(e);
                            }
                          }}
                          placeholder={perJobInputState.prefix ? '' : 'Price per job ($/job)'}
                          className={classNames(
                            styles.input,
                            'w-[187px] mt-3 ml-7 pl-4  h-[40px] font-normal'
                          )}
                          onBlur={e => onBlurHandle(e, setPerJobInputState, perJobDisplayRef)}
                          onFocus={e => onFocusHandle(e, setPerJobInputState, perJobDisplayRef)}
                        />
                        <div
                          className={styles.fakeValue}
                          ref={perJobDisplayRef}
                          onClick={e => {
                            e.currentTarget.style.display = 'none';
                            perJobInputRef.current && perJobInputRef.current.focus();
                          }}
                        >

                          {pricePerJob && (
                            <Text
                            ellipsis={{
                              tooltip: true,
                              suffix: ''
                            }}
                            className="w-[145px]"
                          >
                            {perJobInputState.displayText}
                          </Text>
                          )}

                        </div>
                      </Col>
                    )}
                  />
                  <Row justify="start" className="ml-11">
                    {errors.pricePerJob && (
                      <Text className="text-icon-err">{errors.pricePerJob.message}</Text>
                    )}
                  </Row>
                </Col>
              </Row>
              </Row>
          )}

          <Row className="w-full">
            <TextWithIcon text="Add a note for the Master" classes="ml-1 mt-8" />
            <Controller
              name="note"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomTextArea classes="ml-7 mt-3" value={value} onChangeValue={onChange} />
              )}
            />
          </Row>
        </Row>
        <Row justify="end" className="mt-[40px]">
          <Space size={16}>
            <DButton type="white" className="!w-[148px] !h-[44px]" onClick={closeHireModal}>
              Cancel
            </DButton>

            {isMasterActive() ?  <DButton
              type="light-green"
              className="text-font-16 font-bold text-white w-[200px] h-[44px]"
              onClick={onNvigateToChat}
              submit
              loading={loading}
              disabled={!(job && note.trim())}
            >
              View Offer
            </DButton> :  <DButton
              type="light-green"
              className="text-font-16 font-bold text-white w-[200px] h-[44px]"
              onClick={handleSubmit(onSubmitHandle)}
              submit
              loading={loading}
              disabled={!(job && note.trim())}
            >
              Invite
            </DButton> }

          </Space>
        </Row>
      </>
    );
  };

  export default HireMaster;
