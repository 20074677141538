import { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GALLERY_MODAL_CLOSE } from 'redux/modals/actionTypes';

import ReactPlayer from 'react-player/lazy';

import styles from './index.module.scss';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ReactDOM, { createPortal } from 'react-dom';
import React from 'react';

const GallerySwiper = () => {
  const { galleryModal } = useSelector((state: any) => state.modals);
  const { galleryItems } = galleryModal;
  const [playing, setPlaying] = useState(true);
  const [content, setContent] = useState<any>();
  const swiperRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const pathPrefix =
    galleryItems.fileFetchType === 'download' ? process.env.REACT_APP_S3_BUCKET : '';
  const mediaArray = useMemo(() => {
    const photosAndVideos: { type: 'video' | 'photo'; src: string }[] = [];
    const activeItem = galleryItems.activeItem;
    if (galleryItems) {
      if (galleryItems.videos) {
        galleryItems.videos.map((item: string) => {
          photosAndVideos.push({ type: 'video', src: item });
        });
      }
      if (galleryItems.photos) {
        galleryItems.photos.map((item: string) => {
          photosAndVideos.push({ type: 'photo', src: item });
        });
      }
    }
    if (activeItem) {
      let pos = 0;
      photosAndVideos.find((item, index) => {
        pos = index;
        return item.src === activeItem;
      });
      setActiveIndex(pos);
    }
    return photosAndVideos;
  }, [galleryItems]);

  const getVideo = (item: any, key: number) => {
    const src = pathPrefix + item.src;

    if (activeIndex === key) {
      return (
        <ReactPlayer
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          stopOnUnmount
          // onPause={() => setPlaying(false)}
          // onPlay={() => setPlaying(true)}
          playing={playing}
          controls={true}
          url={src}
          volume={0}
        />
      );
    }
    return <></>;
  };

  const onSlideChange = (swiper: any) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <Swiper
      onSwiper={swiper => {
        swiper.slideTo(activeIndex);
      }}
      className={styles.swiper}
      slidesPerView="auto"
      onSlideChange={swiper => {
        // swiper.slideTo(activeIndex);
        onSlideChange(swiper);
        setPlaying(false);
      }}
      freeMode
      navigation
      modules={[Navigation, Pagination]}
      watchSlidesProgress={true}
      pagination={{
        clickable: true,
        type: 'fraction'
      }}
    >
      {mediaArray.map((item, key) => {
        const src = pathPrefix + item.src;
        return (
          <SwiperSlide id={`swiper-${key}`} className={styles.swiperSlide} key={key}>
            {item.type === 'video' ? getVideo(item, key) : <img src={src} alt="Gallery Image" />}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default GallerySwiper;
