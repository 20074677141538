import { combineReducers } from 'redux';

import { RESET_STORE } from './common/types';

import userReducer from './user/reducer';
import dashboardReducer from './dashboard/reducer';
import { jobPostReducer } from './jobPost/reducer';
import modalsReducer from './modals/reducer';
import { googleMapZoomReducer } from './googleMapZoom/reducer';
import chatReducer from './chat/reducer';
import notificationReducer from './notification/reducer';
import transactionReducer from './transactions/reducer';
import generalReducer from './general/reducer';



const appReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  modals: modalsReducer,
  jobPost: jobPostReducer,
  googleMapZoom: googleMapZoomReducer,
  chat: chatReducer,
  transaction:transactionReducer,
  notifications:notificationReducer,
  general:generalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
