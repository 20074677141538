import { useCallback, useEffect, useRef, useState } from 'react';
import { Menu, Typography, Row, Modal, Skeleton } from 'antd';

import DButton from 'components/DButton';
import Notifications from 'components/Notifications';
import UserSettings from 'components/UserSettings';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import JobPostModal from 'components/JobPostModal';
import useJobPost from 'redux/jobPost/use-job-post';
import classNames from 'classnames';
import NotificationItem from 'components/NitificationItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewNotification,
  fetchMoreNotifications,
  fetchNotifications,
  fetchNotificationsMarkAllAsSeen,
  fetchUnseenNotificationsCount
} from 'redux/notification/actions';
import { NotificationsState } from 'redux/notification/types';
import { Virtuoso } from 'react-virtuoso';
import useUser from 'redux/user/use-user';
import _ from 'lodash';
import OneSignal from 'react-onesignal';
import {
  addNewChannel,
  addNewMessage,
  fetchUnseenChannelsRequest,
  resetChat,
  setRedirectedChanelId,
  updateChannel,
  updateMessage
} from 'redux/chat/actions';
import { unseenMessages } from 'redux/chat/selectors';
import { Socket, connect } from 'socket.io-client';
import { setLastAction, setSocket } from 'redux/general/actions';
import { InterlocutorUser, ModifiedChannel } from 'redux/chat/types';
import { getUserPaymentMethods } from 'api/transactions';
import {
  CLOSE_CONNECT_PAYMENT_MODAL,
  OPEN_CONNECT_PAYMENT_MODAL,
  POST_JOB_MODAL
} from 'redux/modals/actionTypes';
import PayPalMoadalContent from 'components/MainLayout/Modals/PaymentMoadalContent';
import { useEffectAsync } from '../../hooks';
import AgreeAndContinue from '../MainLayout/Modals/AgreeAndContinue ';
import { getExternalUserIdAuthHash } from 'api/notification';

const items = [
  { label: 'Explore', link: '/explore' }
  // { label: 'Jobs Posted', link: '/asd' },
  // { label: 'Jobs Applied', link: '/asd' }
];
const START_INDEX = 100000;

const Header: React.FC = (): JSX.Element => {
  const { isPostJobModalOpen } = useSelector((state: any) => state.modals);
  const { reset: resetFilters } = useJobPost();
  const navigate = useNavigate();
  const [showNotificationDrpDown, setShowNotificationDrpDown] = useState<boolean>(false);
  const { connectPaymentModal } = useSelector((state: any) => state.modals);
  const dispatch = useDispatch();
  const virtuoso = useRef<any>(null);
  const socketRef = useRef<any>(null);
  const { user, keepUser } = useUser();

  const { selectedChannel } = useSelector((state: any) => state.chat);
  const {
    notifications,
    isLoading: notificationsIsLoading,
    next,
    unseenNotificationsCount
  } = useSelector((state: any): NotificationsState => state.notifications);
  const unseenMessagesInfo = useSelector(unseenMessages);
  const { socket, lastAction } = useSelector((state: any) => state.general);
  let notificationDrpDownStyles = '';
  const [agreeAndContinue, setAgreeAndContinue] = useState<boolean>(false);

  window.addEventListener('message', function (event) {
    if (event.origin !== process.env.REACT_APP_HOSTING) {
      return false;
    } // Check the origin

    let receivedData = event.data;
    if (receivedData === 'approved') {
      if (lastAction && lastAction.action !== 'PAYMENT_PAGE') {
        if (lastAction.type === 'modal') {
          dispatch({
            type: lastAction.action,
            payload: lastAction.data
          });
          if (lastAction.afterAction) {
            dispatch(
              setLastAction({
                action: lastAction.afterAction.action,
                data: lastAction.afterAction.data,
                type: lastAction.afterAction.type
              })
            );
          } else {
            dispatch(setLastAction(null));
          }
        }
      } else {
        dispatch(setLastAction({ action: 'PAYMENT_PAGE', data: { finished: true }, type: '' }));
      }
    }
  });

  useEffectAsync(async () => {
    if (lastAction && lastAction.action !== 'PAYMENT_PAGE') {
      if (lastAction.type === 'navigate') {
        navigate(lastAction.action, lastAction.data);
        dispatch(setLastAction(null));
      }

      // const payMethods = await getUserPaymentMethods();
      // if(payMethods.length === 0){
      //     dispatch({
      //       type: OPEN_CONNECT_PAYMENT_MODAL
      //     });
      // }
    }
  }, [lastAction]);

  if (!showNotificationDrpDown) {
    notificationDrpDownStyles = ' invisible opacity-0 top-[85px] translate-y-1';
  } else {
    notificationDrpDownStyles = ' visible opacity-100 top-[58px] translate-y-0';
  }

  useEffect(() => {
    if (!_.isEmpty(user)) {
      dispatch(fetchNotifications({ limit: 10 }));
    }

    if (showNotificationDrpDown) {
      dispatch(fetchNotificationsMarkAllAsSeen());
    }
  }, [showNotificationDrpDown]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      dispatch(fetchUnseenNotificationsCount());
      dispatch(fetchUnseenChannelsRequest());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      const socket = connect('https://socket.doers-dev.com', {
        extraHeaders: {
          user_id: user._id,
          token: localStorage.getItem('token')!
        }
      });
      socketRef.current = socket;
      dispatch(setSocket({ socket }));

      return () => {
        //socket?.off('connect');
        socket?.off('disconnect');
        socket?.off('private-message-channel');
        socket?.off('private-notification-channel');
        console.log('socket disconnected');
      };
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      //socket?.off('private-message-channel');
      socketRef.current?.on('private-message-channel', ({ messages }: any) => {
        messages.forEach((message: any) => {
          if (message.type === 'NEW_CHAT_MESSAGE') {
            dispatch(
              addNewMessage({
                message: message.payload
              })
            );
          } else if (message.type === 'UPDATE_CHAT_MESSAGE') {
            dispatch(
              updateMessage({
                message: message.payload
              })
            );
          }
        });
      });
      socketRef.current?.on('private-notification-channel', ({ messages }: any) => {
        messages.forEach((notification: any) => {
          if (notification.type === 'NEW_NOTIFICATION') {
            dispatch(
              addNewNotification({
                notification: notification.payload
              })
            );
          }
        });
      });
    }
  }, []);

  const openAgreeAndContinueModal = () => {
    setAgreeAndContinue(true);
  };

  const onOpenJobClickHandler = async () => {
    const payMethods = await getUserPaymentMethods();
    if (payMethods.length > 0) {
      dispatch({
        type: POST_JOB_MODAL,
        payload: true
      });
    } else {
      dispatch(setLastAction({ action: POST_JOB_MODAL, data: true, type: 'modal' }));
      dispatch({
        type: OPEN_CONNECT_PAYMENT_MODAL
      });
    }
  };

  const onJobModalCancelHandler = () => {
    dispatch({
      type: POST_JOB_MODAL,
      payload: false
    });
    resetFilters();
  };

  const loadMoreNotifications = () => {
    if (next) {
      dispatch(fetchMoreNotifications({ limit: 10, next }));
    }
  };

  const onNavigateToChatPage = useCallback((cahanelId: string) => {
    setShowNotificationDrpDown(false);
    dispatch(
      setRedirectedChanelId({
        redirectedChanelId: cahanelId
      })
    );
    navigate('/chat');
  }, []);

  // importScripts('https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js');

  // useEffectAsync(async () => {
  //   console.log(
  //     process.env.REACT_APP_ONE_SIGNAL_ID,
  //     'process.env.REACT_APP_ONE_SIGNAL_ID, i am here  444444455'
  //   );

  //   if (!_.isEmpty(user)) {
  //     OneSignal.init({
  //       appId: '66606323-1b49-4485-9e24-540d4b6891c9',
  //       allowLocalhostAsSecureOrigin: true,
  //       persistNotification: false,
  //       autoRegister: false,
  //       notificationClickHandlerMatch: 'origin',
  //       notificationClickHandlerAction: 'focus',
  //       open_url_target: 'ddd test'
  //     })
  //       .then(() => {
  //         OneSignal.getUserId()
  //           .then(async playerId => {
  //             console.log('Player ID:', playerId);
  //             if (!playerId) {
  //               playerId = '';
  //             }
  //             // You can now use the player ID as needed in your application
  //             const externalUserIdAuthHash = await getExternalUserIdAuthHash(playerId);
  //             console.log(
  //               324324,
  //               '-  - -- - - - - -externalUserIdAuthHash - - -',
  //               externalUserIdAuthHash
  //             );

  //             OneSignal.getExternalUserId()
  //               .then(externalUserId => {
  //                 console.log(45645645654, externalUserId);
  //                 if (
  //                   (!externalUserId || externalUserId === 'promo') &&
  //                   localStorage.getItem('pushNotificationId')
  //                 ) {
  //                   console.log('externalUserIdAuthHash - - -', externalUserIdAuthHash);
  //                   const newUserId = localStorage.getItem('pushNotificationId');

  //                   console.log(newUserId, 'newUserId - - -', externalUserIdAuthHash);

  //                   // OneSignal.setExternalUserId(newUserId, externalUserIdAuthHash)

  //                   OneSignal.setExternalUserId(newUserId)
  //                     .then(() => {
  //                       console.log(`OneSignal external user ID set to ${newUserId} for new user.`);
  //                     })
  //                     .catch(error => {
  //                       console.error(error, '--- - -- ---- ee');
  //                     });
  //                 }

  //                 const onNotificationClicked = function (res: Notification) {
  //                   const { data } = res;
  //                   dispatch(
  //                     setRedirectedChanelId({
  //                       redirectedChanelId: data.channelId
  //                     })
  //                   );
  //                   navigate('/chat');
  //                 };
  //                 const handler = function (data: Notification) {
  //                   // call your primary handler
  //                   onNotificationClicked(data);
  //                   // add another handler right away
  //                   OneSignal.addListenerForNotificationOpened(handler);
  //                 };
  //                 // attach your handler for the first time
  //                 OneSignal.addListenerForNotificationOpened(handler);
  //               })
  //               .catch(error => {
  //                 console.log(error, '23432423 - - - - - --');
  //               });
  //           })
  //           .catch(error => {
  //             console.log(error, 'Player ID - - - - - --');
  //           });
  //       })
  //       .catch(error => {
  //         console.log(error, ' - - - - - --');
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_ID,
        allowLocalhostAsSecureOrigin: true,
        persistNotification: false,
        autoRegister: false,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
        open_url_target: 'ddd test'
      })
        .then(() => {
          console.log(OneSignal, 'OneSignal');

          OneSignal.getExternalUserId()
            .then(externalUserId => {
              console.log(45645645654, externalUserId);
              if (
                (!externalUserId || externalUserId === 'promo') &&
                localStorage.getItem('pushNotificationId')
              ) {
                const newUserId = localStorage.getItem('pushNotificationId');

                OneSignal.setExternalUserId(newUserId)
                  .then(() => {
                    console.log(`OneSignal external user ID set to ${newUserId} for new user.`);
                  })
                  .catch(error => {
                    console.error(error, '--- - -- ---- ee');
                  });
              }

              const onNotificationClicked = function (res: Notification) {
                const { data } = res;
                dispatch(
                  setRedirectedChanelId({
                    redirectedChanelId: data.channelId
                  })
                );
                navigate('/chat');
              };
              const handler = function (data: Notification) {
                // call your primary handler
                onNotificationClicked(data);
                // add another handler right away
                OneSignal.addListenerForNotificationOpened(handler);
              };
              // attach your handler for the first time
              OneSignal.addListenerForNotificationOpened(handler);
            })
            .catch(error => {
              console.log(error, ' - - - - - --');
            });
        })
        .catch(error => {
          console.log(error, ' - - - - - --');
        });
    }
  }, []);

  const closeConnectPaymentModal = () => {
    dispatch({ type: CLOSE_CONNECT_PAYMENT_MODAL });
  };

  return (
    <div className={styles.header}>
      <div className="flex">
        <div className="cursor-pointer" onClick={() => navigate('/explore')}>
          <Typography className={styles.logo}>Doers</Typography>
        </div>
        {/* {!_.isEmpty(user) && (
              <Menu mode="horizontal" className={styles.doersMenu}>
                  {items.map(item => (
                    <Menu.Item key={item.label}>
                      <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  ))}
              </Menu>
            )

            } */}
      </div>

      {!_.isEmpty(user) ? (
        <div className="flex mr-8">
          <DButton
            type="light-green"
            className={styles.postJob}
            onClick={openAgreeAndContinueModal}
          >
            <Row align="middle" justify="center">
              Post a job
              <i className="icon-28 ml-2" />
            </Row>
          </DButton>
          <Modal
            visible={isPostJobModalOpen}
            footer={false}
            closable={false}
            className="postJobModal"
            destroyOnClose
          >
            <JobPostModal onCancelHandle={onJobModalCancelHandler} />
          </Modal>
          <Notifications
            type="message"
            className="mr-4"
            unSeenCount={unseenMessagesInfo?.totalUnseenCount || 0}
            onClick={() => {
              navigate('/chat');
            }}
          />
          <Notifications
            unSeenCount={unseenNotificationsCount}
            onClick={() =>
              setShowNotificationDrpDown(!_.isEmpty(user) ? !showNotificationDrpDown : false)
            }
            type="notification"
            className="mr-4"
          >
            {showNotificationDrpDown && (
              <div
                onClick={() => setShowNotificationDrpDown(false)}
                className={classNames(styles.mask)}
              />
            )}

            <div
              className={classNames([
                styles.content,
                notificationDrpDownStyles,
                'w-[280px]  overflow-y-auto  sm:w-[380px] mt-2 transition-all ease-linear duration-200'
              ])}
            >
              <div className="flex  p-5 justify-between">
                <div className="mb-5 font-medium">Notifications</div>
                <div className="mb-5 font-medium">Mark all as read</div>
              </div>

              <div className=" max-h-[32rem] overflow-y-auto overflow-x-hidden">
                {!notificationsIsLoading && showNotificationDrpDown ? (
                  <Virtuoso
                    className="overflow-x-hidden max-h-[32rem]"
                    ref={virtuoso}
                    endReached={loadMoreNotifications}
                    data={notifications}
                    overscan={200}
                    style={{
                      width: '100%',
                      height: '100vh'
                    }}
                    // startReached={loadMoreNotifications}
                    computeItemKey={index => index}
                    itemContent={(index, item: any) => {
                      return (
                        <>
                          <NotificationItem
                            type={item.type}
                            status={item.status}
                            channelId={item.channel}
                            jobTitle={item.job.title}
                            doerName={item.doer.firstName}
                            masterName={item.master.firstName}
                            seen={item.seen}
                            metaData={{
                              tipAmount: item.jobContract.tipAmount,
                              paidAmount: item.jobContract.paidAmount,
                              doerRatingStart: item.jobContract.doerRatingStart,
                              masterRatingStart: item.jobContract.masterRatingStart
                            }}
                            createdAt={item?.createdAt}
                            onNavigate={onNavigateToChatPage}
                          />
                        </>
                      );
                    }}
                  />
                ) : (
                  <div>
                    <Skeleton avatar active title={false} className="p-6 mt-1" />
                    <Skeleton avatar active title={false} className="p-6 mt-1" />
                    <Skeleton avatar active title={false} className="p-6 mt-1" />
                    <Skeleton avatar active title={false} className="p-6 mt-1" />
                    <Skeleton avatar active title={false} className="p-6 mt-1" />
                  </div>
                )}
              </div>
            </div>
          </Notifications>
          <UserSettings />
        </div>
      ) : (
        <>
          <div className="flex mr-8 items-center">
            <Typography className={styles.loginBtn}>
              <span
                onClick={() => {
                  navigate('/auth/login');
                }}
              >
                Log In
              </span>
            </Typography>

            {/* <Typography className={styles.logo}>Log in</Typography> */}
            <DButton
              type="light-green"
              className={styles.postJob}
              onClick={() => {
                navigate('/auth/register');
              }}
            >
              <Row align="middle" justify="center">
                Sign Up
              </Row>
            </DButton>
          </div>
        </>
      )}
      <AgreeAndContinue
        visible={agreeAndContinue}
        closeModal={() => setAgreeAndContinue(false)}
        type="forJob"
        applayAction={() => {
          setAgreeAndContinue(false);
          onOpenJobClickHandler();
        }}
      />
      <PayPalMoadalContent
        visible={connectPaymentModal.show}
        closeModal={closeConnectPaymentModal}
        applayAction={() => console.log('bla bla bla')}
      />
    </div>
  );
};

export default Header;
