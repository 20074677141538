/** @format */

import * as _ from 'lodash';
import { Types } from 'mongoose';

const ObjectId = Types.ObjectId;

export const FilterType = {
  CONTAINS: 1,
  EQUALS: 2,
  EQUALS_CI: 3,
  BEGINS_WITH: 4,
  ENDS_WITH: 5,
  LESS: 6,
  GREATER: 7,
  BETWEEN: 8,
  IN: 9,
  IN_CI: 10,
  ALL: 11,
  SEARCH: 12,
  SEARCH_MULTI: 13,
  BEGINS_WITH_MULTI: 14,
  SEARCH_MULTI_EXPR: 15,
  OR_GROUP_MULTI_FIELDS: 16,
};

export class FilterHelper {
  static addUpdateQuery(query, field, value) {
    if (typeof value !== 'undefined' && value !== null) {
      query[field] = value;
    }
  }

  static query(query, field, filterType, value, match = true, defaultValue = undefined) {
    value = value || defaultValue;
    if (value) {
      switch (filterType) {
        case FilterType.CONTAINS:
          FilterHelper.contains(query, field, value);
          break;
        case FilterType.EQUALS:
          FilterHelper.equals(query, field, value, match);
          break;
        case FilterType.EQUALS_CI:
          FilterHelper.equalsCI(query, field, value, match);
          break;
        case FilterType.BEGINS_WITH:
          query = FilterHelper.beginsWith(query, field, value);
          break;
        case FilterType.BEGINS_WITH_MULTI:
          query = FilterHelper.beginsWithMulti(query, field, value);
          break;
        case FilterType.ENDS_WITH:
          query = FilterHelper.endsWith(query, field, value);
          break;
        case FilterType.LESS:
          query = FilterHelper.less(query, field, value);
          break;
        case FilterType.GREATER:
          query = FilterHelper.greater(query, field, value);
          break;
        case FilterType.BETWEEN:
          if (value.from || value.to) {
            query = FilterHelper.between(query, field, value);
          }
          break;
        case FilterType.IN:
          query = FilterHelper.in(query, field, value, match);
          break;
        case FilterType.IN_CI:
          query = FilterHelper.inCI(query, field, value, match);
          break;
        case FilterType.ALL:
          query = FilterHelper.all(query, field, value, match);
          break;
        case FilterType.SEARCH:
          query = FilterHelper.search(query, field, value);
          break;
        case FilterType.SEARCH_MULTI:
          query = FilterHelper.searchMulti(query, field, value);
          break;
        case FilterType.SEARCH_MULTI_EXPR:
          query = FilterHelper.searchMultiExpr(query, field, value);
          break;
        case FilterType.OR_GROUP_MULTI_FIELDS:
          query = FilterHelper.orGroupMultiFields(query, field, value);
          break;
      }
    }
  }

  static contains(query, field, value) {
    query[field] = { $regex: value, $options: 'i' };
  }

  static equals(query, field, value, match = true) {
    query[field] = match ? value : { $ne: value };
  }

  static equalsCI(query, field, value, match = true) {
    let regex = new RegExp(['^', value, '$'].join(''), 'i');
    query[field] = match ? regex : { $not: regex };
  }

  static beginsWith(query, field, value) {
    query[field] = { $regex: `^${value}`, $options: 'i' };
  }

  static beginsWithMulti(query, fields, value) {
    let regex = new RegExp(['^', value].join(''), 'i');
    query['$or'] = [];
    fields.forEach(fieldName => {
      let fieldQuery = {};
      fieldQuery[fieldName] = regex;
      query['$or'].push(fieldQuery);
    });
  }

  //TODO Fix $regex now it works like "contains"
  static endsWith(query, field, value) {
    query[field] = { $regex: value, $options: 'i' };
  }

  static less(query, field, value) {
    query[field] = { $lte: value };
  }

  static greater(query, field, value) {
    query[field] = { $gte: value };
  }

  static between(query, field, range) {
    if (!_.isEmpty(range)) {
      let { from: minValue, to: maxValue } = range;
      let rangeQuery = {};

      if (minValue) {
        rangeQuery['$gte'] = minValue;
      }

      if (maxValue) {
        rangeQuery['$lte'] = maxValue;
      }

      query[field] = rangeQuery;
    }
  }

  static in(query, field, value, match = true) {
    query[field] = match ? { $in: value } : { $nin: value };
  }

  static inCI(query, field, values, match = true) {
    var optRegexp: Array<any> = [];
    values.forEach(function (opt) {
      optRegexp.push(new RegExp(opt, 'i'));
    });

    query[field] = match ? { $in: optRegexp } : { $nin: optRegexp };
  }

  static all(query, field, value, match = true) {
    query[field] = match ? { $all: value } : { $not: { $all: value } };
  }

  static search(query, field, value) {
    let regexArray: Array<any> = [];
    if (value.startsWith('+')) {
      regexArray.push('\\');
    }
    regexArray.push(value);
    let regex = new RegExp(regexArray.join(''), 'i');

    // let regex = new RegExp([escape(value)].join(''), 'i');
    query[field] = regex;
  }

  static searchMulti(query, fields, value) {
    let regexArray = ['^'];
    if (value.startsWith('+')) {
      regexArray.push('\\');
    }

    regexArray.push(value);

    let regex = new RegExp(regexArray.join(''), 'i');
    query['$or'] = [];
    fields.forEach(fieldName => {
      let fieldQuery = {};
      fieldQuery[fieldName] = regex;
      query['$or'].push(fieldQuery);
    });
  }

  static searchMultiExpr(query, fields, value) {
    let regexArray = ['^'];
    if (value.startsWith('+')) {
      regexArray.push('\\');
    }

    regexArray.push(value);

    let regex = new RegExp(regexArray.join(''), 'i');
    query['$or'] = [];
    fields.forEach(fieldName => {
      let fieldQuery = {
        $expr: {
          $regexMatch: {
            input: { $toString: `$${fieldName}` },
            regex: regex,
          },
        },
      };
      query['$or'].push(fieldQuery);
    });
  }

  static orGroupMultiFields(query, fields, value) {
    query['$or'] = [];
    fields.forEach(fieldName => {
      let fieldQuery = {};
      fieldQuery[fieldName] = value;
      query['$or'].push(fieldQuery);
    });
  }

  static makeObjectId(id) {
    return id ? new ObjectId(id) : undefined;
  }

  static makeDate(date) {
    return date ? new Date(parseInt(date)) : undefined;
  }

  static getPagingAndSorting(
    requestQuery,
    allowedSortingFields,
    defaultSortField = 'createdAt',
    aggregate = false,
  ) {
    let { page, limit, sortBy, sortOrder } = requestQuery;
    let pagingAndSorting: any = { sort: {} };

    sortBy = sortBy || defaultSortField;
    pagingAndSorting.sort[sortBy] = -1;

    sortOrder = parseInt(sortOrder);
    sortOrder = sortOrder && (sortOrder === 1 || sortOrder === -1) ? sortOrder : -1;

    if (page) {
      page = parseInt(page);
      pagingAndSorting.limit = limit ? parseInt(limit) : 10;
      pagingAndSorting.skip = (page - 1) * pagingAndSorting.limit;
    }

    if (sortBy instanceof Object) {
      pagingAndSorting.sort = sortBy;
    } else if (
      sortBy &&
      allowedSortingFields &&
      allowedSortingFields.some(sort => sort === sortBy)
    ) {
      pagingAndSorting.sort[sortBy] = sortOrder;
    } else {
      pagingAndSorting.sort = {};
      pagingAndSorting.sort[defaultSortField] = sortOrder;
    }

    let queryOptions = pagingAndSorting;
    if (aggregate) {
      queryOptions = [
        {
          $sort: { ...pagingAndSorting.sort, _id: sortOrder },
        },
      ];

      if (pagingAndSorting.skip) {
        queryOptions.push({
          $skip: pagingAndSorting.skip,
        });
      }

      if (pagingAndSorting.limit) {
        queryOptions.push({
          $limit: pagingAndSorting.limit,
        });
      }

      queryOptions = { pipelines: queryOptions, collation: { locale: 'en' } };
    } else {
      queryOptions = { ...queryOptions, collation: { locale: 'en' } };
    }

    return queryOptions;
  }

  static getCursoringPagingAndSorting(
    query,
    requestQuery,
    allowedSortingFields,
    defaultSortField = 'createdAt',
  ) {
    let { sortBy, sortOrder, limit, next, previous } = requestQuery;

    if (!next && next !== '' && !previous && previous !== '') {
      let queryOptions = this.getPagingAndSorting(
        requestQuery,
        allowedSortingFields,
        defaultSortField,
      );
      return { cursoredQuery: query, queryOptions: queryOptions };
    }

    let pagingAndSorting = { sort: {}, limit: 10 };

    sortBy = sortBy || defaultSortField;
    pagingAndSorting.sort[sortBy] = -1;

    pagingAndSorting.limit = limit ? parseInt(limit) : 10;
    pagingAndSorting.limit += 1;

    if (sortBy && allowedSortingFields && allowedSortingFields.some(sort => sort === sortBy)) {
      sortOrder = parseInt(sortOrder);
      sortOrder = sortOrder && (sortOrder === 1 || sortOrder === -1) ? sortOrder : -1;
      pagingAndSorting.sort = _.set({}, sortBy, sortOrder);
    }

    requestQuery.sortBy = sortBy;
    requestQuery.sortOrder = sortOrder;

    if (next === '' || previous === '') {
      return { cursoredQuery: query, queryOptions: pagingAndSorting };
    }

    if (previous && previous.length) {
      pagingAndSorting.sort[sortBy] = -1 * pagingAndSorting.sort[sortBy];
    }

    let op = '';
    let sortingKey = next;
    if (next) {
      op = sortOrder > 0 ? '$gt' : '$lt';
    } else if (previous) {
      sortingKey = previous;
      op = sortOrder > 0 ? '$lt' : '$gt';
    }

    let buff = new Buffer(sortingKey, 'base64');
    let cursorData = JSON.parse(buff.toString('utf8'));
    let cursorQuery = [{}, {}];
    let finalQuery = {};
    cursorQuery[0][sortBy] = {};
    cursorQuery[0][sortBy][op] = cursorData.isDate
      ? new Date(parseInt(cursorData.value))
      : cursorData.value;
    cursorQuery[1][sortBy] = cursorData.isDate
      ? new Date(parseInt(cursorData.value))
      : cursorData.value;
    cursorQuery[1]['_id'] = {};
    cursorQuery[1]['_id'][op] = cursorData.id;

    finalQuery['$and'] = [{ $or: cursorQuery }, query];

    return { cursoredQuery: finalQuery, queryOptions: pagingAndSorting };
  }

  static fulfillPagingData(resultData, requestQuery) {
    let result = { results: [], previous: '', next: '', page: undefined, limit: undefined };
    if (
      requestQuery.next ||
      requestQuery.next === '' ||
      requestQuery.previous ||
      requestQuery.previous === ''
    ) {
      result.results = [];
      result.previous = '';
      result.next = '';
      if (resultData.length) {
        let isPageFulfilled = resultData.length > parseInt(requestQuery.limit);

        if (isPageFulfilled) {
          resultData.pop();
        }

        if (requestQuery.previous) {
          resultData = resultData.reverse();
        }

        result.results = resultData;
        let firstObj = resultData[0];
        let lastObj = resultData[resultData.length - 1];

        let isDate = firstObj[requestQuery.sortBy] instanceof Date;
        let fValue = isDate
          ? firstObj[requestQuery.sortBy].getTime()
          : firstObj[requestQuery.sortBy];
        let lValue = isDate ? lastObj[requestQuery.sortBy].getTime() : lastObj[requestQuery.sortBy];

        if (requestQuery.next == '') {
          if (isPageFulfilled) {
            result.next = new Buffer(
              `{"isDate": ${isDate}, "value": "${lValue}", "id": "${lastObj._id.toString()}"}`,
            ).toString('base64');
          }
        } else if (requestQuery.next) {
          if (isPageFulfilled) {
            result.next = new Buffer(
              `{"isDate": ${isDate}, "value": "${lValue}", "id": "${lastObj._id.toString()}"}`,
            ).toString('base64');
          }
          result.previous = new Buffer(
            `{"isDate": ${isDate}, "value": "${fValue}", "id": "${firstObj._id.toString()}"}`,
          ).toString('base64');
        }

        if (requestQuery.previous) {
          if (isPageFulfilled) {
            result.previous = new Buffer(
              `{"isDate": ${isDate}, "value": "${fValue}", "id": "${firstObj._id.toString()}"}`,
            ).toString('base64');
          }
          result.next = new Buffer(
            `{"isDate": ${isDate}, "value": "${lValue}", "id": "${lastObj._id.toString()}"}`,
          ).toString('base64');
        }
      }
    } else {
      result.results = resultData;
      result.page = requestQuery.page;
      result.limit = requestQuery.limit;
    }

    return result;
  }
}
