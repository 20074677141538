import { useState } from 'react';
import DButton from 'components/DButton';
import areas from 'assets/images/areas.svg';
import styles from './index.module.scss';
import Autocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import DotsAndButton from 'components/DotsAndButton';
import classNames from 'classnames';
import { getAddressFromCoords } from 'api/generic';

const Address = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const [place, setPlace] = useState<{
    fullAddress?: string;
    address?: string;
    postalCode?: string;
    coords?: number[];
  }>({
    fullAddress: masterFlowData.fullAddress
  });
  const navigate = useNavigate();

  const getCurrentPosition = () => {
    navigator.permissions
      .query({
        name: 'geolocation'
      })
      .then(function (result) {
        if (result.state == 'granted' || result.state == 'prompt') {
          navigator.geolocation.getCurrentPosition(
            async success => {
              const addresses = await getAddressFromCoords([
                success.coords.longitude,
                success.coords.latitude
              ]);

              const place = addresses[0];

              let address = place.formatted_address;
              const postalCode = place.address_components.find((x: any) => {
                return x.types.includes('postal_code');
              }) || {
                long_name: '0025'
              };
              if (postalCode) {
                address = place.formatted_address.replace(postalCode.long_name, '');
              }
              keepBecomeMasterData({
                ...masterFlowData,
                coords: [success.coords.longitude, success.coords.latitude],
                fullAddress: place.formatted_address,
                address,
                postalCode: postalCode.long_name
              });
              navigate('/become-a-master/radius');
            },
            err => console.log(err),
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0
            }
          );
        } else if (result.state == 'denied') {
          console.log('denied');
        }
      });
  };

  const selectPlace = (place: any) => {

    let address = place.formatted_address;
    const postalCode = place.address_components.find((x: any) => {
      return x.types.includes('postal_code');
    }) || {
      long_name: '0025'
    };
    if (postalCode) {
      address = place.formatted_address.replace(postalCode.long_name, '');
    }
    setPlace({
      fullAddress: place.formatted_address,
      address,
      postalCode: postalCode.long_name,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  };

  const next = () => {
    keepBecomeMasterData({
      ...masterFlowData,
      ...place
    });
    navigate('/become-a-master/radius');
  };

  return (
    <div className="flex flex-col items-center w-[376px]">
      <div className="w-[120px] h-[120px]">
        <img src={areas} alt="areas" />
      </div>
      <p className={styles.title}>Address</p>
      <p className={styles.txt}>Write your US working address</p>
      <DButton type="white-shadowed" className="w-[376px] h-[54px]" onClick={getCurrentPosition}>
        <div className="flex items-center">
          <i className="icon-2 mr-2 text-lg"></i>Use my current location
        </div>
      </DButton>

      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        onPlaceSelected={selectPlace}
        options={{
          // fields: ['formatted_address', 'geometry', 'name'],
          strictBounds: true,
          types: ['address'],
          componentRestrictions: { country: 'usa' }
        }}
        language="en"
        placeholder="Address"
        libraries={['establishment', 'premise']}
        defaultValue={masterFlowData.fullAddress}
        className={classNames('ant-input mt-2 w-[376px] h-[54px]', styles.address)}
      />
      <DotsAndButton>
        <DButton
          text="Next, Radius"
          disabled={!place.fullAddress}
          className={styles.next}
          type="default"
          onClick={next}
        />
      </DotsAndButton>
    </div>
  );
};

export default Address;
