/** @format */

import axios, { AxiosError } from 'axios';
import {
  NotificationItem,
  SendNotificationRequest,
  SendPushNotificationRequest,
} from '../model/business/notification';
import { StatusError } from '../model/errors';

const notificationServiceConfig: {
  url: string;
  apiKey: string;
} = {
  url: process.env.NOTIFICATION_SERVICE_URL!,
  apiKey: 'testKey',
};

export const sendNotification = async (newNotification: SendNotificationRequest) =>
  postData<NotificationItem>(`/send`, newNotification);

export const sendPushNotification = async (pushNotification: SendPushNotificationRequest) =>
  postData<NotificationItem>(`/send-push-notification`, pushNotification);

interface NotificationApiResponse {}

interface NotificationApiError {
  name: string;
  message: string;
}

const postData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.post<NotificationApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

const putData = async <R>(path: string, data: any, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
    },
  };
  let response;
  try {
    const result = await axios.put<NotificationApiResponse>(getUrl(path), data, {
      ...defaultOptions,
      ...options,
    });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }

  return response as R;
};

export const getData = async <R>(path: string, options: any = {}): Promise<R> => {
  const defaultOptions = {
    headers: {
      accept: 'application/json',
      // authorization: `Bearer ${accessToken}`,
    },
  };

  let response;
  try {
    const result = await axios.get(getUrl(path), { ...defaultOptions, ...options });
    response = result.data as R;
  } catch (error) {
    handleHttpError(error as AxiosError);
  }
  return response as R;
};

const handleHttpError = (error: AxiosError<NotificationApiError>) => {
  if (error.response) {
    throw new StatusError(
      error?.response.status,
      error?.response.data.name,
      error?.response.data.message,
    );
  } else {
    throw new StatusError(500, 'InternalServerError', error.message);
  }
};

const getUrl = (path: string) => `${notificationServiceConfig.url}${path}`;
