import { NotificationFilterRequest, NotificationItem } from "common";
import { FetchMoreNotifications, FetchNotificationsLoadMoreRequest, FetchNotificationsRequest, FETCH_MORE_NOTIFICATIONS, NOTIFICATION_GET_REQUEST, NOTIFICATION_GET_SUCCESS, NOTIFICATION_LOAD_MORE_REQUEST, FetchNotificationsUnseenCountRequest, NOTIFICATION_UNSEEN_GET_REQUEST, NOTIFICATION_MARK_AS_SEEN_REQUEST, FetchNotificationsMarkAsSeenRequest, ADD_NEW_NOTIFICATION, AddNewNotificationPayload, AddNewNotification } from "./types";





export const fetchNotifications= (payload: NotificationFilterRequest): FetchNotificationsRequest => ({
  type: NOTIFICATION_GET_REQUEST,
  payload
});

export const fetchMoreNotifications= (payload: NotificationFilterRequest): FetchNotificationsLoadMoreRequest => ({
  type: NOTIFICATION_LOAD_MORE_REQUEST,
  payload
});

export const fetchUnseenNotificationsCount= (): FetchNotificationsUnseenCountRequest => ({
  type: NOTIFICATION_UNSEEN_GET_REQUEST,
  
});
export const fetchNotificationsMarkAllAsSeen= (): FetchNotificationsMarkAsSeenRequest => ({
  type: NOTIFICATION_MARK_AS_SEEN_REQUEST,  
});

export const addNewNotification = (payload: AddNewNotificationPayload): AddNewNotification => ({
  type: ADD_NEW_NOTIFICATION,
  payload
});



// export const FetchNotificationsFailure = (
//   payload: FetchChannelsFailurePayload
// ): FetchChannelsFailure => ({
//   type: FETCH_CHANNELS_FAILURE,
//   payload
// });


