import styles from './index.module.scss';
import classNames from 'classnames';
import Icon from 'assets/icons/svg-icon-7.svg';
import IconX from 'assets/icons/svg-icon-9.svg';


type DocumentCardType ={
  hasIcon?:boolean;
  hasRemoveAction?:boolean;
  onRemove?:()=> void
  fileId:string;
  classes?:string;
  fileName ?:string | null;
  size?:number;
}

const DocumentCard = ({hasIcon = true,hasRemoveAction=false,onRemove,fileId,fileName,classes='',size}:DocumentCardType) =>{


  const opt = fileName?.split('.')
  return(
    <>

    {opt?.length === 2 && (
        <div key={fileId} className={classNames(styles.container,classes)} >
        <div className='flex items-start'>
            {hasIcon && <>
              <div className={classNames(styles.iconContainer)}>
                  <img className='' src={Icon} alt="icon" />
              </div>
            </>}
            {hasRemoveAction && <>
              <div onClick={onRemove} className={classNames(styles.removeContainer)}>
                  <img className='' src={IconX} alt="icon" />
              </div>
            </>
              
            }

            <div className={classNames(styles.fileNameContent)}>
              <span className={classNames(styles.fileName)}> {opt[0]}.{opt[1]}</span>
              <div className={classNames(styles.size)}>
                {size ?  Math.round(size/1000) : 0} MB
              </div>
            </div>
        </div>
      
  </div>
    )

    }
    
    </>
    
  )

}

export default DocumentCard;
