import { Typography, Col, Row, Space, Input } from 'antd';
import { StringGradients } from 'antd/lib/progress/progress';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { attachMediaToMessage, sendMessage } from 'api/chat';
import { SendChatMessageRequest } from 'common';
import CustomTextArea from 'components/CustomTextArea';
import DButton from 'components/DButton';
import TextWithIcon from 'components/JobPostModal/TextWithIcon';
import UploadFiles from 'components/JobPostModal/UploadFiles';
import { asyncForEach, doUploadFlow } from 'helpers';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ChatState } from 'redux/chat/types';
import uuid from 'react-uuid';
import styles from './index.module.scss';
import classNames from 'classnames';
import CustomInput from 'components/CustomInput';

// import uuid from 'react-uuid';

const { Text } = Typography;

type RerportCompleteJobType = {
  fileList: boolean;
  message: string;
  hoursWorked?: number | null;
};

interface IReportCompleteJob {
  title: string;
  messageTitle: string;
  mediaTitle: string;
  cancelBtnText: string;
  submitBtnText: string;
  messageDefaultValue: string;
  onCancelClick: () => void;
  messagePlacehodler: string;
  acceptBtnClass?: string;
  api: any;
  apiData: {
    id: string;
    messageId: string;
  };
  report: boolean;
}

const MEDIA_TYPES: any = {
  'image/png': 'PHOTO',
  'image/jpeg': 'PHOTO',
  'video/mp4': 'VIDEO',
  'video/quicktime': 'VIDEO',
  'application/pdf': 'DOCUMENT'
};

const ReportCompleteJob = ({
  cancelBtnText,
  submitBtnText,
  mediaTitle,
  messageTitle,
  title,
  messagePlacehodler,
  onCancelClick,
  api,
  apiData,
  messageDefaultValue,
  acceptBtnClass,
  report
}: IReportCompleteJob) => {
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    watch
  } = useForm<RerportCompleteJobType>({
    defaultValues: {
      fileList: false,
      message: messageDefaultValue
    }
  });
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedChannel } = useSelector((state: any): ChatState => state.chat);
  const { message, hoursWorked } = watch();

  const closeModal = () => {
    onCancelClick();
  };
  const handleFileDelete = (itemIndex: number) => {
    const newFileList = fileList.filter((item, index) => itemIndex !== index);
    setFileList(newFileList);
    setValue('fileList', !!newFileList.length);
    trigger('fileList');
  };

  const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);
    setValue('fileList', true);
    trigger('fileList');
  };

  useEffect(() => {
    setValue('fileList', !!fileList.length);
  }, [fileList]);

  const handleSubmitClick = async () => {
    try {
      setLoading(true);
      const groupId = uuid();
      await sendMessage({
        channelId: selectedChannel?._id || '',
        type: 'TEXT',
        message,
        groupId
      });
      const mediaData: {
        channelId: string;
      } & SendChatMessageRequest = {
        channelId: selectedChannel?._id || '',
        message: '',
        type: 'MEDIA',
        groupId
      };

      if (fileList.length) {
        const sentMediaMessage = await sendMessage(mediaData);
        const attachedFiles: { path: string; type: string; name: string | null | undefined }[] = [];
        const chatMessageId = sentMediaMessage._id as string;

        await asyncForEach(fileList, async media => {
          const uploadedFile = await doUploadFlow(
            media,
            MEDIA_TYPES[media.type] || 'DOCUMENT',
            'CHAT',
            sentMediaMessage._id
          );
          attachedFiles.push({
            path: uploadedFile.path,
            type: uploadedFile.type,
            name: media.name
          });
        });

        if (attachedFiles.length) {
          await attachMediaToMessage({
            chatMessageId,
            body: { files: attachedFiles }
          });
        }
      }

      let body: any = { groupId };
      if (
        !report &&
        selectedChannel?.jobContract?.hourlyRate &&
        selectedChannel?.jobContract.hourlyRate > 0
      ) {
        body = { groupId, hoursWorked };
      }

      await api(apiData.id, apiData.messageId, body);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const isBtnDisabled = () => {
    if (report) {
      if (loading || !fileList.length || !message) {
        return true;
      }
    } else {
      console.log(
        'i am here',
        selectedChannel?.jobContract,
        hoursWorked,
        selectedChannel?.jobContract?.issueReported
      );
      if (
        selectedChannel?.jobContract &&
        !selectedChannel?.jobContract.hourlyRate &&
        (loading || (selectedChannel?.jobContract?.issueReported && !fileList.length) || !message)
      ) {
        return true;
      } else if (
        selectedChannel?.jobContract &&
        selectedChannel?.jobContract.hourlyRate &&
        selectedChannel?.jobContract.hourlyRate > 0 &&
        (loading ||
          (selectedChannel?.jobContract?.issueReported && !fileList.length) ||
          !message ||
          !hoursWorked)
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <Col className={styles.container}>
      <Row className="flex justify-center">
        <Text className="text-font-22 font-bold">{title} </Text>
      </Row>
      <Row className="w-full">
        <Col className="w-full">
          <TextWithIcon text={messageTitle} classes="ml-1 mt-10" />
          <Col className="ml-7">
            <Controller
              name="message"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { value, onChange } }) => (
                <CustomTextArea
                  classes="mt-3 h-[110px]"
                  value={value}
                  onChangeValue={onChange}
                  placeholder={messagePlacehodler}
                />
              )}
            />
            <Row justify="start">
              {errors.message && (
                <Text className="text-icon-err ml-5">{errors.message.message}</Text>
              )}
            </Row>
          </Col>
        </Col>
      </Row>
      {!report &&
        selectedChannel?.jobContract?.hourlyRate !== 0 &&
        selectedChannel?.jobContract?.hourlyRate !== undefined && (
          <Row className="flex w-full flex-row">
            <Col className="w-full">
              <TextWithIcon text={'Report hours you have worked ?'} classes="ml-1 mt-10" />
              <Controller
                name="hoursWorked"
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field: { value, onChange } }) => (
                  <Col className="!relative">
                    <Input
                      placeholder="Report hours you have worked"
                      type="number"
                      value={value || undefined}
                      prefix={<div className={classNames({ hidden: true })}>$</div>}
                      onChange={e => {
                        if (/^(\d{0}|\d+)$/.test(e.target.value)) {
                          onChange(e);
                        }
                      }}
                      className={classNames(
                        styles.input,
                        'w-[60%] mt-3 h-[40px] ml-7 pl-4 font-normal'
                      )}
                    />
                  </Col>
                )}
              />
              <Row justify="start" className="ml-11">
                {errors.hoursWorked && (
                  <Text className="text-icon-err">{errors.hoursWorked.message}</Text>
                )}
              </Row>
            </Col>
          </Row>
        )}

      <Row className="w-full">
        <Col className="w-full">
          <TextWithIcon
            icon={!report && !selectedChannel?.jobContract?.issueReported ? false : true}
            text={mediaTitle}
            classes="ml-1 mt-6"
          />

          <Col className="ml-7 mt-3">
            <Controller
              name="fileList"
              control={control}
              rules={{
                validate: {
                  required: value => {
                    if (!value && selectedChannel?.jobContract?.issueReported)
                      return 'This field is required';
                  }
                }
              }}
              render={() => (
                <UploadFiles
                  filesList={fileList}
                  maxUploadedFiles={5}
                  multipleSelect
                  onDeleteHandle={handleFileDelete}
                  onUploadHandle={handleFileUpload}
                  uploadingFilesType={'media'}
                />
              )}
            />
            <Row justify="start">
              {errors.fileList && (
                <Text className="text-icon-err mt-2 ml-5">{errors.fileList.message}</Text>
              )}
            </Row>
          </Col>
        </Col>
      </Row>
      <Row justify="end" className="mt-[40px]">
        <Space size={16}>
          <DButton type="white" className="!w-[148px] !h-[44px]" onClick={closeModal}>
            {cancelBtnText}
          </DButton>
          <DButton
            type="light-green"
            className={`text-font-16 font-bold text-white w-[200px] h-[44px] ${acceptBtnClass}`}
            onClick={handleSubmit(handleSubmitClick)}
            submit
            disabled={isBtnDisabled()}
            loading={loading}
          >
            {submitBtnText}
          </DButton>
        </Space>
      </Row>
    </Col>
  );
};

export default ReportCompleteJob;
