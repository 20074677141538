import { CreateJobRequest } from 'common';
import { useDispatch, useSelector } from 'react-redux';
import { postJobAction } from './actions';
import { jobPostSelector } from './selectors';
import { initialState } from './reducer';

const useJobPost = () => {
  const dispatch = useDispatch();
  const jobData: CreateJobRequest = useSelector(jobPostSelector);

  function changeJobData(jobData: CreateJobRequest) {
    dispatch(postJobAction(jobData));
  }

  function reset() {
    dispatch(postJobAction(initialState));
  }

  return {
    jobData,
    reset,
    changeJobData
  };
};

export default useJobPost;
