import { docUploaded, getPresignedUrl, uploadFile } from 'api/generic';
import { MediaType, PurposeType } from 'common';

export const doUploadFlow = async (
  doc: any,
  fileType: MediaType,
  purpose: PurposeType,
  referenceId?: string
) => {


  const formData = new FormData();
  const { s3Response, uuid } = await getPresignedUrl(doc.type, fileType, purpose, referenceId);

  formData.append('key', s3Response.fields.key);
  formData.append('Content-Type', doc.type);
  formData.append('X-Amz-Credential', s3Response.fields['X-Amz-Credential']);
  formData.append('X-Amz-Algorithm', s3Response.fields['X-Amz-Algorithm']);
  formData.append('X-Amz-Date', s3Response.fields['X-Amz-Date']);
  formData.append('acl', 'public-read');
  formData.append('x-amz-meta-reference-id', s3Response.fields['x-amz-meta-reference-id']);
  formData.append('Policy', s3Response.fields['Policy']);
  formData.append('X-Amz-Signature', s3Response.fields['X-Amz-Signature']);
  formData.append('file', doc.originFileObj || doc);
  formData.append('bucket', s3Response.fields['bucket']);
  await uploadFile(s3Response.url, formData);
  const result = await docUploaded(uuid);
  return result;
};
