import React, { useCallback, useEffect, useState } from 'react';
import FilterInput from 'components/FilterInput';
import CustomInput from 'components/CustomInput';
import Prices from './content/Prices';
import DateRangePicker from './content/DateRangePicker';
import Industry from './content/Industry';
import styles from './index.module.scss';
import SearchPlace from 'components/SearchPlace';
import useDashboard from 'redux/dashboard/use-dashboard';
import { dateStateType } from 'types/types';
import { ATLANTA_LOCATION, SERVICES } from '../../../constants';
import { useDidMountEffect, useEffectAsync } from 'hooks';
import { getServices } from 'api/user';
import { Typography, Row } from 'antd';
import Tab from 'components/Tab';
import { getCurrentPositionFromBrowser } from 'helpers';
import { getAddressFromCoords } from 'api/generic';
import { alert } from 'helpers';
const { Text } = Typography;

const defaultPrices = {
  flatPrice: { min: undefined, max: undefined },
  hourlyRate: { min: undefined, max: undefined }
};

const Filters: React.FC = (): JSX.Element => {
  const { filters, changeFilters } = useDashboard();
  const [prices, setPrices] = useState<{
    flatPrice: { min: string | undefined; max: string | undefined };
    hourlyRate: { min: string | undefined; max: string | undefined };
  }>({ ...defaultPrices });
  const [serachValueInput, setSearchValueInput] = useState( filters?.searchPlace?.placeName || ATLANTA_LOCATION.address);
  const [industryValues, setIndustryValues] = useState(new Array());
  const [industryOptions, setIndustryOptions] = useState<string[]>([]);
  const [pricesStr, setPricesStr] = useState<string>('');
  const applyPrices = () => {
    let hourly = '';
    if (prices.hourlyRate.min && prices.hourlyRate.max) {
      hourly = `${prices.hourlyRate.min}-${prices.hourlyRate.max}/h`;
    } else if (prices.hourlyRate.min && !prices.hourlyRate.max) {
      hourly = `From $${prices.hourlyRate.min}/hour`;
    } else if (prices.hourlyRate.max && !prices.hourlyRate.min) {
      hourly = `Up to $${prices.hourlyRate.max}/hour`;
    }
    let flat = '';
    if (prices.flatPrice.min && prices.flatPrice.max) {
      flat = `${prices.flatPrice.min}-${prices.flatPrice.max}/job`;
    } else if (prices.flatPrice.min && !prices.flatPrice.max) {
      flat = `From $${prices.flatPrice.min}/job`;
    } else if (prices.flatPrice.max && !prices.flatPrice.min) {
      flat = `Up to ${prices.flatPrice.max}/job`;
    }
    changeFilters({ ...filters, prices });
    setPricesStr(`${hourly && `${hourly}`}${hourly && flat && ', '}${flat && `${flat}`}`);
  };

  const findMyPlace = useCallback(async () => {
    try {
      const res = await getCurrentPositionFromBrowser();
      const [lng, lat] = res;

      const addresses = await getAddressFromCoords([lng, lat]);
      let address = addresses[0].formatted_address;
      setSearchValueInput(address);
      changeFilters({
        ...filters,
        searchPlace: {
          placeName: address,
          placeCoords: {
            lng,
            lat
          }
        },
        mapInfo: {
          ...filters.mapInfo,
          center: { lng, lat }
        }
      });
    } catch (err) {
      alert.error(`${err}`);
    }
  }, [filters]);

  const resetPrices = () => {
    setPrices(defaultPrices);
    setPricesStr('');
    changeFilters({ ...filters, prices: defaultPrices });
  };


  const applyIndustry = () => {
    let result = '';
    const length = filters.industries.length;
    if (length && length === 1) {
      result = SERVICES[filters.industries[0]];
    } else if (length > 1 && length < industryOptions.length) {
      result = `${length} selected`;
    } else if (length === industryOptions.length) {
      result = `All selected`;
    }
    return result;
  };

  useEffectAsync(async () => {
    const services = await getServices();
    setIndustryOptions(services);
  }, []);

  useDidMountEffect(() => {
    if(filters.prices){
      setPrices(filters.prices)
    }

    filters?.searchPlace?.placeName && setSearchValueInput(filters.searchPlace.placeName);
  }, [filters]);

  useEffect(()=>{
    applyPrices()
  },[prices])

  const onLocationChangeHandle = (lat: number, lng: number, address: string) => {

  
    changeFilters({
      ...filters,
      searchPlace: {
        placeName: address,
        placeCoords: { lng, lat }
      },
      mapInfo: {
        ...filters.mapInfo,
        center: { lng, lat }
      }
    });
  };

  const onSearchPlaceEnterKeyUp = (e: KeyboardEvent) => {

    if (e.key === 'Enter') {
      if (serachValueInput === '') {
        const { lat, lng, address } = ATLANTA_LOCATION;
        setSearchValueInput(address);
        changeFilters({
          ...filters,
          searchPlace: {
            placeName: address,
            placeCoords: {
              lng,
              lat
            }
          },
          mapInfo: {
            ...filters.mapInfo,
            center: { lng, lat }
          }
        });
      }
    }
  };
  return (
    <div className={styles.filters}>
      <CustomInput
        allowedInput={'string'}
        icon="icon-29"
        placeholder="Search"
        className="mr-2 ml-4 w-[212px]"
        value={filters.search}
        onChange={val => changeFilters({ ...filters, search: val })}
      />
      <SearchPlace
        value={serachValueInput}
        setValue={(val: string) => {
          setSearchValueInput(val);
        }}
        onKeyUpHandle={onSearchPlaceEnterKeyUp}
        onLocationChangeHandle={onLocationChangeHandle}
        findMyPlace={findMyPlace}
        classes="w-[250px] mr-2"
      />
      <FilterInput
        placeholder="Price"
        content={<Prices values={prices} setValue={setPrices} />}
        values={pricesStr}
        apply={applyPrices}
        reset={resetPrices}
      />
      <FilterInput
        placeholder="Industry"
        content={
          <Industry
            options={industryOptions.map(x => ({ label: SERVICES[x], value: x }))}
            appliedValues={filters.industries}
            checkedValues={industryValues}
            setCheckedValues={setIndustryValues}
          />
        }
        values={applyIndustry()}
        apply={() => {
          changeFilters({ ...filters, industries: industryValues });
        }}
        onOutsideClick={() => {
          setIndustryValues(filters.industries);
        }}
        reset={() => {
          setIndustryValues([]);
          changeFilters({ ...filters, industries: [] });
        }}
        className="ml-2"
      />
      <DateRangePicker
        dateValues={filters.dates}
        setDateValues={(val: dateStateType) => changeFilters({ ...filters, dates: val })}
        classes="ml-2 w-[240px]"
      />
      <Row className="ml-auto" align="middle">
        <Text className="text-light-gray mr-4">I'm looking for:</Text>
        <Tab
          data={{
            masters: {
              tab: 'Masters'
            },
            jobs: {
              tab: 'Jobs'
            },
            both: {
              tab: 'Both'
            }
          }}
          activeKey={filters.lookingFor}
          classes="mt-4"
          bottomBorder={false}
          handleTabChange={tab => changeFilters({ ...filters, lookingFor: tab })}
        />
      </Row>
    </div>
  );
};
export default Filters;
