import videoCamera from 'assets/images/video.svg';
import DButton from 'components/DButton';
import DotsAndButton from 'components/DotsAndButton';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from 'redux/user/use-user';
import styles from './index.module.scss';
import classNames from 'classnames';

const VideoUpload = () => {
  const { masterFlowData, keepBecomeMasterData } = useUser();
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [source, setSource] = useState<{ video: any; url: string } | null>(null);
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (masterFlowData.video) {
      const file = masterFlowData?.video;
      const url = URL.createObjectURL(file);
      setSource({
        video: file,
        url
      });
    }
  }, [masterFlowData.video]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (videoRef?.current && videoRef.current.duration > 60) {
  //       setSource(null);
  //       setErr(true);
  //     }
  //   }, 100);
  // }, [source?.url]);

  const next = () => {
    keepBecomeMasterData({
      ...masterFlowData,
      video: source?.video
    });
    navigate('/become-a-master/documents');
    // const formData = new FormData();
    // formData.append('file', 'name');
  };

  const handleFileChange = (event: any) => {
    // setErr(false);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource({
      video: file,
      url
    });
    // if (videoRef.current && videoRef.current.readyState > 0) {
    // if (videoRef.current.duration > 60) {
    // setErr(true);
    // setSource(null);
    // }
    // }
  };

  const handleChoose = () => {
    inputRef?.current?.click();
  };

  return (
    <div className={classNames(['flex flex-col items-center', styles.cont])}>
      <div className={styles.video}>
        {source ? (
          <>
            <video src={source.url} autoPlay={false} />
            <span onClick={() => setSource(null)}>
              <i className="icon-24" />
            </span>
          </>
        ) : (
          <img src={videoCamera} alt="video" />
        )}
      </div>
      <p className={styles.title}>Profile video</p>
      <p className={styles.txt}>
        You can shoot an introduction video of yourself{' '}
        <span className={classNames({ [styles.highlight]: err })}>(max length 1 minute)</span> lorem
        ipsum{' '}
      </p>
      <input
        ref={inputRef}
        className="hidden"
        type="file"
        onChange={handleFileChange}
        accept=".mov,.mp4"
        onClick={event => {
          event.currentTarget.value = '';
        }}
      />
      <DButton
        text="Choose from Gallery"
        type="secondary"
        className={styles.choose}
        onClick={handleChoose}
      />
      <DotsAndButton>
        <DButton
          disabled={!source}
          text="Next, Documents"
          className={styles.next}
          type="default"
          onClick={next}
        />
      </DotsAndButton>
    </div>
  );
};

export default VideoUpload;
