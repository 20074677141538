import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import styles from "./index.module.scss";
import {Col, Modal, Row} from "antd";
import DButton from "../../../DButton";


type AgreeAndContinueType ={
  visible:boolean,
  closeModal:()=> void;
  applayAction:()=> void;
  type:'forJob' | 'firstTime';
}
const AgreeAndContinue = ({visible,closeModal,applayAction,type}:AgreeAndContinueType) => {

  const onCloseModal = () =>{
    if(type !== 'firstTime'){
      closeModal()
    }
  }

  const backgroundColor = type === 'firstTime' ? 'rgba(0,0,0,.5)' :'unset';

  return(

    <Modal className={classNames(styles.modal, ' ')}
           visible={visible}
           footer={false}
           destroyOnClose
           centered
           closeIcon={<></>}
           onCancel={onCloseModal}
           maskStyle={{ background: backgroundColor}}>
      <Row className={classNames(styles.container, '')}>
        <Col span={24} >
          {type === 'forJob' && (
            <div className="text-[#18191A] text-[15px] text-left py-3 ">
              <div  className="text-[#1C4E2F] font-semibold">
                <p className="relative pl-[15px]  before:content-[''] before:bg-[#1C4E2F] before:absolute before:top-[10px] before:rounded-full  before:left-0 before:h-[8px] before:w-[8px]">
                  By using the Doers app, you acknowledge that Doers is solely a platform facilitating connections between individuals and businesses and does not function as an employer or provider of recommendations.
                </p>
                <p className="relative pl-[15px]  before:content-[''] before:bg-[#1C4E2F] before:absolute before:top-[10px] before:rounded-full  before:left-0 before:h-[8px] before:w-[8px]">

                You agree that Doers shall not be held liable for any misconduct, poor performance, or other issues arising from interactions between users.
                </p>
                <p className="relative pl-[15px]  before:content-[''] before:bg-[#1C4E2F] before:absolute before:top-[10px] before:rounded-full  before:left-0 before:h-[8px] before:w-[8px]">

                Doers does not verify or authenticate users’ qualifications or licensing, and it is your sole responsibility to exercise due diligence and conduct your own vetting process prior to engaging in any agreements or hiring.
                </p>
                <p className="relative pl-[15px]  before:content-[''] before:bg-[#1C4E2F] before:absolute before:top-[10px] before:rounded-full  before:left-0 before:h-[8px] before:w-[8px]">

                By using this platform, you agree to indemnify and hold harmless Doers from any and all claims, damages, or other liabilities resulting from any wrongdoing in connection with the use of the platform.
                </p>

              </div>

            </div>
          )}
          {type === 'firstTime' && (
            <div className="text-[#1C4E2F] font-semibold text-[15px] text-center py-3 ">
              By tapping "Agree and continue" you agree with our
              <b> <a href="https://www.doersus.com/terms-and-conditions"> Terms of Conditions</a> </b>
              and
              <b> <a href="https://www.doersus.com/privacy-policy"> Privacy Policy</a></b>.
            </div>
          )}
          <div className="flex justify-center">
              <div className='flex justify-end mt-[10px]'>
                  <>
                  {type !== 'firstTime' && (
                    <DButton
                      type="only-text"
                      className="text-font-12 border border-[#18191A] rounded-[70px] font-bold text-[#18191A] w-[120px] h-[35px]"
                      onClick={closeModal}
                      disabled={false}
                      loading={false}
                    >
                      Cancel
                    </DButton>
                  ) }
                    <DButton
                      onClick={applayAction}
                      type="light-green"
                      className="text-font-12 ml-4 !bg-[rgb(193,40,27) font-bold text-white w-[150px] h-[35px]"
                      disabled={false }
                      loading={false}
                    >
                      Agree and Continue
                    </DButton>
                  </>
              </div>
          </div>
        </Col>
      </Row>
    </Modal>
  )

}

export default AgreeAndContinue;
