import api from './index';
import {
  Profile,
  ProfileDoerUpdateRequest,
  ProfileMasterUpdateRequest,
  UserItem,
  UserPaymentMethod,
  UserResponse,
  UserReviewFilterRequest,
  UserReviewsResponse,
  UserVideoResponse
} from 'common';

import qs from 'qs';

export const getServices = (): Promise<string[]> => {
  return api.get('/users/profile/services');
};

export const updateUserProfile = (data: ProfileMasterUpdateRequest): Promise<any> => {
  return api.put('/users/profile', data);
};

export const skipBecomeAMaster = (): Promise<string> => {
  return api.put(`/users/skip`);
};

export const getPrifileByUserId = (id: string): Promise<Profile> => {
  return api.get(`/users/${id}/profile`);
};
export const updateProfile = (profileData: ProfileMasterUpdateRequest): Promise<UserResponse> => {
  return api.put(`/users/profile`, profileData);
};

export const updateDoerProfile = (profileData: ProfileDoerUpdateRequest): Promise<UserResponse> => {
  return api.put(`/users/doer-profile`, profileData);
};

export const getUserVideosById = (id: string): Promise<UserVideoResponse> => {
  return api.get(`feed/videos-by-user-id/${id}`);
};

export const deleteVideoById = (id: string): Promise<UserVideoResponse> => {
  return api.delete(`feed/${id}`);
};

export const getUserReviewsById = (data: {
  userId: string;
  jsonData: UserReviewFilterRequest;
}): Promise<UserReviewsResponse> => {
  const { userId, jsonData } = data;
  return api.get(`users/${userId}/reviews`, {
    params: jsonData,
    paramsSerializer: jsonData => {
      return qs.stringify(jsonData);
    }
  });
};

export const deleteUserAccount = (userId: string): Promise<string> => {
  return api.delete(`/users/${userId}/profile`);
};
