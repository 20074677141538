/** @format */

import * as yup from 'yup';
import { UserItem } from '../user';
import { JobItemLite } from '../job';
import { JobContractItemLite } from '../job-contract';
import { ChatMessageType } from '../chat';

export const NotificationSendRequestYup = yup.object().shape({
  message: yup
    .string()
    .max(1000)
    .when('type', {
      is: 'TEXT',
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  type: yup.string().oneOf(['TEXT', 'MEDIA', 'SYSTEM']).required(),
  groupId: yup.string().optional(),
});

export type NotificationType = ChatMessageType;

export type NotificationStatus = 'PENDING' | 'PUBLISHED';

export type NotificationItem = {
  _id?: string;
  user: string | UserItem;
  master?: string | UserItem;
  doer?: string | UserItem;
  job?: string | JobItemLite;
  jobContract?: string | JobContractItemLite;
  channel?: string;
  type: NotificationType;
  status: NotificationStatus;
  createdAt?: string;
  seen: boolean;
  seenAt?: string;
};

export type SendNotificationRequest = {
  userId: string;
  masterId?: string;
  doerId?: string;
  jobId?: string;
  jobContractId?: string;
  channelId?: string;
  type: NotificationType;
  sendPushNotification?: boolean;
};

export type NotificationCommonFilterRequest = {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  next?: string;
  previous?: string;
};

export type NotificationFilterRequest = NotificationCommonFilterRequest & {
  user?: string;
  type?: NotificationType;
};

export type PushNotificationMetadata = {
  userId?: string;
  masterId?: string;
  doerId?: string;
  jobId?: string;
  jobContractId?: string;
  channelId?: string;
  jobTitle?: string;
  masterName?: string;
  doerName?: string;
  amount?: number;
  doerRatingStart?: number;
  doerFeedback?: string;
  masterRatingStart?: number;
  masterFeedback?: string;
};

export type SendPushNotificationRequest = {
  pushNotificationId: string;
  type: NotificationType;
  navigateTo: PushNotificationNavigation;
  metadata?: PushNotificationMetadata;
};

export type PushNotificationNavigation = 'CHAT' | 'JOB' | 'PROFILE';

export type PushNotificationContent = {
  headings: {
    en: string;
    es?: string;
  };
  contents: {
    en: string;
    es?: string;
  };
};

export const NOTIFICATION_TEXTS = {
  MASTER_APPLY_JOB: {
    title: 'Master applied for your job!',
    text: `Master applied for your {{jobTitle}} job. Review master’s profile and respond to their offer.`,
  },
  DOER_INVITE_TO_JOB: {
    title: 'You’ve got a job offer!',
    text: `You have been invited to the {{jobTitle}} job.  Please review offer and proceed`,
  },
  MASTER_APPLY_JOB_EDITED: {
    title: 'Master applied with a new price!',
    text: `Please review new offer for your {{jobTitle}} and proceed.`,
  },
  DOER_INVITE_TO_JOB_EDITED: {
    title: 'You’ve got a job offer with a new price!',
    text: `Please review new offer for {{jobTitle}} and proceed.`,
  },
  MASTER_CANCELED_OFFER: {
    title: 'Master cancelled the offer!',
    text: 'Please review and continue looking for masters.',
  },
  DOER_CANCELED_OFFER: {
    title: 'Offer is cancelled!',
    text: 'Continue looking for jobs.',
  },
  MASTER_INVITATION_ACCEPTED: {
    title: 'Master accepted the offer!',
    text: 'You’ll be notified when Master starts working.',
  },
  DOER_APPLY_JOB_ACCEPTED: {
    title: 'Job owner accepted the offer!',
    text: 'Change the status once you start working on the job.',
  },

  MASTER_INVITATION_REJECTED: {
    title: 'Master rejected the offer!',
    text: 'Please review and continue looking for masters.',
  },

  DOER_APPLY_JOB_REJECTED: {
    title: 'Offer is rejected!',
    text: 'Continue looking for jobs.',
  },
  MASTER_JOB_STARTED: {
    title: 'Work is started!',
    text: 'You’ll be notified when Master finishes his work.',
  },
  MASTER_JOB_RESUMED: {
    title: 'Work is Resumed!',
    text: 'You’ll be notified when Master finishes his work.',
  },

  MASTER_JOB_MARKED_AS_COMPLETED: {
    title: 'Job is completed!',
    text: `{{masterName}} finished the job, please review and accept.`,
  },
  DOER_JOB_COMPLETION_APPROVED: {
    title: 'Work is approved!',
    text: 'You’ll be paid for it shortly.',
  },
  DOER_JOB_COMPLETION_REPORT_ISSUE: {
    title: 'Issue is reported!',
    text: 'Please review the reported issue(s) and respond.',
  },
  MASTER_JOB_COMPLETION_REPORT_ISSUE_ACCEPTED: {
    title: 'Issue is accepted!',
    text: 'You’ll be notified when he starts/resumes working.',
  },

  MASTER_JOB_COMPLETION_REPORT_ISSUE_DECLINED: {
    title: 'Issue is declined!',
    text: 'Please review again and respond.',
  },
  DOER_JOB_COMPLETION_REPORT_ISSUE_CANCELED: {
    title: 'Issue is cancelled and work is approved ',
    text: 'You’ll be paid for it shortly.',
  },
  DOER_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN: {
    title: 'Job was not approved and passed to Admin for review',
    text: 'Admins will review your case and contact you shortly.',
  },
  DOER_JOB_PAID: {
    title: 'You have been paid!',
    text: '{{AMOUNT}} has been transferred to your Paypal account.',
  },
  DOER_JOB_TIPPED: {
    title: 'Tip is added',
    text: 'You’ll receive a payment shortly.',
  },
  DOER_JOB_TIP_FAILED_FINAL_ATTEMPTS: {
    title: 'Tip transfer from your account is failed!',
    text: 'You’ll receive a payment shortly.',
  },
  DOER_JOB_TIP_FAILED_ATTEMPTS_1: {
    title: 'Tip transfer from your account is failed!',
    text: 'We’re not able to proceed the contract based payment. Master is notified.',
  },
  DOER_JOB_TIP_FAILED_ATTEMPTS_2: {
    title: 'Tip transfer from your account is failed!',
    text: 'We’re not able to proceed the contract based payment.',
  },

  DOER_JOB_PAYMENT_FAILED_FINAL_ATTEMPTS: {
    title: 'The contract based Payment is failed!',
    text: 'We’re not able to proceed the contract based payment. Master is notified.',
  },
  DOER_JOB_PAYMENT_FAILED_ATTEMPTS_1: {
    title: 'The first Payment attempt is failed!',
    text: 'We’re not able to proceed the contract based payment.',
  },
  DOER_JOB_PAYMENT_FAILED_ATTEMPTS_2: {
    title: 'The second Payment attempt is failed!',
    text: 'We’re not able to proceed the contract based payment.',
  },
  SYSTEM_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN: {
    title: 'SYSTEM_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN',
    text: 'SYSTEM_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN',
  },
  DOER_FEEDBACK_LEFT: {
    title: 'You have received {{doerRatingStart}}⭐️ from {{doerName}!',
    text: 'DOER_FEEDBACK_LEFT',
  },
  MASTER_FEEDBACK_LEFT: {
    title: 'You have received {{masterRatingStart}}⭐️ from {{masterName}}!',
    text: 'MASTER_FEEDBACK_LEFT',
  },
};

export type UnseenNotificationCountResponse = {
  unseenCount: number;
};
