import { useRef, useState } from 'react';
import { Col, Divider, Modal, Row, Space, Typography } from 'antd';
import vid from 'assets/images/vdo.png';
import styles from './index.module.scss';
import classNames from 'classnames';
import ServicesInfo from '../ServicesInfo';
import DButton from 'components/DButton';
import ProfileInfo from '../ProfileInfo';
import { JobItem } from 'common';
import { useEffectAsync } from 'hooks';
import { getJob, reportJob } from 'api/jobs';
import { showEndStartDate, showJobPrices, showPostedDate, toFixedUserRating } from 'helpers';
import LabelData from '../LabelData';
import Video from 'components/Video';
import { useDispatch } from 'react-redux';
import { GALLERY_MODAL_OPEN, OPEN_JOB_APPLY_MODAL } from 'redux/modals/actionTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { alert } from 'helpers';
import useUser from 'redux/user/use-user';
import { setRedirectedChanelId } from 'redux/chat/actions';
import _ from 'lodash';
import ReportIcon from 'assets/icons/report.svg';
import TextWithIcon from 'components/JobPostModal/TextWithIcon';
import CustomTextArea from 'components/CustomTextArea';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import {setLastAction} from "../../../../redux/general/actions";


const { Text } = Typography;

const JobView = () => {
  const dispatch = useDispatch();
  const { user } = useUser();

  const location = useLocation();
  const navigate = useNavigate();

  const { state: locationState }: any = location;

  const [job, setJob] = useState<JobItem | undefined>();
  const channelIdRef = useRef<string | null>(null);
  const [isReported, setIsReported] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
    watch
  } = useForm<any>({
    defaultValues: {
      comment: '',
    }
  });

  useEffectAsync(async () => {
    try {
      const job = await getJob(locationState.job);
      setJob(job);
    } catch (error) {
      alert.error('Job not found.');
      navigate('/explore');
    }
  }, []);

  const showDates = () => {
    const { start, end, both } = showEndStartDate(job?.startDate, job?.endDate);
    if (both) {
      return <LabelData classes="mb-3" label="Date:" value={both} />;
    }
    if (start) {
      return <LabelData classes="mb-3" label="Start date:" value={start} />;
    }
    if (end) {
      return <LabelData classes="mb-3" label="End date:" value={end} />;
    }
  };

  const openGalleryModal = (activeItem: string | undefined) => {
    dispatch({
      type: GALLERY_MODAL_OPEN,
      payload: {
        photos: job?.photos,
        videos: [job?.video?.original],
        activeItem
      }
    });
  };

  const handleApplyFlow = (item: any) => {
    if (user._id) {
      if (user.profileType !== 'MASTER') {
        dispatch(setLastAction({action:`/explore/job/${job?._id}`,data:{
            state: {
            job: job?._id
          }
          },
          type:'navigate'
        }));
        navigate('/become-a-master/steps', {
          state: {
            from: location.pathname
          }
        });
      } else {
        dispatch({
          type: OPEN_JOB_APPLY_MODAL,
          payload: {
            show: true,
            jobId: item._id,
            pricePerHour: item.hourlyRate,
            pricePerJob: item.flatPrice
          }
        });
      }
    } else {
      dispatch({
        type: 'OPEN_LOGIN_MODAL',
        payload: {
          show: true
        }
      });
    }
  };


  const isJobActive = ()=>{
    let flag = false;
    if(!_.isEmpty(user)){
      user?.activeJobs.forEach((item:{jobId: string, jobContractId: string, channelId: string} )=> {
        if(item.jobId  === job?._id){
          channelIdRef.current = item.channelId
          flag = true
        }
      })
    }

    return flag
  }

const onNavigateToChatPage = () =>{

  dispatch(
    setRedirectedChanelId({
      redirectedChanelId:channelIdRef.current
    })
  );
     navigate('/chat')
}


const closeReportModal = () =>{
  setShowModal(false)

}
const openReportModal = () =>{
    setShowModal(true)
}

  const onSubmitHandle = async (comment:{comment:string}) => {
    try {
      try{
        if(job){
         const result =   await reportJob(job._id || '',comment);
          alert.success('Thank you for notifying us. We will review your report shortly.');
          setIsReported(true);
          setShowModal(false);
          reset({
            comment:'',
          });

        }
      }catch(e){
          console.error(e)
      }

    }catch (e) {

    }
  }

  return (
    <Col>
      <Row>
        <Col>
          <Row>
            <Video
              thumb={process.env.REACT_APP_MEDIA_BASE_URL + job?.video?.thumbnail}
              size="lg"
              onClick={() => openGalleryModal(job?.video?.original)}
            />
          </Row>
          <Row className={styles.imgContainer}>
            {(job?.photos || []).map((item, key) => (
              <Col
                className="cursor-pointer"
                key={key.toString()}
                onClick={() => openGalleryModal(item)}
              >
                <div className={classNames(styles.imgItem)}>
                  <img src={process.env.REACT_APP_S3_BUCKET + item} alt="" />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col className="ml-6 flex-1 flex flex-col">
          <Row>
            <Col>
              {/* TODOFRONT change 'title' to 'bestMatch' prop when BE will be ready */}
              {job?.title && (
                <Row align="middle">
                  <div className={classNames(styles.iconContainer, styles.blue)}>
                    <i className="icon-26 text-font-12 text-white" />
                  </div>
                  <Text className="font-bold text-light-gray ml-2">Best Match</Text>
                </Row>
              )}
              <Text className="font-bold font-doers-black text-font-22 mt-5 block leading-26px">
                {job?.title}
              </Text>
              {/* // TODOFRONT remove all conditions(?) after job when loading functionality will be rady */}
              <ServicesInfo data={(job?.services || []).map(item => item)} />
              <div className="mt-6">
                <LabelData
                  classes={classNames('mb-3', styles.labelData, 'items-start')}
                  label="Location:"
                  value={job?.address}
                />
                <Text>{showDates()}</Text>
                <LabelData
                  classes={classNames('mb-3', styles.labelData, 'items-start')}
                  label="Job posted:"
                  value={showPostedDate(job?.createdAt)}
                />
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Text className="text-font-22 font-bold mr-4 mt-3">
              {showJobPrices({ hourly: job?.hourlyRate, flat: job?.flatPrice, long: true })}
            </Text>
            {job?.pro && (
              <Row align="middle" className="mt-3">
                <div className={classNames(styles.iconContainer, styles.orange)}>
                  <i className="icon-25 text-font-12 text-white" />
                </div>
                <Text className="font-bold text-light-gray ml-2">Pro Account Needed</Text>
              </Row>
            )}
          </Row>
          <Row className="mt-4">
            <Col className="flex-1">
              {isJobActive() ? <DButton
                type="light-green"
                onClick={onNavigateToChatPage}
                className={styles.applyButton}
              >
                <Text className="font-bold text-white">View offer</Text>
              </DButton>:<DButton
                type="light-green"
                onClick={() => handleApplyFlow(job)}
                className={styles.applyButton}
              >
                <Text className="font-bold text-white">Apply for job</Text>
              </DButton>}

              <Divider className="mt-4 mb-0" />
              {job?.description && (
                <>
                  <Text className="font-bold mt-4 block">Description</Text>
                  <Text className="font-doers-black block text-font-16">{job.description}</Text>
                  <Divider className="mt-3 mb-0" />
                </>
              )}
              <Text className="font-bold mt-3 mb-4 block">Created by</Text>
              <ProfileInfo
                data={{
                  profileImage: vid,
                  fullName: `${job?.userData?.firstName} ${job?.userData?.lastName}`,
                  rating: toFixedUserRating(job?.userData?.masterReviewAvg),
                  reviews: job?.userData?.masterReviews,
                  jobsCompleted: job?.userData?.jobsCompleted
                }}
                size="sm"
              />



              <div  className={classNames(styles.reportJobBlock)}>

                               <DButton
                                type="secondary"
                                className="text-font-16  text-[#D30000] w-[150px] h-[30px]"
                                onClick={openReportModal}>
                                  <>
                                  <img className="h-[16px] w-[16px] mr-1" src={ReportIcon} alt="icon" />
                                  <Text className="font-bold text-[#D30000]">Report job</Text>
                                  </>
                              </DButton>
                                      
                                      
               </div>
              {/* {!_.isEmpty(user) && !isReported ? (
                                    <div onClick={openReportModal} className={classNames(styles.reportJobBlock)}>
                                    <img className="h-[16px] w-[16px] mr-1" src={ReportIcon} alt="icon" />

                                      Report job</div>
              ):(
                <div  className={classNames(styles.reportJobBlock)}>Job is reported</div>
              )
              } */}
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        className={styles.applyModal}
        visible={showModal}
        footer={false}
        destroyOnClose
        centered
        closable={false}
        onCancel={closeReportModal}
      >
         <Col className={styles.container}>
      <Row className="flex justify-center">
        <Text className="text-font-22 font-bold">Report Job</Text>
      </Row>

            <Row className="w-full">
              <Col className="w-full">
          <Col className="">
            <Controller
              name="comment"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { value, onChange } }) => (
                <CustomTextArea
                  classes="mt-3 h-[110px]"
                  value={value}
                  onChangeValue={onChange}
                  placeholder={'Pleace specify report reason'}
                />
              )}
            />
            <Row justify="start">
              {errors && errors.comment && (
                <Text className="text-icon-err ml-5"></Text>
              )}
            </Row>
          </Col>

            </Col>
            </Row>
            <Row justify="end" className="mt-[40px]">
                  <Space size={16}>
                          <DButton type="white" className="!w-[148px] !h-[44px]" onClick={closeReportModal}>
                            Cancel
                          </DButton>

                          <DButton
                            type="light-green"
                            className="text-font-16 font-bold text-white w-[150px] h-[44px]"
                            onClick={handleSubmit(onSubmitHandle)}
                            submit
                          >
                          Report
                          </DButton>
                  </Space>
      </Row>
       </Col>

      </Modal>
    </Col>
  );
};

export default JobView;
