import * as types from './types';

const initialState = {
  filters: {
    search: '',
    sortBy: 'Rating',
    prices: {
      flatPrice: { min: undefined, max: undefined },
      hourlyRate: { min: undefined, max: undefined }
    },
    industries: [],
    dates: [null, null],
    lookingFor: 'masters'
  }
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_CHANGE_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    default:
      return state;
  }
};

export default dashboardReducer;
