import { Col, Input, Row, Typography } from 'antd';
import styles from './Prices.module.scss';

const { Text } = Typography;

type PricesProps = {
  values: {
    flatPrice: { min: string | undefined; max: string | undefined };
    hourlyRate: { min: string | undefined; max: string | undefined };
  };
  setValue: React.Dispatch<React.SetStateAction<any>>;
};

const Prices: React.FC<PricesProps> = ({ values, setValue }): JSX.Element => {

  return (
    <div className={styles.prices}>
      <Text className="block font-bold text-base mb-6">Price</Text>
      <Row className="mb-2">
        <Col span={24} className="flex items-center">
          <i className="icon-19 mr-2" />
          <Text>Hourly Rate</Text>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Input
            onChange={e =>
              setValue((prev: any) => {
                return {
                  ...prev,
                  hourlyRate: {
                    min: e.target.value,
                    max: prev.hourlyRate.max
                  }
                };
              })
            }
            value={values?.hourlyRate?.min}
            placeholder="Min"
            type="number"
            className={styles.input}
            onWheel={event => event.currentTarget.blur()}
            addonBefore="$"
          />
        </Col>
        <Col className={styles.line}>-</Col>
        <Col>
          <Input
            onChange={e =>
              setValue((prev: any) => {
                return {
                  ...prev,
                  hourlyRate: {
                    min: prev.hourlyRate.min,
                    max: e.target.value
                  }
                };
              })
            }
            value={values?.hourlyRate?.max}
            placeholder="Max"
            type="number"
            className={styles.input}
            onWheel={event => event.currentTarget.blur()}
            addonBefore="$"
          />
        </Col>
      </Row>
      <Row className="mt-6 mb-2">
        <Col span={24} className="flex items-center">
          <i className="icon-17 text-font-18 mr-2" />
          <Text>Flat Price</Text>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Input
            onChange={e =>
              setValue((prev: any) => {
                return {
                  ...prev,
                  flatPrice: {
                    min: e.target.value,
                    max: prev.flatPrice.max
                  }
                };
              })
            }
            value={values?.flatPrice?.min}
            placeholder="Min"
            type="number"
            className={styles.input}
            onWheel={event => event.currentTarget.blur()}
            addonBefore="$"
          />
        </Col>
        <Col className={styles.line}>-</Col>
        <Col>
          <Input
            onChange={e =>
              setValue((prev: any) => {
                return {
                  ...prev,
                  flatPrice: {
                    min: prev.flatPrice.min,
                    max: e.target.value
                  }
                };
              })
            }
            value={values?.flatPrice?.max}
            placeholder="Max"
            type="number"
            className={styles.input}
            onWheel={event => event.currentTarget.blur()}
            addonBefore="$"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Prices;
