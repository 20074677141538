import DButton from 'components/DButton';
import RegContainer from '../Containers/RegContainer';
import CheckMarkImage from 'assets/images/checkmark.svg';
import OrDivider from 'components/OrDivider';
import { useNavigate } from 'react-router-dom';
import { skipBecomeAMaster } from 'api/user';

const EmailVerified = () => {
  const navigate = useNavigate();
  const skipAndExplore = async () => {
    await skipBecomeAMaster();
    navigate('/explore');
  };

  return (
    <RegContainer>
      <div className="flex flex-col justify-center items-center w-[530px] mt-[100px]">
        <div className="flex flex-col items-center justify-center">
          <img src={CheckMarkImage} alt="CheckMarkImage" />
          <p className="text-base text-doers-black font-bold mb-8 mt-6 text-center">
            The Email was successfully verified.
            <br /> You Doer profile is active and you can post jobs
          </p>
        </div>
        <DButton
          text="Explore"
          className="w-[216px]"
          size="md"
          type="default"
          onClick={skipAndExplore}
        />
        <div className="w-[376px] mt-12 mb-8">
          <OrDivider text="and" />
        </div>
        <p className="text-base text-doers-black font-bold mb-8 mt-6 text-center">
          If you are interested to apply for jobs you need to Become Master
        </p>
        <DButton text="Become a Master" size="sm" type="white" goTo="/become-a-master/steps" />
      </div>
    </RegContainer>
  );
};

export default EmailVerified;
