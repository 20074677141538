import * as types from './types';

const initialState = {
  userTempRegData: {},
  emailForUserReset: '',
  codeForUserReset: '',
  userData: {},
  userProfile: {},
  userVideos: [],
  userReviews: [],
  isLoading: false,
  myJobs: [],
  userPaymentsMethods: [],
  userPayInMethods: [],
  isFaild: true,
  isAccountDeleted: false,
  becomeMasterFlowData: {
    introduction: null,
    services: null,
    prices: null,
    scalability: null,
    video: null,
    documents: null,
    accountType: null
  },
  paymentsMethodsAmount: {
    totalInAmount: 0,
    totalOutAmount: 0
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_KEEP_REGISTER_DATA:
      return {
        ...state,
        userTempRegData: action.payload
      };
    case types.USER_KEEP_EMAIL_FOR_RESET:
      return {
        ...state,
        emailForUserReset: action.payload
      };
    case types.USER_KEEP_CODE_FOR_RESET:
      return {
        ...state,
        codeForUserReset: action.payload
      };
    case types.KEEP_USER:
      return {
        ...state,
        userData: action.payload
      };
    case types.KEEP_BECOME_MASTER_DATA:
      return {
        ...state,
        becomeMasterFlowData: action.payload
      };

    case types.GET_PROFILE_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.GET_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        isLoading: false
      };

    case types.GET_PROFILE_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case types.USER_SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isFaild: true
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        isLoading: false,
        isFaild: false
      };
    case types.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        isFaild: true
      };
    case types.UPDATE_DOER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isFaild: true
      };
    case types.UPDATE_DOER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        isLoading: false,
        isFaild: false
      };
    case types.UPDATE_DOER_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        isFaild: true
      };

    case types.GET_USER_VIDEOS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.GET_USER_VIDEOS_SUCCESS:
      return {
        ...state,
        userVideos: action.payload.items,
        isLoading: false
      };

    case types.GET_USER_REVIEWS_REQUEST:
      return {
        ...state,
        userReviews: [],
        isLoading: true
      };

    case types.GET_USER_REVIEWS_SUCCESS:
      return {
        ...state,
        userReviews: action.payload.items,
        isLoading: false
      };

    case types.GET_LOGGED_IN_USER_JOBS_REQUSET:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_LOGGED_IN_USER_JOBS_SUCCESS:
      return {
        ...state,
        myJobs: action.payload.items,
        isLoading: false
      };

    case types.GET_USER_PAYMENTS_METHODS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.GET_USER_PAYMENTS_METHODS_SUCCESS:
      return {
        ...state,
        userPaymentsMethods: action.payload,
        isLoading: false
      };

    case types.GET_USER_PAYMENTS_METHODS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case types.GET_USER_PAYIN_METHODS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_USER_PAYIN_METHODS_SUCCESS:
      return {
        ...state,
        userPayInMethods: action.payload,
        isLoading: false
      };

    case types.GET_USER_PAYIN_METHODS_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case types.DELETE_PAYMENTS_METHOD_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.DELETE_PAYMENTS_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case types.SET_PAYMENT_METHOD_DEFAULT_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.SET_PAYMENT_METHOD_DEFAULT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case types.DELETE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.DELETE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAccountDeleted: true
      };
    case types.GET_TOTAL_PAYMENT_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_TOTAL_PAYMENT_SUCCESS:
      return { ...state, isLoading: false, paymentsMethodsAmount: action.payload };

    default:
      return state;
  }
};

export default userReducer;
