import axios from 'axios';
import { alert } from 'helpers';

export const axiosApiConfig = {
  baseURL: `${process.env.REACT_APP_BACK_URL}/api`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
};

const logoutStatusCodes = [401, 403];
const logoutExcludedURLs = ['/auth/login'];

const api = axios.create(axiosApiConfig);

api.interceptors.response.use(
  response => response.data,
  error => {
    if (
      logoutStatusCodes.includes(error.response.status) &&
      !logoutExcludedURLs.includes(error.config.url)
    ) {
      if (error.response.data.name === 'JWT_TOKEN_EXPIRED') {
        localStorage.removeItem('token');
        window.location.href = '/auth/login';
      }
    } else {
      alert.error(error.response?.data?.message);
    }
    return Promise.reject(error.response.data);
  }
);

api.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token');
    request.headers = {
      ...(token && { 'x-access-token': token })
    };
    return request;
  },
  error => Promise.reject(error)
);

export default api;
