import { all, takeLatest, fork, put, call } from 'redux-saga/effects';
import {
  deleteVideoById,
  getPrifileByUserId,
  getUserReviewsById,
  getUserVideosById,
  updateDoerProfile,
  updateProfile
} from 'api/user';

import * as types from './types';
import {
  JobFilterRequest,
  ProfileMasterUpdateRequest,
  JobsFilterResponse,
  Profile,
  UserItem,
  UserPaymentMethod,
  UserResponse,
  UserReviewFilterRequest,
  UserReviewsResponse,
  UserVideoFilterRequest,
  UserVideoResponse,
  PaymentMethodPurpose,
  ProfileDoerUpdateRequest
} from 'common';

import { getMyAllJobs } from 'api/jobs';
import {
  deletePaymentMethod,
  getTotalPayments,
  getUserPayInMethods,
  getUserPaymentMethods,
  setPaymentMethodDefault
} from 'api/transactions';
import { deleteUserAccount } from 'api/user';

function* getAuthUser() {
  try {
    const data = '';
    // const { data } = yield usersApi.get('/me'); // api
    yield put({
      type: types.USER_GET_AUTH_USER_SUCCESS,
      payload: data
    });
  } catch (e: any) {
    yield put({
      type: types.USER_GET_AUTH_USER_FAILURE,
      payload: e?.response?.data
    });
  }
}
function* getUserByIdSaga(action: { type: string; payload: { userId: string } }) {
  try {
    const { userId } = action.payload;
    const response = (yield call(getPrifileByUserId, userId)) as UserResponse;
    yield put({
      type: types.GET_PROFILE_BY_ID_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: types.GET_PROFILE_BY_ID_FAILED
      //payload: e?.response?.data
    });
  }
}

function* updateProfileSaga(action: {
  type: string;
  payload: { profileData: ProfileMasterUpdateRequest };
}) {
  try {
    const { profileData } = action.payload;
    const response = (yield call(updateProfile, profileData)) as UserResponse;

    yield put({
      type: types.UPDATE_PROFILE_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_PROFILE_FAILED
      //payload: e?.response?.data
    });
  }
}

function* updateDoerProfileSaga(action: {
  type: string;
  payload: { profileData: ProfileDoerUpdateRequest };
}) {
  try {
    const { profileData } = action.payload;
    const response = (yield call(updateDoerProfile, profileData)) as UserResponse;

    yield put({
      type: types.UPDATE_DOER_PROFILE_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_DOER_PROFILE_FAILED
      //payload: e?.response?.data
    });
  }
}

function* getUserVideosSaga(action: { type: string; payload: UserVideoFilterRequest }) {
  try {
    const { userId } = action.payload;
    const response = (yield call(getUserVideosById, userId)) as UserVideoResponse;
    yield put({
      type: types.GET_USER_VIDEOS_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}
function* getUserReviewsSaga(action: {
  type: string;
  payload: { userId: string; jsonData: UserReviewFilterRequest };
}) {
  try {
    const { userId, jsonData } = action.payload;
    const response = (yield call(getUserReviewsById, { userId, jsonData })) as UserReviewsResponse;
    yield put({
      type: types.GET_USER_REVIEWS_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}

function* getLoggedInUserJobsSaga(action: { type: string; payload: JobFilterRequest }) {
  try {
    const response = (yield call(getMyAllJobs, action.payload)) as JobsFilterResponse;
    yield put({
      type: types.GET_LOGGED_IN_USER_JOBS_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}

function* deleteVideoByIdSaga(action: { type: string; payload: { id: string } }) {
  try {
    const { id } = action.payload;
    const response = (yield call(deleteVideoById, id)) as UserResponse;
    yield put({
      type: types.DELETE_VIDEO_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}

function* getUserPaymentsMethodsSaga(action: { type: string; payload: JobFilterRequest }) {
  try {
    const response = (yield call(getUserPaymentMethods)) as UserPaymentMethod[];
    yield put({
      type: types.GET_USER_PAYMENTS_METHODS_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.GET_USER_PAYMENTS_METHODS_FAILED
    });
  }
}

function* getUserPayInMethodsSaga(action: { type: string; payload: any }) {
  try {
    const response = (yield call(getUserPayInMethods)) as UserPaymentMethod[];
    yield put({
      type: types.GET_USER_PAYIN_METHODS_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: types.GET_USER_PAYIN_METHODS_FAILED
    });
  }
}

function* deletePaymentMethodSaga(action: {
  type: string;
  payload: { id: string; purpose: PaymentMethodPurpose };
}) {
  try {
    const { id, purpose } = action.payload;
    const response = (yield call(deletePaymentMethod, id)) as string;
    if (purpose === 'PAYOUT') {
      const paymentMethods = (yield call(getUserPaymentMethods)) as UserPaymentMethod[];
      yield put({
        type: types.GET_USER_PAYMENTS_METHODS_SUCCESS,
        payload: paymentMethods
      });
    } else if (purpose === 'PAYIN') {
      const payInMethods = (yield call(getUserPayInMethods)) as UserPaymentMethod[];
      yield put({
        type: types.GET_USER_PAYIN_METHODS_SUCCESS,
        payload: payInMethods
      });
    }

    yield put({
      type: types.DELETE_PAYMENTS_METHOD_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}

function* setPaymentDefaultSaga(action: {
  type: string;
  payload: { id: string; purpose: PaymentMethodPurpose };
}) {
  try {
    const { id, purpose } = action.payload;
    const response = (yield call(setPaymentMethodDefault, action.payload)) as string;
    if (purpose === 'PAYOUT') {
      const paymentMethods = (yield call(getUserPaymentMethods)) as UserPaymentMethod[];
      yield put({
        type: types.GET_USER_PAYMENTS_METHODS_SUCCESS,
        payload: paymentMethods
      });
    } else if (purpose === 'PAYIN') {
      const payInMethods = (yield call(getUserPayInMethods)) as UserPaymentMethod[];
      yield put({
        type: types.GET_USER_PAYIN_METHODS_SUCCESS,
        payload: payInMethods
      });
    }

    yield put({
      type: types.SET_PAYMENT_METHOD_DEFAULT_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}

function* deleteUserAccountSaga(action: { type: string; payload: { userId: string } }) {
  try {
    const { userId } = action.payload;
    const response = (yield call(deleteUserAccount, userId)) as string;
    yield put({
      type: types.DELETE_USER_ACCOUNT_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}
function* getTotalPaymentSaga(action: { type: string }) {
  try {
    const response = (yield call(getTotalPayments)) as string;
    yield put({
      type: types.GET_TOTAL_PAYMENT_SUCCESS,
      payload: response
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchAuthUser() {
  yield takeLatest(types.USER_GET_AUTH_USER, getAuthUser);
  yield takeLatest(types.GET_PROFILE_BY_ID_REQUEST, getUserByIdSaga);
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfileSaga);
  yield takeLatest(types.UPDATE_DOER_PROFILE_REQUEST, updateDoerProfileSaga);
  yield takeLatest(types.GET_USER_VIDEOS_REQUEST, getUserVideosSaga);
  yield takeLatest(types.DELETE_VIDEO_REQUEST, deleteVideoByIdSaga);
  yield takeLatest(types.GET_USER_REVIEWS_REQUEST, getUserReviewsSaga);
  yield takeLatest(types.GET_LOGGED_IN_USER_JOBS_REQUSET, getLoggedInUserJobsSaga);
  yield takeLatest(types.GET_USER_PAYMENTS_METHODS_REQUEST, getUserPaymentsMethodsSaga);
  yield takeLatest(types.DELETE_PAYMENTS_METHOD_REQUEST, deletePaymentMethodSaga);
  yield takeLatest(types.DELETE_USER_ACCOUNT_REQUEST, deleteUserAccountSaga);
  yield takeLatest(types.GET_TOTAL_PAYMENT_REQUEST, getTotalPaymentSaga);
  yield takeLatest(types.GET_USER_PAYIN_METHODS_REQUEST, getUserPayInMethodsSaga);
  yield takeLatest(types.SET_PAYMENT_METHOD_DEFAULT_REQUEST, setPaymentDefaultSaga);
}

export default function* userSagas() {
  yield all([fork(watchAuthUser)]);
}
