import React from 'react';
import reportWebVitals from './reportWebVitals';
import { useEffect, useState } from 'react';
import ReactDOM from "react-dom/client";


import 'antd/dist/antd.min.css';
import App from './App';
import './assets/fonts/moon/icomoon.ttf';
import './index.scss';


interface ErrorBoundaryProps {
  children: React.ReactNode;
}


const ErrorBoundary = ({ children }:ErrorBoundaryProps) => {
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const handleError = (error: ErrorEvent | Error) => {
      // Check if the argument is an ErrorEvent, and extract the error if necessary
      const errorMessage = error instanceof ErrorEvent ? error.error.message : error.message;

      setError(new Error(errorMessage));

      // You can log the error or perform other actions here
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (error) {
    // Render an error message or a fallback UI
    return <div>Error: {error.message}</div>;
  }

  return <>{children}</>;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
     <App />

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
