import api from 'api';
import {
  AttachMediaChatMessageRequest,
  ChatChannelFilterRequest,
  ChatChannelItem,
  ChatMessageItem,
  SendChatMessageRequest,
  UnseenMessagesCountResponse
} from 'common';
import qs from 'qs';

export const getChannels = ({}: // page,
// limit
ChatChannelFilterRequest): Promise<ChatChannelItem[]> => {
  return api.get(`/chat/channels`);
};

export const getMessages = ({
  channel,
  page,
  limit
}: {
  channel: string;
  page: number;
  limit: number;
  sortBy?: string;
  sortOrder?: number;
}): Promise<ChatMessageItem[] | null> => {
  return api.get(`/chat/${channel}/messages?page=${page}&limit=${limit}`);
};

export const getMessagesCursored = (_params: {
  previous?: string;
  next?: string;
  channel: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
}): Promise<{
  results: ChatMessageItem[];
  previous: string;
  next: string;
}> => {
  return api.get(`/chat/${_params.channel}/messages-cursored`, {
    params: _params,
    paramsSerializer: _params => {
      return qs.stringify(_params);
    }
  });
};

export const sendMessage = ({
  channelId,
  message,
  type,
  groupId
}: {
  channelId: string;
} & SendChatMessageRequest): Promise<ChatMessageItem> => {
  return api.post(`/chat/${channelId}/messages`, { message, type, groupId });
};

export const attachMediaToMessage = ({
  chatMessageId,
  body
}: {
  chatMessageId: string;
  body: AttachMediaChatMessageRequest;
}): Promise<ChatMessageItem> => {
  return api.put(`/chat/${chatMessageId}/attach-media`, body);
};

export const getUnseenMessages = (): Promise<UnseenMessagesCountResponse> => {
  return api.get(`/chat/channels/unseen-count`);
};

export const messageMarkAllAsSeen = (channelId: string): Promise<string> => {
  return api.put(`chat/channels/${channelId}/mark-all-as-seen`);
};
