import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Routes from 'Routes';
import store from './redux/store';
import 'react-toastify/dist/ReactToastify.css';

const AppleAppSiteAssociation: React.FC = (): JSX.Element => {
  const reload = () => window.location.reload();

  return (
    <>
      <link
        rel="apple-app-site-association file"
        href="/static/apple-app-site-association"
        type="application/json"
      />
    </>
  );
};

const App: React.FC = (): JSX.Element => (
  <Provider store={store}>
    <BrowserRouter>
      {/* <AppleAppSiteAssociation /> */}
      <Routes />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        pauseOnFocusLoss
        pauseOnHover
      />
    </BrowserRouter>
  </Provider>
);
export default App;
// initiate deployment 2
