import { Col, Modal, Row, Typography } from 'antd';
import styles from './index.module.scss';

const { Text } = Typography;
import classNames from 'classnames';
import PaylapIcon from 'assets/icons/paypal.svg';
import DButton from 'components/DButton';
import PayPalMoadalContent from '../Modals/PaymentMoadalContent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userIsLoading, userPayInMethods, userPaymentsmethods } from 'redux/user/selectors';
import {
  fetchDeletePaymentMethod,
  fetchGetTotalPayments,
  fetchGetUserPayInMethods,
  fetchGetUserPaymentsMethods,
  fetchPaymentMethodAsDefault
} from 'redux/user/actions';
import PaymentMethodItem from 'components/PaymentMethodItem';
import { fetchConnectStripeAccount, fetchSetupToken } from 'api/transactions';
import MaskLoader from 'components/MaskLoader';
import { setLastAction } from 'redux/general/actions';
import useUser from 'redux/user/use-user';
import TextField from 'components/RadioMulti/TextField';
import StripeCardForm from '../../StripeCardForm';

const Payments = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleSetAsDefaultModal, setVisibleSetAsDefaultModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [clickedItemtoken, setClickedItemtoken] = useState<string | null>(null);
  const [clickedItemId, setClickedItemId] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [paymentMethodType, setPaymentMethodType] = useState<string | null>(null);
  const [payInMethodType, setPayInMethodType] = useState<string | null>(null);

  const { user } = useUser();

  const isLoading: boolean = useSelector(userIsLoading);
  const paymentsMethods: Array<any> = useSelector(userPaymentsmethods);
  const payInMethods: Array<any> = useSelector(userPayInMethods);

  const { lastAction } = useSelector((state: any) => state.general);
  const [disconnectModalOptions, setDisconnectModalOptions] = useState<{
    title: string;
    text: string;
    canDisconnect: boolean;
  } | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGetUserPaymentsMethods());
    dispatch(fetchGetUserPayInMethods());
  }, []);

  const closeDeleteModal = () => {
    setVisibleDeleteModal(false);
    setSelectedItem(null);
  };

  const openDeleteModal = (item: any) => {
    setVisibleDeleteModal(true);
    setSelectedItem(item);
  };

  const openSetAsDefaultModal = (item: any) => {
    setVisibleSetAsDefaultModal(true);
    setSelectedItem(item);
  };

  const closeSetAsDefaultModal = () => {
    setVisibleSetAsDefaultModal(false);
    setSelectedItem(null);
  };

  const setAsDefault = () => {
    if (selectedItem) {
      setVisibleSetAsDefaultModal(false);
      dispatch(
        fetchPaymentMethodAsDefault({ id: selectedItem._id, purpose: selectedItem.purpose })
      );
    }
  };

  const deletePaymentMethod = () => {
    if (selectedItem) {
      setVisibleDeleteModal(false);
      dispatch(fetchDeletePaymentMethod({ id: selectedItem._id, purpose: selectedItem.purpose }));
    }
  };

  const redirectTo = (url: string) => {
    const approvalUrl = url;
    dispatch(setLastAction({ action: 'PAYMENT_PAGE', data: { finished: false }, type: '' }));
    let popupWindow = window.open(
      approvalUrl,
      'mywindow',
      'width=660,height=640,toolbar=1,status=1,left= 200'
    );

    if (popupWindow) {
      // Display a pop-up message in the new window
      popupWindow.alert('This is a pop-up!');
    } else {
      // Handle the case when pop-up blocking is detected or the window was not opened
      alert('Pop-up was blocked or not opened. Please check your browser settings.');
      window.location.href = approvalUrl;
    }
  };

  const connectPaypal = async () => {
    await fetchSetupToken().then((res: any) => {
      redirectTo(res);
    });
  };

  const connectStripe = async () => {
    setLoading(true);
    await fetchConnectStripeAccount().then(url => {
      setLoading(false);
      redirectTo(url);
      // window.open(url, 'mywindow', 'width=560,height=340,toolbar=1,status=1,left= 200');
    });
  };

  useEffect(() => {
    if (lastAction && lastAction.action === 'PAYMENT_PAGE' && lastAction?.data?.finished) {
      dispatch(fetchGetUserPaymentsMethods());
      dispatch(fetchGetUserPayInMethods());
      dispatch(setLastAction(null));
    }
  }, [lastAction]);

  useEffect(() => {
    if (user.activeJobs.length > 0 && paymentsMethods.length === 1) {
      setDisconnectModalOptions({
        title: 'Payment account disconnection temporary is not possible .',
        text: 'We can not disconnect your account, as you have active contracts, which should be payed from your account. Please contact our support, if assistance is needed.',
        canDisconnect: false
      });
    } else if (user.profileType === 'MASTER') {
      setDisconnectModalOptions({
        title: 'Are you sure to delete payment method',
        text: '',
        canDisconnect: true
      });
    } else {
      setDisconnectModalOptions({
        title: ' Payment account disconnection.',
        text: ' Are you sure to delete payment method',
        canDisconnect: true
      });
    }
  }, []);

  const onFinishCardConnection = () => {
    setLoading(false);
    dispatch(fetchGetUserPaymentsMethods());
    setPaymentMethodType(null);

    if (lastAction && lastAction.type === 'modal') {
      dispatch({
        type: lastAction.action,
        payload: lastAction.data
      });
    }
  };
  const onStartCardConnection = () => {
    setLoading(true);
  };

  const hasStripePayInMethod = () => {
    let hasConnection = false;
    payInMethods.forEach(item => {
      if (item.method === 'STRIPE') {
        hasConnection = true;
      }
    });
    return hasConnection;
  };

  const hasPaypalPayInMethod = () => {
    let hasConnection = false;
    payInMethods.forEach(item => {
      if (item.method === 'PAYPAL') {
        hasConnection = true;
      }
    });
    return hasConnection;
  };

  const hasPaypalPayOutMethod = () => {
    let hasConnection = false;
    paymentsMethods.forEach(item => {
      if (item.method === 'PAYPAL') {
        hasConnection = true;
      }
    });
    return hasConnection;
  };

  const hasStripePayOutMethod = () => {
    let hasConnection = false;
    paymentsMethods.forEach(item => {
      if (item.method === 'STRIPE') {
        hasConnection = true;
      }
    });
    return hasConnection;
  };

  return (
    <>
      {(isLoading || loading) && <MaskLoader />}

      <Row className={classNames(styles.container, 'pt-5 ')}>
        <Col span={10} offset={3}>
          <div className="text-[#18191A] text-[22px] py-3 font-semibold">Payments</div>
          {paymentsMethods.length > 0 && !isLoading && (
            <>
              {paymentsMethods?.map((item: any) => {
                return (
                  <PaymentMethodItem
                    key={item.bindingId}
                    id={item._id}
                    method={item.method}
                    brand={item.brand}
                    expDate={item.expDate}
                    email={item.email}
                    status={item.status}
                    userFullName={user.fullName}
                    isDefault={item.default}
                    cardNumber={item.cardNumber}
                    accountType={item.accountType}
                    bankName={item.bankName}
                    purpose={item.purpose}
                    onDeleteClick={() => openDeleteModal(item)}
                    onSetDefault={() => openSetAsDefaultModal(item)}
                  />
                );
              })}
            </>
          )}

          <Col span={24}>
            {(!hasPaypalPayOutMethod() || !hasStripePayOutMethod()) && (
              <div className="text-[#18191A] text-[22px] py-3 mt-3 font-semibold">
                Connect payment method
              </div>
            )}

            {!hasStripePayOutMethod() && (
              <>
                <div
                  className={classNames(
                    styles.paymentMethod,
                    paymentMethodType === 'stripe' ? 'rounded-b-sm' : ''
                  )}
                >
                  <TextField
                    text={'Credit / Debit Cards'}
                    isChecked={paymentMethodType === 'stripe' ? true : false}
                    customClasses="bg-[transparent] justify-start shadow-none "
                    side="right"
                    onChange={() => setPaymentMethodType('stripe')}
                  />
                </div>
                {paymentMethodType === 'stripe' && (
                  <div className={classNames(styles.stripeForm)}>
                    <StripeCardForm
                      onFinishConnection={onFinishCardConnection}
                      onStartConnection={onStartCardConnection}
                    />
                  </div>
                )}
              </>
            )}

            {!hasPaypalPayOutMethod() && (
              <>
                <div
                  className={classNames(
                    styles.paymentMethod,
                    ' mt-2',
                    paymentMethodType === 'paypal' ? 'rounded-b-sm' : ''
                  )}
                >
                  <TextField
                    text={'Paypal'}
                    isChecked={paymentMethodType === 'paypal' ? true : false}
                    customClasses="bg-[transparent] justify-start shadow-none"
                    side="right"
                    onChange={() => setPaymentMethodType('paypal')}
                  />
                </div>
                {paymentMethodType === 'paypal' && (
                  <div className={classNames(styles.stripeForm)}>
                    <div className="flex justify-start ">
                      <DButton
                        type="light-green"
                        className="text-font-16 font-bold text-white w-[200px] h-[44px]"
                        submit
                        disabled={false}
                        loading={false}
                        onClick={connectPaypal}
                      >
                        Connect PayPal
                      </DButton>
                    </div>
                  </div>
                )}
              </>
            )}
          </Col>
        </Col>
        <Col span={10}>
          <div className="text-[#18191A] text-[22px] py-3 font-semibold">Payout </div>
          {payInMethods.length > 0 && !isLoading && (
            <>
              {payInMethods?.map((item: any) => {
                return (
                  <PaymentMethodItem
                    key={item.bindingId}
                    id={item._id}
                    method={item.method}
                    brand={item.brand}
                    expDate={item.expDate}
                    email={item.email}
                    status={item.status}
                    userFullName={user.fullName}
                    isDefault={item.default}
                    cardNumber={item.cardNumber}
                    accountType={item.accountType}
                    bankName={item.bankName}
                    purpose={item.purpose}
                    onDeleteClick={() => openDeleteModal(item)}
                    onSetDefault={() => openSetAsDefaultModal(item)}
                  />
                );
              })}
            </>
          )}

          <Col span={24}>
            {(!hasStripePayInMethod() || !hasPaypalPayInMethod()) && (
              <div className="text-[#18191A] text-[22px] py-3 mt-3 font-semibold">
                Connect payout method
              </div>
            )}

            {!hasStripePayInMethod() && (
              <>
                <div
                  className={classNames(
                    styles.paymentMethod,
                    payInMethodType === 'stripe' ? 'rounded-b-sm' : ''
                  )}
                >
                  <TextField
                    text={'Credit Card'}
                    isChecked={payInMethodType === 'stripe' ? true : false}
                    customClasses="bg-[transparent] justify-start shadow-none "
                    side="right"
                    onChange={() => setPayInMethodType('stripe')}
                  />
                </div>
                {payInMethodType === 'stripe' && (
                  <div className={classNames(styles.stripeForm)}>
                    <DButton
                      type="light-green"
                      className="text-font-16 font-bold text-white w-[200px] h-[44px]"
                      submit
                      disabled={false}
                      loading={false}
                      onClick={connectStripe}
                    >
                      Connect Credit Card
                    </DButton>
                  </div>
                )}
              </>
            )}
            {!hasPaypalPayInMethod() && (
              <>
                <div
                  className={classNames(
                    styles.paymentMethod,
                    ' mt-2',
                    payInMethodType === 'paypal' ? 'rounded-b-sm' : ''
                  )}
                >
                  <TextField
                    text={'Paypal'}
                    isChecked={payInMethodType === 'paypal' ? true : false}
                    customClasses="bg-[transparent] justify-start shadow-none"
                    side="right"
                    onChange={() => setPayInMethodType('paypal')}
                  />
                </div>
                {payInMethodType === 'paypal' && (
                  <div className={classNames(styles.stripeForm)}>
                    <div className="flex justify-start ">
                      <DButton
                        type="light-green"
                        className="text-font-16 font-bold text-white w-[200px] h-[44px]"
                        submit
                        disabled={false}
                        loading={false}
                        onClick={connectPaypal}
                      >
                        Connect PayPal
                      </DButton>
                    </div>
                  </div>
                )}
              </>
            )}
          </Col>
        </Col>

        <Modal
          className={classNames(styles.deleteModal, ' ')}
          style={{
            borderRadius: '16px !important'
          }}
          visible={visibleDeleteModal}
          footer={false}
          destroyOnClose
          centered
          closeIcon={<></>}
          onCancel={closeDeleteModal}
          maskStyle={{ background: 'unset' }}
        >
          <Row className={classNames(styles.container, '')}>
            <Col span={24}>
              <div className="text-[#18191A] text-[22px] text-center py-3 font-semibold">
                {disconnectModalOptions?.title}
              </div>
              <div className="text-[#18191A] text-[18px] text-center py-3 font-semibold">
                {' '}
                {disconnectModalOptions?.text}
              </div>

              <div className="flex justify-center mt-[40px]">
                <div>
                  {/* <div className={classNames(styles.paypalImage, 'flex justify-center ')}>
                    <img className="h-[70px] w-[70px]" src={PaylapIcon} alt="icon" />
                  </div> */}
                  <div className="flex justify-end mt-[30px]">
                    {disconnectModalOptions?.canDisconnect ? (
                      <>
                        <DButton
                          type="only-text"
                          className="text-font-12 border border-[#18191A] rounded-[70px] font-bold text-[#18191A] w-[120px] h-[35px]"
                          onClick={closeDeleteModal}
                          disabled={false}
                          loading={false}
                        >
                          Cancel
                        </DButton>
                        <DButton
                          onClick={deletePaymentMethod}
                          type="light-green"
                          className="text-font-12 ml-4 !bg-[rgb(193,40,27) font-bold text-white w-[120px] h-[35px]"
                          disabled={false}
                          loading={false}
                        >
                          Disconnect
                        </DButton>
                      </>
                    ) : (
                      <DButton
                        onClick={closeDeleteModal}
                        type="light-green"
                        className="text-font-12 ml-4 !bg-[rgb(193,40,27) font-bold text-white w-[120px] h-[35px]"
                        disabled={false}
                        loading={false}
                      >
                        Cancel
                      </DButton>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>

        <Modal
          className={classNames(styles.deleteModal, ' ')}
          style={{
            borderRadius: '16px !important'
          }}
          visible={visibleSetAsDefaultModal}
          footer={false}
          destroyOnClose
          centered
          closeIcon={<></>}
          onCancel={closeSetAsDefaultModal}
          maskStyle={{ background: 'unset' }}
        >
          <Row className={classNames(styles.container, '')}>
            <Col span={24}>
              <div className="text-[#18191A] text-[22px] text-center py-3 font-semibold">
                Set As Default
              </div>
              <div className="text-[#18191A] text-[18px] text-center py-3 font-semibold">
                Are you sure ?
              </div>
              <div className="flex justify-center mt-[40px]">
                <div>
                  <div className="flex justify-end mt-[30px]">
                    <>
                      <DButton
                        type="only-text"
                        className="text-font-12 border border-[#18191A] rounded-[70px] font-bold text-[#18191A] w-[120px] h-[35px]"
                        onClick={closeSetAsDefaultModal}
                        disabled={false}
                        loading={false}
                      >
                        Cancel
                      </DButton>
                      <DButton
                        onClick={setAsDefault}
                        type="light-green"
                        className="text-font-12 ml-4 !bg-[rgb(193,40,27) font-bold text-white w-[120px] h-[35px]"
                        disabled={false}
                        loading={false}
                      >
                        Set As Default
                      </DButton>
                    </>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </Row>
    </>
  );
};

export default Payments;
