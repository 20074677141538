import React from 'react';
import DButton from 'components/DButton';
import ResetPassContainer from '../Containers/ResetPassContainer';
import CheckMarkImage from 'assets/images/checkmark.svg';

const ForgotPassword = () => {
  return (
    <ResetPassContainer>
      <div className="flex flex-col justify-center items-center w-[376px] mt-[75px]">
        <div className="flex flex-col items-center justify-center">
          <img src={CheckMarkImage} alt="CheckMarkImage" />
          <p className="text-base text-doers-black font-bold mb-8 mt-6 text-center">
            The password successfully changed
          </p>
        </div>
        <DButton text="Login" className="w-[278px]" type="default" goTo="/auth/login" />
      </div>
    </ResetPassContainer>
  );
};

export default ForgotPassword;
