import { useDispatch, useSelector } from 'react-redux';
import { changeFiltersAction } from './actions';
import { filters as filtersSelector } from './selectors';

const useDashboard = () => {
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const changeFilters = newFilters => {
    dispatch(changeFiltersAction(newFilters));
  };

  return {
    filters,
    changeFilters
  };
};

export default useDashboard;
