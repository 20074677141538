import styles from './index.module.scss';
import React from 'react';
import classNames from 'classnames';

const Video: React.FC<{
  thumb?: string;
  size: 'sm-prof' | 'sm-job' | 'lg' | 'sm-job-post' | 'message';
  classes?: string;
  blob?: string;
  onClick?: () => void;
  playIcon?: boolean;
}> = ({ thumb, blob, classes, size, playIcon = true, onClick }) => {
  return (
    <div className={classNames(styles.videoImg, styles[size], classes)} onClick={onClick}>
      {thumb && (
        <div
          style={{
            backgroundImage: `url(${thumb})`
          }}
          className={styles.background}
        />
      )}
      {blob && <video src={blob} />}
      {playIcon && (
        <div className={styles.playIcon}>
          <i className="icon-35 text-white" />
        </div>
      )}
    </div>
  );
};

export default Video;
