/** @format */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import merge from 'lodash/merge';

export type HttpErrorHandlerResponse = {
  /** The message that should be thrown by the response handler */
  message?: string;
  /**
   * Whether or not callHttp should throw an error.
   * Set this to false if you plan to handle the error yourself.
   */
  shouldThrowError: boolean;
};

export type HttpConfig<ErrorType> = {
  /**
   * Called whenever a response > 300 is recieved.  Use this to handle any errors
   * that are specific to the API called.
   */
  errorHandler?: (error: AxiosResponse<ErrorType>) => HttpErrorHandlerResponse;
  /** The Axios config object.  See https://axios-http.com/docs/req_config. */
  axiosConfig?: AxiosRequestConfig;
  /** See https://axios-http.com/docs/interceptors. */
  requestInterceptor?: {
    onFulfilled: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  };
  /**
   * See https://axios-http.com/docs/interceptors.  onReject interceptors not allowed,
   * use top-level errorHandler field instead.
   */
  responseInterceptor?: {
    onFulfilled: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  };
};

type InteceptorIdStorage = {
  requestId?: number;
  responseId?: number;
};

/**
 * Call a HTTP API and returns the response.
 * @param url The url to call, not including any query string parameters
 * @param httpConfig The config object used to configure this call
 * @returns The response from the API call
 */
export async function callHttp<Result, ErrorType>(
  url: string,
  httpConfig?: HttpConfig<ErrorType>,
): Promise<Result> {
  const defaultConfig: AxiosRequestConfig = {
    headers: {
      accept: 'application/json',
    },
    url,
  };
  const mergedConfig: AxiosRequestConfig = merge(defaultConfig, httpConfig?.axiosConfig);
  const interceptorIds: InteceptorIdStorage = { requestId: undefined, responseId: undefined };
  if (httpConfig?.requestInterceptor) {
    interceptorIds.requestId = axios.interceptors.request.use(
      httpConfig.requestInterceptor.onFulfilled,
    );
  }
  if (httpConfig?.responseInterceptor) {
    interceptorIds.responseId = axios.interceptors.response.use(
      httpConfig.responseInterceptor.onFulfilled,
    );
  }
  let data: Result;
  try {
    const result = await axios.request<Result>(mergedConfig);
    data = result.data;
  } catch (error: any) {
    // JS errors can be literally anything, so they must be any
    if (axios.isAxiosError(error)) {
      // axios helper function above tells TS that the error is an AxiosError
      if (error.response && httpConfig?.errorHandler) {
        const response = httpConfig.errorHandler(error.response as AxiosResponse<ErrorType>);
        if (response.shouldThrowError) {
          // Calling code has indicated that we should throw an error
          throw new Error(response.message ?? error.message);
        }
        // Calling code has indicated that it will handle the error
        return {} as Result;
      }
      // The error was not based on an API response, wrap it in a generic error
      throw new Error(error.message);
    }
    // We have no idea what this error is, just throw it
    throw error;
  } finally {
    if (interceptorIds.requestId) {
      axios.interceptors.request.eject(interceptorIds.requestId);
    }
    if (interceptorIds.responseId) {
      axios.interceptors.response.eject(interceptorIds.responseId);
    }
  }
  return data;
}
