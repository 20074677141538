
import React, { useState } from 'react';
import DButton from 'components/DButton';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Divider } from 'antd';
import Input from 'components/Input';
import { Form } from 'antd';
import useUser from '../../../redux/user/use-user';
import circle from 'assets/images/circle.svg';
import { useNavigate } from 'react-router-dom';
import DotsAndButton from 'components/DotsAndButton';

const Company = () =>{

  const { masterFlowData, keepBecomeMasterData } = useUser();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(true)


  const next = ({companyName,companyTaxId}:{companyName:string,companyTaxId:string}) =>{
    keepBecomeMasterData({ ...masterFlowData, ...{companyName,companyTaxId} });
    navigate('/become-a-master/introduction');
  }

  const onValuesChange = (changedValues: {companyName: string} | {companyTaxId: string},allValues:{companyName: string, companyTaxId: string}) =>{
    if ( allValues.companyTaxId != undefined && allValues.companyName != undefined && allValues.companyTaxId != '' && allValues.companyName != '') {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

return(
  <div className={styles.minH540}>
  <div className="flex flex-col items-center w-[474px]">
    <div className="w-[120px] h-[120px]">
            <img src={circle} alt="pencil" />
    </div>
    <p className={classNames(['text-doers-black', styles.steps])}>Company</p>
    <p className={classNames(styles.youWillAnser)}>
    Fill in your company details
    </p>

    <div className="w-[376px]">


    <div className="mb-2" >
    <Form onFinish={next} 
        onValuesChange={onValuesChange}
         autoComplete="off" form={form} validateTrigger={['onBlur']}>
          <Form.Item
            name="companyName"
            rules={[{ required: true, message: 'This field is required.' }]}
            className="mb-2"
            initialValue={''}
          >
            <Input label="Company Name" size="lg" />
          </Form.Item>
          <Form.Item
            name="companyTaxId"
            rules={[{ required: true, message: 'This field is required.' }]}
            className="mb-2"
            initialValue={''}
          >
            <Input label="Tax ID" size="lg" />
          </Form.Item>
          <Divider />
        <div className='flex flex-col items-center'>
        {/* <div className={styles.dotsContainer}>
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
        </div> */}
        <DotsAndButton>
          <DButton
            text="Next, About"
            className={styles.next}
            type="default"
            submit
            disabled={formValid}
          />
          </DotsAndButton>
        </div>
          </Form>
     
      </div>
    </div>
  
  </div>
</div>
)

}
export default Company;
