
import styles from './index.module.scss';
import classNames from 'classnames';
import { UserItem } from 'common';
import { Avatar, Space } from 'antd';
import moment from 'moment';

type  ReviewItemType ={
user: UserItem | any;
rating:number,
date:any,
feedback:string,
}

const ReviewItem =({user,rating,date,feedback}:ReviewItemType) =>{



  let formattedDate = moment(date).local().format('MMM DD, YYYY');
  if (moment().diff(moment(date), 'days') < 1) {
    formattedDate = moment(date).local().format('LT');
  }
  const getRating = ()=>{
    const html = []
    for(let i = 0;i < rating;i++){
         let className = ''
         if(i > 0){
            className = 'ml-1'
         }
          html.push( <i key={`star`+i} className={`icon-26  text-font-12 text-orange ${className}`} />)
    }
    return html
  }

  return(
    <>
    <div  className={classNames(styles.container, 'pr-4')}>
        <div className={classNames(styles.header, '')}>
              <div className={classNames(styles.leftSide, '')}>

                  <Space wrap size={16}>
                      {user?.profilePicture ?
                      <Avatar className='border-solid border-[1px] border-[#E0E0E0]'
                            size={48}
                            src={`${process.env.REACT_APP_MEDIA_BASE_URL}${user?.profilePicture}`}  /> :
                            <span className={styles.nameCircle}>
                               {user?.firstName && user?.firstName[0]}
                               {user?.firstName && user?.lastName[0]}
                            </span>
                            }
                          </Space>
                          <div className={styles.nameRating}>
                            <div className={styles.nameDate}>
                            <div className={styles.name}>
                                 {user.fullName}

                              </div>
                              <div className={classNames(styles.date, '')}>
                                   {formattedDate}
                                    </div>
                            </div>

                              <div className={styles.rating}>

                                     {getRating()}
                              </div>
                          </div>
              </div>
              {/* <div className={classNames(styles.rightSide, '')}>

              </div> */}
        </div>
        <div className={classNames(styles.body, 'tab text-light-gray')}>
          {feedback}
        </div>

    </div>
    </>
  )
}

export default ReviewItem
