import { Col, Row, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { ChatState } from 'redux/chat/types';
import useUser from 'redux/user/use-user';
import ChatHeaderProfileInfo from './ChatHeaderProfileInfo';
import classNames from 'classnames';
import { JOB_STATUSES } from '../../../constants';
import { toFixedUserRating } from 'helpers';
import JobIcon from 'assets/icons/job.svg';
const ChatHeader = ({
  isJobOfferSectionOpen,
  setIsJobOfferSectionOpen
}: {
  isJobOfferSectionOpen: boolean;
  setIsJobOfferSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedChannel }: ChatState = useSelector((state: any) => state.chat);
  const { Text } = Typography;
  const { user } = useUser();
  const showBadges = selectedChannel?.doerId === user?._id;

  return (
    <Row wrap={false} className="max-h-40 h-[100vh] border-b-[1px] border-[#EBEBEB] pt-4  w-full">
      {selectedChannel && (
        <Col className="flex flex-col justify-between w-full">
          <Col className="ml-6">
            <ChatHeaderProfileInfo
              data={{
                firstName: selectedChannel?.interlocutor.firstName,
                lastName: selectedChannel?.interlocutor.lastName,
                fullName: selectedChannel?.interlocutor.fullName,
                img: selectedChannel.interlocutor.profilePicture,
                rating: toFixedUserRating(
                  selectedChannel?.interlocutor.isMaster
                    ? selectedChannel?.interlocutor.masterReviewAvg
                    : selectedChannel?.interlocutor.doerReviewAvg
                ),
                reviews: selectedChannel?.interlocutor.isMaster
                  ? selectedChannel?.interlocutor.masterReviews
                  : selectedChannel?.interlocutor.doerReviews,
                bestMatch: selectedChannel?.interlocutor.bestMatch,
                master: selectedChannel?.interlocutor.profileType === 'MASTER',
                pro: selectedChannel?.interlocutor.pro,
                jobsCount: selectedChannel?.interlocutor.isMaster
                  ? selectedChannel?.interlocutor.jobsCompleted
                  : selectedChannel?.interlocutor.jobsPosted,
                isMaster: selectedChannel?.interlocutor.isMaster
              }}
              showReviews
              showBadges={showBadges}
              isJobOfferSectionOpen={isJobOfferSectionOpen}
              setIsJobOfferSectionOpen={setIsJobOfferSectionOpen}
              fontSize="text-font-16"
            />
          </Col>
          <Col className="w-full overflow-hidden h-20 bg-[#F7F7F7] pl-6">
            <Row wrap={false} align="middle" className="mt-2">
              <img className="mr-2" src={JobIcon} alt="icon" />
              <Text
                ellipsis={{ tooltip: true }}
                className="text-font-16 font-bold w-auto cursor-default  "
              >
                {selectedChannel?.title}
              </Text>
            </Row>
            <Space className="mt-2 ml-7">
              <Text
                className={classNames(
                  JOB_STATUSES[selectedChannel.status]?.color,
                  'leading-4 px-[5px] py-[1px] rounded-[20px] font-semibold text-white'
                )}
              >
                {JOB_STATUSES[selectedChannel.status]?.text}
              </Text>
              {user._id === selectedChannel.masterId &&
                selectedChannel.type === 'MASTER_APPLY_JOB' && (
                  <Text
                    className={classNames(
                      'leading-4 px-[5px] py-[1px] rounded-[20px] font-semibold text-[#FF6F3D] border-[1px] border-[#FF6F3D]'
                    )}
                  >
                    Applied by me
                  </Text>
                )}
              {user._id === selectedChannel.masterId &&
                selectedChannel.type === 'DOER_INVITE_TO_JOB' && (
                  <Text
                    className={classNames(
                      'leading-4 px-[5px] py-[1px] rounded-[20px] font-semibold text-[#4B5CF5] border-[1px] border-[#4B5CF5]'
                    )}
                  >
                    Invited by doer
                  </Text>
                )}
              {user._id === selectedChannel.doerId && (
                <Text
                  className={classNames(
                    'leading-4 px-[5px] py-[1px] rounded-[20px] font-semibold text-[#49C33B] border-[1px] border-[#49C33B]'
                  )}
                >
                  Posted by me
                </Text>
              )}
            </Space>
          </Col>
        </Col>
      )}
    </Row>
  );
};

export default ChatHeader;
