/** @format */

import * as yup from 'yup';
import { MediaPathType, MediaLiteItem } from '../media';
import { UserItem } from '../user';
import { JobItem } from '../job';
import { Service, servicesList } from '../profile';

export type JobContractStatus =
  | 'DRAFT'
  | 'NEW'
  | 'READY_TO_START'
  | 'IN_PROGRESS'
  | 'PENDING'
  | 'ISSUE_REPORTED'
  | 'ISSUE_ACCEPTED'
  | 'ISSUE_DECLINED'
  | 'FINISHED'
  | 'CANCELED'
  | 'ADMIN_REVIEW'
  | 'REJECTED'
  | 'BLOCKED'
  | 'DELETED';

export type JobContractType = 'MASTER_APPLY_JOB' | 'DOER_INVITE_TO_JOB';
export type JobContractUserRole = 'MASTER' | 'DOER';

export type JobContractItem = {
  _id?: string;
  master: string | UserItem;
  doer: string | UserItem;
  job: string | JobItem;
  hourlyRate?: number;
  flatPrice?: number;
  paidAmount?: number;
  tipAmount?: number;
  hoursWorked?: number;
  description: string;
  files?: Array<MediaLiteItem>;
  status: JobContractStatus;
  type: JobContractType;
  lastReportedIssueDeclinedAt?: Date;
  bestMatch: boolean;
  doerRatingStart?: number;
  doerFeedback?: string;
  masterRatingStart?: number;
  masterFeedback?: string;
  lastSystemMessageId?: string;
  channelId?: string;
  contractId?: number;
  deleted?: boolean;
  jobKeywords?: Array<string>;
  issueReported?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type JobContractItemLite = Pick<
  JobContractItem,
  | '_id'
  | 'description'
  | 'status'
  | 'master'
  | 'doer'
  | 'job'
  | 'hourlyRate'
  | 'hoursWorked'
  | 'flatPrice'
  | 'bestMatch'
  | 'issueReported'
  | 'type'
  | 'deleted'
  | 'createdAt'
  | 'updatedAt'
  | 'lastSystemMessageId'
  | 'channelId'
  | 'doerRatingStart'
  | 'masterRatingStart'
>;

export type JobContractFilterRequest = {
  rateFrom?: number;
  rateTo?: number;
  priceFrom?: number;
  priceTo?: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  from?: string;
  to?: string;
};

export type JobContractsFilterResponse = {
  items: JobContractItem[];
  count: number;
};

/** Payload object for creating a job */
export type CreateJobContractRequest = {
  title: string;
  description?: string;
  services?: Array<Service>;
  startDate?: string;
  endDate?: string;
  hourlyRate?: number;
  flatPrice?: number;
  address: string;
  bestMatch: boolean;
  type: JobContractType;
};

/** Payload object for attaching media to a job */
export type AttachMediaJobContractRequest = {
  video: MediaPathType;
  photos?: Array<string>;
};

export const AttachMediaToJobContractRequestSchema = yup.object().shape({
  video: yup.object().required().shape({
    original: yup.string().required(),
    thumbnail: yup.string().required(),
  }),
  photos: yup.array().max(5).of(yup.string().required()),
});

/** Payload object for updating the job */
export type UpdateJobContractRequest = {
  title: string;
  description?: string;
  services?: Array<Service>;
  startDate?: string;
  endDate?: string;
  hourlyRate?: number;
  flatPrice?: number;
  paidAmount?: number;
  tipAmount?: number;
  address: string;
  video: MediaPathType;
  photos?: Array<string>;
  pro: boolean;
  doerRatingStart?: number;
  doerFeedback?: string;
  masterRatingStart?: number;
  masterFeedback?: string;
  lastSystemMessageId?: string;
};

export const UpdateJobContractRequestSchema = yup.object().shape({
  title: yup.string().min(1).max(255).required(),
  description: yup.string().optional(),
  services: yup.array().of(yup.string().oneOf(servicesList)),
  startDate: yup.date().nullable(true),
  endDate: yup.date().nullable(true),
  hourlyRate: yup.number().positive().nullable(true),
  flatPrice: yup.number().positive().nullable(true),
  paidAmount: yup.number().positive().nullable(true),
  tipAmount: yup.number().positive().nullable(true),
  address: yup.string().required(),
  location: yup.object().shape({
    type: yup.string().required().default('Point'),
    coordinates: yup.array(),
  }),
  video: yup.object().required().shape({
    original: yup.string().required(),
    thumbnail: yup.string().required(),
  }),
  photos: yup.array().max(5).of(yup.string().required()),
  pro: yup.boolean().default(false),
  doerRatingStart: yup.number().oneOf([1, 2, 3, 4, 5]).required(),
  doerFeedback: yup.string().required(),
  masterRatingStart: yup.number().oneOf([1, 2, 3, 4, 5]).required(),
  masterFeedback: yup.string().required(),
});

/** Payload object for updating the job contract last system message id */
export type UpdateJobContractLastSystemMessageRequest = {
  lastSystemMessageId: string;
};

export const UpdateJobContractLastSystemMessageRequestSchema = yup.object().shape({
  lastSystemMessageId: yup.string().min(1).max(255).required(),
});

/** Payload object for grouped messages request */
export type ChangeContractStateRequest = {
  systemMessageId?: string;
};

export const ChangeContractStateRequestSchema = yup.object().shape({
  systemMessageId: yup.string().min(1).max(255).optional(),
});

/** Payload object for grouped messages request */
export type JobGroupMessageRequest = {
  groupId: string;
  systemMessageId?: string;
};

export const JobGroupMessageRequestSchema = yup.object().shape({
  groupId: yup.string().min(1).max(255).required(),
  systemMessageId: yup.string().min(1).max(255).optional(),
});

/** Payload object for complete job request */
export type CompleteJobRequest = {
  groupId: string;
  systemMessageId?: string;
  hoursWorked?: number;
};

export const CompleteJobRequestSchema = yup.object().shape({
  groupId: yup.string().min(1).max(255).required(),
  systemMessageId: yup.string().min(1).max(255).optional(),
  tipAmount: yup.number().min(0.5).max(1000).optional(),
});

/** Payload object for leave a tip request */
export type LeaveTipRequest = {
  tipAmount: number;
  systemMessageId?: string;
};

export const LeaveTipRequestSchema = yup.object().shape({
  tipAmount: yup.number().min(0.01).max(100).required(),
  systemMessageId: yup.string().min(1).max(255).optional(),
});

/** Payload object for leave a feedback request */
export type LeaveFeedbackRequest = {
  ratingStart: number;
  feedback: string;
  systemMessageId?: string;
};

export const LeaveFeedbackRequestSchema = yup.object().shape({
  ratingStart: yup.number().oneOf([1, 2, 3, 4, 5]).required(),
  feedback: yup.string().required(),
  systemMessageId: yup.string().min(1).max(255).optional(),
});

/** Payload object for change the price of job contract */
export type ChangeJobContractPriceRequest = {
  groupId?: string;
  hourlyRate?: number;
  flatPrice?: number;
};

export const ChangeJobContractPriceRequestSchema = yup.object().shape({
  groupId: yup.string().min(1).max(255),
  hourlyRate: yup.number().min(0).nullable(true),
  flatPrice: yup.number().min(0).nullable(true),
});

export type AcceptJobContractRequest = {
  hourlyRate?: number;
  flatPrice?: number;
};

export const AcceptJobContractRequestSchema = yup.object().shape({
  hourlyRate: yup.number().min(0).nullable(true),
  flatPrice: yup.number().min(0).nullable(true),
});
