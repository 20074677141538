import React from 'react';
import { Input } from 'antd';

import classNames from 'classnames';
import FloatLabel from './FloatLabel';
import styles from './index.module.scss';

type Props = {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  size?: string;
  [rest: string]: any;
};

const DInput: React.FC<Props> = ({ value = '', onChange, label, size, ...rest }) => (
  <div className={classNames(styles.inputContainer, 'w-16 md:w-32 lg:w-48', styles[size || ''])}>
    <FloatLabel label={label} value={value}>
      {rest.type === 'password' ? (
        <Input.Password value={value} onChange={onChange} {...rest} />
      ) : (
        <Input
          value={value}
          onChange={onChange}
          onWheel={event => event.currentTarget.blur()}
          {...rest}
        />
      )}
    </FloatLabel>
  </div>
);

export default DInput;
