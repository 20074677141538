import { Typography, Col, Row, Space, Rate } from 'antd';
import { useState } from 'react';
import { requestLeaveFeedback } from 'api/systemMessages';
import CustomTextArea from 'components/CustomTextArea';
import DButton from 'components/DButton';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_FEEDBACK_MODAL } from 'redux/modals/actionTypes';

import styles from './index.module.scss';
import CustomAvatar from 'components/CustomAvatar';

const { Text } = Typography;

interface IFeedbackModal {
  img?: string;
  name: string;
  firstName?: string;
  lastName?: string;
}

interface IFeedbackFields {
  rate: number;
  feedbackMessage: string;
}

const FeedbackModal = ({ img, name, firstName, lastName }: IFeedbackModal) => {
  const { control, handleSubmit, watch } = useForm<IFeedbackFields>({
    defaultValues: {
      rate: 0,
      feedbackMessage: ''
    }
  });
  const dispatch = useDispatch();
  const { feedbackModal } = useSelector((state: any) => state.modals);
  const [loading, setLoading] = useState(false);
  const { rate, feedbackMessage } = watch();
  const handleSubmitClick = async (fields: IFeedbackFields) => {
    try {
      setLoading(true);
      await requestLeaveFeedback(
        feedbackModal.contractId,
        feedbackModal.systemMessageId,
        fields.feedbackMessage,
        fields.rate
      );
      closeModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    dispatch({
      type: CLOSE_FEEDBACK_MODAL
    });
  };

  return (
    <Col className={styles.container}>
      <Row align="middle" justify="center">
        <Text className="text-font-22  font-bold">Leave a feedback</Text>
      </Row>
      <Col className="mt-10">
        <Row className={styles.imgContainer} justify="center">
          {/* <img src={img} alt="img" /> */}
          <CustomAvatar
            firstName={firstName ? firstName[0] : ''}
            lastName={lastName ? lastName[0] : ''}
            size={100}
            replaceImageSize="100x100"
            imagePath={img}
            nameClasses="bg-[#999999] font-medium text-[16px]"
          />
        </Row>
        <Row justify="center" className="mt-2">
          <Text className="text-font-16 font-bold">{name}</Text>
        </Row>
      </Col>
      <Col className="mt-10">
        <Row justify="center">
          <Text className="font-bold">{`How was your experience with ${name}`}</Text>
        </Row>
        <Controller
          control={control}
          name="rate"
          render={({ field }) => (
            <Row justify="center">
              <Rate
                allowHalf={false}
                value={field.value}
                className={styles.rate}
                onChange={field.onChange}
              />
            </Row>
          )}
        />
      </Col>
      <Col className="mt-12">
        <Controller
          control={control}
          name="feedbackMessage"
          rules={{ required: 'This field is required' }}
          render={({ field: { onChange, value } }) => (
            <CustomTextArea
              value={value}
              onChangeValue={onChange}
              classes="h-[110px]"
              placeholder="Share your experience with us."
            />
          )}
        />
      </Col>
      <Row justify="end" className="mt-[40px]">
        <Space size={16}>
          <DButton type="white" className="!w-[148px] !h-[44px]" onClick={closeModal}>
            Cancel
          </DButton>
          <DButton
            type="light-green"
            className="text-font-16 font-bold text-white w-[200px] h-[44px]"
            onClick={handleSubmit(fields => handleSubmitClick(fields))}
            submit
            disabled={!feedbackMessage || !rate}
            loading={loading}
          >
            Submit feedback
          </DButton>
        </Space>
      </Row>
    </Col>
  );
};

export default FeedbackModal;
