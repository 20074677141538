export const FETCH_CHANNELS = 'FETCH_CHANNELS';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';
export const ADD_NEW_CHANNEL = 'ADD_NEW_CHANNEL';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL';
export const SET_MODIFIED_CHANNELS = 'SET_MODIFIED_CHANNELS';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MORE_MESSAGES = 'FETCH_MORE_MESSAGES';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';
export const SET_FIRST_MESSAGE = 'SET_FIRST_MESSAGE';
export const RESET_CHAT = 'RESET_CHAT';
export const SET_SUGGESTION_POPOVER = 'SET_SUGGESTION_POPOVER';
export const SET_REDIRECTED_CHANEL_ID = 'SET_REDIRECTED_CHANEL_ID';
export const SET_MESSAGE_ID_FOR_ACTION = 'SET_MESSAGE_ID_FOR_ACTION';
export const GET_UNSEEN_MESSAGES_REQUEST= 'GET_UNSEEN_MESSAGES_REQUEST';
export const GET_UNSEEN_MESSAGES_SUCCESS = 'GET_UNSEEN_MESSAGES_SUCCESS';
export const GET_UNSEEN_MESSAGES_FAILURE = 'GET_UNSEEN_MESSAGES_FAILURE';
export const MESSAGES_MARK_AS_SEEN_REQUEST = 'MESSAGES_MARK_AS_SEEN_REQUEST';
