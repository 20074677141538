import { Col, Modal, Row, Space } from "antd";
import classNames from "classnames";
import MessageMediaCard from "components/Chat/components/MessageMediaCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteVideoById, getUserVideos } from "redux/user/actions";
import { userIsLoading, userVideos } from "redux/user/selectors";
import styles from './index.module.scss';
import IconX from 'assets/icons/svg-icon-9.svg';
import MaskLoader from "components/MaskLoader";
import { GALLERY_MODAL_OPEN } from "redux/modals/actionTypes";
import { filterObj } from 'helpers';
import DButton from "components/DButton";
import { MediaItem } from "common";

type VideosType ={
  userId:string;
}

const Videos = ({userId}:VideosType)=>{
 // 

 const [openDeleteModal,setOpenDeleteModal] = useState<boolean>(false)
 const [modalOptions,setModalOptions] = useState<{
  title:string,
  text:string,
  actionType?:string,
  activeItemId:string,

 }>({
  title:' Are you sure you want to delete?',
  text:' This video will be deleted from job details',
  activeItemId:'',
 })

 const dispatch = useDispatch();
  const videos:Array<MediaItem> = useSelector(userVideos);
  
 useEffect(()=>{
  dispatch(getUserVideos({userId:userId}));
 },[])


  const onOpenModal = (video:any) => {
    if(!video.job){
      setModalOptions({
        title:'Are you sure you want to delete this video?',
        text:'Video will be deleted permanently and not available in your profile anymore.',
        activeItemId:video._id,
        actionType:'DELETE_VIDEO',

      })
    }
    if(video.job && video.job.status === 'NEW'){
      setModalOptions({
        title:'Are you sure you want to delete this video posted together with the job?',
        text:`Whole ${video.job.title} job will be deactivated permanently and not available in your profile anymore.`,
        activeItemId:video._id,
        actionType:'DELETE_VIDEO_DEACTIVE_JOB',

      })
    }

    if(video.job && video.job.status !== 'NEW'){
      setModalOptions({
        title:'Are you sure you want to delete this video from video feeds?',
        text:`Video will be removed from feeds , but still will be available in ${video.job.title} job.`,
        activeItemId:video._id,
        actionType:'HIDE_VIDEO',

      })
    }
    setOpenDeleteModal(true)
  };

  const onDelete = () =>{

    dispatch(deleteVideoById({id:modalOptions.activeItemId}));
    dispatch(getUserVideos({userId}));
    setOpenDeleteModal(false)
  }



 const handleOnMediaClick = (files: any, file: any) => {
  const photosTemp: string[] = [];
  const videosTemp: string[] = [];
  files?.forEach((file:any) => {
    videosTemp.push('media/'+file.video);
    
  });
  addContentToModal('media/'+file.video, photosTemp, videosTemp);
};

const addContentToModal = (activeItem: string | undefined, photos: any[], videos: any[]) => {
  const payloadTemp = {
    photos,
    videos,
    activeItem: activeItem
  };

  const filteredPayload = filterObj(payloadTemp, ['empty-array']);

  dispatch({
    type: GALLERY_MODAL_OPEN,
    payload: filteredPayload
  });

}

  return(
    <>

<Row className={classNames(styles.videosTab)} gutter={10} justify={'center'} wrap>
                              {videos?.map((video: any, index: number) => {
                                  return (
                                
                                    <Col
                                      key={`video-${index}`}
                                    
                                      className="cursor-pointer mt-3"
                                    >
                                       <div onClick={()=>onOpenModal(video)} className={classNames(styles.removeContainer)}>
                                            <img className='' src={IconX} alt="icon" />
                                          </div>
                                     <span   onClick={() => handleOnMediaClick(videos, video)}>
                                    
                                      <MessageMediaCard
                                        src={`${process.env.REACT_APP_MEDIA_BASE_URL}/media/${
                                          video?.photo.replace('original', '100x100')
                                        }`}
                                        height={100}
                                        width={100}
                                        preview={false}
                                        mediaType={'VIDEO'}
                                        className="border-solid border-2"
                                        videoPlayerIcon={true}
                                      />
                                        </span>   
                                    </Col>
                                  );
                                
                  })}
          </Row> 

          <Modal
        className='!rounded-[30px]'
        visible={openDeleteModal}
        footer={false}
        destroyOnClose
        centered
        closable={false}
        onCancel={() => console.log('on cancel')}
      >
      <div  className={styles.deleteModal}>
      <div  className={classNames(styles.deleteModalTitle, 'text-[18px] text-[#18191A] text-center font-semibold ')}>
              {modalOptions.title}
        </div>
        <div   className={classNames(styles.deleteModalContent, 'text-[14px] text-[#515864] text-center font-medium ')}>
            {modalOptions.text}
        </div>
        <Row justify="end"   className={classNames(styles.deleteModalFooter, 'mt-[40px]')}>
          <Space size={16}>
            <DButton type="white" className="!w-[148px] !h-[38px]" onClick={()=>setOpenDeleteModal(false)}>
                  Cancel
            </DButton>
            <DButton
              type="white"
              className="!w-[148px] !h-[38px] text-[#C1281C] !border-[#C1281C]"
              onClick={onDelete}
              button
              disabled={false }
              loading={false}
            >
             Delete
            </DButton>
          </Space>
        </Row>
      </div>
      </Modal>
    
    
    </>
  )
}

export default Videos;
