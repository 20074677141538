import './MaskLoader.css';


const MaskLoader = () =>{

  return(
   

    <div className="overlay">
    <div className="lmask">
      <div className="loading">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        {/* <div className="flex flex-col items-center justify-end col-span-6 sm:col-span-3 xl:col-span-2">
            <LoadingIcon icon="circles" className="w-16 h-16" />
        </div> */}
      </div>
    </div>
  </div>
    
    
  )

}


export default MaskLoader;
