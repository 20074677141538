import React, { useState } from 'react';
import DButton from 'components/DButton';
import ResetPassContainer from '../Containers/ResetPassContainer';
import { Form } from 'antd';
import Input from 'components/Input';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from 'api/auth';
import useUser from 'redux/user/use-user';

const SetNewPassword = () => {
  const [loading, setLoading] = useState(false);
  const { emailForUserReset, codeForUserReset } = useUser();
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const onFinish = async ({ password }: any) => {
    try {
      setLoading(true);
      await resetPassword({
        email: emailForUserReset,
        code: codeForUserReset,
        password
      });
      navigate('/auth/pass-changed');
    } catch (error: any) {
      console.log(error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResetPassContainer>
      <div className="flex flex-col justify-center items-center w-[376px] mt-[75px]">
        <Form onFinish={onFinish} autoComplete="off" form={form} validateTrigger={['onBlur']}>
          <p className="text-base text-doers-black font-bold mb-6 text-center">
            Create a new password
          </p>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'This field is required.' },
              { min: 6, message: 'Password must contain 6 characters.' },
              { max: 60, message: 'Password must be no longer than 60 characters.' }
            ]}
            className="mb-2"
          >
            <Input
              label="Password (6-60 characters)*"
              size="lg"
              type="password"
              iconRender={(visible: boolean) => (visible ? 'Hide' : 'Show')}
            />
          </Form.Item>
          <Form.Item
            name="repeatPassword"
            dependencies={['password']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              { min: 6, message: 'Password must contain 6 characters.' },
              { max: 60, message: 'Password must be no longer than 60 characters.' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                }
              })
            ]}
          >
            <Input
              label="Repeat Password*"
              size="lg"
              type="password"
              iconRender={(visible: boolean) => (visible ? 'Hide' : 'Show')}
            />
          </Form.Item>
          <div className="mt-6">
            <DButton text="Reset" className="w-[376px]" type="default" submit loading={loading} />
          </div>
        </Form>
      </div>
    </ResetPassContainer>
  );
};

export default SetNewPassword;
