import { Row, Col, Image, Upload, Typography, Space } from 'antd';
import DashedBorderCard from './DashedBorderCard';
import { alert } from 'helpers';
import { IUploadFilesProps } from './types';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Video from 'components/Video';

const { Text } = Typography;

const imageFormats = ['jpeg', 'jpg', 'png'];
const videoFormats = ['mp4', 'webm', 'x-msvideo', 'mpeg', 'quicktime', 'mov'];

const Formats = {
  image: imageFormats,
  video: videoFormats,
  media: [...imageFormats, ...videoFormats]
};

const DeleteIcon = ({ onClickHandle }: { onClickHandle: () => void }) => (
  <Col className="absolute z-10 right-[-6px] top-[-6px] h-[100px]">
    <Row
      className="w-8 h-8 bg-[#F8F8F9] rounded-full color-[#ffffff] border-2 border-[#ffffff] cursor-pointer"
      onClick={() => onClickHandle()}
      justify="center"
      align="middle"
    >
      <i className="icon-24" />
    </Row>
  </Col>
);

const UploadFiles = ({
  uploadingFilesType,
  onUploadHandle,
  filesList,
  maxUploadedFiles,
  multipleSelect,
  error,
  className,
  onDeleteHandle
}: IUploadFilesProps) => {
  const [videoUrlObjMap, setVideoUrlObjMap] = useState<{
    [key: string]: {
      src: string;
      count: number;
    };
  }>({ '': { src: '', count: 0 } });
  const maxFileSize = uploadingFilesType === 'image' ? 10 : 100;

  const Texts = {
    image: [
      'Upload Photo',
      `You can upload up to ${maxUploadedFiles} photos.\n We support 
      ${Formats.image.map(item => `.${item.toUpperCase()}`).join(', ')} formats.`
    ],
    video: [
      'Upload Video',
      `The video should be no longer than 1 minute.\n We support 
      ${Formats.video.map(item => `.${item.toUpperCase()}`).join(', ')} formats.`
    ],
    media: [
      'Upload Media',
      `You can upload up to ${maxUploadedFiles} photos/videos.\n We support
      ${Formats.media.map(item => `.${item.toUpperCase()}`).join(', ')} formats.`
    ]
  };

  const loadVideo = (file: any) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject('Invalid video. Please select a video file.');
        };

        video.src = window.URL.createObjectURL(file);
        if (!videoUrlObjMap[file.name]) {
          setVideoUrlObjMap({
            ...videoUrlObjMap,
            [file.name]: {
              count: 1,
              src: video.src
            }
          });
        } else {
          setVideoUrlObjMap({
            ...videoUrlObjMap,
            [file.name]: {
              count: videoUrlObjMap[file.name].count + 1,
              src: video.src
            }
          });
        }
      } catch (e) {
        reject(e);
      }
    });

  const beforeUpload = async (file: any) => {
    const fileFormats = Formats[uploadingFilesType];
    let isCorrectType = false;
    const fileType = file.type.split('/');

    if (fileType[0] === 'video') {
      const video: any = await loadVideo(file);
      if (video.duration > 60) {
        alert.error('The video should be no longer than 1 minute.');
        return Upload.LIST_IGNORE;
      }
    }

    isCorrectType = fileFormats.includes(fileType[1]);

    if (!isCorrectType) {
      alert.error(`Please upload files only with ${fileFormats.toString()} types.`);
      return Upload.LIST_IGNORE;
    }

    let isLessThanMaxFileSize = file.size / 1024 / 1024 < maxFileSize;
    if (!isLessThanMaxFileSize) {
      alert.error(`Max file size ${maxFileSize}mb.`);
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const videoUrlObjCacheClean = (file: any) => {
    const temp = { ...videoUrlObjMap };
    const tempFile = temp[file.name];
    temp[file.name].count--;
    if (tempFile.count === 0) {
      delete temp[file.name];
    }
    setVideoUrlObjMap(temp);
  };

  const errorStyle = { 'border border-icon-err': error };
  return filesList.length ? (
    <Row className={classNames('ml-[9px]', className)}>
      {filesList.map((item, index) => {
        const fileType = item.type.split('/')[0];
        return (
          <Col className="mr-[18px]" key={item.uid}>
            {fileType === 'image' ? (
              <Col className="relative">
                <DeleteIcon
                  onClickHandle={() => {
                    onDeleteHandle(index);
                  }}
                />
                <Image
                  width={100}
                  height={100}
                  className={'rounded-2xl'}
                  preview={false}
                  src={URL.createObjectURL(item.originFileObj)}
                />
              </Col>
            ) : (
              <Col key={item.uid}>
                <DeleteIcon
                  onClickHandle={() => {
                    onDeleteHandle(index);
                    videoUrlObjCacheClean(item);
                  }}
                />
                <Col className="!rounded-2xl overflow-hidden w-[100px] h-[100px]">
                  <Video blob={videoUrlObjMap[item.name].src} size="sm-job-post" />
                </Col>
              </Col>
            )}
          </Col>
        );
      })}
      <Upload
        beforeUpload={beforeUpload}
        onChange={onUploadHandle}
        maxCount={maxUploadedFiles}
        fileList={filesList}
        showUploadList={false}
        multiple={multipleSelect}
        accept={Formats[uploadingFilesType].map(x => `.${x}`).join(',')}
      >
        {!(filesList.length >= maxUploadedFiles) && (
          <DashedBorderCard classes={classNames('w-[100px] h-[100px]', errorStyle)}>
            <Row justify="center" align="middle" className="w-full h-full">
              <Text className="font-bold" underline>
                Upload
              </Text>
            </Row>
          </DashedBorderCard>
        )}
      </Upload>
    </Row>
  ) : (
    <DashedBorderCard classes={classNames('border-[#BBBCBC]', errorStyle, className)}>
      <Row justify="center" className="mt-[23px] mb-[22px]">
        <Space
          direction="vertical"
          align="center"
          className="text-center tracking-[0px] leading-[17px] whitespace-pre-line"
          size={12}
        >
          <Upload
            beforeUpload={beforeUpload}
            onChange={onUploadHandle}
            listType="text"
            showUploadList={false}
            fileList={filesList}
            maxCount={maxUploadedFiles}
            multiple={multipleSelect}
            accept={Formats[uploadingFilesType].map(x => `.${x}`).join(',')}
          >
            <Space direction="vertical">
              <Text className="font-bold cursor-pointer font-bold " underline>
                {Texts[uploadingFilesType][0]}
              </Text>
              <Text className="text-font-12" italic>
                {Texts[uploadingFilesType][1]}
              </Text>
            </Space>
          </Upload>
        </Space>
      </Row>
    </DashedBorderCard>
  );
};

export default UploadFiles;
