import { Typography } from 'antd';
import DButton from 'components/DButton';
import review from 'assets/images/review.svg';
import styles from '../index.module.scss';
import useDashboard from 'redux/dashboard/use-dashboard';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const EmptyChat = () => {
  const { filters, changeFilters } = useDashboard();
  const navigate = useNavigate();

  return (
    <div className={styles.emptyChatCont}>
      <div className="w-[120px] h-[120px] mb-5 rounded-full">
        <img src={review} alt="review" />
      </div>
      <Text className="text-font-26 font-bold">No Messages, yet</Text>
      <Text className="text-center text-font-16 text-grayot mt-3 mb-6">
        There are no chats yet,
        <br /> Apply to jobs to start the conversation.
      </Text>
      <DButton
        type="white"
        className="font-bold text-sm !w-[224px] h-[54px]"
        text="Explore Jobs"
        onClick={() => {
          changeFilters({ ...filters, lookingFor: 'jobs' });
          navigate('/explore');
        }}
      />
    </div>
  );
};

export default EmptyChat;
