/** @format */

import * as yup from 'yup';
import { UserItem } from '../user';
import { JobItemLite } from '../job';
import { JobContractItemLite } from '../job-contract';
import { mediaTypes, MediaLiteItem } from '../media';

export type ChatMessageType =
  | 'TEXT'
  | 'MEDIA'
  | 'FILE'
  | 'SYSTEM'
  | 'MASTER_APPLY_JOB' // role: master | show Review & Accept button to doer
  | 'MASTER_APPLY_JOB_EDITED' // role: master | show Review & Accept button to doer
  | 'DOER_APPLY_JOB_ACCEPTED' // role: doer | show start button to master
  | 'DOER_APPLY_JOB_REJECTED' // role: doer | no buttons
  | 'DOER_INVITE_TO_JOB' // role: doer | show Review & accept button to master
  | 'DOER_INVITE_TO_JOB_EDITED' // role: doer | show Review & accept button to master
  | 'MASTER_INVITATION_ACCEPTED' // role: master | show start button to master
  | 'MASTER_INVITATION_REJECTED' // role: master | show Explor jobs button to master & show Explor Masters button to doer
  | 'MASTER_JOB_STARTED' // show Mark as completed button to master
  | 'MASTER_JOB_RESUMED' // show Mark as completed button to master
  | 'MASTER_JOB_MARKED_AS_COMPLETED' // show Report issue and Approve buttons to doer
  | 'DOER_JOB_COMPLETION_APPROVED' // show Leave a tip button to doer
  | 'DOER_JOB_COMPLETION_REPORT_ISSUE' // role: doer | show Decline & Accept issue buttons to master
  | 'MASTER_JOB_COMPLETION_REPORT_ISSUE_ACCEPTED' // role: master | show Resume the job button to master
  | 'MASTER_JOB_COMPLETION_REPORT_ISSUE_DECLINED' // role: master | show Cancel Issue & Approve the job & Pass the issue to Admin buttons to doer
  | 'DOER_JOB_COMPLETION_REPORT_ISSUE_CANCELED' // role: doer | show Leave a tip button to doer
  | 'DOER_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN' // role: doer | no button
  | 'SYSTEM_JOB_COMPLETION_REPORT_ISSUE_TO_ADMIN' // role: doer | no button
  | 'DOER_JOB_PAYMENT_FAILED_ATTEMPTS_1' // role: doer | no button
  | 'DOER_JOB_PAYMENT_FAILED_ATTEMPTS_2' // role: doer | no button
  | 'DOER_JOB_PAYMENT_FAILED_FINAL_ATTEMPTS' // show Rate the experience button to doer and master
  | 'DOER_JOB_PAID' // show Rate the experience button to doer and master
  | 'DOER_JOB_TIP_FAILED_ATTEMPTS_1' // role: doer | no button
  | 'DOER_JOB_TIP_FAILED_ATTEMPTS_2' // role: doer | no button
  | 'DOER_JOB_TIP_FAILED_FINAL_ATTEMPTS' // role: doer | no button
  | 'DOER_JOB_TIPPED' // role: doer | no button
  | 'DOER_FEEDBACK_LEFT' // role: doer | no button
  | 'MASTER_FEEDBACK_LEFT' // role: master | no button
  | 'MASTER_CANCELED_OFFER' // role: master | no button
  | 'DOER_CANCELED_OFFER'; // role: doer | no button;

// export type SystemMessageState = 'NEW' | 'ACCEPTED' | 'REJECTED' | 'INACTIVE';

export type SystemMessageState =
  | 'NEW'
  | 'ACCEPTED'
  | 'APPROVED'
  | 'REJECTED'
  | 'CANCELED'
  | 'JOB_STARTED'
  | 'JOB_RESUMED'
  | 'MARKED_AS_COMPLETED'
  | 'ISSUE_REPORTED'
  | 'ISSUE_ACCEPTED'
  | 'ISSUE_DECLINED'
  | 'ISSUE_PASSED_ADMIN'
  | 'TIP_LEFT'
  | 'FEEDBACK_LEFT_DOER'
  | 'FEEDBACK_LEFT_MASTER'
  | 'FEEDBACK_LEFT_BOTH'
  | 'INACTIVE';

export type ChatMessageStatus = 'PENDING' | 'PUBLISHED';

export type SendChatMessageRequest = {
  message: string;
  type: ChatMessageType;
  parentMessageId?: string;
  state?: SystemMessageState;
  lastStateChanged?: string;
  groupId?: string;
  uuid?: string;
};

export const ChatMessageSendRequestYup = yup.object().shape({
  message: yup
    .string()
    .max(1000)
    .when('type', {
      is: 'TEXT',
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  type: yup.string().oneOf(['TEXT', 'MEDIA', 'SYSTEM']).required(),
  groupId: yup.string().optional(),
  uuid: yup.string().optional(),
});

export type ChatMessageSeenBy = {
  userId: string;
  seenAt: Date;
};

export type ChatMessageItem = {
  _id?: string;
  channel: string;
  sender?: string;
  receiver?: string;
  job: string;
  type: ChatMessageType;
  status: ChatMessageStatus;
  message?: string;
  files?: Array<MediaLiteItem>;
  parentMessageId?: string;
  state?: SystemMessageState;
  lastStateChanged?: string;
  groupId?: string;
  metadata?: ChatMessageMetadata;
  uuid?: string;
  seenBy: Array<ChatMessageSeenBy>;
  createdAt?: string;
  updatedAt?: string;
};

export type ChatChannelType = 'MASTER_APPLY_JOB' | 'DOER_INVITE_TO_JOB';
export type ChatChannelStatus = 'PENDING' | 'PUBLISHED';

export type ChatChannelItem = {
  _id?: string;
  master: string | UserItem;
  doer: string | UserItem;
  job: string | JobItemLite;
  jobContract: string | JobContractItemLite;
  type: ChatChannelType;
  status: ChatChannelStatus;
  lastMessageUserId?: string;
  lastMessage?: string;
  lastMessageType?: ChatMessageType;
  lastMessageMetadata?: ChatMessageMetadata;
  unseenCount?: number;
  createdAt?: string;
  updatedAt?: string;
};

export type SendApplyJobMessageRequest = {
  userId: string;
  jobId: string;
  jobContractId: string;
  message: string;
  type: ChatMessageType;
  hourlyRate?: number;
  flatPrice?: number;
  channelId?: string;
};

export type SendJobInvitationMessageRequest = {
  userId: string;
  masterId: string;
  jobId: string;
  jobContractId: string;
  message: string;
  type: ChatMessageType;
  channelId?: string;
};

export type ChatCommonFilterRequest = {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  next?: string;
  previous?: string;
};

export type ChatChannelFilterRequest = ChatCommonFilterRequest & {
  user?: string;
  type?: ChatChannelType;
};

export type ChatChannelAdvancedFilterRequest = ChatCommonFilterRequest & {
  job?: string;
  user?: string;
  type?: ChatChannelType;
};

export type ChatFilterRequest = ChatCommonFilterRequest & {
  channel: string;
  user: string;
  type?: ChatMessageType;
  next?: string;
  previous?: string;
};

export type ChatFilterAdvancedRequest = ChatFilterRequest & {
  user?: string;
};

export const AttachMediaToChatMessageRequestSchema = yup.object().shape({
  files: yup
    .array()
    .max(5)
    .of(
      yup
        .object()
        .required()
        .shape({
          path: yup.string().required(),
          type: yup.string().oneOf(mediaTypes).required(),
        }),
    ),
});

/** Payload object for attaching media to a job */
export type AttachMediaChatMessageRequest = {
  files: Array<MediaLiteItem>;
};

export const ChangeSystemMessageStateByIdRequestSchema = yup.object().shape({
  state: yup.string().required(),
});

/** Payload object for changing state of system message */
export type ChangeSystemMessageStateByIdRequest = {
  state: SystemMessageState;
  metadata?: ChatMessageMetadata;
};

export const ChangeSystemMessageStateByTypeRequestSchema = yup.object().shape({
  type: yup.string().required(),
  state: yup.string().required(),
});

export type ChatMessageMetadata = {
  paidAmount?: number;
  tipAmount?: number;
  amountToPay?: number;
  doerRatingStart?: number;
  masterRatingStart?: number;
  feedbackText?: string;
  hoursWorked?: number;
  hourlyRate?: number;
};

/** Payload object for changing state of system messages */
export type ChangeSystemMessageStateByTypeRequest = {
  type: ChatMessageType;
  state: SystemMessageState;
  metadata?: ChatMessageMetadata;
};

export type SendSystemMessageRequest = {
  sender: string;
  receiver?: string;
  message?: string;
  type: ChatMessageType;
  parentMessageId?: string;
  jobContract?: JobContractItemLite;
  groupId?: string;
  metadata?: ChatMessageMetadata;
};

export type PublishMessagesByGroupIdRequest = {
  channelId: string;
};

export type UnseenMessagesCountByChannel = {
  _id: string;
  count: number;
};

export type UnseenMessagesCountResponse = {
  channels: Array<UnseenMessagesCountByChannel>;
  totalUnseenCount: number;
};
