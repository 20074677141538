/** @format */

export const personalEmailDomains: {
  [key: string]: boolean;
} = {
  '123.com': true,
  '123box.net': true,
  '123india.com': true,
  '123mail.cl': true,
  '123qwe.co.uk': true,
  '150ml.com': true,
  '15meg4free.com': true,
  '163.com': true,
  '1coolplace.com': true,
  '1freeemail.com': true,
  '1funplace.com': true,
  '1internetdrive.com': true,
  '1mail.net': true,
  '1me.net': true,
  '1mum.com': true,
  '1musicrow.com': true,
  '1netdrive.com': true,
  '1nsyncfan.com': true,
  '1under.com': true,
  '1webave.com': true,
  '1webhighway.com': true,
  '212.com': true,
  '24horas.com': true,
  '2911.net': true,
  '2d2i.com': true,
  '2die4.com': true,
  '3000.it': true,
  '37.com': true,
  '3ammagazine.com': true,
  '3email.com': true,
  '3xl.net': true,
  '444.net': true,
  '4email.com': true,
  '4email.net': true,
  '4mg.com': true,
  '4newyork.com': true,
  '4x4man.com': true,
  '5iron.com': true,
  '88.am': true,
  '8848.net': true,
  'aaronkwok.net': true,
  'abbeyroadlondon.co.uk': true,
  'abdulnour.com': true,
  'aberystwyth.com': true,
  'about.com': true,
  'academycougars.com': true,
  'acceso.or.cr': true,
  'access4less.net': true,
  'accessgcc.com': true,
  'ace-of-base.com': true,
  'acmemail.net': true,
  'acninc.net': true,
  'adres.pl': true,
  'adios.net': true,
  'ados.fr': true,
  'advalvas.be': true,
  'aeiou.pt': true,
  'aemail4u.com': true,
  'aeneasmail.com': true,
  'afreeinternet.com': true,
  'agoodmail.com': true,
  'ahaa.dk': true,
  'aichi.com': true,
  'airpost.net': true,
  'ajacied.com': true,
  'aim.com': true,
  'ak47.hu': true,
  'aknet.kg': true,
  'albawaba.com': true,
  'alex4all.com': true,
  'alexandria.cc': true,
  'algeria.com': true,
  'alhilal.net': true,
  'alibaba.com': true,
  'alive.cz': true,
  'allmail.net': true,
  'alloymail.com': true,
  'allsaintsfan.com': true,
  'alskens.dk': true,
  'altavista.com': true,
  'altavista.se': true,
  'alternativagratis.com': true,
  'altmails.com': true,
  'alvilag.hu': true,
  'amele.com': true,
  'america.hm': true,
  'amnetsal.com': true,
  'amrer.net': true,
  'amuro.net': true,
  'amuromail.com': true,
  'ananzi.co.za': true,
  'andylau.net': true,
  'anfmail.com': true,
  'angelfire.com': true,
  'animalwoman.net': true,
  'anjungcafe.com': true,
  'anonmails.de': true,
  'anonymbox.com': true,
  'another.com': true,
  'antichef.com': true,
  'antichef.net': true,
  'antireg.ru': true,
  'antispam.de': true,
  'antispammail.de': true,
  'antisocial.com': true,
  'antongijsen.com': true,
  'antwerpen.com': true,
  'anymoment.com': true,
  'anytimenow.com': true,
  'aol.com': true,
  'apexmail.com': true,
  'apollo.lv': true,
  'approvers.net': true,
  'arabia.com': true,
  'arabtop.net': true,
  'arcor.de': true,
  'arcotronics.bg': true,
  'argentina.com': true,
  'armyspy.com': true,
  'arnet.com.ar': true,
  'artlover.com.au': true,
  'artman-conception.com': true,
  'as-if.com': true,
  'asean-mail.com': true,
  'asheville.com': true,
  'asia-links.com': true,
  'asianavenue.com': true,
  'asiancityweb.com': true,
  'asianwired.net': true,
  'assala.com': true,
  'assamesemail.com': true,
  'astroboymail.com': true,
  'astrolover.com': true,
  'asurfer.com': true,
  'athenachu.net': true,
  'atina.cl': true,
  'atl.lv': true,
  'atlaswebmail.com': true,
  'atozasia.com': true,
  'au.ru': true,
  'ausi.com': true,
  'australia.edu': true,
  'austrosearch.net': true,
  'autoescuelanerja.com': true,
  'autograf.pl': true,
  'avh.hu': true,
  'ayna.com': true,
  'azimiweb.com': true,
  'azmeil.tk': true,
  'bachelorboy.com': true,
  'bachelorgal.com': true,
  'backstreet-boys.com': true,
  'backstreetboysclub.com': true,
  'bagherpour.com': true,
  'baptistmail.com': true,
  'baptized.com': true,
  'barcelona.com': true,
  'batuta.net': true,
  'baudoinconsulting.com': true,
  'baxomale.ht.cx': true,
  'bboygarage.com': true,
  'bcaoo.com': true,
  'bcvibes.com': true,
  'beeebank.com': true,
  'beefmilk.com': true,
  'beenhad.com': true,
  'beep.ru': true,
  'beer.com': true,
  'beethoven.com': true,
  'belice.com': true,
  'belizehome.com': true,
  'berlin.de': true,
  'berlinexpo.de': true,
  'bestmail.us': true,
  'bharatmail.com': true,
  'bigblue.net.au': true,
  'bigboab.com': true,
  'bigfoot.com': true,
  'bigfoot.de': true,
  'bigger.com': true,
  'bigmailbox.com': true,
  'bigramp.com': true,
  'bigstring.com': true,
  'bikemechanics.com': true,
  'bikeracers.net': true,
  'bimla.net': true,
  'binkmail.com': true,
  'bio-muesli.net': true,
  'birdowner.net': true,
  'bitpage.net': true,
  'bizhosting.com': true,
  'blackburnmail.com': true,
  'blackplanet.com': true,
  'blazemail.com': true,
  'bluehyppo.com': true,
  'bluemail.ch': true,
  'bluemail.dk': true,
  'blushmail.com': true,
  'bmlsports.net': true,
  'bobmail.info': true,
  'bol.com.br': true,
  'bolando.com': true,
  'bollywoodz.com': true,
  'bolt.com': true,
  'boltonfans.com': true,
  'bonbon.net': true,
  'boom.com': true,
  'bootmail.com': true,
  'bornnaked.com': true,
  'bostonoffice.com': true,
  'bounce.net': true,
  'box.az': true,
  'boxbg.com': true,
  'boxemail.com': true,
  'boxfrog.com': true,
  'boyzoneclub.com': true,
  'bradfordfans.com': true,
  'brasilia.net': true,
  'brazilmail.com.br': true,
  'breathe.com': true,
  'brfree.com.br': true,
  'britneyclub.com': true,
  'brittonsign.com': true,
  'btc.glass': true,
  'btopenworld.co.uk': true,
  'bullsfan.com': true,
  'bullsgame.com': true,
  'bumerang.ro': true,
  'buryfans.com': true,
  'business-man.com': true,
  'businessman.net': true,
  'bvimailbox.com': true,
  'c2i.net': true,
  'c3.hu': true,
  'c4.com': true,
  'caere.it': true,
  'cairomail.com': true,
  'callnetuk.com': true,
  'caltanet.it': true,
  'camidge.com': true,
  'canada-11.com': true,
  'canada.com': true,
  'canoemail.com': true,
  'canwetalk.com': true,
  'caramail.com': true,
  'care2.com': true,
  'carioca.net': true,
  'cartestraina.ro': true,
  'catcha.com': true,
  'cd2.com': true,
  'celineclub.com': true,
  'centoper.it': true,
  'centralpets.com': true,
  'centrum.cz': true,
  'centrum.sk': true,
  'cgac.es': true,
  'chaiyomail.com': true,
  'chance2mail.com': true,
  'chandrasekar.net': true,
  'chat.ru': true,
  'chattown.com': true,
  'chauhanweb.com': true,
  'check1check.com': true,
  'chequemail.com': true,
  'chickmail.com': true,
  'china.net.vg': true,
  'chirk.com': true,
  'chocaholic.com.au': true,
  'cia-agent.com': true,
  'cia.hu': true,
  'ciaoweb.it': true,
  'cicciociccio.com': true,
  'city-of-bath.org': true,
  'city-of-birmingham.com': true,
  'city-of-brighton.org': true,
  'city-of-cambridge.com': true,
  'city-of-coventry.com': true,
  'city-of-edinburgh.com': true,
  'city-of-lichfield.com': true,
  'city-of-lincoln.com': true,
  'city-of-liverpool.com': true,
  'city-of-manchester.com': true,
  'city-of-nottingham.com': true,
  'city-of-oxford.com': true,
  'city-of-swansea.com': true,
  'city-of-westminster.com': true,
  'city-of-westminster.net': true,
  'city-of-york.net': true,
  'cityofcardiff.net': true,
  'cityoflondon.org': true,
  'claramail.com': true,
  'classicmail.co.za': true,
  'cliffhanger.com': true,
  'close2you.net': true,
  'club4x4.net': true,
  'clubalfa.com': true,
  'clubbers.net': true,
  'clubducati.com': true,
  'clubhonda.net': true,
  'cluemail.com': true,
  'coder.hu': true,
  'coid.biz': true,
  'compuserve.com': true,
  'computer-freak.com': true,
  'computermail.net': true,
  'conexcol.com': true,
  'connect4free.net': true,
  'connectbox.com': true,
  'cookiemonster.com': true,
  'cool.br': true,
  'coolgoose.ca': true,
  'coolgoose.com': true,
  'coolkiwi.com': true,
  'coollist.com': true,
  'coolmail.com': true,
  'coolmail.net': true,
  'coolsend.com': true,
  'cooooool.com': true,
  'cooperation.net': true,
  'cooperationtogo.net': true,
  'copacabana.com': true,
  'cornerpub.com': true,
  'corporatedirtbag.com': true,
  'correo.terra.com.gt': true,
  'cortinet.com': true,
  'cotas.net': true,
  'countrylover.com': true,
  'cracker.hu': true,
  'crazedanddazed.com': true,
  'crazysexycool.com': true,
  'critterpost.com': true,
  'croeso.com': true,
  'crosswinds.net': true,
  'cry4helponline.com': true,
  'cs.com': true,
  'csinibaba.hu': true,
  'cuoly.com': true,
  'curio-city.com': true,
  'cute-girl.com': true,
  'cuteandcuddly.com': true,
  'cww.de': true,
  'cyberbabies.com': true,
  'cyberforeplay.net': true,
  'cyberia.pl': true,
  'cyberinbox.com': true,
  'cyberleports.com': true,
  'cybernet.it': true,
  'dabsol.net': true,
  'dadacasa.com': true,
  'dailypioneer.com': true,
  'dangerous-minds.com': true,
  'dansegulvet.com': true,
  'data54.com': true,
  'davegracey.com': true,
  'dazedandconfused.com': true,
  'dcemail.com': true,
  'deadlymob.org': true,
  'deal-maker.com': true,
  'dearriba.com': true,
  'death-star.com': true,
  'desertmail.com': true,
  'desilota.com': true,
  'deskpilot.com': true,
  'detik.com': true,
  'devotedcouples.com': true,
  'dfwatson.com': true,
  'di-ve.com': true,
  'disinfo.net': true,
  'dmailman.com': true,
  'dnsmadeeasy.com': true,
  'dogmail.co.uk': true,
  'dogsnob.net': true,
  'doityourself.com': true,
  'doneasy.com': true,
  'donjuan.com': true,
  'dontgotmail.com': true,
  'dontmesswithtexas.com': true,
  'dostmail.com': true,
  'dotcom.fr': true,
  'dott.it': true,
  'dplanet.ch': true,
  'dragoncon.net': true,
  'dropzone.com': true,
  'dubaimail.com': true,
  'dublin.ie': true,
  'dygo.com': true,
  'dynamitemail.com': true,
  'e-apollo.lv': true,
  'e-mail.dk': true,
  'e-mail.ru': true,
  'e-mailanywhere.com': true,
  'e-mails.ru': true,
  'e-tapaal.com': true,
  'earthalliance.com': true,
  'earthdome.com': true,
  'eastcoast.co.za': true,
  'eastmail.com': true,
  'ecbsolutions.net': true,
  'echina.com': true,
  'ednatx.com': true,
  'educacao.te.pt': true,
  'eircom.net': true,
  'elsitio.com': true,
  'elvis.com': true,
  'email-london.co.uk': true,
  'email.cz': true,
  'email.ee': true,
  'email.it': true,
  'email.nu': true,
  'email.ro': true,
  'email.ru': true,
  'email.si': true,
  'email2me.net': true,
  'emailacc.com': true,
  'emailaccount.com': true,
  'emailchoice.com': true,
  'emailcorner.net': true,
  'emailengine.net': true,
  'emailforyou.net': true,
  'emailgroups.net': true,
  'emailpinoy.com': true,
  'emailplanet.com': true,
  'emails.ru': true,
  'emailuser.net': true,
  'emailx.net': true,
  'ematic.com': true,
  'end-war.com': true,
  'enel.net': true,
  'england.com': true,
  'england.edu': true,
  'eoopy.com': true,
  'epatra.com': true,
  'epost.de': true,
  'eposta.hu': true,
  'eqqu.com': true,
  'eramail.co.za': true,
  'eresmas.com': true,
  'eriga.lv': true,
  'estranet.it': true,
  'etoast.com': true,
  'eudoramail.com': true,
  'euroseek.com': true,
  'every1.net': true,
  'everyday.com.kh': true,
  'everyone.net': true,
  'examnotes.net': true,
  'excite.co.jp': true,
  'excite.com': true,
  'excite.it': true,
  'expressasia.com': true,
  'extended.com': true,
  'eyou.com': true,
  'ezcybersearch.com': true,
  'ezmail.egine.com': true,
  'ezmail.ru': true,
  'ezrs.com': true,
  'f1fans.net': true,
  'fantasticmail.com': true,
  'faroweb.com': true,
  'fastem.com': true,
  'fastemail.us': true,
  'fastemailer.com': true,
  'fastermail.com': true,
  'fastimap.com': true,
  'fastmail.fm': true,
  'fastmailbox.net': true,
  'fastmessaging.com': true,
  'fatcock.net': true,
  'fathersrightsne.org': true,
  'fbi-agent.com': true,
  'fbi.hu': true,
  'federalcontractors.com': true,
  'femenino.com': true,
  'feyenoorder.com': true,
  'fexbox.org': true,
  'fexbox.ru': true,
  'ffanet.com': true,
  'fiberia.com': true,
  'filipinolinks.com': true,
  'financemail.net': true,
  'findmail.com': true,
  'finebody.com': true,
  'fire-brigade.com': true,
  'fishburne.org': true,
  'flashmail.com': true,
  'flipcode.com': true,
  'fmail.co.uk': true,
  'fmailbox.com': true,
  'fmgirl.com': true,
  'fmguy.com': true,
  'fnbmail.co.za': true,
  'fnmail.com': true,
  'for-president.com': true,
  'forfree.at': true,
  'forpresident.com': true,
  'fortuncity.com': true,
  'forum.dk': true,
  'free.com.pe': true,
  'free.fr': true,
  'freeaccess.nl': true,
  'freeandsingle.com': true,
  'freedomlover.com': true,
  'freegates.be': true,
  'freeghana.com': true,
  'freeler.nl': true,
  'freemail.com.au': true,
  'freemail.com.pk': true,
  'freemail.de': true,
  'freemail.et': true,
  'freemail.gr': true,
  'freemail.hu': true,
  'freemail.it': true,
  'freemail.lt': true,
  'freemail.nl': true,
  'freemail.org.mk': true,
  'freenet.de': true,
  'freenet.kg': true,
  'freeola.com': true,
  'freeola.net': true,
  'freeserve.co.uk': true,
  'freestart.hu': true,
  'freesurf.fr': true,
  'freesurf.nl': true,
  'freeuk.com': true,
  'freeuk.net': true,
  'freeukisp.co.uk': true,
  'freeweb.org': true,
  'freewebemail.com': true,
  'freeyellow.com': true,
  'freezone.co.uk': true,
  'fresnomail.com': true,
  'friendsfan.com': true,
  'from-africa.com': true,
  'from-america.com': true,
  'from-argentina.com': true,
  'from-asia.com': true,
  'from-australia.com': true,
  'from-belgium.com': true,
  'from-brazil.com': true,
  'from-canada.com': true,
  'from-china.net': true,
  'from-england.com': true,
  'from-europe.com': true,
  'from-france.net': true,
  'from-germany.net': true,
  'from-holland.com': true,
  'from-israel.com': true,
  'from-italy.net': true,
  'from-japan.net': true,
  'from-korea.com': true,
  'from-mexico.com': true,
  'from-outerspace.com': true,
  'from-russia.com': true,
  'from-spain.net': true,
  'fromalabama.com': true,
  'fromalaska.com': true,
  'fromarizona.com': true,
  'fromarkansas.com': true,
  'fromcalifornia.com': true,
  'fromcolorado.com': true,
  'fromconnecticut.com': true,
  'fromdelaware.com': true,
  'fromflorida.net': true,
  'fromgeorgia.com': true,
  'fromhawaii.net': true,
  'fromidaho.com': true,
  'fromillinois.com': true,
  'fromindiana.com': true,
  'fromiowa.com': true,
  'fromjupiter.com': true,
  'fromkansas.com': true,
  'fromkentucky.com': true,
  'fromlouisiana.com': true,
  'frommaine.net': true,
  'frommaryland.com': true,
  'frommassachusetts.com': true,
  'frommiami.com': true,
  'frommichigan.com': true,
  'fromminnesota.com': true,
  'frommississippi.com': true,
  'frommissouri.com': true,
  'frommontana.com': true,
  'fromnebraska.com': true,
  'fromnevada.com': true,
  'fromnewhampshire.com': true,
  'fromnewjersey.com': true,
  'fromnewmexico.com': true,
  'fromnewyork.net': true,
  'fromnorthcarolina.com': true,
  'fromnorthdakota.com': true,
  'fromohio.com': true,
  'fromoklahoma.com': true,
  'fromoregon.net': true,
  'frompennsylvania.com': true,
  'fromrhodeisland.com': true,
  'fromru.com': true,
  'fromsouthcarolina.com': true,
  'fromsouthdakota.com': true,
  'fromtennessee.com': true,
  'fromtexas.com': true,
  'fromthestates.com': true,
  'fromutah.com': true,
  'fromvermont.com': true,
  'fromvirginia.com': true,
  'fromwashington.com': true,
  'fromwashingtondc.com': true,
  'fromwestvirginia.com': true,
  'fromwisconsin.com': true,
  'fromwyoming.com': true,
  'front.ru': true,
  'frostbyte.uk.net': true,
  'fsmail.net': true,
  'ftml.net': true,
  'fuorissimo.com': true,
  'furnitureprovider.com': true,
  'fut.es': true,
  'fxsmails.com': true,
  'galaxy5.com': true,
  'gamebox.net': true,
  'gawab.com': true,
  'gaza.net': true,
  'gazeta.pl': true,
  'gazibooks.com': true,
  'geek.hu': true,
  'geeklife.com': true,
  'general-hospital.com': true,
  'geopia.com': true,
  'giga4u.de': true,
  'givepeaceachance.com': true,
  'glay.org': true,
  'glendale.net': true,
  'globalfree.it': true,
  'globalpagan.com': true,
  'globalsite.com.br': true,
  'gmx.at': true,
  'gmx.de': true,
  'gmx.li': true,
  'gmx.net': true,
  'go.com': true,
  'go.ro': true,
  'go.ru': true,
  'go2net.com': true,
  'gofree.co.uk': true,
  'goldenmail.ru': true,
  'goldmail.ru': true,
  'golfemail.com': true,
  'golfmail.be': true,
  'goplay.com': true,
  'gorontalo.net': true,
  'gothere.uk.com': true,
  'gotmail.com': true,
  'gotomy.com': true,
  'gportal.hu': true,
  'graffiti.net': true,
  'gratisweb.com': true,
  'grungecafe.com': true,
  'gua.net': true,
  'guessmail.com': true,
  'guju.net': true,
  'guy.com': true,
  'guy2.com': true,
  'guyanafriends.com': true,
  'gyorsposta.com': true,
  'gyorsposta.hu': true,
  'hackermail.net': true,
  'hailmail.net': true,
  'hamptonroads.com': true,
  'handbag.com': true,
  'hang-ten.com': true,
  'happemail.com': true,
  'happycounsel.com': true,
  'hardcorefreak.com': true,
  'heartthrob.com': true,
  'heerschap.com': true,
  'heesun.net': true,
  'hehe.com': true,
  'hello.hu': true,
  'helter-skelter.com': true,
  'herediano.com': true,
  'herono1.com': true,
  'highmilton.com': true,
  'highquality.com': true,
  'highveldmail.co.za': true,
  'hispavista.com': true,
  'hkstarphoto.com': true,
  'hollywoodkids.com': true,
  'home.no.net': true,
  'home.ro': true,
  'home.se': true,
  'homelocator.com': true,
  'homestead.com': true,
  'hongkong.com': true,
  'hookup.net': true,
  'horrormail.com': true,
  'hot.ee': true,
  'hotbot.com': true,
  'hotbrev.com': true,
  'hotfire.net': true,
  'hotletter.com': true,
  'hotmail.co.il': true,
  'hotmail.com': true,
  'hotmail.fr': true,
  'hotmail.kg': true,
  'hotmail.kz': true,
  'hotmail.ru': true,
  'hotpop.com': true,
  'hotpop3.com': true,
  'hotvoice.com': true,
  'hsuchi.net': true,
  'hunsa.com': true,
  'i-france.com': true,
  'i-mail.com.au': true,
  'i-p.com': true,
  'i12.com': true,
  'iamawoman.com': true,
  'iamwaiting.com': true,
  'iamwasted.com': true,
  'iamyours.com': true,
  'icestorm.com': true,
  'icmsconsultants.com': true,
  'icq.com': true,
  'icqmail.com': true,
  'icrazy.com': true,
  'ididitmyway.com': true,
  'idirect.com': true,
  'iespana.es': true,
  'ignazio.it': true,
  'ignmail.com': true,
  'ijustdontcare.com': true,
  'ilovechocolate.com': true,
  'ilovetocollect.net': true,
  'ilse.nl': true,
  'imail.ru': true,
  'imailbox.com': true,
  'imel.org': true,
  'imneverwrong.com': true,
  'imposter.co.uk': true,
  'imstressed.com': true,
  'imtoosexy.com': true,
  'in-box.net': true,
  'inbox.net': true,
  'inbox.ru': true,
  'incamail.com': true,
  'incredimail.com': true,
  'indexa.fr': true,
  'india.com': true,
  'indiatimes.com': true,
  'infohq.com': true,
  'infomail.es': true,
  'infomart.or.jp': true,
  'infovia.com.ar': true,
  'inicia.es': true,
  'inmail.sk': true,
  'intainfo.com': true,
  'interfree.it': true,
  'interia.pl': true,
  'interlap.com.ar': true,
  'intermail.co.il': true,
  'internet-police.com': true,
  'internetbiz.com': true,
  'internetdrive.com': true,
  'internetegypt.com': true,
  'internetemails.net': true,
  'internetmailing.net': true,
  'intrees.org': true,
  'inwind.it': true,
  'iobox.com': true,
  'iobox.fi': true,
  'iol.it': true,
  'ip3.com': true,
  'iqemail.com': true,
  'irangate.net': true,
  'iraqmail.com': true,
  'irj.hu': true,
  'isellcars.com': true,
  'islamonline.net': true,
  'ismart.net': true,
  'isonfire.com': true,
  'isp9.net': true,
  'itloox.com': true,
  'itmom.com': true,
  'ivebeenframed.com': true,
  'ivillage.com': true,
  'iwan-fals.com': true,
  'iwon.com': true,
  'izadpanah.com': true,
  'jakuza.hu': true,
  'japan.com': true,
  'javadoq.com': true,
  'jaydemail.com': true,
  'jazzandjava.com': true,
  'jazzgame.com': true,
  'jetemail.net': true,
  'jippii.fi': true,
  'jmail.co.za': true,
  'joinme.com': true,
  'jordanmail.com': true,
  'jovem.te.pt': true,
  'joymail.com': true,
  'jpopmail.com': true,
  'jubiimail.dk': true,
  'jumpy.it': true,
  'juno.com': true,
  'justemail.net': true,
  'kaazoo.com': true,
  'kaixo.com': true,
  'kalpoint.com': true,
  'kapoorweb.com': true,
  'karachian.com': true,
  'karachioye.com': true,
  'karbasi.com': true,
  'katamail.com': true,
  'kayafmmail.co.za': true,
  'keg-party.com': true,
  'keko.com.ar': true,
  'kellychen.com': true,
  'kgb.hu': true,
  'khosropour.com': true,
  'kiabws.online': true,
  'kickassmail.com': true,
  'killermail.com': true,
  'kimo.com': true,
  'kinki-kids.com': true,
  'kiwibox.com': true,
  'kiwitown.com': true,
  'krunis.com': true,
  'kukamail.com': true,
  'kumarweb.com': true,
  'kuwait-mail.com': true,
  'ladymail.cz': true,
  'lagerlouts.com': true,
  'lahoreoye.com': true,
  'lakmail.com': true,
  'lamer.hu': true,
  'lancastercoc.com': true,
  'land.ru': true,
  'lankamail.com': true,
  'laposte.net': true,
  'latinmail.com': true,
  'lawyer.com': true,
  'leehom.net': true,
  'legalactions.com': true,
  'leonlai.net': true,
  'levele.com': true,
  'levele.hu': true,
  'lex.bg': true,
  'liberomail.com': true,
  'linkmaster.com': true,
  'linuxfreemail.com': true,
  'lionsfan.com.au': true,
  'liontrucks.com': true,
  'list.ru': true,
  'liverpoolfans.com': true,
  'llandudno.com': true,
  'llangollen.com': true,
  'lmxmail.sk': true,
  'localbar.com': true,
  'london.com': true,
  'looksmart.co.uk': true,
  'looksmart.com': true,
  'looksmart.com.au': true,
  'lopezclub.com': true,
  'louiskoo.com': true,
  'love.cz': true,
  'loveable.com': true,
  'lovelygirl.net': true,
  'lovemail.com': true,
  'lover-boy.com': true,
  'lovergirl.com': true,
  'lovingjesus.com': true,
  'luso.pt': true,
  'luukku.com': true,
  'lycos.co.uk': true,
  'lycos.com': true,
  'lycos.es': true,
  'lycos.it': true,
  'lycos.ne.jp': true,
  'lycosmail.com': true,
  'm-a-i-l.com': true,
  'mac.com': true,
  'machinecandy.com': true,
  'macmail.com': true,
  'madrid.com': true,
  'maffia.hu': true,
  'magicmail.co.za': true,
  'mahmoodweb.com': true,
  'mail-awu.de': true,
  'mail-box.cz': true,
  'mail-center.com': true,
  'mail-central.com': true,
  'mail-page.com': true,
  'mail.austria.com': true,
  'mail.az': true,
  'mail.be': true,
  'mail.bulgaria.com': true,
  'mail.co.za': true,
  'mail.com': true,
  'mail.ee': true,
  'mail.gr': true,
  'mail.md': true,
  'mail.nu': true,
  'mail.pf': true,
  'mail.pt': true,
  'mail.r-o-o-t.com': true,
  'mail.sisna.com': true,
  'mail.vasarhely.hu': true,
  'mail15.com': true,
  'mail2007.com': true,
  'mail2aaron.com': true,
  'mail2abby.com': true,
  'mail2abc.com': true,
  'mail2actor.com': true,
  'mail2admiral.com': true,
  'mail2adorable.com': true,
  'mail2adoration.com': true,
  'mail2adore.com': true,
  'mail2adventure.com': true,
  'mail2aeolus.com': true,
  'mail2aether.com': true,
  'mail2affection.com': true,
  'mail2afghanistan.com': true,
  'mail2africa.com': true,
  'mail2agent.com': true,
  'mail2aha.com': true,
  'mail2ahoy.com': true,
  'mail2aim.com': true,
  'mail2air.com': true,
  'mail2airbag.com': true,
  'mail2airforce.com': true,
  'mail2airport.com': true,
  'mail2alabama.com': true,
  'mail2alan.com': true,
  'mail2alaska.com': true,
  'mail2albania.com': true,
  'mail2alcoholic.com': true,
  'mail2alec.com': true,
  'mail2alexa.com': true,
  'mail2algeria.com': true,
  'mail2alicia.com': true,
  'mail2alien.com': true,
  'mail2allan.com': true,
  'mail2allen.com': true,
  'mail2allison.com': true,
  'mail2alpha.com': true,
  'mail2alyssa.com': true,
  'mail2amanda.com': true,
  'mail2amazing.com': true,
  'mail2amber.com': true,
  'mail2america.com': true,
  'mail2american.com': true,
  'mail2andorra.com': true,
  'mail2andrea.com': true,
  'mail2andy.com': true,
  'mail2anesthesiologist.com': true,
  'mail2angela.com': true,
  'mail2angola.com': true,
  'mail2ann.com': true,
  'mail2anna.com': true,
  'mail2anne.com': true,
  'mail2anthony.com': true,
  'mail2anything.com': true,
  'mail2aphrodite.com': true,
  'mail2apollo.com': true,
  'mail2april.com': true,
  'mail2aquarius.com': true,
  'mail2arabia.com': true,
  'mail2arabic.com': true,
  'mail2architect.com': true,
  'mail2ares.com': true,
  'mail2argentina.com': true,
  'mail2aries.com': true,
  'mail2arizona.com': true,
  'mail2arkansas.com': true,
  'mail2armenia.com': true,
  'mail2army.com': true,
  'mail2arnold.com': true,
  'mail2art.com': true,
  'mail2artemus.com': true,
  'mail2arthur.com': true,
  'mail2artist.com': true,
  'mail2ashley.com': true,
  'mail2ask.com': true,
  'mail2astronomer.com': true,
  'mail2athena.com': true,
  'mail2athlete.com': true,
  'mail2atlas.com': true,
  'mail2atom.com': true,
  'mail2attitude.com': true,
  'mail2auction.com': true,
  'mail2aunt.com': true,
  'mail2australia.com': true,
  'mail2austria.com': true,
  'mail2azerbaijan.com': true,
  'mail2baby.com': true,
  'mail2bahamas.com': true,
  'mail2bahrain.com': true,
  'mail2ballerina.com': true,
  'mail2ballplayer.com': true,
  'mail2band.com': true,
  'mail2bangladesh.com': true,
  'mail2bank.com': true,
  'mail2banker.com': true,
  'mail2bankrupt.com': true,
  'mail2baptist.com': true,
  'mail2bar.com': true,
  'mail2barbados.com': true,
  'mail2barbara.com': true,
  'mail2barter.com': true,
  'mail2basketball.com': true,
  'mail2batter.com': true,
  'mail2beach.com': true,
  'mail2beast.com': true,
  'mail2beatles.com': true,
  'mail2beauty.com': true,
  'mail2becky.com': true,
  'mail2beijing.com': true,
  'mail2belgium.com': true,
  'mail2belize.com': true,
  'mail2ben.com': true,
  'mail2bernard.com': true,
  'mail2beth.com': true,
  'mail2betty.com': true,
  'mail2beverly.com': true,
  'mail2beyond.com': true,
  'mail2biker.com': true,
  'mail2bill.com': true,
  'mail2billionaire.com': true,
  'mail2billy.com': true,
  'mail2bio.com': true,
  'mail2biologist.com': true,
  'mail2black.com': true,
  'mail2blackbelt.com': true,
  'mail2blake.com': true,
  'mail2blind.com': true,
  'mail2blonde.com': true,
  'mail2blues.com': true,
  'mail2bob.com': true,
  'mail2bobby.com': true,
  'mail2bolivia.com': true,
  'mail2bombay.com': true,
  'mail2bonn.com': true,
  'mail2bookmark.com': true,
  'mail2boreas.com': true,
  'mail2bosnia.com': true,
  'mail2boston.com': true,
  'mail2botswana.com': true,
  'mail2bradley.com': true,
  'mail2brazil.com': true,
  'mail2breakfast.com': true,
  'mail2brian.com': true,
  'mail2bride.com': true,
  'mail2brittany.com': true,
  'mail2broker.com': true,
  'mail2brook.com': true,
  'mail2bruce.com': true,
  'mail2brunei.com': true,
  'mail2brunette.com': true,
  'mail2brussels.com': true,
  'mail2bryan.com': true,
  'mail2bug.com': true,
  'mail2bulgaria.com': true,
  'mail2business.com': true,
  'mail2buy.com': true,
  'mail2ca.com': true,
  'mail2california.com': true,
  'mail2calvin.com': true,
  'mail2cambodia.com': true,
  'mail2cameroon.com': true,
  'mail2canada.com': true,
  'mail2cancer.com': true,
  'mail2capeverde.com': true,
  'mail2capricorn.com': true,
  'mail2cardinal.com': true,
  'mail2cardiologist.com': true,
  'mail2care.com': true,
  'mail2caroline.com': true,
  'mail2carolyn.com': true,
  'mail2casey.com': true,
  'mail2cat.com': true,
  'mail2caterer.com': true,
  'mail2cathy.com': true,
  'mail2catlover.com': true,
  'mail2catwalk.com': true,
  'mail2cell.com': true,
  'mail2chad.com': true,
  'mail2champaign.com': true,
  'mail2charles.com': true,
  'mail2chef.com': true,
  'mail2chemist.com': true,
  'mail2cherry.com': true,
  'mail2chicago.com': true,
  'mail2chile.com': true,
  'mail2china.com': true,
  'mail2chinese.com': true,
  'mail2chocolate.com': true,
  'mail2christian.com': true,
  'mail2christie.com': true,
  'mail2christmas.com': true,
  'mail2christy.com': true,
  'mail2chuck.com': true,
  'mail2cindy.com': true,
  'mail2clark.com': true,
  'mail2classifieds.com': true,
  'mail2claude.com': true,
  'mail2cliff.com': true,
  'mail2clinic.com': true,
  'mail2clint.com': true,
  'mail2close.com': true,
  'mail2club.com': true,
  'mail2coach.com': true,
  'mail2coastguard.com': true,
  'mail2colin.com': true,
  'mail2college.com': true,
  'mail2colombia.com': true,
  'mail2color.com': true,
  'mail2colorado.com': true,
  'mail2columbia.com': true,
  'mail2comedian.com': true,
  'mail2composer.com': true,
  'mail2computer.com': true,
  'mail2computers.com': true,
  'mail2concert.com': true,
  'mail2congo.com': true,
  'mail2connect.com': true,
  'mail2connecticut.com': true,
  'mail2consultant.com': true,
  'mail2convict.com': true,
  'mail2cook.com': true,
  'mail2cool.com': true,
  'mail2cory.com': true,
  'mail2costarica.com': true,
  'mail2country.com': true,
  'mail2courtney.com': true,
  'mail2cowboy.com': true,
  'mail2cowgirl.com': true,
  'mail2craig.com': true,
  'mail2crave.com': true,
  'mail2crazy.com': true,
  'mail2create.com': true,
  'mail2croatia.com': true,
  'mail2cry.com': true,
  'mail2crystal.com': true,
  'mail2cuba.com': true,
  'mail2culture.com': true,
  'mail2curt.com': true,
  'mail2customs.com': true,
  'mail2cute.com': true,
  'mail2cutey.com': true,
  'mail2cynthia.com': true,
  'mail2cyprus.com': true,
  'mail2czechrepublic.com': true,
  'mail2dad.com': true,
  'mail2dale.com': true,
  'mail2dallas.com': true,
  'mail2dan.com': true,
  'mail2dana.com': true,
  'mail2dance.com': true,
  'mail2dancer.com': true,
  'mail2danielle.com': true,
  'mail2danny.com': true,
  'mail2darlene.com': true,
  'mail2darling.com': true,
  'mail2darren.com': true,
  'mail2daughter.com': true,
  'mail2dave.com': true,
  'mail2dawn.com': true,
  'mail2dc.com': true,
  'mail2dealer.com': true,
  'mail2deanna.com': true,
  'mail2dearest.com': true,
  'mail2debbie.com': true,
  'mail2debby.com': true,
  'mail2deer.com': true,
  'mail2delaware.com': true,
  'mail2delicious.com': true,
  'mail2demeter.com': true,
  'mail2democrat.com': true,
  'mail2denise.com': true,
  'mail2denmark.com': true,
  'mail2dennis.com': true,
  'mail2dentist.com': true,
  'mail2derek.com': true,
  'mail2desert.com': true,
  'mail2devoted.com': true,
  'mail2devotion.com': true,
  'mail2diamond.com': true,
  'mail2diana.com': true,
  'mail2diane.com': true,
  'mail2diehard.com': true,
  'mail2dilemma.com': true,
  'mail2dillon.com': true,
  'mail2dinner.com': true,
  'mail2dinosaur.com': true,
  'mail2dionysos.com': true,
  'mail2diplomat.com': true,
  'mail2director.com': true,
  'mail2dirk.com': true,
  'mail2disco.com': true,
  'mail2dive.com': true,
  'mail2diver.com': true,
  'mail2divorced.com': true,
  'mail2djibouti.com': true,
  'mail2doctor.com': true,
  'mail2doglover.com': true,
  'mail2dominic.com': true,
  'mail2dominica.com': true,
  'mail2dominicanrepublic.com': true,
  'mail2don.com': true,
  'mail2donald.com': true,
  'mail2donna.com': true,
  'mail2doris.com': true,
  'mail2dorothy.com': true,
  'mail2doug.com': true,
  'mail2dough.com': true,
  'mail2douglas.com': true,
  'mail2dow.com': true,
  'mail2downtown.com': true,
  'mail2dream.com': true,
  'mail2dreamer.com': true,
  'mail2dude.com': true,
  'mail2dustin.com': true,
  'mail2dyke.com': true,
  'mail2dylan.com': true,
  'mail2earl.com': true,
  'mail2earth.com': true,
  'mail2eastend.com': true,
  'mail2eat.com': true,
  'mail2economist.com': true,
  'mail2ecuador.com': true,
  'mail2eddie.com': true,
  'mail2edgar.com': true,
  'mail2edwin.com': true,
  'mail2egypt.com': true,
  'mail2electron.com': true,
  'mail2eli.com': true,
  'mail2elizabeth.com': true,
  'mail2ellen.com': true,
  'mail2elliot.com': true,
  'mail2elsalvador.com': true,
  'mail2elvis.com': true,
  'mail2emergency.com': true,
  'mail2emily.com': true,
  'mail2engineer.com': true,
  'mail2english.com': true,
  'mail2environmentalist.com': true,
  'mail2eos.com': true,
  'mail2eric.com': true,
  'mail2erica.com': true,
  'mail2erin.com': true,
  'mail2erinyes.com': true,
  'mail2eris.com': true,
  'mail2eritrea.com': true,
  'mail2ernie.com': true,
  'mail2eros.com': true,
  'mail2estonia.com': true,
  'mail2ethan.com': true,
  'mail2ethiopia.com': true,
  'mail2eu.com': true,
  'mail2europe.com': true,
  'mail2eurus.com': true,
  'mail2eva.com': true,
  'mail2evan.com': true,
  'mail2evelyn.com': true,
  'mail2everything.com': true,
  'mail2exciting.com': true,
  'mail2expert.com': true,
  'mail2fairy.com': true,
  'mail2faith.com': true,
  'mail2fanatic.com': true,
  'mail2fancy.com': true,
  'mail2fantasy.com': true,
  'mail2farm.com': true,
  'mail2farmer.com': true,
  'mail2fashion.com': true,
  'mail2fat.com': true,
  'mail2feeling.com': true,
  'mail2female.com': true,
  'mail2fever.com': true,
  'mail2fighter.com': true,
  'mail2fiji.com': true,
  'mail2filmfestival.com': true,
  'mail2films.com': true,
  'mail2finance.com': true,
  'mail2finland.com': true,
  'mail2fireman.com': true,
  'mail2firm.com': true,
  'mail2fisherman.com': true,
  'mail2flexible.com': true,
  'mail2florence.com': true,
  'mail2florida.com': true,
  'mail2floyd.com': true,
  'mail2fly.com': true,
  'mail2fond.com': true,
  'mail2fondness.com': true,
  'mail2football.com': true,
  'mail2footballfan.com': true,
  'mail2found.com': true,
  'mail2france.com': true,
  'mail2frank.com': true,
  'mail2frankfurt.com': true,
  'mail2franklin.com': true,
  'mail2fred.com': true,
  'mail2freddie.com': true,
  'mail2free.com': true,
  'mail2freedom.com': true,
  'mail2french.com': true,
  'mail2freudian.com': true,
  'mail2friendship.com': true,
  'mail2from.com': true,
  'mail2fun.com': true,
  'mail2gabon.com': true,
  'mail2gabriel.com': true,
  'mail2gail.com': true,
  'mail2galaxy.com': true,
  'mail2gambia.com': true,
  'mail2games.com': true,
  'mail2gary.com': true,
  'mail2gavin.com': true,
  'mail2gemini.com': true,
  'mail2gene.com': true,
  'mail2genes.com': true,
  'mail2geneva.com': true,
  'mail2george.com': true,
  'mail2georgia.com': true,
  'mail2gerald.com': true,
  'mail2german.com': true,
  'mail2germany.com': true,
  'mail2ghana.com': true,
  'mail2gilbert.com': true,
  'mail2gina.com': true,
  'mail2girl.com': true,
  'mail2glen.com': true,
  'mail2gloria.com': true,
  'mail2goddess.com': true,
  'mail2gold.com': true,
  'mail2golfclub.com': true,
  'mail2golfer.com': true,
  'mail2gordon.com': true,
  'mail2government.com': true,
  'mail2grab.com': true,
  'mail2grace.com': true,
  'mail2graham.com': true,
  'mail2grandma.com': true,
  'mail2grandpa.com': true,
  'mail2grant.com': true,
  'mail2greece.com': true,
  'mail2green.com': true,
  'mail2greg.com': true,
  'mail2grenada.com': true,
  'mail2gsm.com': true,
  'mail2guard.com': true,
  'mail2guatemala.com': true,
  'mail2guy.com': true,
  'mail2hades.com': true,
  'mail2haiti.com': true,
  'mail2hal.com': true,
  'mail2handhelds.com': true,
  'mail2hank.com': true,
  'mail2hannah.com': true,
  'mail2harold.com': true,
  'mail2harry.com': true,
  'mail2hawaii.com': true,
  'mail2headhunter.com': true,
  'mail2heal.com': true,
  'mail2heather.com': true,
  'mail2heaven.com': true,
  'mail2hebe.com': true,
  'mail2hecate.com': true,
  'mail2heidi.com': true,
  'mail2helen.com': true,
  'mail2hell.com': true,
  'mail2help.com': true,
  'mail2helpdesk.com': true,
  'mail2henry.com': true,
  'mail2hephaestus.com': true,
  'mail2hera.com': true,
  'mail2hercules.com': true,
  'mail2herman.com': true,
  'mail2hermes.com': true,
  'mail2hespera.com': true,
  'mail2hestia.com': true,
  'mail2highschool.com': true,
  'mail2hindu.com': true,
  'mail2hip.com': true,
  'mail2hiphop.com': true,
  'mail2holland.com': true,
  'mail2holly.com': true,
  'mail2hollywood.com': true,
  'mail2homer.com': true,
  'mail2honduras.com': true,
  'mail2honey.com': true,
  'mail2hongkong.com': true,
  'mail2hope.com': true,
  'mail2horse.com': true,
  'mail2hot.com': true,
  'mail2hotel.com': true,
  'mail2houston.com': true,
  'mail2howard.com': true,
  'mail2hugh.com': true,
  'mail2human.com': true,
  'mail2hungary.com': true,
  'mail2hungry.com': true,
  'mail2hygeia.com': true,
  'mail2hyperspace.com': true,
  'mail2hypnos.com': true,
  'mail2ian.com': true,
  'mail2ice-cream.com': true,
  'mail2iceland.com': true,
  'mail2idaho.com': true,
  'mail2idontknow.com': true,
  'mail2illinois.com': true,
  'mail2imam.com': true,
  'mail2in.com': true,
  'mail2india.com': true,
  'mail2indian.com': true,
  'mail2indiana.com': true,
  'mail2indonesia.com': true,
  'mail2infinity.com': true,
  'mail2intense.com': true,
  'mail2iowa.com': true,
  'mail2iran.com': true,
  'mail2iraq.com': true,
  'mail2ireland.com': true,
  'mail2irene.com': true,
  'mail2iris.com': true,
  'mail2irresistible.com': true,
  'mail2irving.com': true,
  'mail2irwin.com': true,
  'mail2isaac.com': true,
  'mail2israel.com': true,
  'mail2italian.com': true,
  'mail2italy.com': true,
  'mail2jackie.com': true,
  'mail2jacob.com': true,
  'mail2jail.com': true,
  'mail2jaime.com': true,
  'mail2jake.com': true,
  'mail2jamaica.com': true,
  'mail2james.com': true,
  'mail2jamie.com': true,
  'mail2jan.com': true,
  'mail2jane.com': true,
  'mail2janet.com': true,
  'mail2janice.com': true,
  'mail2japan.com': true,
  'mail2japanese.com': true,
  'mail2jasmine.com': true,
  'mail2jason.com': true,
  'mail2java.com': true,
  'mail2jay.com': true,
  'mail2jazz.com': true,
  'mail2jed.com': true,
  'mail2jeffrey.com': true,
  'mail2jennifer.com': true,
  'mail2jenny.com': true,
  'mail2jeremy.com': true,
  'mail2jerry.com': true,
  'mail2jessica.com': true,
  'mail2jessie.com': true,
  'mail2jesus.com': true,
  'mail2jew.com': true,
  'mail2jeweler.com': true,
  'mail2jim.com': true,
  'mail2jimmy.com': true,
  'mail2joan.com': true,
  'mail2joann.com': true,
  'mail2joanna.com': true,
  'mail2jody.com': true,
  'mail2joe.com': true,
  'mail2joel.com': true,
  'mail2joey.com': true,
  'mail2john.com': true,
  'mail2join.com': true,
  'mail2jon.com': true,
  'mail2jonathan.com': true,
  'mail2jones.com': true,
  'mail2jordan.com': true,
  'mail2joseph.com': true,
  'mail2josh.com': true,
  'mail2joy.com': true,
  'mail2juan.com': true,
  'mail2judge.com': true,
  'mail2judy.com': true,
  'mail2juggler.com': true,
  'mail2julian.com': true,
  'mail2julie.com': true,
  'mail2jumbo.com': true,
  'mail2junk.com': true,
  'mail2justin.com': true,
  'mail2justme.com': true,
  'mail2kansas.com': true,
  'mail2karate.com': true,
  'mail2karen.com': true,
  'mail2karl.com': true,
  'mail2karma.com': true,
  'mail2kathleen.com': true,
  'mail2kathy.com': true,
  'mail2katie.com': true,
  'mail2kay.com': true,
  'mail2kazakhstan.com': true,
  'mail2keen.com': true,
  'mail2keith.com': true,
  'mail2kelly.com': true,
  'mail2kelsey.com': true,
  'mail2ken.com': true,
  'mail2kendall.com': true,
  'mail2kennedy.com': true,
  'mail2kenneth.com': true,
  'mail2kenny.com': true,
  'mail2kentucky.com': true,
  'mail2kenya.com': true,
  'mail2kerry.com': true,
  'mail2kevin.com': true,
  'mail2kim.com': true,
  'mail2kimberly.com': true,
  'mail2king.com': true,
  'mail2kirk.com': true,
  'mail2kiss.com': true,
  'mail2kosher.com': true,
  'mail2kristin.com': true,
  'mail2kurt.com': true,
  'mail2kuwait.com': true,
  'mail2kyle.com': true,
  'mail2kyrgyzstan.com': true,
  'mail2la.com': true,
  'mail2lacrosse.com': true,
  'mail2lance.com': true,
  'mail2lao.com': true,
  'mail2larry.com': true,
  'mail2latvia.com': true,
  'mail2laugh.com': true,
  'mail2laura.com': true,
  'mail2lauren.com': true,
  'mail2laurie.com': true,
  'mail2lawrence.com': true,
  'mail2lawyer.com': true,
  'mail2lebanon.com': true,
  'mail2lee.com': true,
  'mail2leo.com': true,
  'mail2leon.com': true,
  'mail2leonard.com': true,
  'mail2leone.com': true,
  'mail2leslie.com': true,
  'mail2letter.com': true,
  'mail2liberia.com': true,
  'mail2libertarian.com': true,
  'mail2libra.com': true,
  'mail2libya.com': true,
  'mail2liechtenstein.com': true,
  'mail2life.com': true,
  'mail2linda.com': true,
  'mail2linux.com': true,
  'mail2lionel.com': true,
  'mail2lipstick.com': true,
  'mail2liquid.com': true,
  'mail2lisa.com': true,
  'mail2lithuania.com': true,
  'mail2litigator.com': true,
  'mail2liz.com': true,
  'mail2lloyd.com': true,
  'mail2lois.com': true,
  'mail2lola.com': true,
  'mail2london.com': true,
  'mail2looking.com': true,
  'mail2lori.com': true,
  'mail2lost.com': true,
  'mail2lou.com': true,
  'mail2louis.com': true,
  'mail2louisiana.com': true,
  'mail2lovable.com': true,
  'mail2love.com': true,
  'mail2lucky.com': true,
  'mail2lucy.com': true,
  'mail2lunch.com': true,
  'mail2lust.com': true,
  'mail2luxembourg.com': true,
  'mail2luxury.com': true,
  'mail2lyle.com': true,
  'mail2lynn.com': true,
  'mail2madagascar.com': true,
  'mail2madison.com': true,
  'mail2madrid.com': true,
  'mail2maggie.com': true,
  'mail2mail4.com': true,
  'mail2maine.com': true,
  'mail2malawi.com': true,
  'mail2malaysia.com': true,
  'mail2maldives.com': true,
  'mail2mali.com': true,
  'mail2malta.com': true,
  'mail2mambo.com': true,
  'mail2man.com': true,
  'mail2mandy.com': true,
  'mail2manhunter.com': true,
  'mail2mankind.com': true,
  'mail2many.com': true,
  'mail2marc.com': true,
  'mail2marcia.com': true,
  'mail2margaret.com': true,
  'mail2margie.com': true,
  'mail2marhaba.com': true,
  'mail2maria.com': true,
  'mail2marilyn.com': true,
  'mail2marines.com': true,
  'mail2mark.com': true,
  'mail2marriage.com': true,
  'mail2married.com': true,
  'mail2marries.com': true,
  'mail2mars.com': true,
  'mail2marsha.com': true,
  'mail2marshallislands.com': true,
  'mail2martha.com': true,
  'mail2martin.com': true,
  'mail2marty.com': true,
  'mail2marvin.com': true,
  'mail2mary.com': true,
  'mail2maryland.com': true,
  'mail2mason.com': true,
  'mail2massachusetts.com': true,
  'mail2matt.com': true,
  'mail2matthew.com': true,
  'mail2maurice.com': true,
  'mail2mauritania.com': true,
  'mail2mauritius.com': true,
  'mail2max.com': true,
  'mail2maxwell.com': true,
  'mail2maybe.com': true,
  'mail2mba.com': true,
  'mail2me4u.com': true,
  'mail2mechanic.com': true,
  'mail2medieval.com': true,
  'mail2megan.com': true,
  'mail2mel.com': true,
  'mail2melanie.com': true,
  'mail2melissa.com': true,
  'mail2melody.com': true,
  'mail2member.com': true,
  'mail2memphis.com': true,
  'mail2methodist.com': true,
  'mail2mexican.com': true,
  'mail2mexico.com': true,
  'mail2mgz.com': true,
  'mail2miami.com': true,
  'mail2michael.com': true,
  'mail2michelle.com': true,
  'mail2michigan.com': true,
  'mail2mike.com': true,
  'mail2milan.com': true,
  'mail2milano.com': true,
  'mail2mildred.com': true,
  'mail2milkyway.com': true,
  'mail2millennium.com': true,
  'mail2millionaire.com': true,
  'mail2milton.com': true,
  'mail2mime.com': true,
  'mail2mindreader.com': true,
  'mail2mini.com': true,
  'mail2minister.com': true,
  'mail2minneapolis.com': true,
  'mail2minnesota.com': true,
  'mail2miracle.com': true,
  'mail2missionary.com': true,
  'mail2mississippi.com': true,
  'mail2missouri.com': true,
  'mail2mitch.com': true,
  'mail2model.com': true,
  'mail2moldova.commail2molly.com': true,
  'mail2mom.com': true,
  'mail2monaco.com': true,
  'mail2money.com': true,
  'mail2mongolia.com': true,
  'mail2monica.com': true,
  'mail2montana.com': true,
  'mail2monty.com': true,
  'mail2moon.com': true,
  'mail2morocco.com': true,
  'mail2morpheus.com': true,
  'mail2mors.com': true,
  'mail2moscow.com': true,
  'mail2moslem.com': true,
  'mail2mouseketeer.com': true,
  'mail2movies.com': true,
  'mail2mozambique.com': true,
  'mail2mp3.com': true,
  'mail2mrright.com': true,
  'mail2msright.com': true,
  'mail2museum.com': true,
  'mail2music.com': true,
  'mail2musician.com': true,
  'mail2muslim.com': true,
  'mail2my.com': true,
  'mail2myboat.com': true,
  'mail2mycar.com': true,
  'mail2mycell.com': true,
  'mail2mygsm.com': true,
  'mail2mylaptop.com': true,
  'mail2mymac.com': true,
  'mail2mypager.com': true,
  'mail2mypalm.com': true,
  'mail2mypc.com': true,
  'mail2myphone.com': true,
  'mail2myplane.com': true,
  'mail2namibia.com': true,
  'mail2nancy.com': true,
  'mail2nasdaq.com': true,
  'mail2nathan.com': true,
  'mail2nauru.com': true,
  'mail2navy.com': true,
  'mail2neal.com': true,
  'mail2nebraska.com': true,
  'mail2ned.com': true,
  'mail2neil.com': true,
  'mail2nelson.com': true,
  'mail2nemesis.com': true,
  'mail2nepal.com': true,
  'mail2netherlands.com': true,
  'mail2network.com': true,
  'mail2nevada.com': true,
  'mail2newhampshire.com': true,
  'mail2newjersey.com': true,
  'mail2newmexico.com': true,
  'mail2newyork.com': true,
  'mail2newzealand.com': true,
  'mail2nicaragua.com': true,
  'mail2nick.com': true,
  'mail2nicole.com': true,
  'mail2niger.com': true,
  'mail2nigeria.com': true,
  'mail2nike.com': true,
  'mail2no.com': true,
  'mail2noah.com': true,
  'mail2noel.com': true,
  'mail2noelle.com': true,
  'mail2normal.com': true,
  'mail2norman.com': true,
  'mail2northamerica.com': true,
  'mail2northcarolina.com': true,
  'mail2northdakota.com': true,
  'mail2northpole.com': true,
  'mail2norway.com': true,
  'mail2notus.com': true,
  'mail2noway.com': true,
  'mail2nowhere.com': true,
  'mail2nuclear.com': true,
  'mail2nun.com': true,
  'mail2ny.com': true,
  'mail2oasis.com': true,
  'mail2oceanographer.com': true,
  'mail2ohio.com': true,
  'mail2ok.com': true,
  'mail2oklahoma.com': true,
  'mail2oliver.com': true,
  'mail2oman.com': true,
  'mail2one.com': true,
  'mail2onfire.com': true,
  'mail2online.com': true,
  'mail2oops.com': true,
  'mail2open.com': true,
  'mail2ophthalmologist.com': true,
  'mail2optometrist.com': true,
  'mail2oregon.com': true,
  'mail2oscars.com': true,
  'mail2oslo.com': true,
  'mail2painter.com': true,
  'mail2pakistan.com': true,
  'mail2palau.com': true,
  'mail2pan.com': true,
  'mail2panama.com': true,
  'mail2paraguay.com': true,
  'mail2paralegal.com': true,
  'mail2paris.com': true,
  'mail2park.com': true,
  'mail2parker.com': true,
  'mail2party.com': true,
  'mail2passion.com': true,
  'mail2pat.com': true,
  'mail2patricia.com': true,
  'mail2patrick.com': true,
  'mail2patty.com': true,
  'mail2paul.com': true,
  'mail2paula.com': true,
  'mail2pay.com': true,
  'mail2peace.com': true,
  'mail2pediatrician.com': true,
  'mail2peggy.com': true,
  'mail2pennsylvania.com': true,
  'mail2perry.com': true,
  'mail2persephone.com': true,
  'mail2persian.com': true,
  'mail2peru.com': true,
  'mail2pete.com': true,
  'mail2peter.com': true,
  'mail2pharmacist.com': true,
  'mail2phil.com': true,
  'mail2philippines.com': true,
  'mail2phoenix.com': true,
  'mail2phonecall.com': true,
  'mail2phyllis.com': true,
  'mail2pickup.com': true,
  'mail2pilot.com': true,
  'mail2pisces.com': true,
  'mail2planet.com': true,
  'mail2platinum.com': true,
  'mail2plato.com': true,
  'mail2pluto.com': true,
  'mail2pm.com': true,
  'mail2podiatrist.com': true,
  'mail2poet.com': true,
  'mail2poland.com': true,
  'mail2policeman.com': true,
  'mail2policewoman.com': true,
  'mail2politician.com': true,
  'mail2pop.com': true,
  'mail2pope.com': true,
  'mail2popular.com': true,
  'mail2portugal.com': true,
  'mail2poseidon.com': true,
  'mail2potatohead.com': true,
  'mail2power.com': true,
  'mail2presbyterian.com': true,
  'mail2president.com': true,
  'mail2priest.com': true,
  'mail2prince.com': true,
  'mail2princess.com': true,
  'mail2producer.com': true,
  'mail2professor.com': true,
  'mail2protect.com': true,
  'mail2psychiatrist.com': true,
  'mail2psycho.com': true,
  'mail2psychologist.com': true,
  'mail2qatar.com': true,
  'mail2queen.com': true,
  'mail2rabbi.com': true,
  'mail2race.com': true,
  'mail2racer.com': true,
  'mail2rachel.com': true,
  'mail2rage.com': true,
  'mail2rainmaker.com': true,
  'mail2ralph.com': true,
  'mail2randy.com': true,
  'mail2rap.com': true,
  'mail2rare.com': true,
  'mail2rave.com': true,
  'mail2ray.com': true,
  'mail2raymond.com': true,
  'mail2realtor.com': true,
  'mail2rebecca.com': true,
  'mail2recruiter.com': true,
  'mail2recycle.com': true,
  'mail2redhead.com': true,
  'mail2reed.com': true,
  'mail2reggie.com': true,
  'mail2register.com': true,
  'mail2rent.com': true,
  'mail2republican.com': true,
  'mail2resort.com': true,
  'mail2rex.com': true,
  'mail2rhodeisland.com': true,
  'mail2rich.com': true,
  'mail2richard.com': true,
  'mail2ricky.com': true,
  'mail2ride.com': true,
  'mail2riley.com': true,
  'mail2rita.com': true,
  'mail2rob.com': true,
  'mail2robert.com': true,
  'mail2roberta.com': true,
  'mail2robin.com': true,
  'mail2rock.com': true,
  'mail2rocker.com': true,
  'mail2rod.com': true,
  'mail2rodney.com': true,
  'mail2romania.com': true,
  'mail2rome.com': true,
  'mail2ron.com': true,
  'mail2ronald.com': true,
  'mail2ronnie.com': true,
  'mail2rose.com': true,
  'mail2rosie.com': true,
  'mail2roy.com': true,
  'mail2rudy.com': true,
  'mail2rugby.com': true,
  'mail2runner.com': true,
  'mail2russell.com': true,
  'mail2russia.com': true,
  'mail2russian.com': true,
  'mail2rusty.com': true,
  'mail2ruth.com': true,
  'mail2rwanda.com': true,
  'mail2ryan.com': true,
  'mail2sa.com': true,
  'mail2sabrina.com': true,
  'mail2safe.com': true,
  'mail2sagittarius.com': true,
  'mail2sail.com': true,
  'mail2sailor.com': true,
  'mail2sal.com': true,
  'mail2salaam.com': true,
  'mail2sam.com': true,
  'mail2samantha.com': true,
  'mail2samoa.com': true,
  'mail2samurai.com': true,
  'mail2sandra.com': true,
  'mail2sandy.com': true,
  'mail2sanfrancisco.com': true,
  'mail2sanmarino.com': true,
  'mail2santa.com': true,
  'mail2sara.com': true,
  'mail2sarah.com': true,
  'mail2sat.com': true,
  'mail2saturn.com': true,
  'mail2saudi.com': true,
  'mail2saudiarabia.com': true,
  'mail2save.com': true,
  'mail2savings.com': true,
  'mail2school.com': true,
  'mail2scientist.com': true,
  'mail2scorpio.com': true,
  'mail2scott.com': true,
  'mail2sean.com': true,
  'mail2search.com': true,
  'mail2seattle.com': true,
  'mail2secretagent.com': true,
  'mail2senate.com': true,
  'mail2senegal.com': true,
  'mail2sensual.com': true,
  'mail2seth.com': true,
  'mail2sevenseas.com': true,
  'mail2sexy.com': true,
  'mail2seychelles.com': true,
  'mail2shane.com': true,
  'mail2sharon.com': true,
  'mail2shawn.com': true,
  'mail2ship.com': true,
  'mail2shirley.com': true,
  'mail2shoot.com': true,
  'mail2shuttle.com': true,
  'mail2sierraleone.com': true,
  'mail2simon.com': true,
  'mail2singapore.com': true,
  'mail2single.com': true,
  'mail2site.com': true,
  'mail2skater.com': true,
  'mail2skier.com': true,
  'mail2sky.com': true,
  'mail2sleek.com': true,
  'mail2slim.com': true,
  'mail2slovakia.com': true,
  'mail2slovenia.com': true,
  'mail2smile.com': true,
  'mail2smith.com': true,
  'mail2smooth.com': true,
  'mail2soccer.com': true,
  'mail2soccerfan.com': true,
  'mail2socialist.com': true,
  'mail2soldier.com': true,
  'mail2somalia.com': true,
  'mail2son.com': true,
  'mail2song.com': true,
  'mail2sos.com': true,
  'mail2sound.com': true,
  'mail2southafrica.com': true,
  'mail2southamerica.com': true,
  'mail2southcarolina.com': true,
  'mail2southdakota.com': true,
  'mail2southkorea.com': true,
  'mail2southpole.com': true,
  'mail2spain.com': true,
  'mail2spanish.com': true,
  'mail2spare.com': true,
  'mail2spectrum.com': true,
  'mail2splash.com': true,
  'mail2sponsor.com': true,
  'mail2sports.com': true,
  'mail2srilanka.com': true,
  'mail2stacy.com': true,
  'mail2stan.com': true,
  'mail2stanley.com': true,
  'mail2star.com': true,
  'mail2state.com': true,
  'mail2stephanie.com': true,
  'mail2steve.com': true,
  'mail2steven.com': true,
  'mail2stewart.com': true,
  'mail2stlouis.com': true,
  'mail2stock.com': true,
  'mail2stockholm.com': true,
  'mail2stockmarket.com': true,
  'mail2storage.com': true,
  'mail2store.com': true,
  'mail2strong.com': true,
  'mail2student.com': true,
  'mail2studio.com': true,
  'mail2studio54.com': true,
  'mail2stuntman.com': true,
  'mail2subscribe.com': true,
  'mail2sudan.com': true,
  'mail2superstar.com': true,
  'mail2surfer.com': true,
  'mail2suriname.com': true,
  'mail2susan.com': true,
  'mail2suzie.com': true,
  'mail2swaziland.com': true,
  'mail2sweden.com': true,
  'mail2sweetheart.com': true,
  'mail2swim.com': true,
  'mail2swimmer.com': true,
  'mail2swiss.com': true,
  'mail2switzerland.com': true,
  'mail2sydney.com': true,
  'mail2sylvia.com': true,
  'mail2syria.com': true,
  'mail2taboo.com': true,
  'mail2taiwan.com': true,
  'mail2tajikistan.com': true,
  'mail2tammy.com': true,
  'mail2tango.com': true,
  'mail2tanya.com': true,
  'mail2tanzania.com': true,
  'mail2tara.com': true,
  'mail2taurus.com': true,
  'mail2taxi.com': true,
  'mail2taxidermist.com': true,
  'mail2taylor.com': true,
  'mail2taz.com': true,
  'mail2teacher.com': true,
  'mail2technician.com': true,
  'mail2ted.com': true,
  'mail2telephone.com': true,
  'mail2teletubbie.com': true,
  'mail2tenderness.com': true,
  'mail2tennessee.com': true,
  'mail2tennis.com': true,
  'mail2tennisfan.com': true,
  'mail2terri.com': true,
  'mail2terry.com': true,
  'mail2test.com': true,
  'mail2texas.com': true,
  'mail2thailand.com': true,
  'mail2therapy.com': true,
  'mail2think.com': true,
  'mail2tickets.com': true,
  'mail2tiffany.com': true,
  'mail2tim.com': true,
  'mail2time.com': true,
  'mail2timothy.com': true,
  'mail2tina.com': true,
  'mail2titanic.com': true,
  'mail2toby.com': true,
  'mail2todd.com': true,
  'mail2togo.com': true,
  'mail2tom.com': true,
  'mail2tommy.com': true,
  'mail2tonga.com': true,
  'mail2tony.com': true,
  'mail2touch.com': true,
  'mail2tourist.com': true,
  'mail2tracey.com': true,
  'mail2tracy.com': true,
  'mail2tramp.com': true,
  'mail2travel.com': true,
  'mail2traveler.com': true,
  'mail2travis.com': true,
  'mail2trekkie.com': true,
  'mail2trex.com': true,
  'mail2triallawyer.com': true,
  'mail2trick.com': true,
  'mail2trillionaire.com': true,
  'mail2troy.com': true,
  'mail2truck.com': true,
  'mail2trump.com': true,
  'mail2try.com': true,
  'mail2tunisia.com': true,
  'mail2turbo.com': true,
  'mail2turkey.com': true,
  'mail2turkmenistan.com': true,
  'mail2tv.com': true,
  'mail2tycoon.com': true,
  'mail2tyler.com': true,
  'mail2u4me.com': true,
  'mail2uae.com': true,
  'mail2uganda.com': true,
  'mail2uk.com': true,
  'mail2ukraine.com': true,
  'mail2uncle.com': true,
  'mail2unsubscribe.com': true,
  'mail2uptown.com': true,
  'mail2uruguay.com': true,
  'mail2usa.com': true,
  'mail2utah.com': true,
  'mail2uzbekistan.com': true,
  'mail2v.com': true,
  'mail2vacation.com': true,
  'mail2valentines.com': true,
  'mail2valerie.com': true,
  'mail2valley.com': true,
  'mail2vamoose.com': true,
  'mail2vanessa.com': true,
  'mail2vanuatu.com': true,
  'mail2venezuela.com': true,
  'mail2venous.com': true,
  'mail2venus.com': true,
  'mail2vermont.com': true,
  'mail2vickie.com': true,
  'mail2victor.com': true,
  'mail2victoria.com': true,
  'mail2vienna.com': true,
  'mail2vietnam.com': true,
  'mail2vince.com': true,
  'mail2virginia.com': true,
  'mail2virgo.com': true,
  'mail2visionary.com': true,
  'mail2vodka.com': true,
  'mail2volleyball.com': true,
  'mail2waiter.com': true,
  'mail2wallstreet.com': true,
  'mail2wally.com': true,
  'mail2walter.com': true,
  'mail2warren.com': true,
  'mail2washington.com': true,
  'mail2wave.com': true,
  'mail2way.com': true,
  'mail2waycool.com': true,
  'mail2wayne.com': true,
  'mail2webmaster.com': true,
  'mail2webtop.com': true,
  'mail2webtv.com': true,
  'mail2weird.com': true,
  'mail2wendell.com': true,
  'mail2wendy.com': true,
  'mail2westend.com': true,
  'mail2westvirginia.com': true,
  'mail2whether.com': true,
  'mail2whip.com': true,
  'mail2white.com': true,
  'mail2whitehouse.com': true,
  'mail2whitney.com': true,
  'mail2why.com': true,
  'mail2wilbur.com': true,
  'mail2wild.com': true,
  'mail2willard.com': true,
  'mail2willie.com': true,
  'mail2wine.com': true,
  'mail2winner.com': true,
  'mail2wired.com': true,
  'mail2wisconsin.com': true,
  'mail2woman.com': true,
  'mail2wonder.com': true,
  'mail2world.com': true,
  'mail2worship.com': true,
  'mail2wow.com': true,
  'mail2www.com': true,
  'mail2wyoming.com': true,
  'mail2xfiles.com': true,
  'mail2xox.com': true,
  'mail2yachtclub.com': true,
  'mail2yahalla.com': true,
  'mail2yemen.com': true,
  'mail2yes.com': true,
  'mail2yugoslavia.com': true,
  'mail2zack.com': true,
  'mail2zambia.com': true,
  'mail2zenith.com': true,
  'mail2zephir.com': true,
  'mail2zeus.com': true,
  'mail2zipper.com': true,
  'mail2zoo.com': true,
  'mail2zoologist.com': true,
  'mail2zurich.com': true,
  'mail3000.com': true,
  'mail333.com': true,
  'mail7.io': true,
  'mailandftp.com': true,
  'mailandnews.com': true,
  'mailas.com': true,
  'mailasia.com': true,
  'mailbolt.com': true,
  'mailbomb.net': true,
  'mailbox.as': true,
  'mailbox.co.za': true,
  'mailbox.gr': true,
  'mailbox.hu': true,
  'mailbox.in.ua': true,
  'mailbr.com.br': true,
  'mailc.net': true,
  'mailcan.com': true,
  'mailchoose.co': true,
  'mailcity.com': true,
  'mailclub.fr': true,
  'mailclub.net': true,
  'mailexcite.com': true,
  'mailforce.net': true,
  'mailftp.com': true,
  'mailgenie.net': true,
  'mailhaven.com': true,
  'mailhood.com': true,
  'mailingweb.com': true,
  'mailisent.com': true,
  'mailite.com': true,
  'mailme.dk': true,
  'mailmight.com': true,
  'mailmij.nl': true,
  'mailnew.com': true,
  'mailops.com': true,
  'mailoye.com': true,
  'mailpanda.com': true,
  'mailpride.com': true,
  'mailpuppy.com': true,
  'mailroom.com': true,
  'mailru.com': true,
  'mailsent.net': true,
  'mailsurf.com': true,
  'mailsy.top': true,
  'mailup.net': true,
  'maktoob.com': true,
  'malayalamtelevision.net': true,
  'manager.de': true,
  'mantrafreenet.com': true,
  'mantramail.com': true,
  'mantraonline.com': true,
  'marijuana.nl': true,
  'married-not.com': true,
  'marsattack.com': true,
  'masrawy.com': true,
  'mauimail.com': true,
  'maxleft.com': true,
  'mbox.com.au': true,
  'me-mail.hu': true,
  'meetingmall.com': true,
  'megago.com': true,
  'megamail.pt': true,
  'mehrani.com': true,
  'mehtaweb.com': true,
  'melodymail.com': true,
  'meloo.com': true,
  'message.hu': true,
  'metacrawler.com': true,
  'metta.lk': true,
  'miesto.sk': true,
  'mighty.co.za': true,
  'miho-nakayama.com': true,
  'millionaireintraining.com': true,
  'milmail.com': true,
  'misery.net': true,
  'mittalweb.com': true,
  'mixmail.com': true,
  'ml1.net': true,
  'mobilbatam.com': true,
  'mohammed.com': true,
  'moldova.cc': true,
  'moldova.com': true,
  'moldovacc.com': true,
  'montevideo.com.uy': true,
  'moonman.com': true,
  'moose-mail.com': true,
  'mortaza.com': true,
  'mosaicfx.com': true,
  'most-wanted.com': true,
  'mostlysunny.com': true,
  'motormania.com': true,
  'movemail.com': true,
  'mp4.it': true,
  'mr-potatohead.com': true,
  'mscold.com': true,
  'msgbox.com': true,
  'mundomail.net': true,
  'musicscene.org': true,
  'mybox.it': true,
  'mycabin.com': true,
  'mycity.com': true,
  'mycool.com': true,
  'mydomain.com': true,
  'mydotcomaddress.com': true,
  'myfamily.com': true,
  'myiris.com': true,
  'mynamedot.com': true,
  'mynetaddress.com': true,
  'myownemail.com': true,
  'myownfriends.com': true,
  'mypersonalemail.com': true,
  'myplace.com': true,
  'myrealbox.com': true,
  'mystupidjob.com': true,
  'myway.com': true,
  'myworldmail.com': true,
  'n2.com': true,
  'n2business.com': true,
  'n2mail.com': true,
  'n2software.com': true,
  'nabc.biz': true,
  'nagpal.net': true,
  'nakedgreens.com': true,
  'name.com': true,
  'nameplanet.com': true,
  'nandomail.com': true,
  'naseej.com': true,
  'nativestar.net': true,
  'nativeweb.net': true,
  'navigator.lv': true,
  'neeva.net': true,
  'nemra1.com': true,
  'nenter.com': true,
  'nervhq.org': true,
  'net4b.pt': true,
  'net4you.at': true,
  'netbounce.com': true,
  'netbroadcaster.com': true,
  'netcenter-vn.net': true,
  'netcourrier.com': true,
  'netexecutive.com': true,
  'netexpressway.com': true,
  'netian.com': true,
  'netizen.com.ar': true,
  'netlane.com': true,
  'netlimit.com': true,
  'netmongol.com': true,
  'netpiper.com': true,
  'netposta.net': true,
  'netralink.com': true,
  'netscape.net': true,
  'netscapeonline.co.uk': true,
  'netspeedway.com': true,
  'netsquare.com': true,
  'netster.com': true,
  'nettaxi.com': true,
  'netzero.com': true,
  'netzero.net': true,
  'newmail.com': true,
  'newmail.net': true,
  'newmail.ru': true,
  'newyork.com': true,
  'nfmail.com': true,
  'nicegal.com': true,
  'nicholastse.net': true,
  'nicolastse.com': true,
  'nightmail.com': true,
  'nikopage.com': true,
  'nirvanafan.com': true,
  'noavar.com': true,
  'norika-fujiwara.com': true,
  'norikomail.com': true,
  'northgates.net': true,
  'nospammail.net': true,
  'ny.com': true,
  'nyc.com': true,
  'nzoomail.com': true,
  'o-tay.com': true,
  'o2.co.uk': true,
  'oceanfree.net': true,
  'oddpost.com': true,
  'odmail.com': true,
  'oicexchange.com': true,
  'okbank.com': true,
  'okhuman.com': true,
  'okmad.com': true,
  'okmagic.com': true,
  'okname.net': true,
  'okuk.com': true,
  'ole.com': true,
  'olemail.com': true,
  'olympist.net': true,
  'omaninfo.com': true,
  'onebox.com': true,
  'onenet.com.ar': true,
  'onet.eu': true,
  'onet.pl': true,
  'onet.com.pl': true,
  'oninet.pt': true,
  'online.ie': true,
  'onlinewiz.com': true,
  'onmilwaukee.com': true,
  'onobox.com': true,
  'opoczta.pl': true,
  'operamail.com': true,
  'orbitel.bg': true,
  'orgmail.net': true,
  'osite.com.br': true,
  'oso.com': true,
  'otakumail.com': true,
  'our-computer.com': true,
  'our-office.com': true,
  'ourbrisbane.com': true,
  'ournet.md': true,
  'outgun.com': true,
  'over-the-rainbow.com': true,
  'ownmail.net': true,
  'packersfan.com': true,
  'pakistanoye.com': true,
  'palestinemail.com': true,
  'parkjiyoon.com': true,
  'parrot.com': true,
  'partlycloudy.com': true,
  'partynight.at': true,
  'parvazi.com': true,
  'pcpostal.com': true,
  'penpen.com': true,
  'peopleweb.com': true,
  'perfectmail.com': true,
  'personal.ro': true,
  'personales.com': true,
  'petml.com': true,
  'pettypool.com': true,
  'pezeshkpour.com': true,
  'phayze.com': true,
  'phreaker.net': true,
  'picusnet.com': true,
  'pigpig.net': true,
  'pinoymail.com': true,
  'piracha.net': true,
  'pisem.net': true,
  'planetaccess.com': true,
  'planetout.com': true,
  'plasa.com': true,
  'playersodds.com': true,
  'playful.com': true,
  'plusmail.com.br': true,
  'pmail.net': true,
  'pobox.hu': true,
  'pobox.sk': true,
  'pochta.ru': true,
  'poczta.fm': true,
  'poetic.com': true,
  'polbox.com': true,
  'policeoffice.com': true,
  'pool-sharks.com': true,
  'poond.com': true,
  'popmail.com': true,
  'popsmail.com': true,
  'popstar.com': true,
  'portugalmail.com': true,
  'portugalmail.pt': true,
  'portugalnet.com': true,
  'positive-thinking.com': true,
  'post.cz': true,
  'post.sk': true,
  'postaccesslite.com': true,
  'postafree.com': true,
  'postaweb.com': true,
  'postinbox.com': true,
  'postino.ch': true,
  'postmaster.co.uk': true,
  'postpro.net': true,
  'powerfan.com': true,
  'praize.com': true,
  'premiumservice.com': true,
  'press.co.jp': true,
  'primposta.com': true,
  'primposta.hu': true,
  'pro.hu': true,
  'protonmail.ch': true,
  'protonmail.com': true,
  'progetplus.it': true,
  'programozo.hu': true,
  'proinbox.com': true,
  'project2k.com': true,
  'promessage.com': true,
  'prontomail.com': true,
  'pseudonim.pl': true,
  'psv-supporter.com': true,
  'pulp-fiction.com': true,
  'punkass.com': true,
  'qatarmail.com': true,
  'qprfans.com': true,
  'qq.com': true,
  'qrio.com': true,
  'quackquack.com': true,
  'qudsmail.com': true,
  'quepasa.com': true,
  'quickwebmail.com': true,
  'r-o-o-t.com': true,
  'raakim.com': true,
  'racingfan.com.au': true,
  'radicalz.com': true,
  'ragingbull.com': true,
  'ranmamail.com': true,
  'rastogi.net': true,
  'rattle-snake.com': true,
  'ravearena.com': true,
  'razormail.com': true,
  'rccgmail.org': true,
  'realemail.net': true,
  'reallyfast.biz': true,
  'rediffmail.com': true,
  'rediffmailpro.com': true,
  'rednecks.com': true,
  'redseven.de': true,
  'redsfans.com': true,
  'reply.hu': true,
  'rezai.com': true,
  'rickymail.com': true,
  'rin.ru': true,
  'rn.com': true,
  'rock.com': true,
  'rocketmail.com': true,
  'rodrun.com': true,
  'rome.com': true,
  'rover.info': true,
  'roughnet.com': true,
  'rubyridge.com': true,
  'runbox.com': true,
  'rushpost.com': true,
  'ruttolibero.com': true,
  's-mail.com': true,
  'sabreshockey.com': true,
  'sacbeemail.com': true,
  'safe-mail.net': true,
  'sailormoon.com': true,
  'sale-sale-sale.com': true,
  'salehi.net': true,
  'samilan.net': true,
  'sammimail.com': true,
  'sanook.com': true,
  'sapo.pt': true,
  'saudia.com': true,
  'sayhi.net': true,
  'scandalmail.com': true,
  'schweiz.org': true,
  'sci.fi': true,
  'scientist.com': true,
  'scifianime.com': true,
  'scottishmail.co.uk': true,
  'scubadiving.com': true,
  'searchwales.com': true,
  'sebil.com': true,
  'secret-police.com': true,
  'secretservices.net': true,
  'seductive.com': true,
  'seekstoyboy.com': true,
  'send.hu': true,
  'sendme.cz': true,
  'senduvu.com': true,
  'sent.com': true,
  'serga.com.ar': true,
  'servemymail.com': true,
  'sesmail.com': true,
  'sexmagnet.com': true,
  'seznam.cz': true,
  'shahweb.net': true,
  'shaniastuff.com': true,
  'sharmaweb.com': true,
  'she.com': true,
  'shootmail.com': true,
  'shotgun.hu': true,
  'shuf.com': true,
  'sialkotcity.com': true,
  'sialkotian.com': true,
  'sialkotoye.com': true,
  'sify.com': true,
  'sinamail.com': true,
  'sina.com': true,
  'singapore.com': true,
  'singmail.com': true,
  'singnet.com.sg': true,
  'skim.com': true,
  'skizo.hu': true,
  'slamdunkfan.com': true,
  'slingshot.com': true,
  'slo.net': true,
  'slotter.com': true,
  'smapxsmap.net': true,
  'smileyface.comsmithemail.net': true,
  'smoothmail.com': true,
  'snail-mail.net': true,
  'snakemail.com': true,
  'sndt.net': true,
  'sneakemail.com': true,
  'sniper.hu': true,
  'snoopymail.com': true,
  'snowboarding.com': true,
  'snowdonia.net': true,
  'socamail.com': true,
  'softhome.net': true,
  'sol.dk': true,
  'soldier.hu': true,
  'soon.com': true,
  'soulfoodcookbook.com': true,
  'sp.nl': true,
  'space.com': true,
  'spacetowns.com': true,
  'spamex.com': true,
  'spartapiet.com': true,
  'spazmail.com': true,
  'speedpost.net': true,
  'spils.com': true,
  'spinfinder.com': true,
  'spoko.pl': true,
  'sportemail.com': true,
  'spray.no': true,
  'spray.se': true,
  'spymac.com': true,
  'srilankan.net': true,
  'st-davids.net': true,
  'stade.fr': true,
  'stargateradio.com': true,
  'starmail.com': true,
  'starmail.org': true,
  'starmedia.com': true,
  'starplace.com': true,
  'starspath.com': true,
  'start.com.au': true,
  'stopdropandroll.com': true,
  'stribmail.com': true,
  'strompost.com': true,
  'strongguy.com': true,
  'subram.com': true,
  'sudanmail.net': true,
  'suhabi.com': true,
  'suisse.org': true,
  'sunpoint.net': true,
  'sunrise-sunset.com': true,
  'sunsgame.com': true,
  'sunumail.sn': true,
  'superdada.com': true,
  'supereva.it': true,
  'supermail.ru': true,
  'surf3.net': true,
  'surfy.net': true,
  'surimail.com': true,
  'survivormail.com': true,
  'sweb.cz': true,
  'swiftdesk.com': true,
  'swirve.com': true,
  'swissinfo.org': true,
  'swissmail.net': true,
  'switchboardmail.com': true,
  'switzerland.org': true,
  'sx172.com': true,
  'syom.com': true,
  'syriamail.com': true,
  't2mail.com': true,
  'takuyakimura.com': true,
  'talk21.com': true,
  'talkcity.com': true,
  'tamil.com': true,
  'tatanova.com': true,
  'tech4peace.org': true,
  'techemail.com': true,
  'technisamail.co.za': true,
  'teenagedirtbag.com': true,
  'telebot.com': true,
  'teleline.es': true,
  'telinco.net': true,
  'telkom.net': true,
  'telpage.net': true,
  'tenchiclub.com': true,
  'tenderkiss.com': true,
  'terra.cl': true,
  'terra.com': true,
  'terra.com.ar': true,
  'terra.com.br': true,
  'terra.es': true,
  'tfanus.com.er': true,
  'tfz.net': true,
  'thai.com': true,
  'thaimail.com': true,
  'thaimail.net': true,
  'the-african.com': true,
  'the-airforce.com': true,
  'the-aliens.com': true,
  'the-american.com': true,
  'the-animal.com': true,
  'the-army.com': true,
  'the-astronaut.com': true,
  'the-beauty.com': true,
  'the-big-apple.com': true,
  'the-biker.com': true,
  'the-boss.com': true,
  'the-brazilian.com': true,
  'the-canadian.com': true,
  'the-canuck.com': true,
  'the-captain.com': true,
  'the-chinese.com': true,
  'the-country.com': true,
  'the-cowboy.com': true,
  'the-davis-home.com': true,
  'the-dutchman.com': true,
  'the-eagles.com': true,
  'the-englishman.com': true,
  'the-fastest.net': true,
  'the-fool.com': true,
  'the-frenchman.com': true,
  'the-galaxy.net': true,
  'the-genius.com': true,
  'the-gentleman.com': true,
  'the-german.com': true,
  'the-gremlin.com': true,
  'the-hooligan.com': true,
  'the-italian.com': true,
  'the-japanese.com': true,
  'the-lair.com': true,
  'the-madman.com': true,
  'the-mailinglist.com': true,
  'the-marine.com': true,
  'the-master.com': true,
  'the-mexican.com': true,
  'the-ministry.com': true,
  'the-monkey.com': true,
  'the-newsletter.net': true,
  'the-pentagon.com': true,
  'the-police.com': true,
  'the-prayer.com': true,
  'the-professional.com': true,
  'the-quickest.com': true,
  'the-russian.com': true,
  'the-snake.com': true,
  'the-spaceman.com': true,
  'the-stock-market.com': true,
  'the-student.net': true,
  'the-whitehouse.net': true,
  'the-wild-west.com': true,
  'the18th.com': true,
  'thecoolguy.com': true,
  'thecriminals.com': true,
  'thedoghousemail.com': true,
  'theend.hu': true,
  'thegolfcourse.com': true,
  'thegooner.com': true,
  'theheadoffice.com': true,
  'thelanddownunder.com': true,
  'theoffice.net': true,
  'thepokerface.com': true,
  'thepostmaster.net': true,
  'theraces.com': true,
  'theracetrack.com': true,
  'thestreetfighter.com': true,
  'theteebox.com': true,
  'thewatercooler.com': true,
  'thewebpros.co.uk': true,
  'thewizzard.com': true,
  'thewizzkid.com': true,
  'thezhangs.net': true,
  'thirdage.com': true,
  'thundermail.com': true,
  'tidni.com': true,
  'timein.net': true,
  'tiscali.at': true,
  'tiscali.be': true,
  'tiscali.co.uk': true,
  'tiscali.lu': true,
  'tiscali.se': true,
  'tkcity.com': true,
  'topchat.com': true,
  'topgamers.co.uk': true,
  'topletter.com': true,
  'topmail.com.ar': true,
  'topsurf.com': true,
  'torchmail.com': true,
  'travel.li': true,
  'trialbytrivia.com': true,
  'trmailbox.com': true,
  'tropicalstorm.com': true,
  'trust-me.com': true,
  'tsamail.co.za': true,
  'ttml.co.in': true,
  'tunisiamail.com': true,
  'turkey.com': true,
  'tutanota.com': true,
  'twinstarsmail.com': true,
  'tycoonmail.com': true,
  'typemail.com': true,
  'u2club.com': true,
  'uae.ac': true,
  'uaemail.com': true,
  'ubbi.com': true,
  'ubbi.com.br': true,
  'uboot.com': true,
  'uk2k.com': true,
  'uk2net.com': true,
  'uk7.net': true,
  'uk8.net': true,
  'ukbuilder.com': true,
  'ukcool.com': true,
  'ukdreamcast.com': true,
  'ukr.net': true,
  'uku.co.uk': true,
  'ultapulta.com': true,
  'ultrapostman.com': true,
  'ummah.org': true,
  'unbounded.com': true,
  'unican.es': true,
  'unihome.com': true,
  'universal.pt': true,
  'uno.ee': true,
  'uno.it': true,
  'unofree.it': true,
  'uol.com.ar': true,
  'uol.com.br': true,
  'uol.com.co': true,
  'uol.com.mx': true,
  'uol.com.ve': true,
  'uole.com': true,
  'uole.com.ve': true,
  'uolmail.com': true,
  'uomail.com': true,
  'ureach.com': true,
  'urgentmail.biz': true,
  'usanetmail.com': true,
  'uyuyuy.com': true,
  'v-sexi.com': true,
  'velnet.co.uk': true,
  'velocall.com': true,
  'verizonmail.com': true,
  'veryfast.biz': true,
  'veryspeedy.net': true,
  'violinmakers.co.uk': true,
  'vip.gr': true,
  'vip.onet.pl': true,
  'vipmail.ru': true,
  'virgilio.it': true,
  'virgin.net': true,
  'virtualmail.com': true,
  'visitmail.com': true,
  'visto.com': true,
  'vivianhsu.net': true,
  'vjtimail.com': true,
  'vnn.vn': true,
  'volcanomail.com': true,
  'vote-democrats.com': true,
  'vote-hillary.com': true,
  'vote-republicans.com': true,
  'vp.pl': true,
  'wahoye.com': true,
  'wales2000.net': true,
  'wam.co.za': true,
  'wanadoo.es': true,
  'warmmail.com': true,
  'warpmail.net': true,
  'warrior.hu': true,
  'waumail.com': true,
  'wearab.net': true,
  'web-mail.com.ar': true,
  'web-police.com': true,
  'web.de': true,
  'webave.com': true,
  'webcity.ca': true,
  'webdream.com': true,
  'webindia123.com': true,
  'webjump.com': true,
  'webmail.co.yu': true,
  'webmail.co.za': true,
  'webmail.hu': true,
  'webmails.com': true,
  'webprogramming.com': true,
  'webstation.com': true,
  'websurfer.co.za': true,
  'webtopmail.com': true,
  'weedmail.com': true,
  'weekonline.com': true,
  'wehshee.com': true,
  'welsh-lady.com': true,
  'whartontx.com': true,
  'wheelweb.com': true,
  'whipmail.com': true,
  'whoever.com': true,
  'whoopymail.com': true,
  'wildmail.com': true,
  'winmail.com.au': true,
  'winning.com': true,
  'witty.com': true,
  'wolf-web.com': true,
  'wombles.com': true,
  'wongfaye.com': true,
  'wootap.me': true,
  'wooow.it': true,
  'worldemail.com': true,
  'wosaddict.com': true,
  'wouldilie.com': true,
  'wowmail.com': true,
  'wp.pl': true,
  'wrexham.net': true,
  'writemeback.com': true,
  'wrongmail.com': true,
  'www.com': true,
  'wx88.net': true,
  'wxs.net': true,
  'x-mail.net': true,
  'x5g.com': true,
  'xmsg.com': true,
  'xoom.com': true,
  'xsmail.com': true,
  'xuno.com': true,
  'xzapmail.com': true,
  'yada-yada.com': true,
  'yaho.com': true,
  'yahoo.ca': true,
  'yahoo.co.in': true,
  'yahoo.co.jp': true,
  'yahoo.co.kr': true,
  'yahoo.co.nz': true,
  'yahoo.co.uk': true,
  'yahoo.com': true,
  'yahoo.com.ar': true,
  'yahoo.com.au': true,
  'yahoo.com.br': true,
  'yahoo.com.cn': true,
  'yahoo.com.hk': true,
  'yahoo.com.is': true,
  'yahoo.com.mx': true,
  'yahoo.com.ru': true,
  'yahoo.com.sg': true,
  'yahoo.de': true,
  'yahoo.dk': true,
  'yahoo.es': true,
  'yahoo.fr': true,
  'yahoo.ie': true,
  'yahoo.it': true,
  'yahoo.jp': true,
  'yahoo.ru': true,
  'yahoo.se': true,
  'yahoofs.com': true,
  'yalla.com': true,
  'yalla.com.lb': true,
  'yalook.com': true,
  'yam.com': true,
  'yandex.ru': true,
  'yapost.com': true,
  'yebox.com': true,
  'yehey.com': true,
  'yemenmail.com': true,
  'yepmail.net': true,
  'yifan.net': true,
  'yopolis.com': true,
  'youareadork.com': true,
  'your-house.com': true,
  'yourinbox.com': true,
  'yourlover.net': true,
  'yournightmare.com': true,
  'yours.com': true,
  'yourssincerely.com': true,
  'yourteacher.net': true,
  'yourwap.com': true,
  'yuuhuu.net': true,
  'yyhmail.com': true,
  'zahadum.com': true,
  'zeepost.nl': true,
  'zhaowei.net': true,
  'zip.net': true,
  'zipido.com': true,
  'ziplip.com': true,
  'zipmail.com': true,
  'zipmail.com.br': true,
  'zipmax.com': true,
  'zmail.ru': true,
  'zohomail.in': true,
  'zonnet.nl': true,
  'zubee.com': true,
  'zuvio.com': true,
  'zwallet.com': true,
  'zybermail.com': true,
  'zzn.com': true,
  'zzom.co.uk': true,
  'gmail.com': true,
  'live.com': true,
  'live.nl': true,
  'live.at': true,
  'live.be': true,
  'live.jp': true,
  'live.in': true,
  'live.co.uk': true,
  'live.se': true,
  'live.ru': true,
  'live.no': true,
  'outlook.com': true,
  'mail.ru': true,
  'ymail.com': true,
  'yandex.com': true,
  'byom.de': true,
  'mvrht.net': true,
  '0815.ru': true,
  '10minutemail.com': true,
  '10minutemail.co.za': true,
  '2trom.com': true,
  '33mail.com': true,
  '6ip.us': true,
  'abyssmail.com': true,
  'accountant.com': true,
  'acdcfan.com': true,
  'activist.com': true,
  'africamail.com': true,
  'aircraftmail.com': true,
  'allergist.com': true,
  'alumni.com': true,
  'alumnidirector.com': true,
  'amorki.pl': true,
  'anappthat.com': true,
  'angelic.com': true,
  'appraiser.net': true,
  'archaeologist.com': true,
  'arcticmail.com': true,
  'artlover.com': true,
  'asia.com': true,
  'asia-mail.com': true,
  'atheist.com': true,
  'auctioneer.net': true,
  'australiamail.com': true,
  'bartender.net': true,
  'bellair.net': true,
  'berlin.com': true,
  'bikerider.com': true,
  'birdlover.com': true,
  'blader.com': true,
  'boardermail.com': true,
  'boun.cr': true,
  'brazilmail.com': true,
  'brennendesreich.de': true,
  'brew-master.com': true,
  'brew-meister.com': true,
  'bsdmail.com': true,
  'bund.us': true,
  'cachedot.net': true,
  'californiamail.com': true,
  'cash4u.com': true,
  'cashforcarsbristol.co.uk': true,
  'catlover.com': true,
  'ce.mintemail.com': true,
  'chammy.info': true,
  'cheerful.com': true,
  'chef.net': true,
  'chemist.com': true,
  'chinamail.com': true,
  'clerk.com': true,
  'clrmail.com': true,
  'clubmember.org': true,
  'collector.org': true,
  'columnist.com': true,
  'comic.com': true,
  'computer4u.com': true,
  'consultant.com': true,
  'contractor.net': true,
  'coolsite.net': true,
  'counsellor.com': true,
  'cutey.com': true,
  'cuvox.de': true,
  'cyberdude.com': true,
  'cybergal.com': true,
  'cyberservices.com': true,
  'cyber-wizard.com': true,
  'dacoolest.com': true,
  'dallasmail.com': true,
  'dayrep.com': true,
  'dbzmail.com': true,
  'deliveryman.com': true,
  'devnullmail.com': true,
  'diplomats.com': true,
  'discard.email': true,
  'discardmail.com': true,
  'discardmail.de': true,
  'disciples.com': true,
  'discofan.com': true,
  'dispomail.eu': true,
  'disposable.com': true,
  'dispostable.com': true,
  'doctor.com': true,
  'dodgit.com': true,
  'doglover.com': true,
  'doramail.com': true,
  'dr.com': true,
  'drdrb.com': true,
  'dublin.com': true,
  'dutchmail.com': true,
  'eelmail.com': true,
  'einrot.com': true,
  'elvisfan.com': true,
  'email.com': true,
  'emailproxsy.com': true,
  'engineer.com': true,
  'englandmail.com': true,
  'europe.com': true,
  'europemail.com': true,
  'execs.com': true,
  'fakeinbox.com': true,
  'fastservice.com': true,
  'fileslib.site': true,
  'filzmail.com': true,
  'financier.com': true,
  'fireman.net': true,
  'fleckens.hu': true,
  'galaxyhit.com': true,
  'gardener.com': true,
  'geologist.com': true,
  'germanymail.com': true,
  'getairmail.com': true,
  'gmx.com': true,
  'gmx.us': true,
  'graduate.org': true,
  'graphic-designer.com': true,
  'greenmail.net': true,
  'groupmail.com': true,
  'grr.la': true,
  'guerrillamail.biz': true,
  'guerrillamailblock.com': true,
  'guerrillamail.com': true,
  'guerrillamail.de': true,
  'guerrillamail.net': true,
  'guerrillamail.org': true,
  'gurumail.xyz': true,
  'gustr.com': true,
  'hackermail.com': true,
  'hairdresser.net': true,
  'harakirimail.com': true,
  'hilarious.com': true,
  'hiphopfan.com': true,
  'hmamail.com': true,
  'homemail.com': true,
  'hot-shot.com': true,
  'housemail.com': true,
  'hulapla.de': true,
  'humanoid.net': true,
  'hushmail.com': true,
  'imgof.com': true,
  'imgv.de': true,
  'iname.com': true,
  'inboxproxy.com': true,
  'incognitomail.org': true,
  'innocent.com': true,
  'inorbit.com': true,
  'instruction.com': true,
  'instructor.net': true,
  'insurer.com': true,
  'irelandmail.com': true,
  'israelmail.com': true,
  'italymail.com': true,
  'job4u.com': true,
  'journalist.com': true,
  'jourrapide.com': true,
  'keromail.com': true,
  'kissfans.com': true,
  'kittymail.com': true,
  'koreamail.com': true,
  'lags.us': true,
  'leeching.net': true,
  'legislator.com': true,
  'letthemeatspam.com': true,
  'linuxmail.org': true,
  'lobbyist.com': true,
  'lovecat.com': true,
  'madonnafan.com': true,
  'maildrop.cc': true,
  'mailforspam.com': true,
  'mailhub.pw': true,
  'mailimate.com': true,
  'mailinator2.com': true,
  'mailinator.com': true,
  'mailinator.net': true,
  'mail-me.com': true,
  'mailnesia.com': true,
  'mailnull.com': true,
  'mailproxsy.com': true,
  'mailto.plus': true,
  'mailtothis.com': true,
  'marchmail.com': true,
  'meltmail.com': true,
  'metalfan.com': true,
  'mexicomail.com': true,
  'minister.com': true,
  'mintemail.com': true,
  'moscowmail.com': true,
  'mt2015.com': true,
  'munich.com': true,
  'musician.org': true,
  'muslim.com': true,
  'my10minutemail.com': true,
  'mynetstore.de': true,
  'myself.com': true,
  'mytrashmail.com': true,
  'net-shopping.com': true,
  'ninfan.com': true,
  'nonpartisan.com': true,
  'nonspam.eu': true,
  'nonspammer.de': true,
  'notmailinator.com': true,
  'null.net': true,
  'nycmail.com': true,
  'oath.com': true,
  'optician.com': true,
  'op.pl': true,
  'orthodontist.net': true,
  'pacific-ocean.com': true,
  'pacificwest.com': true,
  'pediatrician.com': true,
  'petlover.com': true,
  'photographer.net': true,
  'physicist.net': true,
  'planetmail.com': true,
  'planetmail.net': true,
  'polandmail.com': true,
  'politician.com': true,
  'post.com': true,
  'presidency.com': true,
  'priest.com': true,
  'programmer.net': true,
  'protestant.com': true,
  'publicist.com': true,
  'pwrby.com': true,
  'qoika.com': true,
  'qualityservice.com': true,
  'radiologist.net': true,
  'ravemail.com': true,
  'reallymymail.com': true,
  'realtyagent.com': true,
  'reborn.com': true,
  'reconmail.com': true,
  'reggaefan.com': true,
  'registerednurses.com': true,
  'reincarnate.com': true,
  'religious.com': true,
  'repairman.com': true,
  'representative.com': true,
  'rescueteam.com': true,
  'rhyta.com': true,
  'rocketship.com': true,
  's0ny.net': true,
  'safetymail.info': true,
  'safrica.com': true,
  'saintly.com': true,
  'salesperson.net': true,
  'samerica.com': true,
  'sanfranmail.com': true,
  'scotlandmail.com': true,
  'secretary.net': true,
  'sendspamhere.com': true,
  'sharedmailbox.org': true,
  'sharklasers.com': true,
  'snakebite.com': true,
  'socialworker.net': true,
  'sociologist.com': true,
  'sogetthis.com': true,
  'solution4u.com': true,
  'songwriter.net': true,
  'soodonims.com': true,
  'spainmail.com': true,
  'spam4.me': true,
  'spamavert.com': true,
  'spambog.com': true,
  'spambog.de': true,
  'spambog.ru': true,
  'spambooger.com': true,
  'spambox.us': true,
  'spamgourmet.com': true,
  'spamherelots.com': true,
  'spamhereplease.com': true,
  'spamhole.com': true,
  'spamstack.net': true,
  'spamthisplease.com': true,
  'stonerfans.com': true,
  'streetwisemail.com': true,
  'superrito.com': true,
  'suremail.info': true,
  'surgical.net': true,
  'swedenmail.com': true,
  'swissmail.com': true,
  'tafmail.com': true,
  'teachers.org': true,
  'tech-center.com': true,
  'techie.com': true,
  'technologist.com': true,
  'teewars.org': true,
  'teleworm.us': true,
  'tempmail.eu': true,
  'tempinbox.com': true,
  'temp-mail.org': true,
  'thehighlands.co.uk': true,
  'theplate.com': true,
  'therapist.net': true,
  'thisisnotmyrealemail.com': true,
  'throwawayemailaddress.com': true,
  'toke.com': true,
  'toothfairy.com': true,
  'torontomail.com': true,
  'tradermail.info': true,
  'tvstar.com': true,
  'umpire.com': true,
  'uroid.com': true,
  'usa.com': true,
  'uymail.com': true,
  'value-mycar.co.uk': true,
  'veryrealemail.com': true,
  'webname.com': true,
  'worker.com': true,
  'workmail.com': true,
  'writeme.com': true,
  'yopmail.com': true,
  'zetmail.com': true,
  'zippymail.info': true,
  'zxcvbnm.co.uk': true,
  'trash-mail.com': true,
  'trash-mail.at': true,
  'trash-mail.de': true,
  'trash2009.com': true,
  'trashemail.de': true,
  'trashmail.at': true,
  'trashmail.com': true,
  'trashmail.de': true,
  'trashmail.me': true,
  'trashmail.net': true,
  'trashmail.org': true,
  'trashmail.ws': true,
  'biyac.com': true,
  '0-mail.com': true,
  '0clickemail.com': true,
  '0wnd.net': true,
  '0wnd.org': true,
  '20minutemail.com': true,
  '2prong.com': true,
  '30minutemail.com': true,
  '3d-painting.com': true,
  '4warding.com': true,
  '4warding.net': true,
  '4warding.org': true,
  '60minutemail.com': true,
  '675hosting.com': true,
  '675hosting.net': true,
  '675hosting.org': true,
  '6url.com': true,
  '75hosting.com': true,
  '75hosting.net': true,
  '75hosting.org': true,
  '7tags.com': true,
  '9ox.net': true,
  'a-bc.net': true,
  'afrobacon.com': true,
  'ajaxapp.net': true,
  'amilegit.com': true,
  'amiri.net': true,
  'amiriindustries.com': true,
  'anonbox.net': true,
  'bodhi.lawlita.com': true,
  'bofthew.com': true,
  'brefmail.com': true,
  'broadbandninja.com': true,
  'bsnow.net': true,
  'bugmenot.com': true,
  'bumpymail.com': true,
  'casualdx.com': true,
  'centermail.com': true,
  'centermail.net': true,
  'chogmail.com': true,
  'choicemail1.com': true,
  'cool.fr.nf': true,
  'correo.blogos.net': true,
  'cosmorph.com': true,
  'courriel.fr.nf': true,
  'courrieltemporaire.com': true,
  'cubiclink.com': true,
  'curryworld.de': true,
  'cust.in': true,
  'dandikmail.com': true,
  'deadaddress.com': true,
  'deadspam.com': true,
  'despam.it': true,
  'despammed.com': true,
  'dfgh.net': true,
  'digitalsanctuary.com': true,
  'Disposableemailaddresses:emailmiser.com': true,
  'disposableaddress.com': true,
  'disposeamail.com': true,
  'disposemail.com': true,
  'dm.w3internet.co.ukexample.com': true,
  'dodgeit.com': true,
  'dodgit.org': true,
  'donemail.ru': true,
  'dontreg.com': true,
  'dontsendmespam.de': true,
  'dump-email.info': true,
  'dumpandjunk.com': true,
  'dumpmail.de': true,
  'dumpyemail.com': true,
  'e4ward.com': true,
  'email60.com': true,
  'emaildienst.de': true,
  'emailias.com': true,
  'emailigo.de': true,
  'emailinfive.com': true,
  'emailmiser.com': true,
  'emailsensei.com': true,
  'emailtemporario.com.br': true,
  'emailto.de': true,
  'emailwarden.com': true,
  'emailx.at.hm': true,
  'emailxfer.com': true,
  'emz.net': true,
  'enterto.com': true,
  'ephemail.net': true,
  'etranquil.com': true,
  'etranquil.net': true,
  'etranquil.org': true,
  'explodemail.com': true,
  'fakeinformation.com': true,
  'fastacura.com': true,
  'fastchevy.com': true,
  'fastchrysler.com': true,
  'fastkawasaki.com': true,
  'fastmazda.com': true,
  'fastmitsubishi.com': true,
  'fastnissan.com': true,
  'fastsubaru.com': true,
  'fastsuzuki.com': true,
  'fasttoyota.com': true,
  'fastyamaha.com': true,
  'fizmail.com': true,
  'fr33mail.info': true,
  'frapmail.com': true,
  'front14.org': true,
  'fux0ringduh.com': true,
  'garliclife.com': true,
  'get1mail.com': true,
  'get2mail.fr': true,
  'getonemail.com': true,
  'getonemail.net': true,
  'ghosttexter.de': true,
  'girlsundertheinfluence.com': true,
  'gishpuppy.com': true,
  'gowikibooks.com': true,
  'gowikicampus.com': true,
  'gowikicars.com': true,
  'gowikifilms.com': true,
  'gowikigames.com': true,
  'gowikimusic.com': true,
  'gowikinetwork.com': true,
  'gowikitravel.com': true,
  'gowikitv.com': true,
  'great-host.in': true,
  'greensloth.com': true,
  'gsrv.co.uk': true,
  'guerillamail.biz': true,
  'guerillamail.com': true,
  'guerillamail.net': true,
  'guerillamail.org': true,
  'h.mintemail.com': true,
  'h8s.org': true,
  'haltospam.com': true,
  'hatespam.org': true,
  'hidemail.de': true,
  'hochsitze.com': true,
  'ieatspam.eu': true,
  'ieatspam.info': true,
  'ihateyoualot.info': true,
  'iheartspam.org': true,
  'imails.info': true,
  'inboxclean.com': true,
  'inboxclean.org': true,
  'incognitomail.com': true,
  'incognitomail.net': true,
  'insorg-mail.info': true,
  'ipoo.org': true,
  'irish2me.com': true,
  'iwi.net': true,
  'jetable.com': true,
  'jetable.fr.nf': true,
  'jetable.net': true,
  'jetable.org': true,
  'jnxjn.com': true,
  'junk1e.com': true,
  'kasmail.com': true,
  'kaspop.com': true,
  'keepmymail.com': true,
  'killmail.com': true,
  'killmail.net': true,
  'kir.ch.tc': true,
  'klassmaster.com': true,
  'klassmaster.net': true,
  'klzlk.com': true,
  'kulturbetrieb.info': true,
  'kurzepost.de': true,
  'lhsdv.com': true,
  'lifebyfood.com': true,
  'link2mail.net': true,
  'litedrop.com': true,
  'lol.ovpn.to': true,
  'lookugly.com': true,
  'lopl.co.cc': true,
  'lortemail.dk': true,
  'lr78.com': true,
  'm4ilweb.info': true,
  'maboard.com': true,
  'mail-temporaire.fr': true,
  'mail.by': true,
  'mail.mezimages.net': true,
  'mail2rss.org': true,
  'mail4trash.com': true,
  'mailbidon.com': true,
  'mailblocks.com': true,
  'mailcatch.com': true,
  'maileater.com': true,
  'mailexpire.com': true,
  'mailfreeonline.com': true,
  'mailin8r.com': true,
  'mailinater.com': true,
  'mailincubator.com': true,
  'mailme.ir': true,
  'mailme.lv': true,
  'mailmetrash.com': true,
  'mailmoat.com': true,
  'mailnator.com': true,
  'mailshell.com': true,
  'mailsiphon.com': true,
  'mailslite.com': true,
  'mailzilla.com': true,
  'mailzilla.org': true,
  'mbx.cc': true,
  'mega.zik.dj': true,
  'meinspamschutz.de': true,
  'messagebeamer.de': true,
  'mierdamail.com': true,
  'moburl.com': true,
  'moncourrier.fr.nf': true,
  'monemail.fr.nf': true,
  'monmail.fr.nf': true,
  'msa.minsmail.com': true,
  'mt2009.com': true,
  'mx0.wwwnew.eu': true,
  'mycleaninbox.net': true,
  'mypartyclip.de': true,
  'myphantomemail.com': true,
  'myspaceinc.com': true,
  'myspaceinc.net': true,
  'myspaceinc.org': true,
  'myspacepimpedup.com': true,
  'myspamless.com': true,
  'neomailbox.com': true,
  'nepwk.com': true,
  'nervmich.net': true,
  'nervtmich.net': true,
  'netmails.com': true,
  'netmails.net': true,
  'netzidiot.de': true,
  'neverbox.com': true,
  'no-spam.ws': true,
  'nobulk.com': true,
  'noclickemail.com': true,
  'nogmailspam.info': true,
  'nomail.xl.cx': true,
  'nomail2me.com': true,
  'nomorespamemails.com': true,
  'nospam.ze.tc': true,
  'nospam4.us': true,
  'nospamfor.us': true,
  'nospamthanks.info': true,
  'nowmymail.com': true,
  'nurfuerspam.de': true,
  'nus.edu.sg': true,
  'nwldx.com': true,
  'objectmail.com': true,
  'obobbo.com': true,
  'oneoffemail.com': true,
  'onewaymail.com': true,
  'online.ms': true,
  'oopi.org': true,
  'ordinaryamerican.net': true,
  'otherinbox.com': true,
  'ourklips.com': true,
  'outlawspam.com': true,
  'ovpn.to': true,
  'owlpic.com': true,
  'pancakemail.com': true,
  'pimpedupmyspace.com': true,
  'pjjkp.com': true,
  'politikerclub.de': true,
  'poofy.org': true,
  'pookmail.com': true,
  'privacy.net': true,
  'proxymail.eu': true,
  'prtnx.com': true,
  'PutThisInYourSpamDatabase.com': true,
  'quickinbox.com': true,
  'rcpt.at': true,
  'recode.me': true,
  'recursor.net': true,
  'regbypass.com': true,
  'regbypass.comsafe-mail.net': true,
  'rejectmail.com': true,
  'rklips.com': true,
  'rmqkr.net': true,
  'rppkn.com': true,
  'rtrtr.com': true,
  'safersignup.de': true,
  'safetypost.de': true,
  'sandelf.de': true,
  'saynotospams.com': true,
  'selfdestructingmail.com': true,
  'SendSpamHere.com': true,
  'shiftmail.com': true,
  'shitmail.me': true,
  'shortmail.net': true,
  'sibmail.com': true,
  'skeefmail.com': true,
  'slaskpost.se': true,
  'slopsbox.com': true,
  'smellfear.com': true,
  'sofimail.com': true,
  'sofort-mail.de': true,
  'spam.la': true,
  'spam.su': true,
  'spambob.com': true,
  'spambob.net': true,
  'spambob.org': true,
  'spambox.info': true,
  'spambox.irishspringrealty.com': true,
  'spamcannon.com': true,
  'spamcannon.net': true,
  'spamcero.com': true,
  'spamcon.org': true,
  'spamcorptastic.com': true,
  'spamcowboy.com': true,
  'spamcowboy.net': true,
  'spamcowboy.org': true,
  'spamday.com': true,
  'spamfree24.com': true,
  'spamfree24.de': true,
  'spamfree24.eu': true,
  'spamfree24.info': true,
  'spamfree24.net': true,
  'spamfree24.org': true,
  'SpamHereLots.com': true,
  'SpamHerePlease.com': true,
  'spamify.com': true,
  'spaminator.de': true,
  'spamkill.info': true,
  'spaml.com': true,
  'spaml.de': true,
  'spammotel.com': true,
  'spamobox.com': true,
  'spamoff.de': true,
  'spamslicer.com': true,
  'spamspot.com': true,
  'spamthis.co.uk': true,
  'spamtrail.com': true,
  'speed.1s.fr': true,
  'supergreatmail.com': true,
  'supermailer.jp': true,
  'teleworm.com': true,
  'tempalias.com': true,
  'tempe-mail.com': true,
  'tempemail.biz': true,
  'tempemail.com': true,
  'TempEMail.net': true,
  'tempinbox.co.uk': true,
  'tempmail.it': true,
  'tempmail2.com': true,
  'tempomail.fr': true,
  'tempmailo.com': true,
  'temporarily.de': true,
  'temporarioemail.com.br': true,
  'temporaryemail.net': true,
  'temporaryforwarding.com': true,
  'temporaryinbox.com': true,
  'thanksnospam.info': true,
  'thankyou2010.com': true,
  'tilien.com': true,
  'tmailinator.com': true,
  'trash-amil.com': true,
  'trashmailer.com': true,
  'trashymail.com': true,
  'trashymail.net': true,
  'trillianpro.com': true,
  'turual.com': true,
  'twinmail.de': true,
  'tyldd.com': true,
  'uggsrock.com': true,
  'upliftnow.com': true,
  'uplipht.com': true,
  'venompen.com': true,
  'viditag.com': true,
  'viewcastmedia.com': true,
  'viewcastmedia.net': true,
  'viewcastmedia.org': true,
  'webm4il.info': true,
  'wegwerfadresse.de': true,
  'wegwerfemail.de': true,
  'wegwerfmail.de': true,
  'wegwerfmail.net': true,
  'wegwerfmail.org': true,
  'wetrainbayarea.com': true,
  'wetrainbayarea.org': true,
  'wh4f.org': true,
  'whyspam.me': true,
  'willselfdestruct.com': true,
  'winemaven.info': true,
  'wronghead.com': true,
  'wuzup.net': true,
  'wuzupmail.net': true,
  'www.e4ward.com': true,
  'www.gishpuppy.com': true,
  'www.mailinator.com': true,
  'wwwnew.eu': true,
  'xagloo.com': true,
  'xemaps.com': true,
  'xents.com': true,
  'xmaily.com': true,
  'xoxy.net': true,
  'yep.it': true,
  'yogamaven.com': true,
  'yopmail.fr': true,
  'yopmail.net': true,
  'ypmail.webarnak.fr.eu.org': true,
  'yuurok.com': true,
  'zehnminutenmail.de': true,
  'zoaxe.com': true,
  'zoemail.org': true,
  '0815.ru0clickemail.com': true,
  '0815.ry': true,
  '0815.su': true,
  '0845.ru': true,
  '10mail.com': true,
  '10mail.org': true,
  '10minut.com.pl': true,
  '10minute-email.com': true,
  '10minutemail.cf': true,
  '10minutemail.co.uk': true,
  '10minutemail.de': true,
  '10minutemail.ga': true,
  '10minutemail.gq': true,
  '10minutemail.ml': true,
  '10minutemail.net': true,
  '10minutesmail.com': true,
  '10x9.com': true,
  '123-m.com': true,
  '126.com': true,
  '12houremail.com': true,
  '12minutemail.com': true,
  '12minutemail.net': true,
  '139.com': true,
  '1ce.us': true,
  '1chuan.com': true,
  '1fsdfdsfsdf.tk': true,
  '1mail.ml': true,
  '1pad.de': true,
  '1zhuan.com': true,
  '20mail.it': true,
  '20email.eu': true,
  '21cn.com': true,
  '23423.ru': true,
  '24hourmail.com': true,
  '2fdgdfgdfgdf.tk': true,
  '30mail.ir': true,
  '3mail.ga': true,
  '3trtretgfrfe.tk': true,
  '420blaze.it': true,
  '4gfdsgfdgfd.tk': true,
  '4mail.cf': true,
  '4mail.ga': true,
  '5ghgfhfghfgh.tk': true,
  '5mail.cf': true,
  '5mail.ga': true,
  '6hjgjhgkilkj.tk': true,
  '6mail.cf': true,
  '6mail.ga': true,
  '6mail.ml': true,
  '6paq.com': true,
  '7days-printing.com': true,
  '7mail.ga': true,
  '7mail.ml': true,
  '8127ep.com': true,
  '8chan.co': true,
  '8mail.cf': true,
  '8mail.ga': true,
  '8mail.ml': true,
  '99experts.com': true,
  '9mail.cf': true,
  'a.mailcker.com': true,
  'a.vztc.com': true,
  'a45.in': true,
  'ag.us.to': true,
  'agedmail.com': true,
  'akapost.com': true,
  'akerd.com': true,
  'aktiefmail.nl': true,
  'alivance.com': true,
  'amail4.me': true,
  'ama-trade.de': true,
  'ama-trans.de': true,
  'anappfor.com': true,
  'ano-mail.net': true,
  'anon-mail.de': true,
  'anonymail.dk': true,
  'anonymousmail.org': true,
  'anonymousspeech.com': true,
  'antireg.com': true,
  'antispam24.de': true,
  'asdasd.nl': true,
  'asdasd.ru': true,
  'atvclub.msk.ru': true,
  'auti.st': true,
  'avpa.nl': true,
  'b2cmail.de': true,
  'beddly.com': true,
  'bestxfilm.ru': true,
  'big1.us': true,
  'bigprofessor.so': true,
  'bio-muesli.info': true,
  'blackmarket.to': true,
  'bladesmail.net': true,
  'bloatbox.com': true,
  'blogmyway.org': true,
  'blogos.com': true,
  'bluebottle.com': true,
  'bootybay.de': true,
  'bouncr.com': true,
  'boxformail.in': true,
  'boximail.com': true,
  'br.mintemail.com': true,
  'breakthru.com': true,
  'bspamfree.org': true,
  'bu.mintemail.com': true,
  'buffemail.com': true,
  'bugmenever.com': true,
  'bundes-li.ga': true,
  'burnthespam.info': true,
  'burstmail.info': true,
  'buymoreplays.com': true,
  'buyusedlibrarybooks.org': true,
  'c2.hu': true,
  'cam4you.cc': true,
  'card.zp.ua': true,
  'cc.liamria': true,
  'cek.pm': true,
  'cekc-video.com': true,
  'cellurl.com': true,
  'chaparmail.tk': true,
  'cheatmail.de': true,
  'childsavetrust.org': true,
  'chong-mail.com': true,
  'chong-mail.net': true,
  'chong-mail.org': true,
  'clixser.com': true,
  'cmail.com': true,
  'cmail.net': true,
  'cmail.org': true,
  'cock.li': true,
  'coieo.com': true,
  'coldemail.info': true,
  'consumerriot.com': true,
  'crapmail.org': true,
  'crazymailing.com': true,
  'cryptogmail.com': true,
  'cumallover.me': true,
  'd3p.dk': true,
  'dbunker.com': true,
  'deadchildren.org': true,
  'deadfake.cf': true,
  'deadfake.ga': true,
  'deadfake.ml': true,
  'deadfake.tk': true,
  'deagot.com': true,
  'dealja.com': true,
  'delikkt.de': true,
  'dharmatel.net': true,
  'dicksinhisan.us': true,
  'dicksinmyan.us': true,
  'dingbone.com': true,
  'discard.cf': true,
  'discard.ga': true,
  'discard.gq': true,
  'discard.ml': true,
  'discard.tk': true,
  'disposable.cf': true,
  'disposable.ga': true,
  'disposable.ml': true,
  'disposable-email.ml': true,
  'disposableemailaddresses.com': true,
  'disposableinbox.com': true,
  'dispose.it': true,
  'divermail.com': true,
  'divismail.ru': true,
  'dm.w3internet.co.uk': true,
  'docmail.com': true,
  'doiea.com': true,
  'domozmail.com': true,
  'dotman.de': true,
  'dotmsg.com': true,
  'drdrb.net': true,
  'dropcake.de': true,
  'droplister.com': true,
  'dropmail.me': true,
  'dudmail.com': true,
  'duskmail.com': true,
  'easytrashmail.com': true,
  'edv.to': true,
  'ee1.pl': true,
  'ee2.pl': true,
  'einmalmail.de': true,
  'einrot.de': true,
  'eintagsmail.de': true,
  'e-mail.com': true,
  'emeil.cf': true,
  'email.net': true,
  'emailirani.ir': true,
  'e-mail.org': true,
  'emailage.cf': true,
  'emailage.ga': true,
  'emailage.gq': true,
  'emailage.ml': true,
  'emailage.tk': true,
  'email-fake.cf': true,
  'email-fake.ga': true,
  'email-fake.gq': true,
  'email-fake.ml': true,
  'email-fake.tk': true,
  'emailgo.de': true,
  'emaillime.com': true,
  'emails.ga': true,
  'emailspam.cf': true,
  'emailspam.ga': true,
  'emailspam.gq': true,
  'emailspam.ml': true,
  'emailspam.tk': true,
  'emailtemporanea.com': true,
  'emailtemporanea.net': true,
  'emailtemporar.ro': true,
  'emailthe.net': true,
  'emailtmp.com': true,
  'emailz.cf': true,
  'emailz.ga': true,
  'emailz.gq': true,
  'emailz.ml': true,
  'emall.ir': true,
  'emeil.in': true,
  'emeil.ir': true,
  'emkei.cf': true,
  'emkei.ga': true,
  'emkei.gq': true,
  'emkei.ml': true,
  'emkei.tk': true,
  'e-postkasten.com': true,
  'e-postkasten.de': true,
  'e-postkasten.eu': true,
  'e-postkasten.info': true,
  'ero-tube.org': true,
  'evopo.com': true,
  'example.com': true,
  'express.net.ua': true,
  'eyepaste.com': true,
  'facebook-email.cf': true,
  'facebook-email.ga': true,
  'facebook-email.ml': true,
  'facebookmail.gq': true,
  'facebookmail.ml': true,
  'faecesmail.me': true,
  'fakedemail.com': true,
  'fakeinbox.cf': true,
  'fakeinbox.ga': true,
  'fakeinbox.ml': true,
  'fakeinbox.tk': true,
  'fake-mail.cf': true,
  'fakemail.fr': true,
  'fake-mail.ga': true,
  'fake-mail.ml': true,
  'fakemailgenerator.com': true,
  'fakemailz.com': true,
  'fammix.com': true,
  'fansworldwide.de': true,
  'fantasymail.de': true,
  'fatflap.com': true,
  'fdfdsfds.com': true,
  'fightallspam.com': true,
  'film-blog.biz': true,
  'fivemail.de': true,
  'fixmail.tk': true,
  'flurred.com': true,
  'flyspam.com': true,
  'fly-ts.de': true,
  'footard.com': true,
  'forgetmail.com': true,
  'fornow.eu': true,
  'freecoolemail.com': true,
  'free-email.cf': true,
  'free-email.ga': true,
  'freeletter.me': true,
  'freemail.ms': true,
  'freemails.cf': true,
  'freemails.ga': true,
  'freemails.ml': true,
  'freundin.ru': true,
  'friendlymail.co.uk': true,
  'fuckingduh.com': true,
  'fuckmail.me': true,
  'fudgerub.com': true,
  'fyii.de': true,
  'garbagemail.org': true,
  'garrifulio.mailexpire.com': true,
  'gehensiemirnichtaufdensack.de': true,
  'gelitik.in': true,
  'geschent.biz': true,
  'getairmail.cf': true,
  'getairmail.ga': true,
  'getairmail.gq': true,
  'getairmail.ml': true,
  'getairmail.tk': true,
  'get-mail.cf': true,
  'get-mail.ga': true,
  'get-mail.ml': true,
  'get-mail.tk': true,
  'getmails.eu': true,
  'giantmail.de': true,
  'gmal.com': true,
  'gmial.com': true,
  'goat.si': true,
  'goemailgo.com': true,
  'gomail.in': true,
  'gorillaswithdirtyarmpits.com': true,
  'gotmail.net': true,
  'gotmail.org': true,
  'gotti.otherinbox.com': true,
  'grandmamail.com': true,
  'grandmasmail.com': true,
  'guerillamailblock.com': true,
  'guerrillamail.info': true,
  'hacccc.com': true,
  'hartbot.de': true,
  'hat-geld.de': true,
  'herp.in': true,
  'hidzz.com': true,
  'hooohush.ai': true,
  'hopemail.biz': true,
  'horsefucker.org': true,
  'hotmai.com': true,
  'hot-mail.cf': true,
  'hot-mail.ga': true,
  'hot-mail.gq': true,
  'hot-mail.ml': true,
  'hot-mail.tk': true,
  'hotmial.com': true,
  'huajiachem.cn': true,
  'humaility.com': true,
  'hush.ai': true,
  'hush.com': true,
  'hushmail.me': true,
  'i2pmail.org': true,
  'ieh-mail.de': true,
  'ignoremail.com': true,
  'ikbenspamvrij.nl': true,
  'imstations.com': true,
  'inbax.tk': true,
  'inbox.si': true,
  'inbox2.info': true,
  'inboxalias.com': true,
  'inboxdesign.me': true,
  'inboxed.im': true,
  'inboxed.pw': true,
  'inboxstore.me': true,
  'infocom.zp.ua': true,
  'instantemailaddress.com': true,
  'instant-mail.de': true,
  'iozak.com': true,
  'ip6.li': true,
  'iroid.com': true,
  'is.af': true,
  'iwantmyname.com': true,
  'jsrsolutions.com': true,
  'junk.to': true,
  'junkmail.ga': true,
  'junkmail.gq': true,
  'k2-herbal-incenses.com': true,
  'kloap.com': true,
  'kmhow.com': true,
  'kostenlosemailadresse.de': true,
  'koszmail.pl': true,
  'l33r.eu': true,
  'lackmail.net': true,
  'landmail.co': true,
  'lastmail.co': true,
  'lavabit.com': true,
  'lawlita.com': true,
  'linuxmail.so': true,
  'llogin.ru': true,
  'loadby.us': true,
  'login-email.cf': true,
  'login-email.ga': true,
  'login-email.ml': true,
  'login-email.tk': true,
  'lol.com': true,
  'lolfreak.net': true,
  'losemymail.com': true,
  'lovebitco.in': true,
  'lovemeleaveme.com': true,
  'loves.dicksinhisan.us': true,
  'loves.dicksinmyan.us': true,
  'lr7.us': true,
  'lroid.com': true,
  'luckymail.org': true,
  'lukop.dk': true,
  'luv2.us': true,
  'm21.cc': true,
  'ma1l.bij.pl': true,
  'mac.hush.com': true,
  'mail.me': true,
  'mail.zp.ua': true,
  'mail114.net': true,
  'mail1a.de': true,
  'mail21.cc': true,
  'mailbiz.biz': true,
  'mailbucket.org': true,
  'mailcat.biz': true,
  'mailde.de': true,
  'mailde.info': true,
  'maildrop.cf': true,
  'maildrop.ga': true,
  'maildrop.gq': true,
  'maildrop.ml': true,
  'maildu.de': true,
  'mailed.in': true,
  'maileimer.de': true,
  'mailfa.tk': true,
  'mail-filter.com': true,
  'mailfree.ga': true,
  'mailfree.gq': true,
  'mailfree.ml': true,
  'mailguard.me': true,
  'mailhazard.com': true,
  'mailhazard.us': true,
  'mailhz.me': true,
  'mailinator.gq': true,
  'mailinator.org': true,
  'mailinator.us': true,
  'mailismagic.com': true,
  'mailita.tk': true,
  'mailjunk.cf': true,
  'mailjunk.ga': true,
  'mailjunk.gq': true,
  'mailjunk.ml': true,
  'mailjunk.tk': true,
  'mailme.gq': true,
  'mailme24.com': true,
  'mailms.com': true,
  'mailorg.org': true,
  'mailpick.biz': true,
  'mailquack.com': true,
  'mailrock.biz': true,
  'mailsac.com': true,
  'mailscrap.com': true,
  'mailseal.de': true,
  'mailslapping.com': true,
  'mailtemp.info': true,
  'mailtome.de': true,
  'mailtrash.net': true,
  'mailtv.net': true,
  'mailtv.tv': true,
  'mailwithyou.com': true,
  'mailzi.ru': true,
  'makemetheking.com': true,
  'malahov.de': true,
  'manifestgenerator.com': true,
  'manybrain.com': true,
  'mezimages.net': true,
  'migmail.pl': true,
  'migumail.com': true,
  'ministry-of-silly-walks.de': true,
  'misterpinball.de': true,
  'mjukglass.nu': true,
  'mmmmail.com': true,
  'moakt.com': true,
  'mobi.web.id': true,
  'mobileninja.co.uk': true,
  'mohmal.com': true,
  'monumentmail.com': true,
  'ms9.mailslite.com': true,
  'msb.minsmail.com': true,
  'msg.mailslite.com': true,
  'mt2014.com': true,
  'muchomail.com': true,
  'mvrht.com': true,
  'mycard.net.ua': true,
  'myemailboxy.com': true,
  'mymail-in.net': true,
  'mypacks.net': true,
  'mysamp.de': true,
  'mytempemail.com': true,
  'mytempmail.com': true,
  'mythrashmail.net': true,
  'nabuma.com': true,
  'national.shitposting.agency': true,
  'naver.com': true,
  'nevermail.de': true,
  'nice-4u.com': true,
  'nigge.rs': true,
  'nincsmail.hu': true,
  'nmail.cf': true,
  'nnh.com': true,
  'noblepioneer.com': true,
  'nobugmail.com': true,
  'nobuma.com': true,
  'nomail.pw': true,
  'noref.in': true,
  'nospam.wins.com.br': true,
  'notsharingmy.info': true,
  'nowhere.org': true,
  'ntlhelp.net': true,
  'nullbox.info': true,
  'odaymail.com': true,
  'odnorazovoe.ru': true,
  'ohaaa.de': true,
  'olypmall.ru': true,
  'omail.pro': true,
  'oneoffmail.com': true,
  'onlatedotcom.info': true,
  'opayq.com': true,
  'paplease.com': true,
  'pcusers.otherinbox.com': true,
  'pepbot.com': true,
  'pfui.ru': true,
  'phentermine-mortgages-texas-holdem.biz': true,
  'plexolan.de': true,
  'poczta.onet.pl': true,
  'postonline.me': true,
  'powered.name': true,
  'privatdemail.net': true,
  'privy-mail.com': true,
  'privymail.de': true,
  'privy-mail.de': true,
  'prtz.eu': true,
  'put2.net': true,
  'putthisinyourspamdatabase.com': true,
  'qasti.com': true,
  'qisdo.com': true,
  'qisoa.com': true,
  'quickmail.nl': true,
  'rcs.gaggle.net': true,
  'realtyalerts.ca': true,
  'receiveee.com': true,
  'recyclemail.dk': true,
  'redchan.it': true,
  'reliable-mail.com': true,
  'remail.cf': true,
  'remail.ga': true,
  'royal.net': true,
  'scatmail.com': true,
  'schafmail.de': true,
  'schmeissweg.tk': true,
  'schrott-email.de': true,
  'secmail.pw': true,
  'secretemail.de': true,
  'secure-mail.biz': true,
  'secure-mail.cc': true,
  'selfdestructingmail.org': true,
  'senseless-entertainment.com': true,
  'server.ms': true,
  'services391.com': true,
  'shieldedmail.com': true,
  'shieldemail.com': true,
  'shitmail.org': true,
  'shitware.nl': true,
  'shmeriously.com': true,
  'shut.name': true,
  'shut.ws': true,
  'sina.cn': true,
  'sinnlos-mail.de': true,
  'siteposter.net': true,
  'sky-ts.de': true,
  'skyfi.org.ru': true,
  'slapsfromlastnight.com': true,
  'slave-auctions.net': true,
  'slushmail.com': true,
  'smaakt.naar.gravel': true,
  'smapfree24.com': true,
  'smapfree24.de': true,
  'smapfree24.eu': true,
  'smapfree24.info': true,
  'smapfree24.org': true,
  'smashmail.de': true,
  'sneakmail.de': true,
  'snkmail.com': true,
  'sofortmail.de': true,
  'sohu.com': true,
  'solvemail.info': true,
  'soodomail.com': true,
  'spamail.de': true,
  'spamarrest.com': true,
  'spam-be-gone.com': true,
  'spambog.net': true,
  'spambox.org': true,
  'spamdecoy.net': true,
  'spamfighter.cf': true,
  'spamfighter.ga': true,
  'spamfighter.gq': true,
  'spamfighter.ml': true,
  'spamfighter.tk': true,
  'spamfree.eu': true,
  'spamgoes.in': true,
  'spamgourmet.net': true,
  'spamgourmet.org': true,
  'spamsalad.in': true,
  'spamtroll.net': true,
  'spoofmail.de': true,
  'squizzy.de': true,
  'sry.li': true,
  'ssoia.com': true,
  'startkeys.com': true,
  'stinkefinger.net': true,
  'stop-my-spam.cf': true,
  'stop-my-spam.com': true,
  'stop-my-spam.ga': true,
  'stop-my-spam.ml': true,
  'stop-my-spam.tk': true,
  'stuffmail.de': true,
  'suioe.com': true,
  'super-auswahl.de': true,
  'superplatyna.com': true,
  'superstachel.de': true,
  'sweetxxx.de': true,
  'swift10minutemail.com': true,
  'trbvn.com': true,
  'tagyourself.com': true,
  'talkinator.com': true,
  'tapchicuoihoi.com': true,
  'techgroup.me': true,
  'temp.emeraldwebmail.com': true,
  'tempail.com': true,
  'tempemail.co.za': true,
  'tempemail.net': true,
  'tempimbox.com': true,
  'temp-mail.ru': true,
  'tempmaildemo.com': true,
  'tempmailer.com': true,
  'tempmailer.de': true,
  'temporaryemail.us': true,
  'temporarymailaddress.com': true,
  'tempthe.net': true,
  'tempymail.com': true,
  'tfwno.gf': true,
  'thc.st': true,
  'thecloudindex.com': true,
  'thelimestones.com': true,
  'thismail.net': true,
  'thraml.com': true,
  'thrma.com': true,
  'throam.com': true,
  'throwawaymail.com': true,
  'throwawayemail.com': true,
  'tijdelijkmailadres.nl': true,
  'tittbit.in': true,
  'tizi.com': true,
  'tmail.com': true,
  'toiea.com': true,
  'tokem.co': true,
  'toomail.biz': true,
  'topcoolemail.com': true,
  'topfreeemail.com': true,
  'topranklist.de': true,
  'tormail.net': true,
  'tormail.org': true,
  'trash2010.com': true,
  'trash2011.com': true,
  'trashcanmail.com': true,
  'trashdevil.com': true,
  'trashdevil.de': true,
  'trashinbox.com': true,
  'trash-mail.cf': true,
  'trash-mail.ga': true,
  'trash-mail.gq': true,
  'trash-mail.ml': true,
  'trash-mail.tk': true,
  'trayna.com': true,
  'trbvm.com': true,
  'trialmail.de': true,
  'trickmail.net': true,
  'tryalert.com': true,
  'ubismail.net': true,
  'umail.net': true,
  'us.af': true,
  'uyhip.com': true,
  'valemail.net': true,
  'verticalscope.com': true,
  'veryrealmail.com': true,
  'vkcode.ru': true,
  'vidchart.com': true,
  'vipmail.name': true,
  'vipmail.pw': true,
  'viralplays.com': true,
  'vistomail.com': true,
  'vomoto.com': true,
  'vpn.st': true,
  'vsimcard.com': true,
  'vubby.com': true,
  'vztc.com': true,
  'walala.org': true,
  'walkmail.net': true,
  'wants.dicksinhisan.us': true,
  'wants.dicksinmyan.us': true,
  'wasteland.rfc822.org': true,
  'watchfull.net': true,
  'watch-harry-potter.com': true,
  'wavemail.ir': true,
  'webemail.me': true,
  'webuser.in': true,
  'wegwerfemail.com': true,
  'wegwerf-email.de': true,
  'weg-werf-email.de': true,
  'wegwerfemail.net': true,
  'wegwerf-email.net': true,
  'wegwerfemail.org': true,
  'wegwerf-email-addressen.de': true,
  'wegwerfemailadresse.com': true,
  'wegwerf-email-adressen.de': true,
  'wegwerf-emails.de': true,
  'wegwerfmail.info': true,
  'wegwerpmailadres.nl': true,
  'wegwrfmail.de': true,
  'wegwrfmail.net': true,
  'wegwrfmail.org': true,
  'whatiaas.com': true,
  'whatpaas.com': true,
  'whatsaas.com': true,
  'whopy.com': true,
  'wickmail.net': true,
  'wilemail.com': true,
  'willhackforfood.biz': true,
  'wmail.cf': true,
  'wolfsmail.tk': true,
  'writeme.us': true,
  'x.ip6.li': true,
  'x-pod.ru': true,
  'xagloo.co': true,
  'xmail.com': true,
  'xoxox.cc': true,
  'xxtreamcam.com': true,
  'xyzfree.net': true,
  'yanet.me': true,
  'yapped.net': true,
  'yhg.biz': true,
  'yomail.info': true,
  'yopmail.gq': true,
  'youmail.ga': true,
  'youmailr.com': true,
  'yourdomain.com': true,
  'you-spam.com': true,
  'yxzx.net': true,
  'z1p.biz': true,
  'za.com': true,
  'zebins.com': true,
  'zebins.eu': true,
  'zehnminuten.de': true,
  'zoemail.com': true,
  'zoemail.net': true,
  'zomg.info': true,
  'eml.pp.ua': true,
  'jetable.pp.ua': true,
  'get.pp.ua': true,
  'mox.pp.ua': true,
  'ip4.pp.ua': true,
  'loh.pp.ua': true,
  'add3000.pp.ua': true,
  'web-mail.pp.ua': true,
  'ip6.pp.ua': true,
  'yopmail.pp.ua': true,
  'fake-email.pp.ua': true,
  'stop-my-spam.pp.ua': true,
  'ass.pp.ua': true,
  'lackmail.ru': true,
  'dsiay.com': true,
  'noicd.com': true,
  'figjs.com': true,
  'trbvo.com': true,
  'euaqa.com': true,
  'laoeq.com': true,
  'xzsok.com': true,
  'vps30.com': true,
  '2emea.com': true,
  'hvzoi.com': true,
  'hu4ht.com': true,
  '027168.com': true,
  '0box.eu': true,
  '0v.ro': true,
  '0w.ro': true,
  '0x207.info': true,
  '1-8.biz': true,
  '1000rebates.stream': true,
  '100likers.com': true,
  '10minut.xyz': true,
  '10minutemail.be': true,
  '10minutemail.nl': true,
  '10minutemail.pro': true,
  '10minutemail.us': true,
  '10minutemailbox.com': true,
  '10minutemails.in': true,
  '10minutenemail.de': true,
  '10minutesmail.fr': true,
  '10minutmail.pl': true,
  '12hosting.net': true,
  '12storage.com': true,
  '140unichars.com': true,
  '147.cl': true,
  '14n.co.uk': true,
  '1clck2.com': true,
  '1st-forms.com': true,
  '1to1mail.org': true,
  '20email.it': true,
  '20mail.in': true,
  '20mm.eu': true,
  '2120001.net': true,
  '24hourmail.net': true,
  '2anom.com': true,
  '30wave.com': true,
  '36ru.com': true,
  '3l6.com': true,
  '4-n.us': true,
  '418.dk': true,
  '42o.org': true,
  '4tb.host': true,
  '55hosting.net': true,
  '5gramos.com': true,
  '5july.org': true,
  '5oz.ru': true,
  '5x25.com': true,
  '672643.net': true,
  '80665.com': true,
  '9me.site': true,
  '9q.ro': true,
  'aa5zy64.com': true,
  'abacuswe.us': true,
  'abakiss.com': true,
  'abcmail.email': true,
  'abilitywe.us': true,
  'abovewe.us': true,
  'absolutewe.us': true,
  'abundantwe.us': true,
  'abusemail.de': true,
  'abuser.eu': true,
  'ac20mail.in': true,
  'academiccommunity.com': true,
  'academywe.us': true,
  'acceleratewe.us': true,
  'accentwe.us': true,
  'acceptwe.us': true,
  'acclaimwe.us': true,
  'accordwe.us': true,
  'accreditedwe.us': true,
  'acentri.com': true,
  'achievementwe.us': true,
  'achievewe.us': true,
  'acornwe.us': true,
  'acrylicwe.us': true,
  'activatewe.us': true,
  'activitywe.us': true,
  'acuitywe.us': true,
  'acumenwe.us': true,
  'adaptivewe.us': true,
  'adaptwe.us': true,
  'adeptwe.us': true,
  'adiq.eu': true,
  'admiralwe.us': true,
  'adobeccepdm.com': true,
  'adoniswe.us': true,
  'adpugh.org': true,
  'adsd.org': true,
  'advantagewe.us': true,
  'advantimo.com': true,
  'adventurewe.us': true,
  'adventwe.us': true,
  'advisorwe.us': true,
  'advocatewe.us': true,
  'adwaterandstir.com': true,
  'aegia.net': true,
  'aegiscorp.net': true,
  'aegiswe.us': true,
  'aelo.es': true,
  'aeonpsi.com': true,
  'affiliatedwe.us': true,
  'affinitywe.us': true,
  'affluentwe.us': true,
  'affordablewe.us': true,
  'afterhourswe.us': true,
  'agendawe.us': true,
  'agger.ro': true,
  'agilewe.us': true,
  'agorawe.us': true,
  'agtx.net': true,
  'aheadwe.us': true,
  'ahk.jp': true,
  'airsi.de': true,
  'al-qaeda.us': true,
  'albionwe.us': true,
  'alchemywe.us': true,
  'aliaswe.us': true,
  'aligamel.com': true,
  'alisongamel.com': true,
  'alivewe.us': true,
  'allaccesswe.us': true,
  'allamericanwe.us': true,
  'allaroundwe.us': true,
  'alldirectbuy.com': true,
  'allegiancewe.us': true,
  'allegrowe.us': true,
  'allgoodwe.us': true,
  'alliancewe.us': true,
  'allinonewe.us': true,
  'alloutwe.us': true,
  'allowed.org': true,
  'alloywe.us': true,
  'allprowe.us': true,
  'allseasonswe.us': true,
  'allstarwe.us': true,
  'allthegoodnamesaretaken.org': true,
  'allurewe.us': true,
  'almondwe.us': true,
  'alph.wtf': true,
  'alphaomegawe.us': true,
  'alpinewe.us': true,
  'altairwe.us': true,
  'altitudewe.us': true,
  'altuswe.us': true,
  'amadeuswe.us': true,
  'amail.club': true,
  'amail.com': true,
  'amazon-aws.org': true,
  'amberwe.us': true,
  'ambiancewe.us': true,
  'ambitiouswe.us': true,
  'amelabs.com': true,
  'americanawe.us': true,
  'americasbestwe.us': true,
  'americaswe.us': true,
  'amicuswe.us': true,
  'amplewe.us': true,
  'amplifiedwe.us': true,
  'amplifywe.us': true,
  'ampsylike.com': true,
  'analogwe.us': true,
  'analysiswe.us': true,
  'analyticalwe.us': true,
  'analyticswe.us': true,
  'analyticwe.us': true,
  'andreihusanu.ro': true,
  'andthen.us': true,
  'animesos.com': true,
  'anit.ro': true,
  'anonymized.org': true,
  'anonymousness.com': true,
  'ansibleemail.com': true,
  'anthony-junkmail.com': true,
  'anyalias.com': true,
  'aoeuhtns.com': true,
  'apfelkorps.de': true,
  'aphlog.com': true,
  'apkmd.com': true,
  'appc.se': true,
  'appinventor.nl': true,
  'appixie.com': true,
  'apps.dj': true,
  'arduino.hk': true,
  'ariaz.jetzt': true,
  'aron.us': true,
  'arroisijewellery.com': true,
  'arurgitu.gq': true,
  'arvato-community.de': true,
  'aschenbrandt.net': true,
  'ashleyandrew.com': true,
  'astonut.tk': true,
  'astroempires.info': true,
  'asu.mx': true,
  'asu.su': true,
  'at0mik.org': true,
  'augmentationtechnology.com': true,
  'autorobotica.com': true,
  'autotwollow.com': true,
  'aver.com': true,
  'avls.pt': true,
  'awatum.de': true,
  'awiki.org': true,
  'axiz.org': true,
  'azcomputerworks.com': true,
  'b1of96u.com': true,
  'badgerland.eu': true,
  'badoop.com': true,
  'badpotato.tk': true,
  'banit.club': true,
  'banit.me': true,
  'bareed.ws': true,
  'barryogorman.com': true,
  'bartdevos.be': true,
  'basscode.org': true,
  'bauwerke-online.com': true,
  'bazaaboom.com': true,
  'bbhost.us': true,
  'bcast.ws': true,
  'bcb.ro': true,
  'bccto.me': true,
  'bdmuzic.pw': true,
  'bearsarefuzzy.com': true,
  'belamail.org': true,
  'belljonestax.com': true,
  'benipaula.org': true,
  'bestchoiceusedcar.com': true,
  'bestoption25.club': true,
  'betr.co': true,
  'bgtmail.com': true,
  'bgx.ro': true,
  'bidourlnks.com': true,
  'bigwhoop.co.za': true,
  'bij.pl': true,
  'binka.me': true,
  'binnary.com': true,
  'bitymails.us': true,
  'blip.ch': true,
  'blogspam.ro': true,
  'bluedumpling.info': true,
  'bluewerks.com': true,
  'boatmail.us': true,
  'bobmurchison.com': true,
  'bonobo.email': true,
  'bookthemmore.com': true,
  'borged.com': true,
  'borged.net': true,
  'borged.org': true,
  'bot.nu': true,
  'boxtemp.com.br': true,
  'brandallday.net': true,
  'brasx.org': true,
  'briggsmarcus.com': true,
  'bspooky.com': true,
  'bst-72.com': true,
  'btb-notes.com': true,
  'btc.email': true,
  'btizet.pl': true,
  'bulrushpress.com': true,
  'bum.net': true,
  'bunchofidiots.com': true,
  'bunsenhoneydew.com': true,
  'businessbackend.com': true,
  'businesssuccessislifesuccess.com': true,
  'buspad.org': true,
  'buyordie.info': true,
  'byebyemail.com': true,
  'byespm.com': true,
  'c51vsgq.com': true,
  'californiafitnessdeals.com': true,
  'candymail.de': true,
  'cane.pw': true,
  'car101.pro': true,
  'cars2.club': true,
  'caseedu.tk': true,
  'cavi.mx': true,
  'cbair.com': true,
  'cdpa.cc': true,
  'ceed.se': true,
  'cetpass.com': true,
  'cfo2go.ro': true,
  'ch.tc': true,
  'chacuo.net': true,
  'cheaphub.net': true,
  'chibakenma.ml': true,
  'chickenkiller.com': true,
  'chielo.com': true,
  'chilkat.com': true,
  'chithinh.com': true,
  'chumpstakingdumps.com': true,
  'cigar-auctions.com': true,
  'civx.org': true,
  'ckiso.com': true,
  'cl-cl.org': true,
  'cl0ne.net': true,
  'clandest.in': true,
  'clearwatermail.info': true,
  'clickdeal.co': true,
  'clipmail.eu': true,
  'cmail.club': true,
  'cnamed.com': true,
  'cnew.ir': true,
  'cnmsg.net': true,
  'cnsds.de': true,
  'cobarekyo1.ml': true,
  'cocovpn.com': true,
  'codeandscotch.com': true,
  'codivide.com': true,
  'com.ar': true,
  'compareshippingrates.org': true,
  'completegolfswing.com': true,
  'comwest.de': true,
  'contbay.com': true,
  'coolandwacky.us': true,
  'coolimpool.org': true,
  'coreclip.com': true,
  'coza.ro': true,
  'crankhole.com': true,
  'crastination.de': true,
  'crazespaces.pw': true,
  'cross-law.ga': true,
  'cross-law.gq': true,
  'crossroadsmail.com': true,
  'crusthost.com': true,
  'csh.ro': true,
  'cszbl.com': true,
  'ctmailing.us': true,
  'ctos.ch': true,
  'cu.cc': true,
  'curlhph.tk': true,
  'cutout.club': true,
  'cylab.org': true,
  'd1yun.com': true,
  'dab.ro': true,
  'daemsteam.com': true,
  'daintly.com': true,
  'damai.webcam': true,
  'dammexe.net': true,
  'damnthespam.com': true,
  'darkharvestfilms.com': true,
  'daryxfox.net': true,
  'dash-pads.com': true,
  'dataarca.com': true,
  datafilehost: true,
  'datarca.com': true,
  'datazo.ca': true,
  'davidkoh.net': true,
  'davidlcreative.com': true,
  'ddcrew.com': true,
  'de-a.org': true,
  'dealrek.com': true,
  'deekayen.us': true,
  'defomail.com': true,
  'degradedfun.net': true,
  'delayload.com': true,
  'delayload.net': true,
  'demen.ml': true,
  'dengekibunko.ga': true,
  'dengekibunko.gq': true,
  'dengekibunko.ml': true,
  'der-kombi.de': true,
  'derkombi.de': true,
  'derluxuswagen.de': true,
  'deyom.com': true,
  'dhm.ro': true,
  'dialogus.com': true,
  'diapaulpainting.com': true,
  'digitalmariachis.com': true,
  'dildosfromspace.com': true,
  'disbox.org': true,
  'dispo.in': true,
  'disposablemails.com': true,
  'divad.ga': true,
  'diwaq.com': true,
  'dlemail.ru': true,
  'dmarc.ro': true,
  'dndent.com': true,
  'dnses.ro': true,
  'doanart.com': true,
  'dob.jp': true,
  'dodgemail.de': true,
  'dodsi.com': true,
  'dolphinnet.net': true,
  'domforfb1.tk': true,
  'domforfb18.tk': true,
  'domforfb19.tk': true,
  'domforfb2.tk': true,
  'domforfb23.tk': true,
  'domforfb27.tk': true,
  'domforfb29.tk': true,
  'domforfb3.tk': true,
  'domforfb4.tk': true,
  'domforfb5.tk': true,
  'domforfb6.tk': true,
  'domforfb7.tk': true,
  'domforfb8.tk': true,
  'domforfb9.tk': true,
  'doquier.tk': true,
  'dotslashrage.com': true,
  'douchelounge.com': true,
  'dozvon-spb.ru': true,
  'dp76.com': true,
  'dr69.site': true,
  'dred.ru': true,
  'drevo.si': true,
  'drivetagdev.com': true,
  'droolingfanboy.de': true,
  'droplar.com': true,
  'dspwebservices.com': true,
  'duam.net': true,
  'duck2.club': true,
  'duk33.com': true,
  'dukedish.com': true,
  'durandinterstellar.com': true,
  'dwse.edu.pl': true,
  'dyceroprojects.com': true,
  'dynu.net': true,
  'dz17.net': true,
  'e3z.de': true,
  'easy-trash-mail.com': true,
  'eatmea2z.club': true,
  'ebeschlussbuch.de': true,
  'ecallheandi.com': true,
  'edgex.ru': true,
  'edinburgh-airporthotels.com': true,
  'edu.my': true,
  'edu.sg': true,
  'efxs.ca': true,
  'elearningjournal.org': true,
  'electro.mn': true,
  'elitevipatlantamodels.com': true,
  'email-jetable.fr': true,
  'email-temp.com': true,
  'email.cbes.net': true,
  'emailfake.ml': true,
  'emailfake.nut.cc': true,
  'emailfreedom.ml': true,
  'emailisvalid.com': true,
  'emailresort.com': true,
  'emailsecurer.com': true,
  'emailsingularity.net': true,
  'emailtech.info': true,
  'emeraldwebmail.com': true,
  'emil.com': true,
  'emlhub.com': true,
  'emlpro.com': true,
  'emltmp.com': true,
  'empireanime.ga': true,
  'epb.ro': true,
  'ephemeral.email': true,
  'eqiluxspam.ga': true,
  'ericjohnson.ml': true,
  'esc.la': true,
  'escapehatchapp.com': true,
  'esemay.com': true,
  'esgeneri.com': true,
  'esprity.com': true,
  'ethereum1.top': true,
  'evanfox.info': true,
  'evyush.com': true,
  'exitstageleft.net': true,
  'extremail.ru': true,
  'ez.lv': true,
  'ezfill.com': true,
  'ezstest.com': true,
  'f4k.es': true,
  'fackme.gq': true,
  'fadingemail.com': true,
  'fag.wf': true,
  'failbone.com': true,
  'faithkills.com': true,
  'fangoh.com': true,
  'farrse.co.uk': true,
  'fasternet.biz': true,
  'fbma.tk': true,
  'fbmail.usa.cc': true,
  'fddns.ml': true,
  'fer-gabon.org': true,
  'fettometern.com': true,
  'fictionsite.com': true,
  'figshot.com': true,
  'fiifke.de': true,
  'filbert4u.com': true,
  'filberts4u.com': true,
  'findu.pl': true,
  'fir.hk': true,
  'flemail.ru': true,
  'flowu.com': true,
  'flyinggeek.net': true,
  'foobarbot.net': true,
  'forecastertests.com': true,
  'foreskin.cf': true,
  'foreskin.ga': true,
  'foreskin.gq': true,
  'foreskin.ml': true,
  'foreskin.tk': true,
  'forspam.net': true,
  'forward.cat': true,
  'foxja.com': true,
  'foxtrotter.info': true,
  'fr.nf': true,
  'freebabysittercam.com': true,
  'freeblackbootytube.com': true,
  'freecat.net': true,
  'freedompop.us': true,
  'freefattymovies.com': true,
  'freemail.tweakly.net': true,
  'freeplumpervideos.com': true,
  'freeschoolgirlvids.com': true,
  'freesistercam.com': true,
  'freeteenbums.com': true,
  'ftp.sh': true,
  'ftpinc.ca': true,
  'fuckedupload.com': true,
  'fuckme69.club': true,
  'fucknloveme.top': true,
  'fuckxxme.top': true,
  'fuirio.com': true,
  'fulvie.com': true,
  'fun64.com': true,
  'funnycodesnippets.com': true,
  'funnymail.de': true,
  'furzauflunge.de': true,
  'fxnxs.com': true,
  'g14l71lb.com': true,
  'g1xmail.top': true,
  'g2xmail.top': true,
  'g3xmail.top': true,
  'g4hdrop.us': true,
  'gafy.net': true,
  'gaggle.net': true,
  'galaxy.tv': true,
  'gally.jp': true,
  'gamail.top': true,
  'gamegregious.com': true,
  'gamgling.com': true,
  'garasikita.pw': true,
  'garbagecollector.org': true,
  'gardenscape.ca': true,
  'garizo.com': true,
  'garrymccooey.com': true,
  'gav0.com': true,
  'gbcmail.win': true,
  'gbmail.top': true,
  'gcmail.top': true,
  'gdmail.top': true,
  'gedmail.win': true,
  'geekforex.com': true,
  'geew.ru': true,
  'geldwaschmaschine.de': true,
  'gen.uu.gl': true,
  'genderfuck.net': true,
  'geteit.com': true,
  'getnada.com': true,
  'getnowtoday.cf': true,
  'giacmosuaviet.info': true,
  'giaiphapmuasam.com': true,
  'ginzi.be': true,
  'ginzi.co.uk': true,
  'ginzi.es': true,
  'ginzi.net': true,
  'ginzy.co.uk': true,
  'ginzy.eu': true,
  'girlmail.win': true,
  'girlsindetention.com': true,
  'givmail.com': true,
  'glitch.sx': true,
  'globaltouron.com': true,
  'glubex.com': true,
  'glucosegrin.com': true,
  'gmatch.org': true,
  'gmx.fr.nf': true,
  'gmx1mail.top': true,
  'gmxmail.top': true,
  'gmxmail.win': true,
  'gnctr-calgary.com': true,
  'go2usa.info': true,
  'go2vpn.net': true,
  'golemico.com': true,
  'gothere.biz': true,
  'greenhousemail.com': true,
  'greggamel.com': true,
  'greggamel.net': true,
  'gregorsky.zone': true,
  'gregorygamel.com': true,
  'gregorygamel.net': true,
  'grish.de': true,
  'griuc.schule': true,
  'gs-arc.org': true,
  'gsredcross.org': true,
  'gsxstring.ga': true,
  'gudanglowongan.com': true,
  'guerillamail.de': true,
  'guerillamail.info': true,
  'gxemail.men': true,
  'gynzi.co.uk': true,
  'gynzi.es': true,
  'gynzy.at': true,
  'gynzy.es': true,
  'gynzy.eu': true,
  'gynzy.gr': true,
  'gynzy.info': true,
  'gynzy.lt': true,
  'gynzy.mobi': true,
  'gynzy.pl': true,
  'gynzy.ro': true,
  'gynzy.sk': true,
  'gzb.ro': true,
  'habitue.net': true,
  'hackersquad.tk': true,
  'hackthatbit.ch': true,
  'hahawrong.com': true,
  'haribu.com': true,
  'hasanmail.ml': true,
  'hawrong.com': true,
  'haydoo.com': true,
  'hazelnut4u.com': true,
  'hazelnuts4u.com': true,
  'hazmatshipping.org': true,
  'hccmail.win': true,
  'headstrong.de': true,
  'heathenhammer.com': true,
  'heathenhero.com': true,
  'hecat.es': true,
  'hellodream.mobi': true,
  'helloricky.com': true,
  'helpinghandtaxcenter.org': true,
  'herpderp.nl': true,
  'hezll.com': true,
  'hi5.si': true,
  'hiddentragedy.com': true,
  'hidebox.org': true,
  'hidemail.pro': true,
  'hidemail.us': true,
  'highbros.org': true,
  'hmail.us': true,
  'hmh.ro': true,
  'hoanggiaanh.com': true,
  'hoanglong.tech': true,
  'holl.ga': true,
  'hornyalwary.top': true,
  'hostlaba.com': true,
  'hotprice.co': true,
  'housat.com': true,
  'hpc.tw': true,
  'hs.vc': true,
  'ht.cx': true,
  'hukkmu.tk': true,
  'humn.ws.gy': true,
  'hungpackage.com': true,
  'hushmail.cf': true,
  'huskion.net': true,
  'hvastudiesucces.nl': true,
  'hwsye.net': true,
  'iaoss.com': true,
  'ibnuh.bz': true,
  'icantbelieveineedtoexplainthisshit.com': true,
  'icx.in': true,
  'icx.ro': true,
  'id.au': true,
  'ige.es': true,
  'ihazspam.ca': true,
  'illistnoise.com': true,
  'ilovespam.com': true,
  'inbound.plus': true,
  'inboxbear.com': true,
  'inclusiveprogress.com': true,
  'incq.com': true,
  'ind.st': true,
  'indieclad.com': true,
  'indirect.ws': true,
  'indomaed.pw': true,
  'indomina.cf': true,
  'indoserver.stream': true,
  'indosukses.press': true,
  'ineec.net': true,
  'inggo.org': true,
  'inoutmail.de': true,
  'inoutmail.eu': true,
  'inoutmail.info': true,
  'inoutmail.net': true,
  'insanumingeniumhomebrew.com': true,
  'instantblingmail.info': true,
  'internetoftags.com': true,
  'interstats.org': true,
  'intersteller.com': true,
  'investore.co': true,
  'ippandansei.tk': true,
  'ipsur.org': true,
  'irabops.com': true,
  'irc.so': true,
  'ironiebehindert.de': true,
  'irssi.tv': true,
  'isdaq.com': true,
  'isosq.com': true,
  'istii.ro': true,
  'isukrainestillacountry.com': true,
  'it7.ovh': true,
  'itunesgiftcodegenerator.com': true,
  'ixx.io': true,
  'j-p.us': true,
  'jafps.com': true,
  'janproz.com': true,
  'jdmadventures.com': true,
  'jdz.ro': true,
  'jellow.ml': true,
  'jellyrolls.com': true,
  'jmail.ovh': true,
  'jmail.ro': true,
  'jobbikszimpatizans.hu': true,
  'jobposts.net': true,
  'jobs-to-be-done.net': true,
  'joelpet.com': true,
  'joetestalot.com': true,
  'jopho.com': true,
  'josse.ltd': true,
  'jpco.org': true,
  'jungkamushukum.com': true,
  'justemail.ml': true,
  'jwork.ru': true,
  'kademen.com': true,
  'kadokawa.cf': true,
  'kadokawa.ga': true,
  'kadokawa.gq': true,
  'kadokawa.ml': true,
  'kadokawa.tk': true,
  'kakadua.net': true,
  'kalapi.org': true,
  'kamsg.com': true,
  'kaovo.com': true,
  'karatraman.ml': true,
  'kariplan.com': true,
  'kartvelo.com': true,
  'katztube.com': true,
  'kazelink.ml': true,
  'kcrw.de': true,
  'keinhirn.de': true,
  'keipino.de': true,
  'kekita.com': true,
  'kemptvillebaseball.com': true,
  'kennedy808.com': true,
  'kiani.com': true,
  'kimsdisk.com': true,
  'kingsq.ga': true,
  'kiois.com': true,
  'kismail.ru': true,
  'kisstwink.com': true,
  'kitnastar.com': true,
  'kludgemush.com': true,
  'kommunity.biz': true,
  'kon42.com': true,
  'kook.ml': true,
  'kopagas.com': true,
  'kopaka.net': true,
  'kosmetik-obatkuat.com': true,
  'kozow.com': true,
  'krd.ag': true,
  'krsw.tk': true,
  'krypton.tk': true,
  'kuhrap.com': true,
  'kwift.net': true,
  'kwilco.net': true,
  'kyal.pl': true,
  'l-c-a.us': true,
  'labetteraverouge.at': true,
  'lacedmail.com': true,
  'lain.ch': true,
  'lak.pp.ua': true,
  'lakelivingstonrealestate.com': true,
  'lastmail.com': true,
  'lazyinbox.com': true,
  'lazyinbox.us': true,
  'ldop.com': true,
  'ldtp.com': true,
  'lee.mx': true,
  'lellno.gq': true,
  'letmeinonthis.com': true,
  'lez.se': true,
  'liamcyrus.com': true,
  'lifetotech.com': true,
  'ligsb.com': true,
  'lillemap.net': true,
  'lilo.me': true,
  'lindenbaumjapan.com': true,
  'linkedintuts2016.pw': true,
  'lkgn.se': true,
  'loan101.pro': true,
  'locanto1.club': true,
  'locantofuck.top': true,
  'locantowsite.club': true,
  'locomodev.net': true,
  'logular.com': true,
  'loin.in': true,
  'lolmail.biz': true,
  'lordsofts.com': true,
  'lpfmgmtltd.com': true,
  'lru.me': true,
  'lukecarriere.com': true,
  'lukemail.info': true,
  'lyfestylecreditsolutions.com': true,
  'macromaid.com': true,
  'magamail.com': true,
  'maggotymeat.ga': true,
  'magicbox.ro': true,
  'maidlow.info': true,
  'mail-owl.com': true,
  'mail-temporaire.com': true,
  'mail.wtf': true,
  'mail22.club': true,
  'mail666.ru': true,
  'mail707.com': true,
  'mail72.com': true,
  'mailback.com': true,
  'mailbox80.biz': true,
  'mailbox82.biz': true,
  'mailbox92.biz': true,
  'mailchop.com': true,
  'mailcker.com': true,
  'maildx.com': true,
  'mailed.ro': true,
  'maileme101.com': true,
  'mailf5.com': true,
  'mailfall.com': true,
  'mailfs.com': true,
  'mailgutter.com': true,
  'mailinatar.com': true,
  'mailinator.co.uk': true,
  'mailinator.info': true,
  'mailmate.com': true,
  'mailna.biz': true,
  'mailna.co': true,
  'mailonaut.com': true,
  'mailorc.com': true,
  'mailpooch.com': true,
  'mailpoof.com': true,
  'mailtechx.com': true,
  'mailtemporaire.com': true,
  'mailtemporaire.fr': true,
  'mailtraps.com': true,
  'mailzilla.orgmbx.cc': true,
  'makemenaughty.club': true,
  'malayalamdtp.com': true,
  'mandraghen.cf': true,
  'mansiondev.com': true,
  'markmurfin.com': true,
  'maswae.world': true,
  'matchpol.net': true,
  'mcache.net': true,
  'mciek.com': true,
  'mdhc.tk': true,
  'mechanicalresumes.com': true,
  'meepsheep.eu': true,
  'messwiththebestdielikethe.rest': true,
  'mfsa.info': true,
  'mfsa.ru': true,
  'miaferrari.com': true,
  'midcoastcustoms.com': true,
  'midcoastcustoms.net': true,
  'midcoastsolutions.com': true,
  'midcoastsolutions.net': true,
  'midlertidig.com': true,
  'midlertidig.net': true,
  'midlertidig.org': true,
  'migmail.net': true,
  'mihep.com': true,
  'mijnhva.nl': true,
  'minsmail.com': true,
  'mji.ro': true,
  'mkpfilm.com': true,
  'ml8.ca': true,
  'mm.my': true,
  'mm5.se': true,
  'mnode.me': true,
  'moakt.co': true,
  'moakt.ws': true,
  'mobilevpn.top': true,
  'mockmyid.com': true,
  'moeri.org': true,
  'momentics.ru': true,
  'monadi.ml': true,
  'moneypipe.net': true,
  'moonwake.com': true,
  'moot.es': true,
  'moreawesomethanyou.com': true,
  'moreorcs.com': true,
  'morriesworld.ml': true,
  'morsin.com': true,
  'motique.de': true,
  'mountainregionallibrary.net': true,
  'moza.pl': true,
  'mr24.co': true,
  'msgos.com': true,
  'msk.ru': true,
  'mspeciosa.com': true,
  'msrc.ml': true,
  'mswork.ru': true,
  'msxd.com': true,
  'mtmdev.com': true,
  'muathegame.com': true,
  'mucincanon.com': true,
  'munoubengoshi.gq': true,
  'mutant.me': true,
  'mwarner.org': true,
  'mxfuel.com': true,
  'mybitti.de': true,
  'mycorneroftheinter.net': true,
  'myde.ml': true,
  'mydemo.equipment': true,
  'myecho.es': true,
  'myindohome.services': true,
  'myinterserver.ml': true,
  'mykickassideas.com': true,
  'mymailoasis.com': true,
  'myopang.com': true,
  'mystvpn.com': true,
  'mytemp.email': true,
  'mywarnernet.net': true,
  'myzx.com': true,
  'n1nja.org': true,
  'nada.email': true,
  'nada.ltd': true,
  'nakedtruth.biz': true,
  'nanonym.ch': true,
  'nationalgardeningclub.com': true,
  'nbox.notif.me': true,
  'negated.com': true,
  'netricity.nl': true,
  'netris.net': true,
  'netviewer-france.com': true,
  'newbpotato.tk': true,
  'next.ovh': true,
  'nextstopvalhalla.com': true,
  'nezzart.com': true,
  'nfast.net': true,
  'nguyenusedcars.com': true,
  'nh3.ro': true,
  'nicknassar.com': true,
  'nincsmail.com': true,
  'niwl.net': true,
  'nm7.cc': true,
  'nnot.net': true,
  'no-ux.com': true,
  'nodezine.com': true,
  'nokiamail.com': true,
  'nom.za': true,
  'nonze.ro': true,
  'norseforce.com': true,
  'nothingtoseehere.ca': true,
  'notrnailinator.com': true,
  'now.im': true,
  'nubescontrol.com': true,
  'nutpa.net': true,
  'nuts2trade.com': true,
  'ny7.me': true,
  'nypato.com': true,
  'o2stk.org': true,
  'o7i.net': true,
  'obfusko.com': true,
  'obxpestcontrol.com': true,
  'oerpub.org': true,
  'offshore-proxies.net': true,
  'ohi.tw': true,
  'oing.cf': true,
  'okclprojects.com': true,
  'okrent.us': true,
  'okzk.com': true,
  'omnievents.org': true,
  'one-time.email': true,
  'onlineidea.info': true,
  'onqin.com': true,
  'ontyne.biz': true,
  'oolus.com': true,
  'opendns.ro': true,
  'opmmedia.ga': true,
  'opp24.com': true,
  'oreidresume.com': true,
  'oroki.de': true,
  'oshietechan.link': true,
  'ourpreviewdomain.com': true,
  'outmail.win': true,
  'ovi.usa.cc': true,
  'ownsyou.de': true,
  'oxopoha.com': true,
  'ozyl.de': true,
  'p33.org': true,
  'p71ce1m.com': true,
  'pa9e.com': true,
  'pagamenti.tk': true,
  'pakadebu.ga': true,
  'pastebitch.com': true,
  'penisgoes.in': true,
  'penoto.tk': true,
  'peterdethier.com': true,
  'petrzilka.net': true,
  'photomark.net': true,
  'pi.vu': true,
  'piaa.me': true,
  'pig.pp.ua': true,
  'pii.at': true,
  'piki.si': true,
  'pinehill-seattle.org': true,
  'pingir.com': true,
  'pisls.com': true,
  'plhk.ru': true,
  'plw.me': true,
  'pojok.ml': true,
  'pokemail.net': true,
  'pokiemobile.com': true,
  'polarkingxx.ml': true,
  'pooae.com': true,
  'poopiebutt.club': true,
  'popesodomy.com': true,
  'popgx.com': true,
  'porsh.net': true,
  'postacin.com': true,
  'poutineyourface.com': true,
  'powlearn.com': true,
  'ppetw.com': true,
  'predatorrat.cf': true,
  'predatorrat.ga': true,
  'predatorrat.gq': true,
  'predatorrat.ml': true,
  'predatorrat.tk': true,
  'primabananen.net': true,
  'pro-tag.org': true,
  'procrackers.com': true,
  'projectcl.com': true,
  'proprietativalcea.ro': true,
  'propscore.com': true,
  'protempmail.com': true,
  'proxyparking.com': true,
  'psh.me': true,
  'psles.com': true,
  'psoxs.com': true,
  'puglieisi.com': true,
  'puji.pro': true,
  'purcell.email': true,
  'purelogistics.org': true,
  'qbfree.us': true,
  'qc.to': true,
  'qibl.at': true,
  'qipmail.net': true,
  'qiq.us': true,
  'qq.my': true,
  'qsl.ro': true,
  'qtum-ico.com': true,
  'quadrafit.com': true,
  'ququb.com': true,
  'qvy.me': true,
  'qwickmail.com': true,
  'r4nd0m.de': true,
  'ra3.us': true,
  'rabin.ca': true,
  'rabiot.reisen': true,
  'raetp9.com': true,
  'raketenmann.de': true,
  'rancidhome.net': true,
  'randomail.net': true,
  'raqid.com': true,
  'rax.la': true,
  'raxtest.com': true,
  'razemail.com': true,
  'rbb.org': true,
  'rcasd.com': true,
  'rdklcrv.xyz': true,
  're-gister.com': true,
  'rebates.stream': true,
  'recipeforfailure.com': true,
  'reddit.usa.cc': true,
  'redfeathercrow.com': true,
  'rejo.technology': true,
  'remarkable.rocks': true,
  'remote.li': true,
  'reptilegenetics.com': true,
  'revolvingdoorhoax.org': true,
  'riddermark.de': true,
  'risingsuntouch.com': true,
  'riski.cf': true,
  'rkomo.com': true,
  'rma.ec': true,
  'rnailinator.com': true,
  'ro.lt': true,
  'robertspcrepair.com': true,
  'rollindo.agency': true,
  'ronnierage.net': true,
  'rotaniliam.com': true,
  'rowe-solutions.com': true,
  'royaldoodles.org': true,
  'rtskiya.xyz': true,
  'rudymail.ml': true,
  'ruffrey.com': true,
  'rumgel.com': true,
  'runi.ca': true,
  'rustydoor.com': true,
  'rvb.ro': true,
  's33db0x.com': true,
  'sabrestlouis.com': true,
  'sackboii.com': true,
  'safaat.cf': true,
  'safermail.info': true,
  'saharanightstempe.com': true,
  'salmeow.tk': true,
  'samsclass.info': true,
  'sandwhichvideo.com': true,
  'sanfinder.com': true,
  'sanim.net': true,
  'sanstr.com': true,
  'sast.ro': true,
  'satisfyme.club': true,
  'satukosong.com': true,
  'sausen.com': true,
  'scay.net': true,
  'schachrol.com': true,
  'sd3.in': true,
  'secured-link.net': true,
  'securehost.com.es': true,
  'seekapps.com': true,
  'seekjobs4u.com': true,
  'sejaa.lv': true,
  'sendfree.org': true,
  'sendingspecialflyers.com': true,
  'sexforswingers.com': true,
  'sexical.com': true,
  'sexyalwasmi.top': true,
  'shalar.net': true,
  'shhmail.com': true,
  'shhuut.org': true,
  'shipfromto.com': true,
  'shiphazmat.org': true,
  'shipping-regulations.com': true,
  'shippingterms.org': true,
  'shitmail.de': true,
  'shotmail.ru': true,
  'showslow.de': true,
  'shrib.com': true,
  'sikux.com': true,
  'siliwangi.ga': true,
  'simpleitsecurity.info': true,
  'sin.cl': true,
  'sinfiltro.cl': true,
  'singlespride.com': true,
  'sino.tw': true,
  'sizzlemctwizzle.com': true,
  'sjuaq.com': true,
  'sky-inbox.com': true,
  'slippery.email': true,
  'slipry.net': true,
  'slothmail.net': true,
  'sluteen.com': true,
  'sly.io': true,
  'smallker.tk': true,
  'smellrear.com': true,
  'smellypotato.tk': true,
  'smtp99.com': true,
  'smwg.info': true,
  'snapwet.com': true,
  'socialfurry.org': true,
  'softpls.asia': true,
  'soisz.com': true,
  'solventtrap.wiki': true,
  'sonshi.cf': true,
  'soodmail.com': true,
  'soon.it': true,
  'spam.org.es': true,
  'spamlot.net': true,
  'spamtrap.ro': true,
  'spb.ru': true,
  'speedgaus.net': true,
  'spikio.com': true,
  'spr.io': true,
  'spritzzone.de': true,
  'spybox.de': true,
  'squizzy.net': true,
  'sroff.com': true,
  'stanfordujjain.com': true,
  'starlight-breaker.net': true,
  'startfu.com': true,
  'statdvr.com': true,
  'stathost.net': true,
  'statiix.com': true,
  'steambot.net': true,
  'stexsy.com': true,
  'storj99.com': true,
  'storj99.top': true,
  'stromox.com': true,
  'stuckmail.com': true,
  'stumpfwerk.com': true,
  'suburbanthug.com': true,
  'suckmyd.com': true,
  'sudolife.me': true,
  'sudolife.net': true,
  'sudomail.biz': true,
  'sudomail.com': true,
  'sudomail.net': true,
  'sudoverse.com': true,
  'sudoverse.net': true,
  'sudoweb.net': true,
  'sudoworld.com': true,
  'sudoworld.net': true,
  'svk.jp': true,
  'svxr.org': true,
  'sweetpotato.ml': true,
  'sylvannet.com': true,
  'symphonyresume.com': true,
  'syosetu.gq': true,
  'syujob.accountants': true,
  'szerz.com': true,
  'tafoi.gr': true,
  'taglead.com': true,
  'tagmymedia.com': true,
  'tanukis.org': true,
  'taphear.com': true,
  'tb-on-line.net': true,
  'tech69.com': true,
  'teerest.com': true,
  'tefl.ro': true,
  'telecomix.pl': true,
  'tellos.xyz': true,
  'temp-mail.com': true,
  'temp-mail.de': true,
  'temp-mail.pp.ua': true,
  'temp-mails.com': true,
  'tempmail.co': true,
  'tempmail.de': true,
  'tempmail.pp.ua': true,
  'tempmail.us': true,
  'tempr.email': true,
  'tempsky.com': true,
  'ternaklele.ga': true,
  'testore.co': true,
  'testudine.com': true,
  'thatim.info': true,
  'theaviors.com': true,
  'thebearshark.com': true,
  'thediamants.org': true,
  'thelightningmail.net': true,
  'thembones.com.au': true,
  'themostemail.com': true,
  'thereddoors.online': true,
  'thescrappermovie.com': true,
  'theteastory.info': true,
  'thex.ro': true,
  'thietbivanphong.asia': true,
  'thisurl.website': true,
  'thnikka.com': true,
  'thrott.com': true,
  'throwam.com': true,
  'throwawaymail.pp.ua': true,
  'throya.com': true,
  'thunkinator.org': true,
  'thxmate.com': true,
  'tiapz.com': true,
  'tic.ec': true,
  'timgiarevn.com': true,
  'timkassouf.com': true,
  'tinyurl24.com': true,
  'tiv.cc': true,
  'tkitc.de': true,
  'tlpn.org': true,
  'tmail.ws': true,
  'tmails.net': true,
  'tmpeml.info': true,
  'tmpjr.me': true,
  'tmpmail.net': true,
  'tmpmail.org': true,
  'toddsbighug.com': true,
  'tokenmail.de': true,
  'tonymanso.com': true,
  'toon.ml': true,
  'top101.de': true,
  'top1mail.ru': true,
  'top1post.ru': true,
  'topinrock.cf': true,
  'topofertasdehoy.com': true,
  'toprumours.com': true,
  'toss.pw': true,
  'tosunkaya.com': true,
  'totalvista.com': true,
  'totesmail.com': true,
  'tp-qa-mail.com': true,
  'tqoai.com': true,
  'tqosi.com': true,
  'tranceversal.com': true,
  'trash-me.com': true,
  'trashmail.io': true,
  'trashmails.com': true,
  'trasz.com': true,
  'trollproject.com': true,
  'tropicalbass.info': true,
  'trungtamtoeic.com': true,
  'ttszuo.xyz': true,
  'tualias.com': true,
  'turoid.com': true,
  'tvchd.com': true,
  'tverya.com': true,
  'twkly.ml': true,
  'twoweirdtricks.com': true,
  'txtadvertise.com': true,
  'tyhe.ro': true,
  'uacro.com': true,
  'ubm.md': true,
  'ucche.us': true,
  'ucupdong.ml': true,
  'ufacturing.com': true,
  'uguuchantele.com': true,
  'uhhu.ru': true,
  'uiu.us': true,
  'ujijima1129.gq': true,
  'uk.to': true,
  'undo.it': true,
  'unids.com': true,
  'unimark.org': true,
  'unit7lahaina.com': true,
  'unmail.ru': true,
  'uploadnolimit.com': true,
  'urfunktion.se': true,
  'us.to': true,
  'ushijima1129.cf': true,
  'ushijima1129.ga': true,
  'ushijima1129.gq': true,
  'ushijima1129.ml': true,
  'ushijima1129.tk': true,
  'utiket.us': true,
  'uu.gl': true,
  'uu2.ovh': true,
  'uwork4.us': true,
  'vaati.org': true,
  'valhalladev.com': true,
  'vankin.de': true,
  'vctel.com': true,
  'vda.ro': true,
  'vdig.com': true,
  'verdejo.com': true,
  'veryday.ch': true,
  'veryday.eu': true,
  'veryday.info': true,
  'vesa.pw': true,
  'vfemail.net': true,
  'victime.ninja': true,
  'victoriantwins.com': true,
  'vikingsonly.com': true,
  'vinernet.com': true,
  'vipepe.com': true,
  'vipxm.net': true,
  'visal007.tk': true,
  'visal168.cf': true,
  'visal168.ga': true,
  'visal168.gq': true,
  'visal168.ml': true,
  'visal168.tk': true,
  'vixletdev.com': true,
  'vmailing.info': true,
  'vmani.com': true,
  'vmpanda.com': true,
  'vnedu.me': true,
  'voidbay.com': true,
  'vorga.org': true,
  'votiputox.org': true,
  'voxelcore.com': true,
  'vps911.net': true,
  'vrmtr.com': true,
  'vssms.com': true,
  'vtxmail.us': true,
  'vuiy.pw': true,
  'w3internet.co.uk': true,
  'wakingupesther.com': true,
  'walkmail.ru': true,
  'wallm.com': true,
  'watchever.biz': true,
  'watchironman3onlinefreefullmovie.com': true,
  'wbml.net': true,
  'web.id': true,
  'webmail.igg.biz': true,
  'webtrip.ch': true,
  'wee.my': true,
  'wef.gr': true,
  'wefjo.grn.cc': true,
  'wegwerfemail.info': true,
  'welikecookies.com': true,
  'wg0.com': true,
  'whatifanalytics.com': true,
  'whiffles.org': true,
  'wibblesmith.com': true,
  'widaryanto.info': true,
  'widget.gg': true,
  'wierie.tk': true,
  'wimsg.com': true,
  'wins.com.br': true,
  'wlist.ro': true,
  'wmail.club': true,
  'wollan.info': true,
  'worldspace.link': true,
  'wpg.im': true,
  'wralawfirm.com': true,
  'wudet.men': true,
  'wuespdj.xyz': true,
  'wupics.com': true,
  'wxnw.net': true,
  'x24.com': true,
  'xbaby69.top': true,
  'xcode.ro': true,
  'xcompress.com': true,
  'xcpy.com': true,
  'xemne.com': true,
  'xjoi.com': true,
  'xl.cx': true,
  'xn--9kq967o.com': true,
  'xost.us': true,
  'xrho.com': true,
  'xwaretech.com': true,
  'xwaretech.info': true,
  'xwaretech.net': true,
  'xww.ro': true,
  'xxhamsterxx.ga': true,
  'xxi2.com': true,
  'xxlocanto.us': true,
  'xxolocanto.us': true,
  'yabai-oppai.tk': true,
  'yahmail.top': true,
  'yamail.win': true,
  'yannmail.win': true,
  'yaqp.com': true,
  'ycare.de': true,
  'ycn.ro': true,
  'ye.vc': true,
  'yedi.org': true,
  'ynmrealty.com': true,
  'yodx.ro': true,
  'yoo.ro': true,
  'yordanmail.cf': true,
  'yougotgoated.com': true,
  'youneedmore.info': true,
  'youremail.cf': true,
  'yourewronghereswhy.com': true,
  'yourlms.biz': true,
  'yourtube.ml': true,
  'yspend.com': true,
  'yugasandrika.com': true,
  'yui.it': true,
  'yyolf.net': true,
  'z0d.eu': true,
  'z86.ru': true,
  'zain.site': true,
  'zainmax.net': true,
  'zasod.com': true,
  'zepp.dk': true,
  'zfymail.com': true,
  'zhcne.com': true,
  'zik.dj': true,
  'zipcad.com': true,
  'zipsendtest.com': true,
  'zoetropes.org': true,
  'zombie-hive.com': true,
  'zumpul.com': true,
  'zxcv.com': true,
  'zxcvbnm.com': true,
  'zzi.us': true,
  'zzz.com': true,
  'africamel.net': true,
  'brusseler.com': true,
  'emailasso.net': true,
  'europamel.net': true,
  'francemel.fr': true,
  'inmano.com': true,
  'lavache.com': true,
  'mailo.com': true,
  'monemail.com': true,
  'net-c.be': true,
  'net-c.ca': true,
  'net-c.cat': true,
  'net-c.com': true,
  'net-c.es': true,
  'net-c.fr': true,
  'net-c.it': true,
  'net-c.lu': true,
  'net-c.nl': true,
  'net-c.pl': true,
  'netc.eu': true,
  'netc.fr': true,
  'netc.it': true,
  'netc.lu': true,
  'netc.pl': true,
  'netcmail.com': true,
  'perso.be': true,
  'infomagic.com': true,
  'vyaparys.com': true,
  'wattpad.com': true,
  'aileensoul.com': true,
  'locanto.net': true,
  'callme.co.in': true,
  'clickinfone.com': true,
  'ypquick.com': true,
  'mxlookup.rb': true,
};
