  import classNames from 'classnames';
  import styles from './index.module.scss';
  import { Link, useNavigate } from 'react-router-dom';
  import { useDispatch } from 'react-redux';
  import { RESET_STORE } from 'redux/common/types';
  import { logoutUser } from 'api/auth';
  import OneSignal from 'react-onesignal';
  import { useState } from 'react';
  import { Avatar, Col, Row, Space, Typography } from 'antd';
  import DButton from 'components/DButton';
  import UserIcon from 'assets/icons/user.svg';
  import PostedJobIcon from 'assets/icons/svg-icon-2.svg';
  import PaymentIcon from 'assets/icons/svg-icon-3.svg';
  import TransactionIcon from 'assets/icons/svg-icon-4.svg';
  import PrivacyPolicyIcon from 'assets/icons/svg-icon-5.svg';
  import TermsOfUsIcon from 'assets/icons/svg-icon-6.svg';
  import useUser from 'redux/user/use-user';
  import CircleBgIcon from 'components/Icons/CircleBgIcon';
  import CustomAvatar from 'components/CustomAvatar';


  type Props = {
    className?: string;
  };
  const { Text } = Typography;
  const base_path = process.env.REACT_APP_MEDIA_BASE_URL

  const UserSettings = ({ className }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDrpDown,setShowDrpDown] = useState<boolean>(false)
    const { user,keepUser } = useUser();

    const logout = async () => {

      await logoutUser();

      localStorage.removeItem('token');
      localStorage.removeItem('deviceId');
      localStorage.removeItem('pushNotificationId');
      OneSignal.setExternalUserId('promo');

      setTimeout(()=>{
        dispatch({ type: RESET_STORE });
      },1000)
      navigate('/auth');
    };

    let drpDownStyles  =  ''

    if(!showDrpDown){
      drpDownStyles=' invisible opacity-0 top-[85px] translate-y-1'
    }else{
      drpDownStyles=' visible opacity-100 top-[58px] translate-y-0'
    }

    const onClick = (url:string) =>{
      setShowDrpDown(false)
      navigate(url)
    }

    return (
     <>
      <div className={classNames([styles.div, className])} onClick={()=>setShowDrpDown(!showDrpDown)}>

      <CustomAvatar
          firstName={user?.firstName}
          lastName={user?.lastName}
          size={44}
          replaceImageSize='50x50'
          imagePath={user?.profilePicture}
          nameClasses='bg-[#73787e] font-bold text-[20px]'

          />
        <i className="icon-20 text-white text-font-10 mr-3 mt-1" />
      </div>
      <div>
      {showDrpDown &&   <div onClick={()=>setShowDrpDown(false)} className={classNames(styles.mask)}/>}
      <div className={classNames([styles.content,drpDownStyles,'w-[280px]  overflow-y-auto  sm:w-[360px] mt-2 transition-all ease-linear duration-200'])}>
            <div className="flex pt-5  px-5 justify-start items-baseline">
                  <Col>
                  <CustomAvatar
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    size={44}
                    replaceImageSize='50x50'
                    imagePath={user?.profilePicture}
                    nameClasses='bg-[#73787e] font-bold text-[20px]'
                    />
                </Col>
                   <div className="font-medium  px-5 py-2">
                      <div   className={classNames('flex items-center')}>
                       <div className={classNames(styles.fullName)}> {user?.fullName}</div>
                       {user.pro && (
                         <CircleBgIcon circleClasses="ml-4" circleColor="orange" icon="icon-25" />
                       )}
                      </div>
                      {user.profileType ==='MASTER' &&(
                           <Row
                           justify="center"
                           align="middle"
                           className={classNames('ml-[2px] mt-2 h-[30px] w-[100px] bg-[#EE784B] rounded-[20px]')}
                            >
                           <Text className="text-font-12  font-bold text-white px-2 py-[2px]">Master</Text>
                         </Row>
                      )}
                   </div>
            </div>
            <div className="flex  py-3  px-5  justify-start items-center">
             {user.profileType !=='MASTER' && (
                 <DButton
                 className={classNames(styles.cancelBtn, 'h-10  !p-0 !px-5 text-white font-bold text-font-13')}
                 type="light-green"
                 onClick={()=>navigate('/become-a-master/steps') }
                 text="Become a master"
               />
             )}
            </div>
            <hr/>
            <div className= "px-5  max-h-[360px] overflow-y-auto overflow-x-hidden">
                      <div className="flex pt-[22px]  justify-start items-center">
                              <img className='mx-3' src={UserIcon} alt="icon" />
                              {/* <Link to=''>{'Profile'}</Link> */}
                              <span className='cursor-pointer' onClick={()=>onClick('/profile')}>{'Profile'}</span>

                      </div>
                      <div className="flex pt-[22px]   justify-start items-center">
                      <img className='mx-3' src={PostedJobIcon} alt="icon" />
                                <span className='cursor-pointer' onClick={()=>onClick('/posted-jobs')}>{'Posted Jobs'}</span>

                      </div>
                      <div className="flex pt-[22px]   justify-start items-center">
                        <img className='mx-3' src={PaymentIcon} alt="icon" />

                        <span className='cursor-pointer' onClick={()=>onClick('/payments')}>{'Payments'}</span>

                      </div>
                      <div className="flex pt-[22px]  justify-start items-center">
                        <img className='mx-3' src={TransactionIcon} alt="icon" />
                        <span className='cursor-pointer' onClick={()=>onClick('/transactions')}>{'Transactions'}</span>
                      </div>

                      <div className="flex pt-[22px]  justify-start items-center">
                          <img className='mx-3' src={PrivacyPolicyIcon} alt="icon" />
                                <a href="https://ant.design/index-cn">{'Privicy Policy'}</a>
                      </div>
                      <div className="flex pt-[22px]  justify-start items-center">
                      <img className='mx-3' src={TermsOfUsIcon} alt="icon" />
                                <a href="https://ant.design/index-cn">{'Terms of use'}</a>
                      </div>
                      <div className="flex py-[22px]  pl-10 justify-start items-center">
                                <span className='cursor-pointer' onClick={logout}>{'Log out'}</span>
                      </div>

            </div>
    </div>
      </div>


      </>
    );
  };

  export default UserSettings;
